import { Dialog } from '@/uiCore'
import { VehicleDialog } from './ParkingImage'

export const ParkingDialog = (props) => {
    const { refresh, refresh2, show, setShow, propsdata, isAi, setRender = () => {} } = props

    return (
        <>
            <VehicleDialog
                refresh={refresh}
                show={show}
                setShow={setShow}
                propsdata={propsdata}
                isAi={isAi}
                setRender={setRender}
            />
        </>
    )
}
export const ParkingDialogNo = (props) => {
    return (
        <>
            <Dialog visible={props.showImg} style={{ width: '50vw' }} onHide={() => props.setShowImg(false)}>
                <img
                    src={props.propsdata.image}
                    style={{ cursor: 'pointer', width: '100%', aspectRatio: '1.5', margin: '0 auto' }}
                />
            </Dialog>
        </>
    )
}
