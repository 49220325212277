import { CalendarForm, DropdownForm, FormUpdate, InputForm, InputSwitchForm } from '@/components'
import {listToast, typeCard, typeVehicle} from '@/constants'
import { databaseDate } from '@/lib'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import { clearCardInfo } from '../../../../redux/features/cardInfor'
import {addCardApi, checkCardApartmentApi, updateCardApi} from '../api'
import { useDetailCard } from '../utils'
import {useGetApi} from "@/hooks";
import {confirmDialog} from "primereact/confirmdialog";
import {setToast} from "@/redux/features";


const UpdateCard = () => {
    const { id } = useParams()
    const apartments = useSelector(state => state.apartments)
    const residents = useSelector(state => state.residents)
    const cardDetail = useDetailCard(id)
    const [infos, setInfos] = useState({ status: true, permissions: [], type: 1 })
    const [data, setData] = useState([])
    const cardInfo = useSelector((state) => state.cardInfo)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const numberCard = useGetApi(checkCardApartmentApi, { apartment_id: infos.apartment_id }, 0)

    useEffect(() => {
        let newData = ''
        if (cardDetail.data && cardInfo === null) {
            newData = checkJson(cardDetail.data) || cardDetail.data
        }

        if (cardDetail.id) setInfos({
            ...infos,
            ...removeUndefinedProps(cardDetail),
            card_receipt_date: cardDetail.card_receipt_date ? new Date(cardDetail.card_receipt_date) : undefined,
            // card_receipt_date: cardDetail.card_receipt_date && new Date(cardDetail.card_receipt_date),
            expired: cardDetail.expired && new Date(cardDetail.expired),
            charge_date: cardDetail.charge_date && new Date(cardDetail.charge_date),
            status: cardDetail.status ? true : false,
            full_name: data.full_name || undefined,
            phone: data.phone || undefined,
            cmt_number: data.cmt_number || undefined,
            ...newData
        })
        if (cardInfo) {
            const resident = residents.find(r => r.user_id === cardInfo.user_info_id) || {}
            setInfos({
                ...infos,
                ...removeUndefinedProps(cardInfo),
                user_info_id: resident.id,
            })
            dispatch(clearCardInfo())
        }

    }, [cardDetail, cardInfo, residents]);

    const handleData = () => {
        let info = {
            ...infos,
            status: infos.status ? 1 : 0,
            card_receipt_date: infos.card_receipt_date && databaseDate(infos.card_receipt_date),
            charge_date: infos.charge_date && databaseDate(infos.charge_date),
            expired: infos.expired && databaseDate(infos.expired),
        }
        if (!info.expired) info.expired = undefined
        if (!infos.apartment_id) return 'Vui lòng chọn căn hộ!'
        if (infos.type !== 2 && !infos.user_info_id) return 'Vui lòng chọn người sử dụng!'
        if (!infos.type) return 'Vui lòng chọn loại thẻ!'
        if (!infos.code) return 'Vui lòng nhập mã thẻ!'
        if (!infos.number) return 'Vui lòng nhập số thẻ!'
        if (!infos.charge_date) return 'Vui lòng ngày bắt đầu tính phí!'
        if (infos.type === 2 && !infos.full_name) return 'Vui lòng nhập đủ họ và tên!'
        if (infos.type === 2 && !infos.phone) return 'Vui lòng nhập số điện thoại!'
        if (infos.type === 2 && !infos.cmt_number) return 'Vui lòng nhập số CCCD!'
        if (infos.type === 3 && !infos.type) return 'Vui lòng chọn loại phương tiện!'
        if (infos.type === 3 && !infos.number) return 'Vui lòng nhập biển số xe!'
        if (!id && (numberCard === 0 || info.type !== 1)) {
            let newInfo = info
            info = ""
            confirmDialog({
                message: `Căn hộ này đã hết lượt sử dụng thẻ miễn phí, nếu tiếp tục muốn thêm sẽ phải trả phí theo mức tòa nhà`,
                header: process.env.REACT_APP_BRANCH_NAME,
                icon: 'pi pi-info-circle',
                accept: async () => {
                    const response = await addCardApi(newInfo)
                    if (response.data.status) {
                        navigate('/resident_card_management')
                        dispatch(setToast({ ...listToast[0], detail: `Thêm thẻ cư dân thành công!` }))
                    } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
                },
            })
        }
        if (id) info = { ...removePropObject(info, cardDetail), id: Number(id) }
        return info
    }
    useEffect(() => {
        if (infos.type) {
            setInfos({ ...infos, permissions: [] })
        }
    }, [infos.type])

    return (
        <FormUpdate
            checkId={Number(id)}
            title="thẻ"
            handleData={handleData}
            route="/resident_card_management"
            actions={{ add: addCardApi, update: updateCardApi }}
            refreshObjects={[setInfos]}
        >
            <div
                className="font-left flex align-items-center mb-4 "
                style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
            >
                <b className="text-medium p-0">Thông tin thẻ</b>
            </div>
            <div className="grid grid-form">
                <div className="col-6">
                    <InputForm
                        value={infos.code}
                        onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                        label="Mã thẻ (*)"
                    />
                    <InputForm
                        value={infos.number}
                        onChange={(e) => setInfos({ ...infos, number: e.target.value })}
                        label="Số thẻ (*)"
                    />
                    <CalendarForm
                        value={infos.charge_date}
                        onChange={(e) => setInfos({ ...infos, charge_date: e.target.value })}
                        showTime
                        label="Ngày bắt đầu tính phí (*)"
                    />
                    <InputSwitchForm
                        label="Trạng thái"
                        id="status"
                        value={infos.status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        checked={infos.status}
                    />
                </div>
                <div className="col-6">
                    <DropdownForm
                        value={infos.type}
                        onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                        label="Loại thẻ (*)"
                        options={typeCard}
                        disabled={id}
                    />
                    <CalendarForm
                        value={infos.expired}
                        onChange={(e) => setInfos({ ...infos, expired: e.target.value })}
                        showTime
                        label="Hạn sử dụng"

                    />
                    {infos.type && infos.type === 3 &&
                        <>
                            <DropdownForm
                                value={infos.type_vehicle}
                                onChange={(e) => setInfos({ ...infos, type_vehicle: e.target.value })}
                                label="Loại phương tiện"
                                options={typeVehicle}
                            />
                            <InputForm
                                value={infos.number_vehicle}
                                onChange={(e) => setInfos({ ...infos, number_vehicle: e.target.value })}
                                label="Biển số xe (*)"
                            />
                        </>

                    }
                </div>
            </div>
            <div
                className="font-left flex align-items-center mb-4 "
                style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
            >
                <b className="text-medium p-0">Thông tin chủ sở hữu</b>
            </div>
            {infos.type && infos.type !== 2 ? (<div>
                <div className="">
                    <div className="grid grid-form">
                        <div className="col-6">
                            <DropdownForm
                                value={infos.apartment_id}
                                onChange={(e) => setInfos({ ...infos, apartment_id: e.target.value })}
                                options={apartments}
                                label="Căn hộ (*)"
                                disabled={id}
                            />
                            <CalendarForm
                                value={infos.card_receipt_date}
                                onChange={(e) => setInfos({ ...infos, card_receipt_date: e.target.value })}
                                showTime
                                label="Ngày nhận thẻ "
                            />
                        </div>
                        <div className="col-6">
                            <DropdownForm
                                value={infos.user_info_id}
                                onChange={(e) => setInfos({ ...infos, user_info_id: e.target.value })}
                                label="Người sử dụng (*)"
                                options={residents && residents.filter(r => r.apartment_id === infos.apartment_id)}
                                optionLabel='full_name'
                                // disabled={id}
                                emptyMessage={infos.apartment_id ? "Căn hộ chưa có người sử dụng. Vui lòng chọn căn hộ khác!" : "Vui lòng chọn căn hộ trước!"}
                            />
                        </div>
                    </div>
                </div>
            </div>) : (
                <>
                    <div className="grid grid-form">
                        <div className="col-6">
                            <InputForm
                                value={infos.full_name}
                                onChange={(e) => setInfos({ ...infos, full_name: e.target.value })}
                                label="Họ và tên (*)"
                            />
                            <InputForm
                                id="phone"
                                value={infos.phone}
                                onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                                label="Số điện thoại (*)"
                                type="phone"
                                required
                            />
                        </div>
                        <div className="col-6">
                            <InputForm
                                value={infos.cmt_number}
                                onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                                label="Số CCCD (*)"
                            />
                            <DropdownForm
                                value={infos.apartment_id}
                                onChange={(e) => setInfos({ ...infos, apartment_id: e.target.value })}
                                options={apartments}
                                label="Căn hộ (*)"
                                disabled={id}
                            />
                        </div>
                    </div>
                </>
            )}
        </FormUpdate>
    )
}

export default UpdateCard
