import { countLogImportApartmentApi, listLogImportApartmentApi } from '@/api'
import {
    Body,
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    TimeBody,
    useGetParams
} from '@/components'
import { statusLogImport, typeLogImport } from '@/constants'
import { useGetApi } from '@/hooks'
import { useState } from 'react'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({})

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Dropdownz
                value={filter.type}
                placeholder="Loại import"
                options={typeLogImport}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                placeholder="Trạng thái"
                options={statusLogImport}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                placeholder="Ngày import"
            />
        </GridForm>
    )
}

const Imports = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listLogImportApartmentApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countLogImportApartmentApi, { ...params, first: undefined }, 0)

    return (
        <div className="card">
            <HeaderListForm title="Lịch sử import căn hộ, cư dân" />
            <Header setParams={setParams} />
            <DataTablez
                title="lịch sử import căn hộ, cư dân"
                value={data}
                dataKey="_id"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/import_history"
                headerInfo
                actionsInfo
                basePermissions={["detail"]}
            >
                <Columnz body={(e) => Body({ data: typeLogImport, value: e.type })} header="Loại Import" />
                <Columnz field="import_by_name" header="Người tạo" />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz field="mess" header="Message" />
                <Columnz header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' body={(e) => Body({ data: statusLogImport, value: e.status })} />
            </DataTablez>
        </div>
    )
}

export default Imports
