import { CalendarForm, DropdownForm, FormUpdate, HeaderListForm, InputForm, InputNumberForm, UploadFiles, useGetParams } from '@/components'
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react'
import { useListWarehouse } from '../utils';
import { useListCategory, useListUnit } from '../../asset_managerment/utils';
import { listExchangeV2Api, listUserApi } from '@/api';
import { useGetApi } from '@/hooks';
import { createBill, listDepartmentWareHouse, listWarehouseV2 } from '../api';
import { useNavigate } from 'react-router-dom';
import { databaseDate } from '@/lib';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '@/redux/features'
import { listToast, product_category, statusExport, status_productv2, work_position } from '@/constants'
import { useListAsset, useListAssetMix } from '../../asset_mix_management/utils';
import { removeNullProps } from '@/utils'
import { listPosition } from '@/modules/hrm/position_management/api';
import { type } from '@testing-library/user-event/dist/type';
import { category } from './../../../../constants/main';

function CreateExportTicket() {
      const userInfo = useSelector((state) => state.userInfo)
      const users = useSelector((state) => state.users)
      const user_id = userInfo?.user_id
      const listDepartment = useGetApi(listDepartmentWareHouse)

      const defaultDepartment = listDepartment?.find(department => department.type === 9);

      const [infos, setInfos] = useState({ create_by: user_id, code: generateCode(), })
      useEffect(() => {
            setInfos({ ...infos, department_hand_over: defaultDepartment?.id })
      }, [listDepartment])

      function generateCode() {
            const currentTime = new Date();
            const formattedTime = `${currentTime.getFullYear()}${(currentTime.getMonth() + 1).toString().padStart(2, '0')}${currentTime.getDate().toString().padStart(2, '0')}_${currentTime.getHours().toString().padStart(2, '0')}${currentTime.getMinutes().toString().padStart(2, '0')}${currentTime.getSeconds().toString().padStart(2, '0')}`;
            return `PX_${formattedTime}`;
      }
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const unit = useListUnit()
      const [data, setData] = useState([])
      const [files, setFiles] = useState([])


      const dispatch = useDispatch()
      const person = useGetApi(listUserApi, { ...params, first: undefined, type: 'hrm' }, [])
      const listWareHouser = useGetApi(listWarehouseV2)
      const group = useListAssetMix({ status: undefined })
      const navigate = useNavigate()
      const position = useGetApi(listPosition, { ...params }, [])
      const handleChange = (value, field, index) => {
            setData(prevData => {
                  const newData = [...prevData];
                  newData[index] = { ...newData[index], [field]: value };
                  return newData;
            });
      };
      const handleDelete = (indexV2) => {
            if (indexV2 || indexV2 === 0) setData((pre) => pre.filter((d, index) => index !== indexV2))
      }
      const handleAdd = () => {
            const newItem = {};
            setData((prevData) => [...prevData, newItem]);
      }

      const handleData = async () => {
            let shouldSendToServer = true;
            if (!infos.create_date) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn thời gian xuất" }));
            if (!infos.department_hand_over) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn phòng ban bàn giao" }));
            if (!infos.code) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng nhập mã phiếu xuất" }));
            if (!infos.status_exp) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn trạng thái xuất" }));
            if (!infos.user_use) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người sử dụng" }));
            if (!infos.user_hand_over) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người bàn giao" }));
            if (infos.status_exp === 1 && !infos.department_receive) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn phòng ban tiếp nhận" }));
            if (infos.status_exp === 1 && !infos.user_receive) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người tiếp nhận" }));
            let cleanedInfo = { ...infos, create_date: databaseDate(infos.create_date, false, 'date') };
            cleanedInfo = removeNullProps(cleanedInfo);

            if (!data || data.length === 0) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn sản phẩm" }));
            if (data.some(d => d.product_id === null || d.product_id === undefined || d.product_id === '')) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn sản phẩm" }));
            if (data.some(d => d.unit_id === null || d.unit_id === undefined || d.unit_id === '')) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn đơn vị" }));
            const amountDict = {};
            listWareHouser.forEach(item => {
                  amountDict[item.id] = item.amount;
            });

            const checkAmount = data.some(item => {
                  const productId = item.product_id;
                  const dataAmount = item.amount;
                  const warehouserAmount = amountDict[item.id];
                  if (warehouserAmount !== undefined && dataAmount > warehouserAmount)
                  {
                        shouldSendToServer = false;
                        return true;
                  }
                  return false;
            });

            if (checkAmount)
            {
                  return dispatch(setToast({ ...listToast[1], detail: "Số lượng không được lớn hơn số lượng trong kho" }))
            }
            if (data.some(d => d.type === 'tieu_hao' && (!d.amount || d.amount > 100000)))
            {
                  return dispatch(setToast({ ...listToast[1], detail: "Số lượng không được để trống và phải nhỏ hơn hoặc bằng 100000 " }));
            }
            // if (data.some(d => d.type === 'tieu_hao' && (!d.amount || d.amount < 0))) {
            //       return dispatch(setToast({ ...listToast[1], detail: "Số lượng không được để trống và phải lớn hơn 0" }));
            // }
            if (files && files[0])
            {
                  cleanedInfo.file = files
            } else cleanedInfo.file = []
            const params = { ...cleanedInfo, type: 'exp', list_product: data };

            const call = await createBill(params);

            if (!call.data.status)
            {
                  dispatch(setToast({ ...listToast[1], detail: call.data.mess }));
            } else
            {
                  dispatch(setToast({ ...listToast[0], detail: "Tạo phiếu xuất thành công" }));
                  navigate('/warehouse_management');
            }
      }
      const userWithExChanges = person.filter(d => d.group_id === infos.department_hand_over)
      const userWithExChangesv2 = person.filter(d => d.group_id === infos.department_receive)

      // const matchingProducts = [];
      // let addedIds = new Set();

      // for (let i = 0; i < product.length; i++) {
      //       let productId = product[i].id;
      //       let unitId = product[i].unit_id;

      //       for (let j = 0; j < productor.length; j++) {
      //             if (productor[j].id === product[i].code_unit_product && !addedIds.has(productor[j].id)) {
      //                   matchingProducts.push({
      //                         ...productor[j],
      //                         unit_id: unitId
      //                   });
      //                   addedIds.add(productor[j].id);
      //                   break;
      //             }
      //       }
      // }

      // useEffect(() => {
      //       if (infos.status_exp && infos.status_exp === 2) {
      //             setInfos({ ...infos, department_receive: 0 })
      //       } else {
      //             setInfos({ ...infos, department_receive: null, user_receive: null, position_use: null, user_use: null })
      //       }
      // }, [infos.status_exp])
      const a = users.find(d => d.user_id === infos.create_by)
      const getProduct = () => {
            const productIds = data?.map(item => item.product_id);
            return listWareHouser?.filter(d => !productIds.includes(d.product_id));
      };
      return (
            <div

            >
                  <h3 >Tạo phiếu xuất</h3>

                  <div className="card">
                        <HeaderListForm title="Thông tin chung" />
                        <div className="grid">
                              <div className="col-6">
                                    <CalendarForm label="Thời gian xuất (*)"
                                          value={infos.create_date}
                                          onChange={(e) => setInfos({ ...infos, create_date: e.target.value })} />
                              </div>
                              <div className="col-6">
                                    <InputForm label="Mã phiếu xuất (*)"
                                          value={infos.code}
                                          // onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                          disabled
                                    />
                              </div>
                              <div className="col-6">
                                    <DropdownForm label="Trạng thái xuất (*)"
                                          value={infos.status_exp}
                                          onChange={(e) => { setInfos({ ...infos, status_exp: e.target.value, }) }}
                                          options={statusExport}
                                    />
                              </div>
                              {a && <div className="col-6">
                                    <DropdownForm label="Người tạo phiếu (*)"
                                          value={infos.create_by}
                                          onChange={(e) => setInfos({ ...infos, create_by: e.target.value })}
                                          disabled
                                          options={users}
                                          optionLabel='full_name'
                                          optionValue='user_id'
                                    />
                              </div>}
                              <div className="col-6">
                                    <UploadFiles files={files} setFiles={setFiles} />
                              </div>

                        </div>
                        <HeaderListForm title="Thông tin bàn giao" />
                        <div className="grid">
                              <div className="col-6">
                                    <DropdownForm label="Phòng ban bàn giao (*)"
                                          value={infos.department_hand_over}
                                          options={listDepartment}
                                          disabled
                                    // onChange={(e) => setInfos({ ...infos, department_hand_over: e.target.value })}
                                    />
                              </div>
                              <div className="col-6 grid">
                                    <div className="col-8">
                                          <DropdownForm
                                                options={userWithExChanges}
                                                label="Người bàn giao (*)"
                                                optionLabel="full_name"
                                                value={infos.user_hand_over}
                                                onChange={(e) => {
                                                      const selectedUser = userWithExChanges.find(user => user.id === parseInt(e.target.value));
                                                      setInfos({ ...infos, user_hand_over: e.target.value, mnv: selectedUser.code });
                                                }} />
                                    </div>
                                    <div className="col-4">
                                          <InputForm
                                                label="Mã nhân viên (*)"
                                                value={infos.mnv}
                                                onChange={(e) => setInfos({ ...infos, mnv: e.target.value })}
                                                disabled
                                          />
                                    </div>
                              </div>
                              <div className="col-6">
                                    <DropdownForm label="Người phê duyệt"
                                          value={infos.user_confirm
                                          }
                                          optionLabel="full_name"
                                          options={userWithExChanges}
                                          onChange={(e) => setInfos({
                                                ...infos, user_confirm: e.target.value
                                          })} />
                              </div>
                              {/*<div className="col-6">*/}
                              {/*      <DropdownForm label="Trạng thái "*/}
                              {/*            value={infos.status_product*/}
                              {/*            }*/}

                              {/*            options={status_productv2}*/}
                              {/*            onChange={(e) => setInfos({*/}
                              {/*                  ...infos, status_product*/}
                              {/*                        : e.target.value*/}
                              {/*            })} />*/}
                              {/*</div>*/}
                        </div>
                        {infos.status_exp === 1 && <>
                              <HeaderListForm title="Thông tin tiếp nhận" />
                              <div className="grid">
                                    <div className="col-6">
                                          <DropdownForm label="Phòng ban tiếp nhận (*)"
                                                value={infos.department_receive}
                                                options={listDepartment}
                                                onChange={(e) => setInfos({ ...infos, department_receive: e.target.value })} />
                                    </div>
                                    <div className="col-6 grid">
                                          <div className="col-8">
                                                <DropdownForm label="Người tiếp nhận (*)"
                                                      value={infos.user_receive}
                                                      options={userWithExChangesv2}
                                                      optionLabel="full_name"
                                                      onChange={(e) => {
                                                            const selectedUser = userWithExChangesv2.find(user => user.id === parseInt(e.target.value));
                                                            const position = selectedUser && selectedUser.bills[0] ? selectedUser.bills[0].position : '';
                                                            setInfos({ ...infos, user_receive: e.target.value, position: position });
                                                      }}
                                                />
                                          </div>
                                          <div className="col-4">
                                                <DropdownForm label="Chức vụ "
                                                      value={infos.position}
                                                      options={position}
                                                      disabled
                                                      onChange={(e) => setInfos({ ...infos, position: e.target.value })} />
                                          </div>
                                    </div>

                                    <div className="col-6">
                                          <InputForm label="Vị trí sử dụng"
                                                value={infos.position_use
                                                }
                                                onChange={(e) => setInfos({
                                                      ...infos, position_use
                                                            : e.target.value
                                                })} />
                                    </div>
                                    <div className="col-6 grid">
                                          <div className="col-8">
                                                <DropdownForm label="Người sử dụng (*)"
                                                      value={infos.user_use
                                                      }
                                                      options={userWithExChangesv2}
                                                      optionLabel="full_name"
                                                      onChange={(e) => {
                                                            const selectedUser = userWithExChangesv2.find(user => user.id === parseInt(e.target.value));
                                                            const position = selectedUser && selectedUser.bills[0] ? selectedUser.bills[0].position : '';
                                                            setInfos({ ...infos, user_use: e.target.value, positionv2: position });
                                                      }}
                                                />
                                          </div>
                                          <div className="col-4">
                                                <DropdownForm label="Chức vụ"
                                                      value={infos.positionv2}
                                                      options={position}
                                                      disabled
                                                      onChange={(e) => setInfos({ ...infos, position: e.target.value })} />
                                          </div>
                                    </div>
                              </div>
                        </>}
                        <HeaderListForm title="Thông tin sản phẩm xuất kho" />
                        <div className="grid grid-form align-items-center pt-3">
                              {data.map((d, index) => {
                                    const optionv2 = getProduct()?.concat(listWareHouser.filter((p) => p.product_id === d.product_id));
                                    return (
                                          <div key={index} className="grid grid-form w-full  align-items-center mx-1 card">
                                                <div className="col-12 lg:col-3">
                                                      <DropdownForm
                                                            label="Tên sản phẩm (*)"
                                                            optionLabel="name"
                                                            options={optionv2}
                                                            value={d.id
                                                            }
                                                            optionValue="id"
                                                            onChange={(e) => {
                                                                  handleChange(e.target.value, 'productor', index, setData(prevData => {
                                                                        const selectedProductId = e.target.value;
                                                                        const value = listWareHouser.find(d => d.id === selectedProductId)
                                                                        const findType = group.find(d => d.id === value?.product_group_id)
                                                                        const newData = [...prevData];
                                                                        newData[index] = {
                                                                              product_id: value?.product_id,
                                                                              code: value?.code,
                                                                              type: findType?.type,
                                                                              product_group_id: value?.product_group_id,
                                                                              unit_id: value?.unit_id,
                                                                              status: value?.status,
                                                                              id: value?.id
                                                                        };
                                                                        return newData;
                                                                  }))

                                                            }
                                                            }

                                                      />
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      <InputForm
                                                            label="MSP (*)"
                                                            value={d.code}
                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      <InputForm
                                                            label="Phân loại (*)"
                                                            value={(() => {
                                                                  const category = product_category.find(item => item.id === d.type);
                                                                  return category ? category.name : "";
                                                            })()}
                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      <DropdownForm
                                                            label="Nhóm (*)"
                                                            options={group}
                                                            value={d.product_group_id}
                                                            disabled

                                                      />
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      <DropdownForm
                                                            label="Đơn vị (*)"
                                                            value={d.unit_id}
                                                            disabled
                                                            options={unit}
                                                      />
                                                </div>
                                                {d.type === 'tieu_hao' ? <div className="col-12 lg:col-3">
                                                      <InputNumberForm
                                                            label="Số lượng (*)"
                                                            value={d.amount}
                                                            handleOnChange={(e) => handleChange(e, 'amount', index)}
                                                            required
                                                      />
                                                </div> : ''}

                                                <div className="col-12 lg:col-3">
                                                      <InputForm
                                                            label="Mô tả"
                                                            value={d.detail}
                                                            onChange={(e) => handleChange(e.target.value, 'detail', index)}
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      <Button
                                                            type="button"
                                                            raised
                                                            icon="pi pi-times"
                                                            severity="danger"
                                                            disabled={data.length <= 1}
                                                            onClick={() => handleDelete(index)
                                                            }
                                                      />
                                                </div>
                                          </div>
                                    )
                              })}
                        </div>

                        <Button
                              type="button"
                              raised
                              icon="pi pi-plus"
                              severity="info"
                              onClick={() => handleAdd()}
                              className="ml-2"
                              label='Thêm sản phẩm'
                        />
                  </div>


                  <div className='flex justify-content-end gap-3 pt-3'>
                        <Button label='Trở về' onClick={() => navigate('/warehouse_management')} severity='danger' />
                        <Button label='Tạo phiếu xuất' onClick={handleData} />
                  </div>
            </div>
      )
}

export default CreateExportTicket
