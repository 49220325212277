import { TabPanel, TabView } from 'primereact/tabview'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import AssetGeneralInformation from './AssetGeneralInformation'



export default function UpdateAsset() {
      const [showMore, setShowMore] = useState(false)
      const { id } = useParams()
      return (

            <div className="card">
                  <TabView>
                        <TabPanel header="Thông tin chung">
                              <AssetGeneralInformation setShowMore={setShowMore} />
                        </TabPanel>
                  </TabView>
            </div>
      )
}