import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, useGetParams } from '@/components'
import { prices } from '@/constants'
import { useState } from 'react'
import { deletePriceApi, exportPriceApi } from '../api'
import { useCountPrice, useListPrice } from '../utils'
import ImportPrice from './ImportPrice'
import {useGetApi} from "@/hooks";
import {listTypePriceApi} from "@/api/projects/info";

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ name: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.name}
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                placeholder="Tìm kiếm theo tên bảng giá"
            />
            <Dropdownz
                value={filter.type}
                options={[{ name: "Dịch vụ", id: 1 }, { name: "Phương tiện", id: 2 }, { name: 'Phí phát sinh', id: 3 }]}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại dịch vụ"
            />
        </GridForm>
    )
}

const ListPrice = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPrice({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPrice({ status: undefined, ...params, first: undefined }) || 0
    const [visibleImport, setVisibleImport] = useState(false)
    const typePrices = useGetApi(listTypePriceApi, false, [])
    return (
        <div className="card">
            {visibleImport && <ImportPrice visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            <HeaderListForm title="Bảng giá" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bảng giá"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/list_price"
                actionsInfo={{ deleteAction: deletePriceApi }}
                headerInfo={{ setVisibleImport: setVisibleImport, exportApi: exportPriceApi }}
                basePermissions={["insert", "detail", "delete", "import", "export"]}
            >
                <Columnz field="code" header="Mã bảng giá" />
                <Columnz field="name" header="Tên bảng giá" />
                <Columnz body={(e) => Body({ data: prices, value: e.bdc_price_type_id })} header="Loại giá" />
                <Columnz body={(e) => Body({ data: typePrices, value: e.price_type_id })} header="Loại" />
            </DataTablez>
        </div>
    )
}

export default ListPrice
