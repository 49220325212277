import { getData, postData } from '@/lib/request'

export const listApartmentServiceApi = (params) => getData('web2/service_apartment/getListServiceApartment', params)
export const countApartmentServiceApi = (params) => getData('web2/service_apartment/countServiceApartment', params)
export const deleteApartmentServiceApi = (params) => postData('web2/service_apartment/delServiceApartment', params)
export const detailApartmentServiceApi = (params) => getData('web2/service_apartment/getDetailServiceApartment', params)
export const addApartmentServiceApi = (params) => postData('web2/service_apartment/addServiceApartment', params)
export const updateApartmentServiceApi = (params) => postData('web2/service_apartment/updateServiceApartment', params)
export const updateStatusApartmentServiceApi = (params) =>
    postData('web2/service_apartment/updateStatusServiceApartment', params)
export const listServiceApi = (params) => getData('web2/info/getListServiceV2', params)
export const listServiceV2Api = (params) => getData('web2/info/getListService', params)
export const detailProgressiveApi = (params) => getData('web2/info/getDetailProgressivePriceByService', params)
export const importServiceApartment = (params) => postData('web2/service_apartment/importServiceApartment', params, false, true)
export const exportServiceApartmentApi = (params) => getData('web2/service_apartment/expListServiceApartment', params, true)
