import { Body, Columnz, DataTablez, Dropdownz, GridForm, LoadDialog, useGetParams } from '@/components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { deletePromotionApartApi, listPromotionV2Api } from '../api'
import {
    useCountPromotionApart,
    useDetailPromotionApart,
    useListPromotionApart,
    useListSevicesId,
    useListPromotion,
} from '../utils'
import UpdateApartmentPromotion from './UpdateApartmentPromotion'
import { useGetApi } from '@/hooks'
import ImportPromotionApa from './ImportPromotionApa'

const Header = ({ setParams, promotions }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.promotion_id}
                options={promotions}
                onChange={(e) => setFilter({ ...filter, promotion_id: e.target.value })}
                placeholder="Ưu đãi"
            />
        </GridForm>
    )
}
const ApartmentPromotionManage = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPromotionApart({ status: undefined, ...params, first: undefined })
    const dataSevices = useListSevicesId()
    const apartments = useSelector((state) => state.apartments)
    const users = useSelector((state) => state.users)
    const promotions = useGetApi(listPromotionV2Api, {}, [])

    const totalRecords = useCountPromotionApart({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)
    const { id } = useParams()
    const promotionManageDetail = useDetailPromotionApart(id)
    const [visibleImport, setVisibleImport] = useState(false)
    const [infos, setInfos] = useState({
        service: '',
        apart_service: '',
        promotion: '',
        semester: '',
    })
    useEffect(() => {
        if (promotionManageDetail)
            setInfos({ ...infos, ...promotionManageDetail, status: promotionManageDetail.status === 0 ? false : true })
    }, [promotionManageDetail])

    const apartmentsMap = new Map(apartments.map((apartment) => [apartment.id, apartment]))
    const getApartmentName = (apartmentId) => {
        const apartment = apartmentsMap.get(apartmentId)
        return apartment ? apartment.name : ''
    }

    return (
        <div className="card">
            <LoadDialog visible={visible} />
            {visible && <UpdateApartmentPromotion setParams={setParams} visible={visible} setVisible={setVisible} />}
            {visibleImport && <ImportPromotionApa visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            <Header setParams={setParams} promotions={promotions} />
            <DataTablez
                value={data}
                title="ưu đãi căn hộ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/promotion_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deletePromotionApartApi }}
                headerInfo={{ setVisibleImport: setVisibleImport }}
                basePermissions={["insert", "detail", "delete", "import"]}
            >
                <Columnz
                    field="promotion_id"
                    header="Tên ưu đãi"
                    body={(e) =>
                        Body({
                            data: promotions,
                            value: e.promotion_id,
                        })
                    }
                />
                <Columnz
                    field="apartment_id"
                    header="Căn hộ"
                    body={(rowData) => getApartmentName(rowData.apartment_id)}
                />
                <Columnz
                    field="service_price_id"
                    header="Dịch vụ"
                    body={(e) => Body({ data: dataSevices, value: e.service_price_id })}
                />
                <Columnz field="address" header="Sản phẩm" />
                <Columnz field="cycle_name" header="Kỳ" />
                <Columnz body={e => {
                    const promotion = promotions.find(p => e.promotion_id === p.id)
                    if (promotion) return <span>{(promotion.number_discount || "") + (promotion.type_discount ? "%" : "VNĐ")}</span>
                }} header="ưu đãi" />
                <Columnz
                    body={(e) => Body({ data: users, value: e.by, key: 'user_id', label: 'full_name' }) || "Admin"}
                    header="Người tạo"
                />
                <Columnz field="created_at" header="Ngày tạo" />
            </DataTablez>
        </div>
    )
}

export default ApartmentPromotionManage
