import { Body, Calendarz, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { processingType, statusLogImport } from '@/constants'
import { useState } from 'react'
import { useCountLogDebt, useListLogDebt } from '../utils'
import UpdateDebtImportHistory from './UpdateDebtImportHistory'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Dropdownz
                value={filter.type}
                options={processingType}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại"
            />
            <Calendarz
                value={filter.dates}
                className=""
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                placeholder="Khoảng..."
                selectionMode="multiple"
            />
        </GridForm>
    )
}

const DebtImportHistory = () => {
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState(initParam)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useListLogDebt({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountLogDebt({ status: undefined, ...params, first: undefined }) || 0
    const items = [
        {
            label: 'Chạy lại công nợ',
            icon: 'pi pi-check',
            title: 'form yêu cầu',
            actionType: 'active',
        },
    ]

    return (
        <div className="card">
            {visible && <UpdateDebtImportHistory
                setParams={setParams}
                visible={visible}
                setVisible={setVisible}
                data={data}
            />}
            <HeaderListForm title="Lịch sử import file" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="lịch sử import"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                setVisibledDialog={setVisible}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                route="/service_apartment"
                actionsInfo
                dataKey="_id"
                headerInfo={{ items }}
                basePermissions={['detail']}
            >
                <Columnz field="name" header="Tên" />
                <Columnz body={(e) => Body({ data: processingType, value: e.type })} header="Kiểu xử lý" />
                <Columnz
                    header="Thành công"
                    body={(e) =>
                        e.success || e.success === 0 ? (
                            <span
                                className="text-center font-bold text-white text-xs"
                                style={{
                                    backgroundColor: statusLogImport[1].color,
                                    borderRadius: '4px',
                                    padding: '4px',
                                    minWidth: '100px',
                                    display: 'inline-block',
                                }}
                            >
                                {e.success}
                            </span>
                        ) : null
                    }
                />
                <Columnz
                    header="Thất bại"
                    body={(e) =>
                        e.error || e.error === 0 ? (
                            <span
                                className="text-center font-bold text-white text-xs"
                                style={{
                                    backgroundColor: statusLogImport[0].color,
                                    borderRadius: '4px',
                                    padding: '4px',
                                    minWidth: '100px',
                                    display: 'inline-block',
                                }}
                            >
                                {e.error}
                            </span>
                        ) : null
                    }
                />
            </DataTablez>
        </div>
    )
}

export default DebtImportHistory
