import { DropdownForm, FormUpdateDialog, InputForm, InputSwitchForm } from '@/components'
import { product_category } from '@/constants'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { addProductGroupApi, updateProductGroupApi } from '../api'
import { useDetailAssetMix, useListAssetMix } from '../utils'

function UpdateAssetMixGroup(props) {
      const { setVisible, setParams, visible } = props
      const detail = useDetailAssetMix(typeof visible === 'number' ? visible : undefined)
      const [infos, setInfos] = useState({ status: true })
      useEffect(() => {
            if (detail.id) {
                  setInfos({
                        ...infos,
                        ...detail,
                        status: Number(detail.status) !== 0,
                  })
            }
      }, [detail])



      const handleData = () => {
            if (!infos.type) return "Vui lòng chọn phân loại sản phẩm"
            if (infos.name?.length >= 100) return "Tên nhóm không nhập quá 100 ký tự"
            if (infos.note?.length >= 100) return "Mô tả không nhập quá 100 ký tự"


            let info = {
                  ...infos,
                  status: infos.status ? 1 : 0,
            }
            if (typeof visible === 'number')
                  info = { ...removePropObject(info, detail), id: detail.id }
            return info
      }
      return (
            <FormUpdateDialog
                  title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + ' nhóm sản phẩm'}
                  visible={visible}
                  setVisible={setVisible}
                  setParams={setParams}
                  refreshObjects={[setInfos]}
                  handleData={handleData}
                  actions={{ add: addProductGroupApi, update: updateProductGroupApi }}
            >
                  <InputForm
                        label="Tên nhóm sản phẩm (*)"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        required
                  />
                  <DropdownForm
                        options={product_category}
                        optionValue="id"
                        onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                        value={infos.type}
                        label="Phân loại sản phẩm (*)" />
                  <InputForm
                        label="Mô tả"
                        value={infos.note}
                        onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                  />
                  <InputSwitchForm
                        label="Trạng thái"
                        id="status"
                        value={infos.status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        checked={infos.status}
                  />
            </FormUpdateDialog>
      )
}

export default UpdateAssetMixGroup
