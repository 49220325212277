import {
    CalendarForm,
    DropdownForm,
    FormUpdate,
    InputForm,
    InputNumberForm,
    InputSwitchForm,
    InputTextareaForm, InputVehicleNumberForm, UploadMultiImage
} from '@/components'
import { type_vehicles } from '@/constants'
import { databaseDate } from '@/lib'
import { clearVehicleInfo } from '@/redux/features/vehicleInfo'
import { checkJson, removeUndefinedProps } from '@/utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useDetailProgressive } from '../../aparment_services/utils'
import { addVehicleApi, updateVehicleApi } from '../api'
import { useDetailVehicle, useDetailVehicleOrder } from '../utils'

const UpdateVehicle = () => {
    const { id } = useParams()
    const vehicleDetail = useDetailVehicle(id)
    const generateFormattedDate = () => {
        return moment().format('YYYYMMDD_HHmmss');
    }
    const generateCode = () => {
        const formattedDate = generateFormattedDate();
        return `VC_${formattedDate}`;
    }

    const [infos, setInfos] = useState({ status: true, code: generateCode() });
    const [images, setImages] = useState([])
    const [vehicleRegistration, setVehicleRegistration] = useState([])
    const dispatch = useDispatch()
    const [insurancePhoto, setInsurancePhoto] = useState([])
    const buildings = useSelector((state) => state.buildings)
    const apartments = useSelector((state) => state.apartments)
    const residents = useSelector((state) => state.residents)
    const services = useSelector((state) => state.services)

    const sp = useDetailProgressive(infos.service_id)
    const detailVehicleOrder = useDetailVehicleOrder({
        apartment_id: infos.bdc_apartment_id,
        bdc_service_id: infos.service_id,
    })

    const vehicleInfo = useSelector((state) => state.vehicleInfo)

    const order = detailVehicleOrder + 1
    const [priceList, setPriceList] = useState([])
    const handleServiceChange = (e) => {
        const serviceId = e.target.value
        const service = services.find((s) => s.id === serviceId)
        if (service) {
            setInfos({ ...infos, service_id: serviceId, vehicle_category_id: service.type_vehicle })
        }
    }
    useEffect(() => {
        if (sp !== null && sp.list_price && sp.list_price.length > 0) {
            setPriceList(sp.list_price)
        } else {
            setPriceList(null)
        }
    }, [sp])
    const apartment = apartments.find((a) => a.id === vehicleDetail.bdc_apartment_id)
    useEffect(() => {
        const vehicle = services.find(d => d.id === vehicleDetail?.service_id)
        const a = type_vehicles.find(vehicleType => vehicleType.id === vehicle?.type_vehicle)
        if (vehicleDetail.id) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(vehicleDetail),
                list_user_id: vehicleDetail.list_user_id ? JSON.parse(vehicleDetail.list_user_id) : [],
                building_id: apartment?.building_place_id || undefined,
                bdc_apartment_id: vehicleDetail.bdc_apartment_id || undefined,
                status: vehicleDetail.status === 1,
                first_time_active: vehicleDetail.first_time_active ? new Date(vehicleDetail.first_time_active) : undefined,
                finish: infos.finish || (vehicleDetail.finish ? new Date(vehicleDetail.finish) : undefined),
                vehicle_category_id: a?.id
            })

            if (vehicleDetail.images && vehicleDetail.images !== "[\"[\",\"]\"]") {
                const newImages = checkJson(vehicleDetail.images) || vehicleDetail.images
                if (typeof newImages === "object" && newImages && newImages[0]) {
                    setImages(newImages)
                }

            }
            if (vehicleDetail.vehicle_registration && vehicleDetail.vehicle_registration !== "[\"[\",\"]\"]") {
                const newVehicleRegistration =
                    checkJson(vehicleDetail.vehicle_registration) || vehicleDetail.vehicle_registration
                if (typeof newVehicleRegistration === "object" && newVehicleRegistration && newVehicleRegistration[0]) {
                    setVehicleRegistration(newVehicleRegistration)
                }
            }
            if (vehicleDetail.insurance_photo && vehicleDetail.insurance_photo !== "[\"[\",\"]\"]") {
                const newInsurancePhoto = checkJson(vehicleDetail.insurance_photo) || vehicleDetail.insurance_photo
                if (typeof newInsurancePhoto === "object" && newInsurancePhoto && newInsurancePhoto[0]) {
                    setInsurancePhoto(newInsurancePhoto)
                }
            }


        }

    }, [vehicleDetail, vehicleInfo])

    useEffect(() => {
        if (vehicleInfo) {
            if (!vehicleInfo.id_vehicle) {
                const resident = residents.find(r => r.user_id === Number(vehicleInfo.user_info_id))
                let list_user_id = []
                if (vehicleInfo.list_user_id && Array.isArray(vehicleInfo.list_user_id) && vehicleInfo.list_user_id[0]) {
                    vehicleInfo.list_user_id.forEach(v => {
                        const resident = residents.find(r => r.user_id === Number(v))
                        if (resident && resident.id) list_user_id.push(resident.id)
                    })
                }
                setInfos({
                    ...infos,
                    ...removeUndefinedProps(vehicleInfo),
                    registration_address: vehicleInfo.registration_address,
                    description: vehicleInfo.description,
                    machine_number: vehicleInfo.machine_number,
                    frame_number: vehicleInfo.frame_number,
                    number: vehicleInfo.number_vehicles,
                    building_id: apartments.find((a) => a.id === resident?.apartment_id)?.building_place_id,
                    bdc_apartment_id: resident.apartment_id,
                    first_time_active: vehicleInfo.date_begin ? new Date(vehicleInfo.date_begin) : undefined,
                    vehicle_category_id: Number(vehicleInfo.type_vehicles),
                    user_id: resident.id,
                    status: vehicleInfo.status === 1 ? true : false,
                    list_user_id,
                    additional: vehicleInfo.additional
                })
                if (Array.isArray(vehicleInfo.image_vehicles) && vehicleInfo.image_vehicles) {
                    setImages(vehicleInfo.image_vehicles)
                }
                if (Array.isArray(vehicleInfo.image_reg_vehicles) && vehicleInfo.image_reg_vehicles) {
                    setVehicleRegistration(vehicleInfo.image_reg_vehicles)
                }
                if (Array.isArray(vehicleInfo.image_bao_hiem) && vehicleInfo.image_bao_hiem) {
                    setInsurancePhoto(vehicleInfo.image_bao_hiem)
                }
                dispatch(clearVehicleInfo())
            } else {
                setInfos({ ...infos, finish: new Date(vehicleInfo.extended) })
                dispatch(clearVehicleInfo())
            }
        }
    }, [vehicleInfo])

    const handleData = () => {
        let info = {
            ...infos,
            status: infos.status ? 1 : 0,
            first_time_active: infos.first_time_active && databaseDate(infos.first_time_active, false, 'date'),
            finish: infos.finish && databaseDate(infos.finish, false, 'date'),
        }
        if (info.number === vehicleDetail.number) {
            info.number = undefined
        }
        if (images && images[0]) info.images = images
        else info.images = []
        if (vehicleRegistration && vehicleRegistration[0]) info.vehicle_registration = vehicleRegistration
        else info.vehicle_registration = []
        if (insurancePhoto && insurancePhoto[0]) info.insurance_photo = insurancePhoto
        else info.insurance_photo = []
        if (!infos.building_id) return 'Vui lòng chọn tòa nhà!'
        if (!info.bdc_apartment_id) return 'Vui lòng chọn căn hộ!'
        if (
            infos.first_time_active &&
            new Date(infos.first_time_active).getTime() !== infos.first_time_active.getTime()
        ) {
            info.first_time_active = moment(infos.first_time_active).format('YYYY-MM-DD')
        }
        if (infos.finish && new Date(infos.finish).getTime() !== infos.finish.getTime()) {
            info.finish = moment(infos.finish).format('YYYY-MM-DD')
        }
        if (!infos.number) return 'Vui lòng nhập biển số xe!'
        if (infos.number === vehicleDetail.number) {
            info.number = undefined
        }
        if (!infos.service_id) return 'Vui lòng chọn dịch vụ!'
        if (!info.first_time_active || info.first_time_active === 'Invalid date')
            return 'Vui lòng chọn ngày bắt đầu tính phí!'
        if (!info.finish || info.finish === 'Invalid date') {
            info.finish = undefined
        }
        if (new Date(infos.first_time_active) >= new Date(infos.finish))
            return 'Ngày áp dụng không được lớn hơn hoặc bằng ngày kết thúc!'
        if (info.description === '' || info.description === null) {
            info.description = undefined
        }
        return info
    }
    const filterPriceByOrder = (order, priceList) => {
        if (priceList && priceList.length > 0) {
            const sortedPriceList = priceList.sort((a, b) => a.from - b.from)
            const matchedPrice = sortedPriceList.find((price) => order >= price.from && order <= price.to)
            return matchedPrice || sortedPriceList[sortedPriceList.length - 1]
        } else {
            return null
        }
    }
    const selectedPrice = filterPriceByOrder(order, priceList)
    return (
        <FormUpdate
            checkId={Number(id)}
            title="phương tiện"
            handleData={handleData}
            route="/vehicle_manage"
            actions={{ add: addVehicleApi, update: updateVehicleApi }}
        >
            <div className="card bg-color">
                <div
                    className="font-left flex align-items-center mb-4"
                    style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                >
                    <b className="text-2xl p-0">Thông tin chung</b>
                </div>
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6  ">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên phương tiện (*)"
                            required
                        />
                        <DropdownForm
                            id="service_id"
                            value={infos.service_id}
                            options={services.filter((s) => s.type === 4)}
                            onChange={(e) => handleServiceChange(e)}
                            label="Dịch vụ phương tiện (*)"
                        />
                        <DropdownForm
                            id="building_id"
                            value={infos.building_id}
                            options={buildings}
                            onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                            label="Tòa nhà (*)"
                        />
                        <DropdownForm
                            id="bdc_apartment_id"
                            value={infos.bdc_apartment_id}
                            label="Căn hộ (*)"
                            options={apartments.filter((a) => a.building_place_id === infos.building_id)}
                            disabled={apartment}
                            onChange={(e) =>
                                setInfos({ ...infos, bdc_apartment_id: e.target.value, user_id: undefined })
                            }
                        />
                    </div>
                    <div className="col-12 lg:col-6  ">
                        <DropdownForm
                            id="user_id"
                            value={infos.user_id}
                            label="Chủ phương tiện"
                            options={residents.filter((r) => r.apartment_id === infos.bdc_apartment_id)}
                            optionLabel='full_name'
                            optionValue='user_id'
                            onChange={(e) => setInfos({ ...infos, user_id: e.target.value })}
                        />
                        {/* <MultiSelectForm
                            id="list_user_id"
                            value={infos.list_user_id}
                            label="Người sử dụng"
                            options={residents.filter((r) => r.apartment_id === infos.bdc_apartment_id)}
                            optionLabel={'full_name'}
                            onChange={(e) => setInfos({ ...infos, list_user_id: e.target.value })}
                        /> */}
                        <CalendarForm
                            dateFormat="dd/mm/yy"
                            value={infos.first_time_active}
                            onChange={(e) => setInfos({ ...infos, first_time_active: e.target.value })}
                            label="Ngày áp dụng tính phí (*)"
                        />
                        <CalendarForm
                            dateFormat="dd/mm/yy"
                            value={infos.finish}
                            onChange={(e) => setInfos({ ...infos, finish: e.target.value })}
                            label="Ngày kết thúc tính phí"
                        />
                    </div>
                </div>
                <div
                    className="font-left flex align-items-center mb-4"
                    style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                >
                    <b className="text-2xl p-0">Thông tin chi tiết</b>
                </div>
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="vehicle_category_id"
                            value={infos.vehicle_category_id}
                            options={type_vehicles}
                            onChange={(e) => setInfos({ ...infos, vehicle_category_id: e.target.value })}
                            label="Loại phương tiện"
                            disabled
                        />
                        <InputForm
                            id="name"
                            value={infos.frame_number}
                            onChange={(e) => setInfos({ ...infos, frame_number: e.target.value })}
                            label="Số khung"
                        />
                        <InputForm
                            id="name"
                            value={infos.machine_number}
                            onChange={(e) => setInfos({ ...infos, machine_number: e.target.value })}
                            label="Số máy"
                        />
                        <InputForm
                            id="name"
                            value={infos.registration_address}
                            onChange={(e) => setInfos({ ...infos, registration_address: e.target.value })}
                            label="Địa chỉ đăng ký xe"
                        />
                        <InputVehicleNumberForm
                            id="number"
                            value={infos.number}
                            handleOnChange={(e) => setInfos({ ...infos, number: e })}
                            label="Biển số xe (*)"
                            required
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="code"
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã thẻ"
                            disabled
                        />
                        {infos.vehicle_category_id === 2 &&
                            <InputForm label="Phân khối" onChange={(e) => {
                                setInfos({ ...infos, additional: e.target.value })
                            }}
                                value={infos.additional}
                            />}
                        {infos.vehicle_category_id === 3 && <InputForm label="Loại xe" onChange={(e) => {
                            setInfos({ ...infos, additional: e.target.value })
                        }}
                            value={infos.additional} />}

                        <InputTextareaForm
                            id="description"
                            value={infos.description}
                            onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                            label="Mô tả"
                        />

                        {id && <InputSwitchForm
                            label="Trạng thái"
                            id="status"
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                            checked={infos.status}
                        />}
                    </div>
                </div>
                {sp && sp.bdc_price_type_id === 1 && priceList ? (
                    <>
                        <div
                            className="font-left flex align-items-center mb-4"
                            style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                        >
                            <b className="text-2xl p-0">Phí</b>
                        </div>
                        <InputNumberForm
                            id="servicePrice"
                            value={priceList && priceList[0].price}
                            label="Mức phí được áp dụng"
                            disabled={true}
                        />
                    </>
                ) : sp && sp.bdc_price_type_id === 2 && priceList ? (
                    <>
                        <div
                            className="font-left flex align-items-center mb-4"
                            style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                        >
                            <b className="text-2xl p-0">Bảng giá</b>
                        </div>
                        {priceList.map((item, index) => (
                            <div className="grid grid-form" key={index}>
                                <div className="col-3 lg:col-3">
                                    <InputNumberForm value={item.from} label="Từ" disabled={true} />
                                </div>
                                <div className="col-3 lg:col-3">
                                    <InputNumberForm value={item.to} label="Đến" disabled={true} />
                                </div>
                                <div className="col-3 lg:col-3">
                                    <InputNumberForm
                                        id="servicePrice"
                                        value={item.price}
                                        label="Đơn giá"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        ))}
                        <InputNumberForm
                            id="servicePrice"
                            value={selectedPrice.price}
                            label="Mức phí được áp dụng"
                            disabled={true}
                        />
                    </>
                ) : undefined}
                <div
                    className="font-left flex align-items-center mb-4"
                    style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                >
                    <b className="text-2xl p-0"></b>
                </div>
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6  ">
                        <UploadMultiImage label="Ảnh phương tiện" images={images} setImages={setImages} />
                        <UploadMultiImage
                            label="Ảnh giấy tờ xe"
                            images={vehicleRegistration}
                            setImages={setVehicleRegistration}
                        />
                    </div>
                    <div className="col-12 lg:col-6  ">
                        <UploadMultiImage
                            label="Ảnh bảo hiểm xe"
                            images={insurancePhoto}
                            setImages={setInsurancePhoto}
                        />
                    </div>
                </div>
            </div>
        </FormUpdate>
    )
}
export default UpdateVehicle
