import { postData, getData } from '@/lib/request'
import {getDataV2} from "@/lib/axios";

export const listAggregateDetail = (params) => getDataV2('web2/aggregate_debt_details/getListAggregateDebtDetail', params)
export const totalAggreateDetail = (params) => getData('/web2/aggregate_debt_details/getAggregateDebtDetailSumary', params)
export const countAggregateDebtDetails = (params) => getData('web2/aggregate_debt_details/countAggregateDebtDetail', params)
export const detailCompanyApi = (params) => getData('web2/company/getDetailCompany', params)
export const exportDebtDetailApi = (params) => getData('web2/aggregate_debt_details/expListDebitDetail', params, true)
export const exportDebtDetailByApartmentApi = (params) => getData('web2/aggregate_debt_details/expListDebitDetailByApartment', params, true)
export const exportDebtDetailByServiceApi = (params) => getData('web2/aggregate_debt_details/expListDebitDetailByService', params, true)
export const exportDetailApi = (params) => getData('web2/aggregate_debt_details/expCycle', params, true)
