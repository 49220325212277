import React, {useEffect, useState} from 'react'
import {HeaderListForm, useGetParams} from '@/components'
import {ReportStatistic} from './ReportStatistic'
import {RevenueVehicle} from './RevenueVehicle'
import {useLocation, useNavigate} from "react-router-dom";
import {useRevenueSyntheticLogCarParking} from '@/modules/parking_supervision/report_vehicle/utils'
import {databaseDate} from "@/lib";

export default function ReportVehicle() {
    const today = new Date()
    const navigate = useNavigate()
    const location = useLocation()
    const initParam = useGetParams()
    const [params, setParams] = useState({
        from: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
        to: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)),
        ...initParam,
    })
    const {
        totalDaily,
        revenueTurn,
        revenueSynthetic,
        revenue7LastDay,
        countTicket
    } = useRevenueSyntheticLogCarParking({...params})

    useEffect(() => {
        const query = {}
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                const value = params[key]
                if (Array.isArray(value) && !value[0]) {
                } else if (!['rows', 'render', 'first'].includes(key) && !['', undefined, null].includes(value))
                    query[key] = value
            }
        }
        navigate(location.pathname + '?' + new URLSearchParams(query).toString())
    }, [JSON.stringify(params)])

    return (
        <>
            <div className="card">
                <HeaderListForm title="Báo cáo phương tiện"/>
                <ReportStatistic params={params} setParams={setParams} revenueTurn={revenueTurn} totalDaily={totalDaily}
                                 countTicket={countTicket}/>
                <RevenueVehicle params={params} revenueSynthetic={revenueSynthetic} revenue7LastDay={revenue7LastDay}/>
            </div>
        </>
    )
}
