import { Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { useState } from 'react'

import { GirdChart, RoundChartFlexRow } from '@/components/Chart'
import { typeBuidingServices } from '@/constants'
import { useSelector } from 'react-redux'
import { useGetEndingDept, useGetGroupDeptRate, useGetPaymentRate, useGetPresentDeptRate } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ type_service: '' })

    const building_place_id = useSelector((state) => state.buildings)
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.type_service}
                options={typeBuidingServices}
                onChange={(e) => setFilter({ ...filter, type_service: e.target.value })}
                placeholder="Chọn loại dịch vụ"
                className="mb-3"
            />
            <Dropdownz
                value={filter.building_place}
                options={building_place_id}
                onChange={(e) => setFilter({ ...filter, building_place: e.target.value, apartment_id: undefined })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={filter.building_place ? apartments.filter(a => a.building_place_id === filter.building_place) : apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
        </GridForm>
    )
}

function DebtSummaryReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const paymentRate = useGetPaymentRate(params)
    const endingDept = useGetEndingDept(params)
    const serviceDeptRate = useGetPresentDeptRate(params)
    const groupDeptDate = useGetGroupDeptRate(params)
    const reversedDataSubset = serviceDeptRate
        .slice()
        .reverse()
        .map((item, index) => ({
            owe: item.owe,
            type_service: item.type_service,
            key: index,
        }))

    const deptLeft = endingDept.map((item) => ({
        ...item,
        difference: item.sumery - item.paid,
    }))

    return (
        <div className="p-card p-4">
            <HeaderListForm title="Báo cáo tổng hợp công nợ" />
            <Header setParams={setParams} />
            <div class="grid border-top-1 border-400">
                <div class="col-12 lg:col-7 ">
                    <GirdChart
                        title={'Báo cáo tỷ lệ thanh toán'}
                        color={['#1d2eb9', '#57A2F9']}
                        label={['Thanh toán', 'Phát sinh']}
                        dataChart={paymentRate}
                        keyData={['paid', 'sumery']}
                        labelChart="cycle_name"
                        maxYValue={''}
                        chartType={'bar'}
                        stepSize={''}
                        yAxisText="VND"
                        aspectRatio={'0.8'}
                    />
                </div>
                <div class="col-12 lg:col-5 ">
                    <GirdChart
                        title="Phải thu cuối kỳ"
                        color={['#57A2F9']}
                        label={['Biến động số dư']}
                        dataChart={deptLeft}
                        keyData={['difference']}
                        labelChart="date"
                        maxYValue={''}
                        chartType={'line'}
                        stepSize={''}
                        yAxisText="VND"
                        aspectRatio={'0.8'}
                    />
                </div>
            </div>
            <div className="grid mt-1 border-top-1 border-400">
                <div className="col-12 xl:col-6 border-right-1 border-400">
                    <RoundChartFlexRow
                        title="Tỷ lệ dư nợ hiện tại theo loại DV"
                        color={['#bde7fe', '#A5D7FB', '#76c1f3', '#56a2f7', '#220186']}
                        type="doughnut"
                        data={reversedDataSubset}
                        labels={[
                            'Phí nước nóng',
                            'Phí điện',
                            'Phí phương tiện',
                            'Phí nước',
                            'Phí dịch vụ',
                            'Phí dịch vụ khác',
                        ]}
                        descriptions={['', '', '', '', '', '']}
                        keyChart="owe"
                        aspectRatio={'1'}
                    />
                </div>
                <div className="col-12 xl:col-6">
                    <RoundChartFlexRow
                        title="Tỷ lệ dư nợ hiện tại theo nhóm "
                        color={['#bde7fe', '#A5D7FB', '#76c1f3', '#56a2f7', '#220186']}
                        type="doughnut"
                        data={groupDeptDate}
                        labels={[
                            'Nợ chuẩn',
                            'Nợ cần chú ý',
                            'Nợ dưới chuẩn',
                            'Nợ khó thu hồi',
                            'Nợ có khả năng mất vốn',
                        ]}
                        descriptions={[
                            'Nợ chưa quá hạn thanh toán',
                            'Nợ quá hạn không quá 3 tháng',
                            'Nợ quá hạn từ 3 đến 6 tháng',
                            'Nợ quá hạn từ 6 đến 12 tháng',
                            'Nợ quá hạn trên 12 tháng',
                            '6',
                        ]}
                        keyChart="paid"
                        aspectRatio={'1'}
                    />
                </div>
            </div>
        </div>
    )
}

export default DebtSummaryReport
