import { CalendarForm, DropdownForm, FormUpdate, HeaderListForm, InputForm, InputNumberForm, MultiRadioButton, useGetParams } from '@/components'
import { useGetApi } from '@/hooks'
import React, { useEffect, useState } from 'react'
import { addUserApi, detailUserApi, updateUserApi } from '../api'
import { useParams } from 'react-router-dom'
import { genders, tax_authorization } from '@/constants'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { Calendar } from 'primereact/calendar'
import { databaseDate } from '@/lib'

const initInfos = {
      status: true,
      gender: 1
}
function InsuranceTaxes() {
      const initParam = useGetParams()
      const { id } = useParams()
      const detail = useGetApi(detailUserApi, { id }, {})
      const [params, setParams] = useState(initParam)
      const [infos, setInfos] = useState(initInfos)
      const [dataTax, setDataTax] = useState([]);



      const addRow = () => {
            const newRowId = new Date().getTime();
            setDataTax([...dataTax, { idRow: newRowId, nanamenptme: '', dobnpt: '', tax_codenpt: '', idNumbernpt: '', relationnpt: '', addressnpt: '' }]);

      };
      const onEditorValueChange = (product, field, value) => {
            const updatedProduct = { ...product, [field]: value };
            setDataTax(dataTax.map(d => d.idRow === product.idRow ? updatedProduct : d));
      };
      const inputTextEditorNPT = (product, field) => {
            return <InputText type="text" value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      };
      const inputCalenderNPT = (product, field) => {
            return <Calendar value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      };
      const inputCalenderFromMonthNPT = (product, field) => {
            return <Calendar value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      };
      const inputCalenderToMonthNPT = (product, field) => {
            return <Calendar value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      };

      const columnBodyTemplate = (product, column) => {
            return column.editor ? column.editor(product) : product[column.field];
      };
      const deleteRow = (product) => {

            if (product && (product.idRow || product.idRow === 0 || product.idRow === null)) {
                  setDataTax(dataTax.filter(d => d.idRow !== product.idRow));
            }
      }
      const actionBodyTemplate = (product) => {
            return <Button icon="pi pi-trash" type='button' onClick={() => deleteRow(product)} severity="danger" />;
      };
      useEffect(() => {
            if (detail.id) {
                  setInfos({
                        ...infos,
                        ...removeUndefinedProps(detail),
                        birthday: detail.birthday ? new Date(detail.birthday) : '',
                        validity_period: detail.validity_period ? new Date(detail.validity_period) : '',
                  })
                  const data = checkJson(detail.data_tax) || detail.data_tax
                  if (data && data[0]) {
                        data.forEach(d => {
                              if (d.dobnpt) d.dobnpt = new Date(d.dobnpt)
                              if (d.from_monthnpt) d.from_monthnpt = new Date(d.from_monthnpt)
                              if (d.to_monthnpt) d.to_monthnpt = new Date(d.to_monthnpt)
                        })
                  }

                  if (Array.isArray(data)) setDataTax([...data])
            }
      }, [detail])
      const columns = [
            { field: 'namenpt', header: 'Họ tên NPT', editor: (product) => inputTextEditorNPT(product, 'namenpt') },
            { field: 'dobnpt', header: 'Ngày sinh', editor: (product) => inputCalenderNPT(product, 'dobnpt') },
            { field: 'tax_codenpt', header: 'MST NPT', editor: (product) => inputTextEditorNPT(product, 'tax_codenpt') },
            { field: 'idNumbernpt', header: 'Số CMND/CCCD', editor: (product) => inputTextEditorNPT(product, 'idNumbernpt') },
            { field: 'relationnpt', header: 'Quan hệ', editor: (product) => inputTextEditorNPT(product, 'relationnpt') },
            { field: 'number_npt', header: 'Số', editor: (product) => inputTextEditorNPT(product, 'number_npt') },
            { field: 'permissionnpt', header: 'Quyền', editor: (product) => inputTextEditorNPT(product, 'permissionnpt') },
            { field: 'from_monthnpt', header: 'Từ tháng', editor: (product) => inputCalenderFromMonthNPT(product, 'from_monthnpt') },
            { field: 'to_monthnpt', header: 'Đến tháng', editor: (product) => inputCalenderToMonthNPT(product, 'to_monthnpt') },
            { field: 'nationalitynpt', header: 'Quốc tịch', editor: (product) => inputTextEditorNPT(product, 'nationalitynpt') },
            { field: 'addressnpt', header: 'Địa chỉ', editor: (product) => inputTextEditorNPT(product, 'addressnpt') },
            { body: (e) => actionBodyTemplate(e), header: 'Hành động' },
      ];

      const handleData = () => {
            const params = {
                  user_id: id,

                  data_tax: dataTax,
                  type_user: "hrm"
            }
            return params
      }
      return (
            <FormUpdate
                  checkId={Number(id)}
                  route="/list_personnel"
                  actions={{ add: addUserApi, update: updateUserApi }}
                  handleData={handleData}
            >
                  <div className="card">
                        <HeaderListForm title="Thông tin người phụ thuộc" />

                        <Button label="Thêm dòng" onClick={addRow} type='button' className='mb-3' />
                        <DataTable value={dataTax}>
                              {columns.map((col, i) => (
                                    <Column key={col.field || i}
                                          field={col.field}
                                          header={col.header}
                                          body={(product) => col.body ? col.body(product, col) : columnBodyTemplate(product, col)} />
                              ))}
                        </DataTable>
                  </div>
            </FormUpdate>
      )
}

export default InsuranceTaxes
