import {
    Body, Calendarz, Columnz, DataTablezV3, Dropdownz,
    GridForm, HeaderListForm, Inputz, NumberFormat, useGetParams,
} from '@/components'
import { listToast, typeDebitService } from '@/constants'
import { useGetApi } from '@/lib/react-query'
import {
    countAggregateDebtDetails,
    exportDebtDetailApi,
    exportDebtDetailByApartmentApi,
    exportDebtDetailByServiceApi, exportDetailApi,
    listAggregateDetail,
} from '@/modules/debts/aggregate_debt_details/api'
import { setToast } from '@/redux/features'
import { formatNumber, removeSpecialCharacter } from '@/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useListCycleName } from '../../services_list/utils'

export const getCycle = () => {
    const date = new Date()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return '' + year + (month < 10 ? '0' + month : month)
}

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '', cycle_name: Number(getCycle()) })
    const [select, setSelect] = useState(1)
    const apartments = useSelector((state) => state.apartments)
    const services = useSelector((state) => state.services)
    const buildings = useSelector((state) => state.buildings)
    const listCycleName = useListCycleName()

    useEffect(() => {
        if (select === 2) {
            setFilter({ ...filter, cycle_name: undefined })
        } else {
            if (!filter.cycle_name) setFilter({ ...filter, cycle_name: Number(getCycle()) })
        }
    }, [filter.cycle_name, select])

    const anyList = [
        { id: 1, name: 'Một kỳ bảng kê' },
        { id: 2, name: 'Khoảng thời gian' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Inputz
                id="debt_search"
                value={filter.debt_search}
                placeholder="Dư nợ cuối kì"
                type="number"
                onChange={(e) => setFilter({ ...filter, debt_search: e.target.value })}
            />
            <Dropdownz
                value={filter.place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
                showClear
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
                showClear

            />
            <Dropdownz
                value={filter.service_id}
                options={services}
                onChange={(e) => setFilter({ ...filter, service_id: e.target.value })}
                placeholder="Tên dịch vụ"
                showClear
            />
            <Dropdownz
                options={typeDebitService}
                value={filter.type_service}
                onChange={(e) => setFilter({ ...filter, type_service: e.target.value })}
                placeholder="Chọn loại dịch vụ"
                showClear
            />

            <Dropdownz
                value={select}
                options={anyList}
                onChange={(e) => setSelect(e.target.value)}
                placeholder="Loại"
                showClear={false}
            />
            {select === 1 && <>
                <Dropdownz
                    value={filter.cycle_name}
                    options={listCycleName}
                    onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                    optionLabel="cycle_name"
                    optionValue="cycle_name"
                    placeholder="Chọn kỳ bảng kê"
                    showClear={false}
                />
            </>}
            {select === 2 && <>
                <Calendarz
                    value={filter.dates}
                    onChange={(e) => setFilter({ ...filter, dates: e.value })}
                    placeholder={'Chọn khoảng thời gian '}
                    selectionMode="range"
                />
            </>}
        </GridForm>
    )
}

function AggregateDebtDetails() {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(() => {
        if (!initParam.cycle_name) {
            return { ...initParam, cycle_name: Number(getCycle()), limit: 100 }
        } else return { ...initParam, limit: 100 }
    })
    const { data, isLoading } = useGetApi(listAggregateDetail, params, 'debtdetail')
    const totalRecords = useGetApi(countAggregateDebtDetails, {
        ...params,
        first: undefined,
    }, 'countAggregateDebtDetails')
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loadingDetail, setLoadingDetail] = useState(false)
    const apartments = useSelector((state) => state.apartments)
    const services = useSelector((state) => state.services)

    async function handleExport(exportApi, title, setLoading = () => {
    }) {
        if (exportApi) {
            setLoading(true)
            const response = await exportApi({ ...params, limit: undefined, page: undefined, reOrder: undefined })
            if (response) setLoading(false)
            if (response.status) {
                const downloadLink = document.createElement('a')
                downloadLink.href = URL.createObjectURL(response.data)
                downloadLink.download = (title && `export_${removeSpecialCharacter(title)}.xlsx`) || 'data.xlsx'
                downloadLink.click()
                dispatch(setToast({ ...listToast[0], detail: `Export ${title} thành công!` }))
            } else dispatch(setToast({ ...listToast[1], detail: 'Có lỗi xảy ra!' }))
        }
    }

    const handleExportDebt = async (params) => {
        setLoadingDetail(true)
        const response = await exportDetailApi({ ...params, limit: undefined, page: undefined, reOrder: undefined })
        if (response) setLoadingDetail(false)
        if (response.status) {
            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(response.data)
            downloadLink.download = `xuat_chi_tiet.xlsx` || 'data.xlsx'
            downloadLink.click()
            dispatch(setToast({ ...listToast[0], detail: `Xuất chi tiết thành công!` }))
        } else dispatch(setToast({ ...listToast[1], detail: 'Có lỗi xảy ra!' }))
    }

    const moreOptions = [
        {
            label: 'Xuất chi tiết',
            icon: 'pi pi-download',
            command: () => handleExportDebt(params),
            severity: 'success',
            loading: loadingDetail
        },
        {
            label: 'Tổng hợp phải thu theo căn hộ',
            icon: 'pi pi-download',
            command: () => handleExport(exportDebtDetailByApartmentApi, 'công nợ phải thu theo căn hộ', setLoading1),
            loading: loading1,
        },
        {
            label: 'Tổng hợp phải thu theo dịch vụ',
            icon: 'pi pi-download',
            command: () => handleExport(exportDebtDetailByServiceApi, 'công nợ phải thu theo dịch vụ', setLoading2),
            loading: loading2,
        }]

    const getApartmentName = (apartmentId) => {
        const apartment = apartments.find(apartment => apartment.id === apartmentId)
        return apartment ? apartment.name_customer : ''
    }
    const getApartmentCodeById = (apartmentId) => {
        const apartment = apartments.find(apartment => apartment.id === apartmentId)
        return apartment ? apartment.code : ''
    }
    const getBudget_code = (servicesId) => {
        const service = services.find(d => d.id === servicesId)
        return service ? service.budget_code : ''
    }
    const getCode_receipt = (servicesId) => {
        const service = services.find(d => d.id === servicesId)
        return service ? service.code_receipt : ''
    }
    return (
        <div className="card">
            <HeaderListForm title="Tổng hợp công nợ chi tiết" />
            <Header setParams={setParams} />
            <div style={{ lineHeight: '2' }}>
                <strong>Tổng đầu kỳ: {formatNumber(data?.synthetic?.before_paid || '0')}</strong> <br />
                <strong>Tổng phát sinh: {formatNumber(data?.synthetic?.incurred || '0')}</strong><br />
                <strong>Tổng ưu đãi: {formatNumber(data?.synthetic?.promotion || '0')}</strong><br />
                <strong>Tổng giảm trừ: {formatNumber(data?.synthetic?.discount_out || '0')}</strong><br />
                <strong>Tổng thanh toán: {formatNumber(data?.synthetic?.paid || '0')}</strong><br />
                <strong>Tổng điều chỉnh: {formatNumber(data?.synthetic?.adjustable || '0')}</strong><br />
                <strong>Tổng dư nợ cuối kỳ: {formatNumber(data?.synthetic?.after_paid || '0')}</strong><br />
            </div>
            <br />
            <DataTablezV3
                value={Array.isArray(data?.data) ? data?.data : []}
                title="công nợ chi tiết"
                totalRecords={typeof totalRecords?.data?.data?.data === 'number' ? totalRecords?.data?.data?.data : 0}
                params={params}
                setParams={setParams}
                route="/aggregate_debt_details"
                rowsPerPageOptions={[20, 40, 100]}
                headerInfo={{ exportApi: exportDebtDetailApi, moreOptions }}
                basePermissions={['export']}
                loading={isLoading}

            >
                <Columnz field="full_name" header="Tên KH" body={(e) => getApartmentName(e.bdc_apartment_id)} />
                <Columnz
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'name' })}
                    header="Căn hộ"
                />
                <Columnz field="code" header="Mã căn hộ " body={(e) => getApartmentCodeById(e.bdc_apartment_id)} />
                <Columnz field="code_receipt" header="Mã phí" body={(e) => getCode_receipt(e.bdc_service_id)} />
                <Columnz field="budget_code" header="Mã ngân sách" body={(e) => getBudget_code(e.bdc_service_id)} />
                <Columnz
                    field="service_name"
                    header="Dịch vụ"
                    body={(e) => {
                        if (e.bdc_service_id === 0) {
                            return 'Dịch vụ tiền thừa'
                        } else {
                            return Body({ data: services, value: e.bdc_service_id, label: 'name' })
                        }
                    }}
                ></Columnz>
                <Columnz field="vehicle_name" header="Sản phẩm" />
                <Columnz
                    className="text-right"
                    body={e => NumberFormat({ value: e.before_paid })} header="Số dư đầu kỳ" />
                <Columnz
                    className="text-right"
                    body={e => NumberFormat({ value: e.incurred })} header="Phát sinh trong kỳ" />
                <Columnz
                    className="text-right"
                    body={(e) => NumberFormat({ value: e.promotion })} header="Ưu đãi" />
                <Columnz
                    className="text-right"
                    body={(e) => NumberFormat({ value: e.discount_out })} header="Giảm trừ" />
                <Columnz
                    className="text-right"
                    body={e => NumberFormat({ value: e.paid })} header="Thanh toán" />
                <Columnz
                    className="text-right"
                    body={(e) => NumberFormat({ value: e.adjustable })} header="Điều chỉnh" />
                <Columnz
                    className="text-right"
                    body={e => NumberFormat({ value: e.after_paid })} header="Số dư cuối kỳ" />
            </DataTablezV3>
        </div>
    )
}

export default AggregateDebtDetails
