import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import Building from './Building'
import Floors from './Floor'

export default function BuildingManagement() {
    return (
        <div className="card ">
            <TabView>
                <TabPanel header="Quản lý tòa nhà">
                    <Building />
                </TabPanel>
                <TabPanel header="Quản lý tầng">
                    <Floors />
                </TabPanel>
            </TabView>
        </div>
    )
}
