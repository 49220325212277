import { Calendarz, Dropdownz, GridForm, useGetParams } from '@/components'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart'
import { status } from '@/constants'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Công ty"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Tòa nhà"
            />
            <Calendarz value={filter.status} onChange showIcon placeholder="Tháng" />
        </GridForm>
    )
}

function VehicleRevenueReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [chartDataPie, setChartDataPie] = useState({})
    const [chartOptionsPie, setChartOptionsPie] = useState({})
    const [chartDataPie1, setChartDataPie1] = useState({})
    const [chartOptionsPie1, setChartOptionsPie1] = useState({})
    const [chartDataPie2, setChartDataPie2] = useState({})
    const [chartOptionsPie2, setChartOptionsPie2] = useState({})

    useEffect(() => {
        const data = {
            labels: [
                'Greenbay1',
                'Phương Tây',
                'Fortune 28',
                'GoldenPearl',
                'Efficent',
                'River Lake',
                'BearSky',
                'Fuwa',
                'Golden Plaza 2',
                'Paragic',
                'Green Peel',
                'Imperiol',
                'Sliver Park',
                'New Duxware',
                'Opic2',
                'Astoria 89',
            ],
            datasets: [
                {
                    label: 'Doanh thu',
                    data: [
                        1500, 1300, 1500, 1300, 1250, 1400, 1650, 1550, 1600, 1700, 1350, 1400, 1500, 1700, 1900, 1600,
                    ],
                    backgroundColor: [
                        'rgb(22, 91, 170)',
                        'rgb(22, 91, 170)',
                        'rgb(22, 91, 170)',
                        'rgb(22, 91, 170)',
                        'rgb(22, 91, 170)',
                        'rgb(22, 91, 170)',
                        'rgb(22, 91, 170)',
                    ],
                },
            ],
        }
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    min: 0,
                    max: 2200,
                    ticks: {
                        stepSize: 200,
                        callback: function (value, index, values) {
                            if (index === values.length - 1) {
                                return 'Trăm triệu đồng'
                            } else {
                                return value.toLocaleString()
                            }
                        },
                    },
                },
            },
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                },
            },
        }

        setChartData(data)
        setChartOptions(options)
    }, [])
    useEffect(() => {
        const data = {
            labels: ['Xe máy', 'Ô tô', 'Xe khác'],
            datasets: [
                {
                    data: [35, 60, 5],
                    backgroundColor: ['rgba(243, 163, 44, 1)', 'rgba(22, 91, 169, 1)', 'rgba(42, 42, 42, 1)'],
                    hoverBackgroundColor: ['rgba(243, 163, 44, .8)', 'rgba(22, 91, 169, .8)', 'rgba(42, 42, 42, .8)'],
                },
            ],
        }
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie(data)
        setChartOptionsPie(options)
    }, [])
    useEffect(() => {
        const data = {
            labels: ['Vé lượt', 'Vé tháng'],
            datasets: [
                {
                    data: [70, 20, 50],
                    backgroundColor: ['rgba(243, 163, 44, 1)', 'rgba(22, 91, 169, 1)', 'rgba(42, 42, 42, 1)'],
                    hoverBackgroundColor: ['rgba(243, 163, 44, .8)', 'rgba(22, 91, 169, .8)', 'rgba(42, 42, 42, .8)'],
                },
            ],
        }
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie1(data)
        setChartOptionsPie1(options)
    }, [])
    useEffect(() => {
        const data = {
            labels: ['Xe máy', 'Ô tô', 'Xe khác'],
            datasets: [
                {
                    data: [70, 20, 50],
                    backgroundColor: ['rgba(243, 163, 44, 1)', 'rgba(22, 91, 169, 1)', 'rgba(42, 42, 42, 1)'],
                    hoverBackgroundColor: ['rgba(243, 163, 44, .8)', 'rgba(22, 91, 169, .8)', 'rgba(42, 42, 42, .8)'],
                },
            ],
        }
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie2(data)
        setChartOptionsPie2(options)
    }, [])
    return (
        <>
            <div className="card">
                <Header setParams={setParams} />
                <div>
                    <Chart type="bar" data={chartData} options={chartOptions} />
                </div>
            </div>

            <div class="grid align-items-center ">
                <div class="col-12 md:col-6 lg:col-6 ">
                    <div className="card">
                        <b className="text-xl">Doanh thu theo phương tiện</b>
                        <div className="flex justify-content-end my-3">
                            <InputText disabled value="06/10/2023" />
                        </div>
                        <Chart className="" type="pie" data={chartDataPie} options={chartOptionsPie} />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <div className="card">
                        <b className="text-xl">Doanh thu theo loại vé</b>
                        <div className="flex justify-content-end my-3">
                            <InputText disabled value="07/10/2023" />
                        </div>
                        <Chart className="" type="pie" data={chartDataPie1} options={chartOptionsPie1} />
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div class="grid ">
                    <div class="col-12 md:col-12 lg:col-12 ">
                        <div className="card">
                            <b className="text-xl">Doanh thu theo lượt phương tiện</b>
                            <div className="flex justify-content-end my-3">
                                <InputText disabled value="06/10/2023" />
                            </div>
                            <Chart type="pie" data={chartDataPie2} options={chartOptionsPie2} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VehicleRevenueReport
