import {CalendarForm, DropdownForm, FormUpdateDialog, InputForm, InputNumberForm} from '@/components'
import { typesOfPromotion } from '@/constants'
import { removePropObject, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { addPromotionApi, updatePromotionApi } from '../api'
import { useDetailPromotion } from '../utils'

const UpdatePromotion = (props) => {
    const { setVisible, setParams, visible, services } = props
    const promotionDetail = useDetailPromotion(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState({
        name: '',
        begin: '',
        end: '',
        service_id: '',
        condition: '',
        number_discount: '',
        discount: '',
        type_discount: '',
    })

    useEffect(() => {
        if (promotionDetail.id) {
            setInfos({
                ...removeUndefinedProps(promotionDetail),
                begin: new Date(promotionDetail.begin),
                end: new Date(promotionDetail.end),
            })
        }
    }, [promotionDetail])

    const handleData = () => {
        let info = { ...infos, begin: convertDateFormat(infos.begin), end: convertDateFormat(infos.end) }
        if (infos.name) {
            var format = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
            if (format.test(infos.name)) {
                return 'Tên ưu đãi không đúng định dạng!'
            }
        }
        if (!(infos.begin || infos.end)) return 'Thời gian áp dụng và thời gian kết thúc không được để trống!'
        if (new Date(infos.begin) >= new Date(infos.end))
            return 'Ngày bắt đầu không được lớn hơn hoặc bằng ngày kết thúc!'
        if (!infos.service_id) return 'Vui lòng chọn dịch vụ!'
        if (!infos.number_discount) return 'Số tháng ưu đãi không được để trống!'
        if (infos.type_discount === 1 && infos.discount > 100)
            return 'Kiểu ưu đãi là % thì giá trị ưu đãi phải nhỏ hơn 100'
        if (typeof visible === 'number') info = { ...removePropObject(info, promotionDetail), id: visible }
        return info
    }

    const convertDateFormat = (dateStr) => {
        let date = new Date(dateStr)
        let day = String(date.getDate()).padStart(2, '0')
        let month = String(date.getMonth() + 1).padStart(2, '0')
        let year = date.getFullYear()
        let hours = String(date.getHours()).padStart(2, '0')
        let minutes = String(date.getMinutes()).padStart(2, '0')
        let seconds = String(date.getSeconds()).padStart(2, '0')
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'ưu đãi'}
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            route="/promotion_management"
            refreshObjects={[setInfos]}
            actions={{ add: addPromotionApi, update: updatePromotionApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên ưu đãi (*)"
                            required
                        />
                        <CalendarForm
                            id="time"
                            value={infos.begin}
                            onChange={(e) => {
                                setInfos({ ...infos, begin: e.target.value })
                            }}
                            showIcon
                            label="Thời gian áp dụng (*)"
                            readOnlyInput
                            required
                        />
                        <CalendarForm
                            id="time"
                            value={infos.end}
                            onChange={(e) => {
                                setInfos({ ...infos, end: e.target.value })
                            }}
                            showIcon
                            label="Thời gian kết thúc (*)"
                            readOnlyInput
                        />
                        <DropdownForm
                            id="service_id"
                            label="Dịch vụ (*)"
                            value={infos.service_id}
                            onChange={(e) => setInfos({ ...infos, service_id: e.target.value })}
                            options={services}
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputNumberForm
                            id="condition"
                            value={infos.condition}
                            handleOnChange={(e) => setInfos({ ...infos, condition: e })}
                            label="Điều kiện ưu đãi"
                        />
                        <InputNumberForm
                            id="number_discount"
                            value={infos.number_discount}
                            handleOnChange={(e) => setInfos({ ...infos, number_discount: e })}
                            label="Số tháng ưu đãi (*)"
                            required
                        />
                        <InputForm
                            id="discount"
                            value={infos.discount}
                            onChange={(e) => setInfos({ ...infos, discount: Number(e.target.value) })}
                            label="Giá trị ưu đãi (*)"
                            required
                        />
                        <DropdownForm
                            id="type_discount"
                            label="Loại ưu đãi (*)"
                            value={infos.type_discount}
                            onChange={(e) => setInfos({ ...infos, type_discount: e.target.value })}
                            options={typesOfPromotion}
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdatePromotion
