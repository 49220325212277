import Layout from '@/layout'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { ScrollTop } from 'primereact/scrolltop'
import { Toast } from 'primereact/toast'
import { createContext, Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Loading } from './modules/auth'
import { SocketProvider } from './modules/socket/SocketConnection'
import { hideToast } from './redux/features/toast'
import { accessDeniedPage, errorPage, routes } from './routes'
import { PrivateRoutes, PublicRoutes } from './routes/Routes'
import { useLocation } from 'react-router-dom';
const b = localStorage.getItem('token')
const clientId = localStorage.getItem('clientId')
export const DataContext = createContext();


const NavigationScroll = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return children || null;
};

function App() {
    const dispatch = useDispatch()
    const permission = useSelector((state) => state.myTool).myTool
    const toastOptions = useSelector((state) => state.toast)
    const loading = useSelector((state) => state.loading)
    const toast = useRef(null)
    const [showScrollTop, setShowScrollTop] = useState(true)
    const [data, setData] = useState();
    const item = useSelector(state => state.item);
    const building_id = item.building_id
    const company_id = item.company_id
    let token = ''
    if (!b) {
        token = localStorage.getItem('token')
    } else token = b

    useEffect(() => {
        if (toastOptions.severity) {
            const show = () => {
                toast.current.show({ ...toastOptions })
            }
            show()
            dispatch(hideToast())
        }
    }, [toastOptions])

    useEffect(() => {
        if (permission.includes('/print/bills')) {
            setShowScrollTop(false)
        } else {
            setShowScrollTop(true)
        }
    }, [permission])

    return (

        <SocketProvider token={token} building_id={building_id} company_id={company_id} clientId={clientId}>
            <div className="App">
                {showScrollTop && <ScrollTop />}
                <ConfirmDialog />
                <DataContext.Provider value={{ data, setData }}>
                </DataContext.Provider>
                <Toast ref={toast} />
                <Router>
                    {loading ? (
                        <Loading loading={loading} />
                    ) : (
                        <NavigationScroll>
                            <Routes>
                                {routes.map((route, index) => {
                                    const DefaultLayout = route.layout ? Layout : Fragment
                                    const Page = route.component
                                    const checkAccessRoute =
                                        Boolean(route.public) ||
                                        permission.includes(route.path) ||
                                        [
                                            '/receipt/lap_phieu_thu',
                                            '/deposit_list/lap_phieu_thu_kyquy',
                                            '/deposit_list/lap_phieu_thu_kyquy',
                                            '/deposit_list/print_deposit/:id',
                                            '/receipt/lap_phieu_chi',
                                            '/deposit_list/lap_phieu_chi',
                                            '/receipt/print_slip/:id',
                                            '/deposit_list/print_list/:id',
                                            '/list_detail/detail/:id',
                                            '/print/detail/:id',
                                            '/resident_card_management',
                                            '/resident_card_management/insert',
                                            '/personnel_list',
                                            '/personnel_list/insert',
                                            '/personnel_list/detail/:id',
                                            '/time_keeping_management',
                                            "/print/bills",
                                            "/payroll_management",
                                            '/payroll_management/insert',
                                            '/shift_management',
                                            '/tax_administration',
                                            '/receipt/lap_phieu_thu_khac',
                                            '/cash_book_money',
                                            '/bank_treasury_book',
                                            '/receipt/lap_phieu_dieu_chinh',
                                            '/inventory_management/create_input_form',
                                            '/inventory_management/create_export_ticket',
                                            '/printReduce/detail/:id',
                                            '/parking_supervision',
                                            '/inventory_management/rolate_slip',
                                            '/print/ware_house/:id',
                                        ].includes(route.path)
                                    return (
                                        <Route key={index} element={!route.public ? <PrivateRoutes /> : <PublicRoutes />}>
                                            {checkAccessRoute ? (
                                                <Route
                                                    path={route.path}
                                                    element={
                                                        <DefaultLayout>
                                                            <Page />
                                                        </DefaultLayout>
                                                    }
                                                />
                                            ) : (
                                                <Route path={route.path} element={<accessDeniedPage.component />} />
                                            )}
                                        </Route>
                                    )
                                })}
                                <Route path={errorPage.path} element={<errorPage.component />} />
                            </Routes>
                        </NavigationScroll>
                    )}
                </Router>
            </div>
        </SocketProvider>
    )
}

export default App