import { CalendarForm, DropdownForm, FormUpdate, HeaderListForm, InputForm, UploadImg } from '@/components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Carousel } from 'primereact/carousel'
import { useParams } from 'react-router-dom'
import { useListUnit } from '../../asset_managerment/utils'
import { useListAsset, useListAssetMix } from '../../asset_mix_management/utils'
import { useDetailWarehouse, useListWarehouse } from '../utils'
import { product_category } from '@/constants'
import { checkJson } from '@/utils'

const InformationInventory = () => {
      const { id } = useParams()
      const [infos, setInfos] = useState({})
      const users = useSelector((state) => state.users)
      const detail = useDetailWarehouse(id)
      const unit = useListUnit()

      const group = useListAssetMix({ status: undefined })
      const [image, setImage] = useState([])

      const productor = useListAsset({ status: undefined })
      const data = useListWarehouse({ status: undefined })
      const amout = data.find(d => d.id === detail?.id)
      useEffect(() => {
            const a = group.find(d => d.id === detail.product_group_id)
            // setInfos({ ...infos, })
            if (detail?.id) {

                  setInfos({
                        ...infos,
                        ...detail,
                        created_at: detail.created_at ? new Date(detail.created_at) : '',
                        type: a?.type
                  })
                  if (detail.attack) {
                        const attack = checkJson(detail.attack) || detail.attack

                        if (attack.image) {
                              setImage(attack.image)
                        }
                  }
            }
      }, [detail, group])

      return (
            <FormUpdate
                  checkId={(id)}
                  title="sản phẩm"
                  // handleData={handleData}
                  route="/warehouse_management"
            // actions={{ add: addPriceApi, update: updatePriceApi }}
            // actions={{ add: addApartmentServiceApi, update: updateApartmentServiceApi }}
            >
                  <div className=" bg-color">
                        <div className="grid grid-form">
                              <div className="col-12 lg:col-4">
                                    <div className="card">
                                          <HeaderListForm title="Hình ảnh sản phẩm" />
                                          <UploadImg view image={image} setImage={setImage} title="Ảnh danh mục sản phẩm " />

                                    </div>
                                    <div className='flex flex-column gap-3 m-3'>
                                          <div className="flex justify-content-between">
                                                <b>Tổng số lượng: </b>
                                                <b>{Number(infos.i_amount) + Number(infos.o_amount)}</b>
                                          </div>
                                          <div className="flex justify-content-between">

                                                <b>Số lượng trong kho:</b>
                                                <b>{infos.i_amount}</b>
                                          </div>
                                          <div className="flex justify-content-between">

                                                <b>Số lượng đã cấp phát:</b>
                                                <b>{infos.o_amount}</b>
                                          </div>

                                    </div>
                              </div>
                              <div className="col-12 lg:col-8">
                                    <div className="card">
                                          <div className="grid grid-form ">
                                                <div className="col-12 lg:col-6">
                                                      <DropdownForm
                                                            value={infos.product_id}
                                                            label="Tên sản phẩm (*)"
                                                            options={productor}
                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-6">
                                                      <InputForm
                                                            value={infos.code}
                                                            label="Mã sản phẩm  (*)"
                                                            disabled
                                                      />
                                                </div>

                                                <div className="col-12 lg:col-6">
                                                      <DropdownForm
                                                            id="service_id"
                                                            value={infos?.type}
                                                            label="Phân loại sản phẩm"
                                                            options={product_category}
                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-6">
                                                      <DropdownForm
                                                            id="service_id"
                                                            value={infos.product_group_id}
                                                            label="Nhóm"
                                                            options={group}

                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-6">
                                                      <DropdownForm
                                                            id="service_id"
                                                            value={infos.unit_id}
                                                            label="Đơn vị tính"
                                                            options={unit}
                                                            disabled
                                                      />
                                                </div>

                                                <div className="col-12 lg:col-6">
                                                      <InputForm
                                                            id="desc"
                                                            value={infos.note}
                                                            onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                                                            label="Mô tả"
                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-6">
                                                      <CalendarForm

                                                            value={infos.created_at}
                                                            onChange={(e) => setInfos({ ...infos, created_at: e.target.value })}
                                                            label="Ngày tạo"
                                                            disabled
                                                            showIcon={false}
                                                      />
                                                </div>
                                                {/* <div className="col-12 lg:col-6">
                                                      <DropdownForm
                                                            options={users}
                                                            value={infos.user_by}
                                                            onChange={(e) => setInfos({ ...infos, user_by: e.target.value })}
                                                            label="Người tạo"
                                                            optionLabel="full_name"
                                                            disabled

                                                      />
                                                </div> */}
                                          </div>

                                    </div>

                              </div>

                        </div>
                  </div>
            </FormUpdate >
      )
}

export default InformationInventory
