import {
    Columnz,
    DataTablez, Dropdownz, FormUpdateDialog,
    GridForm,
    HeaderListForm,
    InputForm,
    InputTextareaForm,
    Inputz,
    StatusBody,
    useGetParams
} from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addRequestApi, deleteRequestApi, updateRequestApi } from '../api'
import { useCountRequest, useDetailRequest, useListRequest } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function ManageRequest() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListRequest({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountRequest({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const requestDetail = useDetailRequest(id)
    const [infos, setInfos] = useState({
        name: '',
        category: '',
        type_maintain: '',
        time_maintain: '',
        description: '',
    })
    useEffect(() => {
        if (requestDetail.id)
            setInfos({ ...infos, ...requestDetail, status: requestDetail.status === 0 ? false : true })
    }, [requestDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, requestDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <FormUpdateDialog
                title={(typeof visible === 'number' ? 'Cập nhật' : 'Thêm mới') + ' Yêu cầu'}
                handleData={handleData}
                visible={visible}
                route="/work_management"
                setVisible={setVisible}
                refreshObjects={[setInfos]}
                actions={{ add: addRequestApi, update: updateRequestApi }}
            >
                <div className="card bg-color">
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên tài sản (*)"
                        required
                    />
                    <InputForm
                        id="category"
                        value={infos.category}
                        onChange={(e) => setInfos({ ...infos, category: e.target.value })}
                        label="Danh mục"
                    />
                    <InputForm
                        id="type_maintain"
                        value={infos.type_maintain}
                        onChange={(e) => setInfos({ ...infos, type_maintain: e.target.value })}
                        label="Kiểu bảo trì"
                    />
                    <InputForm
                        id="time_maintain"
                        value={infos.time_maintain}
                        onChange={(e) => setInfos({ ...infos, time_maintain: e.target.value })}
                        label="Thời gian bảo trì"
                    />
                    <InputTextareaForm
                        id="description"
                        value={infos.description}
                        onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                        label="Ghi chú"
                    />
                </div>
            </FormUpdateDialog>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Danh sách yêu cầu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/work_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteRequestApi }}
            >
                <Columnz field="request" header="Yêu cầu" />
                <Columnz field="name" header="Tên công việc" />
                <Columnz field="date_send" header="Bgày gửi" />
                <Columnz field="writer" header="Người gửi" />
                <Columnz field="reason" header="Lý do" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            updateAction: updateRequestApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
