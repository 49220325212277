import { getData, postData } from '@/lib/request'

export const listPostApi = (params) => getData('web2/posts/getListPost', params)
export const countPostApi = (params) => getData('web2/posts/countPost', params)
export const deletePostApi = (params) => postData('web2/posts/deletePost', params)
export const detailPostApi = (params) => getData('web2/posts/getDetailPost', params)
export const addPostApi = (params) => postData('web2/posts/addPost', params, true)
export const updatePostApi = (params) => postData('web2/posts/updatePost', params, true)
export const changeStatusPostApi = (params) => postData('web2/posts/changeStatusPost', params)
export const notifyPost = (params) => postData('web2/posts/notifyPostToApp', params)

export const listBuildingApi = (params) => getData('web2/posts/getListBuildingPlacePost', params)
export const listApartmentApi = (params) => getData('web2/posts/getListApartmentPost', params)
export const listResidentApi = (params) => getData('web2/posts/getListResidentPost', params)
export const listPostCate = (params) => getData('web2/info/getListPostCate', params)



export const countBuildingCompanyApi = (params) => getData('web2/posts/countBuildingCompany', params)
export const detailStaffApi = (params) => getData('web2/posts/getDetailBuildingCompany', params)
export const listNameBuildingCompanyApi = (params) => getData('web2/posts/getListNameBuildingCompany', params)
export const listStaffApi = (params) => getData('web2/posts/getListBuildingCompany', params)
export const listCommnentNotifyApi = (params) => getData('web2/posts/getListCommentPost', params)
export const switchStatusNotifiCommentApi = (params) => postData('web2/feedback_comment/switchStatus', params)
export const addNotifyComment = (params) => postData('web2/posts/addComment', params, true)
export const deleteNotifyComment = (params) => postData('web2/feedback_comment/deleteComments', params,)
