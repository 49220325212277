import { useEffect, useState } from 'react'
import {
    countDepartmentApi,
    detailDepartmentApi,
    listDepartmentApi,
    countBuildingCompanyApi,
    detailStaffApi,
    listNameBuildingCompanyApi,
    listStaffApi,
} from '../api'

export const useListDepartment = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDepartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListNameBuildingCompanyApi = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listNameBuildingCompanyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListStaff = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listStaffApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountDepartment = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countDepartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailDepartment = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailDepartmentApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

export const useDetailStaff = (user_id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailStaffApi({ user_id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (user_id) fetchData()
    }, [user_id])
    return data
}

export const useCountBuildingCompany = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBuildingCompanyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
