import { useEffect, useState } from 'react'
import { useDetailPost, useLisCate } from '../utils'
import { useParams } from 'react-router-dom'
import { DropdownForm, Editorz, FormUpdateDialog, InputForm, InputSwitchForm, UploadImg } from '@/components'
import { addPostApi, updatePostApi } from '../api'
import { handbook_Type } from '@/constants'
import { removePropObject, removeUndefinedProps } from '@/utils'
import { status } from './../../../setting/log_tool/screens/LogTool'

const UpdatePost = (props) => {
    const { setVisible, setParams, visible, services } = props
    const [infos, setInfos] = useState({
        title: '',
        content: '',
        url_video: '',
        feature: false,
        status: false,
    })
    const [avatar, setAvatar] = useState(null)
    const cate = useLisCate()
    const { id } = useParams()
    const postlDetail = useDetailPost(typeof visible === 'number' ? visible : null)
    useEffect(() => {
        if (postlDetail.id) setInfos({ ...infos, ...postlDetail })
    }, [postlDetail])
    useEffect(() => {
        if (postlDetail.id) {
            setInfos({ ...postlDetail, status: postlDetail.status === 1, feature: postlDetail.feature === 1 })
        }
        if (postlDetail.avatar) setAvatar(postlDetail.avatar)
    }, [postlDetail])
    const handleData = () => {
        let info = { ...infos, feature: infos.feature ? 1 : 0, status: infos.status ? 1 : 0 }
        if (avatar) info.avatar = avatar
        setAvatar(null)
        if (Number(id)) info = { ...removePropObject(info, postlDetail), id: visible }
        return info
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Cập nhật' : 'Thêm mới') + ' Bài viết'}
            handleData={handleData}
            visible={visible}
            route="/building_guide"
            setVisible={setVisible}
            refreshObjects={[setInfos]}
            setParams={setParams}
            actions={{ add: addPostApi, update: updatePostApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            value={infos.title}
                            onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                            label="Tiêu đề(*)"
                        />
                        <DropdownForm
                            id="category"
                            value={infos.bdc_handbook_type_id}
                            onChange={(e) => setInfos({ ...infos, bdc_handbook_type_id: e.target.value })}
                            label="Phân loại(*)"
                            options={handbook_Type}
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="directory"
                            value={infos.bdc_handbook_category_id}
                            onChange={(e) => setInfos({ ...infos, bdc_handbook_category_id: e.target.value })}
                            label="Danh mục"
                            options={cate}
                        />
                        <InputForm
                            id="name"
                            value={infos.url_video}
                            onChange={(e) => setInfos({ ...infos, url_video: e.target.value })}
                            label="Link video"
                        />
                    </div>

                    <div className="col-12">
                        <Editorz
                            id="content"
                            data={infos.content}
                            setData={(e) => setInfos({ ...infos, content: e })}
                            label="Nội dung"
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <UploadImg image={avatar} setImage={setAvatar} title="Avatar" />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputSwitchForm
                            checked={infos.feature}
                            onChange={(e) => setInfos({ ...infos, feature: e.target.value })}
                            label="Bài giới thiệu"
                        />
                        <InputSwitchForm
                            checked={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                            label="Trạng thái"
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}
export default UpdatePost
