import { HeaderListForm } from '@/components'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import { Button, Image, InputText } from '@/uiCore'
import { checkJson } from '@/utils'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useListBookingComment } from '../utils'
import { listCommnentBookingApi } from '../api'
import ReactPlayer from 'react-player'

const Comment = ({ c, setFocused, handleAddComment, setRender, deleteApi, addApi, switchStatusApi }) => {
    const dispatch = useDispatch()
    const [moreComment, setMoreComment] = useState(false)
    const [loadComment, setLoadComment] = useState(false)
    const data = c.files ? checkJson(c.files) || c.files : {}

    const file = (data.files && data.files[0]) || ''
    const image = (data.images && data.images[0]) || ''
    const currentDate = new Date()
    const createdAt = new Date(c.created_at)
    const [childrenComment, setChildrenComment] = useState([])
    let timeDiff = Math.floor((currentDate - createdAt) / 60000)
    if (timeDiff > 60 * 24 * 30 * 12) timeDiff = Math.floor(timeDiff / (60 * 24 * 30 * 12)) + ' năm trước'
    else if (timeDiff > 60 * 24 * 30) timeDiff = Math.floor(timeDiff / (60 * 24 * 30)) + ' tháng trước'
    else if (timeDiff > 60 * 24) timeDiff = Math.floor(timeDiff / (60 * 24)) + ' ngày trước'
    else if (timeDiff > 60) timeDiff = Math.floor(timeDiff / 60) + ' giờ trước'
    else timeDiff = (timeDiff < 0 ? 0 : timeDiff) + ' phút trước'

    async function accept(id) {
        const response = await deleteApi({ id: id })
        if (response.data.status) {
            setRender((e) => !e)
            setLoadComment(prev => !prev)
            dispatch(setToast({ ...listToast[0], detail: 'Xóa bình luận thành công!' }))
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục xóa bình luận này?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => accept(id),
        })
    }

    const handleToogleComment = async () => {
        const response = await listCommnentBookingApi({ post_id: c.post_id, parent_id: c.id })
        if (response.data.status) {
            setChildrenComment(response.data.data)
        }
    }

    useEffect(() => {
        if (moreComment) handleToogleComment()
    }, [loadComment, moreComment])

    return (
        <>
            <div className="flex gap-3 mt-4">
                <img
                    src={c.avatar || '/assets/img/profile.png'}
                    height="32px"
                    width="32px"
                    alt="Ảnh đại diện"
                    style={{ borderRadius: '50%' }}
                ></img>
                <div className="w-full" style={{ wordWrap: 'break-word' }}>
                    <div className="p-2 w-full" style={{ backgroundColor: '#f8f9fa' }}>
                        <h6 className="mb-2">{c.user_name || c.display_name}</h6>
                        {file && (
                            file.endsWith('.mp4') ?
                                <div style={{ height: '300px', overflow: 'hidden' }}>
                                    <ReactPlayer url={file} controls width="100%" height="100%" />
                                </div> :
                                <a target="_blank" href={file}>
                                    {file}
                                </a>
                        )}
                        {image && <Image src={image} height="64px" preview></Image>}
                        {c.content && <p>{c.content}</p>}

                    </div>
                    <div className="flex gap-2 align-items-center mt-2">
                        <Button
                            style={{ color: 'var(--primary-color)', fontSize: '14px', padding: '4px' }}
                            label="Xóa"
                            text
                            onClick={() => {
                                confirm(Number(c.id))
                            }}
                            type="button"
                        />
                        <Button
                            style={{ color: 'var(--primary-color)', fontSize: '14px', padding: '4px' }}
                            label="Trả lời"
                            text
                            type="button"
                            onClick={() => {
                                if (c.parent_id === 0) {
                                    setMoreComment(true)
                                    setFocused(c.id)
                                } else setFocused(c.parent_id)
                            }}
                        />
                        {c.count > 0 && (
                            <Button
                                style={{ color: 'var(--primary-color)', fontSize: '14px', padding: '4px' }}
                                label={moreComment ? 'Ẩn bình luận' : `Xem ${c.count} phản hồi`}
                                text
                                type="button"
                                onClick={() => {
                                    setMoreComment((pre) => !pre)
                                }}
                            />
                        )}
                        {timeDiff && <span style={{ fontSize: '14px' }}>{timeDiff}</span>}
                    </div>



                </div>
            </div >
            <div className="ml-6">
                {moreComment
                    ? childrenComment.map((v) => (
                        <Comment
                            setFocused={setFocused}
                            key={v.id}
                            c={v}
                            setRender={setLoadComment}
                            deleteApi={deleteApi}
                        />
                    ))
                    : ''
                }
                {
                    moreComment && (
                        <SendMessage
                            id={c.id}
                            addApi={addApi}
                            handleAddComment={handleAddComment}
                            setRender={setLoadComment}
                            parent_id={Number(c.id)}
                        />
                    )
                }

            </div >
        </>
    )
}

export const SendMessage = ({ id, handleAddComment, parent_id, setRender, addApi }) => {
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()
    const [message, setMessage] = useState('')
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchData = async (params) => {
        const response = await addApi(handleAddComment ? handleAddComment(params) : params)
        if (response.data && response.status) {
            setMessage('')
            setRender((e) => !e)
            setFile(null)
            setLoading(false)
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const handleSendMess = (e) => {
        e.preventDefault()
        if (loading) return
        setLoading(true)
        const params = {
            parent_id: parent_id || 0,
            content: message,
            file: file && file.type ? file : undefined,
        }
        if (!params.content && !params.image && !params.file) return
        fetchData(params)
    }

    useEffect(() => {
        return () => {
            file && URL.revokeObjectURL(file.preview)
        }
    }, [file])

    const handleOnchange = (e) => {
        const file = e.target.files[0]
        if (!file) return
        file.preview = URL.createObjectURL(file)
        setFile(file)
        e.target.value = null
    }

    return (
        <div className="card">
            <h5>Bình luận của bạn</h5>
            <form onSubmit={handleSendMess} className="w-full flex gap-3 align-items-center my-3">
                <img
                    src={userInfo.avatar || '/assets/img/profile.png'}
                    height="32px"
                    width="32px"
                    alt="Ảnh đại diện"
                    style={{ borderRadius: '50%' }}
                ></img>
                <span className="w-full p-input-icon-right">
                    <i className="flex gap-2 mr-2">
                        <label
                            className="p-button p-fileupload-choose text-center"
                            style={{ padding: '0 12px', border: '0', backgroundColor: 'white' }}
                        >
                            <i className="pi pi-folder" style={{ color: 'rgb(60, 141, 188)' }}></i>
                            <input onChange={handleOnchange} type="file" />
                        </label>
                        <i
                            className="pi pi-send cursor-pointer"
                            disabled={loading}
                            style={{ color: 'var(--primary-color)' }}
                            onClick={(e) => handleSendMess(e)}
                            type="submit"
                        />
                    </i>
                    <InputText
                        id={id}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-full"
                        type="text"
                        placeholder="Nhập tin nhắn ....."
                    />
                </span>
            </form>
            {file && (
                <div className="w-full flex gap-3 align-items-center mt-1">
                    <img width="32px"></img>
                    <span className="w-full p-input-icon-right">
                        <div className="bg-color p-2 w-full">
                            {file && file.type && <Image src={file.preview} height="64px" preview></Image>}
                            {file && !file.type && <a>{file.name}</a>}
                        </div>
                    </span>
                </div>
            )}
        </div>
    )
}

export const Comments = ({ handleAddComment, comments, setRender, addApi, deleteApi, switchStatusApi }) => {
    const [focused, setFocused] = useState(null)
    useEffect(() => {
        if (focused || focused === 0) {
            const input = document.getElementById(focused)
            if (input) {
                input.focus()
                setFocused(null)
            }
        }
    }, [focused])

    return (
        <div className="card">
            <HeaderListForm title="Bình luận" />
            {comments.map((c) => {
                return (
                    <Comment
                        switchStatusApi={switchStatusApi}
                        setFocused={setFocused}
                        addApi={addApi}
                        deleteApi={deleteApi}
                        key={c.id}
                        c={c}
                        handleAddComment={handleAddComment}
                        setRender={setRender}
                    />
                )
            })}
        </div>
    )
}
