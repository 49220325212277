import { HeaderListForm } from '@/components'
import { Image, RadioButton } from '@/uiCore'
import React from 'react'

function ConfigPayment() {
      return (
            <div>
                  <div className=''>
                        <HeaderListForm title="Cấu hình mẫu phiếu thu" />

                        <div className="flex gap-3">
                              <div className="card">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 1</h5>
                                          <RadioButton />
                                    </div>
                                    <Image src="https://devo.vn/wp-content/uploads/2023/01/meo-buon-ba.jpg" width="500px" />
                              </div>
                              <div className="card">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 2</h5>
                                          <RadioButton />
                                    </div>
                                    <Image src="https://devo.vn/wp-content/uploads/2023/01/meo-buon-ba.jpg" width="500px" />
                              </div>
                              <div className="card">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 3</h5>
                                          <RadioButton />
                                    </div>
                                    <Image src="https://devo.vn/wp-content/uploads/2023/01/meo-buon-ba.jpg" width="500px" />
                              </div>
                              <div className="card">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 4</h5>
                                          <RadioButton />
                                    </div>
                                    <Image src="https://devo.vn/wp-content/uploads/2023/01/meo-buon-ba.jpg" width="500px" />
                              </div>
                              <div className="card mb-5">
                                    <div className="flex gap-3 ">
                                          <h5>Mẫu 5</h5>
                                          <RadioButton />
                                    </div>
                                    <Image src="https://devo.vn/wp-content/uploads/2023/01/meo-buon-ba.jpg" width="500px" />
                              </div>




                        </div>




                  </div>
            </div>
      )
}

export default ConfigPayment
