import { TabPanel, TabView } from 'primereact/tabview'
import React, { useState } from 'react'

import Posts from './Posts'
import Directory from './Directory'

export default function BuildingGuide() {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Bài viết">
                    <Posts />
                </TabPanel>
                <TabPanel header="Danh mục">
                    <Directory />
                </TabPanel>
            </TabView>
        </div>
    )
}
