import { getData, postData } from '@/lib/request'

export const listRatedServiceApi = (params) => getData('web2/evaluate/getListEvaluate', params)
export const listDepartmentForRate = (params) => getData('web2/rated_service/listDepartmentForRate', params)
export const listCateEvaluateApi = (params) => getData('web2/cateEvaluate/getListCateEvaluate', params)
export const countRatedServiceApi = (params) => getData('web2/evaluate/countEvaluate', params)
export const deleteRatedServiceApi = (params) => getData('web2/rated_service/deleteRatedService', params)
export const detailRatedServiceApi = (params) => getData('web2/rated_service/getDetailRatedService', params)
export const addRatedServiceApi = (params) => postData('web2/rated_service/addRatedService', params)
export const updateRatedServiceApi = (params) => postData('web2/rated_service/updateRatedService', params)

export const listDetailRatedApi = (params) => getData('web2/rated_service/detailRatedService', params)
export const countDetailRatedApi = (params) => getData('web2/rated_service/countListRatedService', params)

export const listTotalDailyApi = (params) => getData('web2/car_parking/listTotalDaily', params)
export const exportDailyReport = (params) => getData('web2/car_parking/expDailyReport', params, true)
