import { useEffect, useState } from 'react'
import { countAsset, countAssetListCirDist, detailAsset, listAssetListCirDist, listAssetManager, listCategory, listGroup, listUnit, renderQrApi } from '../api'



export const useListAssetManager = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listAssetManager({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useListAssetCirDist = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listAssetListCirDist({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useListUnit = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listUnit({ ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useListGroup = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listGroup({ ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useListCategory = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listCategory({ ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}


export const useDetailAsset = (id) => {
      const [data, setData] = useState({})
      async function fetchData() {
            const response = await detailAsset({ id })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            if (id) fetchData()
      }, [id])
      return data
}
export const useCountAssetv2 = (params) => {
      const [data, setData] = useState(0)
      async function fetchData() {
            const response = await countAsset({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useCountCirDist = (params) => {
      const [data, setData] = useState(0)
      async function fetchData() {
            const response = await countAssetListCirDist({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useRenderQrCode = (ids) => {
      const [data, setData] = useState({})
      async function fetchData() {
            const response = await renderQrApi({ ids: ids })
            console.log(response.data);
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            if (ids) fetchData()
      }, [ids])
      return data
}