import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, useGetParams } from '@/components'
import { statusOpinion } from '@/constants'
import { getArrId } from '@/utils'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteResidentOpinionsApi } from '../api'
import { useCountResidentOpinions, useListCreator, useListResidentOpinions } from '../utils'
import AddResidentOption from './AddResidentOption'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)

    const creator = useListCreator()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tiêu đề, ý kiến"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />

            <Dropdownz
                value={filter.status}
                options={statusOpinion}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
            <Dropdownz
                value={filter.creator_op_id}
                options={creator}
                optionLabel="full_name"
                onChange={(e) => setFilter({ ...filter, creator_op_id: e.target.value })}
                placeholder="Người gửi"
            />
        </GridForm>
    )
}

const ResidentOpinions = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListResidentOpinions({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountResidentOpinions({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const [visible, setVisible] = useState(false)

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'ý kiến',
            actionType: 'xóa',
            action: async (list_id) => await deleteResidentOpinionsApi({ list_id: getArrId(list_id) }),
        },
    ]

    const CreatorBody = (name, createAt) => {
        return (
            <div className="text-center">
                <span>{name}</span> <br />
                <span>{createAt}</span>
            </div>
        )
    }

    const TitleBody = (title, id) => <Link to={'/resident_opinion/detail/' + id}>{title}</Link>
    const limitText = (text, limit) => {
        if (text && text.length > limit) {
            return `${text.slice(0, limit)}...`;
        }
        return text;
    };
    return (
        <div className="card">
            <HeaderListForm title="Danh sách ý kiến" />
            <Header setParams={setParams} />
            {visible && <AddResidentOption visible={visible} setVisible={setVisible} setParams={setParams} />}
            <DataTablez
                title="Ý kiến"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/resident_opinion"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                headerInfo={{ items }}
                noStt
                actionsInfo={{
                    deleteAction: deleteResidentOpinionsApi,
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                    noDialog: true
                }}
                setVisibledDialog={setVisible}
                basePermissions={["insert", "detail", "delete"]}
            >
                <Columnz field="id" header="STT" />
                <Columnz body={(e) => TitleBody(e.title, e.id)} header="Tiêu đề" />
                <Columnz
                    field="content"
                    header="Ý kiến"
                    bodyStyle={{ minWidth: "20rem" }}
                    body={(e) => limitText(e.content, 60)}
                />
                <Columnz field="apartment" header="Căn hộ" />
                <Columnz field="floor" header="Tầng" />
                <Columnz field="name" header="Tòa nhà" />
                <Columnz body={(e) => CreatorBody(e.creator, e.created_at)} header="Người viết" />
                <Columnz body={(e) => Body({ data: statusOpinion, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}

export default ResidentOpinions
