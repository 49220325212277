import {
    ActionBody,
    Body,
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm, Inputz, LoadDialog, NumberFormat, TimeBody,
    useGetParams
} from '@/components'
import { statusReceipt } from '@/constants'
import { Column } from '@/uiCore'
import { formatNumber, getArrId } from '@/utils'
import { ColumnGroup } from 'primereact/columngroup'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ImportFirstTermFee from '../../calculate_debt/screens/ImportFirstTermFee'
import { cancelAccountingApi } from '../api'
import { useCountReceiptImport, useListReceiptImport } from '../utils'
import { ImportPeriod } from './Imports'
import UpdateReceiptImport from './UpdateReceiptImport'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.bank_account}
                onChange={(e) => setFilter({ ...filter, bank_account: e.target.value })}
                placeholder="Tìm kiếm theo số tài khoản ngân hàng"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Calendarz
                value={filter.datez}
                onChange={(e) => setFilter({ ...filter, datez: e.target.value })}
                placeholder="Chọn khoảng thời gian"
            />
            <Dropdownz
                value={filter.status}
                options={statusReceipt}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng Thái"
            />
        </GridForm>
    )
}

const ReceiptImport = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReceiptImport({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReceiptImport({ status: undefined, ...params, first: undefined }) || 0
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const apartments = useSelector((state) => state.apartments)
    const services = useSelector((state) => state.services)
    const users = useSelector((state) => state.users)
    const [visibleImportPeriod, setVisibleImportPeriod] = useState(false)
    const [visibleImportTransaction, setVisibleImportTransaction] = useState(false)
    const [visibleImportFile, setVisibleImportFile] = useState(false)

    const items = [
        {
            label: 'Xác nhận hạch toán',
            icon: 'pi pi-check',
            title: 'phiếu thu',
            actionType: 'xác nhận hạch toán',
            action: async (list_id) => await cancelAccountingApi({ list_id: getArrId(list_id), type: 2 }),
        },
        {
            label: 'Hủy hạch toán',
            icon: 'pi pi-times',
            title: 'phiếu thu',
            actionType: 'hủy hoạch toán',
            action: async (list_id) => await cancelAccountingApi({ list_id: getArrId(list_id), type: 1 }),
        },
    ]
    const moreOptions = [
        {
            type: 'Import',
            serverity: 'warning',
            items: [
                {
                    label: 'Import giao dịch',
                    icon: 'pi pi-table',
                    command: () => setVisibleImportPeriod(true),
                },
                // {
                //     label: 'Import đầu kỳ',
                //     icon: 'pi pi-table',
                //     command: () => setVisibleImportTransaction(true),
                // },
                {
                    label: 'Import phí đầu kỳ',
                    icon: 'pi pi-table',
                    command: () => setVisibleImportFile(true),
                },
            ],
        },
        {
            type: 'Xuất Exel',
            serverity: 'success',
            items: [
                {
                    label: 'Export',
                    icon: 'pi pi-table',
                    command: () => navigate('/'),
                },
            ],
        },
    ]
    const formatDate = (fromDate, toDate) => {
        if (!fromDate || !toDate) {
            return '';
        }
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);

        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
        const formattedFromDate = `${addLeadingZero(fromDateObj.getDate())}/${addLeadingZero(
            fromDateObj.getMonth() + 1,
        )}/${fromDateObj.getFullYear()}`;
        const formattedToDate = `${addLeadingZero(toDateObj.getDate())}/${addLeadingZero(
            toDateObj.getMonth() + 1,
        )}/${toDateObj.getFullYear()}`;
        return (
            <span>
                {formattedFromDate}
                <br />
                đến
                <br />
                {formattedToDate}
            </span>
        );
    };


    const convertTypePayment = (typePay) => {
        if (typePay === 'chuyen_khoan') return 'Chuyển Khoản'
        else if (typePay === 'viet_qr') return 'Việt QR'
        else return 'Chuyển khoản auto'
    }
    const BodyInfo = (info) => {
        const dataTable = JSON.parse(info.detail)
        dataTable?.forEach(item => {
            item.account_balance = info.account_balance || 0;
        });
        const sumNewPaid = () => {
            let total = 0
            if (dataTable) {
                for (let sumPaid of dataTable) {
                    if (sumPaid.new_paid) {
                        total += Number(sumPaid.new_paid)
                    }
                }
            }
            return formatNumber(total)
        }
        const accountBalance = () => {
            let total = 0
            if (dataTable) {
                for (let sumPaid of dataTable) {
                    if (sumPaid.account_balance) {
                        total = Number(sumPaid.account_balance)
                    }
                }
            }
            return formatNumber(total)

        }

        const footerGroup = (
            <ColumnGroup>
                <Row>
                    <Column className="" footer="Tiền thừa" colSpan={4} footerStyle={{ textAlign: 'right' }} />
                    {/* <Column colSpan={0} footer={ } /> */}
                    <Column colSpan={0} footer={accountBalance} />
                </Row>
                <Row>
                    <Column className="" footer="Tổng tiền" colSpan={4} footerStyle={{ textAlign: 'right' }} />
                    <Column colSpan={0} footer={sumNewPaid} />
                </Row>
            </ColumnGroup>
        )

        return (
            <>
                <div className="grid grid-form text-left">
                    <div className="lg:col-6">
                        <strong className="flex flex-column gap-3">
                            <span>
                                Căn hộ : {Body({ data: apartments, value: info.bdc_apartment_id })}
                            </span>
                            <span>Số tiền : {formatNumber(info.cost)}</span>
                        </strong>
                    </div>
                    <div className="lg:col-6">
                        <strong className='flex flex-column gap-3'>
                            <span>
                                Chủ hộ : {info.customer_name}
                            </span>
                            <span>
                                Hình thức : {convertTypePayment(info.type_payment)}
                            </span>
                        </strong>
                    </div>
                </div>

                <DataTable value={dataTable} footerColumnGroup={footerGroup} emptyMessage="Không có kết quả tìm kiếm">
                    <Column
                        body={(e) => {
                            if (e.type === 1 && e.bdc_apartment_service_price_id === 0) {
                                return 'Tiền thừa không chỉ định';
                            } else {
                                return e.name;
                            }
                        }}
                        header="Dịch vụ"
                    />
                    <Column body={(e) => formatDate(e.from_date, e.to_date)} header="Thời gian" />
                    <Column
                        className='text-right'
                        body={(e) => NumberFormat({ value: e.sumery })} header="Phát sinh" />
                    <Column
                        className='text-right'
                        body={(e) => NumberFormat({ value: e.sumery - e.paid })} header="Phải trả" />
                    <Column
                        body={(e) => {
                            if (e.type === 1) {
                                return NumberFormat({ value: (e.coin ? Number(e.coin) : 0) });
                            } else {
                                return NumberFormat({ value: (e.new_paid ? Number(e.new_paid) : 0) });
                            }
                        }}
                        header="Số tiền hạch toán"
                    />

                </DataTable>
            </>
        )
    }

    const BodyTransaction = (rowData) => {
        const transfer_info = JSON.parse(rowData.transfer_info)
        return (
            <div className="text text-left">
                <strong>
                    <span className="flex gap-1">
                        <p>Ngân hàng:</p>
                        <p>
                            {rowData.transfer_info === null ? '' : transfer_info.transfer_bank_name}
                            {/* {transfer_info.transfer_bank_name} */}
                        </p>
                    </span>
                </strong>
                <strong>
                    <span className="flex gap-1">
                        <p>Mã giao dịch:</p>
                        <p>{rowData.ngan_hang}</p>
                    </span>
                </strong>
                <strong>
                    <span className="flex gap-1">
                        <p>Nội dung chuyển khoản:</p>
                        <p>{rowData.remark}</p>
                    </span>
                </strong>
                <strong>
                    <span className="flex gap-1">
                        <p>Ngày chuyển khoản:</p>
                        <p>{TimeBody(rowData.create_date)}</p>
                    </span>
                </strong>
                <hr />
                <strong>
                    <span className="flex gap-1">
                        <p>Import bởi: </p>
                        <p>{rowData.created_by === null || rowData.created_by === 0 ? 'auto' : rowData.created_by}</p>
                    </span>

                </strong>
                <strong>
                    <span className="flex gap-1">
                        <p>Import lúc: </p>
                        <p>{rowData.created_at}</p>
                    </span>
                </strong>
                <strong>
                    <span className="flex gap-1">
                        <p>Người duyệt: </p>
                        <p>{Body({ data: users, value: rowData.user_confirm, label: 'full_name', key: 'user_id' })}</p>
                    </span>
                </strong>
                <strong>
                    <span className="flex gap-1">
                        <p>Thời gian duyệt: </p>
                        <p>{rowData.confirm_date}</p>
                    </span>
                </strong>
            </div>
        )
    }

    return (
        <div className="card">
            <ImportPeriod visible={visibleImportPeriod} setVisible={setVisibleImportPeriod} />
            {/* <ImportTransaction visible={visibleImportTransaction} setVisible={setVisibleImportTransaction} /> */}
            <ImportFirstTermFee visible={visibleImportFile} setVisible={setVisibleImportFile} />
            <LoadDialog visible={visible} />

            {visible && (
                <UpdateReceiptImport
                    params={params}
                    setParams={setParams}
                    visible={visible}
                    setVisible={setVisible}
                    convertTypePayment={convertTypePayment}
                />
            )}
            <HeaderListForm title="Danh sách quản lý hạch toán giao dịch" />
            <Header setParams={setParams} />

            <DataTablez
                value={data}
                title="hạch toán"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/receipt_import"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                setVisibledDialog={setVisible}
                headerInfo={{ items }}
            // actionsInfo
            // basePermissions={['detail']}
            >
                <Columnz header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' body={(e) => Body({ data: statusReceipt, value: e.status })} />
                <Columnz body={(e) => BodyInfo(e)} header="Thông tin hạch toán" className="w-6" />
                <Columnz body={(e) => BodyTransaction(e)} header="Thông tin giao dịch" />
                <Columnz
                    header="Thao tác"
                    body={(e) =>
                        ActionBody({
                            id: e.id,
                            params,
                            setParams,
                            basePermissions: (e.status === 'da_hach_toan' ? [""] : ["detail"]),
                            setVisibledDialog: setVisible,
                            route: "/receipt_import",
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}

export default ReceiptImport
