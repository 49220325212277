import { listExchangeV2Api } from '@/api'
import {
      Body, Columnz,
      DataTablez, GridForm, HeaderListForm, Inputz, TimeBody, useGetParams
} from '@/components'
import { listToast } from '@/constants'
import { useGetApi } from '@/hooks'
import { setToast } from '@/redux/features'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useListAsset, useListAssetMix } from '../../asset_mix_management/utils'
import { listDepartmentWareHouse } from '../../inventory_management/api'
import { deleteAsset, renderQrApi } from '../api'
import { useCountAssetv2, useListAssetManager, useListUnit } from '../utils'






const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({ key_search: '' })


      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
                  <Inputz
                        value={filter.key_search}
                        placeholder="Nhập mã tài sản"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
                  />
                  {/* <Dropdownz
                        value={filter.status}
                        options={statusAsset}
                        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                        placeholder="Chọn phân loại"
                  /> */}
            </GridForm>
      )
}

export default function AssetManagement() {
      const initParam = useGetParams()
      const dispatch = useDispatch()
      const [params, setParams] = useState(initParam)
      const data = useListAssetManager({ status: undefined, ...params, first: undefined })
      const totalRecords = useCountAssetv2({ status: undefined, ...params, first: undefined }) || 0
      const [visible, setVisible] = useState(false)
      const [files, setFiles] = useState([])
      const product = useListAsset({ status: undefined })
      const group = useListAssetMix({ status: undefined })
      const unit = useListUnit()
      const navigate = useNavigate()
      const users = useSelector((state) => state.users)
      const exchanges = useGetApi(listExchangeV2Api)
      const listDepartment = useGetApi(listDepartmentWareHouse)
      const [selectedProducts, setSelectedProducts] = useState([])
      const handlePrint = async (ids) => {
            const response = await renderQrApi({ ids: ids })
            if (response.status) {
                  window.open(`/print/qrcode_asset/[${ids}]`, '_blank')
            } else {
                  dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
            }
      }
      let ids = []
      const moreOptions = [
            {
                  label: 'Cấp phát, luân chuyển tài sản',
                  icon: 'pi pi-arrow-right-arrow-left',
                  command: () => {
                        navigate('/inventory_management/rolate_slip')
                  },
            },

      ]
      if (selectedProducts) {
            for (const s of selectedProducts) {
                  ids.push(s.id)
            }
            if (selectedProducts[0]) {
                  moreOptions.push({
                        label: 'In QR code',
                        icon: 'pi pi-qrcode',
                        command: () => handlePrint(ids),
                  })
            }
      }

      return (
            <div className='card'>


                  <HeaderListForm title="Quản lý tài sản" />
                  <Header setParams={setParams} />
                  <DataTablez
                        value={data}
                        title="tài sản"
                        params={params}
                        setParams={setParams}
                        route="/asset"
                        headerInfo={{ moreOptions }}
                        actionsInfo={{ deleteAction: deleteAsset }}
                        basePermissions={["", "detail", ""]}
                        totalRecords={totalRecords}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                  >
                        <Columnz field="code" header="Mã tài sản" />
                        <Columnz field="code_unit_product" header="Tên tài sản" body={(e) => Body({ data: product, value: e.product_id })} />
                        <Columnz header="Nhóm " body={(e) => Body({ data: group, value: e.product_cate_id })} />

                        <Columnz field="amount" header="Số lượng" />
                        <Columnz field="unit_id" header="Đơn vị"
                              body={(e) => Body({ data: unit, value: e.unit_id })}

                        />
                        <Columnz field="" header="Nhân viên sử dụng" body={(e) =>
                              Body({ data: users, value: Number(e.user_use), key: 'user_id', label: 'full_name' })
                        } />
                        <Columnz
                              header="Phòng ban sử dụng"
                              body={(e) => Body({ data: listDepartment, value: e.department_receipt })}
                        />
                        {/* <Columnz header="Vị trí sử dụng" /> */}
                        {/* <Columnz field="" header="Trạng thái sản phẩm" body={(e) => Body({ data: status_productv2, value: e.status_product })} /> */}

                        <Columnz body={(e) => TimeBody(e.created_at)} header="Thời gian bắt đầu sử dụng" />
                        <Columnz field="note" header="Ghi chú" />

                  </DataTablez>
            </div>
      )
}
