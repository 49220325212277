import { Body, CalendarForm, Columnz, Dialogz, DropdownForm, InputForm, TimeBody } from '@/components'
import { listToast, status_Contract, type_Contract } from '@/constants'
import { useGetApi } from '@/hooks'
import { databaseDate } from '@/lib'
import { CustomModal } from '@/modules/accountant/receipts_expenditures/screens/CreateReceipt'
import { setToast } from '@/redux/features'
import { removePropObject } from '@/utils'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { listPosition } from '../../position_management/api'
import { addContractApi, deleteContractApi, updateContractApi } from '../api'
import { useListContract } from '../utils'

function Contract() {
      const { id } = useParams()
      const [render, setRender] = useState(false)
      const detail = useListContract(id, render)
      const [data, setData] = useState([]);
      const [idRow, setIdRow] = useState(1);
      const [infos, setInfos] = useState({
            number: '',
            name: '',
            reason: ''
      })
      const resetForm = () => {
            setInfos({
                  number: '',
                  name: '',
                  reason: '',
                  type: null,
                  status: null,
                  sign_date: null,
                  expired_date: null
            })

      }
      const [showSuccessDialog, setShowSuccessDialog] = useState(false)

      const [visible, setVisible] = useState(false);
      useEffect(() => {
            setData(detail.bills)
      }, [detail])
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const position = useGetApi(listPosition)

      const handleAddContract = async () => {

            if (!infos.number) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng nhập số hợp đồng' }));
            if (!infos.name) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng nhập tên hợp đồng' }));
            if (!infos.type) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn loại hợp đồng' }));
            if (!infos.status) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn trạng thái hợp đồng' }));
            if (!infos.sign_date) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn ngày ký' }));
            // if (!infos.expired_date) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn ngày hết hạn' }));
            // if (infos.sign_date > infos.expired_date) return dispatch(setToast({ ...listToast[1], detail: 'Ngày hết hạn không được nhỏ hơn ngày ký' }));
            const params = {
                  user_id: Number(id),
                  number: infos.number,
                  name: infos.name,
                  type: String(infos.type),
                  status: infos.status,
                  sign_date: databaseDate(infos.sign_date),
                  expired_date: databaseDate(infos.expired_date),
                  reason: infos.reason,
                  position: infos.position
            };



            const { data } = await addContractApi(params);


            if (!data.status) return dispatch(setToast({ ...listToast[1], detail: data.mess }));
            setRender(prevRender => !prevRender);
            if (data.status) {
                  setVisible(false);
                  resetForm();
                  return dispatch(setToast({ ...listToast[0], detail: 'Thêm hợp đồng thành công' }));
            }


      };

      const handleDeleteContract = async (p) => {
            const params = {
                  user_id: Number(id),
                  id: p
            };
            confirmDialog({
                  message: `Xác nhận xóa hợp đồng`,
                  header: process.env.REACT_APP_BRANCH_NAME,
                  icon: 'pi pi-info-circle',
                  accept: async () => {
                        const { data } = await deleteContractApi(params)
                        setShowSuccessDialog(true)
                        setRender(prevRender => !prevRender)
                  },

            })
      };
      const [editContractId, setEditContractId] = useState(null);
      const [isEditing, setIsEditing] = useState(false);
      const show = () => {
            resetForm()
            setVisible(true)
            setIsEditing(false);
      }
      const editContract = async (contractId) => {
            const contractToEdit = data.find(c => c.id === contractId);
            if (contractToEdit) {
                  setInfos({
                        ...contractToEdit,
                        sign_date: new Date(contractToEdit.sign_date),
                        expired_date: new Date(contractToEdit.expired_date)
                  });
                  setEditContractId(contractId);
                  setIsEditing(true);
                  setVisible(true);
            }

      };

      const handleEditContract = async () => {
            if (!infos.number) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng nhập số hợp đồng' }));
            if (!infos.name) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng nhập tên hợp đồng' }));
            if (!infos.type) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn loại hợp đồng' }));
            if (!infos.status) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn trạng thái hợp đồng' }));
            if (!infos.sign_date) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn ngày ký' }));
            // if (!infos.expired_date) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn ngày hết hạn' }));
            // if (infos.sign_date > infos.expired_date) return dispatch(setToast({ ...listToast[1], detail: 'Ngày hết hạn không được nhỏ hơn ngày ký' }));


            let info = {

                  user_id: Number(id),
                  name: infos.name,
                  type: String(infos.type),
                  status: infos.status,
                  sign_date: databaseDate(infos.sign_date),
                  expired_date: databaseDate(infos.expired_date),
                  reason: infos.reason,
                  position: infos.position,
                  id: editContractId,
                  number: infos.number,
            }
            if (editContractId) {
                  const contractToEdit = detail?.bills.find(c => c.id === editContractId);
                  info = {
                        ...removePropObject(info, contractToEdit),
                        id: editContractId
                  };
            }
            const { data } = await updateContractApi(info);
            if (!data.status) return dispatch(setToast({ ...listToast[1], detail: data.mess }));
            setRender(prevRender => !prevRender);
            if (data.status) {
                  setVisible(false);
                  resetForm();
                  return dispatch(setToast({ ...listToast[0], detail: 'Sửa hợp đồng thành công' }));
            }
      }
      const handleAction = (rowData) => {
            return (
                  <div className='flex gap-2 justify-content-center'>
                        <Button icon="pi pi-file-edit" onClick={() => editContract(rowData.id)} severity='success' />
                        <Button icon="pi pi-trash" onClick={() => handleDeleteContract(rowData.id)} severity='danger' />
                  </div>
            )
      }

      return (
            <div
            >
                  <Button label="Thêm hợp đồng" className='m-3' onClick={show} type='button' />
                  <DataTable value={data} emptyMessage="Không có dữ liệu hợp đồng">
                        <Columnz field="number" header="Số hợp đồng" />
                        <Columnz field="name" header="Tên hợp đồng" />
                        <Columnz body={(e) => Body({ data: type_Contract, value: e.type })} header="Loại hợp đồng" />
                        <Columnz body={(e) => Body({ data: position, value: e.position })} header="Chức vụ" />
                        <Columnz body={(e) => Body({ data: status_Contract, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                              className='text-center' />
                        <Columnz body={(rowData) => TimeBody(rowData.sign_date, 'date')} header="Ngày ký" />
                        <Columnz body={(rowData) => TimeBody(rowData.expired_date, 'date')} header="Ngày hết hạn" />
                        <Columnz field="reason" header="Lý do" />
                        <Columnz body={handleAction} header="Hành động" />

                  </DataTable>
                  <div className='flex justify-content-end pt-3'>
                        <Button label='Trở về' severity="danger" type='button' onClick={() => { navigate('/list_personnel') }} />
                  </div>
                  {showSuccessDialog && (
                        <CustomModal
                              visible={showSuccessDialog}
                              message="Xóa hợp đồng thành công!"
                              header={process.env.REACT_APP_BRANCH_NAME}
                              icon="pi pi-check"
                              acceptClassName="p-button-success"
                              acceptLabel="OK"
                              onHide={() => setShowSuccessDialog(false)}
                        />
                  )}
                  <Dialogz header={isEditing ? "Sửa hợp đồng" : "Thêm hợp đồng"} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                        <div className="card">
                              <div className='grid'>
                                    <div className="col-12 lg:col-6">
                                          <InputForm
                                                label="Số hợp đồng (*)"
                                                value={infos.number}
                                                onChange={(e) => setInfos({ ...infos, number: e.target.value })}
                                          />
                                          <InputForm label="Tên hợp đồng (*)" value={infos.name} onChange={(e) => setInfos({ ...infos, name: e.target.value })} />
                                          <DropdownForm
                                                value={infos.type}
                                                onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                                                label="Loại (*)"
                                                options={type_Contract} />

                                          <DropdownForm label="Trạng thái (*)"

                                                value={infos.status}
                                                onChange={(e) => setInfos({ ...infos, status: e.target.value })}

                                                options={status_Contract}
                                          />
                                    </div>
                                    <div className="col-12 lg:col-6">
                                          <DropdownForm label="Chức vụ"

                                                value={infos.position}
                                                onChange={(e) => setInfos({ ...infos, position: e.target.value })}

                                                options={position}
                                          />
                                          <CalendarForm label="Ngày ký (*)"
                                                value={infos.sign_date}
                                                onChange={(e) => setInfos({ ...infos, sign_date: e.target.value })}
                                          />
                                          <CalendarForm label="Ngày hết hạn"
                                                value={infos.expired_date}
                                                onChange={(e) => setInfos({ ...infos, expired_date: e.target.value })}
                                          />
                                          <InputForm label="Lý do"
                                                value={infos.reason}
                                                onChange={(e) => setInfos({ ...infos, reason: e.target.value })}
                                          />
                                    </div>
                              </div>

                        </div>
                        <div className='flex justify-content-end gap-2'>
                              <Button label='Trở về' severity="danger" typ e='button' onClick={() => setVisible(false)} />
                              <Button
                                    label={isEditing ? "Sửa hợp đồng" : "Thêm hợp đồng"}
                                    type='button'
                                    onClick={isEditing ? handleEditContract : handleAddContract}
                              />
                        </div>
                  </Dialogz>
            </div>
      )
}

export default Contract
