import { addToolAffectApi, detailToolAffectApi, updateToolAffectApi } from '@/api'
import { DropdownForm, FormUpdateDialog, MultiSelectForm } from '@/components'
import { useGetApi } from '@/hooks'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'

const UpdateToolAffect = (props) => {
    const { setVisible, setParams, visible, tools } = props
    const detail = useGetApi(detailToolAffectApi, { id: visible }, {})
    const [infos, setInfos] = useState({})

    useEffect(() => {
        if (detail) {
            setInfos({
                ...detail,
            })
        }
    }, [JSON.stringify(detail)])

    const handleData = () => {
        let info = { ...infos }
        if (!infos.tool_id) return 'Vui lòng chọn quyền!'
        if (!(infos.affect_tool_ids && infos.affect_tool_ids[0])) return 'Vui lòng chọn quyền ảnh hưởng!'
        if (typeof visible === 'number' || typeof visible === 'string')
            info = { ...removePropObject(info, detail), id: visible }
        return info
    }

    return (
        <FormUpdateDialog
            title={
                (typeof visible === 'number' || typeof visible === 'string' ? 'Chi tiết ' : 'Thêm mới ') +
                'quyền ảnh hưởng'
            }
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            route={'/tool_affect'}
            actions={{ add: addToolAffectApi, update: updateToolAffectApi }}
        >
            <DropdownForm
                id="tool_id"
                value={infos.tool_id}
                options={tools}
                onChange={(e) => setInfos({ ...infos, tool_id: e.target.value })}
                label="Quyền (*)"
            />
            <MultiSelectForm
                id="affect_tool_ids"
                value={infos.affect_tool_ids}
                options={tools}
                onChange={(e) => setInfos({ ...infos, affect_tool_ids: e.target.value })}
                label="Quyền ảnh hưởng (*)"
            />
        </FormUpdateDialog>
    )
}

export default UpdateToolAffect
