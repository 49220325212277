import { Columnz, DataTablez, Dropdownz, GridForm, Inputz, LoadDialog, NumberFormat, StatusBody, useGetParams } from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUtilitiesApartmentApi } from '../api'
import { useCountUtilitiesApartment, useListUtilities } from '../utils'
import AddUtilitiesApartment from './AddUtilitiesApartment'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '', floor: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã thu"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const ListUtilitiesApartment = () => {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const depositFee = () => {
        return (
            <>
                Phí booking
            </>
        )
    }
    const generateSampleData = () => {
        const sampleData = []

        for (let i = 1; i <= 10; i++) {
            const record = {
                id: `${i}`,
                service_order: `Dịch vụ #${i}`,
                apartment: `Loại dịch vụ #${i}`,
                create_date: `Mã dịch vụ #${i}`,
                fee: `Mã thu #${i}`,
                received: `Phí áp dụng #${i}`,
                pay: `Phí đặt chỗ #${i}`,
                vehicle_number: `Trạng thái #${i}`,
            }

            sampleData.push(record)
        }

        return sampleData
    }

    const data = useListUtilities({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountUtilitiesApartment({ status: undefined, ...params, first: undefined }) || 0
    const buildings = useSelector((state) => state.buildings)

    return (
        <>
            <LoadDialog visible={visible} />
            {visible === true && (
                <AddUtilitiesApartment setParams={setParams} visible={visible} setVisible={setVisible} />
            )}
            <Header buildings={buildings} setParams={setParams} />
            <DataTablez
                title="tiện ích"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/booking"
                headerInfo
                setVisibledDialog={setVisible}
                actionsInfo={{
                    noDialog: true,
                }}
                basePermissions={["insert", "detail"]}
                hideParams
            >
                <Columnz field="name" header="Tên dịch vụ tiện ích" />
                <Columnz field="collection_code" header="Mã thu" />
                <Columnz field="service_type" header="Loại dịch vụ" body={() => depositFee()} />
                <Columnz
                    field="deposit_fee"
                    bodyStyle={{ textAlign: 'right' }}
                    header="Phí yêu cầu cọc"
                    body={(rowData) => <NumberFormat value={rowData.deposit_fee} />}
                />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/booking',
                            updateAction: updateUtilitiesApartmentApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}

export default ListUtilitiesApartment
