import {
    Body,
    CalendarForm,
    Columnz,
    DataTablezV2,
    Dialogz,
    DropdownForm,
    FormUpdate,
    HeaderListForm,
    InputForm,
    InputNumberForm,
    InputNumberFormV2,
    InputTextareaForm,
    MultiRadioButton,
    TimeBody,
    UploadFile,
} from '@/components'
import { gender, genders, listVehicleService, relationshipOwner, statusApartment } from '@/constants'
import { setApartments } from '@/redux/features/apartments'
import { Button } from '@/uiCore'
import { formatNumber, removePropObject, removeSpecialCharacter, removeUndefinedProps } from '@/utils'
import { TabPanel, TabView } from 'primereact/tabview'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import UpdateApartDocs from './UpdateApartDocs'
import UpdateApartLog from './UpdateApartLog'
import UpdateApartServiceUsage from './UpdateApartServiceUsage'
import {
    addApartmentApi,
    addResidentApi,
    deleteResidentOfApartmentApi,
    detailApartmentApi,
    listApartmentV2Api,
    updateApartmentApi,
} from '@/api'
import { useGetApi } from '@/hooks'
import { setMoreItem } from '@/redux/features'
import { databaseDate } from '@/lib'
import { setUsers } from '@/redux/features/users';
import moment from 'moment'
import { setToast } from '@/redux/features'
import { listToast } from '@/constants'
import AddResidentApartment from './AddResidentApartment'
const initInfos = {

}

const UpdateApartment = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = props
    const [render, setRender] = useState({ render: true })
    const [file, setFile] = useState(null)
    const apartmentDetail = useGetApi(detailApartmentApi, { id: id, render: render.render }, {})
    const [infos, setInfos] = useState(initInfos)
    const buildings = useSelector((state) => state.buildings)
    const [dataResident, setDataResident] = useState([])
    const [dataVehicle, setDataVehicle] = useState([])
    const [data, setData] = useState([])
    const resident = useSelector((state) => state.residents)
    const users = useSelector((state) => state.users)
    const services = useSelector((state) => state.services)
    const [visibled, setVisibled] = useState(false)
    const [user, setUser] = useState({
        full_name: '',
        gender: 1,
        email: '',
        phone: ''

    })


    const resetForm = () => {
        setUser({
            full_name: '',
            gender: 1,
            email: '',
            phone: ''
        })

    }
    const handleAfterCallApi = async () => {
        const getApartments = await listApartmentV2Api()
        if (getApartments.data && getApartments.data.status) {
            const apartments = getApartments.data.data
            dispatch(setApartments(apartments))
        }
    }

    useEffect(() => {
        if (apartmentDetail.detailApartment) {
            const residents = (apartmentDetail.residential && apartmentDetail.residential[0]) ? apartmentDetail.residential.filter(n => Number(n.type) !== 7) : []
            setInfos({
                ...infos,
                ...removeUndefinedProps(apartmentDetail.detailApartment),
                number_customer: residents.length,
                number_vehicle: (apartmentDetail.vehicles && apartmentDetail.vehicles.length) || 0,
                date_handover: apartmentDetail.detailApartment && apartmentDetail.detailApartment.date_handover && new Date(apartmentDetail.detailApartment.date_handover),
                date_use: apartmentDetail.detailApartment && apartmentDetail.detailApartment.date_use && new Date(apartmentDetail.detailApartment.date_use),
            })
        }
        if (apartmentDetail.detailApartment) {
            const newFile = apartmentDetail.detailApartment.file || ""
            if (newFile.includes('.jpg')) {
                setFile({ preview: newFile, type: 'image', name: 'newFile' })
            } else setFile(newFile)
        }
        if (apartmentDetail.residential && apartmentDetail.residential[0])
            setDataResident([...apartmentDetail.residential])
        if (apartmentDetail.vehicles && apartmentDetail.vehicles[0]) setDataVehicle([...apartmentDetail.vehicles])
    }, [apartmentDetail])

    useEffect(() => {
        if (!Number(id)) {
            setInfos({
                ...infos,
                code: removeSpecialCharacter(infos.name),
            })
        }
    }, [infos.name])

    const handleData = () => {
        let info = {
            ...infos,
            date_handover: infos.date_handover && databaseDate(infos.date_handover),
            date_use: infos.date_use && databaseDate(infos.date_use),
        }
        if (!info.building_place_id) return 'Vui lòng chọn tòa nhà!'
        if (!(info.status || info.status === 0)) return 'Vui lòng chọn tình trạng căn hộ!'
        if (Number(info.area) >= 1000000) return 'Vui lòng chọn diện tích nhỏ hơn 1,000,000 m2'
        if (file) info.file = file
        else info.file = []
        if (apartmentDetail.detailApartment?.date_use && !info.date_use) info.date_use = " "
        if (apartmentDetail.detailApartment?.date_handover && !info.date_handover) info.date_handover = " "
        if (Number(id)) info = { ...removePropObject(info, apartmentDetail.detailApartment), id: id }
        return info
    }

    const MapUsers = (rowData) => {
        if (typeof rowData.users === 'string') {
            const usersArray = JSON.parse(rowData.users)
            const userNames = usersArray.map((userId) => {
                const user = resident.find((item) => item.id === userId)
                return user ? user.full_name : 'Không tìm thấy'
            })
            if (Array.isArray(usersArray)) {
                const userNamesString = userNames.join(', ')
                return userNamesString
            }
        }
    }

    const getVehicleType = (bdcServiceId) => {
        const service = services.find(service => service.id === bdcServiceId);
        const typeVehicleId = service ? service.type_vehicle : null;
        const vehicle = listVehicleService.find(vehicle => vehicle.id === typeVehicleId);
        return vehicle ? vehicle.name : '';
    }



    return (
        <FormUpdate
            checkId={Number(id)}
            title="căn hộ"
            handleData={handleData}
            route="/apartment_management"
            actions={{ add: addApartmentApi, update: updateApartmentApi }}
            handleAfterCallApi={handleAfterCallApi}
        >

                    <div className="card bg-color">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    id="name"
                                    value={infos.name}
                                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                    label="Tên căn hộ (*)"
                                    required
                                />
                                <InputForm
                                    id="code"
                                    value={infos.code}
                                    onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                    label="Mã căn hộ (*)"
                                    required={!infos.code && true}
                                />
                                <DropdownForm
                                    id="building_place_id"
                                    value={infos.building_place_id}
                                    options={buildings}
                                    onChange={(e) => setInfos({ ...infos, building_place_id: e.target.value })}
                                    label="Tòa nhà (*)"
                                />
                                <InputForm
                                    id="floor"
                                    value={infos.floor}
                                    onChange={(e) => setInfos({ ...infos, floor: e.target.value })}
                                    label="Số tầng (*)"
                                    required
                                />
                                <InputNumberFormV2
                                    id="area"
                                    value={infos.area}
                                    handleOnChange={(e) => setInfos({ ...infos, area: e })}
                                    label="Diện tích m2 (*)"
                                    required
                                />
                                <CalendarForm
                                    dateFormat="dd/mm/yy"
                                    value={infos.date_handover}
                                    onChange={(e) => setInfos({ ...infos, date_handover: e.target.value })}
                                    label="Ngày bàn giao"
                                />
                                <CalendarForm
                                    dateFormat="dd/mm/yy"
                                    value={infos.date_use}
                                    onChange={(e) => setInfos({ ...infos, date_use: e.target.value })}
                                    label="Ngày sử dụng"
                                />
                                <InputNumberForm label="Nhân khẩu tính giá nước" handleOnChange={(e) => setInfos({ ...infos, number_member: e })} value={infos.number_member} />
                                {/* <InputNumberForm label="Số lượng phòng ngủ" handleOnChange={(e) => setInfos({ ...infos, number_bedroom: e })} value={infos.number_bedroom} /> */}
                            </div>
                            <div className="col-12 lg:col-6">
                                <DropdownForm
                                    id="status"
                                    value={infos.status}
                                    options={statusApartment}
                                    onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                    label="Tình trạng căn hộ (*)"
                                />
                                {id && (
                                    <>
                                        <InputForm
                                            id="name_customer"
                                            value={infos.name_customer}
                                            onChange={(e) => setInfos({ ...infos, name_customer: e.target.value })}
                                            label="Chủ hộ"
                                            disabled
                                        />
                                        <InputNumberForm
                                            id="number_customer"
                                            value={infos.number_customer}
                                            label="Số người ở"
                                            disabled
                                        />
                                        <InputNumberForm
                                            id="number_vehicle"
                                            value={infos.number_vehicle}
                                            label="Số phương tiện"
                                            disabled
                                        />
                                    </>
                                )}
                                <InputForm
                                    id="code_electric"
                                    value={infos.code_electric}
                                    onChange={(e) => setInfos({ ...infos, code_electric: e.target.value })}
                                    label="Mã công tơ điện"
                                />
                                <InputForm
                                    id="code_water"
                                    value={infos.code_water}
                                    onChange={(e) => setInfos({ ...infos, code_water: e.target.value })}
                                    label="Mã công tơ nước"
                                />
                                <InputForm
                                    id="code_water"
                                    value={infos.buyer_name}
                                    onChange={(e) => setInfos({ ...infos, buyer_name: e.target.value })}
                                    label="Họ tên người mua hàng "
                                />
                                <InputForm
                                    id="code_water"
                                    value={infos.unit_name}
                                    onChange={(e) => setInfos({ ...infos, unit_name: e.target.value })}
                                    label="Tên đơn vị"
                                />
                                <InputForm
                                    id="code_water"
                                    value={infos.tax_code}
                                    onChange={(e) => setInfos({ ...infos, tax_code: e.target.value })}
                                    label="Mã số thuế"
                                />
                                <InputForm
                                    id="code_water"
                                    value={infos.address}
                                    onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                    label="Địa chỉ"
                                />
                                <UploadFile file={file} setFile={setFile} />
                            </div>
                        </div>
                        <InputTextareaForm
                            id="note"
                            value={infos.note}
                            onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                            label="Ghi chú"
                        />
                        <InputTextareaForm
                            id="description"
                            value={infos.description}
                            onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                            label="Mô tả"
                        />
                    </div>
                    {id && (
                        <>
                            <div className="card">
                                <HeaderListForm
                                    title={'Cư dân'}
                                    SubTitle={() => (
                                        <Button
                                            onClick={() => {
                                                dispatch(setMoreItem({ apartment_id: id }))
                                                setVisibled(true)
                                            }}
                                            icon="pi pi-plus"
                                            label="Thêm mới"
                                            size="small"
                                            severity="info"
                                            raised
                                            type="button"
                                        />
                                    )}
                                />
                                <AddResidentApartment dataResident={dataResident} visible={visibled} setVisible={setVisibled} render={render} setRender={setRender} id={id} />

                                <DataTablezV2
                                    title="cư dân"
                                    data={dataResident}
                                    className="mb-4"
                                    setData={(id) => setDataResident((pre) => pre.filter((p) => p.id !== id))}
                                    handleDelete={(id_resident) => {
                                        return { id_apartment: id, id_resident }
                                    }}
                                    deleteAction={deleteResidentOfApartmentApi}
                                >
                                    <Columnz field="full_name" header="Họ và tên" />
                                    <Columnz field="phone_contact" header="SĐT" />
                                    <Columnz field="email_contact" header="Email liên hệ" />
                                    <Columnz field="cmt_number" header="Số CMND/CCCD" />
                                    <Columnz
                                        body={(e) => Body({ data: relationshipOwner, value: e.type })}
                                        header="Quan hệ với chủ hộ"
                                    />
                                    <Columnz header="Chi tiết" body={(e) => <Button type="button" icon="pi pi-eye" rounded outlined onClick={() => navigate('/resident_management/detail/' + e.id)} />} />
                                </DataTablezV2>
                            </div>
                            <div className="card">
                                <HeaderListForm title="Phương tiện" />
                                <DataTablezV2
                                    title="phương tiện"
                                    value={dataVehicle}
                                >
                                    <Columnz field="name" header="Tên phương tiện" />
                                    <Columnz
                                        header="Loại"
                                        body={(e) => getVehicleType(e.bdc_service_id)}
                                    />
                                    <Columnz field="number" header="Biển số" />
                                    <Columnz
                                        header="Chủ phương tiện"
                                        body={(e) => Body({ data: resident, value: e.user_id, label: 'full_name' })}
                                    />
                                    <Columnz
                                        header="Người sử dụng"
                                        body={(rowData) => MapUsers(rowData)}
                                        bodyStyle={{ minWidth: '12rem' }}
                                    />
                                    <Columnz field="order" header="Mức ưu tiên" />
                                    <Columnz body={e => formatNumber(e.price)} header="Phí áp dụng" />
                                    <Columnz header="Chi tiết" body={(e) => <Button type="button" icon="pi pi-eye" rounded outlined onClick={() => navigate('/vehicle_manage/detail/' + e.id)} />} />
                                </DataTablezV2>
                            </div>
                        </>
                    )}
        </FormUpdate>
    )
}
export default UpdateApartment
