import { listToast } from '@/constants'
import { setItem, setToast, ToggleLoading } from '@/redux/features'
import { TreeSelect } from 'primereact/treeselect'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuProvider } from './context/menucontext'
import MenuSidebar from './MenuSidebar'

const handleTreeSelect = (data) => {
    let newData = []
    if (data && data[0]) {
        newData = data.map((d) => {
            const children =
                d.buildings && d.buildings[0]
                    ? d.buildings.map((b) => {
                        return { key: d.id + '-' + b.id, label: b.name, icon: 'pi pi-building' }
                    })
                    : []
            return { key: d.id + '', label: d.name, children }
        })
    }
    return newData
}

const HeaderSideBar = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo) || {}
    const { data, setVisible, item } = props
    const [selectedNodeKey, setSelectedNodeKey] = useState(() => {
        return item.building_id ? item.company_id + '-' + item.building_id : item.company_id
    })

    const handleNodeChange = (e) => {
        setSelectedNodeKey(e.value)
        const company_id = e.value && e.value.includes('-') ? e.value.split('-')[0] : e.value
        const building_id = e.value && e.value.includes('-') ? e.value.split('-')[1] : undefined
        const obj = { company_id: Number(company_id), building_id: Number(building_id) }
        localStorage.setItem('item', JSON.stringify(obj))
        dispatch(setToast({ ...listToast[0], detail: 'Đổi dự án thành công!' }))
        dispatch(setItem(obj))
        dispatch(ToggleLoading(true))
        navigate('/')
    }

    return (
        <div className="header-sidebar">
            <div className="w-full grid grid-form align-item-center mb-4 mt-4">
                <div className="col-4 text-right cursor-pointer" onClick={() => setVisible(true)}>
                    <img
                        src={userInfo.avatar || '/assets/img/profile.png'}
                        height="48px"
                        width="48px"
                        alt="Ảnh đại diện"
                        style={{ borderRadius: '50%', border: '4px solid #e9ecef' }}
                    ></img>
                </div>
                <div className="col-8 text-left text-ellipsis">
                    <b>Tên: {userInfo && userInfo.full_name}</b>
                    <div className="mt-1">
                        <i className="pi pi-circle-fill mr-2" style={{ color: '#28a745' }} />
                        <b>Online</b>
                    </div>
                </div>
            </div>
            <div className="w-11 text-color mx-auto mt-0 text-center">
                <label htmlFor="company_project" className="font-bold text-white">
                    Dự án quản lý
                </label>
                <TreeSelect
                    panelStyle={{ maxWidth: '50px', padding: '0' }}
                    inputId="company_project"
                    value={selectedNodeKey}
                    onChange={(e) => handleNodeChange(e)}
                    options={data}
                    className="w-full mt-2"
                    placeholder="Chọn dự án"
                    filter
                    style={{ lineHeight: '30px' }}
                ></TreeSelect>
            </div>
        </div>
    )
}

const AppSidebar = ({ setVisible }) => {
    const myTool = useSelector((state) => state.myTool) || {}
    const item = useSelector((state) => state.item) || {}
    const [model, setModel] = useState([])
    const [data, setData] = useState([])
    const location = useLocation()

    useEffect(() => {
        if (myTool.companies && myTool.companies[0]) {
            const newData = handleTreeSelect(myTool.companies)
            setData([...newData])
        }
        if (myTool.myMenuTool && myTool.myMenuTool[0]) {
            const type = item.building_id ? 'building' : 'company'
            const newData = myTool.myMenuTool.filter((m) => m.type === type || !m.type)
            setModel([{ children: newData }])
        }
    }, [myTool])

    useEffect(() => {
        let title
        const type = item.building_id ? 'building' : 'company'
        const newData = myTool.myMenuTool.filter((m) => m.type === type || !m.type)
        newData.forEach(nd => {
            if (nd.children && nd.children[0]) {
                nd.children.forEach(ndc => {
                    if (location.pathname.includes(ndc.route + '/') || location.pathname === ndc.route) title = ndc.name
                })
            }
        });
        if (title) document.title = title
    }, [location.pathname, myTool])

    return (
        <MenuProvider>
            <HeaderSideBar item={item} setVisible={setVisible} data={data} />
            <ul style={{ paddingBottom: '150px' }} className="layout-menu ">
                {model.map((item, i) => {
                    return <MenuSidebar item={item} root={true} index={i} key={i} />
                })}
            </ul>
        </MenuProvider>
    )
}

export default AppSidebar
