import { listExchangeV2Api } from '@/api';
import { Body, Calendarz, Columnz, Dropdownz, GridForm, HeaderListForm, Inputz, MultiSelectz, TimeBody, useGetParams } from '@/components';
import { useGetApi } from '@/hooks';
import { DataTablez } from '@/modules/parking_supervision/parking_check/screens/ParkingForm';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useListTimeKeeping } from './../utils/index';
import moment from 'moment';
const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({})
      const users = useSelector(state => state.users);
      const exchanges = useGetApi(listExchangeV2Api, {}, [])

      const userExChanges = users.filter(u => filter.department_ids?.includes(u.department_id));
      const userExChangesV2 = userExChanges.map((d) => {
            return { ...d, full_name: d.full_name + "-" + d.code }
      })
      const userV2 = users.map((d) => {
            return { ...d, full_name: d.full_name + "-" + d.code }
      })

      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3" array={["department_ids", "user_ids"]}>
                  <MultiSelectz
                        value={filter.department_ids}
                        onChange={(e) => setFilter({ ...filter, department_ids: e.target.value })}
                        placeholder="Phòng ban"
                        options={exchanges}
                  />
                  <MultiSelectz
                        value={filter.user_ids}
                        onChange={(e) => setFilter({ ...filter, user_ids: e.target.value })}
                        placeholder="Nhân viên"
                        options={filter.department_ids ? userExChangesV2 : userV2}
                        optionLabel="full_name"
                        optionValue="user_id"
                  />

                  <Calendarz
                        value={filter.dateq}
                        onChange={(e) => setFilter({ ...filter, dateq: e.value })}
                        placeholder={"Chọn khoảng thời gian "}
                        selectionMode="range"
                  />
            </GridForm>
      )
}
function MachineWork() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useListTimeKeeping({ status: undefined, ...params, first: undefined })
      const users = useSelector(state => state.users);
      const exchanges = useGetApi(listExchangeV2Api, {}, [])
      const getDayOfWeek = (dateString) => {
            const date = new Date(dateString);
            const days = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
            return days[date.getDay()];
      }
      // function sendRequest(time, type) {
      //       const url = 'https://devapibdc.dxmb.vn/app2/shift/checkTimekeeping';
      //       const headers = {
      //             'Accept': 'application/json, text/plain, */*',
      //             'Authorization': 'Bearer Nzk5MDQgMTcxMjcxMzg1ODQ2NCAwbWxxag==',
      //             'Infor': '{"client_id":"BDCBQL-8328-6073-0810-0424","build":"3","device_name":"goldfish_x86_64","deivce_name":"goldfish_x86_64","native_ver":"3.0.0","os":"android","os_ver":"13","bundle_id":"com.stech.bdcims","type":"user","js_ver":"25","building_id":1015,"company_id":83}',
      //             'Content-Type': 'application/x-www-form-urlencoded',
      //             'Cookie': 'SERVERUSED=s1'
      //       };
      //       const body = new URLSearchParams({
      //             'time': time,
      //             'type': type,
      //             'shift_code': 'AFTN'
      //       });

      //       fetch(url, {
      //             method: 'POST',
      //             headers: headers,
      //             body: body
      //       })
      //             .then(response => response.json())
      //             .then(data => console.log(data))
      //             .catch(error => console.error('Error:', error));
      // }

      // useEffect(() => {
      //       const date = new Date();
      //       for (let index = 0; index < 30; index++)
      //       {
      //             (async () => {
      //                   await sendRequest(moment(new Date(date.getFullYear(), date.getMonth() - 1, index + 1, 8, index, 0)).format('YYYY-MM-DD HH:mm:ss'), 'in');
      //                   await sendRequest(moment(new Date(date.getFullYear(), date.getMonth() - 1, index + 1, 11, 30 + index, 0)).format('YYYY-MM-DD HH:mm:ss'), 'out');
      //             })();
      //       }
      // }, []);
      return (
            <div className='card'>
                  <HeaderListForm title="Bảng công máy" />

                  <Header setParams={setParams} />
                  <DataTablez
                        title="công lương"
                        params={params}
                        setParams={setParams}
                        route="/timekeeping_management"
                        totalRecords={data.count}
                        value={data.data}
                  >
                        <Columnz header="Mã NV" body={(e) => Body({ data: users, value: e.by, label: 'code', key: 'user_id' })} />
                        <Columnz header="Họ và Tên" body={(e) => Body({ data: users, value: e.by, label: 'full_name', key: 'user_id' })} />
                        <Columnz header="Phòng ban"
                              body={(e) => Body({ data: exchanges, value: e.department_id, })}
                        />
                        <Columnz header="Thứ" body={(e) => getDayOfWeek(e.date)} />
                        <Columnz header="Ngày " body={(e) => TimeBody(e.date, 'date')} />
                        <Columnz header="Ca làm việc" field="shift_code" />
                        <Columnz header="Giờ vào" field="time_in" />
                        <Columnz header="Giờ ra" field="time_out" />
                        <Columnz header="Đi muộn" field="late" />
                        <Columnz header="Về sớm" field="soon" />
                        <Columnz header="Tổng thời gian" field="total_time" />
                        <Columnz header="Tính công" field="total_work" />
                  </DataTablez>
            </div>
      )
}

export default MachineWork
