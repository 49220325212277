import { postData, getData } from '@/lib/request'
import {getDataV2} from "@/lib/axios";

export const listDebit = (params) => getDataV2('web2/aggregate_debt/getListAggregateDebt', params)
export const countAggregateDebt = (params) => getDataV2('web2/aggregate_debt/countAggregateDebt', params)
export const sumTotal = (params) => getData('web2/aggregate_debt/getAggregateDebtSumary', params)
export const detailDebtApi = (params) => getData('web2/company/getDetailDebt', params)
export const exportDebt = (params) => getData('web2/aggregate_debt/expListDebit', params, true)
export const nhacNoApi = (params) => postData('/web2/debit_detail/nhacNo', params)
export const getRuleDebtApi = (params) => getData('/web2/info/getRuleDebt', params)


