import { getData, postData } from '@/lib/request'

export const debtCalculationApi = (params) => postData('web2/debt/debtCalculation', params)
export const debtAriseCalculationApi = (params) => postData('web2/debt/debtAriseCalculation', params)
export const importFirstTermFee = (params) => postData('web2/debt/importServiceCharge', params, false, true)
export const getListLogDebt = (parrams) => getData('web2/debt/getListLogDebt', parrams)
export const detailLogDebt = (parrams) => getData('web2/debt/detailLogDebt', parrams)
export const countLogDebt = (parrams) => getData('web2/debt/countLogDebt', parrams)
export const debtCalculationWaterApi = (parrams) => postData('web2/debt/debtCalculationWater', parrams)
export const getCheckDebtApi = (parrams) => getData('web2/debt/getListDebtServiceHanover', parrams)
export const updateDebtCheckApi = (parrams) => postData('web2/debt/updateDebtCheck', parrams)


// Log công nợ
export const listDebtLogApi = (params) => getData('web2/debt/getListDebtLog', params)
export const listDebitApi = (params) => getData('web2/debit_detail/getListDebitDetail', params)
export const getCountLogDebtDetail = (params) => getData('web2/debt/countLogDebtDetail', params)
