import {
    CalendarForm,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm, TimeBody, useGetParams
} from '@/components'
import { listToast, statusPeriods } from '@/constants'
import { databaseDate } from '@/lib'
import { setToast } from '@/redux/features'
import { Button, Dialog, InputSwitch } from '@/uiCore'
import { confirmDialog } from 'primereact/confirmdialog'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useListMonth } from '../../electric_water_meter/utils'
import { updateStatusPeriodManageApi } from '../api'
import { useCountPeriodManage, useListPeriodManage } from '../utils'
import AddPeriod from './AddPeriod'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const cycle = useListMonth()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Dropdownz
                value={filter.cycle_name}
                options={cycle}
                onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                placeholder="Chọn kỳ"
                optionLabel="cycle_name"
                optionValue="cycle_name"
            />
            <Dropdownz
                value={filter.status}
                options={statusPeriods}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}
const CustomDialog = ({ visible, onHide, onAccept }) => {
    const [selectedDate, setSelectedDate] = useState('')
    const handleAccept = () => {
        onAccept(selectedDate)
        onHide()
    }

    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            header="Mở khóa kỳ"
            position="top"
            footer={
                <div>
                    <Button label="Hủy bỏ" icon="pi pi-times" onClick={onHide} className="p-button-text" />
                    <Button label="Xác nhận" icon="pi pi-check" onClick={handleAccept} autoFocus />
                </div>
            }
        >
            <div>
                <CalendarForm
                    onChange={(e) => {
                        setSelectedDate(e.value)
                    }}
                    showTime
                    hourFormat="24"
                    showIcon
                    dateFormat="dd/mm/yy"
                    label="Hẹn giờ khóa kỳ"
                />
                <div>Hẹn giờ để hệ thống tự động bật lại khóa kỳ. Tránh trường hợp quên bật khóa kỳ</div>
            </div>
        </Dialog>
    )
}

const StatusBodyV2 = ({ e, route, updateAction, setParams = () => { } }) => {
    const permission = useSelector((state) => state.myTool).myTool
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(() => Boolean(e.status))
    const [visible, setVisible] = useState(false)

    const accept1 = async (date) => {
        if (!date) {
            dispatch(
                setToast({
                    ...listToast[1],
                    detail: 'Bạn chưa chọn ngày!',
                }),
            )
            return
        }
        const restart_date = date ? databaseDate(date) : undefined
        const currentDate = new Date()
        const timeDifference = Math.abs(currentDate.getTime() - date.getTime())
        const hoursDifference = timeDifference / (1000 * 3600)
        if (date < currentDate) {
            dispatch(
                setToast({
                    ...listToast[1],
                    detail: 'Ngày đã chọn nhỏ hơn thời gian hiện tại!',
                }),
            )
            return
        }
        if (hoursDifference >= 48) {
            dispatch(
                setToast({
                    ...listToast[1],
                    detail: 'Thời gian bật lại không được lớn hơn 48h so với thời gian hiện tại!',
                }),
            )
            return
        }
        const params = { id: e.id, status: 0, schedule_active: restart_date }
        const response = await updateAction(params)
        if (response.data.status) {
            setChecked(!checked)
            setParams(pre => ({ ...pre, render: !pre.render }))
            dispatch(setToast({ ...listToast[0], detail: 'Đổi trạng thái thành công!' }))
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
        }
    }

    const accept = async () => {
        const params = { id: e.id, status: 1 }
        const response = await updateAction(params)
        if (response.data.status) {
            setChecked(!checked)
            setParams(pre => ({ ...pre, render: !pre.render }))
            dispatch(setToast({ ...listToast[0], detail: 'Đổi trạng thái thành công!' }))
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
        }
    }
    const confirm = () => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi trạng thái?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept,
        })
    }
    const showDialog = () => {
        if (checked) {
            setVisible(true)
        } else {
            confirm()
        }
    }

    const hideDialog = () => {
        setVisible(false)
    }
    return (
        <>
            {visible && <CustomDialog visible={visible} onHide={hideDialog} onAccept={accept1} />}
            <InputSwitch
                disabled={!updateAction || !route || !permission.includes(route + '/update')}
                checked={checked}
                onChange={showDialog}
            />
        </>
    )
}
const PeriodManages = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPeriodManage({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPeriodManage({ status: undefined, ...params, first: undefined }) || 0
    const users = useSelector((state) => state.users)
    const [visible, setVisible] = useState(false)

    return (
        <div className="card">
            {visible === true && <AddPeriod setParams={setParams} visible={visible} setVisible={setVisible} />}
            <HeaderListForm title="Quản lý khóa kì" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="khóa kỳ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/period_management"
                headerInfo
                setVisibledDialog={setVisible}
                basePermissions={["insert"]}
            >
                <Columnz field="cycle_name" header="Khóa kì" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBodyV2({
                            e,
                            route: '/period_management',
                            updateAction: updateStatusPeriodManageApi,
                            setParams: setParams
                        })
                    }
                />
                <Columnz
                    body={(e) => {
                        if (!e.created_by) return <div>
                            Auto
                            <p>{TimeBody(e.updated_at)}</p>
                        </div>
                        else {
                            const user = users.find(u => u.user_id === e.created_by) || {}
                            return <div>
                                {user.full_name || "Admin"}
                                <p>{TimeBody(e.updated_at)}</p>
                            </div>
                        }
                    }}
                    header="Cập nhật"
                />
            </DataTablez>
        </div>
    )
}

export default PeriodManages
