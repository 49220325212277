import {
    Body, Columnz,
    DataTablez,
    Dropdownz,
    GridForm, HeaderListForm, LoadDialog, useGetParams
} from '@/components'
import { formatNumber } from '@/utils'
import { DataTable } from 'primereact/datatable'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { exportApi } from '../api'
import { useCountExcessMoney, useListExcessMoney } from '../utils'
import DetaiServicesMoney from './DetaiServicesMoney'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    const services = useSelector((state) => state.services)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.service_id}
                options={services}
                onChange={(e) => setFilter({ ...filter, service_id: e.target.value })}
                placeholder="Chọn dịch vụ"
            />
        </GridForm>
    )
}
export default function ExcessMoney() {
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState(initParam)
    const data = useListExcessMoney({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountExcessMoney({ status: undefined, ...params, first: undefined }) || 0
    const buildings = useSelector((state) => state.buildings)
    const services = useSelector((state) => state.services)
    const treeTableTemplateService = (rowData) => {
        const filteredData = rowData.data.filter((e) => e.coin !== 0)
        const ServiceBody = (e) => {
            const service = services.find((s) => s.id === e.service_id)
            if (service) {
                if (e.number) return <span>{service.name + '_' + e.number}</span>
                else return <span>{service.name}</span>
            } else return <span>Tiền thừa không chỉ định</span>
        }
        return (
            <DataTable value={filteredData}>
                <Columnz body={ServiceBody} headerStyle={{ display: 'none' }}></Columnz>
            </DataTable>
        )
    }
    const treeTableTemplateCoin = (rowData) => {
        const filteredData = rowData.data.filter((e) => e.coin !== 0)
        return (
            <DataTable value={filteredData}>
                <Columnz body={(e) => formatNumber(e.coin)} headerStyle={{ display: 'none' }} />
            </DataTable>
        )
    }

    return (
        <div className="card">
            {visible && (
                <DetaiServicesMoney data={data} setParams={setParams} visible={visible} setVisible={setVisible} />
            )}
            <LoadDialog visible={visible} />
            <HeaderListForm title="Quản lý tiền thừa" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                dataKey="bdc_apartment_id"
                route="/excess_money"
                title="tiền thừa"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                actionsInfo
                headerInfo={{ exportApi: exportApi }}
                setVisibledDialog={setVisible}
                basePermissions={['detail', 'export']}
            >
                <Columnz
                    header="Căn hộ"
                    body={(e) => (
                        <Link to={`/excess_money_details?apartment_id=${e.bdc_apartment_id}`}>{e.apartment_name}</Link>
                    )}
                />
                <Columnz field="apartment_code" header="Mã căn hộ" />
                <Columnz body={(e) => Body({ data: buildings, value: e.building_place_id })} header="Tòa" />
                <Columnz field="customer_name" header="Khách hàng" />
                <Columnz body={(e) => formatNumber(e.total)} header="Tổng" />
                <Columnz body={treeTableTemplateService} header="Dịch vụ"></Columnz>
                <Columnz body={treeTableTemplateCoin} header="Tiền thừa hiện tại"></Columnz>
            </DataTablez>
        </div>
    )
}
