import { Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { GirdChart, RoundChartFlexCol } from '@/components/Chart'
import { useState } from 'react'
import { useGetCountVehicle, useGetStatBuilding, useGetStatVehicle } from '../../../dashboard/utils'
import { useSelector } from 'react-redux'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const building_place_id = useSelector((state) => state.buildings)
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Dropdownz
                value={filter.building_place}
                options={building_place_id}
                onChange={(e) => setFilter({ ...filter, building_place: e.target.value, apartment_id: undefined })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={filter.building_place ? apartments.filter(a => a.building_place_id === filter.building_place) : apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
        </GridForm>
    )
}
function AggregateBuildingData() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const statBuilding = useGetStatBuilding(params)
    const statVehicle = useGetStatVehicle(params)
    const countVehicle = useGetCountVehicle(params)

    const dataSubset = countVehicle.slice(1, 6).map((item, index) => ({
        count: item.count,
        key: index,
    }))

    return (
        <div>
            <div className="p-card p-4 mb-3">
                <HeaderListForm title="Báo cáo thông tin căn hộ tòa nhà" />
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-3 ">
                        <div className="text-center p-3 surface-border surface-overlay border-3 border-round font-bold" >
                            <div className="flex  align-items-center ">
                                <div className="flex-auto ">
                                    <h5 className="">{statBuilding.apartment}</h5>
                                    <h5 className="m-0">Căn hộ</h5>
                                </div>
                                <div className="flex-auto ">
                                    <img
                                        className=" pr-3 align-items-center flex"
                                        style={{
                                            fontSize: '4rem',
                                        }}
                                        src={`${process.env.PUBLIC_URL}/assets/img/House.png`}
                                        alt="Căn hộ"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3">
                        <div className="text-center p-3 surface-border surface-overlay border-3 border-round font-bold" >
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="">{statBuilding.user}</h5>
                                    <h5 className="m-0">Cư dân</h5>
                                </div>
                                <div className="flex-auto ">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/Citizen.png`}
                                        alt="Cư dân"
                                        className=" pr-3 align-items-center flex"
                                        style={{
                                            color: '#green',
                                            fontSize: '4rem',
                                        }}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3">
                        <div className="text-center p-3 surface-border surface-overlay border-3 border-round font-bold" >
                            <div className="flex align-items-center ">
                                <div className="flex-auto ">
                                    <h5 className="">{statBuilding.login_app}</h5>
                                    <h5 className="m-0">Đăng nhập</h5>
                                </div>
                                <div className="flex-auto ">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/LoginAccount.png`}
                                        alt="Tài khoản đã đăng nhập"
                                        className="pr-3 align-items-center flex"
                                        style={{
                                            color: '#FF0000',
                                            fontSize: '4rem',
                                        }}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-3">
                        <div className="text-center p-3 surface-border surface-overlay border-3 border-round font-bold" >
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="">{statBuilding.vehicle}</h5>
                                    <h5 className="m-0">Phương tiện</h5>
                                </div>
                                <div className="flex-auto ">
                                    <img
                                        className=" pr-3 align-items-center flex h-1"
                                        style={{
                                            fontSize: '1rem',
                                        }}
                                        src={`${process.env.PUBLIC_URL}/assets/img/Vehicle.png`}
                                        alt="Phương tiện"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" pt-2 pb-3">
                    <h4 className=" pt-3">Thống kê phương tiện</h4>
                    <Header setParams={setParams} />
                </div>
                <div className="grid border-top-1 border-400">
                    <div className="col-12 md:col-4 lg:col-4">
                        <RoundChartFlexCol
                            title="Phương tiện"
                            color={['#bde7fe', '#A5D7FB', '#76c1f3', '#56a2f7', '#220186']}
                            type="doughnut"
                            data={dataSubset}
                            keyChart="count"
                            aspectRatio={'0.91'}
                            labels={['Phương tiện khác', 'Xe đạp', 'Xe máy', 'Ô tô', 'Xe điện']}
                        />
                    </div>
                    <div className="col-12 md:col-8 lg:col-8">
                        <GirdChart
                            title={'Lượng xe vào ra'}
                            color={['#57A2F9', '#010f96']}
                            label={['Vào', 'Ra']}
                            dataChart={statVehicle}
                            keyData={['register', 'cancel']}
                            labelChart="date"
                            maxYValue={''}
                            chartType={'bar'}
                            stepSize={''}
                            yAxisText="Xe"
                            aspectRatio={'0.8'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AggregateBuildingData
