import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useRenderReceipt } from '../utils'

export const PrintSlip = () => {
    const { id } = useParams()
    const data = useRenderReceipt(id)
    return (
        <div className='flex justify-content-center align-items-center' >
            {
                data &&
                typeof data === 'string' && (
                    <div style={{ width: "1140px", fontFamily: 'DejaVu Sans' }} dangerouslySetInnerHTML={{ __html: data }} />
                )
            }
        </div>
    )
}
