import {
    CalendarForm,
    DropdownForm,
    FormUpdateDialog,
    HeaderListForm,
    InputForm,
    InputNumberForm,
    Inputz,
} from '@/components'
import React, { useEffect, useState } from 'react'

import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import { addAllocationApi } from '../api'
import { useParams } from 'react-router-dom'
import { formatNumber, removePropObject, removeUndefinedProps } from '@/utils'
import { apartments } from '@/redux/features/apartments'
import { useListServiceApartments } from '@/modules/accountant/receipts_expenditures/utils'
const Price = ({ value, handleDelete, handleOnChange, index, matchingData, services, listServicesApartment }) => {
    const dataServices = matchingData.data


    const result = dataServices
        .map((dataService) => {
            if (dataService.service_id === 0) {
                return { service_id: 0, name: 'Chưa chỉ định' }
            }
            const service = services.find((service) => service.id === dataService.service_id)
            return service ? { service_id: dataService.service_id, name: service.name } : null
        })
        .filter((item) => item !== null)
    return (
        <div>
            <div className="flex gap-4 align-items-center pt-3">
                <div className="grid grid-form w-11 ">
                    <div className="col-12 lg:col-3 p-0">
                        <DropdownForm
                            options={result}
                            optionValue="service_id"
                            onChange={(e) => handleOnChange(index, { from: e.target.value })}
                            value={value.from}
                            label="Nguồn chuyển"
                        />
                    </div>

                    <div className="col-12 lg:col-3 p-0">
                        <DropdownForm

                            onChange={(e) => handleOnChange(index, { to: e.target.value })}
                            value={value.to}
                            label="Nguồn nhận"
                            options={listServicesApartment}
                        />
                    </div>

                    <div className="col-12 lg:col-3 p-0">
                        <InputNumberForm
                            value={value.coin}
                            handleOnChange={(e) => handleOnChange(index, { coin: e })}
                            label="Giá tiền"
                        />
                    </div>
                    <div className="col-12 lg:col-3 p-0">
                        <CalendarForm
                            value={value.cycle_name}
                            onChange={(e) => handleOnChange(index, { cycle_name: e.target.value })}
                            label="Ngày hạch toán"
                        />
                    </div>
                </div>
                <div className="mt-2 w-1 text-center">
                    <Button
                        type="button"
                        raised
                        icon="pi pi-times"
                        severity="info"
                        onClick={() => handleDelete(index)}
                    />
                </div>
            </div>

            {value.mess && (
                <p className="text-sm pl-1" style={{ color: 'red' }}>
                    {value.mess}
                </p>
            )}
        </div>
    )
}
const DetaiServicesMoney = (props) => {
    const [newData, setNewData] = useState([])

    const { visible, setVisible, setParams, data } = props
    const matchingData = data.find((item) => item.bdc_apartment_id === visible)
    const listServicesApartment = useListServiceApartments({ apartment_id: visible })

    const matchingDataLate = matchingData.data
    const services = useSelector((state) => state.services)
    const inputForms = matchingData
        ? matchingData.data.map((item, index) => {
            const matchingService = services.find((service) => service.id === item.service_id)
            const label = item.service_id === 0 ? 'Chưa chỉ định' : matchingService ? matchingService.name : 'Unknown'
            const formattedValue = formatNumber(item.coin)

            const key = `${index}_${item.service_id}`
            if ((matchingService || item.service_id === 0) && item.coin !== 0) {
                return <InputForm key={key} label={label} value={formattedValue} disabled />
            }

            return null
        })
        : null
    const formattedTotal = matchingData?.total || 0
    const handleOnChange = (index, data) => {
        const dataV2 = newData
        dataV2[index] = { ...dataV2[index], ...data }
        const from = matchingDataLate.find((service) => service.service_id === dataV2[index].from)
        if (dataV2[index].from === dataV2[index].to) {
            dataV2[index] = { ...dataV2[index], mess: 'Nguồn chuyển và nguồn nhận không được trùng nhau ' }
        } else if (dataV2[index].from && !from.coin) {
            dataV2[index] = { ...dataV2[index], mess: 'Nguồn chuyển hiện tại không có tiền ' }
        } else {
            dataV2[index] = { ...dataV2[index], mess: '' }
        }
        setNewData([...dataV2])
    }
    const handleDelete = (indexV2) => {
        if (indexV2 || indexV2 === 0) setNewData((pre) => pre.filter((d, index) => index !== indexV2))
    }
    const handleAdd = () => {
        const newItem = {}
        setNewData((prevData) => [...prevData, newItem])
    }
    const handleData = () => {
        let error = false
        if (newData && newData[0]) {
            newData.forEach((d, index) => {
                if (d.mess) {
                    error = d.mess
                    return
                }
            })
        }
        if (error) return error
        return { data: newData, apartment_id: visible }
    }
    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            route="/excess_money"
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'Tiền thừa'}
            actions={{ update: addAllocationApi }}
            handleData={handleData}
        >
            <HeaderListForm title={`Căn hộ ${matchingData.apartment_name}`} />
            <div className="grid grid-form align-items-center">
                <div className="col-12 ">
                    <InputForm label="Tổng tiền thừa" value={formatNumber(formattedTotal)} disabled />
                    {inputForms}
                </div>
            </div>
            <Button
                type="button"
                raised
                icon="pi pi-plus"
                severity="info"
                label="Thêm phân bổ mới"
                onClick={() => handleAdd()}
                className="ml-2"
            />
            {newData.map((d, index) => {
                return (
                    <Price
                        key={index}
                        index={index}
                        handleDelete={handleDelete}
                        handleOnChange={handleOnChange}
                        value={d}
                        data={data}
                        matchingData={matchingData}
                        services={services}
                        listServicesApartment={listServicesApartment}
                    />
                )
            })}
        </FormUpdateDialog>
    )
}

export default DetaiServicesMoney
