import { useEffect, useState } from 'react'
import { listCompanyApi, countCompanyApi, detailCompanyApi } from '../api'

export const useListCompany = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCompanyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountCompany = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countCompanyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailCompany = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailCompanyApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
