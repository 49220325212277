import { useEffect, useState } from 'react'
import { getListBlackList, countBlackList, deleteBlackListApi, detailBlackListApi } from '../api'

export const useListBlackList = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await getListBlackList({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountBlackList = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBlackList({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useDetailBlackList = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailBlackListApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
