import { detailApartmentApi } from '@/api'
import {
    CalendarForm,
    Columnz,
    DataTablez, DropdownForm,
    HeaderListForm,
    InputForm,
    InputNumberForm,
    InputTextareaForm, NumberFormat,
    TimeBody,
    useGetParams
} from '@/components'
import { customer_payments_type, listToast } from '@/constants'
import { useGetApi } from "@/hooks"
import { databaseDate } from '@/lib'
import { setToast } from '@/redux/features'
import { formatNumber } from '@/utils'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addReceipt, genQrCode, listAccountApi, listDebitReceipt } from '../api'
import { useCountReceiptExpenditure } from '../utils'
import AddDebtForm from '@/modules/accountant/receipts_expenditures/screens/AddDebtForm'
export const initInfos = {
    bdc_apartment_id: null,
    arr_pay: [],
    account_balance: 0,
    create_date: new Date(),
    type_perform: 1,
    type_receipt: 'phieu_thu',
    customer_name: null,
    address: null,
}
export const CustomModal = ({ visible, message, header, icon, acceptClassName, acceptLabel, onAccept, onHide }) => {
    const handleAccept = () => {
        onHide()
        onAccept && onAccept()
    }

    return (
        <Dialog
            visible={visible}
            style={{ width: '50vw' }}
            onHide={onHide}
            closable={false}
            modal
            header={header}
            icon={icon}
        >
            <div className='text-2xl m-3'>{message}</div>
            <div className=" flex flex-row-reverse flex-wrap p-mt-4 p-d-flex p-jc-end">
                <Button label={acceptLabel} className={acceptClassName} onClick={handleAccept} />
            </div>
        </Dialog>
    )
}
export default function CreateReceipt() {
    const listAccount = useGetApi(listAccountApi, {}, [])
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const totalRecords = useCountReceiptExpenditure({ status: undefined, ...params, first: undefined }) || 0
    const [data, setData] = useState([])
    const [infos, setInfos] = useState({
        bdc_apartment_id: null,
        arr_pay: [],
        account_balance: 0,
        create_date: new Date(),
        type_perform: 1,
        type_receipt: 'phieu_thu',
        address: null,
    })
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    // const apartments = useListApartmentName()
    const apartments = useSelector((state) => state.apartments)

    const [listDebits, setListDebits] = useState([])
    const [listCoin, setListCoin] = useState([])
    const dispatch = useDispatch()

    const [moneyPlaceHolder, setMoneyPlaceHolder] = useState(null)
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [qrCode, setQrCode] = useState('')
    const [code_pay, setCode_pay] = useState('')

    const services = useSelector((state) => state.services)

    const nghiep_vu = [
        { id: 1, name: 'Thu tiền dịch vụ' },
        { id: 2, name: 'Hạch toán dịch vụ' },
    ]

    const receipt_type = [
        { id: 'phieu_thu', name: 'Phiếu thu' },
        { id: 'phieu_bao_co', name: 'Phiếu báo có' },
        { id: 'phieu_ke_toan', name: 'Phiếu kế toán' },
    ]
    useEffect(() => {
        if (data.listDebits && data.listDebits[0]) setListDebits(data.listDebits)
        else setListDebits([])
        if (data.listCoin && data.listCoin[0]) {
            data.listCoin.forEach((coin) => {
                if (services) {
                    services.forEach(service => {
                        if (!coin.name && coin.bdc_service_id === service.id) coin.name = service.name
                    })
                }
                coin.init_coin = coin.coin
                coin.remain_coin = coin.coin

                coin.name_coin = `${coin.name} (Tổng: ${formatNumber(coin.coin)}  VND) `
            })
            setListCoin(data.listCoin)
        }
    }, [data])
    const handleRemoveZeroProduct = () => {
        let arrRemoveZero = []
        selectedProducts.forEach(product => {
            if (product.new_paid === 0) {
                arrRemoveZero.push(product)
                dispatch(setToast({ ...listToast[2], detail: 'Không thể chọn dịch vụ có thanh toàn bằng 0 khi đã nhập số tiền' }))
            }
        })
        setSelectedProducts(prev => prev.filter(pro => !arrRemoveZero.includes(pro)))
    }

    useEffect(() => {
        let total = 0
        infos.arr_pay = []
        if (infos.type_perform === 1) {
            //check nếu ko nhập tiền
            if (!infos.money || infos.money === 0) {
                let newListDebits = [...listDebits]
                const notSelected = listDebits.filter(
                    (debit) => !selectedProducts.some((product) => debit.id === product.id),
                )
                newListDebits.forEach((debit, index) => {
                    for (const product of notSelected) {
                        if (product.id === debit.id) {
                            debit.new_paid = 0
                        }
                    }
                })
                setListDebits(newListDebits)
                for (const product of selectedProducts) {
                    infos.arr_pay.push(product)
                    product.new_paid = product.sumery - product.paid
                    total += product.sumery - product.paid
                }
                setInfos({ ...infos, total_money: total })
                setMoneyPlaceHolder(total)
            } else {
                handleRemoveZeroProduct()
                for (const product of selectedProducts) {
                    if (product.new_paid > 0) {
                        infos.arr_pay.push(product)
                        total += product.new_paid
                        if (product.new_paid > product.sumery - product.paid) {
                            product.coin_contain = product.new_paid - (product.sumery - product.paid)
                            infos.arr_pay.push({ id: product.id, type: 1, bdc_apartment_service_price_id: product.bdc_apartment_service_price_id, coin: product.coin_contain })
                            product.is_contanin_paid = true
                        }
                    }
                }
                setInfos({ ...infos, total_money: total, account_balance: infos.money - total })
            }
        } else {
            for (const product of selectedProducts) {
                if (product.new_paid > 0) {
                    infos.arr_pay.push(product)
                    total += product.new_paid
                }
            }
            setInfos({ ...infos, money: total, total_money: total, account_balance: 0 })
        }
    }, [JSON.stringify(selectedProducts)])

    useEffect(() => {
        if (infos.type_perform === 2 && listDebits) {
            let selectedProducts = []
            listDebits.forEach((debit) => {
                if (debit.paid_by_coin && debit.paid_by_coin[0]) {
                    selectedProducts.push(debit)
                }
            })
            setSelectedProducts(selectedProducts)
        }
    }, [listDebits])

    const rowClassName = (rowData) => (infos.type_perform ? '' : 'p-disabled')

    const BodyPaymentFrom = (rowDebit) => {
        if (infos.type_perform === 2) {
            return (
                <div>
                    <DropdownForm
                        filter={false}
                        value={rowDebit.service_coin_id}
                        options={listCoin}
                        optionLabel="name_coin"
                        optionValue="bdc_apartment_service_price_id"
                        onChange={(e) => {
                            const index = listDebits.findIndex((debits) => debits.id === rowDebit.id)

                            const handleClear = () => {  // xóa dịch vụ
                                const indexCoin = listCoin.findIndex(coin => coin.bdc_apartment_service_price_id === rowDebit.service_coin_id)
                                const newListCoin = [...listCoin];
                                newListCoin[indexCoin] = {
                                    ...newListCoin[indexCoin],
                                    remain_coin: newListCoin[indexCoin]?.coin
                                };
                                setListCoin(newListCoin);
                                if (index !== -1) {
                                    const newListDebits = [...listDebits]
                                    newListDebits[index] = {
                                        ...newListDebits[index],
                                        new_paid: 0,
                                        type: null,
                                        service_coin_id: null,
                                        paid_by_coin: [],
                                    }
                                    setListDebits(newListDebits)
                                }
                            }

                            const handleSelectionChange = () => {    //lựa chọn thay đổi dịch vụ
                                let selectedCoin = listCoin.find((coin) => coin.bdc_apartment_service_price_id === e.target.value)
                                const indexCoin = listCoin.findIndex(coin => coin.bdc_apartment_service_price_id === e.target.value)
                                if (selectedCoin.remain_coin === 0) {
                                    dispatch(setToast({ ...listToast[1], detail: `Tiền thừa hạch toán '${selectedCoin.name}' đã chọn thanh toán hết` }))
                                    return
                                }
                                let remainCoin = 0
                                if (selectedCoin.remain_coin >= Number(rowDebit.sumery - rowDebit.paid)) {
                                    remainCoin = selectedCoin.remain_coin - (rowDebit.sumery - rowDebit.paid)
                                }
                                const newListCoin = [...listCoin];
                                newListCoin[indexCoin] = {
                                    ...newListCoin[indexCoin],
                                    remain_coin: remainCoin
                                };
                                setListCoin(newListCoin);

                                if (rowDebit.service_coin_id && rowDebit.service_coin_id !== e.target.value) { //chọn dịch vụ khác
                                    if (index !== -1) {
                                        const newListDebits = [...listDebits]
                                        newListDebits[index] = {
                                            ...newListDebits[index],
                                            new_paid:
                                                rowDebit.sumery - rowDebit.paid >= selectedCoin.remain_coin
                                                    ? selectedCoin.remain_coin
                                                    : rowDebit.sumery - rowDebit.paid,
                                            type: 3,
                                            service_coin_id: selectedCoin.bdc_apartment_service_price_id,
                                            paid_by_coin: [
                                                {
                                                    ...selectedCoin,
                                                    paid:
                                                        rowDebit.sumery - rowDebit.paid >= selectedCoin.remain_coin
                                                            ? selectedCoin.remain_coin
                                                            : rowDebit.sumery - rowDebit.paid,
                                                },
                                            ],
                                        }
                                        setListDebits(newListDebits)
                                    }
                                } else {              // Chọn dịch vụ từ chưa có gì
                                    if (index !== -1) {
                                        const newListDebits = [...listDebits]
                                        newListDebits[index] = {
                                            ...newListDebits[index],
                                            new_paid:
                                                rowDebit.sumery - rowDebit.paid >= selectedCoin.remain_coin
                                                    ? selectedCoin.remain_coin
                                                    : rowDebit.sumery - rowDebit.paid,
                                            type: 3,
                                            service_coin_id: selectedCoin.bdc_apartment_service_price_id,
                                            paid_by_coin: [
                                                {
                                                    ...selectedCoin,
                                                    paid:
                                                        rowDebit.sumery - rowDebit.paid >= selectedCoin.remain_coin
                                                            ? selectedCoin.remain_coin
                                                            : rowDebit.sumery - rowDebit.paid,
                                                },
                                            ],
                                        }
                                        setListDebits(newListDebits)
                                    }
                                }
                            }
                            e.target.value === undefined ? handleClear() : handleSelectionChange()
                        }}
                        disabled={infos.type_perform === 1}
                        placeholder="Chọn thanh toán"
                        showClear
                    />
                </div>
            )
        }
    }

    const BodyPaymentValue = (rowData) => {
        let disabled = false
        selectedProducts.forEach((product) => {
            if (product.id === rowData.id) disabled = true
        })
        let totalNewPaid = 0
        listDebits.forEach(debit => {
            if (debit.id != rowData.id)
                totalNewPaid += debit.new_paid
        })

        return <InputNumberForm
            id='new_paid'
            value={rowData.new_paid}
            handleOnChange={(e) => {
                const index1 = listDebits.findIndex(debits => debits.id === rowData.id)
                if (index1 !== -1) {
                    if (!e || e == 0) {
                        const newListDebits = [...listDebits]
                        newListDebits[index1] = {
                            ...newListDebits[index1],
                            new_paid: 0,
                            type: 2
                        }
                        setListDebits(newListDebits)
                        return
                    }
                    if (totalNewPaid + e > infos.money && infos.money > 0) {
                        dispatch(setToast({ ...listToast[2], detail: 'Tổng thanh toán không được lớn hơn số tiền nhập vào' }))
                        return
                    }
                    const newListDebits = [...listDebits]
                    newListDebits[index1] = {
                        ...newListDebits[index1],
                        new_paid: e,
                        type: 2
                    }
                    const index = listDebits.findIndex((debits) => debits.id === rowData.id)
                    if (index !== -1) {
                        const newListDebits = [...listDebits]
                        newListDebits[index] = {
                            ...newListDebits[index],
                            new_paid: e,
                            type: 2,
                        }
                        setListDebits(newListDebits)
                    }
                }
            }}
            disabled={infos.type_perform === 2 || !infos.money || infos.money === 0 || disabled}
        />
    }
    const resetForm = () => {
        setParams(initParam)
        setInfos(initInfos)
        setData([])
        setListDebits([])
        setSelectedProducts([])
        setMoneyPlaceHolder(null)
    }

    const moreOptions = []
    if (infos.type_perform === 1 && infos.money && !selectedProducts[0]) {
        moreOptions.push({
            label: 'Phân bổ số tiền',
            icon: 'pi pi-sort-alt',
            command: () => {
                if (!infos.money) dispatch(setToast({ ...listToast[2], detail: 'Bạn chưa nhập số tiền' }))
                handleDistributeMoney()
            },
        })
    }

    const handleDistributeMoney = () => {
        let money = infos.money
        let selectedProducts = []
        if (listDebits) {
            const newListDebits = [...listDebits]
            newListDebits.forEach((debit, index) => {
                debit.new_paid = 0
                if (money > 0) {
                    if (debit.sumery - debit.paid > money) {
                        debit.new_paid = money
                    } else {
                        debit.new_paid = debit.sumery - debit.paid
                    }
                    selectedProducts.push(debit)
                    money -= debit.sumery - debit.paid
                }
                if (money < 0) return
            })
            setSelectedProducts(selectedProducts)
            setListDebits(newListDebits)
        }
    }

    const [buyer, setBuyer] = useState()

    useEffect(() => {
        setSelectedProducts([])
        setMoneyPlaceHolder(null)
        const bdc_apartment_id = infos.bdc_apartment_id
        const fetchData = async () => {
            if (bdc_apartment_id) {
                const res = await listDebitReceipt({ ...params, apartment_id: bdc_apartment_id })
                const { data, mess, status } = res.data
                if (!status) {
                    dispatch(setToast({ ...listToast[1], detail: mess }))
                    setData([])
                    setListDebits([])
                    setSelectedProducts([])
                } else {
                    dispatch(setToast({ ...listToast[0], detail: 'Tìm căn hộ thành công' }))
                    setData(data)
                    const defaultCustomer = data.detail.find(item => item.type === 0);
                    if (!defaultCustomer) {
                        setData([])
                        setInfos(initInfos)
                        return dispatch(setToast({ ...listToast[1], detail: 'Không tìm thấy chủ hộ của căn hộ' }))
                    } else {
                        let buy = []
                        const buyer_name = await detailApartmentApi({ ...params, id: bdc_apartment_id })
                        const buyer_namev2 = buyer_name.data?.data?.detailApartment?.buyer_name
                        const hostApartment = buyer_name.data?.data?.detailApartment?.name_customer
                        const oldHostApartmenst = buyer_name.data?.data?.residential?.find(d => d.type === 7)
                        let id = 1;
                        if (buyer_namev2 !== null && buyer_namev2 !== undefined && buyer_namev2 !== '') {
                            buy.push({ id: id++, name: buyer_namev2 });
                        }
                        if (hostApartment !== null && hostApartment !== undefined) {
                            buy.push({ id: id++, name: hostApartment });
                        }
                        if (oldHostApartmenst) {
                            buy.push({ id: id++, name: oldHostApartmenst?.full_name })
                        }
                        setBuyer(buy);
                    }
                }
                setInfos({
                    ...initInfos,
                    bdc_apartment_id: bdc_apartment_id,
                    money: null,
                    total_money: null,
                    arr_pay: [],
                })
            }
        }
        fetchData()
    }, [infos.bdc_apartment_id])

    useEffect(() => {
        if (buyer?.length > 0 && data.detail && data.detail[0]) {
            const defaultCustomer = data.detail?.find(item => item.type === 0);
            setInfos(prevInfos => ({
                ...prevInfos,
                buyer_name: buyer[0].name,
                customer_name: defaultCustomer?.full_name

            }));
        }
    }, [buyer, data.detail]);



    const handleAddReceipt = async (renDialog) => {
        let info = {
            ...infos,
            create_date: infos.create_date && databaseDate(infos.create_date),
            type: 'phieu_thu',
            amount: infos.total_money + infos.account_balance,
        }
        info.arr_pay.forEach((pay, index) => {
            if (info.type_perform === 1 && pay.type !== 1) {
                if (pay.is_contanin_paid) {
                    pay.new_paid = pay.sumery - pay.paid
                }
                pay.type = 2
            }
            if (info.type_perform === 2) pay.type = 3
        })

        if (!info.bdc_apartment_id) {
            dispatch(setToast({ ...listToast[2], detail: 'Bạn chưa chọn căn hộ' }))
            return
        }
        if (!selectedProducts[0]) {
            dispatch(setToast({ ...listToast[2], detail: 'Không có dịch vụ được chọn' }))
            return
        }
        if (!info.customer_payments) {
            dispatch(setToast({ ...listToast[2], detail: 'Bạn chưa loại hình thức thu tiền' }))
            return
        }
        if (!info.type_receipt) {
            dispatch(setToast({ ...listToast[2], detail: 'Bạn chưa chọn kiểu phiếu' }))
            return
        }
        if (info.account_balance < 0) {
            dispatch(setToast({ ...listToast[2], detail: 'Số tiền nhỏ hơn tổng thanh toán' }))
            return
        }
        if (renDialog) {
            if (info.account_balance > 0) {
                dispatch(setToast({ ...listToast[2], detail: 'Không hỗ trợ tạo QR với số tiền thừa lớn hơn 0' }))
                return
            }
            const { data } = await genQrCode(info)
            if (data.status) {
                setQrCode(data.data.qr_code)
                setCode_pay(data.data.code_pay)
                setVisible(true)
            } else {
                dispatch(setToast({ ...listToast[1], detail: data.mess }))
            }
            return
        }
        if (!renDialog) {
            setVisible(false)
            confirmDialog({
                message: `Xác nhận thêm mới phiếu thu`,
                header: process.env.REACT_APP_BRANCH_NAME,
                icon: 'pi pi-info-circle',
                accept: async () => {
                    const { data } = await addReceipt(info)
                    if (!data.status) dispatch(setToast({ ...listToast[1], detail: data.mess }))
                    else setShowSuccessDialog(true)
                    resetForm()
                },
            })
        }
    }

    // --------------------------- Thêm công nợ dialog ---------------------------
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen)
    }

    return (
        <div className="card bg-color">
            <Dialog header="Mã QR" visible={visible} onHide={() => setVisible(false)}>
                <div className="grid" style={{ maxWidth: '800px' }}>
                    <div className='col-12 flex justify-content-center'>
                        <img src={qrCode} style={{ alignItems: 'center' }} alt="Ảnh QR" />
                    </div>
                    <p className='col-12 text-center'>Nội Dung thanh toán {code_pay}</p>
                </div>
            </Dialog>
            <HeaderListForm title="Lập phiếu thu" />
            <div className=" grid grid-form">
                <div className="col-4">
                    <DropdownForm
                        value={infos.bdc_apartment_id}
                        options={apartments}
                        onChange={(e) => {
                            setInfos({ ...infos, bdc_apartment_id: e.target.value })
                            if (!data) {
                                setInfos(initInfos)
                                setListDebits([])
                                setListCoin([])
                            }
                            if (!data.listDebits || !data.listDebits[0]) setListDebits([])
                            if (!data.listCoin || !data.listCoin[0]) setListCoin([])
                        }}
                        label="Chọn căn hộ (*)"
                        required
                    />
                </div>
                {Boolean(data.totalCoin) ? (
                    <div className="col-8">
                        <div className="text-center mb-3" style={{ color: 'red' }}>
                            Căn hộ có tiền thừa hạch toán : {formatNumber(data.totalCoin)} VNĐ !
                        </div>
                        <div className="grid grid-form card ml-2 mr-2 mb-3">
                            {data.listCoin &&
                                data.listCoin.map((coin, index) => {
                                    return (
                                        <>
                                            <div key={index} className="col-6">
                                                {index + 1}. {coin.name}
                                            </div>
                                            <div className="col-6">: {formatNumber(coin.coin)} VNĐ</div>
                                        </>
                                    )
                                })}
                        </div>
                    </div>
                ) : (
                    infos.bdc_apartment_id && (
                        <div className="col-8">
                            <div className="text-center mb-3" style={{ color: 'red' }}>
                                Căn hộ không có tiền thừa hạch toán
                            </div>
                        </div>
                    )
                )}
            </div>
            <div className=" grid grid-form">
                <div className="col-4">
                    <DropdownForm
                        filter={false}
                        value={infos.type_perform}
                        options={nghiep_vu}
                        optionValue="id"
                        onChange={(e) => {
                            if (e.target.value === 2) setInfos({ ...infos, type_receipt: 'phieu_ke_toan', type_perform: e.target.value, money: null })
                            else setInfos({ ...infos, type_receipt: 'phieu_thu', type_perform: e.target.value, money: null })
                            setSelectedProducts([])
                            if (data.listDebits && data.listDebits[0]) setListDebits(data.listDebits.map(debit => ({ ...debit, new_paid: 0 })));
                            else setListDebits([]);
                            if (data.listCoin && data.listCoin[0]) {
                                data.listCoin.forEach((coin) => {
                                    coin.is_choosen = false
                                    if (services) {
                                        services.forEach(service => {
                                            if (!coin.name && coin.bdc_service_id === service.id) coin.name = service.name
                                        })
                                    }
                                    coin.name_coin = `${coin.name} (${formatNumber(coin.coin)}-VND)`
                                })
                                setListCoin(data.listCoin)
                            }
                        }}
                        label="Nghiệp vụ thực hiện (*)"
                        disabled={!infos.bdc_apartment_id}
                        required
                        showClear={false}
                    />
                </div>
                <div className="col-4">
                    <DropdownForm
                        filter={false}
                        value={infos.customer_payments}
                        options={customer_payments_type}
                        onChange={(e) => {
                            const newInfos = { ...infos, customer_payments: e.target.value }
                            if (e.target.value === 'tien_mat') newInfos.type_receipt = 'phieu_thu'
                            if (e.target.value === 'chuyen_khoan') newInfos.type_receipt = 'phieu_bao_co'
                            setInfos(newInfos)
                        }
                        }
                        label="Hình thức thu tiền (*)"
                        disabled={!infos.bdc_apartment_id}
                        required
                    />
                </div>
                <div className="col-4">
                    <DropdownForm
                        filter={false}
                        value={infos.type_receipt}
                        options={receipt_type}
                        onChange={(e) => setInfos({ ...infos, type_receipt: e.target.value })}
                        label="Kiểu phiếu (*)"
                        disabled={!infos.bdc_apartment_id}
                        required
                    />
                </div>
                <div className="col-4">
                    {infos.type_perform === 1 && (
                        <InputNumberForm
                            id="money"
                            value={infos.money}
                            handleOnChange={(e) => {
                                setSelectedProducts([])
                                let newListDebits = [...listDebits]
                                newListDebits.forEach((debit, value) => {
                                    debit.new_paid = 0
                                })
                                setListDebits(newListDebits)
                                setInfos({
                                    ...infos,
                                    money: e,
                                    account_balance: e - infos.total_money,
                                    total_money: 0,
                                })
                            }}
                            label="Số tiền"
                            disabled={!infos.bdc_apartment_id}
                            placeholder={formatNumber(moneyPlaceHolder)}
                        // required
                        />
                    )}
                    {infos.type_perform === 2 && (
                        <InputNumberForm id="money" value={infos.money} label="Số tiền" required disabled />
                    )}
                </div>
                <div className="col-4">
                    <InputNumberForm
                        id="H"
                        value={infos.total_money}
                        onChange={(e) => setInfos({ ...infos, total_money: e, account_balance: infos.money - e })}
                        label="Tổng thanh toán"
                        placeholder="Tổng thanh toán"
                        disabled
                    />
                </div>
                <div className="col-4">
                    <InputNumberForm
                        id="account_balance"
                        value={infos.account_balance}
                        label="Tiền thừa"
                        placeholder="Tiền thừa"
                        disabled
                    />
                </div>
                <div className="col-4">
                    <DropdownForm
                        id="H"
                        value={infos.customer_name}
                        options={data.detail?.filter(d => d.type === 0 || d.type === 7)}
                        optionLabel="full_name"
                        optionValue="full_name"
                        onChange={(e) => setInfos({ ...infos, customer_name: e.target.value })}
                        label="Người nộp tiền"
                    />

                </div>
                <div className="col-4">
                    <InputForm
                        id="H"
                        value={data.detail && data.detail[0] ? data.detail[0].address : ''}
                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                        label="Địa chỉ"
                        disabled
                    />
                </div>
                <div className="col-4">
                    <CalendarForm
                        id="create_date"
                        value={infos.create_date}
                        onChange={(e) => setInfos({ ...infos, create_date: e.target.value })}
                        label="Ngày hạch toán"
                    // disabled
                    />
                </div>
                {
                    infos.customer_payments === 'chuyen_khoan' && (
                        <div className="col-4">
                            <DropdownForm
                                id="payment_info_id"
                                value={infos.payment_info_id}
                                onChange={(e) => setInfos({ ...infos, payment_info_id: e.target.value })}
                                options={listAccount.map(l => ({ name: `${l.short_url} - ${l.bank_account}`, id: l.id }))}
                                label="Tài khoản chuyển khoản"
                            />
                        </div>
                    )
                }

                <div className="col-12 lg:col-4">
                    <DropdownForm label="Người mua hàng "
                        value={infos.buyer_name}
                        options={buyer}
                        optionValue="name"
                        onChange={(e) => setInfos({ ...infos, buyer_name: e.target.value })}
                    />
                </div>
                <div className="col-12 lg:col-8">
                    <InputTextareaForm style={{ height: '220px' }} label="Nội dung thu tiền" />
                </div>
            </div>
            <div>
                <HeaderListForm title="Danh sách dịch vụ" />
                <DataTablez
                    dataKey="id"
                    value={listDebits}
                    title="Phiếu thu/chi"
                    totalRecords={listDebits.length}
                    params={params}
                    setParams={setParams}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    rowClassName={rowClassName}
                    route="/receipt"
                    headerInfo={{ moreOptions }}
                    selectionMode={'checkbox'}
                // selection={selectedProducts}
                // onSelectionChange={(e) => {
                //     setSelectedProducts(e.value)
                // }}
                >
                    {/* {infos.type_perform === 1 && <Columnz selectionMode="multiple" headerStyle={{ width: '3rem' }} />} */}
                    <Columnz field="name" header="Dịch vụ" />
                    <Columnz body={(e) => {
                        return <div>
                            {TimeBody(e.from_date, 'date')}
                            <span>   -    </span>
                            {TimeBody(e.to_date, 'date')}
                        </div>
                    }} header="Thời gian" />
                    <Columnz field="product" header="Sản phẩm" />
                    <Columnz
                        className='text-right'
                        body={(e) => NumberFormat({ value: e.sumery })} header="Phát sinh " />
                    <Columnz
                        className='text-right'
                        body={(e) => NumberFormat({ value: e.sumery - e.paid })} header="Phải trả" />
                    <Columnz body={(e) => BodyPaymentValue(e)} header="Thanh toán" />
                    <Columnz body={(e) => BodyPaymentFrom(e)} header="Thanh toán từ" />
                </DataTablez>
            </div>
            <div className="w-full justify-content-center align-items-center" style={{ position: 'fixed', left: '40%', bottom: '10px' }}>
                <Button
                    style={{ marginLeft: '15px', marginTop: '25px' }}
                    type="button"
                    severity="danger"
                    onClick={() => navigate('/receipt')}
                >
                    <i className='pi pi-backward mr-2'></i>
                    Trở về
                </Button>
                {/* ------------------- Thêm mới công nợ ------------------- */}
                <Button
                    style={{ marginLeft: '15px', marginTop: '25px' }}
                    type="button"
                    severity="info"
                    // onClick={() => navigate(`/debt/detail/${infos.bdc_apartment_id}`)}
                    onClick={toggleDialog}
                    disabled={!infos.bdc_apartment_id}
                >
                    <i className="pi pi-plus mr-2"></i>
                    Thêm mới công nợ
                </Button>
                <AddDebtForm
                    visible={isDialogOpen}
                    infos={infos}
                    params={params}
                    setParams={setParams}
                    setVisible={setIsDialogOpen}
                    setInfos={setInfos}
                ></AddDebtForm>

                {/* <Button
                    style={{ marginLeft: '15px', marginTop: '25px' }}
                    type="button"
                    icon="pi pi-print"
                    serverity
                    onClick={() => handleAddReceipt()}
                >
                    Thu và in
                </Button> */}
                <Button
                    style={{ marginLeft: '15px', marginTop: '25px' }}
                    type="button"
                    severity="warning"
                    onClick={() => handleAddReceipt(false)}
                    disabled={!infos.bdc_apartment_id}
                    raised
                >
                    <i className='pi pi-money-bill mr-2'></i>
                    Thu tiền
                </Button>
                <Button
                    style={{ marginLeft: '15px', marginTop: '25px' }}
                    type="button"
                    severity="help"
                    onClick={async () => {
                        <i className='pi pi-eye mr-2'></i>
                        handleAddReceipt(true)
                    }
                    }
                    disabled={!infos.bdc_apartment_id}
                    raised
                >
                    Tạo mã QR
                </Button>
                {/* <Button
                    style={{ marginLeft: '15px', marginTop: '25px' }}
                    type="button"
                    icon="pi pi-eye"
                    serverity
                >
                    Xem trước
                </Button> */}
            </div>
            {showSuccessDialog && (
                <CustomModal
                    visible={showSuccessDialog}
                    message="Thêm mới phiếu thu thành công!"
                    header={process.env.REACT_APP_BRANCH_NAME}
                    icon="pi pi-check"
                    acceptClassName="p-button-success"
                    acceptLabel="OK"
                    onAccept={() => {
                        resetForm()
                        navigate('/receipt')
                    }
                    }
                    onHide={() => setShowSuccessDialog(false)}
                />
            )}
        </div>
    )
}
