import { getData, postData } from '@/lib/request'

export const listRegisterRepairApi = (params) => getData('web2/register_repair/listRepairApartment', params)
export const countRegisterRepairApi = (params) => getData('web2/register_repair/countListRepairApartment', params)
export const deleteRegisterRepairApi = (params) => postData('web2/register_repair/deleteFeedBack', params)
export const detailRegisterRepairApi = (params) => getData('web2/feedback_comment/detailFeedBack', params)
export const addRegisterRepairApi = (params) => postData('web2/register_repair/addRegisterRepair', params)
export const updateRegisterRepairApi = (params) => postData('web2/register_repair/updateRegisterRepair', params)
export const addCommentApi = (params) => postData('web2/feedback_comment/createComment', params, true)
export const deleteCommentApi = (params) => postData('web2/feedback_comment/deleteComments', params)
export const switchStatusRequestApi = (params) => postData('web2/feedback_comment/switchStatus', params)
export const switchStatusFeedbackApi = (params) => postData('web2/feedback_comment/switchStatusFeedback', params)
