import { useEffect, useState } from "react"
import { countUnit, detailUnit, listUnit } from "../api"

export const useListUnit = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listUnit({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}

export const useDetailUnit = (id) => {
      const [data, setData] = useState({})
      async function fetchData() {
            const response = await detailUnit({ id })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            if (id) fetchData()
      }, [id])
      return data
}
export const useCountUnit = (params) => {
      const [data, setData] = useState(0)
      async function fetchData() {
            const response = await countUnit({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
