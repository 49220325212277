import { useEffect, useState } from 'react'
import { getListVoteApi, postUpdateVoteApi, getDetailVoteApi } from '../api'

export const useListVoteApi = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await getListVoteApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useGetDetailVoteApi = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await getDetailVoteApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
