import { useEffect, useState } from 'react'
import { listServiceApi, countServiceApi, listCycleName, listSum } from '../api'

export const useListService = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListCycleName = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCycleName()
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountService = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
