import { CalendarForm, FormUpdate, InputForm, InputTextareaForm } from '@/components'
import { TabPanel, TabView } from 'primereact/tabview'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addApartmentHandoverApi, updateApartmentHandoverApi } from '../api'
import { useDetailApartmentHandover } from '../utils'

const ApartmentHandoverInfo = (props) => {
    const { infos, setInfos } = props
    return (
        <Fragment>
            <div className="card bg-color">
                <InputForm
                    id="name"
                    value={infos.name}
                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                    label="Tiêu đề"
                    disabled
                />
                <InputTextareaForm
                    id="description"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                    disabled
                    label="Nội dung"
                />
                <InputForm
                    id="name"
                    value={infos.name}
                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                    label="Người tạo"
                    disabled
                />
                <CalendarForm
                    id="ccc"
                    label="Thời gian"
                    value={infos.date}
                    onChange={(e) => setInfos({ ...infos, date: e.target.value })}
                    disabled
                />
            </div>
        </Fragment>
    )
}

const UpdateApartmentHandover = () => {
    const { id } = useParams()
    const apartmentHandoverDetail = useDetailApartmentHandover(id)
    const [infos, setInfos] = useState({
        status: true,
    })

    useEffect(() => {
        if (apartmentHandoverDetail.id) setInfos({ ...infos, ...apartmentHandoverDetail })
    }, [apartmentHandoverDetail])

    return (
        <FormUpdate
            checkId={Number(id)}
            title="Thông báo"
            handleData={() => {}}
            route="/notification"
            actions={{ add: addApartmentHandoverApi, update: updateApartmentHandoverApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card">
                <TabView>
                    <TabPanel header="Chi tiết">
                        <ApartmentHandoverInfo infos={infos} setInfos={setInfos} />
                    </TabPanel>
                    <TabPanel header="Danh sách gửi"></TabPanel>
                </TabView>
            </div>
        </FormUpdate>
    )
}

export default UpdateApartmentHandover
