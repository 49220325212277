import {CalendarForm, Import, MultiRadioButton} from '@/components'
import {codeBillImport} from '@/constants'
import {useState} from 'react'
import {importFirstTermFee} from '../api'
import {databaseDate} from "@/lib";

const MoreOption = ({infos, setInfos}) => {
    return (
        <div className="flex justify-content-center align-items-center gap-3">
            <div className="w-6 mt-4 text-left">
                <CalendarForm
                    id="from_date"
                    value={infos.from_date}
                    onChange={(e) => setInfos({...infos, from_date: e.target.value})}
                    showIcon
                    label="Ngày bắt đầu"
                />
                <CalendarForm
                    id="to_date"
                    value={infos.to_date}
                    onChange={(e) => setInfos({...infos, to_date: e.target.value})}
                    showIcon
                    label="Ngày kết thúc"
                />
                <CalendarForm
                    id="deadline"
                    value={infos.deadline}
                    onChange={(e) => setInfos({...infos, deadline: e.target.value})}
                    showIcon
                    label="Hạn thanh toán"
                />
                <MultiRadioButton
                    id="is_new_bill "
                    value={infos.is_new_bill}
                    onChange={(e) => setInfos({...infos, is_new_bill: e})}
                    options={codeBillImport}
                    className="w-full flex flex-column gap-3"
                />
            </div>
        </div>
    )
}

const ImportFirstTermFee = (props) => {
    const {visible, setVisible} = props
    const [infos, setInfos] = useState({})

    const handleData = () => {
        let info = {
            ...infos,
            from_date: infos.from_date ? databaseDate(infos.from_date) : undefined,
            to_date: infos.to_date ? databaseDate(infos.to_date) : undefined,
            deadline: infos.deadline ? databaseDate(infos.deadline) : undefined,
        }
        return info
    }

    return (
        <Import
            title="phí đầu kỳ"
            visible={visible}
            setVisible={setVisible}
            action={importFirstTermFee}
            MoreOption={() => MoreOption({infos, setInfos})}
            template="https://devapibdc.dxmb.vn/import/import_service_charge.xlsx"
            handleData={handleData}
        />
    )
}

export default ImportFirstTermFee
