import {
    addChildResidentApi,
    addResidentApi,
    addRoleApi,
    checkResidentApi,
    deleteRoleApi,
    detailResidentApi,
    editRoleApi,
    listResidentV2Api,
    updateResidentApi
} from '@/api'
import {
    CalendarForm,
    Dialogz,
    DropdownForm,
    FormUpdate,
    HeaderListForm,
    InputForm,
    LoadDialog,
    MultiRadioButton,
    UploadImg,
    UploadMultiImage
} from '@/components'
import { genders, listToast, member_admin, relationshipOwner } from '@/constants'
import { useGetApi } from '@/hooks'
import { databaseDate, validate } from '@/lib'
import { CustomModal } from '@/modules/accountant/receipts_expenditures/screens/CreateReceipt'
import { clearMoreItem, setResidents, setToast } from '@/redux/features'
import { clearResidentInfo } from '@/redux/features/residentInfo'
import { Button } from '@/uiCore'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import moment from 'moment'
import { TabPanel, TabView } from 'primereact/tabview'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const initInfos = {
    gender: 1
}
const initChild = {
}
const ListApartment = ({ data, setData, id }) => {
    const apartments = useSelector((state) => state.apartments)
    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()
    const [info, setInfo] = useState({})

    const fetchData = async (params, api) => {
        const response = await api(params)
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Cập nhật dữ liệu thành công!' }))
            return true
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
        return false
    }

    const handleUpdate = (index) => {
        if (index || index === 0) {
            const params = { apartment_id: data[index].apartment_id, type: data[index].type, resident_id: id }
            fetchData(params, editRoleApi)
        }
    }

    const handleDelete = (index) => {
        if (index || index === 0) {
            const params = { apartment_id: data[index].apartment_id, resident_id: id }
            setData((pre) => pre.filter((p) => p.apartment_id !== data[index].apartment_id))
            fetchData(params, deleteRoleApi)
        }
    }

    const handleAdd = async () => {
        if (await fetchData({ ...info, resident_id: id }, addRoleApi)) {
            setVisible(false)
            setData((pre) => [...pre, info])
            setInfo({})
        }
    }
    const handleOnChange = (index, value) => {
        const newData = data
        newData[index].type = value
        setData([...newData])
    }

    return (
        <div className="card">
            <Dialogz header="Thông tin cư dân" visible={visible} setVisible={setVisible} width="70vw">
                <div className="card mt-4 flex justify-content-between">
                    <div className="grid grid-form w-full">
                        <div className="col-12 lg:col-4">
                            <DropdownForm
                                value={info.apartment_id}
                                options={apartments.filter((a) => !data.some((d) => a.id === d.apartment_id))}
                                onChange={(e) => setInfo({ ...info, apartment_id: e.target.value })}
                                label="Căn hộ"
                            />
                        </div>
                        <div className="col-12 lg:col-4">
                            <DropdownForm
                                value={info.type}
                                options={relationshipOwner}
                                onChange={(e) => setInfo({ ...info, type: e.target.value })}
                                label="Tình trạng"
                            />
                        </div>
                    </div>
                    <div className="col-12 lg:col-4 flex gap-3 justify-content-end">
                        <Button
                            type="button"
                            raised
                            size="small"
                            severity="danger"
                            label="Hủy"
                            icon="pi pi-times"
                            onClick={() => {
                                setVisible(false)
                                setInfo({})
                            }}
                        />
                        <Button
                            type="button"
                            disabled={!info.apartment_id || !(info.type || info.type === 0)}
                            onClick={() => handleAdd()}
                            raised
                            size="small"
                            icon="pi pi-save"
                            severity="info"
                            label="Xác nhận"
                        />
                    </div>
                </div>
            </Dialogz>
            <div className="text-left py-3">
                <Button
                    type="button"
                    raised
                    size="small"
                    severity="info"
                    label="Thêm căn hộ"
                    onClick={() => setVisible(true)}

                />
            </div>
            {data.map((d, index) => {
                return (
                    <div key={index} className="grid align-items-center ">
                        <div className="col-12 lg:col-4">
                            <DropdownForm value={d.apartment_id} label="Căn hộ" options={apartments} disabled />
                        </div>
                        <div className="col-12 lg:col-4">
                            <DropdownForm
                                value={d.type}
                                options={relationshipOwner}
                                onChange={(e) => handleOnChange(index, e.target.value)}
                                label="Tình trạng"
                            />
                        </div>
                        <div className="col-12 lg:col-4 flex gap-3 justify-content-end">
                            <Button
                                type="button"
                                raised
                                size="small"
                                severity="info"
                                label="Cập nhật"
                                className="mt-2"
                                onClick={() => handleUpdate(index)}
                            />
                            <Button
                                type="button"
                                raised
                                size="small"
                                severity="danger"
                                label="Xóa"
                                className="mt-2"
                                onClick={() => handleDelete(index)}
                            />
                        </div>

                    </div>
                )
            })}
        </div>
    )
}

const UpdateResident = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const [idz, setIdz] = useState(null)
    const residentDetail = useGetApi(detailResidentApi, { id: id || idz }, {})
    const [infos, setInfos] = useState(initInfos)
    const [avatar, setAvatar] = useState(null)
    const [images, setImages] = useState([])
    const [data, setData] = useState([])
    const listApart = useSelector((state) => state.apartments)
    const moreItem = useSelector((state) => state.moreItem)
    const residentInfo = useSelector((state) => state.residentInfo)
    useEffect(() => {
        if (residentInfo && !residentDetail.detailResident) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(residentInfo),
                birthday: residentInfo.birthday ? new Date(residentInfo.birthday) : undefined,
                cmt_address: residentInfo.cmt_address,
                cmt_date: residentInfo.cmt_date ? new Date(residentInfo.cmt_date) : undefined,
                cmt_number: residentInfo.cmt_number,
                email_contact: residentInfo.email,
                full_name: residentInfo.full_name,
                gender: residentInfo.gender,
                phone_contact: residentInfo.phone,
            })
            if (residentInfo.avatar) setAvatar(residentInfo.avatar)
            if (residentInfo.before_cmt || residentInfo.after_cmt) {
                const newImages = []
                if (residentInfo.before_cmt) newImages.push(residentInfo.before_cmt)
                if (residentInfo.after_cmt) newImages.push(residentInfo.after_cmt)
                setImages(newImages)
            }
            if (!id) dispatch(clearResidentInfo())
        }
        if (residentInfo && residentDetail.detailResident) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(residentDetail.detailResident),
                ...removeUndefinedProps(residentInfo),
                birthday: residentInfo.birthday ? new Date(residentInfo.birthday) : residentDetail.detailResident.birthday ? new Date(residentDetail.detailResident.birthday) : undefined,
                cmt_address: residentInfo.cmt_address ? residentInfo.cmt_address : residentDetail.detailResident.cmt_address ? residentDetail.detailResident.cmt_address : undefined,
                cmt_date: residentInfo.cmt_date ? new Date(residentInfo.cmt_date) : residentDetail.detailResident.cmt_date ? new Date(residentDetail.detailResident.cmt_date) : undefined,
                cmt_number: residentInfo.cmt_number ? residentInfo.cmt_number : residentDetail.detailResident.cmt_number ? residentDetail.detailResident.cmt_number : undefined,
                email_contact: residentInfo.email ? residentInfo.email : residentDetail.detailResident.email_contact ? residentDetail.detailResident.email_contact : undefined,
                full_name: residentInfo.full_name ? residentInfo.full_name : residentDetail.detailResident.full_name ? residentDetail.detailResident.full_name : undefined,
                gender: residentInfo.gender ? residentInfo.gender : residentDetail.detailResident.gender ? residentDetail.detailResident.gender : undefined,
                phone_contact: residentInfo.phone ? residentInfo.phone : residentDetail.detailResident.phone_contact ? residentDetail.detailResident.phone_contact : undefined,
                cmt_province: residentInfo.cmt_province ? residentInfo.cmt_province : residentDetail.detailResident.cmt_province ? residentDetail.detailResident.cmt_province : undefined,
                type_board: residentInfo.type_board ? residentInfo.type_board : residentDetail.detailResident.type_board ? residentDetail.detailResident.type_board : undefined,

            })
            if (residentInfo.avatar) {
                setAvatar(residentInfo.avatar)
            } else if (residentDetail.detailResident.avatar) setAvatar(residentDetail.detailResident.avatar)

            if (residentInfo.before_cmt || residentInfo.after_cmt) {
                const newImages = []
                if (residentInfo.before_cmt) newImages.push(residentInfo.before_cmt)
                if (residentInfo.after_cmt) newImages.push(residentInfo.after_cmt)
                setImages(newImages)
            } else if (residentDetail.detailResident.cmt_image) {
                const newImages = checkJson(residentDetail.detailResident.cmt_image) || residentDetail.detailResident.cmt_image
                if (typeof newImages === "object" && newImages) {
                    setImages(newImages)
                }
            }
            if (!id) dispatch(clearResidentInfo())
        }
        if (!residentInfo && residentDetail.detailResident) {
            if (residentInfo) {
                residentDetail.detailResident = { ...residentDetail.detailResident }
            }
            setInfos({
                ...infos,
                ...removeUndefinedProps(residentDetail.detailResident),
                birthday: residentDetail.detailResident.birthday ? new Date(residentDetail.detailResident.birthday) : undefined,
                cmt_date: residentDetail.detailResident.cmt_date ? new Date(residentDetail.detailResident.cmt_date) : undefined,
            })
            if (residentDetail.detailResident.avatar) setAvatar(residentDetail.detailResident.avatar)
            if (residentDetail.detailResident.cmt_image) {
                const newImages = checkJson(residentDetail.detailResident.cmt_image) || residentDetail.detailResident.cmt_image
                if (typeof newImages === "object" && newImages) {
                    setImages(newImages)
                }
            }
        }
        if (residentDetail.roleOfApartments) setData([...residentDetail.roleOfApartments])
    }, [residentDetail, residentInfo])

    useEffect(() => {
        if (moreItem.apartment_id) {
            setInfos({ ...infos, apartment_id: Number(moreItem.apartment_id) })
            setChild({ ...child, apartment_id: Number(moreItem.apartment_id) })
            dispatch(clearMoreItem())
        }
    }, [JSON.stringify(moreItem)])
    const validateName = (name) => {
        const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        return nameRegex.test(name);
    }
    const handleData = () => {
        if (!infos.apartment_id && !Number(infos.id)) return `Vui lòng chọn căn hộ`
        if (!Number(infos.id) && !(infos.type || infos.type === 0)) {
            return `Vui lòng chọn quan hệ với chủ hộ`;
        }
        let info = {
            ...infos,
            birthday: infos.birthday ? databaseDate(infos.birthday) : undefined,
            cmt_date: infos.cmt_date ? databaseDate(infos.cmt_date) : undefined,
        }
        if (validateName(infos.full_name)) return 'Tên cư dân không hợp lệ!'
        if (Number(id))
            info = {
                user_id: infos.user_id,
                ...removePropObject(info, residentDetail.detailResident),
                id: Number(infos.id),
            }
        info.birthday = infos.birthday ? moment(infos.birthday).format('YYYY-MM-DD') : undefined
        if (avatar) info.avatar = avatar
        else info.avatar = []
        if (images && images[0]) {
            info.cmt_image = images
        } else info.cmt_image = []
        return info
    }

    const handleAfterCallApi = async () => {
        const getResident = await listResidentV2Api()
        if (getResident.data && getResident.data.status) {
            const residents = getResident.data.data
            dispatch(setResidents(residents))
        }
    }
    const isAdding = !Number(id);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [visible, setVisible] = useState(false);
    const [addChild, setAddChild] = useState(false);
    const [message, setMessage] = useState("");
    const [child, setChild] = useState(initChild);

    useEffect(() => {
        if (isAdding) {
            setVisible(true);
        }
    }, [isAdding]);
    const navigate = useNavigate()

    const validateInput = (input) => {
        return validate('email', input) || validate('phone', input);
    };

    const handleCheck = async () => {
        let key_search = infos.input;
        let type = '';
        if (validate('email', key_search)) {
            type = 'email';
            setInfos({ ...infos, email: key_search });
        } else if (validate('phone', key_search)) {
            type = 'phone';
            setInfos({ ...infos, phone: key_search });
        } else {
            dispatch(setToast({ ...listToast[1], detail: `Email hoặc số điện thoại không đúng định dạng` }));
            return;
        }
        try {
            const { data } = await checkResidentApi({ key_search, type });
            if (data.status) {
                if (data.data.id) {
                    setIdz(data.data.id);
                    setVisible(false);
                    setMessage("Người dùng đã tồn tại");
                    setShowSuccessDialog(true);
                } else {
                    setVisible(false);
                    setMessage("Không tìm thấy người dùng, tiếp tục thêm mới ?");
                    setShowSuccessDialog(true);
                    dispatch(setToast({ ...listToast[0], detail: `Không tìm thấy người dùng` }));
                }
            } else {
                dispatch(setToast({ ...listToast[2], detail: `Lỗi khi kiểm tra dữ liệu` }));
            }
        } catch (error) {
            dispatch(setToast({ ...listToast[2], detail: `Lỗi kết nối` }));
        }
    };


    const handleInputChild = () => {
        setVisible(false);
        setTimeout(() => {
            setAddChild(true);
        }, 0);
    }
    const relationshipOwnerV2 = relationshipOwner.filter(d => d.id != 0

    )
    function capitalizeWords(str) {
        const words = str.split(' ');
        const capitalizedWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        return capitalizedWords.join(' ');
    }
    async function handleAddChild() {
        const formattedDate = moment(child.birthday).format('YYYY-MM-DD');
        if (!child.full_name) return dispatch(setToast({ ...listToast[1], detail: "Tên cư dân không được để trống" }))
        if (!child.gender) return dispatch(setToast({ ...listToast[1], detail: "Giới tính không được để trống" }))
        if (!child.apartment_id) return dispatch(setToast({ ...listToast[1], detail: "Căn hộ  không được để trống" }))
        if (!(child.type || child.type === 0)) return dispatch(setToast({ ...listToast[1], detail: "Tình trạng không được để trống" }))
        const response = await addChildResidentApi({ ...child, birthday: formattedDate });
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Thêm cư dân thành công' }))
            setAddChild(false)
            navigate("/resident_management")

        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }
    const handleImageUpload = (newImages) => {
        if (newImages.length > 2) {

            return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chỉ chọn tối đa 2 ảnh' }))
        }
        setImages(newImages);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && visible) {
            handleCheck();
        }
    };
    return (
        <FormUpdate
            checkId={Number(infos.id)}
            handleData={handleData}
            actions={{ update: updateResidentApi, add: addResidentApi }}
            route="/resident_management"
            title="cư dân"
            handleAfterCallApi={handleAfterCallApi}
        >
            <TabView className="card">
                <TabPanel header="Thông tin cư dân">
                    {showSuccessDialog && (
                        <CustomModal
                            visible={showSuccessDialog}
                            message={message}
                            header={process.env.REACT_APP_BRANCH_NAME}
                            icon="pi pi-check"
                            acceptClassName="p-button-success"
                            acceptLabel="OK"
                            onHide={() => setShowSuccessDialog(false)}
                        />
                    )}
                    {isAdding && !residentInfo && (
                        <div>
                            <Dialogz header="Kiểm  tra email, số điện thoại" visible={visible} setVisible={setVisible} closable={false}   >
                                <div className="card mt-4">
                                    <div className="col-12 lg:col-12">
                                        <InputForm
                                            value={infos.input}
                                            onChange={(e) => setInfos({ ...infos, input: e.target.value })}
                                            onKeyPress={handleKeyPress}

                                            label="Email hoặc Số điện thoại (*)"
                                            required={!infos.input || !validateInput(infos.input)}
                                        />
                                    </div>
                                    <div className='ml-3'>
                                        <Button onClick={handleInputChild} severity="help" label="Trường hợp cư dân chưa có số điện thoại, email, chứng minh thư " text raised />
                                    </div>
                                </div>
                                <div className="justify-content-end flex gap-3">
                                    <Button
                                        label="Trở về"
                                        icon="pi pi-arrow"
                                        severity="danger"
                                        onClick={() => navigate('/resident_management')}
                                        raised
                                    />
                                    <Button
                                        label="Kiểm tra "
                                        icon="pi pi-check-circle"
                                        severity="info"
                                        onClick={() => handleCheck()}
                                        raised
                                    />
                                </div>
                            </Dialogz>
                            <LoadDialog visible={addChild} />

                            <Dialogz header="Thêm mới cư dân" visible={addChild} closable={false}  >
                                <div className='card mt-4'>
                                    <InputForm
                                        id="full_name"
                                        value={child.full_name}
                                        onChange={(e) => setChild({ ...child, full_name: e.target.value })}
                                        label="Tên dân cư (*)"
                                        required
                                    />
                                    <MultiRadioButton
                                        value={child.gender}
                                        onChange={(e) => setChild({ ...child, gender: e })}
                                        options={genders}
                                        className="w-full"
                                        label="Giới tính (*)"
                                    />
                                    <CalendarForm
                                        value={child.birthday}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => setChild({ ...child, birthday: e.target.value })}
                                        label="Ngày sinh"
                                    />
                                    <DropdownForm
                                        value={child.apartment_id}
                                        onChange={(e) => setChild({ ...child, apartment_id: e.target.value })}
                                        options={listApart}
                                        label="Căn hộ (*)"
                                    />
                                    <DropdownForm
                                        value={child.type}
                                        options={relationshipOwnerV2}
                                        onChange={(e) => setChild({ ...child, type: e.target.value })}
                                        label="Quan hệ với chủ hộ (*)"
                                    />
                                </div>
                                <div className="justify-content-end flex gap-3">
                                    <Button
                                        label="Trở về"
                                        icon="pi pi-arrow"
                                        severity="danger"
                                        onClick={() => navigate('/resident_management')}
                                        raised
                                    />
                                    <Button
                                        label="Xác nhận"
                                        icon="pi pi-check"
                                        severity="info"
                                        onClick={handleAddChild}
                                        raised
                                    />
                                </div>
                            </Dialogz>
                        </div>

                    )}

                    <>
                        <HeaderListForm title="Thông tin đăng nhập" />
                        <div className="card bg-color mt-4">
                            <div className="grid grid-form">
                                {!Number(infos.id) ?
                                    <div className="col-12 lg:col-12">
                                        <InputForm
                                            value={
                                                !Number(infos.id)
                                                    ? infos.input
                                                    : (validate('email', infos.email) ? infos.email : infos.phone)
                                            }
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                if (validate('email', input)) {
                                                    setInfos({ ...infos, email: input });
                                                } else if (validate('phone', input)) {
                                                    setInfos({ ...infos, phone: input });
                                                }
                                            }}
                                            label="Thông tin đăng nhập"
                                            disabled
                                        />
                                    </div>
                                    :
                                    <div className="w-full  flex col-12 lg:col-6">
                                        <div className="w-full">
                                            <InputForm
                                                value={infos.email}
                                                onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                                label="Email đăng nhập"

                                            />
                                        </div>
                                        <div className="w-full">
                                            <InputForm
                                                id="phone"
                                                value={infos.phone}
                                                onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                                                label="Số điện thoại đăng nhập"
                                                type="phone"
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    {!Number(infos.id) && (
                        <>
                            <HeaderListForm title="Thông tin căn hộ" />
                            <div className="card bg-color mt-4">
                                <div className="grid grid-form">
                                    <div className="col-12 lg:col-6">
                                        <DropdownForm
                                            value={infos.apartment_id}
                                            onChange={(e) => setInfos({ ...infos, apartment_id: e.target.value })}
                                            options={listApart}
                                            label="Căn hộ (*)"
                                        />
                                    </div>
                                    <div className="col-12 lg:col-6">
                                        <DropdownForm
                                            value={infos.type}
                                            options={relationshipOwner}
                                            onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                                            label="Quan hệ với chủ hộ (*)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <HeaderListForm title="Chi tiết cư dân" />
                    <div className="card bg-color mt-4">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    id="full_name"
                                    value={infos.full_name}
                                    onChange={(e) => setInfos({ ...infos, full_name: capitalizeWords(e.target.value) })}
                                    label="Tên dân cư (*)"
                                    required
                                />
                                <CalendarForm
                                    value={infos.birthday}
                                    dateFormat="dd/mm/yy"
                                    onChange={(e) => setInfos({ ...infos, birthday: e.target.value })}
                                    label="Ngày sinh"
                                />
                                <InputForm
                                    id="address"
                                    value={infos.address}
                                    onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                    label="Địa chỉ"
                                />
                                <InputForm
                                    id="cmt_number"
                                    value={infos.cmt_number}
                                    onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                                    label="CMND / Hộ chiếu"
                                    type={'number'}
                                />
                                <CalendarForm
                                    value={infos.cmt_date}
                                    onChange={(e) => setInfos({ ...infos, cmt_date: e.target.value })}
                                    label="Ngày cấp"
                                />
                                <InputForm
                                    id="cmt_province"
                                    value={infos.cmt_province}
                                    onChange={(e) => setInfos({ ...infos, cmt_province: e.target.value })}
                                    label="Địa chỉ trên chứng minh thư"
                                />
                                <InputForm
                                    id="cmt_address"
                                    value={infos.cmt_address}
                                    onChange={(e) => setInfos({ ...infos, cmt_address: e.target.value })}
                                    label="Nơi cấp"
                                />
                                <MultiRadioButton
                                    id="gender"
                                    value={infos.gender}
                                    onChange={(e) => setInfos({ ...infos, gender: e })}
                                    options={genders}
                                    className="w-full"
                                    label="Giới tính (*)"
                                />
                            </div>
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    id="email_contact"
                                    value={infos.email_contact}
                                    onChange={(e) => setInfos({ ...infos, email_contact: e.target.value })}
                                    label="Email liên hệ"
                                />
                                <InputForm
                                    id="phone_contact"
                                    value={infos.phone_contact}
                                    onChange={(e) => setInfos({ ...infos, phone_contact: e.target.value })}
                                    label="Điện thoại liên hệ"
                                />
                                <div className="mb-5">
                                    <UploadImg image={avatar} setImage={setAvatar} title="Ảnh đại diện" />
                                </div>
                                <UploadMultiImage label="Ảnh chứng minh thư" images={images} setImages={handleImageUpload} />

                            </div>

                        </div>
                        <div className="col-12 lg:col-12">
                            <HeaderListForm title="Thành viên ban quản trị" />
                            <DropdownForm
                                value={infos.type_board}
                                options={member_admin}
                                onChange={(e) => setInfos({ ...infos, type_board: e.target.value })}
                                label="Tình trạng"
                            />
                        </div>
                        {Number(infos.id) && (
                            <div>
                                <HeaderListForm title="Danh sách căn hộ" />
                                <ListApartment id={Number(infos.id)} data={data} setData={setData} />
                            </div>
                        )}
                    </div>
                </TabPanel>
                {/* {Number(infos.id) && (
                    <TabPanel header="Danh sách căn hộ">
                        <ListApartment id={Number(infos.id)} data={data} setData={setData} />
                    </TabPanel>
                )} */}
            </TabView>
        </FormUpdate>
    )
}

export default UpdateResident
