import { getData, postData } from '@/lib/request'

export const listElectricWaterApi = (params) => getData('web2/electric_meter/getListElectricMeter', params)
export const listFloorApi = (params) => getData('web2/info/getListFloorInfo', params)
export const countElectricWaterApi = (params) => getData('web2/electric_meter/countElectricMeter', params)
export const deleteElectricWaterApi = (params) => postData('web2/electric_meter/deleteElectricMeter', params)
export const downImagesElectricWaterApi = (params) => postData('web2/electric_meter/deleteImageElectricMeter', params)

export const detailElectricWaterApi = (params) => getData('web2/electric_water/getDetailElectricWater', params)
export const deleteImageApi = (params) => postData('web2/electric_meter/deleteImageElectricMeter', params)
export const addElectricWaterApi = (params) => postData('web2/electric_water/addElectricWater', params)
export const listMonth = (params) => getData('web2/info/getListCycleName', params)
export const updateElectricWaterApi = (params) => postData('web2/electric_meter/UpdateAfterNumberElectricMeter', params)
export const updateElectric3PhaApi = (params) => postData('web2/electric_meter/UpdateElectric3Pha', params)

export const importElectricMeterApi = (params) =>
    postData('web2/electric_meter/importElectricMeter', params, false, true)
export const importElectricMeterV2Api = (params) =>
    postData('web2/electric_meter/importElectricMeterV2', params, false, true)
export const importElectricThreePhasePowerApi = (params) =>
    postData('web2/electric_meter/importElectricMeterV3Pha', params, false, true)
export const exportElectricMeterApi = (params) => getData('web2/electric_meter/expElectricMeter', params, true)
export const exportElectricMeter3PriceApi = (params) => getData('web2/electric_meter/expElectric3Price', params, true)
