import { DropdownForm, FormUpdateDialog, InputForm, InputTextareaForm, useGetParams } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addBuildingInfoApi, updateBuildingInfoApi } from '../api'
import { useDetailBuildingInfo } from '../utils'

const UpdateBuildingInfor = (props) => {
    const initParam = useGetParams()
    const { visible, setVisible, setParams } = props

    const { id } = useParams()
    const buildingInfoDetail = useDetailBuildingInfo(id)
    const [infos, setInfos] = useState({
        name: '',
        description: '',
        address: '',
        money: '',
        mobile: '',
        email: '',
        receive_supervision: '',
        template_email: '',
        hashkey: '',
    })
    useEffect(() => {
        if (buildingInfoDetail.id)
            setInfos({ ...infos, ...buildingInfoDetail, status: buildingInfoDetail.status === 0 ? false : true })
    }, [buildingInfoDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, buildingInfoDetail), id: id }
        return info
    }

    const supervisor = [
        { id: 1, name: 'Bộ phận kĩ thuật' },
        { id: 2, name: 'Bộ phận lễ tân' },
        { id: 3, name: 'Bộ phận an ninh' },
        { id: 4, name: 'Bộ phận vệ sinh' },
        { id: 5, name: 'Trưởng ban quản lý' },
    ]
    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            refreshObjects={[setInfos]}
            route="/building_overview"
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'thông tin'}
            actions={{ add: addBuildingInfoApi, update: updateBuildingInfoApi }}
        >
            <InputForm
                id="name"
                value={infos.name}
                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                label="Tên tòa nhà (*)"
                required
            />
            <InputTextareaForm
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Mô tả"
            />
            <InputForm
                id="address"
                value={infos.address}
                onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                label="Địa chỉ"
            />
            <InputForm
                id="money"
                value={infos.money}
                onChange={(e) => setInfos({ ...infos, money: e.target.value })}
                label="Đóng tiền mặt"
            />
            <InputForm
                id="mobile"
                value={infos.type_maintain}
                onChange={(e) => setInfos({ ...infos, mobile: e.target.value })}
                label="Mobile"
            />
            <InputForm
                id="email"
                value={infos.type_maintain}
                onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                label="Email"
            />
            <DropdownForm
                id="receive_supervision"
                value={infos.receive_supervision}
                optionLabel="name"
                options={supervisor}
                onChange={(e) => setInfos({ ...infos, receive_supervision: e.target.value })}
                label="Bộ phận giám sát"
            />
            <InputForm
                id="template_email"
                value={infos.template_email}
                onChange={(e) => setInfos({ ...infos, template_email: e.target.value })}
                label="Template Email"
            />
            <InputForm
                id="hashkey"
                value={infos.hashkey}
                onChange={(e) => setInfos({ ...infos, hashkey: e.target.value })}
                label="Hash Key"
            />
        </FormUpdateDialog>
    )
}

export default UpdateBuildingInfor
