import { getListInvoiceApi, sendInvoiceApi } from "@/api";
import {
    Body,
    Calendarz,
    Columnz,
    DataTablezV3, Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz, TimeBody,
    useGetParams
} from '@/components';
import { listToast, statusInvoice } from "@/constants";
import { useGetApi } from "@/lib/react-query";
import { useListCycleName } from "@/modules/debts/services_list/utils";
import CancelInvoice from "@/modules/invoice/CancelInvoice";
import { setToast } from "@/redux/features";
import { Button } from "@/uiCore";
import { checkJson, formatNumber } from "@/utils";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({})
    const listCycleName = useListCycleName()
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className={"lg:col-6"}>
            <Inputz
                value={filter.tax_authority_code}
                onChange={(e) => setFilter({ ...filter, tax_authority_code: e.target.value })}
                placeholder="Tìm kiếm theo mã CQT"
            />
            <Dropdownz
                value={filter.bdc_apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, bdc_apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.cycle_name}
                options={listCycleName}
                onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                optionLabel="cycle_name"
                optionValue="cycle_name"
                placeholder="Chọn kỳ bảng kê"
            />
            <Dropdownz
                options={statusInvoice}
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
            <Dropdownz
                options={[{ name: "Đã gửi", id: 1 }, { name: "Chưa gửi", id: 0 }]}
                value={filter.is_send}
                onChange={(e) => setFilter({ ...filter, is_send: e.target.value })}
                placeholder="Tình trạng gửi mail"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder="Chọn khoảng thời gian phát hành"
                className="lg:col-3"
            />
        </GridForm>
    )
}
export default function Invoice() {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const { data, isLoading } = useGetApi(getListInvoiceApi, { ...params, first: undefined }, "invoices")
    const services = useSelector(state => state.services);
    const apartments = useSelector((state) => state.apartments)

    const ActionsBody = (e) => {
        return <div className="flex gap-2 align-items-center justify-content-center">
            <Button type="button" icon="pi pi-bell" rounded outlined onClick={async () => {
                setLoading(true)
                const response = await sendInvoiceApi({ id: e.id })
                setLoading(false)
                if (response?.data?.status) {
                    dispatch(setToast({ ...listToast[0], detail: 'Gửi hóa đơn thành công' }))
                    setParams(pre => ({ ...pre, render: !pre.render }))
                } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
            }} />
            {e.status !== "HUY" &&
                <Button type="button" icon="pi pi-times" severity={"danger"} rounded outlined onClick={() => setVisible(e.other_id)} />}
        </div>
    }

    const ActionsBodyV2 = (e) => {
        return <div className="flex gap-2 align-items-center justify-content-center">
            <Button type="button" icon="pi pi-file-pdf" rounded outlined onClick={() => {
                window.open(e.link_pdf_file, "_blank")
            }} />
        </div>
    }

    return (
        <div className="card">
            {visible && <CancelInvoice visible={visible} setVisible={setVisible} setParams={setParams}
                data={data?.data?.data?.data} />}
            <HeaderListForm title="Danh sách hóa đơn" />
            <Header setParams={setParams} />
            <DataTablezV3
                loading={isLoading || loading}
                value={data?.data?.data?.data}
                title="hóa đơn"
                totalRecords={data?.data?.data?.count || 0}
                params={params}
                setParams={setParams}
                route="/invoice"
                rowClassName={(rowData) => {
                    let classes = '';
                    if (rowData.is_send) classes += 'invoice_id-row';
                    return classes;
                }}
            >
                <Columnz field="other_id" header="Mã hóa đơn" bodyStyle={{ minWidth: '12rem' }} />
                <Columnz header="Mã bảng kê" body={e => {
                    const data = checkJson(e.data) || e.data
                    return data.bill_code
                }} />
                <Columnz header="Kỳ" body={e => {
                    const data = checkJson(e.data) || e.data
                    return data.cycle_name
                }} />
                <Columnz header="Thông tin mua hàng" body={e => {
                    const data = checkJson(e.data) || e.data
                    const apartment_id = data.bdc_apartment_id
                    const apartment = apartments.find(a => a.id === apartment_id)
                    return <div className={"flex flex-column text-left gap-1"}>
                        <span><b>Căn hộ: </b> {apartment?.name}</span>
                        <span><b>Người mua hàng: </b> {data.buyer_name}</span>
                        <span><b>Đơn vị: </b> {data.customer_name}</span>
                        <span><b>Mã số thuế: </b> {data.buyer_tax_code}</span>
                        <span><b>Địa chỉ: </b> {data.buyer_address}</span>
                        <span><b>Tổng giá trị: </b> {formatNumber(data.total_payment)}</span>
                    </div>
                }}
                    bodyStyle={{ minWidth: '16rem' }} />
                <Columnz header="Dịch vụ" body={e => {
                    let values = checkJson(e.services) || e.services
                    if (!Array.isArray(values)) values = [values]
                    if (Array.isArray(values)) {
                        return <div className={'flex flex-column gap-2 text-left'}>
                            {values.map((s, index) => {
                                const service = services.find(sv => sv.id === Number(s))
                                return service?.name ? <span key={index}>{index + 1}. {service?.name}</span> : <></>
                            })}
                        </div>
                    }
                }} />
                <Columnz field="tax_authority_code" header="Mã CQT" />
                <Columnz header="Ngày phát hành" body={e => TimeBody(e.invoice_date)} />
                <Columnz body={(e) => Body({
                    data: statusInvoice,
                    value: e.status
                })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
                <Columnz body={ActionsBodyV2} header="Xem/tải hóa đơn" />
                <Columnz body={ActionsBody} header="Actions" />
            </DataTablezV3>
        </div>
    )
}
