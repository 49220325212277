import { CalendarForm, FormUpdateDialog } from '@/components'
import { useState } from 'react'
import { updateCycleName } from '../api'
import {databaseDate} from "@/lib";

const UpdateExcessMoneyDetails = (props) => {
    const { setVisible, setParams, visible } = props
    const [infos, setInfos] = useState({
        begin: '',
    })
    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        return `${year}${month}`;
    }
    const handleData = () => {
        const info = { ...infos, cycle_name: Number(formatDateString(infos.begin)), begin: databaseDate(infos.begin), id: visible }
        return info
    }
    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Sửa ' : 'Thêm mới ') + 'ngày hạch toán'}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            route="/excess_money_details"
            refreshObjects={[setInfos]}
            handleData={handleData}
            actions={{ update: updateCycleName }}
        >
            <div className="card bg-color">
                <div className="grid grid-form justify-content-center">
                    <div className="col-12 lg:col-6">
                        <CalendarForm
                            id="time"
                            value={infos.begin}
                            onChange={(e) => {
                                setInfos({ ...infos, begin: e.target.value })
                            }}
                            showIcon
                            label="Ngày hạch toán"
                            readOnlyInput
                            required
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateExcessMoneyDetails
