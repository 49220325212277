import { FormUpdateDialog, InputForm } from '@/components'
import React, { useEffect, useState } from 'react'
import { addPosition, listPosition, updatePosition } from '../api'
import { useGetApi } from '@/hooks'
import { removePropObject } from '@/utils'

function UpdatePosition(props) {
      const { setVisible, setParams, visible, } = props
      const [infos, setInfos] = useState({})


      const data = useGetApi(listPosition)
      const a = data?.find(d => d.id === visible);

      useEffect(() => {
            if (typeof visible === 'number') {
                  setInfos({
                        ...infos,
                        name: a?.name,
                        description: a?.description,
                        id: visible
                  });
            }
      }, [visible, a]);

      const handleData = () => {

            let info = { ...infos }
            if (typeof visible === 'number') info = { ...removePropObject(info, a), id: visible }
            return info
      }
      return (
            <FormUpdateDialog
                  title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + ' chức vụ'}
                  visible={visible}
                  setVisible={setVisible}
                  setParams={setParams}
                  refreshObjects={[setInfos]}
                  actions={{ add: addPosition, update: updatePosition }}
                  handleData={handleData}
            >
                  <InputForm
                        label="Chức vụ (*)"
                        value={infos.name}
                        onChange={(e) => setInfos({
                              ...infos, name: e.target.value
                        })}
                        required />
                  <InputForm
                        label="Mô tả "
                        value={infos.description}
                        onChange={(e) => setInfos({
                              ...infos, description: e.target.value
                        })}
                        required />


            </FormUpdateDialog >
      )
}

export default UpdatePosition
