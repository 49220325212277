import { useEffect, useState } from 'react'
import { Calendarz, GridForm, useGetParams, HeaderListForm } from '@/components'
import { Chart } from 'primereact/chart'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="3 tháng gần đây" />
        </GridForm>
    )
}
function AggreGateRevenueReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [chartDataHorizon, setChartDataHorizon] = useState({})
    const [chartOptionsHorizon, setChartOptionsHorizon] = useState({})
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: [
                'Toà 17T1',
                'Toà 17T2',
                'Toà 17T3',
                'Toà 15T',
                'Toà 34T',
                'Toà 24T1',
                'Toà 17T4',
                'Toà 17T5',
                'Toà 17T6',
                'Toà 18T1',
                'Toà Bohemia',
                'Toà Vinata Tower',
                'Toà VinaConex 1',
                'New House Xala',
                'Nhà Ga T2',
                'N05 Hoàng.Đ.T',
            ],
            datasets: [
                {
                    type: 'bar',
                    label: 'Công nợ đã thu',
                    backgroundColor: '#F3722C',
                    data: [
                        1800, 1500, 1900, 1600, 1880, 800, 1690, 1350, 1500, 1330, 1700, 1500, 1600, 1700, 1400, 1800,
                    ],
                    borderColor: 'white',
                    borderWidth: 0.5,
                    barPercentage: 0.6,
                    // borderWidth: 10,
                    barThickness: 22,
                    // maxBarThickness: 60,
                },
                {
                    type: 'bar',
                    label: 'Công nợ phải thu',
                    backgroundColor: '#165BAA',
                    data: [1560, 1100, 1800, 1500, 1700, 900, 1900, 1700, 1500, 1590, 1400, 1300, 1200, 1500],
                    borderWidth: 0.5,
                    barPercentage: 0.6,
                    barThickness: 22,
                },
                {
                    type: 'line',
                    label: 'Tỉ lệ %',
                    borderColor: '#F94144',
                    backgroundColor: '#F94144',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: [1500, 1300, 1500, 1900, 1900, 1900, 1900, 1900, 1600, 1500, 1600, 1500],
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    position: 'bottom',
                },
            },
            scales: {
                xAxes: [
                    {
                        categoryPercentage: 1.0,
                        barPercentage: 1.0,
                    },
                ],
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                    },
                },
                y: {
                    max: 2000,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                        text: 'Trăm triệu đồng',
                    },
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: surfaceBorder,
                    },
                },
            },
        }
        setChartData(data)
        setChartOptions(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: [
                'Greenbay',
                'Phương Đông',
                'Lafortuna',
                'Goldenland',
                'Ecolake View',
                'River Side',
                'BeaSky',
                'Fuji',
                'Teco',
                'Paragon',
                'Green Pearl',
                'Imperial',
            ],
            datasets: [
                // {
                //     type: 'scatter',
                //     label: 'Dự kiến',
                //     borderColor: "#00142C",
                //     backgroundColor: "#00142C",
                //     borderWidth: 2,
                //     pointRadius: 6,
                //     fill: false,
                //     tension: 0.4,
                //     data: [250, 1100, 250, 1000, 650, 300, 600, 750, 790, 800, null], // Đã thêm giá trị null ở cuối
                // },
                {
                    type: 'scatter',
                    label: 'Dự kiến',
                    borderColor: '#00142C',
                    backgroundColor: '#00142C',
                    borderWidth: 2,
                    pointRadius: 6,
                    barPercentage: 0.9,
                    categoryPercentage: 0.7,
                    fill: false,
                    tension: 0.4,
                    data: [350, 1200, 350, 1000, 650, 300, 600, 750, 790, 800, 900, 1250], // Đã thêm giá trị null ở cuối
                },
                {
                    label: 'Tổng Doanh thu',
                    backgroundColor: '#0CA614',
                    borderColor: '#0CA614',
                    data: [300, 1250, 150, 1000, 700, 400, 500, 300, 700, 900, 700, 1300],
                    borderRadius: 10,
                    barPercentage: 0.8,
                    categoryPercentage: 0.8,
                },
                {
                    label: 'Tổng chi Phí',
                    backgroundColor: '#F3722C',
                    borderColor: '#F3722C',
                    data: [250, 1100, 200, 1100, 650, 300, 600, 750, 800, 730, 800, 1200],
                    borderRadius: 10,
                    options: {
                        layout: {
                            padding: 20,
                        },
                    },
                },
            ],
        }
        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 1,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                    },
                    position: 'bottom',
                },
            },
            scales: {
                x: {
                    barPercentage: 0.8,
                    categoryPercentage: 0.7,
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500,
                        },
                        maxTicksLimit: 20,
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    grace: '15%',
                },
                y: {
                    barPercentage: 0.8,
                    categoryPercentage: 0.7,
                    ticks: {
                        color: textColorSecondary,
                        maxTicksLimit: 20,
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                    },
                    grace: '15%',
                },
            },
        }
        setChartDataHorizon(data)
        setChartOptionsHorizon(options)
    }, [])

    return (
        <>
            <div className="card">
                <HeaderListForm title="Doanh thu và lợi nhuận" />
                <Header setParams={setParams} />
                <h1 className="m-0">Doanh thu</h1>
                <Chart
                    type="line"
                    data={chartData}
                    options={chartOptions}
                    style={{ height: '600px', marginTop: '50px' }}
                />
            </div>
            <div className="card">
                <Header setParams={setParams} />
                <h1 className="m-0">Lợi nhuận</h1>

                <Chart type="bar" data={chartDataHorizon} options={chartOptionsHorizon} style={{ height: '700px' }} />
            </div>
        </>
    )
}

export default AggreGateRevenueReport
