import { CalendarForm, DropdownForm, FormUpdate, HeaderListForm, InputForm, UploadImg, useGetParams } from '@/components'
import { databaseDate } from '@/lib'
import { checkJson, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import { listUserApi } from '@/api'
import { listToast } from '@/constants'
import { useGetApi } from '@/hooks'
import { listPosition } from '@/modules/hrm/position_management/api'
import { setToast } from '@/redux/features'
import { useListAsset, useListAssetMix } from '../../asset_mix_management/utils'
import { listDepartmentWareHouse } from '../../inventory_management/api'
import { useDetailWarehouse } from '../../inventory_management/utils'
import { addAsset } from '../api'
import { useDetailAsset, useListUnit } from '../utils'

const AssetGeneralInformation = ({ setShowMore }) => {
      const { id } = useParams()
      const unit = useListUnit()
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const [infos, setInfos] = useState({})
      const detail = useDetailAsset(id)
      const imageAssset = useDetailWarehouse(detail.code)
      detail.attack = imageAssset.attack
      const [files, setFiles] = useState([])

      const [images, setImages] = useState([])
      const dispatch = useDispatch()
      useEffect(() => {
            if (id) setShowMore(true)
      }, [id])
      const product = useListAsset({ status: undefined })
      const group = useListAssetMix({ status: undefined })
      const users = useSelector(state => state.users)
      const person = useGetApi(listUserApi, { ...params, first: undefined, type: 'hrm' }, [])
      const position = useGetApi(listPosition, { ...params }, [])
      const listDepartment = useGetApi(listDepartmentWareHouse)
      const [image, setImage] = useState([])
      useEffect(() => {

            if (detail.id)
            {
                  const findMNV = users.find(d => d.user_id === detail.user_hand_over)
                  const findPosition = person?.find(d => d.id === detail.user_receive)
                  const findPositionv2 = findPosition?.bills.find(d => d.status === 1)
                  const findPositionv3 = person?.find(d => d.id === detail.user_use)
                  const findPositionv4 = findPositionv3?.bills.find(d => d.status === 1)
                  setInfos({
                        ...infos,
                        ...removeUndefinedProps(detail),
                        created_at: detail.created_at ? new Date(detail.created_at) : '',
                        mnv: findMNV?.code,
                        position: findPositionv2?.position,
                        positionv2: findPositionv4?.position

                  })

                  if (detail.file)
                  {
                        const file = checkJson(detail.file) || detail.file
                        if (Array.isArray(file))
                        {
                              setFiles([...file])
                        }
                  }
                  if (detail.attack)
                  {
                        const attack = checkJson(detail.attack) || detail.attack

                        if (attack.image)
                        {
                              setImage(attack.image)
                        }
                  }

            }
      }, [detail, position, person, imageAssset])
      const handleData = () => {
            if (!infos.unit_id) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn đơn vị ' }))
            if (!infos.group_id) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn nhóm' }))
            if (!infos.from_location) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn vị trí người bàn giao' }))
            if (!infos.from_by) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng người bàn giao' }))
            if (!infos.start_use) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn thời gian bắt đầu sử dụng' }))
            if (!infos.user_location) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn vị trí sử dụng' }))
            if (!infos.user_use) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn người sử dụng' }))
            if (!infos.status) return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn trạng thái' }))
            let info = { ...infos }
            info.start_use = info.start_use ? databaseDate(info.start_use, false,) : undefined
            if (images && images[0]) info.images = images
            else info.images = []
            if (files && files[0])
            {
                  info.file = files
            } else info.file = []
            return info
      }
      return (
            <FormUpdate
                  checkId={Number(id)}
                  title="tài sản"
                  handleData={handleData}
                  route="/asset_management"
                  actions={{ add: addAsset }}
            >
                  <div className=" bg-color">
                        <div className="grid grid-form">

                              <div className="col-12 lg:col-4">
                                    <UploadImg image={image} view setImage={setImage} title="Ảnh danh mục sản phẩm " />

                              </div>
                              <div className="col-12 lg:col-8">
                                    <div className="card">
                                          <HeaderListForm title="Thông tin bàn giao" />

                                          <div className="grid grid-form ">
                                                <div className="col-12">
                                                      <DropdownForm label="Phòng ban bàn giao" value={infos.department_hand_over} options={listDepartment} disabled />
                                                </div>
                                                <div className="col-6">
                                                      <DropdownForm label="Người bàn giao" value={infos.user_hand_over} options={users} optionLabel="full_name" optionValue="user_id" disabled />

                                                </div>
                                                <div className="col-6">
                                                      <InputForm label="Mã NV" value={infos.mnv} disabled />
                                                </div>
                                          </div>
                                          <HeaderListForm title="Thông tin tiếp nhận" />

                                          <div className="grid grid-form ">
                                                <div className="col-12">
                                                      <DropdownForm label="Phòng ban tiếp nhận" value={infos.department_receipt} options={listDepartment} disabled />
                                                </div>

                                                <div className="col-6">
                                                      <DropdownForm label="Người tiếp nhận" value={infos.user_receive} options={users} optionLabel="full_name" optionValue="user_id" disabled />

                                                </div>
                                                <div className="col-6">
                                                      <DropdownForm label="Chức vụ" options={position} value={infos.position} disabled />
                                                </div>
                                                <div className="col-6">
                                                      <DropdownForm label="Người sử dụng" value={infos.user_use} options={users} optionLabel="full_name" optionValue="user_id" disabled />

                                                </div>
                                                <div className="col-6">
                                                      <DropdownForm label="Chức vụ" options={position} value={infos.positionv2} disabled />
                                                </div>
                                                <div className="col-12">
                                                      <InputForm label="Vị trí sử dụng" disabled />
                                                </div>
                                          </div>
                                          <HeaderListForm title="Thông tin sản phẩm" />
                                          <div className="grid grid-form ">
                                                <div className="col-12 lg:col-6 ">
                                                      <div className="grid">
                                                            <div className="col-6">
                                                                  <DropdownForm
                                                                        id="apartment_id"
                                                                        value={infos.product_id}
                                                                        label="Tên tài sản (*)"
                                                                        onChange={(e) => setInfos({ ...infos, code_unit_product: e.target.value })}
                                                                        options={product}
                                                                        disabled
                                                                  />
                                                            </div>
                                                            <div className="col-6">
                                                                  <InputForm
                                                                        id="service_id"
                                                                        value={infos.code}
                                                                        label="Mã tài sản  (*)"
                                                                        onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                                                        disabled

                                                                  />
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="col-12 lg:col-6">
                                                      <div className="grid">
                                                            <div className="col-8">
                                                                  <InputForm
                                                                        id="service_id"
                                                                        value={infos.amount}
                                                                        label="Số lượng (*)"
                                                                        onChange={(e) => setInfos({ ...infos, amount: e.target.value })}
                                                                        disabled
                                                                  />
                                                            </div>
                                                            <div className="col-4">
                                                                  <DropdownForm
                                                                        label="Đơn vị (*)"
                                                                        value={infos.unit_id
                                                                        }
                                                                        onChange={(e) => setInfos({
                                                                              ...infos, unit_id: e.target.value
                                                                        })}
                                                                        options={unit}
                                                                        disabled
                                                                  />
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="col-6">
                                                      <DropdownForm label="Nhóm"
                                                            value={infos.product_cate_id
                                                            }
                                                            onChange={(e) => setInfos({
                                                                  ...infos, product_cate_id: e.target.value
                                                            })}
                                                            options={group}
                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-6">
                                                      <CalendarForm value={infos.created_at} label="Thời gian bắt đầu sử dụng" disabled
                                                            showIcon={false}
                                                      />
                                                </div>
                                                {/* <div className="col-6">
                                                      <DropdownForm options={status_productv2} label="Trạng thái sản phẩm" value={infos.status_product} disabled />
                                                </div> */}
                                                <div className="col-6">
                                                      <InputForm label="Ghi chú" value={infos?.note} disabled />
                                                </div>



                                                {/*
                                                <div className="col-12 lg:col-6">
                                                      <UploadFiles files={files} setFiles={setFiles} />
                                                </div> */}
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </FormUpdate >
      )
}

export default AssetGeneralInformation
