import { postData, getData } from '@/lib/request'

//building info

export const listDepartmentApi = (params) => getData('web2/building_group/getListGroupV2', params)
export const countDepartmentApi = (params) => getData('web2/building_group/countListGroupV2', params)
export const deleteDepartmentApi = (params) => postData('web2/building_group/deleteGroupV2', params)
export const detailDepartmentApi = (params) => getData('web2/building_group/getDetailGroupV2', params)
export const addDepartmentApi = (params) => postData('web2/building_group/addGroupV2', params)
export const updateDepartmentApi = (params) => postData('web2/building_group/updateGroupV2', params)

export const countBuildingCompanyApi = (params) => getData('web2/building_group/countBuildingCompany', params)
export const detailStaffApi = (params) => getData('web2/building_group/getDetailBuildingCompany', params)
export const listNameBuildingCompanyApi = (params) => getData('web2/building_group/getListNameBuildingCompany', params)
export const listStaffApi = (params) => getData('web2/building_group/getListBuildingCompany', params)
