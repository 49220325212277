import { Fragment, useEffect } from 'react'
import { ProgressBar } from 'primereact/progressbar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    ToggleLoading,
    setBuildings,
    setItem,
    setMyTool,
    setResidents,
    setServices,
    setUserInfo,
} from '@/redux/features'
import { getAuthApi, getMyToolApi } from '../api'
import { setUsers } from '@/redux/features/users'
import { setApartments } from '@/redux/features/apartments'
import { listServiceV2Api } from '@/modules/accountant/building_services/api'
import { listApartmentV2Api, listBuildingV2Api, listResidentV2Api, listUserV2Api } from '@/api'
import { listTypePriceApi } from '@/api/projects/info'
import { setTypePrice } from '@/redux/features/typeprice'

const Loading = (props) => {
    const item = useSelector((state) => state.item) || {}
    const dispatch = useDispatch()
    const navigate = useNavigate()

    async function fetchData() {
        const getAuth = await getAuthApi()
        if (getAuth.data && getAuth.data.status) {
            const userInfo = getAuth.data.data
            dispatch(setUserInfo(userInfo))
        } else {
            localStorage.removeItem('token')
            dispatch(ToggleLoading(false))
            return
        }
        const getListMyTool = await getMyToolApi()
        if (getListMyTool.data && getListMyTool.data.status) {
            const data = getListMyTool.data.data
            if (!data) return
            const companies = data.company && data.company[0] ? data.company : []
            if (!item.company_id && companies[0]) {
                const obj = { company_id: companies[0].id }
                dispatch(setItem(obj))
            }
            const tools = data.tool && data.tool[0] ? data.tool : []
            const myMenuTool = []
            tools.forEach((t) => {
                const index = myMenuTool.findIndex((m) => m.tool_id === t.tool_id)
                if (index < 0) {
                    myMenuTool.push({
                        tool_id: t.tool_id,
                        name: t.tool_name,
                        type: t.type,
                        icon: t.icon,
                        children: [{ name: t.tool_child_name, route: t.route, actions: t.actions }],
                    })
                } else myMenuTool[index].children.push({ name: t.tool_child_name, route: t.route, actions: t.actions })
            })
            let myTool = ['/', '/auth/change_password']
            myMenuTool.forEach((mmt) => {
                if (mmt.children && mmt.children[0]) {
                    mmt.children.forEach((tc) => {
                        if (!tc.route) return
                        const actionsJson = tc.actions
                        const actions = actionsJson ? JSON.parse(actionsJson) : []
                        myTool = [
                            ...myTool,
                            ...actions.map((a) => {
                                if (a === 'view') return tc.route
                                else if (a === 'detail') return tc.route + '/detail/:id'
                                else return tc.route + '/' + a
                            }),
                        ]
                    })
                }
            })
            myMenuTool.unshift({ name: 'Trang chủ', icon: 'pi pi-home', children: [{ name: 'Trang chủ', route: '/' }] })
            dispatch(setMyTool({ companies, myMenuTool, myTool }))
        }
        const getUsers = await listUserV2Api()
        if (getUsers.data && getUsers.data.status) {
            const users = getUsers.data.data
            dispatch(setUsers(users))
        }
        const getApartments = await listApartmentV2Api()
        if (getApartments.data && getApartments.data.status) {
            const apartments = getApartments.data.data
            dispatch(setApartments(apartments))
        }
        const getResident = await listResidentV2Api()
        if (getResident.data && getResident.data.status) {
            const residents = getResident.data.data
            dispatch(setResidents(residents))
        }
        const getBuilding = await listBuildingV2Api()
        if (getBuilding.data && getBuilding.data.status) {
            const buildings = getBuilding.data.data
            dispatch(setBuildings(buildings))
        }
        if (item.building_id) {
            const getService = await listServiceV2Api()
            if (getService.data && getService.data.status) {
                const services = getService.data.data
                dispatch(setServices(services))
            }
        }

        const getListTypePriceApi = await listTypePriceApi()
        if (getListTypePriceApi.data && getListTypePriceApi.data.status)
        {
            const typePrice = getListTypePriceApi.data.data
            const typePricev2 = typePrice.map(item => {
                if (item.name === "Điện Ba giá")
                {
                    return { ...item, name: "Điện 3 giá" };
                }
                return item;
            });
            console.log(typePricev2);
            dispatch(setTypePrice(typePricev2))
        }
        dispatch(ToggleLoading(false))
    }

    useEffect(() => {
        const token = localStorage.getItem('token')
        const clientId = localStorage.getItem('clientId')
        if (!token || !clientId) {
            dispatch(ToggleLoading(false))
            navigate('/auth/login')
        } else fetchData()
    }, [])

    return (
        <Fragment>
            <Fragment>
                <ProgressBar mode="indeterminate" style={{ height: '6px', zIndex: '1001' }}></ProgressBar>
                <div className="block surface-ground fixed w-full h-full top-0 left-0" style={{ zIndex: '1000' }}></div>
            </Fragment>
            {props.children}
        </Fragment>
    )
}

export default Loading
