import { listExchangeV2Api, listUserApi } from '@/api';
import { CalendarForm, DropdownForm, HeaderListForm, InputForm, useGetParams } from '@/components';
import { listToast, product_category } from '@/constants';
import { useGetApi } from '@/hooks';
import { databaseDate } from '@/lib';
import { listPosition } from '@/modules/hrm/position_management/api';
import { setToast } from '@/redux/features';
import { removeNullProps } from '@/utils';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { listAssetManager } from '../../asset_managerment/api';
import { useListCategory, useListUnit } from '../../asset_managerment/utils';
import { useListAsset, useListAssetMix } from '../../asset_mix_management/utils';
import { createBill, listDepartmentWareHouse } from '../api';
import { useListWarehouseDepartment } from '../utils';

function CreateRolateSlip() {
    const userInfo = useSelector((state) => state.userInfo)
    const users = useSelector((state) => state.users)
    const user_id = userInfo?.user_id
    const listDepartment = useGetApi(listDepartmentWareHouse)

    const [infos, setInfos] = useState({
        create_by: user_id,
        code: generateCode()
    });

    function generateCode() {
        const currentTime = new Date();
        const formattedTime = `${currentTime.getFullYear()}${(currentTime.getMonth() + 1).toString().padStart(2, '0')}${currentTime.getDate().toString().padStart(2, '0')}_${currentTime.getHours().toString().padStart(2, '0')}${currentTime.getMinutes().toString().padStart(2, '0')}${currentTime.getSeconds().toString().padStart(2, '0')}`;
        return `PLC_${formattedTime}`;
    }
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const unit = useListUnit()
    const category = useListCategory()
    const [data, setData] = useState([])
    // const product = useListWarehouse({ status: undefined })
    const listProduct = useListWarehouseDepartment({ department_id: infos.department_hand_over })

    const product = useListAsset({ status: undefined })
    const dispatch = useDispatch()
    const person = useGetApi(listUserApi, { ...params, first: undefined, type: 'hrm' }, [])
    const group = useListAssetMix({ status: undefined })
    const exchanges = useGetApi(listExchangeV2Api)

    const navigate = useNavigate()
    const position = useGetApi(listPosition, { ...params }, [])

    const handleChange = (value, field, index) => {
        setData(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], [field]: value };
            return newData;
        });
    };
    const handleDelete = (indexV2) => {
        if (indexV2 || indexV2 === 0) setData((pre) => pre.filter((d, index) => index !== indexV2))
    }
    const handleAdd = () => {
        const newItem = {};
        setData((prevData) => [...prevData, newItem]);
    }

    const handleData = async () => {
        if (!infos.create_date) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn thời gian luân chuyển" }));
        if (!infos.department_hand_over) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn phòng ban bàn giao" }));
        if (!infos.code) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng nhập mã phiếu luân chuyển" }));
        // if (!infos.user_confirm) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người duyệt" }));
        if (!infos.user_hand_over) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người bàn giao" }));
        if (!infos.department_receive) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn phòng ban tiếp nhận" }));
        if (!infos.user_receive) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người tiếp nhận" }));
        if (!infos.user_use) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người sử dụng " }));

        let cleanedInfo = { ...infos, create_date: databaseDate(infos.create_date, false, 'date') };

        cleanedInfo = removeNullProps(cleanedInfo);
        if (data.some(d => d.asset_id === null || d.asset_id === undefined || d.asset_id === '')) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn sản phẩm" }));
        if (data.some(d => d.group_product === 'tieu_hao' && (!d.amount || d.amount > 100000))) return dispatch(setToast({ ...listToast[1], detail: "Số lượng không được để trống và phải nhỏ hơn hoặc bằng 100000" }));
        if (data.some(d => d.group_product === 'tieu_hao'))
        {
            for (let i = 0; i < data.length; i++)
            {
                if (parseInt(data[i].amount) > data[i].soluong)
                {
                    return dispatch(setToast({ ...listToast[1], detail: `Số lượng luân chuyển ở sản phẩm không được lớn hơn số lượng hiện có` }));
                }
            }
        }
        let newData = data.map(obj => {
            let newObj = { ...obj };
            delete newObj.soluong;
            return newObj;
        });

        const params = { ...cleanedInfo, type: 'rol', list_product: newData };
        const call = await createBill(params);
        if (!call.data.status)
        {
            dispatch(setToast({ ...listToast[1], detail: call.data.mess }));
        } else
        {
            dispatch(setToast({ ...listToast[0], detail: "Tạo phiếu luân chuyển thành công" }));
            navigate('/asset');
        }
    }
    const userWithExChanges = person.filter(d => d.group_id === infos.department_hand_over)
    const userWithExChangesv2 = person.filter(d => d.group_id === infos.department_receive)

    // const matchingProducts = [];
    // let addedIds = new Set();

    // for (let i = 0; i < product.length; i++) {
    //     let productId = product[i].id;
    //     let unitId = product[i].unit_id;

    //     for (let j = 0; j < productor.length; j++) {
    //         if (productor[j].id === product[i].code_unit_product && !addedIds.has(productor[j].id)) {
    //             matchingProducts.push({
    //                 ...productor[j],
    //                 unit_id: unitId
    //             });
    //             addedIds.add(productor[j].id);
    //             break;
    //         }
    //     }
    // }

    // useEffect(() => {
    //     if (infos.status_exp && infos.status_exp === 2) {
    //         setInfos({ ...infos, department_receive: 0 })
    //     } else {
    //         setInfos({ ...infos, department_receive: null, user_receive: null, position_use: null, user_use: null })
    //     }
    // }, [infos.status_exp])
    const [assetWith, setAssetWith] = useState(null)
    useEffect(() => {
        if (infos.department_hand_over && infos.user_hand_over)
        {
            const fetchData = async () => {
                const rolate_slip = await listAssetManager({
                    department_id: infos.department_hand_over,
                    user_id: infos.user_hand_over
                });
                setAssetWith(rolate_slip?.data?.data);
            };
            fetchData();
        }
    }, [infos.department_hand_over, infos.user_hand_over]);
    const assetFind = useListAsset({ status: undefined, })

    assetWith?.forEach(item2 => {
        assetFind?.forEach(item1 => {
            if (item1.id === item2.product_id)
            {
                item2.name = item1.name;
            }
        });
    });


    const a = users.find(d => d.user_id === infos.create_by)

    return (
        <div>
            <h3 >Tạo phiếu luân chuyển tài sản</h3>

            <div className="card">
                <HeaderListForm title="Thông tin chung" />
                <div className="grid">
                    <div className="col-6">
                        <CalendarForm label="Thời gian luân chuyển (*)"
                            value={infos.create_date}
                            onChange={(e) => setInfos({ ...infos, create_date: e.target.value })} />
                    </div>
                    <div className="col-6">
                        <InputForm label="Mã phiếu luân chuyển (*)"
                            value={infos.code}
                            // onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            disabled
                        />
                    </div>

                    {a && <div className="col-6">
                        <DropdownForm label="Người tạo phiếu"
                            value={infos.create_by}
                            onChange={(e) => setInfos({ ...infos, create_by: e.target.value })}
                            options={users}
                            optionLabel='full_name'
                            disabled
                            optionValue='user_id'

                        />
                    </div>}
                </div>
                <HeaderListForm title="Thông tin bàn giao" />
                <div className="grid">
                    <div className="col-6">
                        <DropdownForm label="Phòng ban bàn giao (*)"
                            value={infos.department_hand_over}
                            options={listDepartment}
                            onChange={(e) => setInfos({ ...infos, department_hand_over: e.target.value })} />
                    </div>
                    <div className="col-6 grid">
                        <div className="col-8">
                            <DropdownForm
                                options={userWithExChanges.filter(d => d.id !== infos.user_receive)}
                                label="Người bàn giao (*)"
                                optionLabel="full_name"
                                value={infos.user_hand_over}
                                onChange={(e) => {
                                    const selectedUser = userWithExChanges.find(user => user.id === parseInt(e.target.value));
                                    setInfos({ ...infos, user_hand_over: e.target.value, mnv: selectedUser?.code });
                                }} />
                        </div>
                        <div className="col-4">
                            <InputForm
                                label="Mã nhân viên (*)"
                                value={infos.mnv}
                                onChange={(e) => setInfos({ ...infos, mnv: e.target.value })}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <DropdownForm label="Người phê duyệt"
                            value={infos.user_confirm
                            }
                            optionLabel="full_name"
                            options={userWithExChanges}
                            onChange={(e) => setInfos({ ...infos, user_confirm: e.target.value })} />
                    </div>
                </div>

                <HeaderListForm title="Thông tin tiếp nhận" />
                <div className="grid">
                    <div className="col-6">
                        <DropdownForm label="Phòng ban tiếp nhận (*)"
                            value={infos.department_receive}
                            options={listDepartment}
                            onChange={(e) => setInfos({ ...infos, department_receive: e.target.value })} />
                    </div>
                    <div className="col-6 grid">
                        <div className="col-8">
                            <DropdownForm label="Người tiếp nhận (*)"
                                value={infos.user_receive}
                                options={userWithExChangesv2.filter(d => d.id !== infos.user_hand_over)}
                                optionLabel="full_name"
                                onChange={(e) => {
                                    const selectedUser = userWithExChangesv2.find(user => user.id === parseInt(e.target.value));
                                    const position = selectedUser && selectedUser.bills[0] ? selectedUser.bills[0].position : '';
                                    setInfos({ ...infos, user_receive: e.target.value, position: position });
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <DropdownForm label="Chức vụ "
                                value={infos.position}
                                options={position}
                                disabled
                                onChange={(e) => setInfos({ ...infos, position: e.target.value })} />
                        </div>
                    </div>

                    <div className="col-6">
                        <InputForm label="Vị trí sử dụng"
                            value={infos.position_use
                            }
                            onChange={(e) => setInfos({
                                ...infos, position_use: e.target.value
                            })} />
                    </div>
                    <div className="col-6 grid">
                        <div className="col-8">
                            <DropdownForm label="Người sử dụng (*)"
                                value={infos.user_use
                                }
                                options={userWithExChangesv2.filter(d => d.id !== infos.user_hand_over)}
                                optionLabel="full_name"
                                onChange={(e) => {
                                    const selectedUser = userWithExChangesv2.find(user => user.id === parseInt(e.target.value));
                                    const position = selectedUser && selectedUser.bills[0] ? selectedUser.bills[0].position : '';
                                    setInfos({
                                        ...infos, user_use: e.target.value, positionv2: position
                                    });
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <DropdownForm label="Chức vụ"
                                value={infos.positionv2}
                                options={position}
                                disabled
                                onChange={(e) => setInfos({ ...infos, position: e.target.value })} />
                        </div>
                    </div>
                </div>

                <HeaderListForm title="Thông tin sản phẩm luân chuyển" />
                <div className="grid grid-form align-items-center">
                    {data.map((d, index) => {
                        const newOption = assetWith.map(item => {
                            return { ...item, name: item.name + " " + item.code };
                        });
                        return (
                            <div key={index} className="grid grid-form w-full  align-items-center mx-1 card">
                                <div className="col-12 lg:col-3">
                                    <DropdownForm
                                        label="Tên sản phẩm (*)"
                                        optionLabel="name"
                                        options={newOption}
                                        value={d.productor
                                        }
                                        optionValue="id"
                                        onChange={(e) => {

                                            handleChange(e.target.value, 'productor', index, setData(prevData => {
                                                const selectedProductId = e.target.value;
                                                const value = assetWith.find(d => d.id === selectedProductId)
                                                const findGroup = group.find(d => d.id === value?.product_cate_id)
                                                let newData = [...prevData];
                                                newData[index] = {
                                                    asset_id: value?.id,
                                                    code: value?.code,
                                                    group_product: value?.group_product,
                                                    product_cate_id: findGroup?.id,
                                                    unit_id: value?.unit_id,
                                                    status: value?.status,
                                                    id: value?.id,
                                                    product_id: value?.product_id,
                                                    soluong: value?.amount
                                                };

                                                // newData.forEach(obj => {
                                                //     delete obj.productor;

                                                // });

                                                return newData;
                                            }))
                                        }
                                        }
                                    />
                                </div>
                                <div className="col-12 lg:col-3">
                                    <InputForm
                                        label="MSP (*)"
                                        value={d.code}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 lg:col-3">
                                    <InputForm
                                        label="Phân loại (*)"
                                        value={(() => {
                                            const category = product_category.find(item => item.id === d.group_product);
                                            return category ? category.name : "";
                                        })()}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 lg:col-3">
                                    <DropdownForm
                                        label="Nhóm (*)"
                                        options={group}
                                        value={d.product_cate_id}
                                        disabled

                                    />
                                </div>
                                <div className="col-12 lg:col-3">
                                    <DropdownForm
                                        label="Đơn vị (*)"
                                        value={d.unit_id}
                                        disabled
                                        options={unit}
                                    />
                                </div>
                                {d.group_product === 'tieu_hao' ? <div className="col-12 lg:col-3">
                                    <InputForm
                                        label={`Số lượng (*) `}
                                        value={d.amount}
                                        onChange={(e) => handleChange(e.target.value, 'amount', index)}
                                        required
                                    />
                                </div> : ''}
                                <div className="col-12 lg:col-3">
                                    <InputForm
                                        label="Mô tả"
                                        value={d.detail}
                                        onChange={(e) => handleChange(e.target.value, 'detail', index)}
                                    />
                                </div>
                                <div className="col-12 lg:col-3">
                                    <Button
                                        type="button"
                                        raised
                                        icon="pi pi-times"
                                        severity="danger"
                                        disabled={data.length <= 1}
                                        onClick={() => handleDelete(index)
                                        }
                                    />
                                </div>
                                <div className="col-12">
                                    <p>Số lượng hiện có  : {d.soluong}</p>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {infos.user_hand_over && infos.department_hand_over && <Button
                type="button"
                raised
                icon="pi pi-plus"
                severity="info"
                onClick={() => handleAdd()}
                className="ml-2"
                label='Thêm sản phẩm'
            />}


            <div className='flex justify-content-end gap-3 pt-3'>
                <Button label='Trở về' onClick={() => navigate('/asset')} severity='danger' />
                <Button label='Tạo phiếu luân chuyển tài sản' onClick={handleData} />
            </div>
        </div>
    )
}

export default CreateRolateSlip
