import { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailNotification } from '../utils'
import { updateNotificationApi, addNotificationApi } from '../api'
import { InputForm, FormUpdate, InputTextareaForm, InputSwitchForm, CalendarForm } from '@/components'
import { TabPanel, TabView } from 'primereact/tabview'
import { BoxHeader } from './Notification'

const NotificationInfo = (props) => {
    const { infos, setInfos } = props
    return (
        <Fragment>
            <div className="card bg-color">
                <InputForm
                    id="name"
                    value={infos.name}
                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                    label="Tiêu đề"
                    disabled
                />
                <InputTextareaForm
                    id="description"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                    disabled
                    label="Nội dung"
                />
                <InputForm
                    id="name"
                    value={infos.name}
                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                    label="Người tạo"
                    disabled
                />
                <CalendarForm
                    id="ccc"
                    label="Thời gian"
                    value={infos.date}
                    onChange={(e) => setInfos({ ...infos, date: e.target.value })}
                    disabled
                />
            </div>
            <div className="card bg-color">
                <label className="font-medium w-5">Thông số</label>
                <div className="grid grid-form mt-4">
                    <BoxHeader
                        success="1000000"
                        error="1000000"
                        warning="1000000"
                        fluctuation="+20%"
                        title="Email"
                        subTitle="2100000"
                    />
                    <BoxHeader
                        success="1000000"
                        error="1000000"
                        warning="1000000"
                        fluctuation="+20%"
                        title="Email"
                        subTitle="2100000"
                    />
                    <BoxHeader
                        success="1000000"
                        error="1000000"
                        warning="1000000"
                        fluctuation="+20%"
                        title="Email"
                        subTitle="2100000"
                    />
                </div>
            </div>
        </Fragment>
    )
}

const UpdateNotification = () => {
    const { id } = useParams()
    const notificationDetail = useDetailNotification(id)
    const [infos, setInfos] = useState({
        status: true,
    })

    useEffect(() => {
        if (notificationDetail.id) setInfos({ ...infos, ...notificationDetail })
    }, [notificationDetail])

    return (
        <FormUpdate
            checkId={Number(id)}
            title="Thông báo"
            handleData={() => {}}
            route="/notification"
            actions={{ add: addNotificationApi, update: updateNotificationApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card">
                <TabView>
                    <TabPanel header="Chi tiết">
                        <NotificationInfo infos={infos} setInfos={setInfos} />
                    </TabPanel>
                    <TabPanel header="Danh sách gửi"></TabPanel>
                </TabView>
            </div>
        </FormUpdate>
    )
}

export default UpdateNotification
