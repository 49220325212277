import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { InputForm, InputSwitchForm, FormUpdate, DropdownForm } from '@/components'
import { TabPanel, TabView } from 'primereact/tabview'
import ToolChilds from './ToolChild'
import { toolTypes } from '@/constants'
import { addToolApi, detailToolApi, updateToolApi } from '@/api'
import { useGetApi } from '@/hooks'

const ToolInfo = (props) => {
    const { infos, setInfos } = props

    return (
        <div className="card bg-color">
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên Quyền (*)"
                        required
                    />
                    <InputForm
                        id="icon"
                        value={infos.icon}
                        onChange={(e) => setInfos({ ...infos, icon: e.target.value })}
                        label="Icon"
                    />
                    <InputSwitchForm
                        checked={Boolean(infos.status)}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                    />
                </div>
                <div className="col-12 lg:col-6">
                    <DropdownForm
                        id="type"
                        value={infos.type}
                        onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                        options={toolTypes}
                        label="Loại  quyền (*)"
                    />
                    <InputForm
                        id="sort"
                        value={infos.sort}
                        onChange={(e) => setInfos({ ...infos, sort: e.target.value })}
                        type="number"
                        label="Vị trí"
                    />
                </div>
            </div>
        </div>
    )
}

const UpdateTool = () => {
    const { id } = useParams()
    const tools = useGetApi(detailToolApi, { id }, {})
    const [data, setData] = useState([])
    const [infos, setInfos] = useState({
        name: '',
        icon: '',
        sort: '',
        status: true,
    })

    useEffect(() => {
        if (id && tools && tools[0]) {
            const newInfos = tools.find((d) => d.id === Number(id))
            setInfos({ ...newInfos })
            if (newInfos && newInfos.list_tool_child[0]) {
                setData([...newInfos.list_tool_child])
            }
        }
    }, [tools])

    const handleData = () => {}

    return (
        <FormUpdate
            checkId={Number(id)}
            title="quyền hiện có"
            handleData={handleData}
            actions={{ add: addToolApi, update: updateToolApi }}
            route="/tool"
            refreshObjects={[setInfos]}
        >
            <div className="card">
                <TabView>
                    <TabPanel header="Chi tiết">
                        <ToolInfo infos={infos} setInfos={setInfos} />
                    </TabPanel>
                    <TabPanel header="Các tool">
                        <ToolChilds data={data} />
                    </TabPanel>
                </TabView>
            </div>
        </FormUpdate>
    )
}

export default UpdateTool
