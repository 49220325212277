import {
    ActionBody,
    Body,
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    NumberFormat,
    TimeBody,
    useGetParams
} from '@/components'
import { listToast, statusCustomerList } from '@/constants'
import { useListMonth } from "@/modules/accountant/electric_water_meter/utils"
import { setToast } from '@/redux/features'
import { getArrId } from '@/utils'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteCustomerApi, detailNoti, exportBillApi, updateStatusBillApi } from '../api'
import { useCountCustomer, useListCustomer } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '', cycleNames: [] })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    const cycleNames = useListMonth()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.bill_code}
                onChange={(e) => setFilter({ ...filter, bill_code: e.target.value })}
                placeholder="Tìm kiếm theo mã bảng kê"
            />
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder={"Chọn khoảng thời gian tạo bảng kê"}
                selectionMode="range"
            />
            <Dropdownz
                options={statusCustomerList}
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tình trạng"
            />
            <Dropdownz
                options={cycleNames}
                optionLabel="cycle_name"
                optionValue="cycle_name"
                value={filter.cycle_name}
                onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                placeholder="Kỳ bảng kê"
            />
        </GridForm>
    )
}
export default function CustomerList() {
    const navigate = useNavigate()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCustomer({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCustomer({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()
    const apartments = useSelector((state) => state.apartments)
    const items = [
        {
            label: 'Chuyển trạng thái chờ xác nhận',
            command: () => confirm(-3),
        },
        {
            label: 'Chuyển trạng thái chờ gửi',
            command: () => confirm(-2),
        },
        {
            label: 'Chuyển trạng thái chờ thanh toán',
            command: () => confirm(1),
        },
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'bảng kê khách hàng',
            actionType: 'xóa',
            action: async (listId) => await deleteCustomerApi({ bill_ids: getArrId(listId) }),
        },
    ]
    const handleRender = async (id) => {
        const response = await detailNoti({ id })
        if (response.data.status) {
            navigate(`/list_detail/detail/${id}`)
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
        }
    }

    async function accept(status) {
        const response = await updateStatusBillApi({ bill_ids: selectedProducts.map(s => s.id), status })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Chuyển trạng thái thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (status) => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn bảng kê trước khi đổi trạng thái!' }))
            return
        }
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi trạng thái?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => accept(status),
        })
    }

    const moreOptions = [{
        label: 'Lập phiếu thu',
        icon: 'pi pi-plus',
        command: () => navigate('/receipt/lap_phieu_thu')
    }]

    return (
        <div className="card">
            <HeaderListForm title="Chi tiết bảng kê - khách hàng" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bảng kê - khách hàng"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                headerInfo={{ items, moreOptions, exportApi: exportBillApi }}
                route="/bill"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                basePermissions={["export"]}
            >
                <Columnz field="bill_code" header="Mã BK" style={{ whiteSpace: "nowrap" }}></Columnz>
                <Columnz field="customer_name" header="Tên khách hàng" />
                <Columnz field="cycle_name" header="Kì BK" />
                <Columnz
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'name' })}
                    header="Căn hộ"
                />
                <Columnz body={e => TimeBody(e.deadline, 'date')} header="Hạn TT" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary + e.discount + e.promotion })} header="Nợ Phát Sinh" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.discount })} header="Giảm trừ" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.promotion })} header="Ưu đãi" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary })} header="Thành tiền" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.paid })} header="Đã thanh toán" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary - e.paid })} header="Còn nợ" />
                <Columnz
                    className='text-right'
                    body={e => TimeBody(e.created_at)} header="Ngày lập" />
                <Columnz header="Ngày duyệt" body={(e) => TimeBody(e.confirm_date, 'date')} />
                <Columnz header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' body={(e) => Body({ data: statusCustomerList, value: e.status })} />
                <Columnz
                    header="Thao tác"
                    body={(rowData) => {
                        return (
                            <div className='flex gap-2 justify-content-start'>
                                <div>
                                    <Button
                                        type="button"
                                        icon="pi pi-eye" rounded outlined
                                        onClick={e => navigate(`/services_list?apartment_id=${rowData.bdc_apartment_id}&cycle_names=${Number(rowData.cycle_name)}`)}

                                    />
                                </div>
                                <ActionBody
                                    id={rowData.id}
                                    route="/bill"
                                    deleteAction={!rowData.paid ? deleteCustomerApi : undefined}
                                    handleDelete={(id) => {
                                        return { bill_ids: [id] }
                                    }}
                                    params={params}
                                    setParams={setParams}
                                    basePermissions={rowData.paid ? [] : ["delete"]}
                                />
                            </div>
                        )
                    }}
                />
            </DataTablez>
        </div>
    )
}

