import { HeaderListForm, InputForm, InputNumberFormV2, InputTextareaForm, UploadImg } from '@/components'
import { Carousel } from 'primereact/carousel'
import { useEffect, useState } from 'react'
import { useGetApi } from '@/hooks'
import { listBuildingInfoApi } from '../api'
import { checkJson } from '@/utils'

const BuildingInfo = () => {
    const data = useGetApi(listBuildingInfoApi, {}, {})
    const [logo, setLogo] = useState(null)
    const [images, setImages] = useState([])

    useEffect(() => {
        if (data.logo) setLogo(data.logo)
        if (data.images) {
            const newData = checkJson(data.images) || data.images
            if (typeof newData === "object") setImages(newData.map(n => ({ src: n })))
        }
    }, [JSON.stringify(data)])


    const itemTemplate = (item) => {
        return (
            <img src={item.src} alt={item.alt} style={{ width: '100%' }} />
        )
    }
    return (
        <div className="grid grid-form justify-content-center">
            <div className="col-12 lg:col-7">
                <div className='card'>
                    <HeaderListForm title="Thông tin dự án" />
                    <InputForm
                        id="name"
                        value={data.name}
                        label="Tên dự án"
                        disabled
                    />
                    <InputForm
                        id="building_code"
                        value={data.building_code}
                        label="Mã dự án"
                        disabled
                    />
                    <InputNumberFormV2
                        id="area"
                        value={data.area}
                        label="Diện tích m2"
                        disabled
                    />
                    <InputForm
                        id="total_apart"
                        value={data.total_apart}
                        label="Tổng số căn hộ"
                        disabled
                    />
                    <InputForm
                        id="email"
                        value={data.email}
                        label="Email dự án"
                        disabled
                    />
                    <InputForm
                        id="phone"
                        value={data.phone}
                        label="Số điện thoại"
                        disabled
                    />
                    <InputForm
                        id="address"
                        value={data.address}
                        label="Địa chỉ"
                        disabled
                    />
                    <InputTextareaForm
                        id="description"
                        value={data.description}
                        label="Mô tả"
                        disabled
                    />
                </div>
            </div>
            <div className="col-12 lg:col-5">
                <div className='card'>
                    <HeaderListForm title="Logo dự án" />
                    <UploadImg image={logo} view />
                    <br />
                </div>
                <div className={'card'}>
                    <HeaderListForm title="Hình ảnh dự án" />
                    <Carousel value={images} itemTemplate={itemTemplate} circular autoplayInterval={3000} />
                    {/* <div className="card mx-2">
                            <HeaderListForm title="Mã QR" />
                            <div className="flex justify-content-center">
                                <img
                                    src="https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=hi&choe=UTF-8"
                                    style={{ width: '320px', height: '320px' }}
                                    alt="mã qr"
                                />
                            </div>
                        </div> */}
                </div>
            </div>
        </div>
    )
}
export default BuildingInfo
