import { HeaderListForm } from '@/components'
import { Card } from '@/uiCore'
import { Chart } from 'primereact/chart'
import { useState } from 'react'
import { useRevenue7LastDay, useRevenueSyntheticLogCarParking } from '../utils'
import { Revenue, RevenueGirdChart } from '@/components/Chart'

export const RevenueVehicle = ({ params, revenueSynthetic, revenue7LastDay }) => {
    const [chartData1, setChartData1] = useState({})
    const [chartOptions1, setChartOptions1] = useState({})

    return (
        <>
            <div className="flex flex-column">
                <div className="px-3">
                    <HeaderListForm title="DOANH THU" />
                    <Revenue data={revenueSynthetic && revenueSynthetic.dataRevenue} revenue={revenueSynthetic && revenueSynthetic.revenue} />
                    <div className="grid">
                        <div className="col-12 lg:col-6 p-2">
                            <RevenueGirdChart
                                title="Doanh thu 7 ngày gần nhất"
                                color={['#A5D7FB']}
                                label={['Doanh thu']}
                                dataChart={revenue7LastDay && revenue7LastDay}
                                keyData={['revenue']}
                                labelChart="date"
                                maxYValue={''}
                                chartType={'bar'}
                                stepSize={''}
                                yAxisText={'Xe'}
                                aspectRatio={'0.75'}
                                pCard="p-card"
                            />
                        </div>
                        <div className="col-12 lg:col-6 p-2">
                            <Card className="flex flex-column" style={{ width: '100%', aspectRatio: '1.3' }}>
                                <div className="flex justify-content-center align-item-center">
                                    <Chart
                                        type="doughnut"
                                        data={chartData1}
                                        options={chartOptions1}
                                        className="w-full md:w-30rem"
                                    />
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
