import {Body, Columnz, DataTablez, HeaderListForm, TimeBody, useGetParams} from '@/components'
import {useState} from 'react'
import {exportForControl} from '../../api'
import {useListCarParkingForControl} from '../../utils'
import {ParkingDialogNo} from '../ParkingDialog'
import {vehicle_type} from "@/constants";

export const ParkingComparision = ({render, params: paramz}) => {
    const [show, setShow] = useState(false)
    const [showImg, setShowImg] = useState(false)
    const [propsdata, setPropsData] = useState({})
    const handleImage = (e, image) => {
        setShowImg(true)
        setPropsData({
            plate_number: e.plate_number,
            transfer_time: e.transfer_time,
            lane_action: e.lane_action,
            vehicle_type: e.vehicle_type,
            image: image,
        })
    }
    const handleForm = () => {
        setShowImg(false)
        setShow(true)
    }
    const items = []
    const initParam = useGetParams()
    const [params, setParams] = useState({...initParam, render})
    const [data, refresh] = useListCarParkingForControl({
        status: undefined, ...paramz, ...params,
        render,
        first: undefined
    })

    return (
        <div className={"px-2"}>
            <HeaderListForm title="ĐỐI SOÁT"/>
            <DataTablez
                value={data.data}
                title="thông tin đối soát"
                totalRecords={data.count}
                params={params}
                setParams={setParams}
                route="/parking_supervision"
                headerInfo={{exportApi: exportForControl}}
                basePermissions={['export']}
                hideParams
                dataKey="id_pm"
            >
                <Columnz
                    header="Trạng thái"
                    body={(e) => (e.lane_action === 'IN' ? <>Xe vào</> : <>Xe ra</>)}
                />
                <Columnz header="ID1" field="id_ai"/>
                <Columnz
                    header="Thời gian 1"
                    field="time_ai"
                    headerStyle={{height: '75px'}}
                    body={(e) => TimeBody(e.time_ai)}
                />
                <Columnz
                    header="Hình ảnh 1"
                    body={(e) => (
                        <>
                            <div onClick={() => handleImage}>
                                <img
                                    src={e.image_ai}
                                    style={{width: '100px', height: '65px'}}
                                    onClick={() => handleImage(e, e.image_ai)}
                                />
                            </div>
                        </>
                    )}
                />
                <Columnz header="ID2" field="id_pm"/>
                <Columnz
                    header="Thời gian 2"
                    field="time_pm"
                    headerStyle={{height: '75px'}}
                    body={(e) => TimeBody(e.time_pm)}
                />
                <Columnz
                    header="Hình ảnh 2"
                    body={(e) => (
                        <>
                            <div onClick={() => handleImage}>
                                <img
                                    src={e.image_pm}
                                    style={{width: '100px', height: '65px', cursor: 'pointer'}}
                                    onClick={() => handleImage(e, e.image_pm)}
                                />
                            </div>
                        </>
                    )}
                />
                <Columnz header="Loại xe" body={e => Body({data: vehicle_type, value: e.vehicle_type})}/>
                <Columnz header="Biển kiểm soát gốc" field="plate_number"/>
                <Columnz header="Biển đối chiếu" field="plate_compare_number"/>
            </DataTablez>
            <ParkingDialogNo
                show={show}
                showImg={showImg}
                setShow={setShow}
                setShowImg={setShowImg}
                propsdata={propsdata}
                handleForm={handleForm}
            />
        </div>
    )
}
