import {
    ActionBody,
    Body,
    Calendarz,
    Columnz,
    DataTablez,
    Dialogz,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    MultiRadioButton,
    NumberFormat,
    TimeBody,
    useGetParams
} from '@/components'
import { listToast, statusCustomerList } from '@/constants'
import { deleteCustomerApi, detailNoti, updateStatusBillApi } from "@/modules/debts/customer_list/api"
import { setToast } from "@/redux/features"
import { Button } from '@/uiCore'
import { getArrId } from '@/utils'
import { confirmDialog } from "primereact/confirmdialog"
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useListMonth } from '../../electric_water_meter/utils'
import { exportNotiApi, sendNotiApi } from '../api'
import { useCountNoti, useListNoti } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    const cycleNames = useListMonth()
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.bill_code}
                onChange={(e) => setFilter({ ...filter, bill_code: e.target.value })}
                placeholder="Tìm kiếm theo mã bảng kê"
            />
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder={"Chọn khoảng thời gian tạo bảng kê"}
                selectionMode="range"
            />
            <Dropdownz
                options={cycleNames}
                optionLabel="cycle_name"
                optionValue="cycle_name"
                value={filter.cycle_name}
                onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                placeholder="Kỳ bảng kê"
            />
        </GridForm>
    )
}


const Notis = () => {
    const navigate = useNavigate()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListNoti({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountNoti({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()
    const apartments = useSelector((state) => state.apartments)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false);
    const [ingredient, setIngredient] = useState('');

    const items = [
        {
            label: 'Gửi thông báo',
            command: () => {
                if (selectedProducts && selectedProducts[0]) {
                    setVisible(true)
                } else {
                    dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn bảng kê trước khi đổi trạng thái!' }))
                }
            },
        },
        {
            label: 'Chuyển trạng thái chờ thanh toán',
            command: () => confirm(),
        },
    ]

    async function accept(status) {
        const response = await updateStatusBillApi({ bill_ids: selectedProducts.map(s => s.id), status })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Chuyển trạng thái thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (status = 1) => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn bảng kê trước khi đổi trạng thái!' }))
            return
        }
        confirmDialog({
            message: 'Bạn có muốn tiếp tục chuyển trạng thái chờ thanh toán?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => accept(status),
        })
    }

    const moreOptions = [{
        label: 'Lập phiếu thu',
        icon: 'pi pi-plus',
        command: () => navigate('/receipt/lap_phieu_thu')
    },


    ]

    const handleData = async () => {
        setLoading(true);
        if (ingredient) {
            const response = await sendNotiApi({ bill_ids: getArrId(selectedProducts), type: ingredient })
            if (response.data.status) {
                setVisible(false)
                setParams(pre => ({ ...pre, render: !pre.render }))
                dispatch(setToast({ ...listToast[0], detail: 'Gửi thông báo thành công!' }))
            } else {
                dispatch(setToast({ ...listToast[1], detail: 'Gửi thông báo thất bại!' }))
            }
        } else {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn thành viên nhận thông báo!' }))
        }
        setLoading(false);
    }

    const footerContent = (
        <div>
            <Button label={'Gửi'} icon="pi pi-check" onClick={handleData} loading={loading} loadingIcon="pi pi-spinner" />
        </div>
    );
    const handlePrint = async (id) => {
        const response = await detailNoti({ id })
        if (response.data.status) {
            window.open(`/print/detail/${id}`, '_blank')
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
        }
    }
    const BodyPrint = (e) => {
        return (
            <div className="flex gap-2 align-items-center justify-content-center">
                <>
                    <Button type="button" icon="pi pi-print" onClick={event => handlePrint(e.id)} />
                </>
            </div>
        );
    }
    return (
        <div className="card">
            <HeaderListForm title="Danh sách gửi thông báo" />
            <Header setParams={setParams} />
            {visible && <Dialogz modal={true} title="Gửi thông báo" visible={visible} setVisible={setVisible} footer={footerContent}>
                <div className='card mt-2'>
                    <label className='font-medium'>Thành viên nhận thông báo</label>
                    <hr />
                    <div className=" flex justify-content-center ">
                        <MultiRadioButton
                            id="ingredient"
                            value={ingredient}
                            onChange={(e) => setIngredient(e)}
                            options={[{ id: 1, name: 'Chủ hộ' }, { id: 2, name: 'Tất cả thành viên của căn hộ' }]}
                            className="w-full gap-8 "
                        />
                    </div>
                </div>
            </Dialogz >}
            <DataTablez
                value={data}
                title="danh sách gửi thông báo"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                headerInfo={{ items, moreOptions, exportApi: exportNotiApi }}
                route="/noti_list"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                basePermissions={["export"]}

            >
                <Columnz field="bill_code" header="Mã BK"></Columnz>
                <Columnz field="customer_name" header="Tên khách hàng" />
                <Columnz field="cycle_name" header="Kì BK" />
                <Columnz
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'name' })}
                    header="Căn hộ"
                />
                <Columnz body={e => TimeBody(e.deadline, 'date')} header="Hạn TT" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary + e.discount + e.promotion })} header="Nợ Phát Sinh" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.discount })} header="Giảm trừ" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.promotion })} header="Ưu đãi" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary })} header="Thành tiền" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.paid })} header="Đã thanh toán" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary - e.paid })} header="Còn nợ" />
                <Columnz body={e => TimeBody(e.created_at)} header="Ngày lập" />
                <Columnz header="Ngày duyệt" body={(e) => TimeBody(e.confirm_date, 'date')} />
                <Columnz header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className='text-center' body={(e) => Body({ data: statusCustomerList, value: e.status })} />
                <Columnz body={e => BodyPrint(e)} header="In phiếu" />
                <Columnz
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    header="Thao tác"
                    body={(e) => {
                        return (
                            <ActionBody
                                id={e.id}
                                route="/noti_list"
                                deleteAction={deleteCustomerApi}
                                handleDelete={(id) => {
                                    return { bill_ids: [id] }
                                }}
                                params={params}
                                setParams={setParams}
                                basePermissions={e.paid ? ["detail"] : ["delete", "detail"]}
                                st
                            />
                        )
                    }}
                />
            </DataTablez>
        </div >
    )
}

export default Notis
