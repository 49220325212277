import { DropdownForm, FormUpdateDialog, InputForm, InputNumberForm, InputSwitchForm, UploadFiles, UploadImg } from '@/components'
import { time_unit } from '@/constants'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { addProducApi, updateProducApi } from '../api'
import { useDetailProduct, useListProductGroup } from '../utils'

function UpdateAsset(props) {
    const { setVisible, setParams, visible } = props
    const detail = useDetailProduct(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState({ status: true })
    const product_group = useListProductGroup()
    const [files, setFiles] = useState([])
    const [image, setImage] = useState([])
    useEffect(() => {
        let a = {}
        a.image = image

    }, [image])

    useEffect(() => {
        if (detail.id) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(detail),
                status: detail.status === 0 ? false : true
            })
            if (detail.attack) {
                const attack = checkJson(detail.attack) || detail.attack
                if (Array.isArray(attack.file)) {
                    setFiles(attack.file)
                }
                if (attack.image) {
                    setImage(attack.image)
                }
            }

        }
    }, [detail])
    const handleData = () => {
        if (infos.name.length > 50) return "Tên sản phẩm không quá 50 ký tự"
        let info = {
            ...infos,
            status: infos.status ? 1 : 0,
        }
        if (!infos.product_group_id) return "Vui lòng chọn nhóm sản phẩm"
        if (infos.note?.length >= 100) return "Mô tả không nhập quá 100 ký tự"
        if (!infos.time || !infos.time_unit) return "Vui lòng nhập đủ thông tin chu kỳ bảo trì, bảo dưỡng!"
        if (typeof visible === 'number')
            info = { ...removePropObject(info, detail), id: detail.id }
        if (image) info.image = image
        else info.image = []
        if (files && files[0]) {
            info.file = files
        } else info.file = []
        return info
    }
    useEffect(() => {
        if (!detail.id) {
            if (infos.name) {
                setInfos({
                    ...infos,
                    code: code
                })
            }
        }
    }, [infos.product_group_id]);

    const generateRandomString = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = 0; i < 3; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    const generateFormattedDate = () => {
        return moment().format('YYYYMMDD_HHmmss');
    }

    const renderCode = () => {
        const randomString = generateRandomString();
        const formattedDate = generateFormattedDate();
        return `${randomString}_${formattedDate}`;
    }
    const code = renderCode()

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + ' danh mục sản phẩm'}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            handleData={handleData}
            actions={{ add: addProducApi, update: updateProducApi }}

        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-5">
                        <UploadImg image={image} setImage={setImage} title="Ảnh danh mục sản phẩm " />
                    </div>
                    <div className="col-12 lg:col-7 ">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-7">
                                <InputForm
                                    label="Tên danh mục sản phẩm (*)"
                                    value={infos.name}
                                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="col-12 lg:col-5">
                                <InputForm
                                    label="Mã danh mục sản phẩm (*)"
                                    value={infos.code}
                                    onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                    required
                                    disabled
                                />
                            </div>
                        </div>

                        <DropdownForm
                            options={product_group}
                            optionValue="id"
                            onChange={(e) => setInfos({ ...infos, product_group_id: e.target.value })}
                            value={infos.product_group_id}
                            label="Tên nhóm (*)" />
                        <InputForm
                            label="Mô tả"
                            value={infos.note}
                            onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                        />
                        <div className="font-medium text-xl mb-3 px-2">Chu kỳ bảo trì, bảo dưỡng</div>
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6">
                                <InputNumberForm
                                    label="Tần suất"
                                    value={infos.time}
                                    onChange={(e) => setInfos({ ...infos, time: e.target.value })}
                                />
                            </div>
                            <div className="col-12 lg:col-6">
                                <DropdownForm
                                    options={time_unit}
                                    optionValue="id"
                                    onChange={(e) => setInfos({ ...infos, time_unit: e.target.value })}
                                    value={infos.time_unit}
                                    label="Đơn vị thời gian" />
                            </div>
                        </div>


                        <InputSwitchForm
                            label="Trạng thái danh mục sản phẩm"
                            id="status"
                            value={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                            checked={infos.status}
                        />
                        <UploadFiles
                            label="Tệp đính kèm"
                            files={files}

                            setFiles={setFiles}
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateAsset
