import { Columnz, DataTablez, Dropdownz, GridForm, Inputz, useGetParams } from '@/components'
import { useState } from 'react'
import UpdateFloor from './UpdateFloor'
import { useSelector } from 'react-redux'
import { countFloorV2Api, deleteFloorApi, listFloorV2Api } from '@/api'
import { useGetApi } from '@/hooks'

const Header = ({ setParams, buildings }) => {
    const [filter, setFilter] = useState({ name: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.name}
                placeholder="Tìm kiếm theo tên tầng"
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
            />
            <Dropdownz
                value={filter.id_place}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, id_place: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
        </GridForm>
    )
}

const Floor = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listFloorV2Api, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countFloorV2Api, { ...params, first: undefined }, 0)
    const [visible, setVisible] = useState(false)
    const buildings = useSelector((state) => state.buildings)

    return (
        <>
            {visible && (
                <UpdateFloor visible={visible} buildings={buildings} setVisible={setVisible} setParams={setParams} />
            )}
            <Header setParams={setParams} buildings={buildings} />
            <DataTablez
                title="Tầng"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_management"
                headerInfo
                actionsInfo={{ deleteAction: deleteFloorApi }}
                basePermissions={["insert", "detail", "delete"]}
                setVisibledDialog={setVisible}
            >
                <Columnz field="floor_name" header="Tên tầng" />
                <Columnz field="building_place_name" header="Tên tòa" />
                <Columnz field="total_apart" header="Tổng số căn hộ" />
            </DataTablez>
        </>
    )
}

export default Floor
