import { TabPanel, TabView } from 'primereact/tabview'
import ListUtilitiesApartment from './UtilitiesApartment'

const UtilitiesManage = () => {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Danh sách tiện ích căn hộ">
                    <ListUtilitiesApartment />
                </TabPanel>
                {/* <TabPanel header="Danh sách tiện ích đối tác">
                    <GroupApartment />
                </TabPanel> */}
            </TabView>
        </div>
    )
}

export default UtilitiesManage
