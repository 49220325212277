import {CalendarForm, DropdownForm, FormUpdateDialog, InputForm, InputNumberForm, InputTextareaForm} from '@/components'
import {databaseDate} from '@/lib'
import {removePropObject, removeUndefinedProps} from '@/utils'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {addApartmentServiceApi, updateApartmentServiceApi} from '../api'
import {useDetailApartmentService, useDetailProgressive} from '../utils'

const UpdateApartmentService = (props) => {
    const {setVisible, setParams, visible, apartment_id} = props
    const apartmentServiceDetail = useDetailApartmentService(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState({status: 1, apartment_id})
    const apartments = useSelector((state) => state.apartments)
    const services = useSelector((state) => state.services)
    const sp = useDetailProgressive(infos.service_id)
    const [priceList, setPriceList] = useState([])
    useEffect(() => {
        if (apartmentServiceDetail?.id) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(apartmentServiceDetail),
                service_id: apartmentServiceDetail.bdc_service_id || undefined,
                apartment_id: apartmentServiceDetail.bdc_apartment_id || undefined,
                start_date: new Date(apartmentServiceDetail.first_time_active) || undefined,
                end_date: new Date(apartmentServiceDetail.finish) || undefined,
                last_time_pay: new Date(apartmentServiceDetail.last_time_pay) || undefined,
                desc: apartmentServiceDetail.description,
            })
        }
    }, [apartmentServiceDetail])
    useEffect(() => {
        if (sp !== null && sp.list_price && sp.list_price.length > 0) {
            setPriceList(sp.list_price)
        } else {
            setPriceList([])
        }
    }, [sp])
    const handleData = () => {
        let info = {...infos}
        if (typeof visible === 'number')
            info = {...removePropObject(info, apartmentServiceDetail), id: apartmentServiceDetail.id}
        if (!infos.apartment_id) return 'Vui lòng chọn căn hộ!'
        if (!infos.service_id) return 'Vui lòng chọn dịch vụ!'
        if (!info.start_date || info.start_date === 'Invalid date') return 'Vui lòng chọn ngày bắt đàu tính phí!'
        if (info.end_date && typeof info.end_date === 'object' && isNaN(info.end_date.getTime())) {
            info.end_date = undefined
        }
        if (new Date(infos.start_date) >= new Date(infos.end_date))
            return 'Ngày bắt đầu không được lớn hơn hoặc bằng ngày kết thúc!'
        info.start_date = info.start_date ? databaseDate(info.start_date, false, 'date') : undefined
        info.end_date = info.end_date ? databaseDate(info.end_date, false, 'date') : undefined
        info.last_time_pay = info.last_time_pay ? databaseDate(info.last_time_pay, false, 'date') : undefined
        return info
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'dịch vụ căn hộ'}
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            route="/service_apartment"
            actions={{add: addApartmentServiceApi, update: updateApartmentServiceApi}}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="apartment_id"
                            value={infos.apartment_id}
                            label="Căn hộ (*)"
                            options={apartments}
                            onChange={(e) => setInfos({...infos, apartment_id: e.target.value})}
                            disabled={typeof visible === "number"}
                        />
                        <DropdownForm
                            id="service_id"
                            value={infos.service_id}
                            label="Dịch vụ (*)"
                            options={services}
                            onChange={(e) => setInfos({...infos, service_id: e.target.value})}
                            disabled={typeof visible === "number"}
                        />
                        {priceList && sp?.bdc_price_type_id === 1 && priceList ? (
                            <InputForm
                                id="servicePrice"
                                value={priceList && priceList[0]?.price}
                                label="Đơn giá"
                                disabled={true}
                            />
                        ) : (priceList && [2, 3, 4].includes(sp?.bdc_price_type_id)) ? (
                            <div>
                                {priceList.map((item, index) => (
                                    <div className="grid grid-form" key={index}>
                                        <div className="col-3 lg:col-3">
                                            <InputForm value={item.from} label="Từ" disabled={true}/>
                                        </div>
                                        <div className="col-3 lg:col-3">
                                            <InputForm value={item.to} label="Đến" disabled={true}/>
                                        </div>
                                        <div className="col-3 lg:col-3">
                                            <InputForm
                                                id="servicePrice"
                                                value={item.price}
                                                label="Đơn giá"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (priceList && sp?.bdc_price_type_id === 5) ? priceList.map((price, index) => {
                            return (
                                <div key={index} className="grid">
                                    <div className="col-3">
                                        <InputForm value={`Bậc ${index + 1}`} disabled label="Loại"/>
                                    </div>
                                    <div className="col-3">
                                        <InputNumberForm value={price.price} disabled label="Giá"/>
                                    </div>
                                    <div className="col-3">
                                        <InputForm value={'VNĐ'} label="Đơn vị tính" disabled/>
                                    </div>
                                    <div className="col-3">
                                        <InputForm value={price.description || " "} label="Description" disabled/>
                                    </div>
                                </div>)
                        }) : <></>}

                        <InputForm
                            value="VNĐ"
                            onChange={(e) => setInfos({...infos, condition: e.target.value})}
                            label="Đơn vị tính (*)"
                            disabled={visible}
                        />
                        <InputTextareaForm
                            id="desc"
                            value={infos.desc}
                            onChange={(e) => setInfos({...infos, desc: e.target.value})}
                            label="Mô tả"
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <CalendarForm
                            dateFormat="dd/mm/yy"
                            id="start_date"
                            value={infos.start_date}
                            onChange={(e) => setInfos({...infos, start_date: e.target.value})}
                            label="Ngày áp dụng tính phí (*)"
                            required
                        />
                        <CalendarForm
                            id="end_date"
                            dateFormat="dd/mm/yy"
                            value={infos.end_date}
                            onChange={(e) => setInfos({...infos, end_date: e.target.value})}
                            label="Ngày kết thúc tính phí"
                        />
                        {typeof visible === 'number'
                            ?
                            <CalendarForm
                                id="last_time_pay"
                                dateFormat="dd/mm/yy"
                                value={infos.last_time_pay}
                                onChange={(e) => setInfos({...infos, last_time_pay: e.target.value})}
                                label="Ngày tính phí tiếp theo"
                                required
                            />
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateApartmentService
