import {
    countUserApi,
    deleteUserApi,
    listExchangeV2Api,
    listGroupV2Api,
    listUserApi,
    resetPassUserApi,
    updateUserApi
} from '@/api'
import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { listToast, status } from '@/constants'
import { useGetApi } from '@/hooks'
import { setToast } from '@/redux/features'
import { removeUser } from '@/redux/features/users'
import { Dialog } from '@/uiCore'
import { confirmDialog } from 'primereact/confirmdialog'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

const Header = ({ setParams, exchanges, groups }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Inputz
                value={filter.username}
                placeholder="Tìm kiếm theo Email,SĐT"
                onChange={(e) => setFilter({ ...filter, username: e.target.value })}
            />
            <Dropdownz
                value={filter.department_id}
                options={exchanges}
                onChange={(e) => setFilter({ ...filter, department_id: e.target.value, group_id: undefined })}
                placeholder="Phòng ban"
            />
            <Dropdownz
                value={filter.group_id}
                options={filter.department_id ? groups.filter(g => g.bdc_department_id === filter.department_id) : groups}
                onChange={(e) => setFilter({ ...filter, group_id: e.target.value })}
                placeholder="Bộ phận"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Users = ({ group_id }) => {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const data = useGetApi(listUserApi, { group_id, ...params, first: undefined }, [])
    const totalRecords = useGetApi(countUserApi, { group_id, ...params, first: undefined }, 0)
    const exchanges = useGetApi(listExchangeV2Api, { ...params, first: undefined }, [])
    const groups = useGetApi(listGroupV2Api, { ...params, first: undefined }, [])

    async function accept(id) {
        const response = await resetPassUserApi({ id })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Đổi mật khẩu thành công!' }))
            setVisible(response.data.mess)
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi mật khẩu của người dùng này?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => accept(id),
        })
    }
    const moreActions = [{ icon: 'pi pi-sync', command: (id) => confirm(id) }]

    const handleAfterCallApi = (data) => {
        if (data) dispatch(removeUser(data))
    }

    return (
        <div className={group_id ? '' : 'card'}>
            <Dialog
                header="Mật khẩu mới"
                visible={Boolean(visible)}
                style={{ width: '500px' }}
                onHide={() => setVisible(false)}
            >
                <p className="mt-6 text-center text-xl font-bold mb-4">{visible}</p>
            </Dialog>
            {!group_id && (
                <>
                    <HeaderListForm title="Danh sách người dùng" />
                    <Header groups={groups} exchanges={exchanges} setParams={setParams} />
                </>
            )}
            <DataTablez
                title="người dùng"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/user"
                headerInfo={group_id ? false : true}
                actionsInfo={{ deleteAction: deleteUserApi, moreActions }}
                basePermissions={group_id ? ["detail", "delete"] : ["insert", "detail", "delete"]}
                handleAfterCallApi={handleAfterCallApi}
            >
                <Columnz field="code" header="Mã nhân viên" />
                <Columnz field="full_name" header="Tên nhân viên" />
                <Columnz field="phone" header="SĐT" />
                <Columnz field="email" header="Email" />
                <Columnz body={(e) => Body({ data: exchanges, value: e.department_id })} header="Phòng ban" />
                <Columnz body={(e) => Body({ data: groups, value: e.group_id })} header="Bộ phận" />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/company', updateAction: updateUserApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Users
