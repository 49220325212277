import { Body, Columnz, DataTablez, GridForm, HeaderListForm, Inputz, useGetParams } from '@/components'
import { statusRepair } from '@/constants'
import { getArrId } from '@/utils'
import { useState } from 'react'
import { deleteRegisterRepairApi } from '../api'
import { useCountRegisterRepair, useListRegisterRepair } from '../utils'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const RegisterRepairs = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListRegisterRepair({ status: undefined, ...params, first: undefined })
    const [selectedProducts, setSelectedProducts] = useState([])
    const totalRecords = useCountRegisterRepair({ status: undefined, ...params, first: undefined }) || 0
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'căn hộ',
            actionType: 'xóa',
            action: async (list_id) => await deleteRegisterRepairApi({ list_id: getArrId(list_id) }),
        },
    ]

    return (
        <div className="card">
            <HeaderListForm title="Danh sách đăng kí sửa chữa" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="đăng kí sửa chữa"
                totalRecords={totalRecords}
                params={params}
                headerInfo={{ items }}
                setParams={setParams}
                route="/register_repair"
                actionsInfo={{
                    deleteAction: deleteRegisterRepairApi,
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="id" header="ID" />
                <Columnz field="title" header="Tiêu đề" />
                <Columnz field="unit_name" header="Đơn vị thi công" />
                <Columnz field="apartment" header="Căn hộ" />
                <Columnz field="full_name" header="Người đăng ký" />
                <Columnz field="email" header="Email" />
                <Columnz field="phone" header="Số điện thoại" />
                <Columnz field="created_at" header="Thời gian" />
                <Columnz body={(e) => Body({ data: statusRepair, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}

export default RegisterRepairs
