import { Body, Calendarz, Columnz, DataTablez, Dropdownz, GridForm, TimeBody, useGetParams } from '@/components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useCountRatedService, useListCateEvaluate, useListDepartment, useListRatedService } from '../utils'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const departments = useListDepartment()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.department_id}
                options={departments}
                onChange={(e) => setFilter({ ...filter, department_id: e.target.value })}
                placeholder="Chọn bộ phận"
            />
            <Calendarz
                value={filter.dates}
                className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                showIcon
            />
        </GridForm>
    )
}

const RateOverview = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListRatedService({ status: undefined, ...params, first: undefined })
    const cateEvaluate = useListCateEvaluate({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountRatedService({ status: undefined, ...params, first: undefined }) || 0
    const resident = useSelector((state) => state.residents)
    const BodyV1 = (rowData) => {
        const styles = `
        .star-icon-container {
            display: inline-block;
            position: relative;
            margin-right: 2px;
        }
        .hover-info {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #fff;
            border: 1px solid #ccc;
            padding: 5px;
            border-radius: 3px;
        }
        
        .star-icon-container:hover .hover-info {
            display: block;
        }
    `;
        const length = rowData ? rowData.length : 0;
        const numberStart = rowData && rowData[0] ? rowData[0].number_start : 0;
        const iconArray = Array.from({ length: numberStart }, (_, index) => (
            <>
                <div key={index} className="star-icon-container">
                    <i className="pi pi-star-fill text-yellow-500" style={{ fontSize: '1rem' }}></i>
                </div>
            </>
        ));
        const totalVotes = rowData.reduce((total, item) => total + item.number_start, 0);
        const numberStart2 = length > 0 ? Math.floor(totalVotes / length) : 0;
        const iconArrayV2 = Array.from({ length: numberStart2 }, (_, index) => (
            <>
                <div key={index} className="star-icon-container" >
                    <i className="pi pi-star-fill text-yellow-500" style={{ fontSize: '1rem' }}></i>
                </div>
            </>
        ));
        return (
            <>
                {
                    length === 1 ? (
                        <>
                            {
                                rowData && rowData[0] && rowData[0].number_start ? (
                                    <>
                                        <style>{styles}</style>
                                        {iconArray}
                                    </>
                                ) : null
                            }
                        </>
                    ) : (
                        <>
                            <style>{styles}</style>
                            <div>
                                {iconArrayV2}
                            </div>
                        </>
                    )
                }
            </>
        )
    }
    const ReceptionDirectory = (rowData) => {
        const rd = rowData.reception_directory
        const typeRequest = [
            { id: 1, name: 'Thêm phương tiện' },
            { id: 2, name: 'Hủy phương tiện' },
            { id: 3, name: 'Cấp lại thẻ xe (có thông tin chi tiết)' },
            { id: 4, name: 'Chuyển đồ' },
            { id: 5, name: 'Sửa chữa' },
            { id: 6, name: 'Tiện ích' },
            { id: 7, name: 'Cấp lại thẻ xe (không có thông tin chi tiết)' },
            { id: 9, name: 'Thêm nhân khẩu' },
            { id: 10, name: 'Thay đổi thông tin cá nhân' },
            { id: 11, name: 'Đăng ký thẻ' },
            { id: 12, name: 'Yêu cầu hủy thẻ' },
            { id: 13, name: 'Yêu cầu sửa chữa' },
            { id: 14, name: 'Yêu cầu bảo trì, bảo dưỡng' },
            { id: 15, name: 'Gia hạn phương tiện' },
        ]
        let reception_directory = ''
        if (typeof rd === 'string') {
            reception_directory = rd
        }
        if (typeof rd === 'number') {
            reception_directory = typeRequest.find(t => t.id = rd)?.namename
        }
        return (
            <>
                {reception_directory}
            </>
        )
    }

    return (
        <div className="card">
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Đánh giá"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/rated_service"
            >
                <Columnz field="created_at" header="Thời gian tạo" body={e => TimeBody(e.created_at)} />
                <Columnz
                    header="Dịch vụ sử dụng"
                    body={(e) => Body({ data: cateEvaluate, value: e.cate_evaluate_id, label: 'name' })}
                />
                <Columnz
                    header="Danh mục tiếp nhận"
                    body={(rowData) => ReceptionDirectory(rowData)}
                />
                <Columnz field="feedback_note" header="Nội dung ý kiến" />
                <Columnz body={(rowData) => BodyV1(rowData.data, 5)} header="Xếp loại đánh giá" />
                <Columnz
                    header="SĐT"
                    body={(e) => Body({ data: resident, value: e.user_id, key: 'user_id', label: 'phone_contact' })}
                />
                <Columnz
                    header="Người tạo"
                    body={(e) => Body({ data: resident, value: e.user_id, key: 'user_id', label: 'full_name' })}
                />
            </DataTablez>
        </div>
    )
}

export default RateOverview
