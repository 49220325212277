import { FormUpdateDialog, InputForm, InputNumberForm } from '@/components'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import { Galleria, Image } from '@/uiCore'
import { Button } from 'primereact/button'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteImageApi, updateElectric3PhaApi, updateElectricWaterApi } from '../api'


const DetailElectricWater = (props) => {
    const dispatch = useDispatch()
    const { visible, setVisible, setParams, data, apartments, type, params } = props
    const [rotation, setRotation] = useState(0)
    const [index, setIndex] = useState(() => {
        return data.findIndex((d) => d.id === Number(visible))
    })
    const [infos, setInfos] = useState({
        before_number: '',
        apartment: '',
        service: '',
    })

    useEffect(() => {
        if (data && data[0] && (index || index === 0)) {
            const electricMeter = data[index]
            const apartment = apartments.find((d) => d.id === electricMeter.bdc_apartment_id)
            const typeElectric = type.find((d) => d.id === electricMeter.type)
            if (electricMeter)
                setInfos({
                    id: electricMeter.id,
                    before_number: electricMeter.before_number,
                    apartment: apartment.name,
                    service: electricMeter.service_name || electricMeter.service_name || typeElectric?.name,
                    after_number: electricMeter.after_number,
                    month_create: electricMeter.month_create,
                    images: electricMeter.images,
                    number_1: electricMeter.number_1,
                    number_2: electricMeter.number_2,
                    number_3: electricMeter.number_3,
                })
        }
    }, [data, index])

    const handleRotateImg = () => {
        setRotation(rotation + 90)
    }

    const handlePage = (type) => {
        if (type === 'next') {
            if (index < data.length - 1) setIndex(index + 1)
            else setIndex(0)
        } else if (type === 'prev') {
            if (index > 0) setIndex(index - 1)
            else setIndex(data.length - 1)
        }
    }

    const handleData = () => {
        if (infos.after_number < infos.before_number) return 'Chỉ số cuối không được nhỏ hơn chỉ số đầu!'
        if (params.type === 7 && infos.number_3 < infos.number_1) return 'Chỉ số 3 không được nhỏ hơn chỉ số 1!'
        return params.type === 7 ? { number_3: infos.number_3, id: infos.id } : { after_number: infos.after_number, id: infos.id }
    }

    const handLeDeleteImage = async () => {
        const response = await deleteImageApi({ id: infos.id })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Xóa ảnh thành công' }))
            if (setParams) {
                setParams((prev) => {
                    return { ...prev, render: !prev.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: 'Có lỗi xảy ra' }))
    }

    const Galleriaz = (prop) => {
        const { images } = prop

        const defaulImg = [{ itemImageSrc: '../../../../../public/assets/img/imgIcon.png', }]

        const newImage = images && images[0] ? Object.values(JSON.parse(images)).map(i => ({
            itemImageSrc: i,
            thumbnailImageSrc: i
        })) : []

        const responsiveOptions = [
            {
                breakpoint: '991px',
                numVisible: 6
            },
            {
                breakpoint: '767px',
                numVisible: 3
            },
            {
                breakpoint: '575px',
                numVisible: 1
            }
        ];

        const itemTemplate = (item) => {
            return <Image
                className="flex w-screen"
                src={item.itemImageSrc}
                preview
                imageStyle={{
                    width: "auto",
                    height: '400px',
                    margin: '0 auto',
                }}
            />
        }
        const thumbnailTemplate = (item) => {
            return <Image
                className="flex"
                src={item.thumbnailImageSrc}
                imageStyle={{
                    height: '50%',
                    width: '50%',
                    margin: '0 auto',
                }}
            />
        }

        return (
            <div className="">
                <Galleria value={newImage && newImage[0] ? newImage : defaulImg}
                    item={itemTemplate} responsiveOptions={responsiveOptions} thumbnail={thumbnailTemplate} />
            </div>
        )
    }
    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            route="/electric_meter"
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'chỉ số điện nước'}
            actions={{ update: params.type !== 7 ? updateElectricWaterApi : updateElectric3PhaApi }}
        >
            <div className="grid grid-form align-items-center">
                <div className="col-12 lg:col-6">
                    <div className="card w-full text-center">
                        {params.type !== 7 ? <Image
                            preview
                            src={infos.images || '/assets/img/imgIcon.png'}
                            imageStyle={{
                                height: '200px',
                                width: '200px',
                                margin: '0 auto',
                                transform: `rotate(${rotation}deg)`,
                            }}
                        /> :
                            <Galleriaz images={infos.images} />
                        }
                    </div>
                    <div className="flex justify-content-around">
                        <Button icon="pi pi-arrow-left" type="button" onClick={() => handlePage('prev')} />
                        <div className="flex gap-2">
                            {params.type !== 7 &&
                                <Fragment>
                                    <Button
                                        type="button"
                                        icon="pi pi-trash"
                                        disabled={!infos.images}
                                        label="Xóa ảnh"
                                        severity="danger"
                                        onClick={handLeDeleteImage}
                                    />
                                    <Button
                                        type="button"
                                        icon="pi pi-replay"
                                        disabled={!infos.images}
                                        onClick={handleRotateImg}
                                    />
                                </Fragment>
                            }
                        </div>
                        <Button type="button" icon="pi pi-arrow-right" onClick={() => handlePage('next')} />
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <InputForm label="Căn hộ" value={infos.apartment} disabled />
                    <InputForm label="Loại dịch vụ" value={infos.service} disabled />
                    <InputForm label="Tháng chốt số" value={infos.month_create} disabled />
                    {params.type !== 7 ?
                        <Fragment>
                            <InputForm label="Chỉ số đầu" value={infos.before_number} disabled />
                            <InputNumberForm
                                label="Chỉ số cuối"
                                value={infos.after_number}
                                handleOnChange={(e) => setInfos({ ...infos, after_number: e })}
                            />
                        </Fragment>
                        :
                        <Fragment>
                            <InputForm label="Chỉ số 1" value={infos.number_1} disabled />
                            <InputForm label="Chỉ số 2" value={infos.number_2} disabled />
                            <InputForm label="Chỉ số 3" value={infos.number_3} onChange={e => setInfos({ ...infos, number_3: e.target.value })} />
                        </Fragment>

                    }
                    <InputForm label="Ghi chú (Mã đồng hồ mới)" disabled />
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default DetailElectricWater
