import { Dropdownz, FormUpdate, InputForm, InputNumberForm } from '@/components'
import { removePropObject } from '@/utils'
import { Button } from 'primereact/button'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useListPost } from '../../notification/utils'
import { postUpdateVoteApi } from '../api'
import { useGetDetailVoteApi } from '../utils'

const InputQuestion = ({ handleOnChange, id, value, handleDelete }) => {
    return (
        <div className="flex align-items-center justify-content-between lg:col-6 col-12 md:col-6 p-0 pr-2">
            <div className="w-full">
                <InputForm
                    placeholder="Nhập câu trả lời"
                    value={value[`poll_${id}`]}
                    onChange={(e) => handleOnChange({ value: e.target.value, id })}
                    required
                />
            </div>
            <div className="mb-2">
                <Button
                    type="button"
                    icon="pi pi-trash"
                    onClick={(e) => handleDelete(id)}
                    severity="danger"
                    className=""
                />
            </div>
        </div>
    )
}

const AddVote = (props) => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const detail = useGetDetailVoteApi({ id })
    const post = useListPost()
    const [infos, setInfos] = useState({})
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (detail) {
            const { title, options: optionsString, maximum, post_id, status } = detail
            const parsedOptions = optionsString ? JSON.parse(optionsString) : []
            setOptions(parsedOptions)
            setInfos({ title, maximum, post_id, options: parsedOptions, status })
        }
    }, [detail])

    const handleAdd = () => {
        const newOption = {}
        newOption[`poll_${options.length}`] = null
        setOptions((prev) => [...prev, newOption])
    }

    const handleDelete = (id) => {
        setOptions((prev) => prev.filter((_, index) => index !== id))
    }

    const handleOnChange = ({ value, id }) => {
        setOptions((prev) => prev.map((item, index) => (index === id ? { ...item, [`poll_${id}`]: value } : item)))
    }

    const handleData = () => {
        let info = { ...infos }
        if (!infos.title) return 'Bạn chưa nhập tên chiến dịch bình chọn'
        if (!infos.maximum) return 'Bạn chưa nhập số câu trả lời tối đa'
        if (!options || options.length === 0) {
            return 'Bạn chưa nhập câu trả lời'
        }
        if (options.length < infos.maximum) {
            return 'Số câu trả lời tối đa phải nhỏ hơn số đáp án'
        }
        if (Number(id)) info = { ...removePropObject(info, detail), id: id }
        info.options = options
        return info
    }
    return (
        <FormUpdate
            checkId={Number(id)}
            title="chiến dịch"
            route="/voting_list"
            actions={{ update: postUpdateVoteApi }}
            refreshObjects={[setInfos]}
            handleData={handleData}
        >
            <div>
                <InputForm
                    label="Câu hỏi thăm dò (*)"
                    value={infos.title || ''}
                    onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                    required
                />
            </div>
            <span className="pl-2 font-semibold">Câu trả lời</span>
            <div className="w-full flex flex-wrap">
                {options.map((value, index) => (
                    <InputQuestion
                        id={index}
                        value={value}
                        handleOnChange={handleOnChange}
                        handleDelete={handleDelete}
                        key={index}
                    />
                ))}
            </div>
            <div className="flex flex-column py-3">
                <div className="pl-2">
                    <Button type="button" label="Thêm câu trả lời" severity="primary" raised onClick={handleAdd} />
                </div>
            </div>
            <div className="">
                <InputNumberForm
                    value={infos.maximum || ''}
                    handleOnChange={(e) => setInfos({ ...infos, maximum: e })}
                    label="Số câu trả lời tối đa"
                    required
                />
            </div>
            <span className="pl-2 font-semibold">Bài viết liên quan</span>
            <Dropdownz
                id="post_id"
                value={infos.post_id}
                options={post}
                optionLabel="title"
                onChange={(e) => setInfos({ ...infos, post_id: e.target.value })}
                showClear={false}
                filter
            />
        </FormUpdate>
    )
}

export default AddVote
