import { getData, postData } from '@/lib/request'

export const listCarParkingAi = (params) => getData('web2/car_parking/listFilteredLogAi', params)
export const listCarParkingPm = (params) => getData('web2/car_parking/listFilteredLogPm', params)
export const ListCarParkingForControl = (params) => getData('web2/car_parking/listForControl', params)
export const listCarParkingLogAi = (params) => getData('web2/car_parking/listLogAi', params)
export const listCarParkingLogPm = (params) => getData('web2/car_parking/listLogPm', params)
export const countLogAi = (params) => getData('web2/car_parking/countLogAi', params)
export const countLogPm = (params) => getData('web2/car_parking/countLogPm', params)
export const listHandRecognition = (params) => getData('web2/car_parking/handRecognition', params)
export const deleteLogCarParking = (params) => postData('web2/car_parking/deleteAiLog', params)
export const updateLogCarParking = (params) => postData('web2/car_parking/updateLogCarParking', params)
export const exportFilteredAiLog = (params) => getData('web2/car_parking/expFilteredAiLog', params, true)
export const exportFilteredPmLog = (params) => getData('web2/car_parking/expFilteredPmLog', params, true)
export const exportAiLog = (params) => getData('web2/car_parking/expListLogAi', params, true)
export const exportPmLog = (params) => getData('web2/car_parking/expListLogPm', params, true)
export const exportForControl = (params) => getData('web2/car_parking/expForControl', params, true)
export const updateHandRecognition = (params) => postData('web2/car_parking/handRecognition', params)
export const updatePmLog = (params) => postData('web2/car_parking/updatePmLog', params)
export const updateAiLog = (params) => postData('web2/car_parking/updateAiLog', params)
