import { useEffect, useState } from 'react'
import {
    getListLogDebt,
    detailLogDebt,
    countLogDebt,
    listDebtLogApi,
    listDebitApi,
    getCountLogDebtDetail,
} from '../api'

export const useListLogDebt = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await getListLogDebt({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useDetailListLogDebt = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailLogDebt({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
export const useCountLogDebt = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countLogDebt({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useApartmentFilterApi = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDebtLogApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListDebitApi = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDebitApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useGetCountLogDebtDetail = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await getCountLogDebtDetail({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
