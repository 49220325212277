import { useEffect, useState } from 'react'
import {
    countBoardContactInfoApi,
    countBuildingInfoApi,
    countContactInfoApi,
    countPaymentInfoApi,
    countPaymentMethodApi,
    detailBuildingInfoApi,
    detailContactInfoApi,
    detailPaymentInfoApi,
    detailPaymentMethodApi,
    listBankApi,
    listBoardContactInfoApi,
    listBuildingInfoApi,
    listContactInfoApi,
    listDepartmentNameApi,
    listPaymentInfoApi,
    listPaymentMethodApi,
    listUserDepartmentApi,
} from '../api'

export const useListBuildingInfo = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBuildingInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountBuildingInfo = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBuildingInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailBuildingInfo = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailBuildingInfoApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

// ====================================================================

export const useListContactInfo = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listContactInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListBoardContactInfo = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBoardContactInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountContactInfo = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countContactInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountBoardContactInfo = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBoardContactInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useDetailContactInfo = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailContactInfoApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

export const useListDepartmentName = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDepartmentNameApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListUserDepartment = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listUserDepartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}


// ====================================================================

export const useListPaymentInfo = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPaymentInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountPaymentInfo = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPaymentInfoApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPaymentInfo = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPaymentInfoApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

// ====================================================================

export const useListPaymentMethod = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPaymentMethodApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountPaymentMethod = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPaymentMethodApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPaymentMethod = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPaymentMethodApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
////////////
export const useListBankInfo = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBankApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
