
import { Body, CalendarForm, Columnz, DataTablez, Dialogz, Dropdownz, GridForm, HeaderListForm, NumberFormat, TimeBody, useGetParams } from '@/components'

import { useState } from 'react'

import { customer_payments_type, listReceptType } from '@/constants'
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


import { setToast } from '@/redux/features'
import { useCountReceiptExpenditure, useListReceiptExpenditure } from '../utils'

import { listToast } from '@/constants'
import { useGetApi } from '@/hooks'
import { databaseDate } from '@/lib'
import { createAdjustment, listAccountApi } from '../api'
const Header = ({ setParams }) => {
    const apartments = useSelector(state => state.apartments)

    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                options={apartments}
                value={filter.bdc_apartment_id}
                onChange={(e) => setFilter({ ...filter, bdc_apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />

        </GridForm>
    )
}
export default function Createnew_paidmentVoucher() {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const navigate = useNavigate()
    const users = useSelector(state => state.users)
    const [apartment_id, setApartment_id] = useState(null)
    const dataV2 = useListReceiptExpenditure({ status: undefined, ...params, first: undefined, type: ["phieu_thu", "phieu_thu_khac", "phieu_bao_co"] })
    const totalRecords = useCountReceiptExpenditure({ status: undefined, ...params, first: undefined, type: ["phieu_thu", "phieu_thu_khac", "phieu_bao_co"] }) || 0
    const listAccount = useGetApi(listAccountApi, {}, [])
    const [visible, setVisible] = useState(false)
    const [infos, setInfos] = useState({})
    const [id, setId] = useState(null)
    const resetForm = () => {
        setInfos({ ...infos, create_date: null })
        setId(null)
        setApartment_id(null)
    }
    const handleData = async () => {
        const params = {
            id: id,
            create_date: infos.create_date ? databaseDate(infos.create_date, false,) : undefined,
            apartment_id: apartment_id
        }
        if (!infos.create_date) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn ngày điều chỉnh" }))
        const data = await createAdjustment(params)
        if (!data.data.status) {
            dispatch(setToast({ ...listToast[1], detail: data.data.mess }))
        } else {
            dispatch(setToast({ ...listToast[0], detail: "Điều chỉnh phiếu thu thành công" }))
            resetForm()
            setVisible(false)
            navigate('/receipt')
        }
    }
    const Edit = (rowData) => {
        const handleButtonClick = () => {
            setApartment_id(rowData.bdc_apartment_id)
            setId(rowData.id)
            setVisible(true);
        }
        return (
            <div className="flex gap-2 align-items-center justify-content-center">
                {rowData.deleted_at || rowData.status === 2 ? '' : (
                    <Button type="button" icon="pi pi-plus-circle" onClick={handleButtonClick} />
                )}
            </div>
        );
    }

    return (

        <div className="card bg-color">
            <HeaderListForm
                title={'Lập phiếu điều chỉnh'}
            />
            <Header setParams={setParams} />
            <div>
                <HeaderListForm title="Danh sách hóa đơn" />
                <DataTablez
                    value={dataV2}
                    title="hóa đơn"
                    route="receipt"
                    params={params}
                    setParams={setParams}
                    rowClassName={(rowData) => {
                        let classes = '';
                        if (rowData.deleted_at) {
                            classes += 'deleted-row';
                        }
                        if (rowData.status === 2) {
                            classes += (classes.length > 0 ? ' ' : '') + 'adjustmentVoucher-row';
                        }
                        return classes;
                    }}

                    totalRecords={totalRecords}
                >
                    <Columnz field="receipt_code" header="Mã chứng từ" />
                    <Columnz body={e => Body({ data: customer_payments_type, value: e.type_payment })} header="Hình thức" />
                    <Columnz body={e => Body({ data: listReceptType, value: e.type })} header="Loại phiếu" />
                    <Columnz body={e => TimeBody(e.created_at, 'date')} header="Ngày lập phiếu" />
                    <Columnz body={e => TimeBody(e.create_date, 'date')} header="Ngày hạch toán" />
                    <Columnz field="apartment_name" header="Căn hộ" />
                    <Columnz field="building_place_name" header="Tòa nhà" />
                    <Columnz field="customer_name" header="Khách hàng" />
                    <Columnz
                        className='text-right'
                        body={e => NumberFormat({ value: e.cost_paid })} header="Số tiền" />
                    <Columnz field="create_by" header="Người tạo" />
                    <Columnz body={e => e.payment_info_id ? Body({ data: listAccount.map(l => ({ name: `${l.short_url} - ${l.bank_account}`, id: l.id })), value: e.payment_info_id }) : ""} header="Tài khoản" />
                    <Columnz
                        header="Người xóa"
                        body={(e) => {
                            if (!e.user_id) return <div>
                                Auto
                                <p>{e.user_id}</p>
                            </div>
                            else {
                                const user = users.find(u => u.user_id === e.user_id) || {}
                                return <div>
                                    {user.full_name || "Admin"}
                                </div>
                            }
                        }}
                    />
                    <Columnz
                        header="Lý do xóa"
                        body={e => { if (e.deleted_at) return e.description }}
                    />
                    <Columnz body={(rowData) => Edit(rowData)} header="Thao tác" />


                </DataTablez>
                <Dialogz header="Phiếu điều chỉnh" visible={visible} onHide={() => setVisible(false)} >
                    <CalendarForm
                        label="Chọn ngày điều chỉnh"
                        value={infos.create_date}
                        onChange={(e) => setInfos({ ...infos, create_date: e.target.value })} />
                    <div className="flex justify-content-end gap-2">
                        <Button severity="danger" label="Hủy" onClick={() => setVisible(false)} />
                        <Button label="Điều chỉnh" onClick={handleData} />
                    </div>
                </Dialogz>
            </div>

        </div>

    )
}
