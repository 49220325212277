import { getData, postData } from '@/lib/request'



export const countAssetAreaApi = (params) => getData('web2/company/countCompany', params)




export const listAssetMix = (params) => getData('web2/asset_mix_management/getListProductGroup', params)
export const updateAssetMix = (params) => postData('/web2/asset_mix_management/updateProductGroup', params)
export const addAssetMix = (params) => postData('/web2/asset_mix_management/addProductGroup', params)
export const detailAssetMix = (params) => getData('web2/asset_mix_management/detailProductGroup', params)
export const deleteAssetMix = (params) => postData('web2/product/delProduct', params)
export const countAssetMix = (params) => getData('web2/asset_mix_management/countProductGroup', params)

export const listAssetApi = (params) => getData('web2/product/getListProductV2', params)
export const countProductApi = (params) => getData('web2/product/countProduct', params)
export const detailProductApi = (params) => getData('web2/product/getDetailProduct', params)
export const addProducApi = (params) => postData('web2/product/addProduct', params, true)
export const updateProducApi = (params) => postData('web2/product/updateProduct', params, true)

export const listProductGroupApi = (params) => getData('web2/asset_mix_management/getListProductGroupV2', params)
export const addProductGroupApi = (params) => postData('web2/asset_mix_management/addProductGroupV2', params)
export const updateProductGroupApi = (params) => postData('web2/asset_mix_management/updateProductGroupV2', params)



