import { getData, postData } from '@/lib/request'
export const listWarehouse = (params) => getData('web2/warehouse/getListWarehouseAsset', params)
export const listDepartmentWareHouse = (params) => getData('web2/group/getListGroup', params)
export const listWarehouseV2 = (params) => getData('web2/warehouse/getListInventoryAsset', params)
export const listWarehouseByDepartment = (params) => getData('web2/warehouse/getListDepartmentAsset', params)
export const countWarehouse = (params) => getData('web2/warehouse/countListWarehouseAsset', params)
export const detailWarehouse = (params) => getData('/web2/warehouse/getDetailWarehouseAsset', params)
export const addWarehouse = (params) => postData('web2/warehouse_product/addWarehouseProduct', params, true)
export const createBill = (params) => postData('web2/warehouse_receipt/addWarehouseReceipt', params, true)
export const updateWarehouse = (params) => postData('web2/warehouse_product/updateWarehouseProduct', params, true)
export const historybill = (params) => getData('web2/warehouse_receipt/getListBdcWarehouseReceiptDetailByProductId', params,)
export const countHistoryBill = (params) => getData('web2/warehouse_receipt/countBdcWarehouseReceiptDetailByProductId', params,)
export const historybillReceipt = (params) => getData('web2/warehouse_receipt/getListWarehouseReceipt', params,)
export const countHistoryBillReceipt = (params) => getData('web2/warehouse_receipt/countListWarehouseReceipt', params,)
export const printBillWareHouse = (params) => getData('web2/warehouse_receipt/renderWarehouseReceipt', params,)