import React, { useState } from 'react'
import {
    Columnz,
    DataTablez,
    useGetParams,
    HeaderListForm,
    Body,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'

import { useCountManagementDocs, useListDocsGroup, useListManagementDocs } from '../utils'
import { deleteManagementDocsApi } from '../api'
import UpdateApartmentDocs from './UpdateApartmentDocs'
import { useSelector } from 'react-redux'
import ShowFile from '@/components/ShowFile'

const Header = ({ setParams, apartments, documentClassification }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập tên file tài liệu, từ khóa tìm kiếm..."
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.document_type}
                options={documentClassification}
                onChange={(e) => setFilter({ ...filter, document_type: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.from_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, from_id: e.target.value })}
                placeholder="Căn hộ"
            />
        </GridForm>
    )
}

export default function ApartmentDocs() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListManagementDocs({ status: undefined, ...params, first: undefined, from_type: 1 })
    const documentClassification = useListDocsGroup({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountManagementDocs({ status: undefined, ...params, from_type: 1, first: undefined }) || 0
    const [visible, setVisible] = useState(false)
    const buildings = useSelector(state => state.buildings)
    const apartments = useSelector(state => state.apartments)
    const users = useSelector(state => state.users)

    return (
        <div className="card">
            {
                visible && <UpdateApartmentDocs
                    documentClassification={documentClassification} visible={visible}
                    setVisible={setVisible} setParams={setParams} buildings={buildings}
                    apartments={apartments}
                />
            }
            <HeaderListForm title="Thông tin tài liệu căn hộ" />
            <Header setParams={setParams} apartments={apartments} documentClassification={documentClassification} />
            <DataTablez
                value={data}
                title="Tài liệu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/docs_management"
                headerInfo={{}}
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteManagementDocsApi }}
                basePermissions={['insert', 'detail', 'delete']}
                hideParams
            >
                <Columnz body={e => Body({ data: apartments, value: e.from_id })} header="Căn hộ" />
                <Columnz body={e => {
                    const building_place_id = apartments && apartments.find(a => a.id === e.from_id)
                    return Body({ data: buildings, value: building_place_id?.building_place_id })
                }} header="Tòa nhà" />
                <Columnz body={e => <div className="cursor-pointer">
                    <ShowFile target="_blank" link={e.file} label={e.name} />
                </div>}
                         header="File tài liệu" />
                <Columnz body={e => Body({ data: documentClassification, value: e.document_type })}
                         header="Phân loại" />
                <Columnz body={(e) => {
                    const user = users.find(u => u.user_id === e.user_id)
                    return user?.full_name ? user?.full_name : 'Admin'
                }}
                         header="Người tạo" />
                <Columnz field="department_store" header="Bộ phận lưu trữ tài liệu gốc" />
                <Columnz field="department_backup" header="Bộ phận lưu trữ tài sao lưu" />
            </DataTablez>
        </div>
    )
}
