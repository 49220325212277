import { useEffect, useState } from 'react'
import { countServicesApi, detailServicesApi, listServicesApi } from '../api'

export const useListServices = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listServicesApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountServices = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countServicesApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailServices = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailServicesApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
