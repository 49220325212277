import { LoadDialog, useGetParams } from '@/components'
import { listToast } from '@/constants'
import { useSocket } from '@/modules/socket/SocketConnection'
import { clearUserInfo, setItem, setToast, ToggleLoading } from '@/redux/features'
import { Button } from '@/uiCore'
// import { Avatar, MenuItem } from '@mui/material'
import { confirmDialog } from 'primereact/confirmdialog'
import { Sidebar } from 'primereact/sidebar'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { deleteApi, readNotifyApi, readNotifyV2Api } from './api'
import { useCountNotify, useListNotify } from './utils'
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
const b = localStorage.getItem('token')
const clientId = localStorage.getItem('clientId')

const AppTopbar = forwardRef((props, ref) => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const { setVisible } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userInfo = useSelector((state) => state.userInfo) || {}
    const [anchorEl, setAnchorEl] = useState(null)
    const [notify, setNotify] = useState(false);
    const data = useListNotify({ status: undefined, ...params, first: undefined })
    const countNotify = useCountNotify({ status: undefined, ...params, first: undefined }) || 0
    const notiRef = useRef()
    const countData = data.length
    const item = useSelector(state => state.item);
    const myTool = useSelector((state) => state.myTool) || {}
    const building_id = item.building_id
    const company_id = item.company_id
    const companies = myTool.companies || []
    const keySk = 'notify_user_admin_' + userInfo.user_id
    const residents = useSelector(state => state.residents);
    const menuRight = useRef(null);
    const toast = useRef(null);
    const socket = useSocket();
    let token = ''
    if (!b)
    {
        token = localStorage.getItem('token')
    } else token = b

    const handleLogout = () => {
        localStorage.removeItem('token')
        dispatch(clearUserInfo())
        dispatch(setToast({ ...listToast[0], detail: 'Đăng xuất thành công!' }))
        navigate('/auth/login')
        handleClose()
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleHideMenu = (e) => {
        if (anchorEl && !anchorEl.contains(e.target))
        {
            handleClose()
        }
    }
    const handleNoti = (data) => {
        if (data.status)
        {
            setParams((prev) => ({ ...prev, render: !prev.render }));
            dispatch(setToast({ ...listToast[3], detail: 'Bạn có 1 thông báo mới' }));
        }
    };
    useEffect(() => {
        if (socket)
        {
            socket.on(keySk, handleNoti);
            return () => {
                socket.off(keySk, handleNoti);
            };
        }
    }, [socket]);
    useEffect(() => {
        if (anchorEl)
        {
            document.addEventListener('mousedown', handleHideMenu)
        } else
        {
            document.removeEventListener('mousedown', handleHideMenu)
        }

        return () => {
            document.removeEventListener('mousedown', handleHideMenu)
        }
    }, [anchorEl])


    const accept = async (id) => {
        const res = await deleteApi({ id: id, })
        if (res.data.status) dispatch(setToast({ ...listToast[0], detail: "Xóa thông báo thành công" }))
        setParams(prev => ({ ...prev, render: !prev.render }))
    }

    const handleDelete = (id) => {
        confirmDialog({
            message: 'Xác nhận xóa thông báo này?',
            header: 'X-Building',
            icon: 'pi pi-info-circle',
            accept: () => accept(id),
        })
    };

    const redirectNoti = (type, id, building_idz) => {
        const typeToUrl = {
            1: `/resident_opinion/detail/${id}`, // ý kiến
            3: `/posts/detail/${id}`, // thông báo
            5: `/posts/detail/${id}`, // thông báo
            6: `/posts/detail/${id}`, // thông báo
            8: `/service_request/detail/${id}`, // yêu cầu
            13: `/booking_time_place/detail/${id}`, // đặt chỗ
        }
        const url = typeToUrl[Number(type)]
        if (url)
        {
            navigate(url)
            setNotify(false)
            if (building_idz !== building_id)
            {
                let company_id
                companies.forEach(c => {
                    if (c.buildings && c.buildings[0])
                    {
                        c.buildings.forEach(b => {
                            if (b.id === building_idz) company_id = c.id
                        })
                    }
                })
                const obj = { building_id: building_idz, company_id }
                localStorage.setItem('item', JSON.stringify(obj))
                dispatch(setItem(obj))
                dispatch(ToggleLoading(true))
            }
        }
    }

    const items = [
        {
            label: 'Thông tin ',
            icon: 'pi pi-info-circle',
            command: () => setVisible(true),
        },
        {
            label: 'Đổi mật khẩu',
            icon: 'pi pi-sync',
            command: () => navigate('/auth/change_password'),
        },
        {
            label: 'Đăng xuất',
            icon: 'pi pi-sign-out',
            command: () => handleLogout(),
        }
    ]

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <div className="flex align-items-center">
                    <span className="text-white text-xl">
                        X Building
                    </span>
                </div>
            </Link>
            <button
                type="button"
                className="p-link layout-menu-button layout-topbar-button"
                onClick={props.onMenuToggle}
            >
                <i className="pi pi-bars" />
            </button>

            <div className="flex gap-1 layout-topbar-menu">
                <Button rounded onClick={async () => {
                    setNotify(true)
                    await readNotifyV2Api()
                    setParams(prev => ({ ...prev, render: !prev.render }))

                }} className="border-none shadow-none flex align-items-center cursor-pointer p-3 border-round text-700 w-full  p-link" style={{ background: '#3c8dba', }}>
                    <i className="pi pi-bell mr-2  text-white text-2xl relative" ></i>
                    {Boolean(countNotify) &&
                        <span className="align-items-center justify-content-center ml-auto bg-red-500 text-0 border-circle absolute top-50 left-50" style={{ minWidth: '1.5rem', height: '1.5rem', }}>
                            {countNotify}
                        </span>
                    }
                </Button>
                <div className="flex justify-content-center align-items-center">.
                    <Sidebar ref={notiRef} showCloseIcon={true} position='right' visible={notify} onHide={() => {
                        setNotify(false)
                        setParams(initParam)
                    }}
                        icons={
                            <>
                                <div className="flex w-full align-items-center justify-content-between text-xl relative">
                                    <b className='mb-0'>Thông báo</b>
                                </div>
                            </>}
                    >
                        <hr />
                        {data && data.map((d, index, arr) => {
                            const currentDate = new Date()
                            const createdAt = new Date(d.created_at)
                            let timeDiff = Math.floor((currentDate - createdAt) / 60000)
                            if (timeDiff > 60 * 24 * 30 * 12) timeDiff = Math.floor(timeDiff / (60 * 24 * 30 * 12)) + ' năm trước'
                            else if (timeDiff > 60 * 24 * 30) timeDiff = Math.floor(timeDiff / (60 * 24 * 30)) + ' tháng trước'
                            else if (timeDiff > 60 * 24) timeDiff = Math.floor(timeDiff / (60 * 24)) + ' ngày trước'
                            else if (timeDiff > 60) timeDiff = Math.floor(timeDiff / 60) + ' giờ trước'
                            else timeDiff = (timeDiff <= 0 ? 'Vừa xong' : timeDiff + ' phút trước')
                            return (
                                <div key={index} className={`cursor-pointer grid mb-3 hover:surface-300 border-round-xl  ${d.status === 0 || d.status === 2 ? 'surface-200' : ''} `}>
                                    <div onClick={async () => {
                                        redirectNoti(d.type_sender, d.id_sender, d.building_id)
                                        await readNotifyApi({ id: d._id })
                                        setParams(prev => ({ ...prev, limit: 10, render: !prev.render }))
                                    }} className='flex align-items-center gap-3 col-11'>
                                        <Avatar
                                            className="avatar"
                                            src={'/assets/img/profile.png'}
                                            alt="Ảnh đại diện"
                                            style={{ borderRadius: '50%' }}
                                        />
                                        <div className="">
                                            <div className='flex mb-1 align-items-start gap-1 flex-column'>
                                                <b className='font-bold'>{d.building_name}</b>
                                                <b className='font-bold'>{d.sender}<b className='font-medium text-800 ml-1' >{d.action_desc}</b> </b>
                                            </div>
                                            <p className='font-normal mb-1' style={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: '3',
                                                overflow: 'hidden'
                                            }}>{d.content}</p>
                                            <span className={`text-sm ${d.status !== 1 && 'text-blue-500'}`}>{timeDiff}</span>
                                        </div>

                                    </div>
                                    <div className='col-1 pl-1 '>
                                        <i onClick={() => handleDelete(d._id)} className='pi pi-times hover:text-red-500'></i>
                                    </div>
                                </div>
                            )
                        })}
                        {
                            countData >= params.limit && (
                                <div className='flex justify-content-center '>
                                    <Button className='shadow-none' label="Show more" text onClick={e => {
                                        setParams(prev => ({ ...prev, render: !prev.render, limit: prev.limit + 10, }))
                                    }}></Button>
                                </div>
                            )
                        }
                    </Sidebar>
                </div>

                <span>{userInfo && userInfo.email}</span>
                <div >
                    {/* <Avatar image={userInfo.avatar || '/assets/img/profile.png'} shape="circle" />   */}
                    <img src={userInfo.avatar || '/assets/img/profile.png'} height="42px"
                        width="42px" className='border-circle' onClick={(event) => menuRight.current.toggle(event)}
                    />
                    <Toast ref={toast}></Toast>
                    <Menu className='' model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                </div>

            </div>
        </div >
    )
})

export default AppTopbar
