import { getData, postData } from '@/lib/request'

export const listDataReviewApi = (params) => getData('web2/bill_approval/getListBillApproval', params)
export const countDataReviewApi = (params) => getData('web2/bill_approval/countBillApproval', params)
export const deleteDataReviewApi = (params) => postData('web2/bill_approval/deleteDataReview', params)
export const detailDataReviewApi = (params) => getData('web2/bill_approval/detailDataReview', params)
export const addDataReviewApi = (params) => postData('web2/bill_approval/addDataReview', params)
export const updateDataReviewApi = (params) => postData('web2/bill_approval/updateBdcDataReview', params)
export const updateAcpDataReviewApi = (params) => postData('web2/bill_approval/approveBill', params)
export const approveBillApi = (params) => postData('web2/bill_approval/approveBill', params)
