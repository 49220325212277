import { DropdownForm, FormUpdateDialog, InputForm, InputSwitchForm, InputTextareaForm, UploadFiles } from '@/components'
import React, { useState } from 'react'
import { addSubdivisionApi, updateSubdivisonApi } from '@/api'
import { useGetApi } from '@/hooks'
import { addResidentOptionApi, getListCategoryReviewApi } from '../api'
import { useSelector } from 'react-redux'

const AddResidentOption = (props) => {
    const { setVisible, setParams, visible } = props
    const categories = useGetApi(getListCategoryReviewApi, false, [])
    const apartments = useSelector(state => state.apartments)
    const residents = useSelector(state => state.residents)
    const [infos, setInfos] = useState({
        title: '',
        content: '',
    })
    const [files, setFiles] = useState([])

    const handleData = () => {
        let info = { ...infos }
        if (!info.apartment_id) return "Vui lòng chọn căn hộ"
        if (!info.content) return "Vui lòng nhập nội dung"
        if (!info.user_id) return "Vui lòng chọn người yêu cầu"
        if (!info.category_review_id) return "Vui lòng chọn danh mục"
        if (files && files[0]) info.file = files
        return info
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            refreshObjects={[setInfos]}
            route="/resident_opinion"
            title={"Thêm ý kiến cư dân"}
            actions={{ add: addResidentOptionApi, update: addResidentOptionApi }}
        >
            <div className="card bg-color">
                <DropdownForm
                    id="apartment_id"
                    options={apartments}
                    value={infos.apartment_id}
                    onChange={(e) => setInfos({ ...infos, apartment_id: e.target.value, user_id: undefined })}
                    label="Căn hộ (*)"
                />
                <DropdownForm
                    id="user_id"
                    options={infos.apartment_id ? residents.filter(r => r.apartment_id === infos.apartment_id) : []}
                    value={infos.user_id}
                    optionLabel="full_name"
                    optionValue="user_id"
                    onChange={(e) => setInfos({ ...infos, user_id: e.target.value })}
                    label="Người yêu cầu (*)"
                />
                <InputForm
                    id="title"
                    value={infos.title}
                    onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                    label="Tiêu đề (*)"
                    required
                />
                <InputTextareaForm
                    id="content"
                    value={infos.content}
                    onChange={(e) => setInfos({ ...infos, content: e.target.value })}
                    label="Nội dung (*)"
                    required
                />
                <DropdownForm
                    id="category_review_id"
                    options={categories}
                    value={infos.category_review_id}
                    onChange={(e) => setInfos({ ...infos, category_review_id: e.target.value })}
                    label="Danh mục"
                />
                <UploadFiles files={files} setFiles={setFiles} />
            </div>
        </FormUpdateDialog>
    )
}

export default AddResidentOption
