import React, { useEffect, useState } from 'react'
import {
    InputForm,
    FormUpdateDialog,
} from '@/components'
import { removePropObject } from '@/utils'
import { addDocsGroupApi, deleteDocsGroupApi, updateDocsGroupApi } from '../api'
import { useDetailDocsGroup } from '../utils'

function UpdateDocsGroup(props) {
    const { visible, setVisible, setParams } = props
    const [infos, setInfos] = useState({ name: '' })
    const detailDocsGroup = useDetailDocsGroup(typeof visible === 'number' ? visible : undefined)

    useEffect(() => {
        if (detailDocsGroup.id) {
            setInfos({
                ...infos,
                ...detailDocsGroup
            })
        }
    }, [detailDocsGroup])

    const handleData = () => {
        let info = { ...infos, ...removePropObject(infos, detailDocsGroup), id: visible }
        if (!info.name) return 'Vui lòng nhập tên nhóm tài liệu'
        return info
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Cập nhật' : 'Thêm mới') + ' nhóm tài liệu'}
            setParams={setParams}
            handleData={handleData}
            visible={visible}
            route="/docs_management"
            setVisible={setVisible}
            refreshObjects={[setInfos]}
            actions={{ add: addDocsGroupApi, update: updateDocsGroupApi }}
        >
            <div className="bg-color">
                <InputForm
                    id="name"
                    value={infos.name}
                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                    label="Tên nhóm tài liệu"
                    required
                />

            </div>
        </FormUpdateDialog>
    )
}
export default UpdateDocsGroup