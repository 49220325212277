import {
    Columnz,
    DataTablez,
    DataTablezV2,
    DropdownForm,
    Dropdownz,
    FormUpdate,
    GridForm,
    HeaderListForm,
    InputForm,
    InputTextareaForm,
    Inputz,
    MultiSelectForm,
    StatusBody,
    useGetParams,
} from '@/components'
import { checkJson, getArrId, removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { removeUndefinedProps } from '@/utils'
import { useDetailDepartment, useCountBuildingCompany, useListNameBuildingCompanyApi, useListStaff } from '../utils'
import { addDepartmentApi, updateDepartmentApi } from '../api'
import UpdateStaff from './UpdateStaff'
import { InputSwitch } from '@/uiCore'
import { useGetApi } from '@/hooks'
import { listProjectApi } from '@/api'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const UpdateDepartment = () => {
    const { id } = useParams()
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState(initParam)
    const detailApartment = useDetailDepartment(id)
    const detailGroup = detailApartment.detailGroup
    const listStaff = useListStaff({ status: undefined, ...params, first: undefined, id: id })
    const totalRecords = useCountBuildingCompany({ id: id })
    const listNameBuildingCompanyApi = useListNameBuildingCompanyApi({ id: id })
    const projects = useGetApi(listProjectApi, {}, [])
    const [infos, setInfos] = useState({
        name: '',
        phone_number: '',
        main_user_id: '',
        description: '',
        building_id: '',
        email: '',
        code: '',
    })

    useEffect(() => {
        if (detailApartment) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(detailApartment),
                building_id: Number(detailApartment),
            })
        }
    }, [detailGroup, projects])

    const handleData = () => {
        let info = { ...infos, main_user_id: Number(infos.main_user_id) }
        if (Number(id)) info = { ...removePropObject(info, detailApartment), id: Number(id) }
        return info
    }

    return (
        <>
            <FormUpdate
                checkId={Number(id)}
                title="bộ phận"
                handleData={handleData}
                route="/departments_list/"
                actions={{ update: updateDepartmentApi }}
                refreshObjects={[setInfos]}
            >
                <div className="card bg-color">
                    <div style={{ maxWidth: '1200px' }}>
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên bộ phận"
                            required
                        />
                        <InputForm
                            id="code"
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã bộ phận"
                            required
                        />
                        {/* <MultiSelectForm
                            id="building_id"
                            // value={infos.building_id}
                            onChange={
                                (e) => setInfos({ ...infos, building_id: e.target.value })}
                            options={projects}
                            label="Tòa nhà"
                        /> */}
                        <DropdownForm
                            id="building_id"
                            value={infos.building_id}
                            onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                            label="Tòa nhà"
                            options={projects}
                        />
                        <InputForm
                            id="phone_number"
                            value={infos.phone_number}
                            onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                            label="Số điện thoại liên hệ"
                            required
                        />
                        <InputForm
                            id="email"
                            value={infos.email}
                            onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                            label="Email liên hệ"
                            required
                        />
                        <DropdownForm
                            value={infos.main_user_id}
                            onChange={(e) => setInfos({ ...infos, main_user_id: e.target.value })}
                            label="Trưởng bộ phận"
                            options={listNameBuildingCompanyApi}
                            optionLabel="full_name"
                            optionValue="user_id"
                        />
                        <InputTextareaForm
                            id="description"
                            value={infos.description}
                            onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                            label="Mô tả"
                        />
                    </div>
                </div>
            </FormUpdate>
            {id && (
                <div className="card">
                    {visible && <UpdateStaff params={params} visible={visible} setVisible={setVisible} />}
                    <HeaderListForm title="Danh sách nhân sự thuộc bộ phận" />
                    <Header setParams={setParams} />
                    <DataTablez
                        dataKey="user_id"
                        value={listStaff}
                        title="nhân sự"
                        totalRecords={totalRecords}
                        params={params}
                        setParams={setParams}
                        route="/departments_list"
                        setVisibledDialog={setVisible}
                        actionsInfo={{}}
                    >
                        <Columnz field="full_name" header="Tên nhân viên" />
                        <Columnz field="user_id" header="Mã nhân viên" />
                        <Columnz field="email_contact" header="Email" />
                        <Columnz field="phone_contact" header="Số điện thoại" />
                        <Columnz field="cmt_number" header="Số CMND/CCCD" />
                        <Columnz
                            header="Trưởng bộ phận"
                            body={(rowData) => (
                                <InputSwitch disabled checked={rowData.main_user_id === rowData.user_id} />
                            )}
                        />
                        <Columnz field="code" header="Mô tả" />
                    </DataTablez>
                </div>
            )}
        </>
    )
}

export default UpdateDepartment
