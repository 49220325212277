import { Body, Columnz, DataTablez, GridForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { typesOfPromotion } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deletePromotionApi, updatePromotionApi2 } from '../api'
import { useCountPromotion, useListPromotion, useListServices } from '../utils'
import UpdatePromotion from './UpdatePromotion'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập nội dung tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function ListPromotionManage() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPromotion({ status: undefined, ...params, first: undefined })
    const services = useListServices()
    const users = useSelector((state) => state.users)
    const totalRecords = useCountPromotion({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    return (
        <div className="card">
            {visible && (
                <UpdatePromotion setParams={setParams} visible={visible} setVisible={setVisible} services={services} />
            )}
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="ưu đãi"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/promotion_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deletePromotionApi }}
                headerInfo
                basePermissions={["insert", "detail", "delete"]}
            >
                <Columnz field="id" header="ID ưu đãi" />
                <Columnz field="name" header="Tên ưu đãi" />
                <Columnz body={(e) => Body({ data: typesOfPromotion, value: e.type_discount })} header="Loại ưu đãi" />
                <Columnz body={e => TimeBody(e.begin)} header="Thời gian áp dụng" />
                <Columnz field="discount" header="Giá trị ưu đãi" />
                <Columnz
                    body={(e) => Body({ data: users, value: e.by, key: 'user_id', label: 'full_name' }) || "Admin"}
                    header="Người tạo"
                />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/project_general', updateAction: updatePromotionApi2 })}
                />
            </DataTablez>
        </div>
    )
}
