const { postData, getData } = require("@/lib/request");

export const listConfirmConfigApi = (params) => getData('web2/confirm_manage/getListConfirmConfig', params)
export const countConfirmConfigApi = (params) => getData('web2/confirm_manage/countConfirmConfig', params)
export const updateStatusConfirmConfigApi = (params) => postData('web2/confirm_manage/updateStatusConfirmConfig', params)
export const addConfirmConfigApi = (params) => postData('web2/confirm_manage/addConfirmConfig', params)
export const detailConfirmConfigApi = (params) => getData('web2/confirm_manage/getDetailConfirmConfig', params)

export const updateConfirmConfigApi = (params) => postData('web2/confirm_manage/updateStepConfig', params)

