import { TabPanel, TabView } from 'primereact/tabview'
import React, { useState } from 'react'
import GeneralInformation from './GeneralInformation'
import InsuranceTaxes from './InsuranceTaxes'
import Other from './Other'
import Contract from './Contract'

function UpdatePersonnel() {
      const [showMore, setShowMore] = useState(false)
      return (
            <div>
                  <div className="card">
                        <TabView>
                              <TabPanel header="Thông tin chung">
                                    <GeneralInformation setShowMore={setShowMore} />
                              </TabPanel>
                              {Boolean(showMore) ?

                                    <TabPanel header="Hợp đồng">
                                          <Contract />
                                    </TabPanel>

                                    : ''}
                              {Boolean(showMore) ?

                                    <TabPanel header="Khác">
                                          <Other />
                                    </TabPanel>

                                    : ''}

                        </TabView>
                  </div>
            </div>
      )
}

export default UpdatePersonnel
