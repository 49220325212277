import { useEffect, useState } from "react"
import { countHistoryBill, countHistoryBillReceipt, countWarehouse, detailWarehouse, historybill, historybillReceipt, listWarehouse, listWarehouseByDepartment, printBillWareHouse } from "../api"

export const useListWarehouse = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listWarehouse({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useListWarehouseDepartment = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listWarehouseByDepartment({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useListHistoryBill = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await historybill({ ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useListHistoryBillReceipt = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await historybillReceipt({ ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}

export const useDetailWarehouse = (code) => {
      const [data, setData] = useState({})
      async function fetchData() {
            const response = await detailWarehouse({ code: code })
            if (response.data.status)
                  setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [code])
      return data
}
export const useCountHistoryBillReceipt = (params) => {
      const [data, setData] = useState(0)
      async function fetchData() {
            const response = await countHistoryBillReceipt({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useCountHistoryBill = (params) => {
      const [data, setData] = useState(0)
      async function fetchData() {
            const response = await countHistoryBill({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useCountWareHouse = (params) => {
      const [data, setData] = useState(0)
      async function fetchData() {
            const response = await countWarehouse({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useRenderBillWareHouse = (id) => {
      const [data, setData] = useState({})
      async function fetchData() {
            const response = await printBillWareHouse({ receipt_id: id })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            if (id) fetchData()
      }, [id])
      return data
}