import {
    Body,
    Columnz,
    DataTablez,
    DropdownForm,
    Dropdownz,
    FormUpdateDialog,
    GridForm,
    Inputz,
    MultiSelectForm,
    useGetParams,
} from '@/components'
import { statusApartment } from '@/constants'
import { useState } from 'react'
import { getArrId } from '@/utils'
import { useDispatch, useSelector } from 'react-redux'
import { removeApartment } from '@/redux/features/apartments'
import ImportApartment from './ImportApartment'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import {
    addApartmentToGroupApi,
    countApartmentApi,
    deleteApartmentApi,
    exportApartmentApi,
    listApartmentApi,
    listGroupApartmentApi,
    listOwnerApi,
} from '@/api'
import { useGetApi } from '@/hooks'

const ApartmentToGroup = ({ visible, setParams, setVisible, selectedProducts, groupApartments }) => {
    const [groupId, setGroupId] = useState(false)
    const apartments = useSelector((state) => state.apartments)

    return (
        <FormUpdateDialog
            title="Thêm vào nhóm"
            checkId={true}
            handleData={() => {
                return { group_id: groupId, list_id: getArrId(selectedProducts) }
            }}
            actions={{ update: addApartmentToGroupApi }}
            visible={visible}
            route="/apartment_management"
            setVisible={setVisible}
            refreshObjects={[setGroupId]}
            setParams={setParams}
        >
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <MultiSelectForm
                        id="apartment_ids"
                        value={getArrId(selectedProducts)}
                        options={apartments}
                        label="Căn hộ"
                        disabled
                    />
                </div>
                <div className="col-12 lg:col-6">
                    <DropdownForm
                        id="building_place_id"
                        value={groupId}
                        options={groupApartments}
                        optionLabel="group_name"
                        onChange={(e) => setGroupId(e.target.value)}
                        label="Chọn nhóm căn hộ (*)"
                    />
                </div>
            </div>
        </FormUpdateDialog>
    )
}

const Header = ({ setParams, buildings, owners }) => {
    const [filter, setFilter] = useState({ key_search: '', floor: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã căn hộ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Inputz
                value={filter.floor}
                placeholder="Tầng"
                onChange={(e) => setFilter({ ...filter, floor: e.target.value })}
            />
            <Dropdownz
                value={filter.owner_id}
                onChange={(e) => setFilter({ ...filter, owner_id: e.target.value })}
                options={owners}
                optionLabel="full_name"
                placeholder="Chủ hộ"
            />
            <Dropdownz
                value={filter.building_place_id}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                options={buildings}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={statusApartment}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const ListApartment = () => {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const [visibleImport, setVisibleImport] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useGetApi(listApartmentApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countApartmentApi, { ...params, first: undefined }, 0)
    const buildings = useSelector((state) => state.buildings)
    const groupApartments = useGetApi(listGroupApartmentApi, {}, [])
    const owners = useGetApi(listOwnerApi, {}, [])
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'căn hộ',
            actionType: 'xóa',
            action: async (listId) => await deleteApartmentApi({ listId: getArrId(listId) }),
        },
        {
            label: 'Thêm vào nhóm',
            icon: 'pi pi-plus',
            command: () => {
                if (selectedProducts && selectedProducts[0]) setVisible(true)
                else dispatch(setToast({ ...listToast[2], detail: 'Vui lòng chọn căn hộ trước khi thêm vào nhóm!' }))
            },
        },
    ]

    const handleAfterCallApi = (data) => {
        if (data) {
            if (typeof data === 'object') {
                const arrId = getArrId(data)
                arrId.forEach((a) => {
                    dispatch(removeApartment(a))
                })
            } else dispatch(removeApartment(data))
        }
    }

    return (
        <>
            {visible && (
                <ApartmentToGroup
                    setParams={setParams}
                    selectedProducts={selectedProducts}
                    buildings={buildings}
                    visible={visible}
                    groupApartments={groupApartments}
                    setVisible={setVisible}
                />
            )}
            {visibleImport && <ImportApartment visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            <Header buildings={buildings} owners={owners} setParams={setParams} />
            <DataTablez
                title="căn hộ"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/apartment_management"
                headerInfo={{ items, exportApi: exportApartmentApi, setVisibleImport: setVisibleImport }}
                actionsInfo={{
                    deleteAction: deleteApartmentApi,
                    handleDelete: (id) => {
                        return { listId: [id] }
                    },
                }}
                basePermissions={["insert", "detail", "delete", "import", "export"]}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                handleAfterCallApi={handleAfterCallApi}
            >
                <Columnz field="id" header="ID căn hộ" />
                <Columnz field="name" header="Căn hộ" />
                <Columnz field="code" header="Mã căn hộ" />
                <Columnz field="name_customer"
                    header="Chủ hộ"
                />
                <Columnz body={(e) => Body({ data: buildings, value: e.building_place_id })} header="Tòa" />
                <Columnz field="floor" header="Tầng" />
                <Columnz field="user_number" header="Số người" />
                <Columnz field="vehicle_number" header="Số phương tiện" />
                <Columnz body={(e) => Body({ data: statusApartment, value: e.status })} header="Tình trạng" />
                <Columnz
                    body={(e) => Body({ data: groupApartments, value: e.bdc_apartment_group_id, label: 'group_name' })}
                    header="Nhóm căn hộ"
                />
            </DataTablez>
        </>
    )
}

export default ListApartment
