import { DropdownForm, FormUpdate, InputForm, InputSwitchForm, } from '@/components'
import { addPaymentInfoApi } from '@/modules/buildings/building_overview/api'
import { useState } from 'react'
import { useListBankInfo } from '@/modules/buildings/building_overview/utils'

function BillingInfo(props) {

    const { setVisible, visible } = props
    const bank = useListBankInfo()

    const [infos, setInfos] = useState({
        bank_account: '',
        bank_name: '',
        bank_id: '',
        branch: '',
        holder_name: '',
        active_payment: true,
        status_payment_info: true,
    })
    const handleData = () => {
        let info = {
            ...infos,
            active_payment: infos.active_payment ? 1 : 0,
            status_payment_info: infos.active_payment ? 1 : 0,
            id: visible
        }
        return info
    }
    return (
        <div className="col-12 lg:col-12">
            <FormUpdate
                title={'thông tin thanh toán'}
                route="/project_general"
                setVisible={setVisible}
                visible={visible}
                actions={{ add: addPaymentInfoApi }}
                handleData={handleData}
                refreshObjects={[setInfos]}
            >
                <InputForm
                    id="bank_account"
                    value={infos.bank_account}
                    onChange={(e) => setInfos({ ...infos, bank_account: e.target.value })}
                    label="Số tài khoản (*)"
                    required
                />
                <DropdownForm
                    optionValue="name"
                    optionLabel="name"
                    value={infos.bank_name}
                    onChange={(e) => {
                        setInfos({ ...infos, bank_name: e.target.value })
                    }}
                    options={bank}
                    label="Ngân hàng (*)"
                />
                <InputForm
                    id="branch"
                    value={infos.branch}
                    onChange={(e) => setInfos({ ...infos, branch: e.target.value })}
                    label="Chi nhánh (*)"
                    required
                />
                <InputForm
                    id="holder_name"
                    value={infos.holder_name}
                    onChange={(e) => setInfos({ ...infos, holder_name: e.target.value })}
                    label="Tên tài khoản (*)"
                    required
                />
                <InputSwitchForm
                    label="Nhận giao dịch tự động"
                    id="active_payment"
                    value={infos.active_payment}
                    onChange={(e) => setInfos({ ...infos, active_payment: e.target.value })}
                    checked={infos.active_payment}
                />
                <InputSwitchForm
                    label="Tự động hạch toán"
                    id="status_payment_info"
                    value={infos.status_payment_info}
                    onChange={(e) => setInfos({ ...infos, status_payment_info: e.target.value })}
                    checked={infos.status_payment_info}
                />
            </FormUpdate>
        </div>
    )
}

export default BillingInfo