import { getData, postData } from '@/lib/request'


export const listAssetManager = (params) => getData('web2/asset/getListAsset', params)
export const listAssetListCirDist = (params) => getData('web2/asset/getListCirDist', params)
export const countAssetListCirDist = (params) => getData('web2/asset/countCirDist', params)
export const listGroup = (params) => getData('web2/asset/listProductGroup', params)
export const listCategory = (params) => getData('web2/asset/listProductCategory', params)
export const detailAsset = (params) => getData('web2/asset/detailAsset', params)
export const countAsset = (params) => getData('web2/asset/countAsset', params)
export const listUnit = (params) => getData('web2/asset/listUnit', params)
export const addAsset = (params) => postData('web2/asset/addAsset', params, true)
export const updateAsset = (params) => postData('web2/asset/updateAsset', params, true)
export const deleteAsset = (params) => postData('web2/asset/deleteAsset', params)
export const allocationAssetPost = (params) => postData('web2/asset/addCirDist', params, true)
export const renderQrApi = (params) => getData('web2/warehouse_receipt/renderQrCode', params)