import {
    Body,
    Columnz,
    DataTablez,
    GridForm,
    HeaderListForm,
    Inputz, StatusBodyV2,
    TimeBody,
    useGetParams
} from '@/components'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import { getArrId } from '@/utils'
import { confirmDialog } from 'primereact/confirmdialog'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { notifyPost } from '../../notification/api'
import { useListPost } from '../../notification/utils'
import { changeStatusVoteApi, deleteVoteApi } from '../api'
import { useListVoteApi } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm sự kiện bình chọn"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const ListVoted = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const { data, count } = useListVoteApi({ status: undefined, ...params, first: undefined })
    const [selectedProducts, setSelectedProducts] = useState([])
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            actionType: 'xóa',
            title: 'thông báo',
            action: async (ids) => await deleteVoteApi({ ids: getArrId(ids) }),
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            actionType: 'active',
            title: 'thông báo',
            action: async (ids) => await changeStatusVoteApi({ ids: getArrId(ids), status: 1 }),
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            actionType: 'inactive',
            title: 'thông báo',
            action: async (ids) => await changeStatusVoteApi({ ids: getArrId(ids), status: 0 }),
        },
    ]

    const dispatch = useDispatch()

    async function accept(id) {
        const response = await notifyPost({ id })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Gửi thông báo thành công!' }))
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục đặt lịch bình chọn?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => accept(id),
        })
    }

    const moreActions = [{ icon: 'pi pi-calendar', command: (id) => confirm(id) }]
    const posts = useListPost({ status: undefined })

    return (
        <div className="p-card px-4 pt-2 pb-4">
            <HeaderListForm title="Quản lí bình chọn" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="thông báo"
                totalRecords={count}
                params={params}
                setParams={setParams}
                route="/voting_list"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                actionsInfo={{
                    handleDelete: (id) => {
                        return { ids: [id] }
                    },
                    moreActions,
                    deleteAction: deleteVoteApi,
                }}
                basePermissions={['insert', 'detail', 'delete']}
                headerInfo={{ items }}
            >
                <Columnz field="title" header="Sự kiện" className="" />
                {/* Tách, lấy value của object trong mảng từ api */}
                <Columnz
                    className="w-2"
                    header="Bình chọn"
                    body={(rowData) => {
                        const options = JSON.parse(rowData.options)

                        // eslint-disable-next-line array-callback-return
                        return  options.map((option, i) => {
                            const ask = Object.values(option)
                            return (
                                <ul className="text-left">
                                    <li>
                                        {ask[0]}{ask[1] && <span className="text-primary">{` (${ask[1]})`}</span>}
                                    </li>
                                </ul>
                            )
                        })

                    }}
                />
                <Columnz
                    className="w-2"
                    body={(e) => Body({ data: posts, value: e.post_id, label: 'title' })}
                    header="Bài viết liên quan"
                />
                <Columnz header="Last update" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng thái"
                    body={(e) =>
                        StatusBodyV2({
                            e,
                            route: '/voting_list',
                            updateAction: changeStatusVoteApi,
                            handleUpdate: (id, status) => ({ ids: [id], status: Boolean(status) ? 0 : 1 }),
                            setParams,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}

export default ListVoted
