const AppFooter = () => {
    return (
        <div className="layout-footer">
            {/* <img src="" alt="Logo" height="20" className="mr-2" /> */}
            <span className="font-medium ml-2"></span>
        </div>
    )
}

export default AppFooter
