import { listTypePriceApi } from "@/api/projects/info"
import {
    Body,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    LoadDialog,
    StatusBody, useGetParams
} from '@/components'
import { listToast, status, typeVehiclesService } from '@/constants'
import { useGetApi } from "@/hooks"
import ImportService from "@/modules/accountant/building_services/screen/ImportService"
import { setToast } from '@/redux/features'
import { Button } from '@/uiCore'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { exportServiceApi, updateSortServiceSortToPaymentApi, updateStatusBuildingServiceApi } from '../api'
import { useCountBuildingService, useListBuildingService } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const [gridFormClass, setGridFormClass] = useState('lg:col-3');

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth < 1260) {
                setGridFormClass('lg:col-12');
            } else {
                setGridFormClass('lg:col-3');
            }
        };

        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('resize', handleScroll);
        };
    }, [])

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className={gridFormClass}>
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên dịch vụ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng Thái"
            />
            <Dropdownz
                value={filter.type}
                options={typeVehiclesService}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại dịch vụ"
            />
        </GridForm>
    )
}
const BuildingServices = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [reOrder, setReOrder] = useState(false)
    const totalRecords = useCountBuildingService({ status: undefined, ...params, first: undefined }) || 0
    const data = useListBuildingService({ status: undefined, ...params, first: undefined, reOrder: reOrder, type_service: 1 })
    const [productsReoder, setProductsReoder] = useState([])
    const logSortToPayment = useRef([])
    const isBack = useRef(false)
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const [visibleImport, setVisibleImport] = useState(false)
    const typePrices = useGetApi(listTypePriceApi, false, [])

    useEffect(() => {
        if (data) {
            logSortToPayment.current = []
            data.sort((a, b) => {
                if (a.index_accounting === null || a.index_accounting === 0) return 1
                if (b.index_accounting === null || b.index_accounting === 0) return -1
                return a.index_accounting - b.index_accounting
            })
            const copiedData = data.map((item, index) => ({
                ...item,
                newIndex: item.index_accounting,
            }))
            setProductsReoder(copiedData)
            logSortToPayment.current.push(copiedData)
        }
    }, [data])

    const handleChangeProductReorder = (product) => {
        let numIncrease = 1
        if (isBack.current === false) {
            product.forEach((items) => {
                items.newIndex = numIncrease++
            })
            setProductsReoder(product)
            logSortToPayment.current.push(productsReoder)
        }
        isBack.current = false
    }

    const handleBackForwardSort = ({ step }) => {
        if (step === 'back') {
            if (logSortToPayment.current.length > 0) {
                isBack.current = true
                setProductsReoder(logSortToPayment.current[logSortToPayment.current.length - 1])
                logSortToPayment.current.pop()
            }
        }
    }

    const handleSubmitReoder = () => {
        const arrDataChage = productsReoder.map((item, index) => ({
            index_accounting: item.newIndex,
            id: item.id,
        }))
        confirmDialog({
            message: `Xác nhận cập nhật ưu tiên hạch toán`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: async () => {
                await updateSortServiceSortToPaymentApi({ list: arrDataChage })
                    .then(setReOrder(true))
                    .then(dispatch(setToast({ ...listToast[0], detail: 'Sắp xếp thành công!' })))
                    .catch((e) => dispatch(setToast({ ...listToast[1], detail: 'Có lỗi xảy ra' })))
                setReOrder(false)
                logSortToPayment.current = []
            },
        })
    }

    const moreOptions = [
        {
            label: 'Sắp xếp dịch vụ ưu tiên hạch toán',
            icon: 'pi pi-sort-alt',
            command: () => {
                if (!reOrder) {
                    setReOrder(true)
                    return
                }
                handleSubmitReoder()
            },
        },
    ]

    const renderStepOrder = () => (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                left: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
            }}
        >
            <Button
                icon="pi pi-arrow-left"
                style={{ width: '150px' }}
                severity="info"
                aria-label="arrow"
                onClick={() => handleBackForwardSort({ step: 'back' })}
            />
        </div>
    )
    const columns = [
        { field: 'id', header: 'Mã dịch vụ' },
        { field: 'name', header: 'Tên dịch vụ' },
        { body: (e) => Body({ data: typePrices, value: e.bdc_price_type_id }), header: 'Loại dịch vụ' },
        { body: (e) => e.bdc_period_id + ' Tháng', header: 'Chu kì thu' },
        { field: 'budget_code', header: 'Mã ngân sách' },
        { field: 'code_receipt', header: 'Mã phí' },
        { field: 'number_apartment', header: 'Số căn hộ sử dụng dịch vụ' },
        { field: 'bill_date', header: 'Ngày đầu kì số liệu' },
        { field: 'ngay_chuyen_doi', header: 'Ngày chuyển đổi' },
        {
            body: (e) =>
                StatusBody({
                    e,
                    route: '/services',
                    updateAction: updateStatusBuildingServiceApi,
                }),
            header: 'Trạng thái',
        },
    ]

    const dynamicColumns = columns.map((col, i) => {
        return (
            <Columnz
                key={i}
                columnKey={col.field}
                field={col.field}
                header={col.header}
                body={col.body}
                sortable={col.sortable}
            />
        )
    })

    return (
        <div className="card">
            <HeaderListForm title="Danh sách dịch vụ tòa nhà" />
            {visibleImport && <ImportService visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}

            <LoadDialog visible={visible} />
            <Header setParams={setParams} />
            <DataTablez
                value={productsReoder}
                title="dịch vụ tòa nhà"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/services"
                actionsInfo
                headerInfo={{ moreOptions, setVisibleImport: setVisibleImport, exportApi: exportServiceApi }}
                basePermissions={["insert", "detail", "import", "export"]}
                reorderableRows
                onRowReorder={(e) => {
                    handleChangeProductReorder(e.value)
                }}
                noStt
            >
                {reOrder ? <Columnz rowReorder={reOrder} bodyStyle={{ width: '40px', minWidth: '40px' }} headerStyle={{ width: '40px', minWidth: '40px' }} tableStyle={{ color: 'blue' }} /> : <Columnz rowReorder={reOrder} style={{ width: '1rem', display: "none" }} tableStyle={{ color: 'blue' }} />}
                {dynamicColumns}
            </DataTablez>
            {reOrder && renderStepOrder()}
        </div>
    )
}

export default BuildingServices
