import { DropdownForm, FormUpdateDialog, InputForm } from '@/components'
import React, { useEffect, useState } from 'react'
import { addBlackListApi, updateBlackListApi } from '../api'
import { useDetailBlackList } from '../utils'
import { removePropObject } from '@/utils'
import { validate } from '@/lib'
import { removeUndefinedProps } from '@/utils'
import { statusLog, typeServiceNotification } from '@/constants'

const UpdateBuilding = (props) => {
    const { setVisible, setParams, visible } = props
    const blackListDetail = useDetailBlackList(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState({
        from: '',
        to: '',
        status: 0,
        type_service: '',
    })

    useEffect(() => {
        if (blackListDetail.id) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(blackListDetail),
            })
        }
    }, [blackListDetail])

    const handleData = () => {
        let info = { ...infos }
        if (info.type_service === '') return 'Vui lòng chọn kiểu dịch vụ!'
        if (info.status === 0) return 'Vui lòng chọn trạng thái!'
        if (validateInput(infos.from, infos.type_service) || validateInput(infos.from, infos.type_service))
            return 'Vui lòng nhập đúng định dạng'
        if (typeof visible === 'number') info = { ...removePropObject(info, blackListDetail), id: visible }
        return info
    }

    const validateInput = (value, type) => {
        if (type === 'email') {
            return !validate('email', value)
        } else if (type === 'sms') {
            return !validate('phone', value)
        } else return true
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            refreshObjects={[setInfos]}
            route="/block_list"
            title={(typeof visible === 'number' ? 'Cập nhật ' : 'Thêm mới ') + 'địa chỉ chặn'}
            actions={{ update: updateBlackListApi, add: addBlackListApi }}
        >
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <InputForm
                        id="from"
                        value={infos.from}
                        onChange={(e) => setInfos({ ...infos, from: e.target.value })}
                        label="Địa chỉ gửi (*)"
                        type={infos.type_service ? (infos.type_service === 'email' ? 'email' : 'phone') : 'text'}
                        required={validateInput(infos.from, infos.type_service)}
                    />

                    <InputForm
                        id="name"
                        value={infos.to}
                        onChange={(e) => setInfos({ ...infos, to: e.target.value })}
                        label="Địa chỉ chặn (*)"
                        type={infos.type_service ? (infos.type_service === 'email' ? 'email' : 'phone') : 'text'}
                        required={validateInput(infos.to, infos.type_service)}
                    />
                </div>
                <div className="col-12 lg:col-6">
                    <DropdownForm
                        id="type_service"
                        value={infos.type_service}
                        onChange={(e) => setInfos({ ...infos, type_service: e.target.value })}
                        options={typeServiceNotification}
                        label="Kiểu dịch vụ (*)"
                        disabled={typeof visible === 'number' ? true : false}
                    />
                    <DropdownForm
                        id="status"
                        value={infos.status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        options={
                            typeof visible === 'number'
                                ? statusLog
                                : statusLog.filter((log) => log.id !== '3' && log.id !== '5' && log.id !== '00')
                        }
                        label="Trạng thái (*)"
                    />
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateBuilding
