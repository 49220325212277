import {
    Columnz,
    DataTablez,
    GridForm,
    HeaderListForm,
    Inputz, StatusBodyV2,
    TimeBody,
    useGetParams
} from '@/components'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import { getArrId } from '@/utils'
import { confirmDialog } from 'primereact/confirmdialog'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeStatusPostApi, deletePostApi, notifyPost } from '../api'
import { useCountPost, useListPost } from '../utils'


const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tiêu đề"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const Notifications = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPost({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPost({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            actionType: 'xóa',
            title: 'thông báo',
            action: async (ids) => await deletePostApi({ list_id: getArrId(ids) }),
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            actionType: 'active',
            title: 'thông báo',
            action: async (ids) => await changeStatusPostApi({ list_id: getArrId(ids), active: 1 }),
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            actionType: 'inactive',
            title: 'thông báo',
            action: async (ids) => await changeStatusPostApi({ list_id: getArrId(ids), active: 0 }),
        },
    ]

    const dispatch = useDispatch()

    async function accept(id) {
        const response = await notifyPost({ id })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Gửi thông báo thành công!' }))
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }
    const confirm = (id) => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục gửi thông báo?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => accept(id),
        })
    }

    const moreActions = [{ icon: 'pi pi-bell', command: (id) => confirm(id) }]

    return (
        <div className="card">
            <HeaderListForm title="Danh sách thông báo" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="thông báo"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/posts"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                actionsInfo={{
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                    moreActions,
                    deleteAction: deletePostApi,
                }}
                basePermissions={["insert", "detail", "delete"]}
                headerInfo={{ items }}
            >
                <Columnz field="id" header="ID" />
                <Columnz field="title" header="Tiêu đề" />
                <Columnz field="hashtag" header="Hashtag" />
                <Columnz field="category_name" header="Danh mục" />
                <Columnz field="full_name" header="Người viết" />
                <Columnz body={e => TimeBody(e.created_at)} header="Thời gian tạo" />
                <Columnz header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' body={(e) => StatusBodyV2({ e, route: '/posts', updateAction: changeStatusPostApi, handleUpdate: (id, status) => ({ list_id: [id], active: Boolean(status) ? 0 : 1 }), setParams })} />
            </DataTablez>
        </div>
    )
}

export default Notifications
