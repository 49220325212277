import { getData, postData } from '@/lib/request'

export const listPromotionApi = (params) => getData('web2/promotion_management/getListPromotionV2', params)
export const countPromotionApi = (params) => getData('web2/promotion_management/countPromotionV2', params)
export const deletePromotionApi = (params) => postData('web2/promotion_management/deletePromotionV2', params)
export const detailPromotionApi = (params) => getData('web2/promotion_management/detailPromotionV2', params)
export const addPromotionApi = (params) => postData('web2/promotion_management/addPromotionV2', params)
export const updatePromotionApi = (params) => postData('web2/promotion_management/updatePromotionV2', params)

export const updatePromotionApi2 = (params) => postData('web2/promotion_management/updateStatusPromotionV2', params)
export const listServicesApi = (params) => getData('web2/info/getListService', params)
export const listPromotionApartApi = (params) =>
    getData('web2/promotion_management/getListPromotionApartmentV2', params)
export const listSevicesId = (params) => getData('web2/info/getListServiceApartment', params)
export const countPromotionApartApi = (params) => getData('web2/promotion_management/countPromotionApartmentV2', params)
export const deletePromotionApartApi = (params) =>
    postData('web2/promotion_management/deletePromotionApartmentV2', params)
export const detailPromotioApartApi = (params) =>
    getData('web2/promotion_management/detailPromotionApartmentV2', params)
export const addPromotionApartApi = (params) => postData('web2/promotion_management/addPromotionApartmentV2', params)
export const updatePromotionApartApi = (params) =>
    postData('web2/promotion_management/updatePromotionApartmentV2', params)

export const listPromotionV2Api = (params) =>
    getData('web2/info/getListPromotion', params)

export const importPromotionApaApi = (params) => postData('web2/promotion_management/importPromotionApartment', params, false, true)
