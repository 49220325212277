import { TabPanel, TabView } from 'primereact/tabview'
import { useParams } from 'react-router-dom'
import { useDetailWarehouse } from '../utils'
import InformationInventory from './InformationInventory'
import PurchaseInformation from './PurchaseInformation'


export default function UpdateInventoryManagement() {
      const { id } = useParams()

      const detail = useDetailWarehouse(id)

      return (
            <div className="card">
                  <TabView>
                        <TabPanel header="Thông tin chung">
                              <InformationInventory />
                        </TabPanel>
                        <TabPanel header="Lịch sử sản phẩm">
                              <PurchaseInformation product_id={detail.product_id} />
                        </TabPanel>


                  </TabView>
            </div>
      )
}