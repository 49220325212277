import { addToolUseApi, detailToolUseApi, updateToolUseApi } from '@/api'
import { DropdownForm, FormUpdateDialog, MultiSelectForm } from '@/components'
import { useGetApi } from '@/hooks'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'

const UpdateToolUse = (props) => {
    const { setVisible, setParams, visible, companies, tools } = props
    const detail = useGetApi(detailToolUseApi, { id: visible }, {})
    const [infos, setInfos] = useState({})

    useEffect(() => {
        if (detail) {
            setInfos({
                ...detail,
                bdc_company_id: detail.company_id
            })
        }
    }, [JSON.stringify(detail)])

    const handleData = () => {
        let info = { ...infos }
        if (!infos.bdc_company_id) return 'Vui lòng chọn công ty!'
        if (!(infos.tool_use_ids && infos.tool_use_ids[0])) return 'Vui lòng chọn quyền!'
        if (typeof visible === 'number' || typeof visible === 'string')
            info = { ...removePropObject(info, detail), id: visible }
        return info
    }

    return (
        <FormUpdateDialog
            title={
                (typeof visible === 'number' || typeof visible === 'string' ? 'Chi tiết ' : 'Thêm mới ') +
                'quyền sử dụng'
            }
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            route={'/tool_use'}
            actions={{ add: addToolUseApi, update: updateToolUseApi }}
        >
            <DropdownForm
                id="bdc_company_id"
                value={infos.bdc_company_id}
                options={companies}
                onChange={(e) => setInfos({ ...infos, bdc_company_id: e.target.value })}
                label="Công ty (*)"
            />
            <MultiSelectForm
                id="tool_use_ids"
                value={infos.tool_use_ids}
                options={tools}
                onChange={(e) => setInfos({ ...infos, tool_use_ids: e.target.value })}
                label="Danh sách quyền (*)"
            />
        </FormUpdateDialog>
    )
}

export default UpdateToolUse
