import React, { useEffect, useState } from 'react'
import {
    InputForm,
    FormUpdateDialog,
    DropdownForm,
    UploadFile,
} from '@/components'
import { removePropObject, removeUndefinedProps } from '@/utils'
import { addManagementDocsApi, updateManagementDocsApi } from '../api'
import { useDetailManagementDocs } from '../utils'

function UpdateApartmentDocs(props) {
    const [file, setFile] = useState(null)
    const { visible, setVisible, setParams, apartments, buildings, apartment_id, documentClassification } = props
    const [infos, setInfos] = useState({ name: '', key_search: '', department_backup: '', department_store: '', })
    const detailDocsApartment = useDetailManagementDocs(typeof visible === 'number' ? visible : undefined)
    const buildingPlaceId = apartments?.find(b => b.id === apartment_id  )

    useEffect(() => {
        if (detailDocsApartment.id) {
            const building_place_id = apartments.find(a => a.id === detailDocsApartment.from_id).building_place_id
            setInfos({
                ...infos,
                ...removeUndefinedProps(detailDocsApartment),
                building_id: building_place_id,
                document_type: detailDocsApartment.document_type
            })
            if (detailDocsApartment.file) setFile(detailDocsApartment.file.replace(/"/g, ''))
        }
    }, [detailDocsApartment])

    useEffect(() => {
        if (apartment_id) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(detailDocsApartment),
                from_id: apartment_id,
                building_id: buildingPlaceId.building_place_id
            })
            if (detailDocsApartment.file) setFile(detailDocsApartment.file.replace(/"/g, ''))
        }
    }, [apartment_id])

    const handleData = () => {
        let info = { ...infos, from_type: 1, type: 1, parent_id: 0, level: 0 }
        if (file) info.file = file
        else return "Vui lòng chọn file!"
        if(!info.building_id) return "Vui lòng chọn tòa nhà!"
        if(!info.from_id) return "Vui lòng chọn căn hộ!"
        if (typeof visible === 'number') info = { ...removePropObject(infos, detailDocsApartment), id: visible, file: info.file }
        return info
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Cập nhật' : 'Thêm mới') + ' tài liệu căn hộ'}
            setParams={setParams}
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            refreshObjects={[setInfos]}
            actions={{ add: addManagementDocsApi, update: updateManagementDocsApi }}
        >
            <div className="bg-color grid grid-column">
                <div className='col-6'>
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên tài liệu (*)"
                        required
                    />
                    <DropdownForm
                        id="document_type"
                        value={infos.document_type}
                        onChange={(e) => setInfos({ ...infos, document_type: e.target.value })}
                        label="Phân loại"
                        options={documentClassification}
                    />
                    <DropdownForm
                        id="building_id"
                        value={infos.building_id}
                        label="Tòa nhà"
                        disabled={typeof visible === 'number' || apartment_id}
                        options={buildings}
                        onChange={(e) => {
                            if (e.target.value) {
                                setInfos({ ...infos, building_id: e.target.value, from_id: null })
                            } else {
                                setInfos({ ...infos, building_id: null, from_id: null })
                            }
                        }}
                    />
                    <DropdownForm
                        id="from_id"
                        value={infos.from_id}
                        label="Căn hộ"
                        options={apartments.filter(a => a.building_place_id === infos.building_id)}
                        onChange={(e) => setInfos({ ...infos, from_id: e.target.value })}
                        disabled={typeof visible === 'number' || apartment_id}
                    />
                </div>
                <div className='col-6'>
                    <InputForm
                        id="department_store"
                        value={infos.department_store}
                        onChange={(e) => setInfos({ ...infos, department_store: e.target.value })}
                        label="Bộ phận lưu trữ (*)"
                        required
                    />
                    <InputForm
                        id="department_backup"
                        value={infos.department_backup}
                        onChange={(e) => setInfos({ ...infos, department_backup: e.target.value })}
                        label="Bộ phận sao lưu (*)"
                        required
                    />
                    <InputForm
                        id="key_search"
                        value={infos.key_search}
                        onChange={(e) => setInfos({ ...infos, key_search: e.target.value })}
                        label="Từ khóa tìm kiếm (*)"
                        required
                    />
                    <UploadFile file={file} setFile={setFile} target="_blank" />

                </div>
            </div>
        </FormUpdateDialog>
    )
}
export default UpdateApartmentDocs