import { addPartnerApi, detailPartnerApi, updatePartnerApi } from '@/api'
import { FormUpdate, InputForm, InputTextareaForm } from '@/components'
import { useGetApi } from '@/hooks'
import { removePropObject, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const initInfos = { name: '', phone: '', email: '', address: '', note: '', status: true }

const UpdatePartner = () => {
    const { id } = useParams()
    const [infos, setInfos] = useState(initInfos)
    const detail = useGetApi(detailPartnerApi, { id }, {})

    useEffect(() => {
        if (detail.id) setInfos({ ...infos, ...removeUndefinedProps(detail), status: Number(detail.status) === 1 })
    }, [detail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, detail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="đối tác"
            handleData={handleData}
            route="/partner"
            actions={{ add: addPartnerApi, update: updatePartnerApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên đối tác (*)"
                            required
                        />
                        <InputForm
                            id="contact"
                            value={infos.contact}
                            onChange={(e) => setInfos({ ...infos, contact: e.target.value })}
                            label="Đơn vị cung cấp (*)"
                            required
                        />
                        <InputForm
                            id="address"
                            value={infos.address}
                            onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                            label="Địa chỉ (*)"
                            required
                        />
                        <InputForm
                            id="position"
                            value={infos.position}
                            onChange={(e) => setInfos({ ...infos, position: e.target.value })}
                            label="Số điện thoại (*)"
                            required
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="mobile"
                            value={infos.mobile}
                            onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                            label="Số điện thoại (*)"
                            type="mobile"
                            required
                        />
                        <InputForm
                            id="email"
                            value={infos.email}
                            onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                            label="Email (*)"
                            type="email"
                            required
                        />
                        <InputForm
                            id="representative"
                            value={infos.representative}
                            onChange={(e) => setInfos({ ...infos, representative: e.target.value })}
                            label="Người đại diện (*)"
                            required
                        />
                    </div>
                </div>
                <InputTextareaForm
                    id="description"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                    label="Ghi chú"
                />
            </div>
        </FormUpdate>
    )
}

export default UpdatePartner
