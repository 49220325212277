import {
    DropdownForm,
    Editorz,
    FormUpdate,
    InputForm,
    InputSwitchForm,
    InputTextareaForm,
    UploadFile,
} from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addFormTemplateApi, updateFormTemplateApi } from '../api'
import { useDetailFormTemplate } from '../utils'
import { formTemplate } from '@/constants'
import { info } from 'sass'

const initInfos = {
    content: '',
    hint: '',
    title: formTemplate[0].name,
    status: true,
}

const UpdateFormTemplate = () => {
    const { id } = useParams()
    const formTemplateDetail = useDetailFormTemplate(id)
    const [file, setFile] = useState(null)
    const [params, setParams] = useState()
    const [infos, setInfos] = useState(initInfos)

    useEffect(() => {
        if (formTemplateDetail.id)
            setInfos({
                ...infos,
                ...formTemplateDetail,
                status: Number(formTemplateDetail.status) !== 0,
                description: formTemplateDetail.hint || '',
            })
        if (formTemplateDetail.url) {
            if (formTemplateDetail.url.includes('.jpg')) {
                setFile({ preview: formTemplateDetail.url, type: 'image', name: 'formTemplateDetail.url' })
            } else setFile(formTemplateDetail.url)
        }
    }, [formTemplateDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0, params }
        if (file) info.file = file
        if (!infos.title) return 'Vui lòng chọn mẫu form!'
        if (file === formTemplateDetail.url) delete info.file
        if (info.description === formTemplateDetail.hint) delete info.description

        if (Number(id)) info = { ...removePropObject(info, formTemplateDetail), id: id }
        return info
    }
    useEffect(() => {
        setParams(formTemplate.find(d => d.name === infos.title));
    }, [infos.title]);

    return (
        <FormUpdate
            checkId={Number(id)}
            title="mẫu form"
            handleData={handleData}
            route="/form_setup"
            actions={{ add: addFormTemplateApi, update: updateFormTemplateApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            value={infos.title}
                            options={formTemplate}
                            optionValue="name"
                            onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                            label="Mẫu form (*)"
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputSwitchForm
                            id="status"
                            checked={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        />
                    </div>
                </div>
                <UploadFile file={file} setFile={setFile} />
                <InputForm value={params && params.params} label="Tham số truyền vào" disabled />
                <InputTextareaForm
                    id="description"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                    label="Mô tả"
                />
                <Editorz
                    data={infos.content}
                    setData={(e) => setInfos({ ...infos, content: e })}
                    label="Nội dung"
                    height="600"
                />
            </div>
        </FormUpdate>
    )
}

export default UpdateFormTemplate
