import { useEffect, useState } from "react"
import { checkDoubleUserShift, countShift, detailShift, listShift } from "../api"

export const useListShift = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await listShift({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useCheckShift = (params) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await checkDoubleUserShift({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}
export const useDetailShift = (id, render) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await detailShift({ id, })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            if (id) fetchData()

      }, [JSON.stringify(id), render])
      return data
}
export const useCountShift = (params) => {
      const [data, setData] = useState(0)
      async function fetchData() {
            const response = await countShift({ status: 1, ...params })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            fetchData()
      }, [JSON.stringify(params)])
      return data
}