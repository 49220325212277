import { countExchangeApi, deleteExchangeApi, listExchangeApi, updateExchangeApi } from '@/api'
import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { status, units } from '@/constants'
import { useGetApi } from '@/hooks'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã phòng ban"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.type}
                options={units.slice(0, -1)}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Exchanges = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listExchangeApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countExchangeApi, { ...params, first: undefined }, 0)
    const users = useSelector((state) => state.users)

    return (
        <div className="card">
            <HeaderListForm title="Danh sách phòng ban" />
            <Header setParams={setParams} />
            <DataTablez
                title="phòng ban"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/department"
                headerInfo
                actionsInfo={{ deleteAction: deleteExchangeApi }}
                basePermissions={["insert", "detail", "delete"]}
            >
                <Columnz body={(e) => Body({ data: units, value: e.type })} header="Phân loại" />
                <Columnz field="name" header="Tên phòng ban" />
                <Columnz field="code_department" header="Mã phòng ban" />
                <Columnz field="address" header="Địa chỉ" bodyStyle={{ minWidth: '16rem' }} />
                <Columnz field="phone_number" header="Hotline" />
                <Columnz field="email" header="Email liên hệ" />
                <Columnz
                    body={(e) =>
                        Body({ data: users, value: Number(e.main_user_id), key: 'user_id', label: 'full_name' })
                    }
                    header="Trưởng bộ phận dự án"
                />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/department', updateAction: updateExchangeApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Exchanges
