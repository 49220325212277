import {
      Columnz,
      DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, LoadDialog, StatusBody, useGetParams
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteAssetMix, updateAssetMix } from '../api'
import { useCountAssetMix, useListAssetMix } from '../utils'
import UpdateAssetGroup from './UpdateAssetMixGroup'

const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({ key_search: '' })



      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
                  <Inputz
                        value={filter.key_search}
                        placeholder="Nhập tên nhóm sản phẩm"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
                  />
                  <Dropdownz
                        value={filter.status}
                        options={status}
                        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                        placeholder="Trạng thái"
                  />
            </GridForm>
      )
}

export default function AssetMixGroup() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useListAssetMix({ status: undefined, ...params, first: undefined })
      const totalRecords = useCountAssetMix({ status: undefined, ...params, first: undefined }) || 0
      const [visible, setVisible] = useState(false)
      const users = useSelector((state) => state.users)
      const CategoryProductBody = (rowData) => {
            const type = rowData.type
            return (
                  <>
                        {
                              type === 'tieu_hao' ? (
                                    <div>
                                          Sản phẩm tiêu hao
                                    </div>
                              ) : type === 'co_dinh' ? (
                                    <div>
                                          Sản phẩm cố định
                                    </div>
                              ) : type === 'thiet_bi' ? (
                                    <div>
                                          Thiết bị máy móc tòa nhà
                                    </div>
                              ) : (
                                    <div>
                                          Khác
                                    </div>
                              )
                        }
                  </>
            )
      }
      return (
            <>
                  {visible && <UpdateAssetGroup setParams={setParams} visible={visible} setVisible={setVisible} />}
                  <LoadDialog visible={visible} />

                  <HeaderListForm title="Thông tin nhóm sản phẩm" />
                  <Header setParams={setParams} />
                  <DataTablez
                        value={data}
                        title="nhóm sản phẩm"
                        totalRecords={totalRecords}
                        params={params}
                        setParams={setParams}
                        route="/asset"
                        setVisibledDialog={setVisible}
                        headerInfo
                        actionsInfo={{ deleteAction: deleteAssetMix }}

                        basePermissions={["insert", "detail",]}

                  >
                        <Columnz field="name" header="Tên nhóm sản phẩm" />
                        <Columnz body={(e) => CategoryProductBody(e)} header="Phân loại  sản phẩm" />
                        <Columnz
                              body={(e) => {
                                    const user = users.find(u => u.user_id === e.user_id)
                                    return user?.full_name ? user?.full_name : 'Admin'
                              }}
                              header="Người tạo"
                        />
                        <Columnz field="note" header="Mô tả" />
                        <Columnz
                              header="Trạng Thái"
                              headerStyle={{ padding: 'auto', textAlign: 'center' }}
                              className='text-center'
                              body={(e) => StatusBody({ e, route: '/asset_mix_management', updateAction: updateAssetMix })}
                        />

                  </DataTablez>
            </>
      )
}
