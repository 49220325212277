import { Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { GirdChart, RoundChartFlexCol } from '@/components/Chart'
import { typeBuidingServices } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetCashFlow } from '../../../dashboard/utils'
import { useGetRevenueReport } from '../utils'
const Header = ({ setParams }) => {

    const [filter, setFilter] = useState({ key_search: '' })
    const building_place_id = useSelector((state) => state.buildings)
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.type_service}
                options={typeBuidingServices}
                onChange={(e) => setFilter({ ...filter, type_service: e.target.value })}
                placeholder="Chọn loại dịch vụ"
                className="mb-3"
            />
            <Dropdownz
                value={filter.building_place}
                options={building_place_id}
                onChange={(e) => setFilter({ ...filter, building_place: e.target.value, apartment_id: undefined })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={filter.building_place ? apartments.filter(a => a.building_place_id === filter.building_place) : apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
        </GridForm>
    )
}
function RevenueAggregate() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const revenueReport = useGetRevenueReport(params)
    const cashFlow = useGetCashFlow(params)
    return (
        <>
            <div className="p-card p-3">
                <div className="pb-3">
                    <HeaderListForm title="Báo cáo thống kê" />
                    <Header setParams={setParams} />
                </div>

                <div class="grid justify-content-between border-top-1 border-400">
                    <div class="col-12 md:col-8 lg:col-8">
                        <GirdChart
                            title={'Báo cáo doanh thu'}
                            color={['#76c1f3', '#56a2f7', '#220186']}
                            label={['Thanh toán', 'Tiền thừa', 'Phát sinh']}
                            dataChart={revenueReport}
                            keyData={['paid_by_cycle_name', 'coin', 'sumery']}
                            labelChart="cycle_name"
                            maxYValue={''}
                            chartType={'bar'}
                            stepSize={''}
                            yAxisText="VND"
                            aspectRatio={'0.8'}
                        />
                    </div>
                    <div class="col-12 md:col-4 lg:col-4">
                        <RoundChartFlexCol
                            title="Tổng hợp dòng tiền"
                            color={['#76c1f3', '#56a2f7', '#220186']}
                            type="pie"
                            data={cashFlow}
                            labels={['Chuyển khoản', 'Tiền mặt', 'Khác']}
                            descriptions={['', '', '', '', '', '']}
                            keyChart="cost"
                            aspectRatio={'0.9'}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RevenueAggregate
