import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputSwitchForm, DropdownForm } from '@/components'
import EditorV2 from '@/components/Editorz'
import { useDetailReceptForm } from '../utils'
import { addReceptFormApi, updateReceptFormApi } from '../api'
import { customer_payments_type } from '@/constants'

const UpdateReceptForm = () => {
    const { id } = useParams()
    const receptFormDetail = useDetailReceptForm(id)

    const [infos, setInfos] = useState({
        title: '',
        category: '',
        content: '',
        status: false,
    })

    useEffect(() => {
        if (receptFormDetail.id)
            setInfos({ ...infos, ...receptFormDetail, status: receptFormDetail.status === 0 ? false : true })
    }, [receptFormDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (!infos.title) return 'Vui lòng nhập hình thức thu tiền'
        if (!infos.category) return 'Vui lòng chọn phiếu kế toán'
        if (Number(id)) info = { ...removePropObject(info, receptFormDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="hình thức phiếu thu"
            handleData={handleData}
            route="/recept_form"
            actions={{ add: addReceptFormApi, update: updateReceptFormApi }}
            refreshObjects={[setInfos]}
        >
            <InputForm
                id="title"
                value={infos.title}
                onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                label="Hình thức thu tiền (*)"
                required
            />
            <DropdownForm
                id="category"
                value={infos.category}
                label="Phiếu kế toán"
                options={customer_payments_type}
                onChange={(e) => setInfos({ ...infos, category: e.target.value })}
            />
            <EditorV2
                id="content"
                data={infos.content}
                setData={(e) => setInfos({ ...infos, content: e })}
                label="Mô tả"
            />
            <div className="mb-5"></div>
            <InputSwitchForm
                id="status"
                checked={infos.status}
                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                label="Trạng thái"
            />
        </FormUpdate>
    )
}

export default UpdateReceptForm
