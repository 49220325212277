import { Columnz, DataTablezV2, HeaderListForm, TimeBody, useGetParams } from '@/components'
import { Column, DataTable } from '@/uiCore'
import { useState } from 'react'
import { useListHistory } from '../utils'

const UpdateApartLog = (id) => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListHistory({ apartment_id: id.id, status: undefined, ...params, first: undefined })
    return (
       <div className="card">
           <HeaderListForm title="Nhật ký căn hộ"/>
           <DataTablezV2 title="bản ghi" value={data} total params={params} setParams={setParams}>
               <Columnz body={e => TimeBody(e.date_use, "date")} header="Thời gian" />
               <Columnz
                   header="Nhật ký ra vào"
                   body={(rowData) => (
                       <DataTable value={rowData.list_change}>
                           <Column field="full_name" header="Tên cư dân"></Column>
                           <Column
                               field="type"
                               header="Trạng Thái"
                               headerStyle={{ padding: 'auto', textAlign: 'center' }}

                               className='text-center'
                               body={(rowData) =>
                                   rowData.type === 'out' ? (
                                       <span
                                           className="text-center font-bold text-white text-xs"
                                           style={{
                                               backgroundColor: '#E74C3C',
                                               borderRadius: '4px',
                                               padding: '4px',
                                               minWidth: '100px',
                                               display: 'inline-block',
                                           }}
                                       >
                                        Ra
                                    </span>
                                   ) : (
                                       <span
                                           className="text-center font-bold text-white text-xs"
                                           style={{
                                               backgroundColor: '#30CB83',
                                               borderRadius: '4px',
                                               padding: '4px',
                                               minWidth: '100px',
                                               display: 'inline-block',
                                           }}
                                       >
                                        Vào
                                    </span>
                                   )
                               }
                           />
                       </DataTable>
                   )}
               />
           </DataTablezV2>
       </div>
    )
}

export default UpdateApartLog
