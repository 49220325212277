import {Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, useGetParams} from '@/components'

import {useState} from 'react'


import {date_work, status} from '@/constants'
import {checkJson} from '@/utils'
import {deleteShift, updateShift} from '../api'
import {useCountShift, useListShift} from '../utils'
import {useSelector} from 'react-redux'
import {listExchangeV2Api} from '@/api'
import {useGetApi} from '@/hooks'

const Header = ({setParams}) => {
    const [filter, setFilter] = useState({})
    const users = useSelector(state => state.users);
    const exchanges = useGetApi(listExchangeV2Api, {}, [])


    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz value={filter.key_search}
                    placeholder="Tìm kiếm theo tên, mã ca"
                    onChange={(e) => setFilter({...filter, key_search: e.target.value})}/>
            <Dropdownz
                value={filter.department_id}
                onChange={(e) => setFilter({...filter, department_id: e.target.value})}
                placeholder="Phòng ban"
                options={exchanges}
            />
            <Dropdownz
                value={filter.user_id}
                onChange={(e) => setFilter({...filter, user_id: e.target.value})}
                placeholder="Nhân viên"
                options={users.filter(u => u.department_id)}
                optionLabel="full_name"
                optionValue="user_id"
            />
            <Dropdownz
                value={filter.status}
                onChange={(e) => setFilter({...filter, status: e.target.value})}
                placeholder="Trạng thái"
                options={status}
            />
        </GridForm>
    )
}

function ShiftManagement() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListShift({status: undefined, ...params, first: undefined})

    return (
        <div className='card'>
            <HeaderListForm title="Danh sách ca làm việc"/>
            <Header setParams={setParams}/>
            <DataTablez
                value={data.data}
                title="ca làm việc"
                totalRecords={data.count}
                params={params}
                setParams={setParams}
                route="/shift_management"
                headerInfo
                actionsInfo={{deleteAction: deleteShift}}
                basePermissions={["insert", "detail", "delete"]}
            >
                <Columnz field="code" header="Mã ca"/>
                <Columnz field="name" header="Tên ca"/>
                <Columnz field="start" header="Giờ vào"/>
                <Columnz field="end" header="Giờ ra"/>
                <Columnz field="number_work" header="Tổng giờ"/>
                <Columnz field="total_work" header="Tổng công"/>
                <Columnz
                    body={(rowData) => {
                        let dates = checkJson(rowData.dates)
                        if (dates?.length > 0) {
                            return <div>
                                {dates.map((d, index) => {
                                    if (d) {
                                        const z = date_work.find(w => w.id === d)?.name || ""
                                        if (z) {
                                            return <span key={index} style={{
                                                backgroundColor: "#dee2e6",
                                                borderRadius: "8px",
                                                padding: "4px 8px",
                                                color: "#495057",
                                                margin: "0 4px"
                                            }}>{z}</span>
                                        }
                                    }
                                })}
                            </div>
                        }
                    }}
                    header="Ngày làm việc trong tuần"/>
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{padding: 'auto', textAlign: 'center'}}
                    className='text-center'
                    body={(e) => StatusBody({e, route: '/shift_management', updateAction: updateShift})}
                />
            </DataTablez>
        </div>
    )
}

export default ShiftManagement
