import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { InputForm, InputSwitchForm, FormUpdate, CalendarForm, DropdownForm } from '@/components'
import { UploadImg } from '@/components'
import { databaseDate } from '@/lib'
import { removeUndefinedProps } from '@/utils'
import { useDispatch } from 'react-redux'
import { setUsers } from '@/redux/features/users'
import { addUserApi, detailUserApi, listExchangeV2Api, listGroupV2Api, listUserV2Api, updateUserApi } from '@/api'
import { useGetApi } from '@/hooks'
import { work_position } from '@/constants'
import {listPosition} from "@/modules/hrm/position_management/api";

const initInfos = {
    status: true,
}
const UpdateUser = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const [infos, setInfos] = useState(initInfos)
    const detail = useGetApi(detailUserApi, { id }, {})
    const [avatar, setAvatar] = useState(null)
    const exchanges = useGetApi(listExchangeV2Api, {}, [])
    const groups = useGetApi(listGroupV2Api, { department_id: infos.department_id }, [])
    const position = useGetApi(listPosition)

    useEffect(() => {
        if (detail.id)
            setInfos({
                ...infos,
                ...removeUndefinedProps(detail),
                status: Number(detail.status) === 1,

                birthday: detail.birthday ? new Date(detail.birthday) : '',
            })
        if (detail.avatar && detail.avatar !== JSON.stringify([])) setAvatar(detail.avatar)
    }, [detail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        info.birthday = info.birthday ? databaseDate(info.birthday, false, 'date') : undefined
        if (avatar) info.avatar = avatar
        else info.avatar = []
        if (!info.department_id) return 'Vui lòng chọn phòng ban'
        if (Number(id)) info = { ...removePropObject(info, detail), id: id }
        if (!infos.group_id && detail.group_id) info.group_id = 0
        info.type_user = "user"
        return info
    }
    const handleAfterCallApi = async () => {
        const getUsers = await listUserV2Api()
        if (getUsers.data && getUsers.data.status) {
            const users = getUsers.data.data
            dispatch(setUsers(users))
        }
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="người dùng"
            handleData={handleData}
            route="/user"
            actions={{ add: addUserApi, update: updateUserApi }}
            handleAfterCallApi={handleAfterCallApi}
        >
            <div className="card">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-3">
                        <div className="card bg-color">
                            <UploadImg image={avatar} setImage={setAvatar} title="Ảnh đại diện" />
                        </div>
                    </div>
                    <div className="col-12 lg:col-9 ">
                        <div className="card bg-color">
                            <div className="grid grid-form">
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="full_name"
                                        value={infos.full_name}
                                        onChange={(e) => setInfos({ ...infos, full_name: e.target.value })}
                                        label="Tên nhân viên (*)"
                                        required
                                    />
                                    <InputForm
                                        id="code"
                                        value={infos.code}
                                        onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                        label="Mã nhân viên (*)"
                                        required
                                    />
                                    <InputForm
                                        id="email"
                                        value={infos.email}
                                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                        label="Email (*)"
                                        type="email"
                                        required
                                        disabled={id}
                                    />
                                    <InputForm
                                        id="phone"
                                        value={infos.phone}
                                        onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                                        label="Số điện thoại (*)"
                                        type="phone"
                                        required
                                        disabled={id}
                                    />
                                    <CalendarForm
                                        id="birthday"
                                        label="Ngày sinh"
                                        value={infos.birthday}
                                        onChange={(e) => setInfos({ ...infos, birthday: e.target.value })}
                                    />
                                </div>
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="address"
                                        value={infos.address}
                                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                        label="Địa chỉ"
                                    />
                                    <InputForm
                                        id="cmt_number"
                                        value={infos.cmt_number}
                                        onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                                        label="Số chứng minh thư"
                                        type="number"
                                    />
                                    <InputForm
                                        id="cmt_address"
                                        value={infos.cmt_address}
                                        onChange={(e) => setInfos({ ...infos, cmt_address: e.target.value })}
                                        label="Nơi cấp"
                                    />
                                    <DropdownForm
                                        value={infos.work_position
                                        }
                                        onChange={(e) => setInfos({
                                            ...infos, work_position: e.target.value
                                        })}
                                        label="Chức vụ (*)"
                                        options={position}
                                    />
                                    <DropdownForm
                                        id="department_id"
                                        value={infos.department_id}
                                        onChange={(e) =>
                                            setInfos({ ...infos, department_id: e.target.value, group_id: undefined })
                                        }
                                        options={exchanges}
                                        label="Phòng ban (*)"
                                    />
                                    <DropdownForm
                                        id="group_id"
                                        value={infos.group_id}
                                        onChange={(e) => setInfos({ ...infos, group_id: e.target.value })}
                                        options={groups}
                                        label="Bộ phận"
                                    />
                                    <InputSwitchForm
                                        checked={infos.status}
                                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateUser
