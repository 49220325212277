import moment from 'moment'
import { classNames } from 'primereact/utils'
import { Fragment } from 'react'
import Calendar from '../../parking_check/screens/ParkingImage'
export const Calendarz = (props) => {
    const { label, className, ...inputprop } = props
    return (
        <div className={classNames('col-12 mb-3 md:col-6 lg:col-3', className)}>
            <Calendar
                showIcon
                readOnlyInput
                showButtonBar
                dateFormat="dd/mm/yy"
                placeholder={label}
                className="w-full"
                style={{ minHeight: '50px' }}
                {...inputprop}
            />
        </div>
    )
}
export const TimeBody = (value) => {
    if (value) return <Fragment>{moment.unix(value).format('DD/MM/YYYY')}</Fragment>
}
