import { countPermissionApi, deletePermissionApi, listPermissionApi, updatePermissionApi } from '@/api'
import { Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { status } from '@/constants'
import { useGetApi } from '@/hooks'
import { useState } from 'react'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Permission = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listPermissionApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countPermissionApi, { ...params, first: undefined }, 0)

    return (
        <div className="card">
            <HeaderListForm title="Danh sách nhóm quyền" />
            <Header setParams={setParams} />
            <DataTablez
                title="nhóm quyền"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/permission"
                headerInfo
                actionsInfo={{ deleteAction: deletePermissionApi }}
                basePermissions={["insert", "detail", "delete"]}
            >
                <Columnz field="name" header="Tên nhóm quyền" />
                <Columnz header="Thời gian khởi tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    field="status"
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/permission', updateAction: updatePermissionApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Permission
