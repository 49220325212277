import { DropdownForm, FormUpdateDialog, InputForm, InputSwitchForm } from '@/components'
import React, { useEffect, useState } from 'react'
import { removePropObject } from '@/utils'
import { removeUndefinedProps } from '@/utils'
import { addSubdivisionApi, detailSubdivisionApi, listProjectApi, updateSubdivisonApi } from '@/api'
import { useGetApi } from '@/hooks'

const UpdateSubdivisionList = (props) => {
    const { setVisible, setParams, visible } = props
    const projects = useGetApi(listProjectApi, {}, [])
    const detail = useGetApi(detailSubdivisionApi, { id: typeof visible === 'number' ? visible : undefined }, {})
    const [infos, setInfos] = useState({
        name: '',
        code: '',
        status: true,
    })

    useEffect(() => {
        if (detail.id) setInfos({ ...infos, ...removeUndefinedProps(detail), status: Number(detail.status) !== 0 })
    }, [detail])

    const handleData = () => {
        let info = { ...infos, status: convertStatusToNumber(infos.status) }
        info = { ...removePropObject(info, detail), id: detail.id }
        return info
    }

    const convertStatusToNumber = (status) => {
        if (status === true) {
            return 1
        } else if (status === false) {
            return 0
        }
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            refreshObjects={[setInfos]}
            route="/subdivision"
            title={(typeof visible === 'number' ? 'Cập nhật ' : 'Thêm mới ') + 'phân khu'}
            actions={{ add: addSubdivisionApi, update: updateSubdivisonApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên phân khu"
                        />
                        <InputForm
                            id="code"
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã phân khu"
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="type_service"
                            value={infos.building_id}
                            onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                            options={projects}
                            label="Dự án"
                        />

                        <InputSwitchForm
                            id="status"
                            checked={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                            label="Trạng thái phân khu"
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateSubdivisionList
