const { postData, getData } = require('@/lib/request')

export const cashFlow = (params) => getData('web2/dash_board/getCashFlow', params)
export const getOpinions = (params) => getData('web2/dash_board/getOpinions', params)
export const getStatVehicle = (params) => getData('web2/dash_board/getStatVehicleReg', params)
export const getCountVehicle = (params) => getData('web2/dash_board/getStatVehicle', params)
export const getStatBuilding = (params) => getData('web2/dash_board/getStatBuilding', params)
export const getNotifyEvent = (params) => getData('web2/dash_board/getNotifyEvent', params)
export const residentPost = (params) => getData('web2/dash_board/residentPost', params)
export const opinionResident = (params) => getData('web2/dash_board/opinionResident', params)

export const getBalanceChange = (params) => getData('web2/statistic_report/balanceChange', params)
// Tổng hợp doanh thu
export const getTotalRevenue = (params) => getData('web2/statistic_report/getStatPayment', params)
