import { TabPanel, TabView } from 'primereact/tabview'
import GroupApartment from './GroupAparment'
import ListApartment from './ListApartment'

const Apartment = () => {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Danh sách căn hộ">
                    <ListApartment />
                </TabPanel>
                <TabPanel header="Danh sách nhóm căn hộ">
                    <GroupApartment />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default Apartment
