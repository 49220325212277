import { addCompanyApi, detailCompanyApi, updateCompanyApi } from '@/api'
import { FormUpdate, InputForm, InputSwitchForm, InputTextareaForm, UploadImg } from '@/components'
import { useGetApi } from '@/hooks'
import { validate } from '@/lib'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const initInfos = {
    name: '',
    address: '',
    email: '',
    phone_number: '',
    description: '',
    status: true,
}

const UpdateCompany = () => {
    const { id } = useParams()
    const [infos, setInfos] = useState(initInfos)
    const detail = useGetApi(detailCompanyApi, { id }, {})
    const [logo, setLogo] = useState([])

    useEffect(() => {
        if (detail.id) {
            setInfos({ ...infos, ...removeUndefinedProps(detail), status: Number(detail.status) !== 0 })
            if (detail.logo) {
                const newLogo = checkJson(detail.logo) || detail.logo
                if (newLogo && newLogo[0]) {
                    setLogo(newLogo)
                }
            }
        }
    }, [detail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (infos.email) if (!validate('email', info.email)) return 'Email không đúng định dạng'
        if (logo) info.logo = logo || null
        if (infos.phone_number) {
            if (infos.phone_number.startsWith('84')) info.phone_number = info.phone_number.replace('84', '0')
            if (!validate('phone', info.phone_number)) return 'Số điện thoại không đúng định dạng'
        }
        if (Number(id)) info = { ...removePropObject(info, detail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="công ty"
            handleData={handleData}
            route="/company"
            actions={{ add: addCompanyApi, update: updateCompanyApi }}
        >
            <div className="card">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-3">
                        <div className="card bg-color">
                            <UploadImg image={logo} setImage={setLogo} title="Logo Công ty" />
                        </div>
                    </div>
                    <div className="col-12 lg:col-9">
                        <div className="card bg-color">
                            <div className="grid grid-form">
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="name"
                                        value={infos.name}
                                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                        label="Tên công ty (*)"
                                        required
                                    />
                                    <InputForm
                                        id="address"
                                        value={infos.address}
                                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                        label="Địa chỉ"
                                    />
                                </div>
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="email"
                                        value={infos.email}
                                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                        label="Email"
                                    />
                                    <InputForm
                                        id="phone_number"
                                        type="phone"
                                        value={infos.phone_number}
                                        onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                                        label="Số điện thoại"
                                    />
                                    <InputSwitchForm
                                        id="status"
                                        checked={infos.status}
                                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                    />
                                </div>
                            </div>
                            <InputTextareaForm
                                id="description"
                                value={infos.description}
                                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                                label="Ghi chú"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateCompany
