import { listToolApi } from '@/api'
import { Body, Calendarz, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, TimeBody, useGetParams } from '@/components'
import { useGetApi } from '@/hooks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { descriptionlogTool } from '../../template_tool_log/screens/TemplateToolLog'
import { useCountLogTool, useListLogTool } from '../utils'

export const status = [
    { name: 'Thành công', id: 1, color: '#30CB83' },
    { name: 'Có lỗi', id: 0, color: '#F39C12' },
]

export const actions = [
    { name: 'Cập nhật', id: 'update' },
    { name: 'Thêm', id: 'insert' },
]

export const handleModule = (value) => {
    if (value && value.includes('/')) {
        const splits = value.split('/')
        return '/' + splits[1]
    } else return value
}

const Header = ({ setParams, tools }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const users = useSelector((state) => state.users)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                value={filter.tool}
                options={tools}
                optionValue={'route'}
                onChange={(e) => setFilter({ ...filter, tool: e.target.value })}
                placeholder="Module"
            />
            <Dropdownz
                value={filter.by}
                options={users}
                optionLabel={'full_name'}
                optionValue={'user_id'}
                onChange={(e) => setFilter({ ...filter, by: e.target.value })}
                placeholder="Người thao tác"
            />
            <Dropdownz
                value={filter.action}
                options={actions}
                onChange={(e) => setFilter({ ...filter, action: e.target.value })}
                placeholder="Hàng động"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                placeholder="Chọn khoảng thời gian"
            />
        </GridForm>
    )
}

const LogTools = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [tools, setTools] = useState([])

    const data = useListLogTool({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountLogTool({ status: undefined, ...params, first: undefined }) || 0
    const toolsData = useGetApi(listToolApi, {}, [])

    useEffect(() => {
        if (toolsData && toolsData[0]) {
            let newData = []
            toolsData.forEach((t) => {
                if (t.list_tool_child && t.list_tool_child[0]) {
                    newData = [...newData, ...t.list_tool_child]
                }
            })
            setTools([...newData, { name: 'Authentication', route: '/auth' }])
        }
    }, [toolsData])

    return (
        <div className="card">
            <HeaderListForm title="Danh sách lịch sử tool" />
            <Header setParams={setParams} tools={tools} />
            <DataTablez
                value={data}
                title="lịch sử tool"
                totalRecords={totalRecords}
                params={params}
                dataKey="_id"
                setParams={setParams}
                route="/log_tool"
                actionsInfo
                basePermissions={["detail"]}
            >
                <Columnz
                    body={(e) => Body({ data: tools, value: handleModule(e.tool), key: 'route' })}
                    header="module"
                />
                <Columnz field="by_name" header="Người thao tác" />
                <Columnz body={(e) => <span>{e.action === 'update' ? 'Cập nhật' : 'Thêm'}</span>} header="Hành động" />
                <Columnz body={(e) => Body({ data: descriptionlogTool, value: e.tool, key: 'route' })} header="Mô tả" />
                <Columnz header="Thời gian" body={(e) => TimeBody(e.time)} />
                <Columnz body={(e) => Body({ data: status, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}

export default LogTools
