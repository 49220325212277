import { useEffect, useState } from 'react'
import { detailBill } from '../api'

export const useDetailBill = (id) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await detailBill({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(id)])
    return data
}