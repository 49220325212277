import { Import } from '@/components'
import { importReceipt } from '../api'

export const ImportPeriod = ({ visible, setVisible }) => {
    return (
        <Import
            title={'giao dịch'}
            visible={visible}
            setVisible={setVisible}
            action={importReceipt}
            template="https://devapibdc.dxmb.vn/import/import_apartments.xlsx"
        />
    )
}
export const ImportTransaction = ({ visible, setVisible }) => {
    return (
        <Import
            title={'đầu kỳ'}
            visible={visible}
            setVisible={setVisible}
            action={importReceipt}
            template="https://devapibdc.dxmb.vn/import/import_apartments.xlsx"
        />
    )
}
