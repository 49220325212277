import {
    Body,
    Columnz,
    DataTablez, Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    LoadDialog,
    TimeBody,
    useGetParams
} from '@/components'
import { statusLog, typeServiceNotification } from '@/constants'
import { useState } from 'react'
import { deleteBlackListApi } from '../api'
import { useCountBlackList, useListBlackList } from '../utils'
import UpdateBlockList from './UpdateBlockList'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ địa chỉ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.type_service}
                options={typeServiceNotification}
                onChange={(e) => setFilter({ ...filter, type_service: e.target.value })}
                placeholder="Kiểu dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={statusLog}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}
export default function BlockList() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const data = useListBlackList({ status: undefined, ...params })
    const totalRecords = useCountBlackList({ status: undefined, ...params, first: undefined }) || 0

    const renderTypeServiceColumn = (rowData) => {
        const type = typeServiceNotification.find((item) => item.id === rowData.type_service)
        if (type) {
            return (
                <i
                    className={`pi ${type.id === 'email' ? 'pi-envelope' : ''} ${type.id === 'sms' ? 'pi-tablet' : ''}`}
                />
            )
        }
        return null
    }
    return (
        <div className="card">
            {visible && <UpdateBlockList setParams={setParams} visible={visible} setVisible={setVisible} />}
            <LoadDialog visible={visible} />

            <HeaderListForm title="Danh sách địa chỉ chặn" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="địa chỉ chặn"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/block_list"
                setVisibledDialog={setVisible}
                headerInfo
                actionsInfo={{
                    deleteAction: deleteBlackListApi,
                }}
                basePermissions={["insert", "detail", "delete"]}
                hideParams
            >
                <Columnz field="from" header="Địa chỉ gửi" />
                <Columnz field="to" header="Địa chỉ chặn" />
                <Columnz field="created_at" header="Thời gian tạo" body={e => TimeBody(e.created_at)} />
                <Columnz field="updated_at" header="Thời gian cập nhật" body={e => TimeBody(e.updated_at)} />
                <Columnz field="type_service" header="Kiểu dịch vụ" body={renderTypeServiceColumn} />
                <Columnz body={(e) => Body({ data: statusLog, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}
