import { useEffect, useState } from 'react'
import {
    countDirectoryApi,
    countPostApi,
    detailDirectoryApi,
    detailPostApi,
    listCate,
    listDirectoryApi,
    listHandbookApi,
    listPostApi,
    listUserCreate,
} from '../api'

export const useHandbook = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listHandbookApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useLisCate = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCate({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListUserCreate = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listUserCreate({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountPost = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPostApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPost = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPostApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

//========================================================================
export const useListDirectory = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDirectoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountDirectory = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countDirectoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailDirectory = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailDirectoryApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
