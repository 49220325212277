import {Body, Columnz, DataTablez, HeaderListForm, TimeBody, useGetParams} from '@/components'
import {listToast, vehicle_type} from '@/constants'
import {setToast} from '@/redux/features'
import {Button} from '@/uiCore'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {updateHandRecognition} from '../../api'
import {useListCarParkingAI} from '../../utils'
import {ParkingDialogNo} from '../ParkingDialog'

export const HandRecognition = ({data1 = [], data2 = [], setRender, setSelectedProductsAI, setSelectedProductsPM}) => {
    const [show, setShow] = useState(false)
    const [showImg, setShowImg] = useState(false)
    const [propsdata, setPropsData] = useState({})
    const handleImage = (e, image) => {
        setShowImg(true)
        setPropsData({
            plate_number: e.plate_number,
            transfer_time: e.transfer_time,
            lane_action: e.lane_action,
            vehicle_type: e.vehicle_type,
            image: image,
        })
    }
    const handleForm = () => {
        setShowImg(false)
        setShow(true)
    }
    const delay = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const dispatch = useDispatch()

    const handleHand = async () => {
        if (data1[0] && data2[0]) {
            setSelectedProductsPM([])
            setSelectedProductsAI([])
            let data = []
            data1.forEach((d, index) => {
                if (data2[index]) data.push({ ai: d.id, pm: data2[index]?.id })
            })
            const response = await updateHandRecognition({ data })
            if (response.data.status) {
                dispatch(setToast({...listToast[0], detail: 'Ghi nhận dữ liệu thành công!'}))
                setRender((pre) => !pre)
            } else dispatch(setToast({...listToast[1], detail: response.data.mess}))
        }
    }

    const handleChangeProductReorder = (e, setProduct) => {
        setProduct(e.value)
    }
    return (
        <div className={"mx-2"}>
            <HeaderListForm title="GHI NHẬN TAY"/>
            <div className="flex justify-content-end mb-4">
                <Button raised onClick={() => handleHand(data1[0]?.id, data2[0]?.id)}>Ghi nhận</Button>
            </div>
            <div className="grid">
                <div className="col-12 xl:col-6 pr-4">
                    <DataTablez
                        value={data1}
                        title="thông tin ghi nhận tay"
                        totalRecords={data1.length}
                        params={params}
                        setParams={setParams}
                        paginator={false}
                        hideParams
                        reorderableRows
                        onRowReorder={(e) => {
                            handleChangeProductReorder(e, setSelectedProductsAI)
                        }}
                    >
                        <Columnz rowReorder bodyStyle={{width: '40px', minWidth: '40px'}}
                                 headerStyle={{width: '40px', minWidth: '40px'}}
                                 tableStyle={{color: 'blue', textAlign: 'center'}}/>
                        <Columnz header="Thời gian" headerStyle={{height: '75px'}}
                                 body={e => TimeBody(e.transfer_time)}/>
                        <Columnz
                            header="Trạng Thái" body={(e) => (e.lane_action === 'IN' ? <>Xe vào</> : <>Xe ra</>)}
                        />
                        <Columnz
                            header="Hình ảnh"
                            body={(e) => (
                                <div onClick={() => handleImage}>
                                    <img
                                        src={e.image}
                                        style={{width: '100px', height: '65px'}}
                                        onClick={() => handleImage(e, e.image)}
                                    />
                                </div>
                            )}
                        />
                        <Columnz header="Loại xe" body={e => Body({data: vehicle_type, value: e.vehicle_type})}/>
                        <Columnz header="Biển đối chiếu" field="plate_number"/>
                        <Columnz body={e => <Button type="button" rounded outlined icon="pi pi-trash"
                                                    onClick={() => setSelectedProductsAI(data1.filter(d => d.id !== e.id))}
                                                    severity="danger"/>}/>
                    </DataTablez>
                </div>
                <div className="col-12 xl:col-6 pl-4">
                    <DataTablez
                        value={data2}
                        title="log"
                        totalRecords={data2.length}
                        params={params}
                        setParams={setParams}
                        paginator={false}
                        hideParams
                        reorderableRows
                        onRowReorder={(e) => {
                            handleChangeProductReorder(e, setSelectedProductsPM)
                        }}
                    >
                        <Columnz rowReorder bodyStyle={{width: '40px', minWidth: '40px'}}
                                 headerStyle={{width: '40px', minWidth: '40px'}}
                                 tableStyle={{color: 'blue', textAlign: 'center'}}/>
                        <Columnz header="Thời gian" body={e => TimeBody(e.date_time)} headerStyle={{height: '75px'}}/>
                        <Columnz
                            header="Trạng Thái" body={(e) => (e.lane_action === 'IN' ? <>Xe vào</> : <>Xe ra</>)}
                        />
                        <Columnz
                            header="Hình ảnh"
                            body={(e) => (
                                <>
                                    <div onClick={() => handleImage}>
                                        <img
                                            src={e.image_1}
                                            style={{width: '100px', height: '65px', cursor: 'pointer'}}
                                            onClick={() => handleImage(e, e.image_1)}
                                        />
                                    </div>
                                </>
                            )}
                        />
                        <Columnz header="Loại xe" body={e => Body({data: vehicle_type, value: e.vehicle_type})}/>
                        <Columnz header="Biển đối chiếu" field="plate_number"/>
                        <Columnz body={e => <Button type="button" rounded outlined icon="pi pi-trash"
                                                    onClick={() => setSelectedProductsPM(data2.filter(d => d.id !== e.id))}
                                                    severity="danger"/>}/>
                    </DataTablez>
                </div>
            </div>
            <ParkingDialogNo
                show={show}
                showImg={showImg}
                setShow={setShow}
                setShowImg={setShowImg}
                propsdata={propsdata}
                handleForm={handleForm}
            />
        </div>
    )
}
