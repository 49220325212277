import { Column, DataTable } from '@/uiCore'
const DataTableParking = (props) => {
    return (
        <div className="flex flex-column pb-2 ">
            <div
                className="p-2 w-full justify-content-center"
                style={{
                    margin: '0 auto',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '2vh',
                    color: 'white',
                    backgroundColor: '#081f3e',
                    borderTopRightRadius: '1vh',
                    borderTopLeftRadius: '1vh',
                }}
            >
                {props.item.vehicle}
            </div>
            <DataTable value={props.item.data}>
                <Column
                    field="main"
                    headerStyle={{ display: 'none' }}
                    bodyStyle={{ fontWeight: 'bold', height: '65px', maxWidth: '150px' }}
                ></Column>
                <Column
                    field="field1"
                    headerStyle={{ display: 'none' }}
                    bodyStyle={{ color: 'orange', fontWeight: 'bold' }}
                ></Column>
                <Column field="n1" headerStyle={{ display: 'none' }} bodyStyle={{ fontWeight: 'bold' }}></Column>
                <Column
                    field="field2"
                    headerStyle={{ display: 'none' }}
                    bodyStyle={{ color: 'green', fontWeight: 'bold' }}
                ></Column>
                <Column field="n2" headerStyle={{ display: 'none' }} bodyStyle={{ fontWeight: 'bold' }}></Column>
            </DataTable>
            <hr />
        </div>
    )
}

export default DataTableParking
