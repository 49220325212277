import { getData } from '@/lib/request'
import { Image as Images } from 'primereact/image'
import { useEffect, useState } from 'react'
import { FormUpdateDialog } from './ParkingForm'
import { InputForm } from '@/components'
import { Button } from '@/uiCore'
import { listToast } from '@/constants'
import { setToast } from '@/redux/features'
import { useDispatch } from 'react-redux'
import { deleteLogCarParking, updateAiLog, updateLogCarParking, updatePmLog } from '../api'
import { Calendar as Calendars } from 'primereact/calendar'
import { confirmDialog } from 'primereact/confirmdialog'
import { Input } from '@mui/material'

export const Image = ({ src, ...rest }) => {
    const [hello, setHello] = useState(true)
    const [data, setData] = useState(null)
    const handCheck = async () => {
        if (src && src.includes(process.env.REACT_APP_API_URL)) {
            const res = await getData(src)
            if (res?.data?.status) setData(res?.data?.data)
        } else setData(src)
    }

    useEffect(() => {
        handCheck()
    }, [src])
    return (
        <>
            <Images src={data} {...rest} />
            {hello ? <div></div> : <></>}
        </>
    )
}
export const VehicleDialog = (props) => {
    const dispatch = useDispatch()
    // const params = { id: props.propsdata.id }
    const fetchData = async (params, api) => {
        const response = await api(params)
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Cập nhật dữ liệu thành công!' }))
            props.setShow(false)
            props.setRender((pre) => !pre)
            return true
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
        return false
    }

    async function accept() {
        const params = { id: props.propsdata.id }
        const response = await deleteLogCarParking(params)
        if (response && response.data?.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Xóa dữ liệu thành công!' }))
            props.refresh()
            props.setShow(false)
        } else dispatch(setToast({ ...listToast[1], detail: response && response.data?.mess }))
    }

    const confirm = () => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục xóa dữ liệu này?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept,
        })
    }

    const [plate, setPlate] = useState({
        // id: props.propsdata.id,
    })
    const handlePlateChange = (event) => {
        setPlate(event.target.value)
    }
    const handleUpdate = (index, plate) => {
        if (index && plate && (plate !== '' || plate !== null)) {
            if (props.isAi) {
                const params = { id: index, plate_number: plate }
                fetchData(params, updateAiLog)
            } else {
                const params = { id: index, plate_compare_number: plate }
                fetchData(params, updatePmLog)
            }
        }
        if (plate === '' || plate === null) {
            dispatch(setToast({ ...listToast[1], detail: 'Bạn cần nhập biển số' }))
        }
    }
    useEffect(() => {
        setPlate({ ...props.propsdata })
    }, [props.propsdata])

    return (
        <FormUpdateDialog title="CHỈNH SỬA THÔNG TIN" visible={props.show} setVisible={props.setShow}>
            <div className="w-full">
                <div className="flex w-full justify-content-center">
                    <Image
                        className=" justify-content-center"
                        preview
                        imageStyle={{
                            width: '1100px',
                            height: '450px',
                            margin: '0 auto',
                        }}
                        src={props.propsdata.image}
                    />
                </div>
                <div className="flex mt-3">
                    <div className="w-full">
                        <InputForm
                            className=""
                            id="full_name"
                            value={plate.plate_number}
                            label="Biển số:"
                            required
                            onChange={(e) => setPlate({ ...plate, plate_number: e.target.value })}
                        />
                    </div>
                    <div className="w-full">
                        <InputForm id="code" value={props.propsdata.transfer_time || props.propsdata.date_time} label="Thời gian" disabled />
                    </div>
                </div>
                <div className="flex">
                    <div className="w-full">
                        <InputForm id="email" value={props.propsdata.lane_action} label="Trạng thái" disabled />
                    </div>
                    <div className="w-full">
                        <InputForm id="phone" value={props.propsdata.vehicle_type} label="Loại xe" disabled />
                    </div>
                </div>
            </div>
            <div className="flex justify-content-end flex-wrap">
                {props.isAi && (
                    <div className="my-3">
                        <Button onClick={confirm} severity="danger" type="button">
                            Xóa
                        </Button>
                    </div>
                )}
                <div className="m-3">
                    <Button type="button" onClick={() => handleUpdate(props.propsdata.id, plate.plate_number)}>
                        Lưu thay đổi
                    </Button>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

const Calendar = ({ ...rest }) => {
    return <Calendars {...rest} clearButtonClassName="hidden" />
}
export default Calendar

export const refreshObject = (object) => {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            if (key === 'dates') {
                object[key] = ''
            } else if (typeof object[key] === 'string') {
                object[key] = ''
            } else if (Array.isArray(object[key])) {
                object[key] = []
            } else if (typeof object[key] === 'object') {
                object[key] = {}
            } else {
                object[key] = undefined
            }
        }
    }
    return object
}
