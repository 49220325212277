import { Body, Columnz, DataTablezV2, TimeBody } from '@/components'
import { Card } from 'primereact/card'
import { useState } from 'react'

import { FinalReceive, GirdChart, RoundChartFlexCol, RoundChartFlexRow } from '@/components/Chart'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    useGetCashFlow, useGetCountVehicle, useGetNotifyEvent,
    useGetOpinions,
    useGetStatBuilding,
    useGetStatVehicle, useGetTotalRevenue, useListBalanceChange, useOpinionResident,
    useResidentPost
} from '../utils'

const DashBoard = () => {

    const apartments = useSelector((state) => state.apartments)
    const initParams = { page: 1, limit: 5 }
    const cashFlow = useGetCashFlow()
    const opinions = useGetOpinions()
    const statVehicle = useGetStatVehicle()
    const statBuilding = useGetStatBuilding()
    const notifyEvent = useGetNotifyEvent()
    const totalRevenue = useGetTotalRevenue()
    const residentPost = useResidentPost(initParams)
    const opinionResident = useOpinionResident(initParams)
    const countVehicle = useGetCountVehicle()
    const balanceChange = useListBalanceChange()

    const dataSubset = countVehicle.slice(1, 6).map((item, index) => ({
        count: item.count,
        key: index,
    }))

    const TitleBody = (title, id) => <Link to={'/resident_opinion/detail/' + id}>{title}</Link>
    return (
        <div className="">
            {/* Big bar chart row */}
            <div className="grid flex h-32rem mb-3">
                <div className="flex-initial col-12 xl:col-7">
                    <GirdChart
                        className="p-card"
                        title={'TỔNG HỢP DOANH THU'}
                        color={['#57a2f8', '#4675bd']}
                        label={['Thực thu trong kỳ', 'Phải thu trong kỳ']}
                        dataChart={totalRevenue}
                        keyData={['paid_by_cycle_name', 'sumery']}
                        labelChart="cycle_name"
                        maxYValue={''}
                        stepSize={''}
                        pCard="p-card"
                        chartType={'bar'}
                        yAxisText={'VND'}
                        aspectRatio={'0.79'}
                    />
                </div>
                <div className=" col-12 xl:col-5 h-full flex-wrap">
                    <FinalReceive
                        title="Phải thu cuối kỳ"
                        data={balanceChange}
                        color={['#4272bc', '#77c0f3', '#56a2f8']}

                    />
                    <RoundChartFlexRow
                        title="Dòng tiền"
                        color={['#77c0f3', '#56a2f8', '#4272bd', '#b8e6ff']}
                        type="doughnut"
                        data={cashFlow}
                        labels={['Chuyển khoản', 'Tiền mặt', 'Khác']}
                        descriptions={['', '', '', '', '', '']}
                        keyChart="cost"
                        aspectRatio={'1.6'}
                        pCard="p-card"
                    />
                </div>
            </div>
            {/* 2nd row */}
            <div className="grid mb-3">
                {/* chart */}
                <div className="xl:col-4 col-12 align-items-center	justify-content-center">
                    <RoundChartFlexCol
                        title="Ý kiến - Kiến nghị"
                        color={['#4272bc', '#56a2f8', '#77c0f3']}
                        type="pie"
                        data={opinions}
                        keyChart="count"
                        aspectRatio={'1.6'}
                        pCard="p-card"
                        labels={['Đã tiếp nhận', 'Hoàn thành', 'Chờ phản hồi']}
                    />
                </div>
                {/* info widgets */}
                <div className="xl:col-8 col-12 flex-row flex justify-content-between h-full ">
                    {/* First column */}
                    <div
                        className=" flex flex-column align-items-center justify-content-between row-gap-2"
                        style={{ width: '32%' }}
                    >
                        {/* row1 */}
                        <Card
                            className="w-full flex align-items-center justify-content-center"
                            style={{ height: '48%' }}
                        >
                            <div className="flex align-items-center flex-column xl:flex-row">
                                <img
                                    className=" pr-3 align-items-center flex"
                                    style={{
                                        // color: '#FF0000',
                                        fontSize: '4rem',
                                    }}
                                    src={`${process.env.PUBLIC_URL}/assets/img/House.png`}
                                    alt="Căn hộ"
                                ></img>
                                <div className="font-semibold text-base ">
                                    <div className="p-d-flex p-ai-center">
                                        <span className="text-5xl font-bold pr-2">{statBuilding.apartment}</span>
                                    </div>
                                    <div>Căn hộ</div>
                                </div>
                            </div>
                        </Card>
                        {/* row2 */}
                        <Card
                            className="w-full flex align-items-center justify-content-center"
                            style={{ height: '48%' }}
                        >
                            <div className="flex align-items-center flex-column xl:flex-row">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/Notification.png`}
                                    alt="Thông báo"
                                    className="pr-3 align-items-center flex"
                                    style={{
                                        color: '#3399FF',
                                        fontSize: '4rem',
                                    }}
                                ></img>
                                <div className="font-semibold text-base ">
                                    <div className="p-d-flex p-ai-center">
                                        <span className="text-5xl font-bold pr-2">{notifyEvent.count}</span>
                                    </div>
                                    <div>Thông báo</div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    {/* Second column */}
                    <div className=" flex flex-column justify-content-between row-gap-2" style={{ width: '32%' }}>
                        {/* row1 */}
                        <Card
                            className="w-full flex align-items-center justify-content-center"
                            style={{ height: '48%' }}
                        >
                            <div className="flex align-items-center flex-column xl:flex-row">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/Citizen.png`}
                                    alt="Cư dân"
                                    className=" pr-3 align-items-center flex"
                                    style={{
                                        color: '#green',
                                        fontSize: '4rem',
                                    }}
                                ></img>
                                <div className="font-semibold text-base ">
                                    <div className="p-d-flex p-ai-center">
                                        <span className="text-5xl font-bold pr-2">{statBuilding.user}</span>
                                    </div>
                                    <div>Cư dân</div>
                                </div>
                            </div>
                        </Card>

                        {/* row2 */}
                        <Card
                            className="w-full flex align-items-center justify-content-center"
                            style={{ height: '48%' }}
                        >
                            <div className="flex align-items-center flex-column xl:flex-row">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/EventCalender.png`}
                                    alt="Sự kiện"
                                    className="pr-3 align-items-center flex"
                                    style={{
                                        color: '#FF0000',
                                        fontSize: '4rem',
                                    }}
                                ></img>
                                <div className="font-semibold text-base ">
                                    <div className="p-d-flex p-ai-center">
                                        <span className="text-5xl font-bold pr-2">{notifyEvent.category_id}</span>
                                    </div>
                                    <div>Sự kiện</div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    {/* Third column */}
                    <div className=" flex flex-column justify-content-between row-gap-2" style={{ width: '32%' }}>
                        {/* row1 */}
                        <Card
                            className="w-full flex align-items-center justify-content-center"
                            style={{ height: '48%' }}
                        >
                            <div className="flex align-items-center flex-column xl:flex-row">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/LoginAccount.png`}
                                    alt="Tài khoản đã đăng nhập"
                                    className="pr-3 align-items-center flex"
                                    style={{
                                        color: '#FF0000',
                                        fontSize: '4rem',
                                    }}
                                ></img>
                                <div className="font-semibold text-base ">
                                    <div className="p-d-flex p-ai-center">
                                        <span className="text-5xl font-bold pr-2">{statBuilding.login_app}</span>
                                    </div>
                                    <div>
                                        Tài khoản <br />
                                        đã đăng nhập
                                    </div>
                                </div>
                            </div>
                        </Card>

                        {/* row2 */}
                        <Card
                            className="w-full flex align-items-center justify-content-center"
                            style={{ height: '48%' }}
                        >
                            <div className="flex align-items-center flex-column xl:flex-row">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/Comment.png`}
                                    alt="Bình luận"
                                    className=" pr-3 align-items-center flex"
                                    style={{
                                        color: '#FF0000',
                                        fontSize: '4rem',
                                    }}
                                ></img>
                                <div className="font-semibold text-base ">
                                    <div className="p-d-flex p-ai-center">
                                        <span className="text-5xl font-bold pr-2">
                                            {notifyEvent.interaction_comment}
                                        </span>
                                    </div>
                                    <div>Bình luận</div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 xl:col-7 ">
                    <GirdChart
                        title="Lượng xe ra - vào"
                        color={['#A5D7FB', '#57A2F9']}
                        label={['Vào', 'Ra']}
                        dataChart={statVehicle}
                        keyData={['register', 'cancel']}
                        labelChart="date"
                        maxYValue={''}
                        chartType={'bar'}
                        stepSize={''}
                        yAxisText={'Xe'}
                        aspectRatio={'0.9'}
                        pCard="p-card"
                    />
                </div>
                <div className="xl:col-5 col-12 align-items-center	justify-content-center">
                    <RoundChartFlexCol
                        title="Phương tiện"
                        color={['#bde7fe', '#A5D7FB', '#76c1f3', '#56a2f7', '#220186']}
                        type="doughnut"
                        data={dataSubset}
                        keyChart="count"
                        aspectRatio={'1'}
                        labels={['Phương tiện khác', 'Xe đạp', 'Xe máy', 'Ô tô', 'Xe điện']}
                        pCard="p-card"
                    />
                </div>
            </div>
            <div className="grid mt-2">
                <div className="xl:col-5 col-12">
                    <div className="p-card p-4">
                        <DataTablezV2
                            title="thông báo cư dân"
                            data={residentPost}
                            className=""
                            paginator={false}
                            header={<h5 className="text-left w-full">Thông báo cư dân</h5>}
                        >
                            <Columnz field="title" header="Tiêu đề" />
                            <Columnz field="category_name" header="Danh mục" />
                            <Columnz field="created_at" header="Ngày tạo" body={e => TimeBody(e.created_at, "date")} />
                        </DataTablezV2>
                    </div>
                </div>
                <div className="xl:col-7 col-12">
                    <div className="p-card p-4">
                        <DataTablezV2
                            title="ý kiến cư dân"
                            data={opinionResident}
                            className=""
                            paginator={false}
                            header={<h5 className="text-left w-full">Ý kiến cư dân</h5>}
                        >
                            <Columnz body={(e) => TitleBody(e.title, e.id)} header="Tiêu đề" />
                            <Columnz field="content" header="Ý kiến" />
                            <Columnz field="bdc_apartment_id" body={e => Body({ data: apartments, value: e.bdc_apartment_id, })} header="Căn hộ" />
                            <Columnz field="created_at" header="Ngày tạo" body={e => TimeBody(e.created_at, "date")} />
                        </DataTablezV2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashBoard
