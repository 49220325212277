import { Dialogz } from '@/components'
import { listToast } from '@/constants'
import { databaseDate } from '@/lib'
import { setToast } from '@/redux/features'
import { Button, Calendar } from '@/uiCore'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { expListTest2Api } from '../api'

const ExportVehicle = (props) => {
    const { visible, setVisible } = props
    const [date, setDate] = useState(new Date())
    const [loading1, setLoading1] = useState(false)
    const dispatch = useDispatch()

    async function handleExport1() {
        setLoading1(true)
        const from = databaseDate(new Date(date.getFullYear(), date.getMonth(), 1))
        const to = databaseDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))

        const response = await expListTest2Api({ from, to })
        if (response) setLoading1(false)
        if (response.status) {
            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(response.data)
            downloadLink.download = `export_bien_dong_phuong_tien_theo_loai.xlsx`
            downloadLink.click()
            dispatch(setToast({ ...listToast[0], detail: `Export biến động phương tiện theo loại thành công!` }))
        } else dispatch(setToast({ ...listToast[1], detail: 'Có lỗi xảy ra!' }))
    }
    return (
        visible && (
            <Dialogz title={'Export biến động phương tiện theo loại'} visible={visible} onHide={() => setVisible(false)} width="800px">
                <div className="card">
                    <div className="justify-content-center text-center mt-3">
                        <div className={'mt-4'}>
                            <div className="flex justify-content-center">
                                <Calendar value={date} onChange={(e) => setDate(e.value)} view="month" dateFormat="mm/yy" showIcon />
                            </div>
                        </div>
                    </div>
                    <div className="justify-content-center mt-4 flex">
                        <Button
                            loading={loading1}
                            onClick={handleExport1}
                            label="Export"
                            className="ml-2 mt-2"
                            severity="info"
                            size="small"
                            raised
                        />
                    </div>
                </div>
            </Dialogz>
        )
    )
}

export default ExportVehicle
