import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailTemplateToolLog } from '../utils'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm } from '@/components'
import { removeUndefinedProps } from '@/utils'
import { Button } from '@/uiCore'
import { addTemplateToolLogApi } from '@/modules/setting/template_tool_log/api'

const AddItems = ({ title, data, setData }) => {
    const handleOnChange = (key, value) => {
        if ((key || key === 0) && value) {
            const newData = data.map((d, index) => {
                if (index === key) return value
                else return d
            })
            setData([...newData])
        }
    }

    const handleDelete = (key) => {
        if (key || key === 0) {
            const newData = data.filter((d, index) => index !== key)
            setData([...newData])
        }
    }

    const handleAdd = () => {
        setData([...data, ''])
    }

    return (
        <>
            <div className="flex justify-content-between align-items-center mb-3">
                <label className="font-medium w-full ml-2">{title}</label>

                <div className="mt-2 w-1 text-center">
                    <Button type="button" raised icon="pi pi-plus" severity="info" onClick={() => handleAdd()} />
                </div>
            </div>
            <div className={'card mx-2'}>
                {data &&
                    data.map((d, index) => {
                        return (
                            <div key={index} className="flex justify-content-between">
                                <div className={'w-full'}>
                                    <InputForm
                                        value={d}
                                        className={'w-full'}
                                        onChange={(e) => handleOnChange(index, e.target.value)}
                                    />
                                </div>
                                <div className="mt-2 w-1 text-center">
                                    <Button
                                        type="button"
                                        icon="pi pi-trash"
                                        onClick={() => handleDelete(index)}
                                        rounded
                                        outlined
                                        severity="danger"
                                    />
                                </div>
                            </div>
                        )
                    })}
            </div>
        </>
    )
}

export const Keys = (props) => {
    const { data, setData, title } = props
    const handAdd = () => {
        setData([...data, { key: '', list_key: [''] }])
    }

    const handleDelete = (key) => {
        if (key || key === 0) setData((pre) => pre.filter((d, index) => index !== key))
    }

    const handleSetData = ({ value, index }) => {
        if ((index || index === 0) && value && typeof value === 'object') {
            let newData = data
            newData[index] = { ...newData[index], ...value }
            setData([...newData])
        }
    }

    const handleSetDataV2 = (newData, index) => {
        handleSetData({ value: { list_key: newData }, index })
    }

    return (
        <>
            <div className="flex justify-content-between align-items-center mb-3">
                <label className="font-medium w-full ml-2">{title}</label>
                <div className="mt-2 text-center mr-2">
                    <Button
                        onClick={handAdd}
                        type="button"
                        label="Thêm"
                        size="small"
                        icon="pi pi-plus"
                        style={{ minWidth: '112px' }}
                    />
                </div>
            </div>
            <div className="card mx-2">
                <div className="mt-4"></div>
                {data.map((d, index) => {
                    return (
                        <div key={index} className={' flex justify-content-between align-items-center gap-2 mb-3'}>
                            <div className={'card w-full'}>
                                <div className="w-full grid grid-form">
                                    <div className="col-12 lg:col-12">
                                        <InputForm
                                            value={d.key}
                                            onChange={(e) => handleSetData({ value: { key: e.target.value }, index })}
                                            label="Key (*)"
                                            required
                                        />
                                    </div>
                                    <div className="col-12 lg:col-12">
                                        <AddItems
                                            title={'List_key'}
                                            data={d.list_key}
                                            setData={(newData) => handleSetDataV2(newData, index)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 text-center">
                                <Button
                                    type="button"
                                    icon="pi pi-trash"
                                    onClick={() => handleDelete(index)}
                                    rounded
                                    outlined
                                    severity="danger"
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

const UpdateTemplateToolLog = () => {
    const { id } = useParams()
    const templateToolLogDetail = useDetailTemplateToolLog(id)
    const [infos, setInfos] = useState({ tool: '', template: '' })
    const [variables, setVariables] = useState([''])
    const [arrValues, setArrValues] = useState([''])
    const [arrObjs, setArrObjs] = useState([{ key: '', list_key: [''] }])

    useEffect(() => {
        if (templateToolLogDetail._id) {
            let newVariables = []
            let newArrValues = []
            let newArrObjs = []
            setInfos({ ...infos, ...removeUndefinedProps(templateToolLogDetail) })
            if (templateToolLogDetail.param && templateToolLogDetail.param[0]) {
                templateToolLogDetail.param.forEach((t) => {
                    if (t.variable) newVariables.push(t.variable)
                    if (t.arr_value) newArrValues.push(t.arr_value)
                    if (t.arr_obj) newArrObjs.push(t.arr_obj)
                })
                if (newVariables[0]) setVariables([...newVariables])
                if (newArrValues[0]) setArrValues([...newArrValues])
                if (newArrObjs[0]) setArrObjs([...newArrObjs])
            }
        }
    }, [templateToolLogDetail])
    const handleData = () => {
        let info = { ...infos }
        if (variables) info.variables = variables
        if (arrValues) info.arr_values = arrValues
        if (arrObjs) info.arr_objs = arrObjs
        if (id) info = { ...removePropObject(info, templateToolLogDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="template tool"
            handleData={handleData}
            route="/template_tool_log"
            refreshObjects={[setInfos]}
            actions={{ add: addTemplateToolLogApi }}
        >
            <div className="card bg-color">
                <InputForm
                    id={'tool'}
                    required
                    value={infos.tool}
                    onChange={(e) => setInfos({ ...infos, tool: e.target.value })}
                    label={'Tool (*)'}
                />
                <InputTextareaForm
                    id={'template'}
                    value={infos.template}
                    onChange={(e) => setInfos({ ...infos, template: e.target.value })}
                    label={'Template (*)'}
                />
                <div className={'grid grid-form'}>
                    <div className={'col-12 lg:col-6'}>
                        <AddItems title={'Variables'} data={variables} setData={setVariables} />
                    </div>
                    <div className={'col-12 lg:col-6'}>
                        <AddItems title={'ArrValues'} data={arrValues} setData={setArrValues} />
                    </div>
                </div>
                <Keys title={'ArrObject'} data={arrObjs} setData={setArrObjs} />
            </div>
        </FormUpdate>
    )
}

export default UpdateTemplateToolLog
