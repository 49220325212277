import {
    Body,
    Columnz,
    DataTablez, GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    useGetParams
} from '@/components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteDepartmentApi } from '../api'
import { useCountDepartment, useListDepartment } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const DepartmentsList = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const users = useSelector((state) => state.users)
    const data = useListDepartment({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDepartment({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card ">
            <HeaderListForm title="Danh sách bộ phận" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bộ phận"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/departments_list"
                actionsInfo={{ deleteAction: deleteDepartmentApi }}
            >
                <Columnz field="name" header="Tên bộ phận" />
                <Columnz field="code" header="Mã bộ phận" />
                <Columnz field="building_id" header="Tòa nhà" />
                <Columnz field="email" header="Email liên hệ" />
                <Columnz field="phone_number" header="SĐT liên hệ" />
                <Columnz
                    field="main_user_id"
                    body={(e) => Body({ data: users, value: e.main_user_id, label: 'full_name', key: 'user_id' })}
                    header="Trưởng bộ phận"
                />
                <Columnz field="description" header="Mô tả" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            // updateAction: updateDepartmentApi,
                        })
                    }
                />
                <Columnz
                    header="Đánh giá"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            // updateAction: updateDepartmentApi,
                        })
                    }
                />
                <Columnz
                    header="Thông báo"
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            // updateAction: updateDepartmentApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}

export default DepartmentsList
