import { useEffect, useState } from 'react'
import { listTemplateToolLogApi, countTemplateToolLogApi, detailTemplateToolLogApi } from '../api'

export const useListTemplateToolLog = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listTemplateToolLogApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountTemplateToolLog = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countTemplateToolLogApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailTemplateToolLog = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailTemplateToolLogApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
