import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import ConfigReceipt from './ConfigReceipt'
import ConfigPayment from './ConfigPayment'

function ConfigMenu() {
      return (
            <div>

                  <div className="">
                        <TabView>
                              <TabPanel header="Phiếu thu">
                                    <ConfigReceipt />
                              </TabPanel>
                              <TabPanel header="Phiếu chi">
                                    <ConfigPayment />
                              </TabPanel>
                              <TabPanel header="Phiếu báo có">
                              </TabPanel>
                              <TabPanel header="Phiếu điều chỉnh">
                              </TabPanel>
                              <TabPanel header="Phiếu kế toán">
                              </TabPanel>
                              <TabPanel header="Phiếu thu ký quỹ">
                              </TabPanel>
                        </TabView>
                  </div>
            </div>

      )
}

export default ConfigMenu
