import { Body, Calendarz, Columnz, DataTablez, Dropdownz, GridFormV2, HeaderListForm, Inputz, TimeBody, useGetParams } from '@/components'
import { typeClassify } from '@/constants'
import { Tag } from 'primereact/tag'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useListUnit } from '../../asset_managerment/utils'
import { useCountHistoryBill, useListHistoryBill } from '../utils'
const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({})
      return (
            <GridFormV2 setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
                  <Inputz
                        value={filter.key_search}
                        placeholder="Nhập mã phiếu"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
                  />
                  <Dropdownz
                        value={filter.type}
                        options={typeClassify}
                        onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                        placeholder="Loại Phiếu"
                  />
                  <Calendarz
                        value={filter.dates}
                        onChange={(e) => setFilter({ ...filter, dates: e.value })}
                        placeholder={"Chọn khoảng thời gian"}
                        selectionMode="range"
                  />
            </GridFormV2>
      )
}

function PurchaseInformation(props) {
      const { product_id } = props
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useListHistoryBill(({ status: undefined, ...params, first: undefined, product_id: product_id }))
      const users = useSelector((state) => state.users)
      const totalRecords = useCountHistoryBill({ status: undefined, ...params, first: undefined, product_id: product_id }) || 0
      const unit = useListUnit()
      const StatusBody = (rowData) => {
            return (
                  (rowData.status === 1 ? (
                        <Tag
                              value='Xác nhận'
                              rounded
                              severity="success"
                        >
                        </Tag>
                  ) : (

                        <Tag
                              value='Chưa xác nhận'
                              rounded
                              severity="warning"
                        >
                        </Tag>
                  ))
            )
      }
      return (
            <div>
                  <Header setParams={setParams} />

                  <HeaderListForm title="Thông tin mua nhập hàng" />
                  <DataTablez
                        value={data}
                        title="thông tin mua nhập hàng"
                        totalRecords={totalRecords}
                        params={params}
                        setParams={setParams}
                  // setVisibledDialog={setVisible}
                  // actionsInfo={{ deleteAction: deleteAssetApi }}
                  >
                        <Columnz
                              header="Thời gian"
                              body={(e) => TimeBody(e.create_date, "date")}
                        />
                        <Columnz
                              field="categogy"
                              header="Loại phiếu"
                              body={(e) => Body({ data: typeClassify, value: e.type })}
                        />
                        <Columnz
                              field="code"
                              header="Mã phiếu" />
                        <Columnz
                              header='Người tạo'
                              body={(e) => Body({ data: users, value: Number(e.create_by), key: 'user_id', label: 'full_name' })}
                        />
                        <Columnz
                              header="Người duyệt"
                              body={(e) => Body({ data: users, value: Number(e.user_confirm), key: 'user_id', label: 'full_name' })}
                        />
                        <Columnz
                              field="position_use"
                              header="Nơi lưu trữ,sử dụng"
                        />
                        <Columnz
                              header="Nhân viên tiếp nhận"

                              body={(e) => Body({ data: users, value: Number(e.user_receive), key: 'user_id', label: 'full_name' })}
                        />
                        <Columnz
                              body={(e) => Body({ data: unit, value: e.unit_id })}
                              header="Đơn vị tính"
                        />
                        <Columnz
                              field="amount"
                              header="Số lượng"
                        />
                        <Columnz
                              field="actual_quantity_in_stock"
                              header="Số lượng thực kho"
                        />
                        <Columnz
                              headerStyle={{ padding: 'auto', textAlign: 'center' }}
                              className='text-center'
                              body={(e) => StatusBody(e)}
                              header="Trạng thái"
                        />
                        {/* <Columnz field="file" header="File đính kèm" /> */}
                  </DataTablez>
            </div>
      )
}

export default PurchaseInformation
