import { listToolApi, updateToolApi } from '@/api'
import { Columnz, DataTablez, HeaderListForm, StatusBody, TimeBody, useGetParams } from '@/components'
import { useGetApi } from '@/hooks'
import { useState } from 'react'

const Tools = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listToolApi, {}, [])
    const totalRecords = data.length

    return (
        <div className="card">
            <HeaderListForm title="Danh sách quyền hiện có" />
            <DataTablez
                title="quyền hiện có"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/tool"
                headerInfo
                actionsInfo
                basePermissions={["detail"]}
            >
                <Columnz field="name" header="Tên quyền hiện có" />
                <Columnz field="type" header="Loại quyền" />
                <Columnz header="Ngày khởi tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    field="status"
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/tool', updateAction: updateToolApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Tools
