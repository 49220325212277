import React from 'react'
import { HeaderListForm } from '@/components'
import ParkingFilterList from './filter/ParkingFilterList'
import ParkingLogList from './log/ParkingLogList'
import { TabPanel, TabView } from 'primereact/tabview'
export default function ParkingCheck() {
    return (
        <>
            <div className="card">
                <HeaderListForm title="Đối soát hệ thống" />
                <TabView>
                    <TabPanel header="Danh sách đã lọc">
                        <ParkingFilterList />
                    </TabPanel>
                    <TabPanel header="Danh sách log">
                        <ParkingLogList />
                    </TabPanel>
                </TabView>
            </div>
        </>
    )
}
