import { DropdownForm, FormUpdateDialog, InputNumberForm } from '@/components'
import { type_payment } from '@/constants'
import { useEffect, useState } from 'react'
import { addPaymentMethodApi, updatePaymentMethodApi } from '../api'

const UpdatePaymentMethod = (props) => {
    const { visible, setVisible, setParams, data } = props
    const [infos, setInfos] = useState({})

    useEffect(() => {
        if (typeof visible === "number") {
            const detail = data.find(d => d.id === visible) || {}
            setInfos({ ...detail })

        }
    }, [data, visible])

    const handleData = () => {
        let info = { ...infos }
        return info
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'phương thức thanh toán'}
            actions={{ add: addPaymentMethodApi, update: updatePaymentMethodApi }}
        >
            <DropdownForm
                id="type_payment"
                value={infos.type_payment}
                onChange={(e) => setInfos({ ...infos, type_payment: e.target.value })}
                options={type_payment}
                disabled={typeof visible === "number"}
                label="Chọn phương thức"
            />
            <InputNumberForm
                id="fixed_charge"
                value={infos.fixed_charge}
                handleOnChange={(e) => setInfos({ ...infos, fixed_charge: e })}
                label="Phí cố định (vnđ)"
                required
            />
            <InputNumberForm
                id="fee_percentage"
                value={infos.fee_percentage}
                handleOnChange={(e) => setInfos({ ...infos, fee_percentage: e })}
                label="Phí giao dịch (%)"
                required
            />
        </FormUpdateDialog>
    )
}

export default UpdatePaymentMethod
