import { getData, postData } from '@/lib/request'

//building info
export const listBuildingInfoApi = (params) => getData('web2/building_overview/getDetailBuilding', params)
export const countBuildingInfoApi = (params) => getData('web2/company/countCompany', params)
export const deleteBuildingInfoApi = (params) => postData('web2/company/deleteCompany', params)
export const detailBuildingInfoApi = (params) => getData('web2/company/getDetailCompany', params)
export const addBuildingInfoApi = (params) => postData('/web2/company/addCompany', params)
export const updateBuildingInfoApi = (params) => postData('/web2/company/updateCompany', params)
//contact info
export const listContactInfoApi = (params) => getData('web2/building_overview/getListManagerContact', params)
export const listBoardContactInfoApi = (params) => getData('web2/building_overview/getListBoardContact', params)
export const countContactInfoApi = (params) => getData('web2/building_overview/countManagerContact', params)
export const countBoardContactInfoApi = (params) => getData('web2/building_overview/countBoardContact', params)
export const deleteContactInfoApi = (params) => postData('web2/company/deleteCompany', params)
export const detailContactInfoApi = (params) => getData('web2/company/getDetailCompany', params)
export const addContactInfoApi = (params) => postData('/web2/company/addCompany', params)
export const updateContactInfoApi = (params) => postData('/web2/company/updateCompany', params)

export const listDepartmentNameApi = (params) => getData('web2/building_overview/getListDepartmentName', params)
export const listUserDepartmentApi = (params) => getData('web2/building_overview/getListUserDepartment', params)
export const addManagerContactApi = (params) => postData('/web2/building_overview/addManagerContact', params)
export const deleteManagerContactApi = (params) => postData('/web2/building_overview/deleteManagerContact', params)

//paymentInfo
export const listPaymentInfoApi = (params) => getData('web2/building_overview/getListPaymentInfo', params)
export const countPaymentInfoApi = (params) => getData('web2/company/countCompany', params)
export const deletePaymentInfoApi = (params) => postData('web2/building_overview/deletePaymentInfo', params)
export const detailPaymentInfoApi = (params) => getData('web2/building_overview/getDetailPaymentInfo', params)
export const addPaymentInfoApi = (params) => postData('web2/building_overview/addPaymentInfo', params)
export const updatePaymentInfoApi = (params) => postData('web2/building_overview/updatePaymentInfo', params)

//paymentMethod
export const listPaymentMethodApi = (params) => getData('web2/building_overview/getListPaymentMethod', params)
export const countPaymentMethodApi = (params) => getData('web2/building_overview/countPaymentMethod', params)
export const deletePaymentMethodApi = (params) => postData('web2/building_overview/deletePaymentMethod', params)
export const detailPaymentMethodApi = (params) => getData('web2/building_overview/getDetailPaymentMethod', params)
export const addPaymentMethodApi = (params) => postData('/web2/building_overview/addPaymentMethod', params)
export const updatePaymentMethodApi = (params) => postData('/web2/building_overview/updatePaymentMethod', params)

//list bank
export const listBankApi = (params) => getData('web2/building_overview/getListBankName', params)
