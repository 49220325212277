import { countPartnerApi, deletePartnerApi, listPartnerApi, swithcStatusPartnerApi } from '@/api'
import { Columnz, DataTablez, GridForm, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { useGetApi } from '@/hooks'
import { useState } from 'react'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo email, số điện thoại"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const Partners = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listPartnerApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countPartnerApi, { ...params, first: undefined }, 0)

    return (
        <div className="card">
            <HeaderListForm title="Danh sách đối tác" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="đối tác"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/partner"
                actionsInfo={{ deleteAction: deletePartnerApi }}
            >
                <Columnz field="name" header="Tên đối tác" />
                <Columnz field="address" header="Địa chỉ" />
                <Columnz field="mobile" header="SĐT" />
                <Columnz field="email" header="Email" />
                <Columnz field="representative" header="Người đại diện" />
                <Columnz field="contact" header="Danh mục cung cấp" />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/partner', updateAction: swithcStatusPartnerApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Partners
