import { useEffect, useState } from 'react';
import { countAssetAreaApi, countAssetMix, countProductApi, detailAssetMix, detailProductApi, listAssetApi, listAssetMix, listProductGroupApi } from './../api/index';


export const useListAssetMix = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listAssetMix({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)

    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListProductGroup = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listProductGroupApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListAsset = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listAssetApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}


export const useDetailAssetMix = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailAssetMix({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
export const useDetailProduct = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailProductApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
export const useCountAssetMix = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countAssetMix({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountProduct = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countProductApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountAsset = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countAssetAreaApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}


//============================================================





//============================================================





//============================================================
// export const useListAssetDetail = (params) => {
//     const [data, setData] = useState([])
//     async function fetchData() {
//         const response = await listAssetDetailApi({ status: 1, ...params })
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         fetchData()
//     }, [JSON.stringify(params)])
//     return data
// }

// export const useCountAssetDetail = (params) => {
//     const [data, setData] = useState(0)
//     async function fetchData() {
//         const response = await countAssetDetailApi({ status: 1, ...params })
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         fetchData()
//     }, [JSON.stringify(params)])
//     return data
// }

// export const useDetailAssetDetail = (id) => {
//     const [data, setData] = useState({})
//     async function fetchData() {
//         const response = await detailAssetDetailApi({ id })
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         if (id) fetchData()
//     }, [id])
//     return data
// }
