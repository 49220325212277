import { Columnz, DataTablez, GridForm, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { getArrId } from '@/utils'
import { useState } from 'react'
import { deleteReceptFormApi, updateActiveStatus, updateInActiveStatus, updateReceptFormApi } from '../api'
import { useCountReceptForm, useListReceptForm } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function ConfigList() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReceptForm({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReceptForm({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'phiếu thu',
            actionType: 'xóa',
            action: async (list_id) => await deleteReceptFormApi({ list_id: getArrId(list_id) }),
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            title: 'phiếu thu',
            actionType: 'active',
            action: async (list_id) => await updateActiveStatus({ list_id: getArrId(list_id) }),
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            title: 'phiếu thu',
            actionType: 'inActive',
            action: async (list_id) => await updateInActiveStatus({ list_id: getArrId(list_id) }),
        },
    ]

    return (
        <div className="card">
            <HeaderListForm title="Hình thức phiếu thu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="phiếu thu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/recept_form"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                headerInfo={{ items }}
                actionsInfo={{
                    deleteAction: deleteReceptFormApi,
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                }}
                basePermissions={["insert", "detail", "delete"]}
            >
                <Columnz field="title" header="Kiểu phiếu" />
                <Columnz field="category" header="Phân loại" />
                <Columnz field="updated_at" header="Cập nhật" body={e => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/recept_form',
                            updateAction: updateReceptFormApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}
