import { TabPanel, TabView } from 'primereact/tabview'
import InventoryManagement from './InventoryManagement'
import ReceiptMangage from './ReceiptManage'

function WareHouseManager() {
      return (
            <div className="">
                  <TabView>
                        <TabPanel header="Quản lý kho">
                              <InventoryManagement />
                        </TabPanel>
                        <TabPanel header="Quản lý phiếu nhập, phiếu xuất">
                              <ReceiptMangage />
                        </TabPanel>
                  </TabView>
            </div>
      )
}

export default WareHouseManager
