import { useParams } from 'react-router-dom'
import { useDetailPage } from '../utils'
import {useEffect} from "react";

const PrintBill = () => {
    const { id } = useParams()
    const data = useDetailPage(id)

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.ctrlKey && event.key === 'p') {
                // Thay đổi nội dung trang web ở đây
                console.log("User pressed Ctrl + P");
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div className='flex justify-content-center align-items-center ' style={{ pageBreakInside: 'avoid', backgroundColor: '#FFF' }}>
            {
                data &&
                typeof data === 'string' && (
                    <div style={{ pageBreakAfter: 'always', }} dangerouslySetInnerHTML={{ __html: data }} />
                )
            }
        </div>
    )
}

export default PrintBill
