import {
    CalendarForm,
    Calendarz,
    Columnz,
    DataTablez, DropdownForm,
    Dropdownz, FormUpdateDialog,
    GridForm,
    HeaderListForm,
    InputForm,
    InputTextareaForm,
    Inputz,
    StatusBody,
    UploadFile,
    useGetParams
} from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addWorkApi, deletWorkApi, updateWorkApi } from '../api'
import { useCountWork, useDetailWork, useListWork } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
            <Calendarz
                value={filter.status}
                //className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                showIcon
                placeholder="Từ..."
            />
            <Calendarz
                value={filter.status}
                //className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                showIcon
                placeholder="Tới..."
            />
        </GridForm>
    )
}

export default function ListWork() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListWork({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountWork({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)
    const [file, setFile] = useState(null)

    const { id } = useParams()
    const workDetail = useDetailWork(id)
    const [infos, setInfos] = useState({
        name: '',
        category: '',
        apartment: '',
        level: '',
        description: '',
        checklist: '',
        receiving_depart: '',
        implementer: '',
        supervisor: '',
        type: '',
        date: '',
        file: '',
    })
    useEffect(() => {
        if (workDetail.id) setInfos({ ...infos, ...workDetail, status: workDetail.status === 0 ? false : true })
    }, [workDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, workDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <FormUpdateDialog
                title={(typeof visible === 'number' ? 'Cập nhật' : 'Thêm mới') + ' Công việc'}
                handleData={handleData}
                visible={visible}
                route="/work_management"
                setVisible={setVisible}
                refreshObjects={[setInfos]}
                actions={{ add: addWorkApi, update: updateWorkApi }}
            >
                <div className="card bg-color">
                    <div className="grid grid-form">
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="name"
                                value={infos.name}
                                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                label="Tên công việc"
                                required
                            />
                            <DropdownForm
                                value={infos.category}
                                onChange={(e) => setInfos({ ...infos, category: e.target.value })}
                                label="Danh mục"
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <DropdownForm
                                value={infos.apartment}
                                onChange={(e) => setInfos({ ...infos, apartment: e.target.value })}
                                label="Căn hộ liên quan"
                            />
                            <DropdownForm
                                value={infos.level}
                                optionValue={[{ id: 1, level: '2' }]}
                                onChange={(e) => setInfos({ ...infos, level: e.target.value })}
                                label="Mức độ công việc"
                            />
                        </div>
                        <div className="col-12">
                            <InputTextareaForm
                                id="description"
                                value={infos.description}
                                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                                label="Mô tả"
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="name"
                                value={infos.receiving_depart}
                                onChange={(e) => setInfos({ ...infos, receiving_depart: e.target.value })}
                                label="Bộ phận tiếp nhận"
                                required
                            />
                            <InputForm
                                id="name"
                                value={infos.implementer}
                                onChange={(e) => setInfos({ ...infos, implementer: e.target.value })}
                                label="Người thực hiện"
                                required
                            />
                            <InputForm
                                id="name"
                                value={infos.supervisor}
                                onChange={(e) => setInfos({ ...infos, supervisor: e.target.value })}
                                label="Người giám sát"
                                required
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <DropdownForm
                                value={infos.type}
                                onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                                label="Loại công việc"
                            />
                            <CalendarForm
                                id="date"
                                value={infos.date}
                                showIcon
                                onChange={(e) => setInfos({ ...infos, date: e.target.value })}
                                label="Ngày bắt đầu - kết thúc"
                            />
                            <UploadFile
                                id="file"
                                file={file}
                                setFile={setFile}
                                onChange={(e) => setInfos({ ...infos, file: e.target.value })}
                                label="Chose file"
                            />
                        </div>
                    </div>
                </div>
            </FormUpdateDialog>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Thông tin công việc" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Công việc"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/work_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deletWorkApi }}
            >
                <Columnz field="name" header="Tên công việc" />
                <Columnz field="categogy" header="Danh mục" />
                <Columnz field="time_start" header="Thời gian bắt đầu" />
                <Columnz field="time_end" header="Thời gian kết thúc" />
                <Columnz field="depart" header="Bộ phận" />
                <Columnz field="creator" header="Người thực hiện" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            updateAction: updateWorkApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
