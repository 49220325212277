import { Body, FormUpdateDialog, TimeBody } from '@/components'
import { processingType, statusLogImport } from '@/constants'
import { Column } from '@/uiCore'
import { removeUndefinedProps } from '@/utils'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDetailListLogDebt } from '../utils'

const UpdateDebtImportHistory = (props) => {
    const { setVisible, setParams, visible, data } = props
    const listLogDebtDetail = useDetailListLogDebt(typeof visible === 'string' ? visible : undefined)
    const [debt, setDebt] = useState({})
    const services = useSelector((state) => state.services)
    const filter = data.find(e => e._id === visible)
    const serviceConfig = filter.data.service_config
    const aparments = useSelector((state) => state.apartments)
    useEffect(() => {
        if (listLogDebtDetail) {
            setDebt({
                ...removeUndefinedProps(listLogDebtDetail.debt),
            })
        }
    }, [listLogDebtDetail])
    const cycle_name = debt.data?.service_config.find(d => d.cycle_name_use);

    return (
        <FormUpdateDialog
            title={(typeof visible === 'string' ? 'Chi tiết ' : 'Thêm mới ') + 'lịch sử'}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setDebt]}
            actions={{}}
        >
            <div className="card bg-color">
                <div className="flex flex-column align-items-center ">
                    <div className="flex justify-content-between w-6">
                        <div>
                            <div className="mb-3 px-2 change-disabled">
                                <div className="w-full flex justify-content-between">
                                    <label className="font-medium w-full">Tên</label>
                                </div>
                                <div className="w-full mt-2 flex pt-2 pb-2" style={{ minHeight: '42px' }}>
                                    {debt.name}
                                </div>
                            </div>
                            <div className="mb-3 px-2 change-disabled">
                                <div className="w-full flex justify-content-between">
                                    <label className="font-medium w-full">Kiểu xử lý</label>
                                </div>
                                <div className="w-full mt-2 flex pt-2 pb-2" style={{ minHeight: '42px' }}>
                                    <Body data={processingType} value={debt.type} />
                                </div>
                            </div>
                            {debt.data?.service_config.some(d => d.cycle_name_use) &&
                                <div className="mb-3 px-2 change-disabled">
                                    <div className="w-full flex justify-content-between">
                                        <label className="font-medium w-full">Tháng chốt số điện</label>
                                    </div>
                                    <div className="w-full mt-2 flex pt-2 pb-2" style={{ minHeight: '42px' }}>
                                        {cycle_name.cycle_name_use}
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            <div className="mb-3 px-2 change-disabled">
                                <label className="font-medium w-full">Thành công</label>
                                <div className="w-full mt-2 flex pt-2 pb-2" style={{ minHeight: '42px' }}>
                                    <span
                                        className="text-center font-bold text-white text-xs"
                                        style={{
                                            backgroundColor: statusLogImport[1].color,
                                            borderRadius: '4px',
                                            padding: '4px',
                                            minWidth: '100px',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {debt.success}
                                    </span>
                                </div>
                                <label className="font-medium w-full">Thất bại</label>
                                <div className="w-full mt-2 flex pt-2 pb-2" style={{ minHeight: '42px' }}>
                                    <span
                                        className="text-center font-bold text-white text-xs"
                                        style={{
                                            backgroundColor: statusLogImport[0].color,
                                            borderRadius: '4px',
                                            padding: '4px',
                                            minWidth: '100px',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {debt.error}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full mt-5">
                        <DataTable value={listLogDebtDetail.data} showGridlines stripedRows>
                            <Column
                                className=""
                                body={(e) => Body({ data: aparments, value: e.apartment_id })}
                                header="Căn hộ"
                            ></Column>
                            <Column
                                className="w-1"
                                body={(e) => (e.message === 'false' ? '' : e.message)}
                                header="Lỗi"
                            ></Column>
                            <Column
                                body={(e) => {
                                    if (debt.type === 2) {
                                        return (
                                            <div className="flex justify-content-center ">
                                                <table className="w-full">
                                                    <tr className="flex">
                                                        <th className="max-w-8rem flex-1">Kỳ</th>
                                                        <th className="flex-1">Dịch vụ</th>
                                                        <th className="max-w-8rem flex-1">Phí</th>
                                                    </tr>
                                                    <tr className="flex py-3">
                                                        <td
                                                            style={
                                                                e.status
                                                                    ? { backgroundColor: '#30CB83' }
                                                                    : { backgroundColor: '#F39C12' }
                                                            }
                                                            className="max-w-8rem p-3 flex-1 border-round-left-md font-medium text-white"
                                                        >
                                                            {e.data.cycle_name}
                                                        </td>
                                                        <td
                                                            style={
                                                                e.status
                                                                    ? { backgroundColor: '#30CB83' }
                                                                    : { backgroundColor: '#F39C12' }
                                                            }
                                                            className="flex-1 p-3 font-medium text-white"
                                                        >
                                                            {Body({ data: services, value: e.data.service_id })}
                                                        </td>
                                                        <td
                                                            style={
                                                                e.status
                                                                    ? { backgroundColor: '#30CB83' }
                                                                    : { backgroundColor: '#F39C12' }
                                                            }
                                                            className="max-w-8rem flex-1 p-3 border-round-right-md font-medium text-white"
                                                        >
                                                            {e.data.sumery}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        )
                                    } else if (debt.type === 1) {
                                        return (
                                            <div className="flex justify-content-center ">
                                                <table className="w-full">
                                                    <tr className="flex">
                                                        <th className="flex-1 text-lg font-bold">Dịch vụ</th>
                                                        <th className="flex-1 text-lg font-bold">Message</th>
                                                        <th className="flex-1 text-lg font-bold">Ngày bắt đầu</th>
                                                        <th className="flex-1 text-lg font-bold">Ngày kết thúc</th>
                                                    </tr>

                                                    {e.data.map((d) => {
                                                        return (
                                                            <tr className="flex py-3 mt-2 border-round-md">
                                                                <td
                                                                    style={
                                                                        d.status
                                                                            ? { backgroundColor: '#30CB83' }
                                                                            : { backgroundColor: '#F39C12' }
                                                                    }
                                                                    className="p-2 flex-1 border-round-md font-medium text-white m-1"
                                                                >
                                                                    {Body({ data: services, value: d.service_id })}
                                                                </td>
                                                                <td
                                                                    style={
                                                                        d.status
                                                                            ? { backgroundColor: '#30CB83' }
                                                                            : { backgroundColor: '#F39C12' }
                                                                    }
                                                                    className="p-2 flex-1 border-round-md font-medium text-white m-1"
                                                                >
                                                                    {String(d.message)}
                                                                </td>
                                                                <td
                                                                    style={
                                                                        d.status
                                                                            ? { backgroundColor: '#30CB83' }
                                                                            : { backgroundColor: '#F39C12' }
                                                                    }
                                                                    className="p-2 flex-1 border-round-md font-medium text-white m-1"
                                                                >
                                                                    {TimeBody(serviceConfig.find(e => e.bdc_service_id === d.service_id)?.start || null, 'date')}
                                                                </td>
                                                                <td
                                                                    style={
                                                                        d.status
                                                                            ? { backgroundColor: '#30CB83' }
                                                                            : { backgroundColor: '#F39C12' }
                                                                    }
                                                                    className="p-2 flex-1 border-round-md font-medium text-white m-1"
                                                                >
                                                                    {TimeBody(serviceConfig.find(e => e.bdc_service_id === d.service_id)?.end || null, 'date')}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </table>
                                            </div>
                                        )
                                    }
                                }}
                                header="Params"
                            ></Column>
                            <Column
                                body={(e) => Body({ data: statusLogImport, value: e.status })}
                                header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                                className='text-center'
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateDebtImportHistory
