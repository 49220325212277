import { Body, Columnz, DataTablez, Dropdownz, GridForm, TimeBody, useGetParams } from '@/components'
import { Column } from '@/uiCore'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import React, { useState } from 'react'
import { useApartmentFilterApi, useGetCountLogDebtDetail } from '../utils'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useListCycleName } from '@/modules/debts/services_list/utils'

export const HeaderDetail = ({ setParams, params }) => {
    const [filter, setFilter] = useState({ key_search: 'log_debt_id' })

    // -------------------- Get from redux store --------------------
    const apartments = useSelector((state) => state.apartments)
    const services = useSelector((state) => state.services)
    const cycleName = useListCycleName()

    // --------------------------------------------------------------
    const onClear = () => {
        // Preserved params
        const preservedParams = {
            page: params.page || 1,
            limit: params.limit || 10,
            first: params.first || 1,
            log_debt_id: params.log_debt_id || undefined,
        }

        // Merge preservedParams with the existing filter object
        setParams({
            ...preservedParams,
            render: params.render,
        })

        setFilter({ key_search: '' })
    }

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} onClear={onClear} className="">
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, ...params, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.bdc_service_id}
                options={services}
                onChange={(e) => setFilter({ ...filter, ...params, bdc_service_id: e.target.value })}
                placeholder="Dịch vụ"
            />
            <Dropdownz
                value={filter.cycle_name}
                options={cycleName}
                onChange={(e) => setFilter({ ...filter, ...params, cycle_name: e.target.value })}
                optionLabel="cycle_name"
                optionValue="cycle_name"
                placeholder="Kỳ hạch toán"
            />
        </GridForm>
    )
}
export const HistoryDetail = ({ data, params, setParams, rowData }) => {
    const countLogDebtDetail = useGetCountLogDebtDetail({ status: undefined, ...params, first: undefined })

    // -------------------- Get from redux store --------------------
    const apartments = useSelector((state) => state.apartments)
    const services = useSelector((state) => state.services)

    // ------------------ Connect string date -----------------
    const concatenateDates = (rowData) => {
        if (!rowData.data.from_date || !rowData.data.to_date) {
            return ''
        }

        const fromDateFormatted = moment(rowData.data.from_date).format('DD/MM/YYYY')
        const toDateFormatted = moment(rowData.data.to_date).format('DD/MM/YYYY')
        return `${fromDateFormatted} - ${toDateFormatted}`
    }

    // -------------------- Hover Column -----------------------
    const [hoveredRow, setHoveredRow] = useState(false)

    const handleMouseOver = (rowData) => {
        setHoveredRow(rowData) // Set the hovered row
    }

    const handleMouseOut = () => {
        setHoveredRow(null) // Reset hovered row when mouse leaves
    }

    // ---------------------- Header ---------------------------
    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Stt" colSpan={1} rowSpan={2} style={{ textAlign: 'center' }} />
                <Column header="Đầu vào" field="" colSpan={8} style={{ textAlign: 'center' }} />
                <Column header="Đầu ra" field="" colSpan={5} style={{ textAlign: 'center' }} />
                <Column header="Thông báo" colSpan={1} rowSpan={2} style={{ textAlign: 'center' }} />
            </Row>
            <Row>
                <Column header="Căn hộ" style={{ textAlign: 'center' }} />
                <Column header="Dịch vụ tính phí" style={{ textAlign: 'center' }} />
                <Column header="Kỳ hạch toán" style={{ textAlign: 'center' }} />
                <Column header="Hạn thanh toán" style={{ textAlign: 'center' }} />
                <Column header="Ngày tính phí tiếp theo" style={{ textAlign: 'center' }} />
                <Column header="Thời gian" style={{ textAlign: 'center' }} />
                <Column header="Giảm trừ" style={{ textAlign: 'center' }} />
                <Column header="Đơn giá" style={{ textAlign: 'center' }} />
                <Column header="Thành tiền" style={{ textAlign: 'center' }} />
                <Column header="Người tạo" style={{ textAlign: 'center' }} />
                <Column header="Ngày tạo" style={{ textAlign: 'center' }} />
                <Column header="Ngày update" style={{ textAlign: 'center' }} />
                <Column header="Trạng thái" style={{ textAlign: 'center' }} />
            </Row>
        </ColumnGroup>
    )

    return (
        <div>
            <HeaderDetail setParams={setParams} />
            <DataTablez
                value={data}
                title="lịch sử import"
                totalRecords={countLogDebtDetail}
                params={params}
                setParams={setParams}
                headerColumnGroup={headerGroup}
            >
                <Columnz
                    field="apartment_id"
                    header="Căn hộ"
                    body={(e) => Body({ data: apartments, value: e.apartment_id })}
                ></Columnz>
                <Columnz
                    header="Dịch vụ tính phí"
                    body={(e) => (
                        <div
                            className="max-w-13rem overflow-hidden whitespace-nowrap"
                            onMouseOver={() => handleMouseOver(e)}
                            onMouseOut={handleMouseOut}
                            style={{ textOverflow: 'ellipsis' }}
                        >
                            {hoveredRow === e && (
                                <div className="absolute text-gray-800 font-semibold bg-white border border-gray-200 shadow-md border-round-md px-2 pb-1 ">
                                    {Body({
                                        data: services,
                                        value: e.bdc_service_id,
                                    })}
                                </div>
                            )}
                            {Body({
                                data: services,
                                value: e.bdc_service_id,
                            })}
                        </div>
                    )}
                />
                <Columnz
                    field="cycle_name"
                    header="Kỳ hạch toán"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    body={(e) => TimeBody(e.data.date_end, 'date')}
                    header="Hạn thanh toán"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    body={(e) => TimeBody(e.data.date_end, 'date')}
                    header="Ngày tính phí tiếp theo"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    body={(rowData) => concatenateDates(rowData)}
                    header="Thời gian"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    field={(e) => (!e.discount ? 0 : e.discount)}
                    header="Giảm trừ"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    field={(e) => (!e.data.price ? 0 : e.data.price)}
                    header="Đơn giá"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    field={(e) => (!e.data.money ? 0 : e.data.money)}
                    header="Thành tiền"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    field="log_debt_id.by_name"
                    header="Người tạo"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    field={(e) => moment(e.created_at).format('DD/MM/YYYY HH:mm:ss')}
                    header="Ngày tạo"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    field={(e) => moment(e.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                    header="Ngày update"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
                <Columnz
                    field="status"
                    header="Trạng thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                    body={(rowData) =>
                        rowData.status === 1 ? (
                            <div className="bg-blue-100 border-primary border-2 border-round-sm p-1 font-semibold text-primary">
                                Done
                            </div>
                        ) : (
                            <div className="bg-orange-100 border-orange-500 border-2 pb-1 border-round-sm p-1 font-semibold text-orange-600">
                                Not Done
                            </div>
                        )
                    }
                />
                <Columnz
                    field="data.message"
                    header="Thông báo"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className="text-center"
                />
            </DataTablez>
        </div>
    )
}

export default HistoryDetail
