import { Body, Columnz, DataTablez, HeaderListForm, TimeBody, useGetParams } from '@/components'
import { useEffect, useState } from 'react'
import UpdateToolUse from './UpdateToolUse'
import { Chip } from '@/uiCore'
import { useGetApi } from '@/hooks'
import { countToolUseApi, deleteToolUseApi, listCompanyV2Api, listToolApi, listToolUseApi } from '@/api'

const ToolUses = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listToolUseApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countToolUseApi, { ...params, first: undefined }, 0)
    const [visible, setVisible] = useState(false)
    const companies = useGetApi(listCompanyV2Api, {}, [])
    const [tools, setTools] = useState([])
    const toolsData = useGetApi(listToolApi, {}, [])

    useEffect(() => {
        if (toolsData && toolsData[0]) {
            let newData = []
            toolsData.forEach((t) => {
                if (t.list_tool_child && t.list_tool_child[0]) {
                    newData = [...newData, ...t.list_tool_child]
                }
            })
            setTools([...newData])
        }
    }, [toolsData])

    const ToolBody = (ids) => {
        if (ids && ids[0]) {
            return (
                <div className="flex gap-2 flex-wrap">
                    {ids.map((id) => {
                        const tool = tools.find((t) => t.id === id)
                        if (tool) return <Chip key={id} label={tool.name} />
                    })}
                </div>
            )
        }
    }

    return (
        <div className="card">
            {visible && (
                <UpdateToolUse
                    tools={tools}
                    companies={companies}
                    setParams={setParams}
                    visible={visible}
                    setVisible={setVisible}
                />
            )}
            <HeaderListForm title="Danh sách quyền sử dụng" />
            <DataTablez
                title="quyền sử dụng"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/tool_use"
                headerInfo
                actionsInfo={{ deleteAction: deleteToolUseApi }}
                basePermissions={["insert", "detail", "delete"]}
                setVisibledDialog={setVisible}
            >
                <Columnz body={(e) => Body({ data: companies, value: e.company_id })} header="Công ty" />
                <Columnz
                    body={(e) => ToolBody(e.tool_use_ids)}
                    header="Danh sách quyền"
                    bodyStyle={{ width: '50rem' }}
                />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
            </DataTablez>
        </div>
    )
}

export default ToolUses
