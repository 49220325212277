import {Import} from '@/components'
import {importService, listServiceV2Api} from '../api'
import {useDispatch} from "react-redux";
import {setServices} from "@/redux/features";

const ImportService = ({visible, setVisible, setParams}) => {
    const dispatch = useDispatch()
    const handleSuccess = async () => {
        setParams(pre => ({...pre, render: !pre.render}))
        setVisible(false)
        const getService = await listServiceV2Api()
        if (getService.data && getService.data.status) {
            const services = getService.data.data
            dispatch(setServices(services))
        }
    }
    return (
        <Import
            title="dịch vụ tòa nhà"
            visible={visible}
            setVisible={setVisible}
            action={importService}
            template="https://devapibdc.dxmb.vn/import/import_service.xlsx"
            handleSuccess={handleSuccess}
        />
    )
}

export default ImportService
