import {
    CalendarForm,
    Columnz,
    DataTablez,
    DropdownForm,
    HeaderListForm,
    InputNumberForm,
    InputTextareaForm,
    useGetParams
} from '@/components'
import { customer_payments_type, listToast } from '@/constants'
import { databaseDate } from '@/lib'
import { setToast } from '@/redux/features'
import { Button } from '@/uiCore'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addReceiptPayment, listCoinApi } from '../api'
import { CustomModal } from './CreateReceipt'

export default function CreatePaymentVoucher() {
    const initParam = useGetParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [params, setParams] = useState(initParam)
    const [infos, setInfos] = useState({ customer_payments: null, amount: '', create_date: new Date(), apartment_id: null, description: '' })
    const [selectedProducts, setSelectedProducts] = useState([])
    const [data, setData] = useState([])
    const apartments = useSelector((state) => state.apartments)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)

    useEffect(() => {
        if (data) {
            setInfos({
                ...infos,
                arr_pay: [...data],
            })
        }
    }, [data])

    const handleOnchange = async (e) => {
        if (e.target.value) {
            setInfos({ ...infos, apartment_id: e.target.value, customer_payments: null, create_date: new Date() })
            setSelectedProducts([])
            const response = await listCoinApi({ apartment_id: e.target.value })
            if (response.data.status) {
                setData(response.data.data)
            }
        } else {
            setInfos({ ...infos, apartment_id: null, customer_payments: null, create_date: new Date(), description: '' })
            setSelectedProducts([])
            setData([])
        }
    }

    useEffect(() => {
        let total = 0
        if (data) {
            for (let d of selectedProducts) {
                if (d.money) {
                    total += d.money
                }
            }
        }
        setInfos({ ...infos, amount: total })
    }, [selectedProducts])

    const handleOnchangeInput = (newValue, rowData) => {
        if (newValue > rowData.coin) {
            return dispatch(setToast({ ...listToast[1], detail: 'Số tiền chi phải nhỏ hơn số dư!' }))
        }
        const index = data.findIndex(
            (row) => row.bdc_apartment_service_price_id === rowData.bdc_apartment_service_price_id,
        )
        const newData = [...data]
        newData[index] = {
            ...newData[index],
            money: !newValue ? 0 : newValue,
        }

        setData(newData)
    }

    const handleData = () => {
        let info = { ...infos, create_date: databaseDate(infos.create_date) }
        if (!infos.apartment_id) {
            return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn căn hộ!' }))
        }
        if (!infos.customer_payments) {
            return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn hình thức!' }))
        }
        if (!infos.create_date) {
            return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn ngày hạch toán!' }))
        }
        if (!selectedProducts[0]) {
            return dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn hóa đơn!' }))
        }
        if (!infos.amount) {
            return dispatch(setToast({ ...listToast[1], detail: 'Số tiền phải lớn hơn 0!' }))
        }
        confirmDialog({
            message: `Xác nhận thêm mới phiếu thu`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: async () => {
                let newData = {
                    ...info,
                    arr_pay:
                        selectedProducts &&
                        selectedProducts.map((e) => {
                            return {
                                bdc_apartment_service_price_id: e.bdc_apartment_service_price_id,
                                coin: e.money,
                                type: 4,
                                bdc_apartment_id: e.bdc_apartment_id,
                            }
                        }),
                }
                const { data } = await addReceiptPayment(newData)
                if (!data.status) {
                    dispatch(setToast({ ...listToast[1], detail: data.mess }))
                } else setShowSuccessDialog(true)
            },
        })
        return info
    }

    return (
        <div>
            <HeaderListForm title="Phiếu chi" />
            <div className="grid grid-form">
                <div className="col-3 col-lg-3">
                    <DropdownForm
                        label="Chọn căn hộ (*)"
                        value={infos.apartment_id}
                        onChange={handleOnchange}
                        options={apartments}
                        showClear
                    />
                </div>
                <div className="col-3 col-lg-3">
                    <InputNumberForm
                        label="Số tiền"
                        value={infos.amount}
                        onChange={(e) => setInfos({ ...infos, amount: e.target.value })}
                        disabled
                    />
                </div>
                <div className="col-3 col-lg-3">
                    <DropdownForm
                        label="Hình thức (*)"
                        value={infos.customer_payments}
                        onChange={(e) => setInfos({ ...infos, customer_payments: e.target.value })}
                        options={customer_payments_type}
                    />
                </div>
                <div className="col-3 col-lg-3">
                    <CalendarForm
                        label="Ngày hạch toán (*)"
                        value={infos.create_date}
                        onChange={(e) => setInfos({ ...infos, create_date: e.target.value })}
                    />
                </div>
            </div >
            <div>
                <InputTextareaForm
                    label="Nội dung"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                />
            </div>
            <HeaderListForm title="Danh sách hóa đơn" />
            <DataTablez
                value={data}
                params={params}
                title="hóa đơn"
                setParams={setParams}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                totalRecords={data ? data.length : 0}
                route="receipt"
                dataKey="bdc_apartment_service_price_id"
            >
                <Columnz
                    field={"name"}
                    header="Dịch vụ"
                ></Columnz>
                <Columnz header="Thời gian"></Columnz>
                <Columnz header="Sản phẩm"></Columnz>
                <Columnz header="Phát sinh"></Columnz>
                <Columnz header="Thời gian hạch toán"></Columnz>
                <Columnz
                    body={(e) => (
                        <InputNumberForm value={e.coin} disabled className="w-full" placeholder="Nhập số tiền" />
                    )}
                    header="Số dư/đã hạch toán"
                ></Columnz>
                <Columnz
                    body={(rowData) => (
                        <InputNumberForm
                            value={
                                rowData.money || rowData.money === 0 ? rowData.money : (rowData.money = rowData.coin)
                            }
                            className="w-full"
                            handleOnChange={(newValue) => handleOnchangeInput(newValue, rowData)}
                            placeholder="Nhập số tiền"
                            disabled={selectedProducts.includes(rowData)}
                        />
                    )}
                    header="Số tiền chi"
                />
            </DataTablez>
            <div className="grid grid-form w-full justify-content-center align-items-center mx-1">
                <Button
                    style={{ marginLeft: '15px', marginTop: '25px' }}
                    type="button"
                    onClick={() => navigate('/deposit_list')}
                >
                    Bỏ qua
                </Button>
                <Button style={{ marginLeft: '15px', marginTop: '25px' }} type="submit" onClick={() => handleData()}>
                    Lập phiếu
                </Button>
                {showSuccessDialog && (
                    <CustomModal
                        visible={showSuccessDialog}
                        message="Lập phiếu chi thành công!"
                        header={process.env.REACT_APP_BRANCH_NAME}
                        icon="pi pi-check"
                        acceptClassName="p-button-success"
                        acceptLabel="OK"
                        onAccept={() => navigate('/receipt')}
                        onHide={() => setShowSuccessDialog(false)}
                    />
                )}
            </div>
        </div >
    )
}
