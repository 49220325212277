import { getData, postData } from '@/lib/request'

export const listVehicleApi = (params) => getData('web2/vehicle_manage/getListVehicles', params)
export const countVehicleApi = (params) => getData('web2/vehicle_manage/countVehicles', params)
export const detailVehicleApi = (params) => getData('web2/vehicle_manage/getDetailVehicle', params)
export const detailVehicleOrderApi = (params) => getData('web2/vehicle_manage/getDetailVehicleOrder', params)
export const addVehicleApi = (params) => postData('web2/vehicle_manage/addVehicle', params, true)
export const updateVehicleApi = (params) => postData('web2/vehicle_manage/updateVehicle', params, true)
export const swithcStatusVehicleApi = (params) => postData('web2/vehicle_manage/updateVehicle', params)
export const importVehicleApi = (params) => postData('web2/vehicle_manage/importVehicle', params, false, true)
export const exportVehicleApi = (params) => getData('web2/vehicle_manage/expListVehicle', params, true)
export const deleteVehicleApi = (params) => postData('web2/vehicle_manage/deleteVehicle', params)
export const expListTestApi = (params) => getData('web2/vehicle_manage/expListTest', params, true)
export const expListTest2Api = (params) => getData('web2/vehicle_manage/expListTest2', params, true)


