import {
    Columnz,
    DataTablez, GridForm, HeaderListForm, Inputz, TimeBody, useGetParams
} from '@/components'
import { useState } from 'react'
import { deleteDocsGroupApi } from '../api'
import { useCountDocsGroup, useListDocsGroup } from '../utils'
import UpdateDocsGroup from './UpdateDocsGroup.js'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên nhóm tài liệu..."
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function DocsGroup() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const data = useListDocsGroup({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDocsGroup({ status: undefined, ...params, first: undefined }) || 0


    return (
        <div className='card'>
            {visible && <UpdateDocsGroup visible={visible} setVisible={setVisible} setParams={setParams} />}
            <HeaderListForm title="Thông tin nhóm tài liệu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="nhóm tài liệu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                headerInfo
                route="/docs_management"
                basePermissions={["insert", "detail", "delete"]}
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteDocsGroupApi }}
                hideParams
            >
                <Columnz field="name" header="Nhóm tài liệu" />
                <Columnz field="created_at" header="Ngày tạo" body={e => TimeBody(e.created_at)} />
                <Columnz field="updated_at" header="Ngày cập nhật" body={e => TimeBody(e.updated_at)} />
            </DataTablez>
        </div>
    )
}
