import { addChildResidentApi, addResidentApi, addRoleApi, checkResidentApi } from '@/api'
import { CalendarForm, Dialogz, DropdownForm, InputForm, LoadDialog, MultiRadioButton } from '@/components'
import { genders, relationshipOwner } from '@/constants'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import { setToast } from '@/redux/features'
import { listToast } from '@/constants'
import { useDispatch, useSelector } from 'react-redux'
import { databaseDate, validate } from '@/lib'
import { useNavigate } from 'react-router-dom'
import { CustomModal } from '@/modules/accountant/receipts_expenditures/screens/CreateReceipt'


function AddResidentApartment(props) {
      const { id, setVisible, visible, render, setRender, dataResident } = props

      const dispatch = useDispatch()
      const [user, setUser] = useState({
            full_name: '',
            gender: 1,

      })
      const resident = useSelector((state) => state.residents)

      const resetForm = () => {
            setUser({
                  full_name: '',
                  gender: 1,
                  email: '',
                  phone: '',
                  birthday: undefined,
                  type: undefined
            })
            setChild({
                  full_name: '',
                  gender: 1,
                  birthday: undefined,
                  type: undefined
            })

      }
      const [visibled, setVisibled] = useState(false);
      const [addChild, setAddChild] = useState(false);
      const [child, setChild] = useState({
            gender: 1,
            apartment_id: id
      });
      const [idz, setIdz] = useState(null)
      const [message, setMessage] = useState("");
      const [showSuccessDialog, setShowSuccessDialog] = useState(false)
      const navigate = useNavigate()

      const validateInput = (input) => {
            return validate('email', input) || validate('phone', input);
      };
      const handleCheck = async () => {
            let key_search = user.input;
            let type = '';
            if (validate('email', key_search)) {
                  type = 'email';
                  setUser({ ...user, email: key_search });
            } else if (validate('phone', key_search)) {
                  type = 'phone';
                  setUser({ ...user, phone: key_search });
            } else {
                  dispatch(setToast({ ...listToast[1], detail: `Email hoặc số điện thoại không đúng định dạng` }));
                  return;
            }
            try {
                  const { data } = await checkResidentApi({ key_search, type });

                  if (data.status) {
                        if (data.data.id) {
                              setIdz(data.data.id);
                              dispatch(setToast({ ...listToast[0], detail: `Người dùng đã tồn tại` }));
                              setVisible(setVisibled)
                              setUser({
                                    ...user,
                                    full_name: data.data.full_name,
                                    email: data.data.email,
                                    phone: data.data.phone,
                                    birthday: new Date(data.data.birthday),
                                    type: data.data.type,
                              })

                        } else {
                              setIdz(undefined)
                              setVisible(false);
                              setVisibled(true)
                              dispatch(setToast({ ...listToast[0], detail: `Không tìm thấy người dùng tiếp tục thêm mới` }));
                        }
                  } else {
                        dispatch(setToast({ ...listToast[2], detail: `Lỗi khi kiểm tra dữ liệu` }));
                  }
            } catch (error) {
                  dispatch(setToast({ ...listToast[2], detail: `Lỗi kết nối` }));
            }
      };
      const handleInputChild = () => {
            setVisible(false);
            setTimeout(() => {
                  setAddChild(true);
            }, 0);
      }
      const relationshipOwnerV2 = relationshipOwner.filter(d => d.id != 0

      )
      async function handleAddChild() {
            const formattedDate = moment(child.birthday).format('YYYY-MM-DD');
            if (!child.full_name) return dispatch(setToast({ ...listToast[1], detail: "Tên cư dân không được để trống" }))
            if (!child.gender) return dispatch(setToast({ ...listToast[1], detail: "Giới tính không được để trống" }))

            if (!(child.type || child.type === 0)) return dispatch(setToast({ ...listToast[1], detail: "Mối quan hệ với chủ hộ không được để trống" }))
            const response = await addChildResidentApi({ ...child, birthday: formattedDate });
            if (response.data.status) {
                  dispatch(setToast({ ...listToast[0], detail: 'Thêm cư dân thành công' }))
                  setAddChild(false)
                  setVisibled(false)
                  setRender(prevRender => !prevRender);
                  resetForm()
            } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
      }
      const handleAddResident = async () => {
            if (user.type === undefined || user.type === null) return dispatch(setToast({ ...listToast[1], detail: "Bạn chưa chọn chọn quan hệ với chủ hộ" }));
            const params = {
                  full_name: user.full_name,
                  gender: user.gender,
                  birthday: user.birthday ? moment(user.birthday).format('YYYY-MM-DD') : undefined,
                  type: user.type,
                  apartment_id: id,
                  email: user.email || undefined,
                  phone: user.phone || undefined,
                  resident_id: idz
            };
            const checkUser = dataResident.find(d => d.id === idz);
            if (checkUser) {
                  return dispatch(setToast({ ...listToast[1], detail: "Cư dân này đã là thành viên của căn hộ này." }));
            } else {

                  const data = await addRoleApi(params)
                  if (data.data.status) {
                        setRender(prevRender => !prevRender);
                        setVisibled(false)
                        resetForm()
                        return dispatch(setToast({ ...listToast[0], detail: "Thêm mới cư dân thành công" }))
                  } else dispatch(setToast({ ...listToast[1], detail: data.data.mess }))
            }
      }
      const handleKeyPress = (event) => {
            if (event.key === 'Enter' && visible) {
                  handleCheck();
            }
      };
      const handleAddResidentApartment = async () => {
            if (!user.full_name) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng nhập tên cư dân" }))
            if (!(user.type || user.type === 0)) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn mối quan hệ với chủ hộ" }))
            else {
                  const params = {
                        full_name: user.full_name,
                        gender: user.gender,
                        birthday: user.birthday ? moment(user.birthday).format('YYYY-MM-DD') : undefined,
                        type: user.type,
                        apartment_id: id,
                        email: user.email || undefined,
                        phone: user.phone || undefined,
                  }
                  const data = await addResidentApi(params)
                  if (data.data.status) {
                        setRender(prevRender => !prevRender);
                        setVisibled(false)
                        resetForm()
                        return dispatch(setToast({ ...listToast[0], detail: "Thêm mới cư dân thành công" }))
                  } else dispatch(setToast({ ...listToast[1], detail: data.data.mess }))
            }
      }
      return (
            <div>
                  <div>
                        {showSuccessDialog && (
                              <CustomModal
                                    visible={showSuccessDialog}
                                    message={message}
                                    header="X-Building"
                                    icon="pi pi-check"
                                    acceptClassName="p-button-success"
                                    acceptLabel="OK"
                                    onHide={() => setShowSuccessDialog(false)}
                              />
                        )}
                        <Dialogz header="Kiểm  tra email, số điện thoại" visible={visible} setVisible={setVisible} closable={false}   >
                              <div className="card mt-4">
                                    <div className="col-12 lg:col-12">
                                          <InputForm
                                                value={user.input}
                                                onChange={(e) => setUser({ ...user, input: e.target.value })}
                                                onKeyPress={handleKeyPress}
                                                label="Email hoặc Số điện thoại (*)"
                                                required={!user.input || !validateInput(user.input)}
                                          />
                                    </div>
                                    <div className='ml-3'>
                                          <Button onClick={handleInputChild} severity="help" label="Trường hợp cư dân chưa có số điện thoại, email, chứng minh thư " text raised />
                                    </div>
                              </div>
                              <div className="justify-content-end flex gap-3">
                                    <Button
                                          label="Trở về"
                                          icon="pi pi-arrow"
                                          severity="danger"
                                          onClick={() => setVisible(false)}
                                          raised
                                    />
                                    <Button
                                          label="Kiểm tra "
                                          icon="pi pi-check-circle"
                                          severity="info"
                                          onClick={() => handleCheck()}
                                          raised
                                    />
                              </div>
                        </Dialogz>
                        <LoadDialog visible={addChild} />

                        <Dialogz header="Thêm mới cư dân" visible={addChild} closable={false}  >
                              <div className='card mt-4'>
                                    <InputForm
                                          id="full_name"
                                          value={child.full_name}
                                          onChange={(e) => setChild({ ...child, full_name: e.target.value })}
                                          label="Tên dân cư (*)"
                                          required
                                    />
                                    <MultiRadioButton
                                          value={child.gender}
                                          onChange={(e) => setChild({ ...child, gender: e })}
                                          options={genders}
                                          className="w-full"
                                          label="Giới tính (*)"
                                    />
                                    <CalendarForm
                                          value={child.birthday}
                                          dateFormat="dd/mm/yy"
                                          onChange={(e) => setChild({ ...child, birthday: e.target.value })}
                                          label="Ngày sinh"
                                    />

                                    <DropdownForm
                                          value={child.type}
                                          options={relationshipOwnerV2}
                                          onChange={(e) => setChild({ ...child, type: e.target.value })}
                                          label="Quan hệ với chủ hộ (*)"
                                    />
                              </div>
                              <div className="justify-content-end flex gap-3">
                                    <Button
                                          label="Trở về"
                                          icon="pi pi-arrow"
                                          severity="danger"
                                          onClick={() => setAddChild(false)}
                                          raised
                                    />
                                    <Button
                                          label="Xác nhận"
                                          icon="pi pi-check"
                                          severity="info"
                                          onClick={handleAddChild}
                                          raised
                                    />
                              </div>
                        </Dialogz>
                  </div>
                  <Dialogz header="Thêm mới cư dân" visible={visibled} setVisible={setVisibled}>
                        <InputForm label="Tên cư dân (*)"
                              value={user.full_name}
                              onChange={(e) => setUser({ ...user, full_name: e.target.value })}
                              disabled={idz ? true : false}

                        />
                        <MultiRadioButton
                              value={user.gender}
                              onChange={(e) => setUser({ ...user, gender: e })}
                              options={genders}
                              className="w-full"
                              label="Giới tính (*)"
                              disabled={idz ? true : false}

                        />
                        <InputForm
                              value={user.email}
                              onChange={(e) => setUser({ ...user, email: e.target.value })}
                              label="Email đăng nhập"
                              disabled={idz ? true : false}

                        />
                        <InputForm

                              value={user.phone}
                              onChange={(e) => setUser({ ...user, phone: e.target.value })}
                              label="Số điện thoại đăng nhập"
                              type="phone"
                              disabled={idz ? true : false}

                        />
                        <CalendarForm
                              value={user.birthday}
                              dateFormat="dd/mm/yy"
                              onChange={(e) => setUser({ ...user, birthday: e.target.value })}
                              label="Ngày sinh (*)"
                              disabled={idz ? true : false}

                        />
                        <DropdownForm
                              value={user.type}
                              options={relationshipOwner}
                              onChange={(e) => setUser({ ...user, type: e.target.value })}
                              label="Quan hệ với chủ hộ (*)"
                        />
                        <div className='flex justify-content-end gap-2 pt-2'>
                              <Button label="Trở về" onClick={() => setVisibled(false)} severity="danger" />
                              <Button label="Thêm cư dân" onClick={idz ? handleAddResident : handleAddResidentApartment} />
                        </div>
                  </Dialogz>
            </div>
      )
}

export default AddResidentApartment
