import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    TimeBody,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { useCountParking, useListParking } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Calendarz value={filter.status} className="lg:col-6" onChange showIcon placeholder="Thời gian" />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Loại xe"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Phân loại"
            />
        </GridForm>
    )
}

export default function ParkingGeneral() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListParking({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountParking({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            {/* <div style={{ marginBottom: 30 }}>
                <div className="shadow-1 p-3">
                    <b className="flex font-bold">GIÁM SÁT BÃI XE</b>
                </div>
                <div className="p-3 shadow-3 border-round-top-sm">
                    <b className="font-bold">Tổng lượt xe</b>
                    <div className="flex">
                        <div className="flex-1 ">
                            <div className=" ">
                                <div className="flex justify-content-start my-2 ">
                                    <span className="text-xs border-1 p-1 border-round-sm border-gray-500 ">6:00 7/8 - 18:00 7/8</span>
                                </div>
                                <h1 className="font-bold text-7xl text-orange-500">582.123</h1>
                            </div>
                        </div>
                        <div className="flex-1 border-left-2 border-gray-600">
                            <div className="px-3">
                                <div className="flex align-items-center gap-5">
                                    <i className="pi pi-car" style={{ fontSize: 70 }} />
                                    <h1 className="font-bold text-5xl text-orange-500 mb-0 font-bold pt-2">582.123</h1>
                                </div>
                                <div>
                                    <div className="flex gap-5">
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <b className="font-bold"> Lượt vào: </b>
                                        </div>
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <i className="pi pi-arrow-right"></i>
                                        </div>
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <b className="font-bold">100.000</b>
                                        </div>
                                    </div>
                                    <div className="flex gap-5">
                                        <div className="p-0 align-items-center my-2 ">
                                            <b className="font-bold"> Lượt vào: </b>
                                        </div>
                                        <div className="p-0 align-items-center my-2 ">
                                            <i className="pi pi-arrow-left"></i>
                                        </div>
                                        <div className="p-0 align-items-center my-2 ">
                                            <b className="font-bold">100.000</b>
                                        </div>
                                    </div >
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 ">
                            <div className="border-x-2 border-gray-600 px-3 ">
                                <div className="">
                                    <div className="flex align-items-center gap-5">
                                        <i className="pi pi-car" style={{ fontSize: 70 }} />
                                        <h1 className="font-bold text-5xl text-orange-500 mb-0 font-bold pt-2">582.123</h1>
                                    </div>
                                    <div className="flex gap-5">
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <b className="font-bold"> Lượt vào: </b>
                                        </div>
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <i className="pi pi-arrow-right"></i>
                                        </div>
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <b className="font-bold">100.000</b>
                                        </div>
                                    </div>
                                    <div className="flex gap-5">
                                        <div className="p-0 align-items-center my-2 ">
                                            <b className="font-bold"> Lượt vào: </b>
                                        </div>
                                        <div className="p-0 align-items-center my-2 ">
                                            <i className="pi pi-arrow-left"></i>
                                        </div>
                                        <div className="p-0 align-items-center my-2 ">
                                            <b className="font-bold">100.000</b>
                                        </div>
                                    </div >

                                </div>
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="px-3">
                                <div className="flex align-items-center gap-5">
                                    <i className="pi pi-car" style={{ fontSize: 70 }} />
                                    <h1 className="font-bold text-5xl text-orange-500 mb-0 font-bold pt-2">582.123</h1>
                                </div>
                                <div>
                                    <div className="flex gap-5">
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <b className="font-bold"> Lượt vào: </b>
                                        </div>
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <i className="pi pi-arrow-right"></i>
                                        </div>
                                        <div className="p-0 align-items-center my-2 mt-3">
                                            <b className="font-bold">100.000</b>
                                        </div>
                                    </div>
                                    <div className="flex gap-5">
                                        <div className="p-0 align-items-center my-2 ">
                                            <b className="font-bold"> Lượt vào: </b>
                                        </div>
                                        <div className="p-0 align-items-center my-2 ">
                                            <i className="pi pi-arrow-left"></i>
                                        </div>
                                        <div className="p-0 align-items-center my-2 ">
                                            <b className="font-bold">100.000</b>
                                        </div>
                                    </div >
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
            <HeaderListForm title="Tổng hợp xe ra vào" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                // header={RenderHeader({ title: 'Tổng hợp xe ra vào', route: '/parking_general' })}
                title="bãi xe"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/parking_general"
                headerInfo={{}}
            >
                <Columnz field="name" header="Phân loại" />
                <Columnz header=" Thời gian " body={(e) => TimeBody(e.created_at)} />
                <Columnz field="image" header="Hình ảnh" />
                <Columnz field="category" header="Loại xe" />
                <Columnz field="hotline" header="Biển kiểm soát" />
                <Columnz field="note" header="Ghi chú" />
            </DataTablez>
        </div>
    )
}
