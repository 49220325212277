import { useEffect, useState } from 'react'
import {
    ListCarParkingForControl,
    listCarParkingAi,
    listCarParkingLogAi,
    listCarParkingLogPm,
    listCarParkingPm,
    listHandRecognition,
} from '../api'

export const useListCarParkingAI = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCarParkingAi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return [data, fetchData]
}
export const useListCarParkingPM = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCarParkingPm({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return [data, fetchData]
}
export const useListCarParkingLogAI = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCarParkingLogAi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return [data, fetchData]
}
export const useListCarParkingLogPM = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCarParkingLogPm({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return [data, fetchData]
}
export const useListHandRecognition = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listHandRecognition({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListCarParkingForControl = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await ListCarParkingForControl({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return [data, fetchData]
}
