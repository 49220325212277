import { useEffect, useState } from 'react'
import {
    listNotifyApi,
    countNotifyApi
} from '../api'


export const useListNotify = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listNotifyApi({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountNotify = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countNotifyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}