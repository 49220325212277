import { getData, postData } from '@/lib/request'

export const listBuildingApi = (params) => getData('web2/building_management/getBuildingPlace', params)
export const listBuildingV2Api = (params) => getData('web2/info/getListBuildingPlaceInfo', params)
export const countBuildingApi = (params) => getData('web2/building_management/countBuildingPlace', params)
export const deleteBuildingApi = (params) => postData('web2/building_management/deleteBuildingPlace', params)
export const detailBuildingApi = (params) => getData('web2/building_management/detailBuildingPlace', params)
export const addBuildingApi = (params) => postData('web2/building_management/newBuildingPlace', params)
export const updateBuildingApi = (params) => postData('web2/building_management/editBuildingPlace', params)

export const listFloorV2Api = (params) => getData('web2/building_management/getListFloorV2', params)
export const countFloorV2Api = (params) => getData('web2/building_management/countFloorV2', params)
export const detailFloorV2Api = (params) => getData('web2/building_management/detailFloor', params)
export const addFloorApi = (params) => postData('web2/building_management/createFloor', params)
export const updateFloorApi = (params) => postData('web2/building_management/editFloorV2', params)
export const deleteFloorApi = (params) => postData('web2/building_management/deleteFloor', params)
