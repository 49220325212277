import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { checkJson, removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm, DropdownForm, InputSwitchForm, MultiSelectForm } from '@/components'
import { TabView, TabPanel } from 'primereact/tabview'
import Users from '../users/Users'
import { removeUndefinedProps } from '@/utils'
import { addGroupApi, detailGroupApi, listExchangeV2Api, listProjectApi, listUserV2Api, updateGroupApi } from '@/api'
import { useGetApi } from '@/hooks'
import { validate } from '@/lib'
import { useSelector } from 'react-redux'

const initInfos = {
    name: '',
    phone_number: '',
    email: '',
    address: '',
    description: '',
    code: '',
    status: true,
    type: 1,
}

const GroupInfo = (props) => {
    const { infos, setInfos } = props
    const projects = useGetApi(listProjectApi, {}, [])
    const exchanges = useGetApi(listExchangeV2Api, {}, [])
    const users = useSelector(state => state.users)

    useEffect(() => {
        const selectedExchange = exchanges.find((exchange) => exchange.id === infos.bdc_department_id)
        const buildingIds = selectedExchange ? selectedExchange.building_id : []
        if (projects && projects[0] && buildingIds) {
            if (buildingIds === '*') {
                setInfos({ ...infos, buildingOptions: projects })
            } else {
                setInfos({ ...infos, buildingOptions: projects.filter((project) => buildingIds.includes(project.id)) })
            }
        }
    }, [infos.bdc_department_id, exchanges, projects])

    return (
        <div className="card bg-color">
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên bộ phận (*)"
                    />
                    <InputForm
                        id="code"
                        value={infos.code}
                        onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                        label="Mã bộ phận (*)"
                    />
                    <DropdownForm
                        id="bdc_department_id"
                        value={infos.bdc_department_id}
                        options={exchanges}
                        onChange={(e) => {
                            setInfos({ ...infos, bdc_department_id: e.target.value, building_id: undefined, main_user_id: undefined })
                        }}
                        label="Phòng ban (*)"
                    />
                    <DropdownForm
                        id="main_user_id"
                        value={infos.main_user_id}
                        onChange={(e) => setInfos({ ...infos, main_user_id: e.target.value })}
                        options={infos.bdc_department_id
                            ? (Number(infos.id)
                                ? users.filter(u => !u.group_id || (u.group_id === Number(infos.id))) : users.filter(u => !u.group_id))
                            : []}
                        optionLabel="full_name"
                        optionValue="user_id"
                        label="Trưởng bộ phận"
                        showClear
                    />
                </div>
                <div className="col-12 lg:col-6">
                    <MultiSelectForm
                        id="building_id"
                        options={infos.bdc_department_id ? infos.buildingOptions : []}
                        value={infos.building_id}
                        onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                        label="Dự án"
                        disabled={!infos.bdc_department_id}
                    />
                    <InputForm
                        id="email"
                        value={infos.email}
                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                        label="Email "
                        type="email"
                    />
                    <InputForm
                        id="phone_number"
                        value={infos.phone_number}
                        onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                        label="Số điện thoại "
                        type="phone"
                    />
                </div>
            </div>
            <InputTextareaForm
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Ghi chú"

            />
            <InputSwitchForm
                id="status"
                checked={infos.status}
                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
            />
        </div>
    )
}

const UpdateGroup = () => {
    const { id } = useParams()
    const detail = useGetApi(detailGroupApi, { id }, {})
    const [infos, setInfos] = useState(initInfos)

    useEffect(() => {
        if (detail.id)
            setInfos({
                ...infos,
                ...removeUndefinedProps(detail),
                status: Number(detail.status) !== 0,
                building_id: checkJson(detail.building_id) || detail.building_id,
            })
    }, [detail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (!infos.name) return 'Bạn chưa nhập tên bộ phận'
        if (!infos.code) return 'Bạn chưa nhập mã bộ phận'
        if (!infos.bdc_department_id) return 'Bạn chưa chọn phòng ban'
        if (infos.email && (!validate('email', infos.email))) {
            return 'Email không đúng định dạng '
        }
        if (infos.phone_number && (!validate('phone', infos.phone_number))) {
            return 'Số điện thoại không đúng định dạng'
        }
        if (Number(id)) info = { ...removePropObject(info, detail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="bộ phận"
            handleData={handleData}
            route="/group"
            actions={{ add: addGroupApi, update: updateGroupApi }}
            refreshObjects={[setInfos]}
        >
            <TabView>
                <TabPanel header="Chi tiết">
                    <GroupInfo infos={infos} setInfos={setInfos} />
                </TabPanel>
                {Number(id) && (
                    <TabPanel header="Danh sách nhân sự">
                        <Users group_id={Number(id)} />
                    </TabPanel>
                )}
            </TabView>
        </FormUpdate>
    )
}

export default UpdateGroup
