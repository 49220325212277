import { listUserApi } from '@/api';
import { CalendarForm, DropdownForm, HeaderListForm, InputForm, InputNumberForm, UploadFiles, useGetParams } from '@/components';
import { listToast, product_category, status_input_warehouse } from '@/constants';
import { useGetApi } from '@/hooks';
import { databaseDate } from '@/lib';
import { listPosition } from '@/modules/hrm/position_management/api';
import { setToast } from '@/redux/features';
import moment from 'moment';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { listAssetManager } from '../../asset_managerment/api';
import { useListUnit } from '../../asset_managerment/utils';
import { useListAsset, useListAssetMix } from '../../asset_mix_management/utils';
import { createBill, listDepartmentWareHouse } from '../api';
function CreateInputForm() {
      const userInfo = useSelector((state) => state.userInfo)
      const users = useSelector((state) => state.users)
      const user_id = userInfo.user_id
      const listDepartment = useGetApi(listDepartmentWareHouse)

      const defaultDepartment = listDepartment?.find(department => department.type === 9);

      const [infos, setInfos] = useState({ create_by: user_id, code: generateCode(), })
      useEffect(() => {
            setInfos({ ...infos, department_receive: defaultDepartment?.id })
      }, [listDepartment])
      const [files, setFiles] = useState([])

      function generateCode() {
            const currentTime = new Date();
            const formattedTime = `${currentTime.getFullYear()}${(currentTime.getMonth() + 1).toString().padStart(2, '0')}${currentTime.getDate().toString().padStart(2, '0')}_${currentTime.getHours().toString().padStart(2, '0')}${currentTime.getMinutes().toString().padStart(2, '0')}${currentTime.getSeconds().toString().padStart(2, '0')}`;
            return `PN_${formattedTime}`;
      }
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const unit = useListUnit()
      const [data, setData] = useState([])
      const product = useListAsset({ status: undefined })

      const dispatch = useDispatch()
      const navigate = useNavigate()
      const person = useGetApi(listUserApi, { ...params, first: undefined, type: 'hrm' }, [])
      const group = useListAssetMix({ status: undefined })

      const handleChange = (value, field, index) => {
            setData(prevData => {
                  const newData = [...prevData];
                  newData[index] = { ...newData[index], [field]: value };
                  return newData;
            });
      };
      const handleDelete = (indexV2) => {
            if (indexV2 || indexV2 === 0) setData((pre) => pre.filter((d, index) => index !== indexV2))
      }
      const handleAdd = () => {
            const newItem = {};
            setData((prevData) => [...prevData, newItem]);
      }

      const handleData = async () => {
            if (!infos.create_date) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn thời gian nhập" }))
            if (!infos.code) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng nhập mã phiếu nhập" }))
            if (!infos.status_input) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn trạng thái nhập" }))
            if (infos.status_input === 1 && !infos.department_hand_over) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn phòng ban bàn giao" }))
            if (infos.status_input === 1 && !infos.user_hand_over) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người bàn giao" }))
            if (!infos.user_receive) return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn người tiếp nhận" }))
            if (infos.status_input === 2 && data && data.some(d => d.produc_id === null || d.produc_id === undefined || d.produc_id === ''))
            {
                  return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn sản phẩm" }))
            }
            if (infos.status_input === 1 && data && data.some(d => d.productor === null || d.productor === undefined || d.productor === ''))
            {
                  return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn sản phẩm" }))
            }
            if (infos.status_input === 2 && data && data.some(d => d.unit_id === null || d.unit_id === undefined || d.unit_id === ''))
            {
                  return dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn đơn vị" }))
            }
            if (infos.status_input === 1 && data.some(d => d.group_product === 'tieu_hao' && (!d.amount || d.amount > 100000)))
            {
                  return dispatch(setToast({ ...listToast[1], detail: "Số lượng không được để trống và phải nhỏ hơn hoặc bằng 100000" }));
            }
            if (infos.status_input === 2 && data.some(d => d.type === 'tieu_hao' && (!d.amount || d.amount > 100000)))
            {
                  return dispatch(setToast({ ...listToast[1], detail: "Số lượng không được để trống và phải nhỏ hơn hoặc bằng 100000" }));
            }
            let info = { ...infos, create_date: databaseDate(infos.create_date, false, 'date'), }
            if (files && files[0])
            {
                  info.file = files
            } else info.file = []
            if (infos.status_input === 1 && data.some(d => d.group_product === 'tieu_hao'))
            {
                  for (let i = 0; i < data.length; i++)
                  {
                        if (parseInt(data[i].amount) > data[i].soluong)
                        {
                              return dispatch(setToast({ ...listToast[1], detail: `Số lượng luân chuyển ở sản phẩm không được lớn hơn số lượng hiện có` }));
                        }
                  }
            }
            let newData = data.map(obj => {
                  let newObj = { ...obj };
                  delete newObj.soluong;
                  return newObj;
            });
            const params = {
                  ...info, type: 'imp', list_product: newData.map(item => ({
                        ...item,
                        warranty_period: item.status !== 0 ? moment(item.warranty_period).format('YY-MM-DD') : item.warranty_period
                  }))
            }
            const call = await createBill(params)
            if (!call.data.status)
            {
                  dispatch(setToast({ ...listToast[1], detail: call.data.mess }))
            } else
            {
                  dispatch(setToast({ ...listToast[0], detail: "Tạo phiếu nhập thành công" }))
                  navigate('/warehouse_management')
            }
      }

      const userWithExChanges = person.filter(d => d.group_id === infos.department_hand_over)
      const userWithExChangesv2 = person.filter(d => d.group_id === infos.department_receive)
      const a = users.find(d => d.user_id === infos.create_by)
      const [assetWith, setAssetWith] = useState(null)
      useEffect(() => {
            if (infos.department_hand_over && infos.user_hand_over)
            {
                  const fetchData = async () => {
                        const rolate_slip = await listAssetManager({
                              department_id: infos.department_hand_over,
                              user_id: infos.user_hand_over
                        });
                        setAssetWith(rolate_slip?.data?.data);
                  };
                  fetchData();
            }
      }, [infos.department_hand_over, infos.user_hand_over]);
      // const product = useListAsset({ status: undefined })
      const assetFind = useListAsset({ status: undefined, })

      assetWith?.forEach(item2 => {
            assetFind?.forEach(item1 => {
                  if (item1.id === item2.product_id)
                  {
                        item2.name = item1.name;
                  }
            });
      });
      const work = useGetApi(listPosition, { ...params }, [])
      const getAvailableServices = () => {
            const value = data.map(item => item.id);
            return assetWith?.filter(d => !value.includes(d.id));
      };
      const getProduct = () => {
            const value = data.map(item => item.id);

            return product.filter(d => !value.includes(d.id));
      };

      return (
            <div>
                  <h3 >Tạo phiếu nhập</h3>
                  <div className="card">
                        <HeaderListForm title="Thông tin chung" />
                        <div className="grid">
                              <div className="col-6">
                                    <CalendarForm label="Thời gian nhập (*)"
                                          value={infos.create_date}
                                          onChange={(e) => setInfos({ ...infos, create_date: e.target.value })} />
                              </div>
                              <div className="col-6">
                                    <InputForm label="Mã phiếu nhập (*)"
                                          value={infos.code}
                                          // onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                          disabled
                                    />
                              </div>
                              {a && <div className="col-6">
                                    <DropdownForm label="Người tạo phiếu (*)"
                                          value={infos.create_by}
                                          onChange={(e) => setInfos({
                                                ...infos, create_by: e.target.value
                                          })}
                                          disabled
                                          options={users}
                                          optionLabel='full_name'
                                          optionValue='user_id'
                                    />
                              </div>}
                              <div className="col-6">
                                    <DropdownForm label="Trạng thái nhập (*)" value={infos.status_input} options={status_input_warehouse} onChange={(e) => setInfos({
                                          ...infos,
                                          status_input: e.target.value
                                    })} />
                              </div>
                              <div className="col-6">
                                    <UploadFiles files={files} setFiles={setFiles} />

                              </div>

                        </div>
                        {infos.status_input === 1 &&
                              <>
                                    <HeaderListForm title="Thông tin bàn giao" />
                                    <div className="grid">
                                          <div className="col-6">
                                                <DropdownForm label="Phòng ban bàn giao  (*)"
                                                      value={infos.department_hand_over}
                                                      options={listDepartment}
                                                      onChange={(e) => setInfos({ ...infos, department_hand_over: e.target.value })} />
                                          </div>
                                          <div className="col-6 grid">
                                                <div className="col-8">
                                                      <DropdownForm
                                                            options={userWithExChanges}
                                                            label="Người bàn giao (*)"
                                                            optionLabel="full_name"
                                                            value={infos.user_hand_over}
                                                            onChange={(e) => {
                                                                  const selectedUser = userWithExChanges.find(user => user.id === parseInt(e.target.value));
                                                                  setInfos({ ...infos, user_hand_over: e.target.value, mnv: selectedUser?.code });
                                                            }} />
                                                </div>
                                                <div className="col-4">
                                                      <InputForm
                                                            label="Mã nhân viên "
                                                            value={infos.mnv}
                                                            onChange={(e) => setInfos({ ...infos, mnv: e.target.value })}
                                                            disabled
                                                      />
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <DropdownForm label="Người phê duyệt"
                                                      value={infos.user_confirm
                                                      }
                                                      optionLabel="full_name"
                                                      options={userWithExChanges}
                                                      onChange={(e) => setInfos({
                                                            ...infos, user_confirm: e.target.value
                                                      })} />
                                          </div>
                                    </div>
                              </>
                        }
                        <HeaderListForm title="Thông tin tiếp nhận" />
                        <div className="grid">
                              <div className="col-6">
                                    <DropdownForm
                                          label="Phòng ban tiếp nhận (*)"
                                          value={infos.department_receive}
                                          options={listDepartment}
                                          // onChange={(e) => setInfos({ ...infos, department_receive: e.target.value })}
                                          disabled
                                    />
                              </div>

                              <div className="col-6 grid">
                                    <div className="col-8">
                                          <DropdownForm label="Người tiếp nhận (*)"
                                                value={infos.user_receive
                                                }
                                                options={userWithExChangesv2}
                                                optionLabel="full_name"
                                                onChange={(e) => {
                                                      const selectedUser = userWithExChangesv2.find(user => user.id === parseInt(e.target.value));
                                                      const position = selectedUser && selectedUser.bills[0] ? selectedUser.bills[0].position : '';
                                                      setInfos({
                                                            ...infos, user_receive: e.target.value, position: position
                                                      });
                                                }}
                                          />
                                    </div>
                                    <div className="col-4">
                                          <DropdownForm label="Chức vụ "
                                                value={infos.position}
                                                options={work}
                                                onChange={(e) => setInfos({ ...infos, position: e.target.value })} disabled />
                                    </div>
                              </div>
                              <div className="col-6">
                                    <InputForm label="Vị trí sử dụng"
                                          value={infos.position_use}
                                          onChange={(e) => setInfos({ ...infos, position_use: e.target.value })} />
                              </div>
                              <div className="col-6 grid">
                                    <div className="col-8">
                                          <DropdownForm label="Người sử dụng "
                                                value={infos.user_use
                                                }
                                                options={userWithExChangesv2}
                                                optionLabel="full_name"
                                                onChange={(e) => {
                                                      const selectedUser = userWithExChangesv2.find(user => user.id === parseInt(e.target.value));
                                                      const position = selectedUser && selectedUser.bills[0] ? selectedUser.bills[0].position : '';

                                                      setInfos({
                                                            ...infos, user_use: e.target.value, positionv2: position
                                                      });
                                                }}
                                          />
                                    </div>
                                    <div className="col-4">
                                          <DropdownForm label="Chức vụ "
                                                value={infos.positionv2}
                                                options={work}
                                                onChange={(e) => setInfos({ ...infos, positionv2: e.target.value })}
                                                disabled />
                                    </div>
                              </div>
                        </div>
                        <HeaderListForm title="Thông tin sản phẩm nhập kho" />
                        <div className="grid grid-form align-items-center">
                              {data.map((d, index) => {
                                    const option = getAvailableServices()?.concat(assetWith.filter((d) => d.id === d.id));
                                    const optionv2 = getProduct()?.concat(product.filter((p) => p.id === d.id));
                                    const newOption = option?.map(item => {
                                          return { ...item, name: item.name + " " + item.code };
                                    });
                                    return (
                                          <div key={index} className="grid grid-form w-full  align-items-center mx-1 card">
                                                <div className="col-12 lg:col-3">
                                                      {
                                                            infos.status_input === 1 ? <DropdownForm
                                                                  label="Tên sản phẩm (*)"
                                                                  optionLabel="name"
                                                                  options={newOption}
                                                                  value={d.productor}
                                                                  optionValue="id"
                                                                  onChange={(e) => {
                                                                        handleChange(e.target.value, 'productor', index, setData(prevData => {
                                                                              const selectedProductId = e.target.value;
                                                                              const value = assetWith.find(d => d.id === selectedProductId)
                                                                              const findGroup = group.find(d => d.id === value?.product_cate_id)
                                                                              let newData = [...prevData];
                                                                              newData[index] = {
                                                                                    asset_id: value?.id,
                                                                                    code: value?.code,
                                                                                    group_product: value?.group_product,
                                                                                    product_cate_id: findGroup?.id,
                                                                                    unit_id: value?.unit_id,
                                                                                    status: value?.status,
                                                                                    product_id: value?.product_id,
                                                                                    id: value?.id,
                                                                                    soluong: value?.amount
                                                                              };
                                                                              return newData;
                                                                        }))
                                                                  }
                                                                  }
                                                            />
                                                                  : <DropdownForm
                                                                        label="Tên sản phẩm (*)"
                                                                        options={optionv2}
                                                                        value={d.produc_id}
                                                                        onChange={(e) => {
                                                                              const a = product.find(d => d.id === e.target.value)
                                                                              const b = group.find(d => d.id === a?.product_group_id)
                                                                              const c = group.find(d => d.id === a?.product_group_id)
                                                                              setData(prevData => {
                                                                                    const newData = [...prevData];
                                                                                    newData[index] = {
                                                                                          ...a,
                                                                                          produc_id: e.target.value,
                                                                                          type: b?.type,
                                                                                          unit_id: a?.unit_id,
                                                                                          product_cate_id: c?.id
                                                                                    };
                                                                                    return newData;
                                                                              });
                                                                        }}
                                                                  />
                                                      }
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      <InputForm
                                                            label="MSP (*)"
                                                            value={d.code}
                                                            disabled
                                                      />
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                      {infos.status_input === 1 ? <InputForm
                                                            label="Phân loại (*)"
                                                            value={(() => {
                                                                  const category = product_category.find(item => item.id === d.group_product);
                                                                  return category ? category.name : "";
                                                            })()}
                                                            disabled
                                                      /> :

                                                            <InputForm
                                                                  label="Phân loại (*)"
                                                                  value={(() => {
                                                                        const category = product_category.find(item => item.id === d.type);
                                                                        return category ? category.name : "";
                                                                  })()}
                                                                  disabled
                                                            />}
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      <DropdownForm
                                                            label="Nhóm sản phẩm (*)"
                                                            options={group}
                                                            value={d.product_cate_id}
                                                            disabled
                                                      />
                                                </div>
                                                <div className="col-12 lg:col-3">
                                                      {infos.status_input === 1 ? <DropdownForm
                                                            label="Đơn vị (*)"
                                                            value={d.unit_id}
                                                            disabled
                                                            options={unit}
                                                      /> : <DropdownForm
                                                            label="Đơn vị (*)"
                                                            value={d.unit_id}
                                                            onChange={(e) => handleChange(e.target.value, 'unit_id', index)}
                                                            options={unit}
                                                      />
                                                      }

                                                </div>

                                                {infos.status_input === 1 && d.group_product === 'tieu_hao' ? <div className="col-12 lg:col-3">
                                                      <InputNumberForm
                                                            label="Số lượng (*)"
                                                            value={d.amount}
                                                            handleOnChange={(e) => handleChange(e, 'amount', index)}
                                                      />
                                                </div> : ''}
                                                {infos.status_input === 2 && d.type === 'tieu_hao' ? <div className="col-12 lg:col-3">
                                                      <InputNumberForm
                                                            label="Số lượng (*)"
                                                            value={d.amount}
                                                            handleOnChange={(e) => handleChange(e, 'amount', index)}
                                                            required
                                                      />
                                                </div> : ''}

                                                <div className="col-12 lg:col-3">
                                                      <InputForm
                                                            label="Ghi chú "
                                                            value={d.detail}
                                                            onChange={(e) => handleChange(e.target.value, 'detail', index)}
                                                      />
                                                </div>

                                                <div className="col-12 lg:col-3">
                                                      <Button
                                                            type="button"
                                                            raised
                                                            icon="pi pi-times"
                                                            severity="danger"
                                                            disabled={data.length <= 1}
                                                            onClick={() => handleDelete(index)
                                                            }
                                                      />
                                                </div>
                                                {infos.status_input === 1 && <div className="col-12">
                                                      <p>Số lượng hiện có  : {d.soluong}</p>
                                                </div>}
                                          </div>
                                    )
                              })}
                        </div>
                        <Button
                              type="button"
                              raised
                              icon="pi pi-plus"
                              severity="info"
                              onClick={() => handleAdd()}
                              className=" mt-3"
                              label='Thêm sản phẩm'
                              disabled={infos.status_input ? false : true}
                        />
                  </div>

                  <div className='flex justify-content-end gap-3 pt-3'>
                        <Button label='Trở về' onClick={() => navigate('/warehouse_management')} severity='danger' />
                        <Button label='Tạo phiếu nhập' onClick={handleData} />
                  </div>
            </div>
      )
}

export default CreateInputForm
