import {
    Body,
    CalendarForm,
    Calendarz,
    Columnz,
    DataTablez,
    Dialogz,
    DropdownForm,
    Dropdownz,
    GridForm,
    HeaderListForm,
    InputForm,

    Inputz,

    NumberFormat, TimeBody,
    useGetParams
} from '@/components';
import { customer_payments_type, listToast } from '@/constants';
import { Button } from '@/uiCore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    deleteReceiptExpenditureApi,
    exportReceiptApi,
    listAccountApi,
    renderReceipt,
    updateListReceiptApi
} from '../api';
import { useCountReceiptExpenditure, useListApartmentName, useListReceiptExpenditure } from '../utils';

import { useGetApi } from "@/hooks";
import { setToast } from '@/redux/features';
import { formatNumber } from '@/utils';
import { useDispatch, useSelector } from 'react-redux';
import ImportReceipt from './ImportReceipt';


export const listReceptType = [
    { id: "phieu_thu", name: 'Phiếu thu' },
    { id: "phieu_bao_co", name: 'Phiếu báo có' },
    { id: "phieu_thu_khac", name: 'Phiếu thu khác' },
    { id: "phieu_chi", name: 'Phiếu chi' },
    { id: "phieu_chi_khac", name: 'Phiếu chi khác' },
    { id: "phieu_ke_toan", name: 'Phiếu kế toán' },
    { id: "phieu_dieu_chinh_giam", name: 'Phiếu điều chỉnh' },
    { id: "phieu_thu_ky_quy", name: 'phiếu thu ký quỹ' },
]

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useListApartmentName()
    const buildings = useSelector((state) => state.buildings)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} >
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo mã chứng từ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.type_payment}
                options={customer_payments_type}
                onChange={(e) => setFilter({ ...filter, type_payment: e.target.value })}
                placeholder="Hình thức"
            />
            <Dropdownz
                value={filter.place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
                showClear
            />
            <Dropdownz
                value={filter.bdc_apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, bdc_apartment_id: e.target.value })}
                placeholder="Căn hộ"
            />
            {/*<Dropdownz*/}
            {/*    value={filter.status}*/}
            {/*    options={status}*/}
            {/*    onChange={(e) => setFilter({ ...filter, status: e.target.value })}*/}
            {/*    placeholder="Người thu"*/}
            {/*/>*/}
            <Calendarz
                value={filter.dates}
                selectionMode="range"
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                placeholder="Khoảng ngày lập phiếu"
            />
            <Calendarz
                value={filter.datez}
                selectionMode="range"
                onChange={(e) => setFilter({ ...filter, datez: e.target.value })}
                placeholder="Khoảng ngày hạch toán"
            />
            <Dropdownz
                value={filter.type}
                onChange={(e) => setFilter({ ...filter, type: e.value })}
                options={listReceptType}
                placeholder="Kiểu phiếu"
            ></Dropdownz>
        </GridForm>
    )
}

const ListReceiptExpenditure = () => {
    const listAccount = useGetApi(listAccountApi, {}, [])
    const [render, setRender] = useState(false)
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [reason, setReason] = useState('')
    const [detailData, setDetailData] = useState(null)
    const initParams = {
        customer_name: '',
        description: '',
        type_payment: '',
        create_date: '',
    };
    const [infos, setInfos] = useState(initParams);

    const [showInputDialog, setShowInputDialog] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const data = useListReceiptExpenditure({ status: undefined, ...params, first: undefined }, render)
    const totalRecords = useCountReceiptExpenditure({ status: undefined, ...params, first: undefined }) || 0
    const navigate = useNavigate()

    const [visible, setVisible] = useState(false)
    const [visibleImport, setVisibleImport] = useState(false)
    const users = useSelector(state => state.users)

    const moreOptions = [
        {
            type: 'Lập phiếu',
            items: [
                {
                    label: 'Lập phiếu thu',
                    icon: 'pi pi-table',
                    command: () => navigate('/receipt/lap_phieu_thu')
                },
                {
                    label: 'Lập phiếu chi',
                    icon: 'pi pi-table',
                    command: () => navigate('/receipt/lap_phieu_chi')
                },
                {
                    label: 'Lập phiếu thu khác',
                    icon: 'pi pi-table',
                    command: () => navigate('/receipt/lap_phieu_thu_khac'),
                },
                {
                    label: 'Lập phiếu điều chỉnh',
                    icon: 'pi pi-table',
                    command: () => navigate('/receipt/lap_phieu_dieu_chinh'),
                },
            ],
        },

    ]
    const dispatch = useDispatch()
    const handleRender = async (id) => {
        const response = await renderReceipt({ id })
        if (response.data.status) {
            window.open(`/receipt/print_slip/${id}`, '_blank');
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
        }
    }

    const hideInput = () => {
        setShowInputDialog(false);
        setReason(null)
        setReasonError('');
    };
    const handlePopups = (e) => {
        setDetailData(e);
        setShowInputDialog(true);
    };
    const hideUpdate = () => {
        setShowUpdate(false);
        setInfos(initParams)
    };
    const handleUpdate = (e) => {
        setDetailData(e);
        setInfos({
            customer_name: e.customer_name || '',
            description: e.description || '',
            type_payment: e.type_payment || '',
            create_date: e.create_date ? new Date(e.create_date) : ''

        });
        setShowUpdate(true);
    };
    const [reasonError, setReasonError] = useState('');

    const handleData = async (e) => {
        if (!reason) {
            setReasonError('Không được để trống lý do xóa');
            return;
        }
        const params = {
            id: detailData.id,
            reason: reason
        };

        const response = await deleteReceiptExpenditureApi(params);
        if (!response.data.status) {
            dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
        } else {
            dispatch(setToast({ ...listToast[0], detail: "Bạn đã xóa thành công!!!" }))
            hideInput()
            setRender(e => !e)
        }
    };

    const handleDatav2 = async (e) => {
        const inputDate = new Date(infos.create_date);
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const day = inputDate.getDate().toString().padStart(2, '0');
        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');
        const seconds = inputDate.getSeconds().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const params = {
            id: detailData.id,
            customer_name: infos.customer_name,
            type_payment: infos.type_payment,
            create_date: formattedDate,
            description: infos.description
        }
        const response = await updateListReceiptApi(params);
        if (!infos.customer_name) {
            return dispatch(setToast({ ...listToast[1], detail: "Người tạo không được để trống" }))

        }
        setReasonError('');
        if (!response.data.status) {
            return dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
        } else {
            dispatch(setToast({ ...listToast[0], detail: "Cập nhật thành công!!!" }))
            hideUpdate()
            setParams((pre) => {
                return { ...pre, render: !pre.render }
            })
        }
    }

    const BodyExportReceipt = (e) => {
        return (
            <div className="flex gap-2 align-items-center justify-content-center">
                <Button type="button" icon="pi pi-print" onClick={event => handleRender(e.id)} />
                {e.deleted_at ? '' : (
                    <>
                        <Button type="button" icon="pi pi-eye" onClick={() => handleUpdate(e)} />
                        {e.status !== 2 && e.type !== 'phieu_dieu_chinh_giam' && <Button type="button" icon="pi pi-trash" severity="danger" onClick={() => handlePopups(e)} />}
                    </>
                )}
            </div>
        );
    }

    const getFullNameByUserId = (users, detailData) => {
        if (detailData && detailData.user_id !== null && detailData.user_id !== undefined) {
            const userIdToFind = detailData.user_id;
            const foundUser = users.find(user => user.user_id === userIdToFind);
            return foundUser ? foundUser.full_name : 'Admin';
        } else {
            return 'Không có thông tin người dùng';
        }
    };
    const fullName = getFullNameByUserId(users, detailData);
    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setReasonError('');
    };
    let getUpdate = e => {
        if (e.deleted_at && !e.updated_by) return <span></span>
        else if (e.deleted_at) return Body({
            data: users,
            value: e.updated_by,
            key: 'user_id',
            label: 'full_name',
        }) || <span>Admin</span>
    }
    return (
        <div className='card'>
            {visibleImport && <ImportReceipt visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            <HeaderListForm title="Danh sách phiếu thu/chi" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Phiếu thu/chi"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/receipt"
                headerInfo={{ moreOptions, exportApi: exportReceiptApi, setVisibleImport: setVisibleImport }}
                setVisibledDialog={setVisible}
                basePermissions={["import", "export"]}
                // rowClassName={(rowData) => rowData.deleted_at ? 'deleted-row' : ''}
                rowClassName={(rowData) => {
                    let classes = '';
                    if (rowData.deleted_at) {
                        classes += 'deleted-row';
                    }
                    if (rowData.status === 2) {
                        classes += (classes.length > 0 ? ' ' : '') + 'adjustmentVoucher-row';
                    }
                    return classes;
                }}

            >
                <Columnz field="receipt_code" header="Mã chứng từ" />
                <Columnz body={e => Body({ data: customer_payments_type, value: e.type_payment })} header="Hình thức" />
                <Columnz body={e => Body({ data: listReceptType, value: e.type })} header="Loại phiếu" />
                <Columnz body={e => TimeBody(e.created_at, 'date')} header="Ngày lập phiếu" />
                <Columnz body={e => TimeBody(e.create_date, 'date')} header="Ngày hạch toán" />
                <Columnz field="apartment_name" header="Căn hộ" />
                <Columnz field="building_place_name" header="Tòa nhà" />
                <Columnz field="customer_name" header="Khách hàng" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.cost_paid })} header="Số tiền" />
                <Columnz field="create_by" header="Người tạo" />
                <Columnz body={e => e.payment_info_id ? Body({ data: listAccount.map(l => ({ name: `${l.short_url} - ${l.bank_account}`, id: l.id })), value: e.payment_info_id }) : ""} header="Tài khoản" />
                <Columnz
                    header="Người xóa"
                    body={getUpdate}
                />
                <Columnz
                    header="Nội dung"
                    body={e => { if (e.deleted_at) return e.description }}
                />
                <Columnz body={e => BodyExportReceipt(e)} header="Thao tác" />
            </DataTablez>
            <Dialogz
                visible={showInputDialog}
                onHide={hideInput}
                header="Xác nhận xóa phiếu thu"
                className="w-10"
                modal
                footer={
                    <div>
                        <Button label="Hủy" icon="pi pi-times" onClick={hideInput} />
                        <Button label="Xóa" icon="pi pi-trash" severity="danger" onClick={handleData} />
                    </div>
                }
            >
                <div className="p-fluid">
                    <div className="grid grid-form ">
                        <div className="col-4">
                            <InputForm disabled label="Mã chứng từ" value={detailData?.receipt_code || ''} />
                            <InputForm disabled label="Hình thức" value={detailData?.type_payment || ''} />
                            <InputForm disabled label="Loại phiếu" value={detailData?.type || ''} />

                        </div>
                        <div className="col-4">
                            <InputForm disabled label="Ngày lập" value={detailData?.created_at || ''} />
                            <InputForm disabled label="Ngày hạch toán" value={detailData?.create_date || ''} />
                            <InputForm disabled label="Căn hộ" value={detailData?.apartment_name || ''} />
                        </div>

                        <div className="col-4">
                            <InputForm disabled label="Khách hàng" value={detailData?.customer_name || ''} />
                            <InputForm disabled label="Số tiền" value={formatNumber(detailData?.cost_paid) || ''} />
                            <InputForm disabled label="Người tạo" value={fullName || ''} />
                        </div>

                        <div className="col-12">
                            <InputForm
                                value={reason}
                                onChange={handleReasonChange}
                                className="w-full"
                                label="Nhập lý do xóa (*)"
                                error={!!reasonError}
                            />
                            {reasonError && <small className="p-error">{reasonError}</small>}
                        </div>
                    </div>
                </div>
            </Dialogz>

            <Dialogz
                visible={showUpdate}
                onHide={hideUpdate}
                header="Cập nhật phiếu thu chi"
                className="w-10"
                modal
                footer={
                    <div>
                        <Button label="Hủy" icon="pi pi-times" onClick={hideUpdate} />
                        <Button label="Cập nhật" icon="pi pi-check" severity="success" onClick={handleDatav2} />
                    </div>
                }
            >
                <div className="grid grid-form pt-4">
                    <div>
                        <p className='m-0' style={{ float: "right" }}>Số PT: {detailData?.receipt_code || ''}</p>
                    </div>
                    <br />
                    <div className='flex w-full flex-column align-items-center'>
                        <h4>
                            {detailData?.type &&
                                (listReceptType.find((receipt) => receipt.id === detailData.type)?.name || '')}
                        </h4>
                        <i >{detailData?.create_date || ''}</i>
                    </div>
                    <br />
                    <div className="flex align-items-center w-full">
                        <div className="col-4">
                            <InputForm
                                value={infos.customer_name}
                                onChange={(e) => setInfos((prevInfos) => ({ ...prevInfos, customer_name: e.target.value }))}
                                label="Người nộp tiền"
                            />
                            <InputForm disabled label="Căn hộ" value={detailData?.apartment_name || ''} />
                        </div>
                        <div className="col-4">
                            <DropdownForm
                                onChange={(e) => setInfos((prevInfos) => ({ ...prevInfos, type_payment: e.target.value }))}
                                options={customer_payments_type}
                                value={infos.type_payment}
                                label="Hình thức"
                            />
                            <InputForm disabled label="Số tiền" value={formatNumber(detailData?.cost_paid) || ''} />
                        </div>
                        <div className="col-4">

                            <CalendarForm
                                value={infos.create_date}
                                onChange={(e) => setInfos((prevInfos) => ({ ...prevInfos, create_date: e.target.value }))}
                                label="Ngày hạch toán"
                            />
                            <InputForm label="Nội dung" onChange={(e) => setInfos((prevInfos) => ({ ...prevInfos, description: e.target.value }))} value={infos.description} />
                        </div>
                    </div>

                </div>
            </Dialogz>
        </div>
    )
}

export default ListReceiptExpenditure
