import { useEffect, useState } from 'react'
import { listReceiptImportApi, countReceiptImportApi, detailReceiptImportApi, listServicesApi } from '../api'
import { useListService } from './../../../debts/services_list/utils/index'

export const useListReceiptImport = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listReceiptImportApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListServices = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listServicesApi({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountReceiptImport = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countReceiptImportApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailReceiptImport = (id, apartment_id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailReceiptImportApi({ id, apartment_id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        // if (apartment_id) fetchData()
        fetchData()
    }, [id, apartment_id])
    return data
}
