import {
    ActionBody, Columnz, DataTablezV3,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz, MultiSelectz,
    NumberFormat,
    TimeBody,
    useGetParams
} from '@/components'
import { listToast, services_Debt, typeServicesGroup } from '@/constants'
import { Column } from '@/uiCore'
import { formatNumber, getArrId } from '@/utils'

import { useGetApi } from "@/lib/react-query"
import LogInvoice from "@/modules/debts/services_list/screens/LogInvoice"
import { setToast } from "@/redux/features"
import { Button } from 'primereact/button'
import { confirmDialog } from "primereact/confirmdialog"
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    countServiceApi,
    deleteServiceApi,
    deleteServicesApi,
    exportDebitDetailApi,
    exportInvoiceByDebitApi, exportInvoiceByDebitsApi, listServiceApi,
    printApi,
    viewInvoiceByDebitApi, viewInvoiceByDebitsApi
} from '../api'
import { useListCycleName } from '../utils'
import DetailReceipt from './DetailReceipt'

const Header = ({ setParams }) => {
    const listCycleName = useListCycleName()
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    const services = useSelector((state) => state.services)
    const isExport = [
        {
            id: 1,
            name: 'Đã Xuất'
        },
        {
            id: 2,
            name: 'Chưa xuất'
        },
    ]
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} array={["apartment_ids", "service_ids", "cycle_names", "type_services"]} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo mã bảng kê"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Inputz
                value={filter.debt_search}
                placeholder="Còn nợ"
                onChange={(e) => setFilter({ ...filter, debt_search: e.target.value })}
                type="number"
            />
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <MultiSelectz
                value={filter.apartment_ids}
                options={filter.building_place_id ? apartments.filter(a => a.building_place_id === filter.building_place_id) : apartments}
                onChange={(e) => setFilter({ ...filter, apartment_ids: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <MultiSelectz
                value={filter.cycle_names}
                options={listCycleName}
                onChange={(e) => setFilter({ ...filter, cycle_names: e.target.value })}
                optionLabel="cycle_name"
                optionValue="cycle_name"
                placeholder="Chọn kỳ bảng kê"
            />
            <MultiSelectz
                value={filter.service_ids}
                options={services}
                onChange={(e) => setFilter({ ...filter, service_ids: e.target.value })}
                placeholder="Dịch vụ"
                showClear
            />
            <Dropdownz
                value={filter.service_group}
                options={typeServicesGroup}
                onChange={(e) => setFilter({ ...filter, service_group: e.target.value })}
                placeholder="Phí dịch vụ"
            />
            <MultiSelectz
                value={filter.type_services}
                options={services_Debt}
                onChange={(e) => setFilter({ ...filter, type_services: e.target.value })}
                placeholder="Chọn loại dịch vụ"
            />
            <Dropdownz
                value={filter.isExport}
                options={isExport}
                onChange={(e) => setFilter({ ...filter, isExport: e.target.value })}
                placeholder="Tình trạng xuất hóa đơn"
            />
        </GridForm>
    )
}
const ServicesList = () => {
    const dispatch = useDispatch()
    const initParam = useGetParams(["apartment_ids", "service_ids", "cycle_names", "type_services"])
    const [visible, setVisible] = useState(false)
    const [visibleLog, setVisibleLog] = useState(false)
    const [rowData, setRowData] = useState({})
    const [params, setParams] = useState(initParam)
    const { data, isLoading } = useGetApi(listServiceApi, { status: undefined, ...params, first: undefined }, "serviceList")
    const { data: totalRecords } = useGetApi(countServiceApi, {
        status: undefined, ...params,
        first: undefined
    }, "countServiceList")
    const [selectedProducts, setSelectedProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [from, setFrom] = useState(null)
    const total = data?.data?.data?.total

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'bảng kê dịch vụ',
            actionType: 'xóa',
            action: async (listId) => await deleteServicesApi({ ids: getArrId(listId) }),
        },
    ]

    const confirm = (status, type = "bill") => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn bảng kê dịch vụ trước khi tiếp tục!' }))
            return
        }
        if (type === "export") {
            confirmDialog({
                message: 'Bạn có muốn phát hành hóa đơn?',
                header: process.env.REACT_APP_BRANCH_NAME,
                icon: 'pi pi-info-circle',
                accept: async () => {
                    setLoading(true)
                    setLoading1(true)
                    setFrom(new Date())
                    const response = await exportInvoiceByDebitsApi({ debit_ids: getArrId(selectedProducts.filter(s => !s.invoice_id)) })
                    setLoading1(false)
                    setLoading(false)
                    if (response.data.status) {
                        dispatch(setToast({ ...listToast[0], detail: 'Đã thêm dịch vụ vào hàng chờ phát hành hóa đơn' }))
                        setVisibleLog(true)
                    }
                },
            })
        }
        if (type === "view") {
            confirmDialog({
                message: 'Bạn có muốn xem trước hóa đơn?',
                header: process.env.REACT_APP_BRANCH_NAME,
                icon: 'pi pi-info-circle',
                accept: async () => {
                    setLoading(true)
                    setLoading2(true)
                    const response = await viewInvoiceByDebitsApi({ debit_ids: getArrId(selectedProducts) })
                    setLoading2(false)
                    setLoading(false)
                    if (response.data.status) {
                        const data = response.data.data
                        if (Array.isArray(data)) {
                            data.forEach((url, index) => {
                                const link = document.createElement('a');
                                link.href = url;
                                link.target = '_blank';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            });
                        }
                    }
                },
            })
        }
    }

    const moreOptions = [
        {
            label: 'Phát hành hóa đơn',
            command: () => confirm(false, "export"),
            icon: 'pi pi-box',
            severity: 'success',
            loading: loading1,
        },
        {
            label: 'Xem trước hóa đơn',
            command: () => confirm(false, "view"),
            icon: 'pi pi-eye',
            severity: 'success',
            loading: loading2
        },
        {
            label: 'Lịch sử phát hành hóa đơn',
            command: () => setVisibleLog(true),
            severity: 'warning',
        }
    ]

    const listReceipt = (rowData) => {
        if (rowData.typez) return rowData.typez
        if (rowData.list_receipt && rowData.list_receipt.length > 0) {
            return rowData.list_receipt.map((d, index) => <span
                key={index}> <span>{d.receipt_code}</span> <br /> </span>)
        } else {
            return ''
        }
    }

    const handlePrint = async (id) => {
        setLoading(true)
        const response = await printApi({ id })
        setLoading(false)
        if (response.data.status) {
            window.open(`/printReduce/detail/${id}`, '_blank')
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
        }
    }

    const ViewInvoiceBody = (e) => {
        return (
            <div className="flex gap-2 align-items-center justify-content-center">
                {e.invoice_id ?
                    <Button
                        type="button"
                        icon="pi pi-file-pdf"
                        rounded
                        outlined
                        onClick={async () => {
                            window.open(e.invoice_pdf, "_blank")
                        }}
                        title="Xem hóa đơn"
                    /> :
                    <>
                        <Button
                            type="button"
                            icon="pi pi-file-pdf"
                            rounded
                            outlined
                            onClick={async () => {
                                setLoading(true)
                                const response = await viewInvoiceByDebitApi({ id: e.id })
                                setLoading(false)
                                if (response.data.status) {
                                    window.open(response.data?.data, "_blank")
                                } else dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
                            }}
                            title="Xem hóa đơn"
                        />
                        <Button
                            type="button"
                            icon="pi pi-upload"
                            rounded
                            outlined
                            onClick={async () => {
                                setLoading(true)
                                const response = await exportInvoiceByDebitApi({ id: e.id })
                                setLoading(false)
                                if (response.data.status) {
                                    window.open(response.data?.data, "_blank")
                                } else dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
                            }}
                            title="Xuất hóa đơn"
                        />
                    </>}

            </div>
        );
    }

    return (
        <div className="card">
            <HeaderListForm title="Chi tiết bảng kê - dịch vụ" />
            {visible && (
                <DetailReceipt rowData={rowData} visible={visible} setVisible={setVisible} setParams={setParams} />
            )}
            {visibleLog && <LogInvoice setVisible={setVisibleLog} visible={{ visibleLog }} from={from} setFrom={setFrom} />}
            <Header setParams={setParams} />
            <div style={{ lineHeight: '2' }}>
                <div>
                    <strong>Tổng tiền: {formatNumber(total?.total_sumery || '0')} </strong>
                </div>
                <div>
                    <strong>Tổng giảm trừ : {formatNumber(total?.total_discount || '0')}</strong>
                </div>
                <div>
                    <strong>
                        Tổng thành tiền : {formatNumber(total?.total_sumery - total?.total_discount || '0')}
                    </strong>
                </div>
                <div>
                    <strong>Đã hạch toán : {formatNumber(total?.total_paid || '0')}</strong>
                </div>
                <div>
                    <strong>Còn nợ : {formatNumber(total?.total_debt_remain || '0')}</strong>
                </div>
            </div>
            <br />
            <DataTablezV3
                loading={isLoading || loading}
                value={data?.data?.data?.listDebitDetail}
                title="bảng kê dịch vụ"
                totalRecords={typeof totalRecords?.data?.data === 'number' ? totalRecords?.data?.data : 0}
                params={params}
                setParams={setParams}
                route="/services_list"
                headerInfo={{ items, exportApi: exportDebitDetailApi, moreOptions }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                tableStyle={{ minWidth: '2000px' }}
                basePermissions={['export']}
                setVisibledDialog={setVisible}
                rowClassName={(rowData) => {
                    let classes = '';
                    if (rowData.invoice_id) {
                        classes += 'invoice_id-row';
                    }

                    return classes;
                }}
            >
                <Columnz header="Mã BK" body={e => {
                    if (e.invoice_id) return <Link
                        to={`/invoice?bdc_apartment_id=${e?.bdc_apartment_id}&cycle_name=${e?.cycle_name}`}>{e.bill_code}</Link>
                    else return e.bill_code
                }} style={{ whiteSpace: "nowrap" }} />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    field="cycle_name"
                    header="Kì"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    field="name"
                    header="Căn Hộ"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    field="code"
                    header="Mã căn hộ"
                />
                <Column
                    field="service_name"
                    header="Dịch vụ"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    field="vehicle_number"
                    header="Sản phẩm"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    field="budget_code"
                    header="Mã ngân sách"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    field="code_receipt"
                    header="Mã phí"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.price })}
                    className='text-right'
                    header="Đơn giá"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.quantity })}
                    className='text-right'
                    header="SL"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.sumary + e.discount + e.khuyen_mai })}
                    className='text-right'
                    header="Tổng"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.discount })}
                    className='text-right'
                    header="Giảm trừ"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.khuyen_mai })}
                    className='text-right'
                    header="Ưu đãi"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.sumary })}
                    header="Thành tiền"
                    className='text-right'
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.paid })}
                    className='text-right'
                    header="Đã hạch toán"
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    body={(e) => NumberFormat({ value: e.debt_remain })}
                    className='text-right'
                    header="Còn nợ"
                />
                <Column
                    headerStyle={{ width: '50px' }}
                    bodyStyle={{ minWidth: '50px' }}
                    body={listReceipt}
                    header="Phiếu thu"
                    style={{ whiteSpace: "nowrap" }}
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '50px' }}
                    bodyStyle={{ width: '20px', minWidth: '50px' }}
                    field="created_at"
                    header="Ngày lập"
                    body={(e) => TimeBody(e.created_at, 'date')}
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '20px' }}
                    bodyStyle={{ width: '20px', minWidth: '20px' }}
                    field="deadline"
                    header="Hạn thanh toán"
                    body={(e) => TimeBody(e.deadline, 'date')}
                />
                <Column
                    headerStyle={{ width: '20px', minWidth: '80px' }}
                    bodyStyle={{ width: '20px', minWidth: '80px' }}
                    body={(e) => {
                        return (
                            <span>
                                {TimeBody(e.from_date, 'date')} - {TimeBody(e.to_date, 'date')}
                            </span>
                        )
                    }}
                    header="Thời gian"
                />
                <Columnz body={e => ViewInvoiceBody(e)} header="Xem/ xuất hóa đơn" />
                <Column
                    header="Thao tác"
                    body={(rowData) => {
                        return (
                            <div className="flex gap-2 justify-content-center">
                                {Number(rowData.discount) > 0 &&
                                    <Button type="button" icon="pi pi-print" rounded outlined
                                        onClick={event => handlePrint(rowData.id)} />}
                                <Button
                                    type="button"
                                    icon="pi pi-eye"
                                    rounded
                                    outlined
                                    onClick={(e) => {
                                        setVisible(rowData.id)
                                        setRowData(rowData)
                                    }}
                                />
                                {parseFloat(rowData.paid) === 0 && (
                                    <div>
                                        <ActionBody
                                            id={rowData.id}
                                            route="/services_list"
                                            deleteAction={deleteServiceApi}
                                            handleDelete={(id) => {
                                                return { id: id, apartment_id: rowData.bdc_apartment_id }
                                            }}
                                            params={params}
                                            setParams={setParams}
                                            basePermissions={['delete',]}
                                        />
                                    </div>
                                )}
                            </div>
                        )
                    }}
                />
            </DataTablezV3>
        </div>
    )
}

export default ServicesList
