import { postData, getData } from '@/lib/request'

export const listCustomerApi = (params) => getData('web2/bill/getListBill', params)
export const countCustomerApi = (params) => getData('web2/bill/countBill', params)
export const deleteCustomerApi = (params) => postData('web2/bill/deleteBill', params)
export const updateStatusBillApi = (params) => postData('web2/bill/updateStatusBill', params)
export const detailReceiptApi = (params) => getData('web2/company/getDetailReceipt', params)
export const addReceiptApi = (params) => postData('/web2/company/addReceipt', params)
export const updateReceiptApi = (params) => postData('/web2/company/updateStatusBill', params)
export const detailNoti = (params) => getData('web2/bill/renderBill', params)
export const printBills = (params) => getData('web2/bill/renderListBill', params)

export const exportBillApi = (params) => getData('web2/bill/expListBill', params, true)