import {Body, DropdownForm, HeaderListForm, UploadFile, UploadFiles, UploadImg, UploadMultiImage} from '@/components'
import { listToast, statusRequest, typeServices } from '@/constants'
import { setToast } from '@/redux/features'
import { setBookingInfo } from '@/redux/features/bookingInfo'
import { setCardInfo } from '@/redux/features/cardInfor'
import { setVehicleInfo } from '@/redux/features/vehicleInfo'

import { setResidentInfo } from '@/redux/features/residentInfo'
import { Button } from '@/uiCore'
import { checkJson } from '@/utils'
import moment from 'moment'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteVehicleApi, updateVehicleApi } from '../../../accountant/vehicle_management/api'
import { addCommentApi, deleteCommentApi, switchStatusRequestApi } from '../api'
import { useDetailServiceRequest } from '../utils'
import { Comments } from './Comment'
import { DataBody } from './ServiceRequest'

const RequestInfo = ({ infos, id, setRender }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const [status, setStatus] = useState(null)
    const navigate = useNavigate()
    const images = [data.after_cmt, data.before_cmt]
    const file = data.file
    const files = data.files
    const handleButtonClick = () => {
        dispatch(
            setResidentInfo({
                ...data,
                cmt_img: JSON.stringify(images),
            }),
        )
        navigate('/resident_management/insert')
    }
    const changeInfor = () => {
        dispatch(
            setResidentInfo({
                ...data,
                cmt_img: JSON.stringify(images),
            }),
        )
        navigate(`/resident_management/detail/${infos.data.user_info_id}`)
    }
    const insertCard = () => {
        const cardInfo = data
        dispatch(setCardInfo({ ...cardInfo, apartment_id: infos.apartment_id }))
        navigate('/resident_card_management/insert')
    }
    const editCard = () => {
        const cardInfo = data
        dispatch(setCardInfo({ ...cardInfo, apartment_id: infos.apartment_id }))
        navigate(`/resident_card_management/detail/${data.card_id}`)
    }
    const deleteVehicle = () => {
        confirmDialog({
            message: `Bạn có muốn tiếp tục hủy phương tiện ?`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: accept1,
        })
    }
    const accept1 = async () => {
        const response = await deleteVehicleApi({
            id: data.id_vehicles,
        })
        if (response.data.status) {
            await switchStatusRequestApi({ id: id, status: 3 })
            dispatch(setToast({ ...listToast[0], detail: 'Hủy phương tiện thành công!' }))
            setRender((e) => !e)
        } else {
            dispatch(setToast({ ...listToast[1], detail: 'Bạn không có quyền hủy phương tiện!' }))
        }
    }
    const insertVehicle = () => {
        const vehicleInfo = infos
        dispatch(setVehicleInfo(vehicleInfo?.data))
        navigate('/vehicle_manage/insert')
    }
    // const repair = () => {
    //     navigate('/resident_card_management/insert');
    // };
    // const moveFurniture = () => {
    //     navigate('/resident_card_management/insert');
    // };
    const registerUltility = () => {
        const bookingInfo = data
        dispatch(setBookingInfo(bookingInfo))
        navigate('/booking_time_place/insert')
    }
    const accept = async () => {
        const response = await updateVehicleApi({
            id: data.id_vehicles,
            status: 1,
            finish: data.extended,
        })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Gia hạn phương tiện thành công!' }))
            setRender((e) => !e)
        } else {
            dispatch(setToast({ ...listToast[1], detail: 'Bạn không có quyền gia hạn phương tiện!' }))
        }
    }
    const confirm = () => {
        const finishDate = moment(data.extended).format('DD/MM/YYYY')
        confirmDialog({
            message: `Bạn có muốn tiếp tục gia hạn phương tiện tới ngày ${finishDate} ?`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept,
        })
    }
    useEffect(() => {
        if (infos && infos.data) {
            const data = checkJson(infos.data) || infos.data
            setData({ ...data })
        }
    }, [infos])
    const fetchData = async () => {
        const response = await switchStatusRequestApi({ id, status })
        if (response.data && response.status) {
            setRender((e) => !e)
            dispatch(setToast({ ...listToast[0], detail: 'Đổi trạng thái thành công!' }))
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }
    return (
        <>
            <div className="card">
                <HeaderListForm title="Form yêu cầu" />
                <DataBody data={data} type={infos.type} visible={true} infos={infos} />
                <div className="grid grid-form px-2 mt-2">
                    <p className="font-semibold col-12 lg:col-6 flex align-items-center">Trạng thái</p>
                    <div className="col-12 lg:col-6">
                        <div className="w-6">
                            <Body data={statusRequest} value={infos.status} />
                        </div>
                    </div>
                </div>
                {infos.type === 11 && data.file ? (
                    <>
                        {file && (
                            <div className="mb-4">
                                <HeaderListForm title="File đính kèm" />
                                {file.files.map((f, index) => {
                                    return (
                                        f &&
                                        (f.endsWith('.mp4') || f.endsWith('MOV') ? (
                                            <div style={{ height: '300px', overflow: 'hidden' }}>
                                                <ReactPlayer url={f} controls width="100%" height="100%" />
                                            </div>
                                        ) : (
                                            <div>
                                                <label className="font-medium">{`File ${index + 1}`}</label>
                                                <br />
                                                <UploadFile file={f} isView />
                                            </div>
                                        ))
                                    )
                                })}
                            </div>
                        )}
                        {images && images[0] && (
                            <div className="mb-4">
                                <HeaderListForm title="Ảnh đính kèm" />
                                <UploadMultiImage view images={images} label=" " />
                            </div>
                        )}
                    </>
                ) : null}
                {infos.type === 9 ? (
                    <>
                        <div className="mt-4">
                            <HeaderListForm title="Ảnh đại diện" />
                            <UploadImg label="ảnh đại diện" view image={infos.data.avatar} />
                        </div>
                        {data.cmt_img && data.cmt_img[0] && (
                            <div className="mb-4">
                                <HeaderListForm title="Ảnh chứng minh thư" />
                                <UploadMultiImage view images={data.cmt_img} label=" " />
                            </div>
                        )}
                        {data.attach_file && (
                            <div className="mb-4">
                                <HeaderListForm title="File đính kèm" />
                                <UploadFiles view files={typeof data.attach_file === 'object' ? data.attach_file : [data.attach_file]} label=" " />
                            </div>
                        )}
                    </>
                ) : null}

                {infos.type === 5 && data.files ? (
                    <>
                        {files.files && files.files[0] && (
                            <div className="mb-4">
                                <HeaderListForm title="File đính kèm" />
                                {files.map((f, index) => {
                                    return (
                                        f &&
                                        (f.endsWith('.mp4') || f.endsWith('MOV') ? (
                                            <div style={{ height: '300px', overflow: 'hidden' }}>
                                                <ReactPlayer url={f} controls width="100%" height="100%" />
                                            </div>
                                        ) : (
                                            <div>
                                                <label className="font-medium">{`File ${index + 1}`}</label>
                                                <br />
                                                <UploadFile file={f} isView />
                                            </div>
                                        ))
                                    )
                                })}
                            </div>
                        )}
                        {files.images && files.images[0] && (
                            <div className="mb-4">
                                <HeaderListForm title="Ảnh đính kèm" />
                                <UploadMultiImage view images={files.images} label=" " />
                            </div>
                        )}
                    </>
                ) : null}
                {(infos.type === 13 || infos.type === 14) && data.files ? (
                    <>
                        {files && files[0] && (
                            <div className="mb-4">
                                <HeaderListForm title="File đính kèm" />
                                {files.map((f, index) => {
                                    return (
                                        f &&
                                        (f.endsWith('.mp4') || f.endsWith('MOV') ? (
                                            <div style={{ height: '300px', overflow: 'hidden' }}>
                                                <ReactPlayer url={f} controls width="100%" height="100%" />
                                            </div>
                                        ) : (
                                            <div>
                                                <label className="font-medium">{`File ${index + 1}`}</label>
                                                <br />
                                                <UploadFile file={f} isView />
                                            </div>
                                        ))
                                    )
                                })}
                            </div>
                        )}
                    </>
                ) : null}
                {infos.type === 4 && data.file ? (
                    <>
                        {file.files && file.files[0] && (
                            <div className="mb-4">
                                <HeaderListForm title="File đính kèm" />
                                {file.files.map((f) => {
                                    return (
                                        f &&
                                        (f.endsWith('.mp4') || f.endsWith('MOV') ? (
                                            <div style={{ height: '300px', overflow: 'hidden' }}>
                                                <ReactPlayer url={f} controls width="100%" height="100%" />
                                            </div>
                                        ) : (
                                            <UploadFile file={f} isView />
                                        ))
                                    )
                                })}
                            </div>
                        )}
                        {file.images && file.images[0] && (
                            <div className="mb-4">
                                <HeaderListForm title="Ảnh đính kèm" />
                                <UploadMultiImage view images={file.images} label=" " />
                            </div>
                        )}
                    </>
                ) : null}
                {infos.type === 9 ? (
                    <Button
                        label="Thêm mới cư dân"
                        icon="pi pi-user"
                        severity="info"
                        onClick={handleButtonClick}
                        aria-label="User"
                        className="mx-2"
                    />
                ) : infos.type === 10 ? (
                    <Button
                        label="Thay đổi thông tin cá nhân"
                        icon="pi pi-user"
                        severity="info"
                        aria-label="User"
                        onClick={changeInfor}
                        className="mx-2"
                    />
                ) : infos.type === 6 ? (
                    <Button
                        label="Thêm mới đặt chỗ"
                        icon="pi pi-user"
                        severity="info"
                        aria-label="User"
                        onClick={registerUltility}
                        className="mx-2"
                    />
                ) : infos.type === 11 && infos.status !== 3 ? (
                    <Button
                        label="Thêm mới thẻ"
                        icon="pi pi-user"
                        severity="info"
                        aria-label="User"
                        onClick={insertCard}
                        className="mx-2"
                    />
                ) : infos.type === 12 ? (
                    <Button
                        label="Cập nhật thẻ"
                        icon="pi pi-user"
                        severity="info"
                        aria-label="User"
                        onClick={editCard}
                        className="mx-2"
                    />
                ) : infos.type === 2 ? (
                    <Button
                        label="Hủy phương tiện"
                        icon="pi pi-user"
                        severity="danger"
                        aria-label="User"
                        onClick={deleteVehicle}
                        className="mx-2"
                    />
                ) : infos.type === 1 ? (
                    <Button
                        label="Thêm phương tiện"
                        icon="pi pi-user"
                        severity="info"
                        aria-label="User"
                        onClick={insertVehicle}
                        className="mx-2"
                    />
                ) : infos.type === 15 ? (
                    <Button
                        label="Gia hạn phương tiện"
                        icon="pi pi-user"
                        aria-label="User"
                        onClick={confirm}
                        className="mx-2"
                    />
                ) : (
                    <></>
                )}

                {infos.type === 1 &&
                    typeServices[0].images.map((ts, index) => {
                        return (
                            <div key={index} className="mt-4">
                                <UploadMultiImage label={ts.label} view images={infos.data[ts.key]} />
                            </div>
                        )
                    })}
                {(infos.type === 10 || infos.type === 11) &&
                    typeServices[9].images.map((ts, index) => {
                        return (
                            <div key={index} className="mt-4">
                                <label className="font-medium">{ts.label}</label>
                                <UploadImg label={ts.label} view image={infos.data[ts.key]} />
                            </div>
                        )
                    })}
                {infos.status !== 3 && (
                    <div className="w-full flex align-items-center mt-4">
                        <div className="w-6">
                            <DropdownForm
                                options={statusRequest.filter((s) => ![0, 2].includes(s.id))}
                                value={status || infos.status}
                                placeholder="Trạng thái"
                                onChange={(e) => setStatus(e.target.value)}
                            />
                        </div>
                        <div className="w-6 px-2">
                            <Button
                                className="mt-2 mb-3"
                                raised
                                size="small"
                                icon="pi pi-save"
                                severity="info"
                                disabled={!status}
                                label="Xác nhận"
                                onClick={() => fetchData()}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

const UpdateServiceRequest = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [render, setRender] = useState(false)
    const serviceRequestDetail = useDetailServiceRequest(id, render)
    const [infos, setInfos] = useState({})
    const [comments, setComments] = useState([])

    useEffect(() => {
        if (serviceRequestDetail.detailRequest) setInfos({ ...infos, ...serviceRequestDetail.detailRequest })
        if (serviceRequestDetail.comment && Array.isArray(serviceRequestDetail.comment))
            setComments([...serviceRequestDetail.comment])
    }, [serviceRequestDetail])

    const SubTitle = () => {
        return (
            <Button type="button" raised size="small" severity="danger" label="Trở về" onClick={() => navigate(-1)} />
        )
    }

    const handleAddComment = (params) => {
        return { ...params, id_request: Number(id) }
    }

    return (
        <div className="card">
            <HeaderListForm title="Yêu cầu dịch vụ" SubTitle={SubTitle} />
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    {infos && <RequestInfo setRender={setRender} id={Number(id)} infos={infos} />}
                </div>
                <div className="col-12 lg:col-6">
                    <Comments
                        addApi={addCommentApi}
                        deleteApi={deleteCommentApi}
                        setRender={setRender}
                        handleAddComment={handleAddComment}
                        comments={comments}
                    />
                </div>
            </div>
        </div>
    )
}

export default UpdateServiceRequest
