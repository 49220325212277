import { useEffect, useState } from "react"
import { countConfirmConfigApi, detailConfirmConfigApi, listConfirmConfigApi } from "../api"

export const useListuseListConfirmConfig = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listConfirmConfigApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountConfirmConfig = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countConfirmConfigApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}
export const useDetailConfirmConfig = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailConfirmConfigApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}