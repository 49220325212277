import {
    Body, Columnz,
    DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, LoadDialog, useGetParams
} from '@/components'
import { handbook_Type, status_Handbook } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deletePostApi } from '../api'
import { useCountPost, useHandbook, useLisCate } from '../utils'
import UpdatePost from './UpdatePost'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const cate = useLisCate()
    const userCreate = useSelector(state => state.users)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập nội dung tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.bdc_handbook_category_id}
                options={cate}
                onChange={(e) => setFilter({ ...filter, bdc_handbook_category_id: e.target.value })}
                placeholder="Danh mục"
            />

            <Dropdownz
                value={filter.bdc_handbook_type_id}
                options={handbook_Type}
                onChange={(e) => setFilter({ ...filter, bdc_handbook_type_id: e.target.value })}
                placeholder="Phân loại"
            />
            <Dropdownz
                value={filter.full_name}
                options={userCreate}
                onChange={(e) => setFilter({ ...filter, full_name: e.target.value })}
                placeholder="Người tạo"
                optionLabel="full_name"
                optionsValue="full_name"
            />

            <Dropdownz
                value={filter.status}
                options={status_Handbook}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

export default function Posts() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useHandbook({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPost({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    return (
        <>
            {visible && <UpdatePost setParams={setParams} visible={visible} setVisible={setVisible} />}
            <LoadDialog visible={visible} />
            <HeaderListForm title="Bài viết" />
            <Header setParams={setParams} />
            <DataTablez
                title="Bài viết"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_guide"
                headerInfo
                actionsInfo={{ deleteAction: deletePostApi }}
                basePermissions={["insert", "detail"]}
                setVisibledDialog={setVisible}

            >
                <Columnz field="title" header="Tiêu đề " />
                <Columnz field="category_name" header="Danh mục" />
                <Columnz
                    body={(e) => Body({ data: handbook_Type, value: e.bdc_handbook_type_id })}
                    header="Phân loại"
                />

                <Columnz field="pub_user_name" header="Người tạo" />
                <Columnz header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' body={(e) => Body({ data: status_Handbook, value: e.status })} />
            </DataTablez>
        </>
    )
}
