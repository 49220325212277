import { useEffect, useState } from 'react'
import {
    countDetailRatedApi,
    countRatedServiceApi,
    detailRatedServiceApi,
    listCateEvaluateApi,
    listDepartmentForRate,
    listDetailRatedApi,
    listRatedServiceApi,
    listTotalDailyApi,
} from '../api'

export const useListRatedService = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listRatedServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListCateEvaluate = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCateEvaluateApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListDepartment = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDepartmentForRate({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountRatedService = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countRatedServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailRatedService = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailRatedServiceApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

//=======================================================================
export const useListDetailRated = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDetailRatedApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountDetailRated = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countDetailRatedApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
///////////////////////////////////////////////////////////
export const useListTotalDaily = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listTotalDailyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListTotalDailySynthetic = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listTotalDailyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data.synthetic)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountTotalDaily = (params) => {
    const data = useListTotalDaily(params)

    const getCount = () => {
        return data.length
    }

    return getCount()
}
