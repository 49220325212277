import { getData, postData } from '@/lib/request'

export const listServiceApi = (params) => getData('web2/services_list/getListDebitDetail', params)
export const listCycleName = (params) => getData('web2/services_list/getListCycleName', params)
export const countServiceApi = (params) => getData('web2/services_list/countListDebitDetail', params)
export const deleteServiceApi = (params) => postData('web2/services_list/delDebit', params)
export const deleteServicesApi = (params) => postData('web2/services_list/delDebits', params)
export const exportDebitDetailApi = (params) => getData('web2/services_list/expListDebitDetailV2', params, true)
export const updateDebitApi = (params) => postData('web2/debit_detail/updateDebitDetail', params)
export const printApi = (params) => getData('web2/receipt/renderReceiptReduce', params)
export const viewInvoiceByDebitApi = (params) => getData('web2/debit_detail/viewInvoiceByDebit', params)
export const viewInvoiceByDebitsApi = (params) => getData('web2/debit_detail/viewInvoiceByDebits', params)
export const exportInvoiceByDebitsApi = (params) => postData('web2/debit_detail/exportInvoiceByDebits', params)
export const exportInvoiceByDebitApi = (params) => postData('web2/debit_detail/exportInvoiceByDebit', params)
