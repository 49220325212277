import { Body, CalendarForm, Columnz, DropdownForm, FormUpdate, InputForm, InputNumberForm, UploadFile } from '@/components'
import { listToast, reservationStatus, typePayment } from '@/constants'
import { databaseDate, validate } from '@/lib'
import { setToast } from '@/redux/features'
import { DataTable, Image, SplitButton } from '@/uiCore'
import { removePropObject } from '@/utils'
import moment from 'moment'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addBookingTimePlaceApi, detailBookingApi, updateBookingTimePlaceApi } from '../api'
import { useBookingRegistered, useDetailBookingTimePlace, useListUtilitiesApartmentApi } from '../utils'
import BookingComment from './BookingComment'
const ResevationDetail = (props) => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [infos, setInfos] = useState({
        number_member: '',
        owner: '',
        phone: '',
        type_payment: 1
    })
    const [render, setRender] = useState(false)

    const detailBooking = useDetailBookingTimePlace(id, render)
    const { setParams } = props
    const apartments = useSelector((state) => state.apartments)
    const residents = useSelector((state) => state.residents)
    const [booking, setBooking] = useState({})
    const user = apartments.find((d) => d.id === infos.bdc_apartment_id)
    const listBooking = useListUtilitiesApartmentApi()
    const [file, setFile] = useState(null)
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const listBookingRegister = useBookingRegistered({ booking_id: infos.booking_id, date_use: formattedDate })

    const Time = (rowData) => {
        const hours = String(rowData.getHours()).padStart(2, '0');
        const minutes = String(rowData.getMinutes()).padStart(2, '0');
        const formattedTime = `${hours}:${minutes}`;
        return formattedTime
    }

    useEffect(() => {
        if (detailBooking.id) {
            setInfos({
                ...infos,
                ...detailBooking,
                date_use: new Date(detailBooking.date_use),
                start_time: new Date(detailBooking.start_time),
                end_time: new Date(detailBooking.end_time),
            })
            fetchBooking(detailBooking.booking_id)
            if (detailBooking.file) {
                const newFile = detailBooking.file || ""
                if (newFile.includes('.jpg')) {
                    setFile({ preview: newFile, type: 'image', name: 'newFile' })
                } else setFile(newFile)
            }
        }
    }, [detailBooking])

    const fetchBooking = async (id) => {
        const response = await detailBookingApi({ id: id })
        if (response.data.status) setBooking(response.data.data)
    }

    const convertDateToTime = (dateStr) => {
        let dateObj = new Date(dateStr);
        return dateObj.toTimeString().split(' ')[0];
    }

    useEffect(() => {
        setInfos({ ...infos, owner: user?.name_customer ? user?.name_customer : '' })
    }, [infos.bdc_apartment_id])


    const accept = (type) => {
        const response = updateBookingTimePlaceApi({ id, status: type })
        response.then((res) => {
            if (res.data.status) {
                dispatch(setToast({ ...listToast[0], detail: `Chuyển trạng thái thành công!` }))
                setRender(prev => !prev)
            } else {
                dispatch(setToast({ ...listToast[1], detail: `Chuyển trạng thái thất bại!` }))
            }
        })
    }

    const confirm = ({ type, title }) => {
        confirmDialog({
            message:
                'Bạn có chắc chắn xác nhận đăng ký đặt chỗ, hệ thống sẽ chuyển trạng thái của phiếu đăng ký đã chọn?',
            icon: 'pi pi-info-circle',
            header: `Chuyển trạng thái qua ${title}`,
            accept: () => accept(type),
        })
    }

    const items = [
        {
            label: 'Chờ xác nhận',
            command: () => {
                confirm({ type: 1, title: 'chờ xác nhận' })
            },
        },
        {
            label: 'Đã cọc',
            command: () => confirm({ type: 2, title: 'đã cọc' }),
        },
        {
            label: 'Đang sử dụng',
            command: () => confirm({ type: 3, title: 'đang sử dụng' }),
        },
        {
            label: 'Kiểm tra vi phạm',
            command: () => confirm({ type: 4, title: 'kiểm tra vi phạm' }),
        },
        {
            label: 'Hoàn thành',
            command: () => confirm({ type: 5, title: 'hoàn thành' }),
        },
        {
            label: 'Hủy',
            command: () => confirm({ type: 6, title: 'hủy' }),
        },
        {
            label: 'Phạt',
            command: () => confirm({ type: 7, title: 'phạt' }),
        },
    ]

    const handleChangeBooking = async (e) => {
        if (e.target.value) {
            setInfos({
                ...infos, booking_id: e.target.value, booking_time_id: null,
                booking_place_id: null, start_time: null, end_time: null
            })
            fetchBooking(e.target.value)
        }
    }


    const check = listBooking.find(d => d.id === infos.booking_id)


    const bookingExists = listBookingRegister.some(booking => {
        return booking.booking_place_id === parseInt(infos.booking_place_id) &&
            booking.booking_time_id === parseInt(infos.booking_time_id);
    });


    useEffect(() => {
        if (bookingExists) dispatch(setToast({ ...listToast[2], detail: 'Vị trí và thời gian bạn chọn đã có người đặt trước' }));
    }, [infos.booking_place_id, infos.booking_time_id])

    const handleData = () => {
        let info = {
            ...infos,
            apartment_id: infos.bdc_apartment_id,
            date_use: databaseDate(infos.date_use, false, 'date'),
        }
        if (!info.bdc_apartment_id) return "Vui lòng chọn căn hộ !"
        if (!infos.user_id) return "Vui lòng chọn người đặt chỗ!"
        if (booking.type_time === 2) {
            if (infos.start_time) info.start_time = convertDateToTime(infos.start_time)
            else return 'Vui lòng chọn thời gian bắt đầu!'
            if (infos.end_time) info.end_time = convertDateToTime(infos.end_time)
            else return 'Vui lòng chọn thời gian kết thúc!'
        }
        if (booking.type_time === 1) {
            if (!info.booking_time_id) {
                return 'Vui lòng chọn giờ đăng ký sử dụng!'
            }
            delete info.start_time
            delete info.end_time
        }

        if (!info.number_member) return "Vui lòng nhập số người đăng ký!"
        if (!info.phone) return "Vui lòng nhập số điện thoại!"
        if (info.phone && (!validate("phone", info.phone))) return "Số điện thoại không đúng định dạng!"
        if (!info.booking_place_id) return "Vui lòng chọn vị trí đăng ký!"
        if (!info.type_payment) return "Vui lòng chọn phương thức thanh toán!"
        if (!info.date_use) return "Vui lòng chọn ngày đăng ký sử dụng!"
        if (bookingExists) return 'Vị trí và thời gian bạn chọn đã có người đặt trước'
        if (new Date(databaseDate(detailBooking.date_use, false, "date")).getTime() === new Date(info.date_use).getTime()) {
            delete info.date_use
        }

        if (booking.type_time === 2) {
            if ((new Date(databaseDate(detailBooking.start_time)).getTime() === new Date(infos.start_time).getTime()) &&
                (new Date(databaseDate(detailBooking.end_time)).getTime() === new Date(infos.end_time).getTime())) {
                delete info.start_time
                delete info.end_time
            }
        }

        if (file) info.file = file
        else info.file = []

        if (id) info = { ...removePropObject(info, detailBooking), id: Number(id) }
        delete info.owner
        delete info.bdc_apartment_id

        return info
    }

    return (
        <div className="card">
            <FormUpdate
                checkId={Number(id)}
                setParams={setParams}
                handleData={handleData}
                refreshObjects={[setInfos]}
                route="/booking_time_place"
                title="đặt chỗ"
                actions={{ add: addBookingTimePlaceApi, update: updateBookingTimePlaceApi }}
            >
                <div
                    className="font-left justify-content-between flex align-items-center mb-4 "
                    style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                >
                    <b className="text-medium p-0">Thông tin dịch vụ</b>
                    <div className="flex gap-1">
                        <SplitButton label="Xác nhận chuyển trạng thái" model={items} />
                    </div>
                </div>
                <div className="grid grid-form">
                    <div className="col-12 lg:col-8  ">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6  ">
                                <DropdownForm
                                    id="bdc_apartment_id"
                                    value={infos.bdc_apartment_id}
                                    onChange={(e) =>
                                        setInfos({
                                            ...infos,
                                            bdc_apartment_id: e.target.value,
                                        })
                                    }
                                    options={apartments}
                                    label="Căn hộ (*) "
                                    disabled={id}
                                />
                                <DropdownForm
                                    id="user_id"
                                    value={infos.user_id}
                                    onChange={(e) => setInfos({ ...infos, user_id: e.target.value })}
                                    label="Người đặt chỗ (*)"
                                    options={residents && residents.filter(d =>
                                        d.apartment_id === infos.bdc_apartment_id
                                    )}
                                    optionLabel="full_name"
                                    optionValue="user_id"
                                    emptyMessage={infos.bdc_apartment_id ? 'Không tìm thấy dữ liệu' : "Vui lòng chọn căn hộ trước!"}

                                />
                                <DropdownForm
                                    id="booking_id"
                                    value={infos.booking_id}
                                    onChange={handleChangeBooking}
                                    options={listBooking}
                                    label="Dịch vụ đăng ký"
                                    disabled={id}
                                    showClear={false}
                                />

                                {booking.type_time === 2 ? (
                                    <>
                                        <CalendarForm
                                            id="start_time"
                                            value={infos.start_time}
                                            onChange={(e) => setInfos({ ...infos, start_time: e.target.value })}
                                            label="Giờ bắt đầu sử dụng"
                                            timeOnly
                                        />
                                        <CalendarForm
                                            id="end_time"
                                            value={infos.end_time}
                                            onChange={(e) => setInfos({ ...infos, end_time: e.target.value })}
                                            label="Giờ kết thúc sử dụng"
                                            timeOnly
                                        />
                                    </>
                                ) : (
                                    <DropdownForm
                                        id="booking_place_id"
                                        value={infos.booking_place_id}
                                        onChange={(e) => setInfos({ ...infos, booking_place_id: e.target.value })}
                                        options={booking.location_config}
                                        label="Vị trí đăng ký"
                                        showClear={false}
                                        disabled={infos.booking_time_id ? false : true}
                                    />
                                )}
                                <InputForm
                                    id="number_member"
                                    value={infos.number_member}
                                    onChange={(e) => setInfos({ ...infos, number_member: e.target.value })}
                                    label="Số người đăng ký"
                                />
                                {id && <InputNumberForm
                                    id="deposit_fee"
                                    value={infos.deposit_fee}
                                    handleOnChange={(e) => setInfos({ ...infos, deposit_fee: e })}
                                    label="Phí đặt chỗ"
                                    disabled
                                />
                                }
                            </div>
                            <div className="col-12 lg:col-6  ">
                                <InputForm
                                    id="owner "
                                    value={infos.owner ? infos.owner : 'Không tìm thấy chủ hộ'}
                                    label="Chủ hộ"
                                    required
                                    disabled
                                />
                                <InputForm
                                    id="phone"
                                    value={infos.phone}
                                    onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                                    label="Số điện thoại"
                                    required

                                />

                                <DropdownForm
                                    id="booking_time_id"
                                    value={infos.booking_time_id}
                                    onChange={(e) => setInfos({ ...infos, booking_time_id: e.target.value })}
                                    label="Giờ đăng ký sử dụng"

                                    showClear={false}
                                    options={
                                        booking.location_time &&
                                        booking.location_time.map((d) => ({
                                            ...d,
                                            times: `${d.start_time.slice(0, 5)} - ${d.end_time.slice(0, 5)}`
                                        }))
                                    }
                                    optionLabel="times"
                                />
                                {
                                    check?.deposit_fee === 0 ? "" : <DropdownForm
                                        id="type_payment"
                                        value={infos.type_payment}
                                        onChange={(e) => setInfos({ ...infos, type_payment: e.target.value })}
                                        options={typePayment}
                                        label="Phương thức thanh toán"
                                    />
                                }

                                <CalendarForm
                                    id="date_use"
                                    value={infos.date_use}
                                    onChange={(e) => setInfos({ ...infos, date_use: e.target.value })}
                                    label="Ngày đăng ký sử dụng"
                                />
                                {id && <div className="mb-3 px-2 change-disabled">
                                    <label htmlFor={id} className="font-medium">
                                        Trạng thái
                                    </label>
                                    <div className="w-full mt-4">
                                        <Body data={reservationStatus} value={infos.status} />
                                    </div>
                                </div>}

                                {/* <CalendarForm
                            id="date_use"
                            value={infos.date_use}
                            onChange={(e) => setInfos({ ...infos, date_use: e.target.value })}
                            label="Ngày sử dụng"
                            required
                        /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-4  ">
                        <div
                            className="font-left justify-content-between flex align-items-center mb-4 "
                            style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                        >
                            <b className="text-medium p-0">Danh sách các khung giờ đã đăng ký</b>
                        </div>
                        <DataTable
                            title="chỗ đã đặt"
                            value={listBookingRegister}
                            sortField="start_time"
                            sortOrder={1}
                        >
                            <Columnz
                                body={(e) => moment(e.start_time).format('DD-MM-YYYY')}
                                header="Ngày"
                            />
                            <Columnz
                                header="Giờ bắt đầu"
                                body={(e) => moment(e.start_time).format('HH:mm:ss')}
                            />
                            <Columnz
                                header="Giờ kết thúc"
                                body={(e) => moment(e.end_time).format('HH:mm:ss')}
                            />
                            <Columnz
                                body={(e) => booking.location_config ? booking.location_config.find((d) => d.id === e.booking_place_id)?.name : null}
                                header="Vị trí đăng ký"
                            />
                        </DataTable>
                    </div>
                </div>

                {detailBooking && (detailBooking.status === 1) && (detailBooking.qr) && (
                    <div className='card flex justify-content-center'>
                        <Image src={detailBooking.qr} preview height="300px" />
                    </div>
                )}
                <UploadFile file={file} setFile={setFile} />
                {/* <div
                    className="font-left flex align-items-center mb-4"
                    style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                >
                    <b className="text-xl p-0">Thông tin đặt cọc</b>
                </div>
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6  ">
                        <InputForm
                            id="deposit_fee_paid"
                            value={infos.deposit_fee_paid}
                            onChange={(e) => setInfos({ ...infos, deposit_fee_paid: e.target.value })}
                            label="Số tiền đặt chỗ"
                            required
                        />
                        <DropdownForm
                            id="type_payment"
                            value={infos.type_payment}
                            onChange={(e) => setInfos({ ...infos, type_payment: e.target.value })}
                            options={reservationStatus}
                            label="Trạng thái thanh toán"
                        />

                    </div>

                    <div className="col-12 lg:col-6  ">
                        <DropdownForm
                            id="type_payment"
                            value={infos.type_payment}
                            onChange={(e) => setInfos({ ...infos, type_payment: e.target.value })}
                            options={typePayment}
                            label="Phương thức thanh toán"
                        />
                        <InputForm
                            id="receipt_code"
                            value={infos.receipt_code}
                            onChange={(e) => setInfos({ ...infos, receipt_code: e.target.value })}
                            label="Mã đơn"
                            required
                        />
                    </div>
                </div> */}
                {/* <div
                    className="font-left flex align-items-center mb-4"
                    style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                >
                    <b className="text-xl p-0">Trạng thái sử dụng</b>
                </div>
                <DataTable>
                    <Column header="STT" />
                    <Column header="Thời gian" />
                    <Column header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
 
className='text-center'  />
                    <Column header="Vi phạm sử dụng" />
                    <Column header="Phí vi phạm" />
                    <Column header="Hình ảnh" />
                    <Column header="Ghi chú" />
                </DataTable>
                <div
                    className="font-left flex align-items-center mb-4"
                    style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                >
                    <b className="text-xl p-0">Thông tin phí sử dụng</b>
                </div>
                <DataTable
                    value={products}
                    editMode="row"
                    stripedRows
                    showGridlines
                    dataKey="bdc_service_id"
                    selectionMode="checkbox"
                    onRowEditComplete={onRowEditComplete}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                >
                    <Columnz selectionMode="multiple" headerStyle={{ width: '3rem' }}></Columnz>
                    <Columnz
                        field="discount_type"
                        body={(e) => typeTemplate(e.discount_type)}
                        header="Dịch vụ sử dụng"
                        editor={(options) => typeEditor(options)}
                    ></Columnz>
                    <Columnz
                        field="discount"
                        header="Đơn giá"
                        editor={(options) => priceEditor(options)}
                    ></Columnz>
                    <Columnz
                        field="discount"
                        header="Số lượng"
                        editor={(options) => priceEditor(options)}
                    ></Columnz>
                    <Columnz
                        field="discount"
                        header="Giảm trừ"
                        editor={(options) => priceEditor(options)}
                    ></Columnz>
                    <Columnz
                        field="discount"
                        header="Thành tiền"
                        editor={(options) => priceEditor(options)}
                    ></Columnz>
                    <Columnz
                        field="name"
                        header="Chi tiết"
                        editor={(options) => textEditor(options)}
                    ></Columnz>
                </DataTable> */}
                {/* <button onClick={handleAddNew}>Thêm mới</button> */}
                {/* <div className="grid grid-form" style={{ textAlign: 'left', borderBottom: '1px solid #d3d9de' }}>
                    <div className="col-12 lg:col-6  " style={{ paddingLeft: 90 }}>
                        <strong>Tổng</strong>
                    </div>
                    <div className="col-12 lg:col-3  ">
                        <strong>1.082.004</strong>
                    </div>
                    <div className="col-12 lg:col-3  ">
                        <strong>VNĐ</strong>
                    </div>
                </div>
                <div className="grid grid-form" style={{ textAlign: 'left', borderBottom: '1px solid #d3d9de' }}>
                    <div className="col-12 lg:col-6  " style={{ paddingLeft: 90 }}>
                        <strong>Cọc đặt chỗ</strong>
                    </div>
                    <div className="col-12 lg:col-3  ">
                        <strong>1.000.000</strong>
                    </div>
                    <div className="col-12 lg:col-3  ">
                        <strong>VNĐ</strong>
                    </div>
                </div>
                <div className="grid grid-form" style={{ textAlign: 'left', borderBottom: '1px solid #d3d9de' }}>
                    <div className="col-12 lg:col-6  " style={{ paddingLeft: 90 }}>
                        <strong>Thanh toán</strong>
                    </div>
                    <div className="col-12 lg:col-3  ">
                        <strong>82.004</strong>
                    </div>
                    <div className="col-12 lg:col-3  ">
                        <strong>VNĐ</strong>
                    </div>
                </div> */}
            </FormUpdate>
            {id && (
                <div>
                    <div
                        className="font-left flex align-items-center mb-4"
                        style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                    >
                        <b className="text-xl p-0">Bình luận liên quan</b>
                    </div>
                    <BookingComment />
                </div>
            )}
        </div>
    )
}

export default ResevationDetail
