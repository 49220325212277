import { useEffect, useState } from 'react'
import { countMaintainScheduleApi, detailMaintainScheduleApi, listMaintainScheduleApi } from '../api'

export const useListMaintainSchedule = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listMaintainScheduleApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountMaintainSchedule = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countMaintainScheduleApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailMaintainSchedule = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailMaintainScheduleApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
