import {
    countSubdivisionApi,
    deleteSubdivisionApi,
    listProjectApi,
    listSubdivisionApi,
    updateSubdivisonApi
} from '@/api'
import {
    Body,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    useGetParams
} from '@/components'
import { status } from '@/constants'
import { useGetApi } from '@/hooks'
import { useState } from 'react'
import UpdateSubdivisionList from './UpdateSubdivisionList'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã phân khu"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

export default function SubdivisionList() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [visible, setVisible] = useState(false)
    const data = useGetApi(listSubdivisionApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countSubdivisionApi, { ...params, first: undefined }, 0)
    const projects = useGetApi(listProjectApi, {}, [])

    return (
        <div className="card">
            {visible && <UpdateSubdivisionList visible={visible} setVisible={setVisible} setParams={setParams} />}
            <HeaderListForm title="Danh sách phân khu" />
            <Header setParams={setParams} />
            <DataTablez
                title="danh sách phân khu"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/subdivision"
                headerInfo
                actionsInfo={{
                    deleteAction: deleteSubdivisionApi,
                }}
                basePermissions={["insert", "detail", "delete"]}
                setVisibledDialog={setVisible}
                hideParams
            >
                <Columnz field="name" header="Tên phân khu" />
                <Columnz field="code" header="Mã phân khu" />
                <Columnz body={(e) => Body({ data: projects, value: e.building_id })} header="Dự án" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/subdivision',
                            updateAction: updateSubdivisonApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}
