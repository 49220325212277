import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, useGetParams } from '@/components'
import { statusFormSetup } from '@/constants'
import { getArrId } from '@/utils'
import { useState } from 'react'
import { deleteFormTemplateApi, switchStatusFormTemplateApi } from '../api'
import { useCountFormTemplate, useListFormTemplate } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tiêu đề"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                placeholder="Trạng thái"
                options={statusFormSetup}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
            />
        </GridForm>
    )
}

const FormTemplates = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListFormTemplate({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountFormTemplate({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])

    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'form yêu cầu',
            actionType: 'xóa',
            action: async (list_id) => await deleteFormTemplateApi({ list_id: getArrId(list_id) }),
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            title: 'form yêu cầu',
            actionType: 'active',
            action: async (list_id) => await switchStatusFormTemplateApi({ list_id: getArrId(list_id), type: 1 }),
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            title: 'form yêu cầu',
            actionType: 'inactive',
            action: async (list_id) => await switchStatusFormTemplateApi({ list_id: getArrId(list_id), type: 2 }),
        },
    ]

    return (
        <div className="card">
            <HeaderListForm title="Danh sách mẫu form" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Mẫu form"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/form_setup"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                headerInfo={{ items }}
                actionsInfo={{
                    deleteAction: deleteFormTemplateApi,
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                }}
                basePermissions={["insert", "detail", "delete"]}
            >
                <Columnz field="id" header="ID" />
                <Columnz field="title" header="Tiêu đề" />
                <Columnz field="hint" header="Mô tả" />
                <Columnz
                    body={(e) => (
                        <a target="_blank" href={e.url}>
                            {e.url ? 'Tải xuống' : ''}
                        </a>
                    )}
                    header="File"
                />
                <Columnz body={(e) => Body({ data: statusFormSetup, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}

export default FormTemplates
