import { Calendarz, Columnz, DataTablez, Dropdownz, GridForm, Inputz, RenderHeader, TimeBody, useGetParams } from '@/components'
import { status } from '@/constants'
import { formatNumber } from '@/utils'
import { Message } from 'primereact/message'
import { useState } from 'react'
import { useCountNotification, useListNotification } from '../utils'

export const BoxHeader = (props) => {
    const { title, subTitle, fluctuation, error, success, warning } = props
    return (
        <div className="col-12 lg:col-4">
            <div className="card">
                <div className="flex align-items-center justify-content-between">
                    <span className="text-lg font-semibold">{title}</span>
                    <div className="flex gap-2 align-items-center">
                        <span className={fluctuation && fluctuation.includes('-') ? 'text-red-500' : 'text-teal-500'}>
                            {fluctuation}
                        </span>
                        <span className="text-lg font-semibold">{formatNumber(subTitle)}</span>
                    </div>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-between mt-3">
                    <div className="flex align-items-center gap-2">
                        <Message severity="success" className="p-2" />
                        <div className="flex flex-column">
                            <span className="text-sm font-medium">{formatNumber(success)}</span>
                            <span className="text-xs">Thành công</span>
                        </div>
                    </div>
                    <div className="flex align-items-center gap-2">
                        <Message severity="error" className="p-2" />
                        <div className="flex flex-column">
                            <span className="text-sm font-medium">{formatNumber(error)}</span>
                            <span className="text-xs">Lỗi</span>
                        </div>
                    </div>
                    <div className="flex align-items-center gap-2">
                        <Message severity="warn" className="p-2" />
                        <div className="flex flex-column">
                            <span className="text-sm font-medium">{formatNumber(warning)}</span>
                            <span className="text-xs">Bị báo cáo</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
            <Calendarz value={filter.status} className="lg:col-6" onChange showIcon placeholder="Thời gian" />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Phân loại"
            />
        </GridForm>
    )
}

const Companies = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListNotification({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountNotification({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <Header setParams={setParams} />
            <div className="grid grid-form mb-3">
                <BoxHeader
                    success="1000000"
                    error="1000000"
                    warning="1000000"
                    fluctuation="+20%"
                    title="Email"
                    subTitle="2100000"
                />
                <BoxHeader
                    success="1000000"
                    error="1000000"
                    warning="1000000"
                    fluctuation="+20%"
                    title="Email"
                    subTitle="2100000"
                />
                <BoxHeader
                    success="1000000"
                    error="1000000"
                    warning="1000000"
                    fluctuation="+20%"
                    title="Email"
                    subTitle="2100000"
                />
            </div>
            <DataTablez
                value={data}
                header={RenderHeader({ title: 'Danh sách SMS, thông báo', route: '/notification' })}
                title="SMS, thông báo"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
            >
                <Columnz field="name" header="ID" />
                <Columnz field="address" header="Tiêu đề" />
                <Columnz field="email" header="Phân loại" />
                <Columnz header="Ngày gửi" body={(e) => TimeBody(e.created_at)} />
                <Columnz field="phone_number" header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
                <Columnz field="phone_number" header="Tổng số lượng" />
                <Columnz field="phone_number" header="SMS" />
                <Columnz field="phone_number" header="Email" />
                <Columnz field="phone_number" header="App" />
                <Columnz field="phone_number" header="Lỗi" />
            </DataTablez>
        </div>
    )
}

export default Companies
