import {
    Body,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    TimeBody,
    useGetParams
} from '@/components'
import { status, typeCard } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteCardApi, updateCardApi } from '../api'
import { useCountCard, useListCard } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ card_code: '' })
    const apartments = useSelector((state) => state.apartments)
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo mã thẻ, số thẻ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Căn hộ"
            />
            <Dropdownz
                value={filter.type}
                options={typeCard}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại thẻ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}




const Cards = () => {
    const initParam = useGetParams()
    const apartments = useSelector(state => state.apartments)
    const residents = useSelector(state => state.residents)
    const [params, setParams] = useState(initParam)
    const data = useListCard({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCard({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    return (
        <div className="card">
            <HeaderListForm title="Quản lý thẻ cư dân" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="thẻ cư dân"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                headerInfo
                route="/resident_card_management"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                actionsInfo={{ deleteAction: deleteCardApi }}
                basePermissions={['insert', 'detail', 'delete']}
            >
                <Columnz body={e => Body({ data: apartments, value: e.apartment_id })} header="Căn hộ" />
                <Columnz field="code" header="Mã thẻ" />
                <Columnz field="number" header="Số thẻ" />
                <Columnz body={e => Body({ data: typeCard, value: e.type })} header="Loại thẻ" />
                {/* <Columnz field="license_plates" header="Tích hợp phương tiện" /> */}
                <Columnz body={e => Body({ data: residents, value: e.user_info_id, label: 'full_name' })} header="Người sử dụng" />
                <Columnz body={e => TimeBody(e.charge_date)} header="Ngày bắt đầu SD" />
                <Columnz body={e => TimeBody(e.expired)} header="Ngày kết thúc SD" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/recept_form',
                            updateAction: updateCardApi,
                        })
                    }
                />

            </DataTablez>
        </div>
    )
}

export default Cards
