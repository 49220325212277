import {DropdownForm, FormUpdateDialog, InputForm, InputNumberForm, InputNumberFormV2} from '@/components'
import {prices, typeBdcPrices, typePrices} from '@/constants'
import {Button} from '@/uiCore'
import {removePropObject} from '@/utils'
import {useEffect, useState} from 'react'
import {addPriceApi, updatePriceApi} from '../api'
import {useDetailPrice} from '../utils'

const initInfos = {
    name: '',
    bdc_price_type_id: 1,
    description: typeBdcPrices[0].name,
    type: 1,
}
const initData = [{from: null, to: null, price: null}]

const Price = ({value, handleDelete, handleOnChange, index}) => {
    return (
        <>
            <div className="flex gap-4">
                <div className="grid grid-form w-11">
                    <div className="col-12 lg:col-4">
                        <InputNumberForm
                            value={value.from}
                            handleOnChange={(e) => handleOnChange(index, {from: e})}
                            placeholder="Từ"
                        />
                    </div>
                    <div className="col-12 lg:col-4">
                        <InputNumberForm
                            value={value.to}
                            handleOnChange={(e) => handleOnChange(index, {to: e})}
                            placeholder="Đến"
                        />
                    </div>
                    <div className="col-12 lg:col-4">
                        <InputNumberFormV2
                            value={value.price}
                            handleOnChange={(e) => handleOnChange(index, {price: e})}
                            placeholder="Giá tiền"
                        />
                    </div>
                </div>
                <div className="mt-2 w-1 text-center">
                    <Button
                        type="button"
                        raised
                        icon="pi pi-times"
                        severity="info"
                        onClick={() => handleDelete(index)}
                    />
                </div>
            </div>
        </>
    )
}

const PriceV2 = ({value, handleDelete, handleOnChange, index}) => {
    return (
        <>
            <div className="flex gap-4">
                <div className="grid grid-form w-11">
                    <div className="col-12 lg:col-4">
                        <InputNumberForm
                            value="Bậc 1 (Bình thường)"
                        />
                    </div>
                    <div className="col-12 lg:col-4">
                        <InputNumberFormV2
                            value={value.price}
                            handleOnChange={(e) => handleOnChange(index, {price: e}, true)}
                            placeholder="Giá tiền"
                        />
                    </div>
                    <div className="col-12 lg:col-4">
                        <InputNumberForm
                            value={value.description}
                            handleOnChange={(e) => handleOnChange(index, {description: e}, true)}
                            placeholder="Ghi chú"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const UpdatePrice = (props) => {
    const {visible, setVisible, setParams} = props
    // const { id } = useParams()
    const priceDetail = useDetailPrice(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState(initInfos)
    const [data, setData] = useState(initData)
    const [dataz, setDataz] = useState([{ price: null, description: null }, { price: null, description: null }, { price: null, description: null }])

    useEffect(() => {
        if (priceDetail.id) {
            setInfos({...infos, ...priceDetail})
            setData(priceDetail.list_price)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceDetail])

    useEffect(() => {
        if (priceDetail.id && priceDetail.bdc_price_type_id === infos.bdc_price_type_id) {
            setData([...priceDetail.list_price])
        } else {
            setData(initData)
        }
    }, [infos.bdc_price_type_id, priceDetail])

    const handleData = () => {
        let info = {...infos}
        let oldListPrice = priceDetail.list_price
        let newListPrice = data

        let dataChange = []
        if (typeof visible === 'number') {
            info = {...removePropObject(info, priceDetail), id: visible}
            if (oldListPrice && priceDetail.id === info.bdc_price_type_id) {
                //check delete
                const deleteElements = oldListPrice.filter((oldP) => !newListPrice.some((newP) => oldP.id === newP.id))
                if (deleteElements && deleteElements[0]) {
                    deleteElements.forEach((del) => {
                        dataChange.push({price: del.price, from: del.from, to: del.to, id: del.id, deleted_at: 1})
                    })
                }
                //check update
                oldListPrice.forEach((oldP, index) => {
                    const elements = newListPrice.find((newP) => newP.id === oldP.id)
                    if (elements && JSON.stringify(elements) !== JSON.stringify(oldP)) {
                        dataChange.push({
                            price: elements.price,
                            from: elements.from,
                            to: elements.to,
                            id: elements.id,
                        })
                    }
                })
                //check add
                const addElements = newListPrice.filter((p) => !p.id)
                if (addElements && addElements[0]) {
                    addElements.forEach((add) => {
                        dataChange.push(add)
                    })
                }
            }
        } else dataChange = newListPrice
        info.progressive_price = newListPrice
        return info
    }

    const handleOnChange = (key, obj, z) => {
        if ((key || key === 0) && obj) {
            const newData = data.map((d, index) => {
                if (index === key) return {...d, ...obj}
                else return {...d}
            })
            if (z) setDataz([...newData])
            else setData([...newData])
        }
    }

    const handleDelete = (key) => {
        if (key || key === 0) setData((pre) => pre.filter((d, index) => index !== key))
    }

    const handleAdd = () => {
        setData((preData) => [...preData, {from: '', to: '', price: ''}])
    }

    return (
        <>
            <FormUpdateDialog
                visible={visible}
                setVisible={setVisible}
                setParams={setParams}
                route="/services"
                handleData={handleData}
                actions={{add: addPriceApi, update: updatePriceApi}}
                title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'bảng giá'}
            >
                <div className="card bg-color">
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="name"
                                value={infos.name}
                                onChange={(e) => setInfos({...infos, name: e.target.value})}
                                placeholder="Tên bảng giá"
                                label="Tên bảng giá(*)"
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <DropdownForm
                                id="bdc_price_type_id"
                                value={infos.bdc_price_type_id}
                                onChange={(e) => setInfos({...infos, bdc_price_type_id: e.target.value})}
                                options={prices}
                                placeholder="Công thức tính..."
                                label="Công thức tính(*)"
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            <DropdownForm
                                id="description"
                                value={infos.description}
                                options={typeBdcPrices}
                                optionValue="name"
                                onChange={(e) => setInfos({...infos, description: e.target.value})}
                                placeholder="Mô tả ..."
                                label="Mô tả"
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <DropdownForm
                                id="type"
                                value={infos.type}
                                options={typePrices}
                                onChange={(e) => setInfos({...infos, type: e.target.value})}
                                placeholder="Loại ..."
                                label="Loại ..."
                            />
                        </div>
                    </div>

                    {/*{infos.bdc_price_type_id === 1 && (*/}
                    {/*    <div className="grid grid-form w-11">*/}
                    {/*        <div className="col-12 lg:col-4">*/}
                    {/*            <InputNumberFormV2*/}
                    {/*                id="area"*/}
                    {/*                value={data[0] && data[0].price}*/}
                    {/*                handleOnChange={(e) => setData([{from: 0, to: 0, price: e}])}*/}
                    {/*                placeholder="Giá tiền"*/}
                    {/*                label="Giá tiền"*/}
                    {/*                required*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {infos.bdc_price_type_id === 5 && (
                        dataz.map((d, index) => {
                            return (
                                <PriceV2
                                    key={index}
                                    index={index}
                                    handleDelete={handleDelete}
                                    handleOnChange={handleOnChange}
                                    value={d}
                                />
                            )
                        })
                    )}
                    <hr/>
                    {[2, 3, 4].includes(infos.bdc_price_type_id) && (
                        <div className="flex gap-4">
                            <div className="grid grid-form w-11">
                                <div className="col-12 lg:col-4">
                                    <label className="font-medium w-full ml-2">Từ</label>
                                </div>
                                <div className="col-12 lg:col-4">
                                    <label className="font-medium w-full ml-2">Đến</label>
                                </div>
                                <div className="col-12 lg:col-4">
                                    <label className="font-medium w-full ml-2">Giá</label>
                                </div>
                            </div>
                        </div>
                    )}

                    {[2, 3, 4].includes(infos.bdc_price_type_id) &&
                        data &&
                        data[0] &&
                        data.map((d, index) => {
                            return (
                                <Price
                                    key={index}
                                    index={index}
                                    handleDelete={handleDelete}
                                    handleOnChange={handleOnChange}
                                    value={d}
                                />
                            )
                        })}
                    <div className="col-12 lg:col-6">
                        <Button
                            type="button"
                            raised
                            icon="pi pi-plus"
                            severity="info"
                            disabled={infos.bdc_price_type_id === 1}
                            onClick={() => handleAdd()}
                        />
                    </div>
                </div>
            </FormUpdateDialog>
        </>
    )
}

export default UpdatePrice
