
import { usePrintBills } from '../utils'
import { useState } from 'react'
import { checkJson } from '@/utils'


const PrintBills = () => {
    const [ids, setIds] = useState(pre => {
        const billJSON = localStorage.getItem("bill_ids")
        const bill_ids = checkJson(billJSON) || billJSON
        if (Array.isArray(bill_ids) && bill_ids && bill_ids[0]) {
            return bill_ids
        }
    })
    const data = usePrintBills(ids)

    return (
        <div className='' >
            <div className='flex flex-column align-items-center' style={{ pageBreakInside: 'avoid', backgroundColor: '#FFF', }}>
                {
                    data &&
                    Array.isArray(data) &&
                    data.map((item, index) => (
                        <div className='' key={index} style={{ maxWidth: '1170px', fontSize: '14px', fontFamily: 'DejaVu Sans', pageBreakAfter: 'always', }} dangerouslySetInnerHTML={{ __html: item }} />
                    ))
                }
            </div>
        </div>
    )
}

export default PrintBills
