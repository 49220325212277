import { postData, getData } from '@/lib/request'

export const listReceptFormApi = (params) => getData('web2/recept_form/getListReceptForm', params)
export const countReceptFormApi = (params) => getData('web2/recept_form/countReceptForm', params)
export const deleteReceptFormApi = (params) => postData('web2/recept_form/deleteReceptForm', params)
export const detailReceptFormApi = (params) => getData('web2/recept_form/getDetailReceptForm', params)
export const addReceptFormApi = (params) => postData('web2/recept_form/addReceptForm', params)
export const updateReceptFormApi = (params) => postData('web2/recept_form/updateReceptForm', params)
export const updateActiveStatus = (params) => postData('web2/recept_form/activeStatus', params)
export const updateInActiveStatus = (params) => postData('web2/recept_form/inActiveStatus', params)
