import { useEffect, useState } from 'react'
import {
      Columnz,
      DataTablez,
      useGetParams,
      InputForm,
      InputTextareaForm,
      DropdownForm,
      HeaderListForm,
      FormUpdateDialog,
      LoadDialog,
      Dialogz,
      InputNumberForm,
      CalendarForm,
      UploadFiles,
      Calendarz,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { useCountAsset } from '../../asset_mix_management/utils'
import { Button } from 'primereact/button'







const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({ key_search: '' })
      const listCategoryData = [
            { id: 1, name: 'Thiết bị điện' },
            { id: 2, name: 'Đồ gia dụng' },
            { id: 3, name: 'Phòng cháy chữa cháy' },
            { id: 4, name: 'Đồ điện tử' },
            { id: 5, name: 'Đồ Thông Cống' },
            { id: 6, name: 'Thiết bị thông minh' },
      ]

      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
                  <Inputz
                        value={filter.key_search}
                        placeholder="Nhập từ khóa"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
                  />
                  <Dropdownz
                        value={filter.category}
                        options={listCategoryData}
                        onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                        placeholder="Chọn phân loại"
                  />
                  <Calendarz label="" />
            </GridForm>
      )
}

export default function InputAsset() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      // const data = useListAsset({ status: undefined, ...params, first: undefined })
      const totalRecords = useCountAsset({ status: undefined, ...params, first: undefined }) || 0
      const [visible, setVisible] = useState(false)
      const [files, setFiles] = useState([])


      return (
            <>
                  <HeaderListForm title="Quản lý tài sản" />
                  <Header setParams={setParams} />



                  <DataTablez
                        // value={data}
                        title="khóa kỳ"
                        params={params}
                        setParams={setParams}
                        route="/asset"
                        headerInfo
                        actionsInfo
                        basePermissions={["insert", "detail",]}
                  >
                        <Columnz field="name" header="Phân loại" />
                        <Columnz field="name" header="Mã phiếu" />
                        <Columnz field="categogy" header="Phòng ban quản lý" />
                        <Columnz field="typeMaintain" header="Phòng ban tiếp nhận" />
                        <Columnz field="note" header="Nhân viên tiếp nhận" />
                        <Columnz field="note" header="Đơn vị tính" />
                        <Columnz field="note" header="Số lượng" />
                        <Columnz field="timeMaintain" header="File đính kèm" />
                        <Columnz field="timeMaintain" header="Vị trí" />
                        <Columnz field="timeMaintain" header="Thời gian" />
                  </DataTablez>
            </>
      )
}
