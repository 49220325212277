import {
    Calendarz, Columnz, DataTablezV3, Dialogz, Dropdownz,
    GridForm, HeaderListForm, Inputz, MultiRadioButton, NumberFormat, useGetParams
} from '@/components'
import { useEffect, useState } from 'react'

import { listToast } from "@/constants"
import { useGetApi } from "@/lib/react-query"
import { getCycle } from "@/modules/debts/aggregate_debt_details/screens/AggregateDebtDetails"
import { setToast } from "@/redux/features"
import { Button } from "@/uiCore"
import { formatNumber } from '@/utils'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { useListCycleName } from '../../services_list/utils'
import { countAggregateDebt, exportDebt, listDebit, nhacNoApi } from '../api'


const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '', cycle_name: Number(getCycle()) })
    const apartments = useSelector((state) => state.apartments)
    const listCycleName = useListCycleName()
    const [select, setSelect] = useState(1)

    useEffect(() => {
        if (select === 2) {
            setFilter({ ...filter, cycle_name: undefined })
        } else if (select === 3) {
            setFilter({ ...filter, cycle_name: undefined })

        }
        else {
            if (!filter.cycle_name) setFilter({ ...filter, cycle_name: Number(getCycle()) })
        }
    }, [filter.cycle_name, select])

    const anyList = [
        { id: 1, name: 'Một kỳ bảng kê' },
        { id: 2, name: 'Khoảng thời gian' },
        { id: 3, name: 'Khoảng kỳ' },
    ]
    const handleCalendarChange = (e) => {
        if (e.value && e.value.length > 0) {
            const startDate = e.value[0];
            const startOfMonth = startDate ? new Date(startDate.getFullYear(), startDate.getMonth(), 1) : null;
            let endOfMonth = null;
            if (e.value.length > 1) {
                const endDate = e.value[1];
                if (endDate) {
                    endOfMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
                }
            }
            setFilter({ ...filter, dates: [startOfMonth, endOfMonth] });
        }
    };



    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className={"lg:col-12"}>
            <Inputz
                id='debt_search'
                value={filter.debt_search}
                placeholder="Dư nợ cuối kì"
                type="number"
                onChange={(e) => setFilter({ ...filter, debt_search: e.target.value })}
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />

            <Dropdownz
                value={select}
                options={anyList}
                onChange={(e) => setSelect(e.target.value)}
                placeholder="Loại"
                showClear={false}
            />
            {select === 1 && <>
                <Dropdownz
                    value={filter.cycle_name}
                    options={listCycleName}
                    onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                    optionLabel="cycle_name"
                    optionValue="cycle_name"
                    placeholder="Chọn kỳ bảng kê"
                    showClear={false}
                />
            </>}
            {select === 2 && <>
                <Calendarz
                    value={filter.dates}
                    onChange={(e) => setFilter({ ...filter, dates: e.value })}
                    placeholder={"Chọn khoảng thời gian "}
                    selectionMode="range"
                />
            </>}
            {select === 3 && <>
                <Calendarz
                    value={filter.dates}
                    onChange={handleCalendarChange}
                    placeholder={"Chọn khoảng thời gian "}
                    selectionMode="range"
                    view="month"
                />
            </>}

        </GridForm>
    )
}

function AggregateDebt() {
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState(() => {
        if (!initParam.cycle_name) {
            return { ...initParam, cycle_name: Number(getCycle()), limit: 100 }
        } else return { ...initParam, limit: 100 }
    })
    const { data, isLoading } = useGetApi(listDebit, params, "debt")
    const totalRecords = useGetApi(countAggregateDebt, { ...params, first: undefined }, "countAggregateDebt")
    const apartments = useSelector((state) => state.apartments)
    const [loading, setLoading] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [ingredient, setIngredient] = useState(1);
    const dispatch = useDispatch()


    const moreOptions = [
        {
            label: 'Nhắc nợ',
            command: () => {
                if (selectedProducts && selectedProducts[0]) {
                    setVisible(true)
                } else dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn công nợ" }))
            },
            icon: 'pi pi-bell'
        },
    ]

    const getApartmentName = (apartmentId) => {
        const apartment = apartments.find(apartment => apartment.id === apartmentId);
        return apartment ? apartment.name_customer : "";
    };
    const getApartmentCodeById = (apartmentId) => {
        const apartment = apartments.find(apartment => apartment.id === apartmentId);
        return apartment ? apartment.code : "";
    };

    const onSubmit = async () => {
        if (!params.cycle_name) {
            dispatch(setToast({ ...listToast[1], detail: "Vui lòng chọn kỳ" }))
            return
        }
        setLoading(true);
        const data = []
        selectedProducts.forEach(s => {
            const index = data.findIndex(d => d.apartment_id === s.bdc_apartment_id)
            if (index < 0) data.push({ apartment_id: s.bdc_apartment_id, cycle_name: params.cycle_name })
        })
        const response = await nhacNoApi({ data, type: ingredient })
        setLoading(false);
        if (response?.data?.status) {
            setVisible(false)
            setParams(pre => ({ ...pre, render: !pre.render }))
            dispatch(setToast({ ...listToast[0], detail: 'Gửi nhắc nợ thành công!' }))
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    const footerContent = (
        <div>
            <Button label={'Gửi'} icon="pi pi-check" onClick={onSubmit} loading={loading} />
        </div>
    );

    return (
        <div className="card">
            {visible && <Dialogz modal={true} title="Gửi thông báo" visible={visible} setVisible={setVisible} footer={footerContent}>
                <div className='card mt-2'>
                    <label className='font-medium'>Thành viên nhận thông báo</label>
                    <hr />
                    <div className=" flex justify-content-center ">
                        <MultiRadioButton
                            id="ingredient"
                            value={ingredient}
                            onChange={(e) => setIngredient(e)}
                            options={[{ id: 1, name: 'Chủ hộ' }, { id: 2, name: 'Tất cả thành viên của căn hộ' }]}
                            className="w-full gap-8"
                        />
                    </div>
                </div>
            </Dialogz >}
            <HeaderListForm title="Tổng hợp công nợ" />
            <Header setParams={setParams} />
            <div style={{ lineHeight: "2" }}>
                <strong>Tổng đầu kỳ: {formatNumber(data?.synthetic?.before_paid || '0')}</strong> <br />
                <strong>Tổng phát sinh: {formatNumber(data?.synthetic?.incurred || '0')}</strong><br />
                <strong>Tổng ưu đãi: {formatNumber(data?.synthetic?.promotion || '0')}</strong><br />
                <strong>Tổng giảm trừ: {formatNumber(data?.synthetic?.discount_out || '0')}</strong><br />
                <strong>Tổng thanh toán: {formatNumber(data?.synthetic?.paid || '0')}</strong><br />
                <strong>Tổng điều chỉnh: {formatNumber(data?.synthetic?.adjustable || '0')}</strong><br />
                <strong>Tổng dư nợ cuối kỳ: {formatNumber(data?.synthetic?.after_paid || '0')}</strong><br />
            </div>

            <br />
            <DataTablezV3
                dataKey={"bdc_apartment_id"}
                loading={isLoading}
                value={Array.isArray(data?.data) ? data?.data : []}
                title="công nợ"
                totalRecords={typeof totalRecords?.data === 'number' ? totalRecords?.data : 0}
                params={params}
                setParams={setParams}
                route="/aggregate_debt"
                headerInfo={{ exportApi: exportDebt, moreOptions }}
                basePermissions={["export"]}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="full_name" header="Tên KH/NCC"
                    body={(e) => getApartmentName(e.bdc_apartment_id)} />
                <Columnz
                    body={(e) => {
                        const apartment = apartments.find(a => a.id === e.bdc_apartment_id)
                        if (apartment) return <Link
                            to={`/aggregate_debt_details?apartment_id=${apartment.id}&cycle_name=${params.cycle_name}`}>{apartment.name}</Link>
                    }}
                    header="Căn hộ"
                />
                <Columnz field="code" header="Mã căn hộ "
                    body={(e) => getApartmentCodeById(e.bdc_apartment_id)} />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.before_paid })} header="Số dư đầu kỳ" />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.incurred })} header="Phát sinh trong kỳ" />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.promotion })} header="Ưu đãi" />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.discount_out })} header="Giảm trừ" />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.paid })} header="Thanh toán" />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.adjustable })} header="Điều chỉnh" />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.after_paid })} header="Số dư cuối kỳ" />
                <Columnz body={(e) => {
                    return <Link to={`/bill?page=1&limit=10&apartment_id=${e.bdc_apartment_id}`}>{e.count_debt_cyclename}</Link>
                }} header="Tuổi nợ" />
            </DataTablezV3>
        </div>
    )
}

export default AggregateDebt
