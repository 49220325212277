import { TabPanel, TabView } from 'primereact/tabview'
import ApartmentPromotionManage from './ApartmentPromotionManage'
import ListPromotionManage from './PromotionManage'

const Apartment = () => {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Chính sách ưu đãi">
                    <ListPromotionManage />
                </TabPanel>
                <TabPanel header="Chính sách ưu đãi căn hộ">
                    <ApartmentPromotionManage />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default Apartment
