import { CalendarForm, DropdownForm, FormUpdate, InputForm, InputNumberForm, InputSwitchForm } from '@/components'
import { registration_period, registration_time_limit, serviceChargeV2, type_time } from '@/constants'
import { databaseDate } from '@/lib'
import { Button } from '@/uiCore'
import { removePropObject } from '@/utils'
import moment from 'moment'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateUtilitiesApartmentApi } from '../api'
import { useDetailUtilities } from '../utils'

const UpdateUtilitiesApartment = () => {
    const { id } = useParams()
    const [render, setRender] = useState({ render: true })
    const utilitiesDetail = useDetailUtilities(id, render.render)
    const building_place = useSelector((state) => state.buildings)
    const [infos, setInfos] = useState({
        name: '',
        collection_code: '',
        status: '',
        receiver_object: '',
        deposit_fee: '',
        deposit_time_limit: '',
        registrations_number: '',
        registration_period: '',
        location_config: '',
        location_time: '',
        type_time: '',
        registration_time_limit: '',
        start_time: '',
        end_time: '',
    })
    const time_type = infos.type_time
    const [locationConfig, setLocationConfig] = useState([])
    const [locationTime, setLocationTime] = useState([])
    const handleAddLCRow = () => {
        confirmDialog({
            message: `Xác nhận thêm mới cấu hình vị trí`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => {
                const newRow = {
                    id: locationConfig.length + 1,
                    name: '', building_place_id: '', note: ''
                }
                setLocationConfig([...locationConfig, newRow])
            },
        })
    }
    let a = []
    const handleTypeTimeChange = (e) => {
        setInfos({ ...infos, type_time: e.target.value })
        if (infos.type_time === 2) {
            locationTime.forEach((f) => {
                a.push({ id: f.id, deleted_at: true })
            })
            setLocationTime([])
        }
    }
    const handleAddLTRow = () => {
        confirmDialog({
            message: `Xác nhận thêm mới cấu hình thòi gian`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => {
                const newRow = {
                    id: locationTime.length + 1,
                    name: `Khung giờ ${locationTime.length + 1}`,
                    start_time: '',
                    end_time: '',
                    note: '',
                }
                setLocationTime([...locationTime, newRow])
            },
        })
    }
    const handleDeleteLCRow = (id) => {
        confirmDialog({
            message: `Xác nhận xóa cấu hình thòi gian`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => {
                setLocationConfig(locationConfig.filter((row) => row.id !== id))
            },
        })
    }
    const handleDeleteLTRow = (id) => {
        confirmDialog({
            message: `Xác nhận xóa cấu hình thòi gian`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => {
                setLocationTime(locationTime.filter((row) => row.id !== id))
            },
        })
    }
    const handleInputLCChange = (id, field, value) => {
        const newData = locationConfig.map((row) => (row.id === id ? { ...row, [field]: value } : row))
        setLocationConfig(newData)
    }
    const handleInputLTChange = (id, field, value) => {
        const newData = locationTime.map((row) => (row.id === id ? { ...row, [field]: value } : row))
        setLocationTime(newData)
    }
    const locationConfigDetail = utilitiesDetail.location_config || []
    const locationTimeDetail = utilitiesDetail.location_time || []
    useEffect(() => {
        if (utilitiesDetail && utilitiesDetail.id) {
            const newInfo = {
                ...infos,
                ...utilitiesDetail,
                status: utilitiesDetail.status === 0 ? false : true,
            }
            if (utilitiesDetail.location_time && utilitiesDetail.location_time[0] && utilitiesDetail.type_time === 2) {
                newInfo.start_time = new Date(
                    moment().format(`YYYY-MM-DD ${utilitiesDetail.location_time[0]?.start_time}`),
                )
                newInfo.end_time = new Date(moment().format(`YYYY-MM-DD ${utilitiesDetail.location_time[0]?.end_time}`))
            }
            setInfos({ ...newInfo })
            const initialLocationConfig = utilitiesDetail.location_config || []
            setLocationConfig(initialLocationConfig)
            const initialLocationTime = utilitiesDetail.location_time || []
            const updatedLocationTime = initialLocationTime.map((lt, index) => ({
                ...lt,
                name: `Khung giờ ${index + 1}`,
                start_time: new Date(moment().format(`YYYY-MM-DD ${lt.start_time}`)),
                end_time: new Date(moment().format(`YYYY-MM-DD ${lt.end_time}`)),
            }))
            setLocationTime(updatedLocationTime)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utilitiesDetail])
    const invalidStartTime = locationTime.filter((e) => e.start_time === '')
    const invalidEndTime = locationTime.filter((e) => e.end_time === '')
    const handleData = () => {
        const config = []
        const time = []
        let info = { ...infos, status: infos.status ? 1 : 0 }
        let newLocationTime = []
        if (infos.type_time === 1 && locationTime && locationTime[0]) {
            const isInvalidTime = locationTime.some((row) => {
                const startTime = new Date(row.start_time)
                const endTime = new Date(row.end_time)
                return endTime <= startTime
            })
            if (isInvalidTime) {
                return 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu!'
            }
            newLocationTime = locationTime.map((lt) => ({
                id: locationTimeDetail.find((row) => row.id === lt.id) ? lt.id : undefined,
                start_time: lt.start_time,
                end_time: lt.end_time,
                note: lt.note,
            }))
        } else if (infos.type_time === 2) {
            if (infos.start_time === null || infos.end_time === null)
                return 'Vui lòng nhập đủ thông tin cấu hình thời gian!'
            const startTime = new Date(infos.start_time)
            const endTime = new Date(infos.end_time)
            if (startTime >= endTime) {
                return 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu!'
            }
            info.location_time = [
                {
                    start_time: databaseDate(infos.start_time),
                    end_time: databaseDate(infos.end_time),
                },
            ]
        }
        const newLocationConfig = locationConfig.map((lc) => ({
            id: locationConfigDetail.find((row) => row.id === lc.id) ? lc.id : undefined,
            name: lc.name,
            building_place_id: lc.building_place_id,
            note: lc.note,
        }))
        if (Number(id)) {
            info = { ...removePropObject(info, utilitiesDetail), id: id }
            if (utilitiesDetail.location_config) {
                const deleteLCElements = utilitiesDetail.location_config.filter(
                    (d) => !newLocationConfig.some((n) => n.id === d.id),
                )
                if (deleteLCElements && deleteLCElements[0]) {
                    deleteLCElements.forEach((f) => {
                        config.push({ id: f.id, deleted_at: true })
                    })
                }
                newLocationConfig.forEach((n, index) => {
                    const elements = utilitiesDetail.location_config.find((p) => (p.id = n.id))
                    if (
                        elements &&
                        JSON.stringify({
                            name: n.name,
                            building_place_id: n.building_place_id,
                            note: n.note,
                        }) !==
                        JSON.stringify({
                            name: elements.name,
                            building_place_id: elements.building_place_id,
                            note: elements.note,
                        })
                    )
                        config.push({
                            id: elements.id,
                            building_place_id: n.building_place_id,
                            name: n.name,
                            note: n.note,
                        })
                    else if (!elements) {
                        config.push({
                            name: n.name,
                            building_place_id: n.building_place_id,
                            note: n.note,
                        })
                    }
                })
            }
            if (utilitiesDetail.location_time) {
                const deleteLTElements = utilitiesDetail.location_time.filter(
                    (d) => !newLocationTime.some((n) => n.id === d.id),
                )
                if (deleteLTElements && deleteLTElements[0]) {
                    deleteLTElements.forEach((f) => {
                        time.push({ id: f.id, deleted_at: true })
                    })
                }
                newLocationTime.forEach((n, index) => {
                    const elements = utilitiesDetail.location_time.find((p) => (p.id = n.id))
                    const new_start_time = databaseDate(n.start_time)
                    const new_end_time = databaseDate(n.end_time)
                    if (
                        elements &&
                        JSON.stringify({
                            start_time: n.start_time,
                            end_time: n.end_time,
                            note: n.note,
                        }) !==
                        JSON.stringify({
                            start_time: elements.start_time,
                            end_time: elements.end_time,
                            note: elements.note,
                        })
                    ) {
                        time.push({
                            id: elements.id,
                            start_time: new_start_time,
                            end_time: new_end_time,
                            note: n.note,
                        })
                    } else if (!elements) {
                        time.push({
                            start_time: new_start_time,
                            end_time: new_end_time,
                            note: n.note,
                        })
                    }
                })
            }
        }
        if (infos.type_time === 1) {
            a.forEach((f) => {
                time.push({ id: f.id, deleted_at: true })
            })
            info.location_time = time
        }
        if (infos.type_time === 2) {
            locationTime.forEach((f) => {
                time.push({ id: f.id, deleted_at: true })
            })
            time.push(
                {
                    start_time: databaseDate(infos.start_time),
                    end_time: databaseDate(infos.end_time),
                },
            )
            info.location_time = time
        }
        info.location_config = config
        if (infos.name === '') return 'Vui lòng nhập tên tiện ích!'
        if (infos.collection_code === '') return 'Vui lòng nhập mã thu!'
        if (infos.receiver_object === '') return 'Vui lòng chọn đối tượng thu!'
        if (infos.deposit_fee > 100000000) return 'Phí yêu cầu cọc không được lớn hơn 100,000,000!'
        if (infos.deposit_time_limit === '') return 'Vui lòng nhập giới hạn thời gian đặt cọc!'
        if (locationConfig.length === 0) return 'Vui lòng nhập cấu hình vị trí!'
        if (locationConfig.find((e) => e.name === undefined)) return 'Không được để trống tên cấu hình vị trí!'
        if (locationConfig.find((e) => e.building_place_id === '')) return 'Vui lòng chọn tòa nhà cho cấu hình vị trí!'
        if (infos.type_time === '') return 'Vui lòng chọn phân loại thời gian!'
        if (infos.registrations_number === '') return 'Vui lòng nhập số lượt đăng ký tối đa!'
        if (infos.registrations_number >= 120) return 'Số lượt đăng ký tối đa không được vượt quá 120!'
        if (infos.registration_period === '') return 'Vui lòng chọn đơn vị!'
        if (!infos.number_member) return 'Vui lòng nhập số người tham gia tối đa!'
        if (infos.number_member > 40) return 'Số người tham gia tối đa cho phép là 40!'
        if (infos.type_time === 1 && locationTime.length === 0) return 'Vui lòng thêm mới khung giờ!'
        if (infos.type_time === 1 && info.location_time === undefined) return 'Vui lòng thêm mới khung giờ!'
        if (invalidEndTime.length !== 0 || invalidStartTime.length !== 0) return 'Vui lòng nhập đầy đủ thời gian bắt đầu và thời gian kết thúc!'
        info.status = infos.status === true ? 1 : 0
        info.deposit_fee = infos.deposit_fee === '' ? 0 : infos.deposit_fee
        info.registration_time_limit = infos.registration_time_limit === '' ? undefined : infos.registration_time_limit
        return info
    }
    return (
        <FormUpdate
            checkId={Number(id)}
            handleData={handleData}
            refreshObjects={[setInfos]}
            route="/booking"
            title="tiện ích"
            actions={{ update: updateUtilitiesApartmentApi }}
        >
            <div
                className="font-left flex align-items-center mb-4 m-2"
                style={{ height: '25px', borderBottom: '2px solid #dee2e6' }}
            >
                <b className="text-xl p-0">Thông tin tiện ích</b>
            </div>
            <div className="grid grid-form">
                <div className="col-12 lg:col-6  ">
                    <InputForm
                        id="name"
                        value={infos.name}
                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                        label="Tên tiện ích (*)"
                    />
                    <InputForm
                        id="collection_code"
                        value={infos.collection_code}
                        onChange={(e) => setInfos({ ...infos, collection_code: e.target.value })}
                        label="Mã thu (*)"
                    />
                    <InputNumberForm
                        id="register_time_before"
                        value={infos.register_time_before}
                        handleOnChange={(e) => {
                            setInfos({ ...infos, register_time_before: e })
                        }}
                        label="Thời gian tối thiểu trước khi đặt chỗ ('Giờ') "
                    />
                </div>
                <div className="col-12 lg:col-6  ">
                    <InputForm id="bank_id" value="Phí booking" disabled label="Phân loại" />
                    <DropdownForm
                        id="receiver_object"
                        value={infos.receiver_object}
                        onChange={(e) => setInfos({ ...infos, receiver_object: e.target.value })}
                        options={serviceChargeV2}
                        label="Đối tượng thu (*)"
                    />
                    <InputSwitchForm
                        label="Trạng thái"
                        id="status"
                        value={infos.status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        checked={infos.status}
                    />
                </div>
            </div>
            <div
                className="font-left flex align-items-center mb-4 m-2"
                style={{ height: '25px', borderBottom: '2px solid #dee2e6' }}
            >
                <b className="text-xl p-0">Thông tin chi phí</b>
            </div>
            <div className="grid grid-form">
                <div className="col-12 lg:col-6  ">
                    <InputNumberForm
                        id="deposit_fee"
                        value={infos.deposit_fee}
                        handleOnChange={(e) => {
                            setInfos({ ...infos, deposit_fee: e })
                        }}
                        label="Phí yêu cầu đặt cọc"
                    />
                </div>
                <div className="col-12 lg:col-6  ">
                    <InputNumberForm
                        id="cancer_time_before"
                        value={infos.cancer_time_before}
                        handleOnChange={(e) => {
                            setInfos({ ...infos, cancer_time_before: e })
                        }}
                        label="Số giờ cho phép hủy ('Giờ')"
                    />
                </div>
                <div className="col-12 lg:col-6  ">
                    <InputNumberForm
                        id="deposit_time_limit"
                        value={infos.deposit_time_limit}
                        handleOnChange={(e) => setInfos({ ...infos, deposit_time_limit: e })}
                        label="Giới hạn thời gian đặt cọc ('Phút') (*)"
                    />
                </div>
            </div>
            <div
                className="font-left flex align-items-center mb-4 m-2"
                style={{ height: '25px', borderBottom: '2px solid #dee2e6' }}
            >
                <b className="text-xl p-0">Cấu hình vị trí</b>
            </div>
            <div>
                <table className="w-full">
                    <thead>
                        <tr className=" justify-content-center w-full gap-2">
                            <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                Tên vị trí
                            </th>
                            <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                Tòa nhà
                            </th>
                            <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                Ghi chú
                            </th>
                            <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                Hành động
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {locationConfig.map((row) => (
                            <tr key={row.id}>
                                <td>
                                    <InputForm
                                        value={row.name}
                                        onChange={(e) => handleInputLCChange(row.id, 'name', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <DropdownForm
                                        value={row.building_place_id}
                                        onChange={(e) =>
                                            handleInputLCChange(row.id, 'building_place_id', e.target.value)
                                        }
                                        options={building_place}
                                    />
                                </td>
                                <td>
                                    <InputForm
                                        value={row.note}
                                        onChange={(e) => handleInputLCChange(row.id, 'note', e.target.value)}
                                    />
                                </td>
                                <td className="flex align-items-center justify-content-center">
                                    <div className="m-2">
                                        <Button
                                            type="button"
                                            rounded
                                            outlined
                                            icon="pi pi-trash"
                                            onClick={() => handleDeleteLCRow(row.id)}
                                            severity="danger"
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="m-3">
                    <Button style={{ marginLeft: '9px', marginTop: '15px' }} type="button" onClick={handleAddLCRow}>
                        Thêm mới
                    </Button>
                </div>
            </div>
            <div
                className="font-left flex align-items-center mb-4 m-2"
                style={{ height: '25px', borderBottom: '2px solid #dee2e6' }}
            >
                <b className="text-xl p-0">Cấu hình thời gian</b>
            </div>
            <div className="grid grid-form">
                <DropdownForm
                    id="type_time"
                    value={infos.type_time}
                    onChange={(e) => handleTypeTimeChange(e)}
                    options={type_time}
                    label="Phân loại thời gian (*)"
                />
            </div>
            {time_type && time_type === 2 ? (
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6  ">
                        <DropdownForm
                            id="registration_time_limit"
                            value={infos.registration_time_limit}
                            onChange={(e) => setInfos({ ...infos, registration_time_limit: e.target.value })}
                            options={registration_time_limit}
                            label="Giới hạn thời gian sử dụng (*)"
                        />
                    </div>
                    <div className="col-12 lg:col-6  ">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6  ">
                                <CalendarForm
                                    id="start_time"
                                    timeOnly
                                    value={infos.start_time}
                                    onChange={(e) => setInfos({ ...infos, start_time: e.target.value })}
                                    label="Mở cửa"
                                />
                            </div>
                            <div className="col-12 lg:col-6  ">
                                <CalendarForm
                                    timeOnly
                                    id="end_time"
                                    value={infos.end_time}
                                    onChange={(e) => setInfos({ ...infos, end_time: e.target.value })}
                                    label="Đóng cửa"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : time_type && time_type === 1 ? (
                <div>
                    <table className="w-full">
                        <thead>
                            <tr className=" justify-content-center w-full gap-2">
                                <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                    Tên khung giờ
                                </th>
                                <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                    Thời gian bắt đầu
                                </th>
                                <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                    Thời gian kết thúc
                                </th>
                                <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                    Ghi chú
                                </th>
                                <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                                    Hành động
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {locationTime.map((row) => (
                                <tr key={row.id}>
                                    <td>
                                        <InputForm value={row.name} disabled />
                                    </td>
                                    <td>
                                        <CalendarForm
                                            showTime
                                            timeOnly
                                            hourFormat="24"
                                            id="start_time"
                                            value={row.start_time}
                                            onChange={(e) => handleInputLTChange(row.id, 'start_time', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <CalendarForm
                                            showTime
                                            timeOnly
                                            hourFormat="24"
                                            id="end_time"
                                            value={row.end_time}
                                            onChange={(e) => handleInputLTChange(row.id, 'end_time', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <InputForm
                                            value={row.note}
                                            onChange={(e) => handleInputLTChange(row.id, 'note', e.target.value)}
                                        />
                                    </td>
                                    <td className="flex align-items-center justify-content-center">
                                        <div className="m-2">
                                            <Button
                                                type="button"
                                                rounded
                                                outlined
                                                icon="pi pi-trash"
                                                onClick={() => handleDeleteLTRow(row.id)}
                                                severity="danger"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="m-3">
                        <Button style={{ marginLeft: '9px', marginTop: '15px' }} type="button" onClick={handleAddLTRow}>
                            Thêm mới
                        </Button>
                    </div>
                </div>
            ) : null}
            <div
                className="font-left flex align-items-center mb-4 m-2"
                style={{ height: '25px', borderBottom: '2px solid #dee2e6' }}
            >
                <b className="text-xl p-0">Cấu hình đăng ký</b>
            </div>
            <div className="grid grid-form">
                <div className="col-12 lg:col-6  ">
                    <InputNumberForm
                        id="registrations_number"
                        value={infos.registrations_number}
                        handleOnChange={(e) => setInfos({ ...infos, registrations_number: e })}
                        label="Số lượt đăng ký tối đa (*)"
                    />
                    <InputNumberForm
                        id="number_member"
                        value={infos.number_member}
                        handleOnChange={(e) => setInfos({ ...infos, number_member: e })}
                        label="Số lượng người tham gia tối đa (*)"
                    />
                </div>
                <div className="col-12 lg:col-6  ">
                    <DropdownForm
                        id="registration_period"
                        value={infos.registration_period}
                        onChange={(e) => setInfos({ ...infos, registration_period: e.target.value })}
                        options={registration_period}
                        label="Đơn vị (*)"
                    />
                </div>
            </div>
            {/* <div
            className="font-left flex align-items-center mb-4 m-2"
            style={{ height: '25px', borderBottom: '2px solid #dee2e6' }}
        >
            <b className="text-xl p-0">Dịch vụ đi kèm</b>
        </div>
        <div>
            <table className="w-full">
                <thead>
                    <tr className=" justify-content-center w-full gap-2">
                        <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                            STT
                        </th>
                        <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                            Dịch vụ
                        </th>
                        <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                            Đơn giá
                        </th>
                        <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                            Đơn vị
                        </th>
                        <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                            Trạng thái
                        </th>
                        <th className="align-items-center justify-content-center h-4rem surface-200 font-bold border-round">
                            Ghi chú
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => (
                        <tr key={row.id}>
                            <td>{row.id}</td>
                            <td>
                                <DropdownForm
                                    value={row.serviceName}
                                    onChange={(e) => handleInputChange(row.id, 'serviceName', e.target.value)}
                                    options={bank}
                                />
                            </td>
                            <td>
                                <InputForm
                                    type="number"
                                    value={row.quantity}
                                    onChange={(e) =>
                                        handleInputChange(row.id, 'quantity', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <InputForm
                                    value={row.quantity}
                                    onChange={(e) =>
                                        handleInputChange(row.id, 'quantity', e.target.value)
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <i className="pi pi-plus-circle" style={{ fontSize: '1.5rem' }} onClick={handleAddRow}></i>
            </div>
        </div> */}
        </FormUpdate>
    )
}

export default UpdateUtilitiesApartment
