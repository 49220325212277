import { countGroupApi, deleteGroupApi, listExchangeV2Api, listGroupApi, updateGroupApi } from '@/api'
import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { status } from '@/constants'
import { useGetApi } from '@/hooks'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const Header = ({ setParams, exchanges }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, mã bộ phận"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.bdc_department_id}
                options={exchanges}
                onChange={(e) => setFilter({ ...filter, bdc_department_id: e.target.value })}
                placeholder="Phòng ban"
                showClear
            />

            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
                showClear
            />
        </GridForm>
    )
}

const Groups = ({ bdc_department_id }) => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listGroupApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countGroupApi, { ...params, first: undefined }, 0)
    const exchanges = useGetApi(listExchangeV2Api, { ...params, first: undefined }, [])
    const users = useSelector((state) => state.users)

    return (
        <div className={bdc_department_id ? '' : 'card'}>
            {!bdc_department_id && (
                <>
                    <HeaderListForm title="Danh sách bộ phận" />
                    <Header exchanges={exchanges} setParams={setParams} />
                </>
            )}
            <DataTablez
                title="bộ phận"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/group"
                headerInfo={bdc_department_id ? false : true}
                actionsInfo={{ deleteAction: deleteGroupApi }}
                basePermissions={bdc_department_id ? ["detail", "delete"] : ["insert", "detail", "delete"]}
            >
                <Columnz
                    body={(e) => Body({ data: exchanges, value: e.bdc_department_id })}
                    header="Tên phòng ban / dự án"
                />
                <Columnz field="name" header="Tên bộ phận" />
                <Columnz field="code" header="Mã bộ phận" />
                <Columnz field="phone_number" header="Hotline" />
                <Columnz field="email" header="Email liên hệ" />
                <Columnz
                    body={(e) => Body({ data: users, value: e.main_user_id, key: 'user_id', label: 'full_name' }) || "Admin"}
                    header="Trưởng bộ phận"
                />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/group', updateAction: updateGroupApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Groups
