import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm, InputSwitchForm } from '@/components'
import { useDetailConfig } from '../utils'
import { updateConfigApi } from '../api'
import EditorV2 from '@/components/Editorz'

const UpdateConfig = () => {
    const { id } = useParams()
    const configDetail = useDetailConfig(id)
    const [infos, setInfos] = useState({
        title: '',
        value: '',
        key: '',
        description: '',
    })
    useEffect(() => {
        if (configDetail.id) setInfos({ ...infos, ...configDetail, status: configDetail.status === 0 ? false : true })
    }, [configDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, configDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="Config"
            handleData={handleData}
            route="/config_list"
            actions={{ add: updateConfigApi, update: updateConfigApi }}
            refreshObjects={[setInfos]}
        >
            {/* <div className="card bg-color"> */}
            <InputForm
                id="title"
                value={infos.title}
                onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                label="Tiêu đề(*)"
            />
            <InputForm
                id="value"
                value={infos.value}
                onChange={(e) => setInfos({ ...infos, value: e.target.value })}
                label="Giá trị"
            />
            <InputForm
                id="key"
                value={infos.key}
                onChange={(e) => setInfos({ ...infos, key: e.target.value })}
                label="Key"
            />
            <label className="mt-3 font-medium w-5">Mô tả</label>
            <EditorV2
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Mô tả"
            />

            {/* </div> */}
        </FormUpdate>
    )
}

export default UpdateConfig
