import { useEffect, useState } from 'react'
import {
    countBuildingServiceApi,
    countPriceApi,
    countVehiclCategoryApi,
    detailBuildingServiceApi,
    detailPriceApi,
    listBuildingServiceApi,
    listPriceApi,
    listProgressives,
    listVehicleCategories
} from '../api'

//Building
export const useListBuildingService = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBuildingServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}
//--------------------------VehicleCategory-------------------
export const useListVehicleCategories = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listVehicleCategories({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}
export const useCountVehiclCategory = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countVehiclCategoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
//--------------------------------------------------------
export const useListProgressives = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listProgressives({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountBuildingService = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBuildingServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailBuildingService = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailBuildingServiceApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

//Apartment
//Price
export const useListPrice = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPriceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountPrice = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPriceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPrice = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPriceApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
