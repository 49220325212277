import { listFloorV2Api } from '@/api'
import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, useGetParams } from '@/components'
import { statusOpinion } from '@/constants'
import { useGetApi } from '@/hooks'
import { getArrId } from '@/utils'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { activeFormRequestApi, deleteFormRequestApi, inActiveFormRequestApi } from '../api'
import { useCountFormRequest, useListFormRequest } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const floors = useGetApi(listFloorV2Api, {}, [])

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.floor}
                options={floors}
                optionLabel="floor_name"
                optionValue="floor_name"
                onChange={(e) => setFilter({ ...filter, floor: e.target.value })}
                placeholder="Tầng"
            />
            <Dropdownz
                value={filter.status}
                options={statusOpinion}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={statusOpinion}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Người gửi"
            />
        </GridForm>
    )
}

const FormRequests = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListFormRequest({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountFormRequest({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'ý kiến',
            actionType: 'xóa',
            action: async (list_id) => await deleteFormRequestApi({ list_id: getArrId(list_id) }),
        },
        {
            label: 'Active',
            icon: 'pi pi-check',
            title: 'ý kiến',
            actionType: 'active',
            action: async (list_id) => await activeFormRequestApi({ list_id: getArrId(list_id) }),
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',
            title: 'cư dân',
            actionType: 'inactive',
            action: async (list_id) => await inActiveFormRequestApi({ list_id: getArrId(list_id) }),
        },
    ]

    const CreatorBody = (name, createAt) => {
        return (
            <div className="text-center">
                <span>{name}</span> <br />
                <span>{createAt}</span>
            </div>
        )
    }

    const TitleBody = (title, data) => (
        <Link to={'/form_request/comment'} state={{ feedback: data }}>
            {title}
        </Link>
    )

    return (
        <div className="card">
            <HeaderListForm title="Danh sách form yêu cầu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Yêu cầu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/form_request"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                headerInfo={{ items }}
                actionsInfo={{
                    deleteAction: deleteFormRequestApi,
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                }}
            >
                <Columnz field="id" header="ID" />
                <Columnz body={(e) => TitleBody(e.title)} header="Tiêu đề" />
                <Columnz field="content" header="Ý kiến" />
                <Columnz field="apartment" header="Căn hộ" />
                <Columnz field="floor" header="Tầng" />
                <Columnz field="name" header="Tòa nhà" />
                <Columnz body={(e) => CreatorBody(e.creator, e.created_at)} header="Người viết" />
                <Columnz body={(e) => Body({ data: statusOpinion, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}

export default FormRequests
