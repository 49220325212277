import { HeaderListForm } from '@/components'
import { listToast } from '@/constants'
import { useGetApi } from '@/hooks'
import { listBuildingInfoApi } from '@/modules/buildings/building_overview/api'
import { setToast } from '@/redux/features'
import { Button, Image, RadioButton } from '@/uiCore'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { configBill } from '../api'

function ConfigBill() {
      const dispatch = useDispatch()
      const dataBill = useGetApi(listBuildingInfoApi, {}, {})
      const [infos, setInfos] = useState(null)
      useEffect(() => {
            if (dataBill && dataBill.bill_code) {
                  setInfos(dataBill.bill_code)
            }
      }, [dataBill])


      const item = useSelector((state) => state.item)
      const handleUpdateBill = async () => {
            if (!infos) dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn mẫu bảng kê' }))
            const params = {
                  bill_code: infos,
                  building_id: item.building_id
            }
            const data = await configBill(params)
            if (!data.data.status) {
                  dispatch(setToast({ ...listToast[1], detail: data.data.mess }))
            } else {
                  dispatch(setToast({ ...listToast[0], detail: "Cập nhật mẫu bảng kê thành công" }))
            }
      }
      return (
            <div className=''>
                  <HeaderListForm title="Cấu hình mẫu bảng kê" />
                  <div className="card">
                        <Button label="Lưu " onClick={handleUpdateBill} className="mb-3" />
                        <div className="flex justify-content-between gap-3 " >
                              <div className="card  flex-1 ">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 1</h5>
                                          <RadioButton value="BILL_V1" onChange={(e) => setInfos(e.value)} checked={infos === 'BILL_V1'} />

                                    </div>
                                    <Image src="https://devapibdc.dxmb.vn/images/billv1.png" width="100%" preview />
                              </div>
                              <div className="card  flex-1">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 2</h5>
                                          <RadioButton value="BILL_V2" onChange={(e) => setInfos(e.value)} checked={infos === 'BILL_V2'} />
                                    </div>
                                    <Image src="https://devapibdc.dxmb.vn/images/billv2.png" width="100%" preview />
                              </div>
                              <div className="card  flex-1">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 3</h5>
                                          <RadioButton value="BILL_V3" onChange={(e) => setInfos(e.value)} checked={infos === 'BILL_V3'} />
                                    </div>
                                    <Image src="https://devapibdc.dxmb.vn/images/billv3.png" width="100%" preview />
                              </div>
                              <div className="card  mb-5 flex-1">
                                    <div className="flex gap-3">
                                          <h5>Mẫu 4</h5>
                                          <RadioButton value="BILL_V4" onChange={(e) => setInfos(e.value)} checked={infos === 'BILL_V4'} />
                                    </div>
                                    <Image src="https://devapibdc.dxmb.vn/images/billv4.png" width="100%" preview />
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default ConfigBill
