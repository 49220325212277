import React from 'react'
import { useRenderBillWareHouse } from '../utils'
import { useParams } from 'react-router-dom';

function PrintBillWareHouse() {
      const { id } = useParams()
      const data = useRenderBillWareHouse(id)
      return (
            <div className='flex justify-content-center align-items-center' >
                  {
                        data &&
                        typeof data === 'string' && (
                              <div style={{ width: "1140px", fontFamily: 'DejaVu Sans' }} dangerouslySetInnerHTML={{ __html: data }} />
                        )
                  }
            </div>
      )
}

export default PrintBillWareHouse

