import { CalendarForm, DropdownForm, HeaderListForm, InputForm, InputFormV2, InputNumberForm, InputTextareaForm } from '@/components'

import { useEffect, useState } from 'react'

import { customer_payments_type, listToast, typesV3, typesV4 } from '@/constants'
import { Button } from 'primereact/button'


import { setToast } from '@/redux/features'
import { confirmDialog } from 'primereact/confirmdialog'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useListSevicesId } from '../../promotion_management/utils'
import { createCharacters } from '../api'
import { CustomModal } from './CreateReceipt'
import { formatNumber } from '@/utils'
import { useListServiceApartments } from '../utils'
import { ProgressSpinner } from 'primereact/progressspinner'

export default function CreateFeesOther() {
      const [infos, setInfos] = useState({
            apartment_id: '',
            dsc: '',
            type_receipt: 'phieu_thu_khac ',
            create_date: new Date()
      });
      const [newData, setNewData] = useState([{ type: 1, }]);
      const navigate = useNavigate()
      const apartments = useSelector((state) => state.apartments)
      const services = useListServiceApartments({ apartment_id: infos.apartment_id })
      const dispatch = useDispatch()
      const [showSuccessDialog, setShowSuccessDialog] = useState(false)
      const handleDelete = (indexV2) => {
            if (indexV2 || indexV2 === 0) setNewData((pre) => pre.filter((d, index) => index !== indexV2))
      }
      const handleAdd = () => {
            const availableServices = getAvailableServices();
            const newItem = { type: 1 };
            if (availableServices.length > 0) {
                  newItem.bdc_apartment_service_price_id = availableServices[0].id;
            }
            setNewData((prevData) => [...prevData, { type: 1, bdc_apartment_service_price_id: null }]);
      }


      const handleChangeServices = (value, index) => {
            const updateNewItem = [...newData];
            updateNewItem[index].bdc_apartment_service_price_id = value
            setNewData(updateNewItem)
      }
      const handleChangeMoney = (value, index) => {
            const updateNewItem = [...newData];
            updateNewItem[index].coin = value

            setNewData(updateNewItem)
      }

      const formatDate = (isoDate) => {
            const date = new Date(isoDate)
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            const seconds = String(date.getSeconds()).padStart(2, '0')

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }
      const resetForm = () => {
            setInfos({ apartment_id: '', dsc: '', type_receipt: 'phieu_thu_khac ', create_date: new Date() })
            setNewData([{ type: 1, bdc_apartment_service_price_id: null }])
      }
      const [loading, setLoading] = useState(false);
      const handleData = () => {
            if (!infos.apartment_id) {
                  dispatch(setToast({ ...listToast[1], detail: 'Căn hộ không được bỏ trống' }))
            } else if (!infos.customer_payments) {
                  dispatch(setToast({ ...listToast[1], detail: 'Hình thức không được bỏ trống' }))
            } else if (!infos.type_receipt) {
                  dispatch(setToast({ ...listToast[1], detail: 'Kiểu phiếu không được bỏ chống' }))
            } else if (!infos.create_date) {
                  dispatch(setToast({ ...listToast[1], detail: 'Ngày hạch toán không được bỏ chống' }))
            } else if (newData && newData.some(item => item.bdc_apartment_service_price_id === null || item.bdc_apartment_service_price_id === undefined)) {
                  dispatch(setToast({ ...listToast[1], detail: ' Dịch vụ không được bỏ trống' }))
            } else if (newData && newData.some(item => !item.coin)) {
                  dispatch(setToast({ ...listToast[1], detail: 'Số tiền không được bỏ trống' }))
            } else {
                  const params = {
                        apartment_id: infos.apartment_id,
                        arr_pay: [...newData],
                        amount: totalAmount,
                        customer_payments: infos.customer_payments,
                        dsc: infos.dsc,
                        type_receipt: infos.type_receipt,
                        create_date: formatDate(infos.create_date),
                  }

                  confirmDialog({
                        message: `Xác nhận thêm mới phiếu thu khác`,
                        header: process.env.REACT_APP_BRANCH_NAME,
                        icon: 'pi pi-info-circle',
                        accept: async () => {
                              setLoading(true);
                              const { data } = await createCharacters(params)
                              setLoading(false);
                              if (!data.status) {
                                    dispatch(setToast({ ...listToast[1], detail: data.mess }))
                              } else {
                                    resetForm()
                                    dispatch(setToast({ ...listToast[0], detail: "Thêm phiếu thu khác thành công" }))
                                    setShowSuccessDialog(true)
                                    navigate('/receipt')
                              }
                        },
                  })
            }
      }
      const [totalAmount, setTotalAmount] = useState(0)

      useEffect(() => {

            const calculatedTotal = newData.reduce((acc, item) => acc + parseFloat(item.coin || 0), 0);
            setTotalAmount(calculatedTotal);
      }, [newData]);

      const servicesV2 = [
            { id: 0, name: 'Phí không chỉ định' },
            ...services
      ];

      const optionServices = servicesV2.map(obj => {
            if ("vehicle_name" in obj && obj["vehicle_name"]) {
                  return obj;
            } else {
                  return { ...obj, "vehicle_name": obj["name"] };
            }
      });
      const getAvailableServices = () => {
            const selectedServiceIds = newData.map(item => item.bdc_apartment_service_price_id);

            return optionServices.filter(service => !selectedServiceIds.includes(service.id));
      };
      return (

            <div className="card bg-color">
                  {loading && <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        zIndex: 9999,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                  }}>
                        <ProgressSpinner />
                  </div>
                  }
                  <HeaderListForm
                        title={'Thêm phiếu thu khác'}
                  />
                  <div className=" grid grid-form">
                        <div className="col-3 ">
                              <DropdownForm
                                    id="apartment_id"
                                    label="Căn hộ (*)"
                                    value={infos.apartment_id}
                                    options={apartments}
                                    onChange={(e) => setInfos({ ...infos, apartment_id: e.target.value })}
                              />
                        </div>
                        <div className="col-3 ">
                              <DropdownForm
                                    className="w-full"
                                    options={customer_payments_type}
                                    onChange={(e) => setInfos({ ...infos, customer_payments: e.target.value })}
                                    value={infos.customer_payments}
                                    label="Hình thức (*)"
                              />
                        </div>
                        <div className="col-3 ">
                              <DropdownForm
                                    className="w-full"
                                    options={typesV4}
                                    value={infos.type_receipt}
                                    onChange={(e) => setInfos({ ...infos, type_receipt: e.target.value })}
                                    label="Kiểu phiếu (*)"
                                    disabled
                              />
                        </div>
                        <div className="col-3 ">
                              <CalendarForm
                                    id="create_date"
                                    value={infos.create_date}
                                    className="w-full"
                                    onChange={(e) => setInfos({ ...infos, create_date: e.target.value })}
                                    label="Ngày hạch toán (*)"
                              />
                        </div>

                        <div className="col-12 ">
                              <InputForm className="w-full" disabled label="Tổng số tiền" value={formatNumber(totalAmount)} />
                        </div>
                        <div className="col-12">
                              <InputTextareaForm
                                    value={infos.dsc}
                                    onChange={(e) => setInfos({ ...infos, dsc: e.target.value })}
                                    label="Ghi chú"
                              />
                        </div>
                  </div>
                  <div className="grid grid-form align-items-center">
                        {newData.map((d, index) => {
                              const availableServices = getAvailableServices().concat(servicesV2.filter((service) => service.id === d.bdc_apartment_service_price_id));
                              const currentServiceOptions = availableServices.map(service => ({ ...service, vehicle_name: service.name || service.vehicle_name }));
                              const opitonz = currentServiceOptions.filter(d => d.status !== 0)

                              return (
                                    <div key={index} className="grid grid-form w-full justify-content-center align-items-center mx-1">
                                          <div className="col-12 lg:col-5">
                                                <DropdownForm
                                                      label="Dịch vụ (*)"
                                                      options={opitonz}
                                                      optionLabel="name"
                                                      value={d.bdc_apartment_service_price_id}
                                                      onChange={(e) => handleChangeServices(e.target.value, index)}
                                                      disabled={infos.apartment_id ? false : true}
                                                />
                                          </div>
                                          <div className="col-12 lg:col-5">

                                                <InputNumberForm
                                                      label="Số tiền (*)"
                                                      className="w-full"
                                                      value={d.coin}
                                                      handleOnChange={(e) => handleChangeMoney(e, index)}
                                                />
                                          </div>
                                          <div className="lg:col-2">
                                                <Button
                                                      type="button"
                                                      raised
                                                      icon="pi pi-times"
                                                      severity="danger"
                                                      disabled={newData.length <= 1}
                                                      onClick={() => handleDelete(index)
                                                      }
                                                />
                                          </div>


                                    </div>
                              )
                        })}
                  </div>
                  <Button
                        type="button"
                        raised
                        icon="pi pi-plus"
                        severity="info"
                        label="Thêm chỉ định tiền thừa"
                        onClick={() => handleAdd()}
                        className="ml-2"
                  />

                  <div style={{}}>
                        <div className='flex justify-content-end py-5'>
                              <Button
                                    severity="danger"
                                    style={{ marginLeft: '9px', marginTop: '15px' }}
                                    type="button"
                                    onClick={() => navigate('/receipt')}
                              >
                                    Trở về
                              </Button>
                              <Button style={{ marginLeft: '9px', marginTop: '15px' }} type="submit" onClick={() => handleData()}>
                                    Thêm mới phiếu thu khác
                              </Button>
                        </div>
                        {showSuccessDialog && (
                              <CustomModal
                                    visible={showSuccessDialog}
                                    message="Thêm mới phiếu thu khác thành công!"
                                    header={process.env.REACT_APP_BRANCH_NAME}
                                    icon="pi pi-check"
                                    acceptClassName="p-button-success"
                                    acceptLabel="OK"
                                    onAccept={() => resetForm()}
                                    onHide={() => setShowSuccessDialog(false)}
                              />
                        )}
                  </div>
            </div>
      )
}
