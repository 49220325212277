import { getData, postData } from '@/lib/request'

export const listUtilitiesApartmentApi = (params) => getData('web2/booking/getListBooking', params)
export const countUtilitiesApartmentApi = (params) => getData('web2/booking/countBooking', params)
export const detailUtilitiesAparmentApi = (params) => getData('web2/booking/detailBooking', params)
export const deleteUtilitiesApartmentApi = (params) => postData('web2/booking/deleteBooking', params)
export const addUtilitiesApartmentApi = (params) => postData('web2/booking/addBooking', params)
export const updateUtilitiesApartmentApi = (params) => postData('web2/booking/updateBooking', params)
export const importUtilitiesApartment = (params) =>
    postData('web2/apartment_management/importUtilitiesApartment', params, false, true)
export const exportUtilitiesApartment = (params) =>
    getData('web2/apartment_management/expUtilitiesApartment', params, true)

export const listPartnerUtilitiesApi = (params) => getData('web2/apartment_management/listPartnerUtilities', params)
export const addPartnerUtilitiesApi = (params) =>
    postData('web2/apartment_management/createListPartnerUtilities', params)
export const updatePartnerUtilitiesApi = (params) =>
    postData('web2/apartment_management/updatePartnerUtilities', params)
export const countPartnerUtilitiesApi = (params) =>
    getData('web2/apartment_management/countListPartnerUtilities', params)
export const detailPartnerUtilitiesApi = (params) => getData('web2/apartment_management/detailPartnerUtilities', params)
export const deletePartnerUtilitiesApi = (params) =>
    postData('web2/apartment_management/deletePartnerUtilitiesV2', params)
export const exportPartnerUtilities = (params) => getData('web2/apartment_management/expPartnerUtilities', params, true)

export const deleteResidentOfUtilitiesApartmentApi = (params) =>
    postData('web2/apartment_management/deleteResidentOfUtilitiesApartment', params)
export const addUtilitiesApartmentToGroupApi = (params) =>
    postData('web2/apartment_management/apartmentToGroup', params)

export const listBookingTimePlaceApi = (params) => getData('web2/booking_time_place/getListBookingTimePlace', params)
export const countBookingApi = (params) => getData('web2/booking_time_place/countBookingTimePlace', params)
export const listCommnentBookingApi = (params) => getData('web2/booking_time_place/getListCommentBooking', params)

export const detailBookingTimePlaceApi = (params) => getData('web2/booking_time_place/detailBookingTimePlace', params)
export const detailBookingApi = (params) => getData('web2/booking/detailBooking', params)
export const addBookingTimePlaceApi = (params) => postData('web2/booking_time_place/addBookingTimePlace', params, true)
export const updateBookingTimePlaceApi = (params) => postData('web2/booking_time_place/updateBookingTimePlace', params, true)
export const deleteBookingTimePlaceApi = (params) => postData('web2/booking_time_place/deleteBookingTimePlace', params)

export const switchStatusFeedbackApi = (params) => postData('web2/feedback_comment/switchStatusFeedback', params)
export const detailRegisterRepairApi = (params) => getData('web2/feedback_comment/detailFeedBack', params)
export const switchStatusRequestApi = (params) => postData('web2/feedback_comment/switchStatus', params)
export const addCommentApi = (params) => postData('web2/booking_time_place/insertComment', params, true)
export const deleteCommentApi = (params) => postData('web2/booking_time_place/deleteComment', params)

export const listBookingRegisteredApi = (params) => getData('web2/booking/getListBookingRegistered', params)

