import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import MachineWork from './MachineWork'
import WorksheetByShift from './WorksheetByShift'
import SummaryTable from './SummaryTable'

function TimeManagement() {
      return (
            <div className='card'>
                  <TabView>
                        <TabPanel header="Bảng công máy">
                              <MachineWork />
                        </TabPanel>
                        <TabPanel header="Bảng công làm việc theo ca">
                              <WorksheetByShift />
                        </TabPanel>
                        <TabPanel header="Bảng công tổng hợp">
                              <SummaryTable />
                        </TabPanel>
                  </TabView>
            </div>
      )
}

export default TimeManagement
