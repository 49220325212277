import {
    ActionBody,
    Body, Columnz,
    DataTablez, Dropdownz,
    GridForm,
    HeaderListForm, LoadDialog,
    StatusBody,
    TimeBody,
    useGetParams
} from '@/components'
import { status } from '@/constants'
import ImportServiceApartment from "@/modules/accountant/aparment_services/screen/ImportServiceApartment"
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteApartmentServiceApi, exportServiceApartmentApi, updateStatusApartmentServiceApi } from '../api'
import { useCountApartmentService, useListApartmentService } from '../utils'
import UpdateApartmentService from './UpdateApartmentService'

const Header = ({ setParams, apartments, services }) => {
    const [filter, setFilter] = useState({})

    return (
        <GridForm
            setParams={setParams}
            filter={filter}
            setFilter={setFilter}
        >
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Căn hộ"
            />
            <Dropdownz
                value={filter.service_id}
                options={services}
                // options={services.filter(s => s.type !== 4)}
                onChange={(e) => setFilter({ ...filter, service_id: e.target.value })}
                placeholder="Dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const ApartmentServices = ({ apartment_id }) => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListApartmentService({ status: undefined, apartment_id, ...params, first: undefined })
    const totalRecords = useCountApartmentService({ status: undefined, apartment_id, ...params, first: undefined }) || 0
    const apartments = useSelector((state) => state.apartments)
    const [visible, setVisible] = useState(false)
    const services = useSelector((state) => state.services)
    const [visibleImport, setVisibleImport] = useState(false)

    return (
        <div className="card">
            <HeaderListForm title="Dịch vụ sử dụng"/>
            {visible && <UpdateApartmentService setParams={setParams} visible={visible} setVisible={setVisible} apartment_id={apartment_id} />}
            {visibleImport && <ImportServiceApartment visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            <LoadDialog visible={visible} />
            {!apartment_id && <>
                <HeaderListForm title="Danh sách dịch vụ căn hộ" />
                <Header setParams={setParams} services={services} apartments={apartments} />
            </>}
            <DataTablez
                value={data}
                title="dịch vụ căn hộ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                setVisibledDialog={setVisible}
                // selectedProducts={selectedProducts}
                // setSelectedProducts={setSelectedProducts}
                route="/service_apartment"
                // actionsInfo={{ deleteAction: deleteApartmentServiceApi }}
                headerInfo={apartment_id ? {} : { setVisibleImport: setVisibleImport, exportApi: exportServiceApartmentApi }}
                basePermissions={apartment_id ? ["detail", "insert"] : ["insert", "delete", "detail", "import", "export"]}
                rowClassName={(rowData) => rowData.deleted_at ? 'deleted-row' : ''}
            >
                <Columnz header="Căn hộ" body={(e) => Body({ data: apartments, value: e.bdc_apartment_id })} />
                <Columnz
                    field="code"
                    header="Mã căn hộ"
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'code' })}
                />
                <Columnz header="Dịch vụ" body={(e) => {
                    return e.number ?
                        <>
                            {Body({ data: services, value: e.bdc_service_id })} - {e.number}
                        </> :
                        Body({ data: services, value: e.bdc_service_id })
                }} />

                <Columnz body={e => Body({ data: services, value: e.bdc_service_id, label: "code_receipt" })} header="Mã phí" />
                <Columnz body={(e) => TimeBody(e.first_time_active, 'date')} header="Ngày bắt đầu" />
                <Columnz field="finish" header="Ngày kết thúc" body={(e) => TimeBody(e.finish, 'date')} />
                <Columnz body={(e) => TimeBody(e.last_time_pay, 'date')} header="Ngày tính phí tiếp theo" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => e.deleted_at ? StatusBody({ e }) : StatusBody({
                        e,
                        route: '/service_apartment',
                        updateAction: updateStatusApartmentServiceApi,
                    })
                    }
                />

                <Columnz
                    header="Thao tác"
                    body={(e) => e.deleted_at ? <ActionBody
                    /> : <ActionBody
                        id={e.id}
                        route="/service_apartment"
                        deleteAction={deleteApartmentServiceApi}
                        handleDelete={(id) => {
                            return { id: id }
                        }}
                        params={params}
                        setParams={setParams}
                        setVisibledDialog={setVisible}
                        headerInfo={apartment_id ? {} : { setVisibleImport: setVisibleImport, exportApi: exportServiceApartmentApi }}
                        basePermissions={apartment_id ? ["detail",] : [, "delete", "detail"]}
                    />}
                />

            </DataTablez>
        </div>
    )
}

export default ApartmentServices
