import {useEffect, useState} from 'react'
import {FormUpdateDialog, InputForm} from '@/components'
import {cancelInvoiceApi} from "@/api";

export default function CancelInvoice(prop) {
    const {visible, setVisible, setParams, data = []} = prop
    const [infos, setInfos] = useState({})
    const item = data.find(d => d.other_id === visible)

    useEffect(() => {
        if (item.id) {
            setInfos({ ...item })
        }
    }, [item])

    const handleData = () => {
        return {other_id: visible, destroy_reason: infos.reason}
    }

    return (
        <FormUpdateDialog
            checkId={true}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            title="Hủy hóa đơn"
            actions={{update: cancelInvoiceApi}}
        >
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <InputForm id="other_id" value={infos.other_id} label="Mã hóa đơn" disabled/>
                    <InputForm id="invoice_number" value={infos.invoice_number} label="Số hóa đơn" disabled/>
                    <InputForm id="invoice_date" value={infos.invoice_date} label="Ngày phát hành" disabled/>
                </div>
                <div className="col-12 lg:col-6">
                    <InputForm id="invoice_series" value={infos.invoice_series} label="Mã template" disabled/>
                    <InputForm id="tax_authority_code" value={infos.tax_authority_code} label="Mã CQT" disabled/>
                    <InputForm id="reason" value={infos.reason} label="Lý do hủy hóa đơn (*)" required
                               onChange={e => setInfos({ ...infos, reason: e.target.value })}/>
                </div>
            </div>
        </FormUpdateDialog>
    )
}
