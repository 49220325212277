import {
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz, LoadDialog, StatusBody,
    TimeBody,
    useGetParams
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { updateStatusConfirmConfigApi } from '../api'
import { useCountConfirmConfig, useListuseListConfirmConfig } from '../utils'
import AddConfirmConfig from './AddConfirmConfig'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm ..."
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                options={status}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}
const ApprovalList = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const totalRecords = useCountConfirmConfig({ status: undefined, ...params, first: undefined }) || 0
    const data = useListuseListConfirmConfig({ status: undefined, ...params, first: undefined })
    const [visible, setVisible] = useState(false)

    return (
        <div className="card">
            <HeaderListForm title="Danh sách quyền phê duyệt" />
            <Header
                setParams={setParams}
            />
            <LoadDialog visible={visible} />
            {visible && <AddConfirmConfig setParams={setParams} visible={visible} setVisible={setVisible} />}
            <DataTablez
                title="phê duyệt"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setVisibledDialog={setVisible}
                setParams={setParams}
                route="/confirm_manage"
                headerInfo
                actionsInfo={{}}
                basePermissions={['insert', 'detail', 'delete']}
            >
                <Columnz field="name" header="Quyền phê duyệt" />
                <Columnz field="note" header="Mô tả" />
                <Columnz
                    header="Ngày tạo"
                    body={(e) => TimeBody(e.created_at, 'date')}
                />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({
                        e,
                        route: '/confirm_manage',
                        updateAction: updateStatusConfirmConfigApi
                    })}
                />
            </DataTablez>
        </div>
    )
}

export default ApprovalList
