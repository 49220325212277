import { getData, postData } from '@/lib/request'

export const listPriceApi = (params) => getData('web2/list_price/getListProgressives', params)
export const countPriceApi = (params) => getData('web2/list_price/countProgressives', params)
export const deletePriceApi = (params) => postData('web2/list_price/delProgressives', params)
export const detailPriceApi = (params) => getData('web2/list_price/getDetailProgressives', params)
export const addPriceApi = (params) => postData('web2/list_price/addProgressives', params)
export const updatePriceApi = (params) => postData('web2/list_price/updateProgressives', params)
export const listPriceV2Api = (params) => getData('web2/info/getListProgressInfo', params)
export const importPrice = (params) => postData('web2/list_price/importPrice', params, false, true)
export const exportPriceApi = (params) => getData('web2/list_price/expListPrice', params, true)

