import { resetPassUserApi } from "@/api"
import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { listToast, status, status_Contract, type_Contract } from '@/constants'
import { useGetApi } from '@/hooks'
import { setToast } from "@/redux/features"
import { Dialog } from "@/uiCore"
import { confirmDialog } from "primereact/confirmdialog"
import { DataTable } from 'primereact/datatable'
import { useState } from 'react'
import { useDispatch } from "react-redux"
import { listPosition } from '../../position_management/api'
import { countUserApi, deleteUserApi, listExchangeV2Api, listGroupV2Api, listUserApi, updateUserApi } from '../api'
const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({})
      const groups = useGetApi(listGroupV2Api)
      const exchanges = useGetApi(listExchangeV2Api)

      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
                  <Inputz value={filter.key_search}
                        placeholder="Tìm kiếm theo tên, mã"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })} />

                  <Dropdownz
                        value={filter.department_id}
                        placeholder="Phòng ban"
                        options={exchanges}
                        onChange={(e) => setFilter({ ...filter, department_id: e.target.value })}
                  />
                  <Dropdownz
                        value={filter.group_id}
                        placeholder="Chức vụ"
                        options={groups}
                        // optionLabel={'name'}
                        // optionValue={optionValue ? optionValue : 'id'}
                        onChange={(e) => setFilter({ ...filter, group_id: e.target.value })}
                  />
                  <Dropdownz
                        value={filter.status}
                        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                        placeholder="Trạng thái"
                        options={status}

                  />
            </GridForm>
      )
}
function ListPersonnel() {
      const dispatch = useDispatch()
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useGetApi(listUserApi, { ...params, first: undefined, type: 'hrm' }, [])
      const totalRecords = useGetApi(countUserApi, { ...params, first: undefined }, 0)
      const exchanges = useGetApi(listExchangeV2Api, { ...params, first: undefined }, [])
      const groups = useGetApi(listGroupV2Api)
      const [visible, setVisible] = useState(false)
      const position = useGetApi(listPosition)

      const [expandedRows, setExpandedRows] = useState({});
      const rowExpansionTemplate = (rowData) => {
            const bills = rowData.bills;
            return (
                  <div className='card'>
                        {bills && bills[0] ?
                              <>
                                    <HeaderListForm title="Thông tin hợp đồng" />
                                    <DataTable value={bills}>
                                          <Columnz body={(rowData) => Body({ data: position, value: rowData.position })} header="Vị trí" />
                                          <Columnz body={(rowData) => Body({ data: type_Contract, value: rowData.type })} header="Loại hợp đồng" />
                                          <Columnz body={(rowData) => Body({ data: status_Contract, value: rowData.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                                                className='text-center' />
                                          <Columnz body={(rowData) => TimeBody(rowData.sign_date, 'date')} header="Ngày ký HĐ" />
                                          <Columnz body={(rowData) => TimeBody(rowData.expired_date, 'date')} header="Ngày hết hạn" />
                                    </DataTable>

                              </>
                              : <HeaderListForm title="Nhân sự này chưa có hợp đồng" />
                        }

                  </div>
            );
      };
      const tonggleCard = (rowData) => {
            return (
                  <i
                        className="pi pi-id-card"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                              setExpandedRows(prevState => {
                                    if (prevState[rowData.id]) {
                                          const newState = { ...prevState };
                                          delete newState[rowData.id];
                                          return newState;
                                    } else {
                                          return { ...prevState, [rowData.id]: true };
                                    }
                              });
                        }}
                  ></i>
            );
      };

      async function accept(id) {
            const response = await resetPassUserApi({ id })
            if (response.data.status) {
                  dispatch(setToast({ ...listToast[0], detail: 'Đổi mật khẩu thành công!' }))
                  setVisible(response.data.mess)
            } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
      }

      const confirm = (id) => {
            confirmDialog({
                  message: 'Bạn có muốn tiếp tục thay đổi mật khẩu của người dùng này?',
                  header: process.env.REACT_APP_BRANCH_NAME,
                  icon: 'pi pi-info-circle',
                  accept: () => accept(id),
            })
      }
      const moreActions = [{ icon: 'pi pi-sync', command: (id) => confirm(id) }]

      return (
            <div className='card'>
                  <Dialog
                        header="Mật khẩu mới"
                        visible={Boolean(visible)}
                        style={{ width: '500px' }}
                        onHide={() => setVisible(false)}
                  >
                        <p className="mt-6 text-center text-xl font-bold mb-4">{visible}</p>
                  </Dialog>
                  <HeaderListForm title="Danh sách nhân sự" />
                  <Header setParams={setParams} />
                  <DataTablez
                        value={data}
                        title="khóa kỳ"
                        totalRecords={totalRecords}
                        params={params}
                        setParams={setParams}
                        route="/list_personnel"
                        // setVisibledDialog={setVisible}
                        headerInfo
                        actionsInfo={{ deleteAction: deleteUserApi, moreActions }}
                        basePermissions={["insert", "detail", "delete"]}
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}
                  >
                        <Columnz field="code" header="Mã nhân viên" />
                        <Columnz field="full_name" header="Tên nhân viên" />
                        <Columnz field="phone" header="SĐT" />
                        <Columnz field="email" header="Email" />
                        <Columnz body={(e) => Body({ data: groups, value: e.group_id })} header="Bộ phận" />
                        <Columnz body={(e) => Body({ data: exchanges, value: e.department_id })} header="Phòng ban" />
                        <Columnz body={tonggleCard} header="Hồ sơ" />
                        <Columnz
                              header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                              className='text-center'
                              body={(e) => StatusBody({ e, route: '/list_personnel', updateAction: updateUserApi })}
                        />
                  </DataTablez>
            </div>
      )
}

export default ListPersonnel
