import { getData, postData } from '@/lib/request'

export const listExchangeApi = (params) => getData('web2/department/getListDepartment', params)
export const listExchangeV2Api = (params) => getData('web2/info/getListDepartmentInfo', params)
export const countExchangeApi = (params) => getData('web2/department/countDepartment', params)
export const deleteExchangeApi = (params) => postData('web2/department/deleteDepartment', params)
export const detailExchangeApi = (params) => getData('web2/department/getDetailDepartment', params)
export const addExchangeApi = (params) => postData('web2/department/addDepartment', params, true)
export const updateExchangeApi = (params) => postData('web2/department/updateDepartment', params, true)
export const positionApi = (params) => getData('web2/position/getListPosition', params)

