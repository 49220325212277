import { Body, Calendarz, Columnz, DataTablez, Dropdownz, GridFormV2, HeaderListForm, Inputz, StatusBody, TimeBody, useGetParams } from '@/components'
import { listToast, typeClassifyV2 } from '@/constants'
import { useGetApi } from '@/hooks'
import { setToast } from "@/redux/features"
import { Button } from '@/uiCore'
import { checkJson } from '@/utils'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listDepartmentWareHouse, printBillWareHouse } from '../api'
import { useCountHistoryBillReceipt, useListHistoryBillReceipt } from '../utils'
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({})
    return (
        <GridFormV2 setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập mã phiếu"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.type}
                options={typeClassifyV2}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại Phiếu"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder={"Chọn khoảng thời gian"}
                selectionMode="range"
            />
        </GridFormV2>
    )
}

function ReceiptMangage(props) {

    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const listDepartment = useGetApi(listDepartmentWareHouse)
    const data = useListHistoryBillReceipt(({ status: undefined, ...params, first: undefined }))
    const totalRecords = useCountHistoryBillReceipt({ status: undefined, ...params, first: undefined }) || 0
    const users = useSelector((state) => state.users)
    const dispatch = useDispatch()

    const handlePrint = async (id) => {
        const response = await printBillWareHouse({ receipt_id: id })
        if (response.data.status) {
            window.open(`/print/ware_house/${id}`, '_blank')
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
        }
    }
    const BodyPrint = (e) => {
        return (
            <div className="flex gap-2 align-items-center justify-content-center">
                <>
                    <Button type="button" icon="pi pi-print" onClick={event => handlePrint(e.id)} />
                </>
            </div>
        );
    }
    const FileBody = (rowData) => {
        const a = checkJson(rowData.file)
        const file = a?.file
        return (
            <>
                {file?.map((file, index) => (
                    <>
                        <Link to={`${file}`}>
                            <i className="pi pi-file" style={{ fontSize: '1.5rem' }}></i>
                        </Link>
                    </>
                ))
                }
            </>
        )
    };
    return (
        <div>
            <Header setParams={setParams} />

            <HeaderListForm title="Thông tin mua nhập hàng" />
            <DataTablez
                value={data}
                title="thông tin mua nhập hàng"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/warehouse_management"
            >
                <Columnz
                    header="Thời gian"
                    body={(e) => TimeBody(e.create_date, "date")}
                />
                <Columnz
                    field="categogy"
                    header="Loại phiếu"
                    body={(e) => Body({ data: typeClassifyV2, value: e.type })}
                />
                <Columnz
                    field="code"
                    header="Mã phiếu"
                />
                <Columnz
                    header="Phòng ban bàn giao"
                    body={(e) => Body({ data: listDepartment, value: e.department_hand_over })}
                />
                <Columnz
                    header="Phòng ban tiếp nhận"
                    body={(e) => Body({ data: listDepartment, value: e.department_receive })}
                />
                <Columnz
                    header='Người tạo'
                    body={(e) => Body({ data: users, value: Number(e.create_by), key: 'user_id', label: 'full_name' })}
                />
                <Columnz
                    header="Người bàn giao"
                    body={(e) => Body({ data: users, value: Number(e.user_hand_over), key: 'user_id', label: 'full_name' })}
                />
                <Columnz
                    header="Người duyệt"
                    body={(e) => Body({ data: users, value: Number(e.user_confirm), key: 'user_id', label: 'full_name' })}
                />
                <Columnz
                    header="Nhân viên tiếp nhận"
                    body={(e) => Body({ data: users, value: Number(e.user_receive), key: 'user_id', label: 'full_name' })}
                />
                <Columnz
                    header="Trạng thái xác nhận"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/warehouse_management' })}
                />
                <Columnz body={(e) => FileBody(e)} header="File đính kèm" />
                <Columnz body={e => BodyPrint(e)} header="In phiếu" />
            </DataTablez>
        </div>
    )
}

export default ReceiptMangage
