import { DropdownForm, FormUpdate, InputForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addApartmentDepositApi, updateApartmentDepositApi } from '../api'
import { useDetailApartmentDeposit } from '../utils'

const UpdateApartmentDeposit = () => {
    const { id } = useParams()
    const apartmentDepositDetail = useDetailApartmentDeposit(id)
    const [infos, setInfos] = useState({
        nameApartmentDepositPlace: '',
        statusApartmentDepositPlace: '',
        codeApartmentDepositPlace: '',
    })

    useEffect(() => {
        if (apartmentDepositDetail.id) setInfos({ ...infos, ...apartmentDepositDetail, password: '' })
    }, [apartmentDepositDetail])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (Number(id)) info = { ...removePropObject(info, apartmentDepositDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="tòa nhà"
            handleData={handleData}
            route="/apartment_deposit"
            actions={{ add: addApartmentDepositApi, update: updateApartmentDepositApi }}
            refreshObjects={[setInfos]}
        >
            <div className="card bg-color">
                <div style={{ maxWidth: '1200px' }}>
                    <InputForm
                        id="nameApartmentDepositPlace"
                        value={infos.nameApartmentDepositPlace}
                        onChange={(e) => setInfos({ ...infos, nameApartmentDepositPlace: e.target.value })}
                        label="Tên tòa nhà (*)"
                        required
                    />
                    <InputForm
                        id="statusApartmentDepositPlace"
                        value={infos.statusApartmentDepositPlace}
                        onChange={(e) => setInfos({ ...infos, statusApartmentDepositPlace: e.target.value })}
                        label="Địa chỉ (*)"
                        required
                    />
                    <DropdownForm
                        value={infos.codeApartmentDepositPlace}
                        onChange={(e) => setInfos({ ...infos, codeApartmentDepositPlace: e.target.value })}
                        label="Mã tòa nhà (*)"
                    />
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateApartmentDeposit
