import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { InputForm, FormUpdate, InputTextareaForm, InputSwitchForm } from '@/components'
import EditorV2 from '@/components/Editorz'
import { useDetailReceptForm, useDetailServices } from '../utils'
import { addReceptFormApi, addServicesApi, updateReceptFormApi, updateServicesApi } from '../api'

const UpdateService = () => {
    const { id } = useParams()
    const servicesDetail = useDetailServices(id)
    const [infos, setInfos] = useState({
        code: '',
        fee: '',
        description: '',
        status: true,
    })
    useEffect(() => {
        if (servicesDetail.id)
            setInfos({ ...infos, ...servicesDetail, status: servicesDetail.status === 0 ? false : true })
    }, [servicesDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, servicesDetail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="Dịch vụ"
            handleData={handleData}
            route="/service_type"
            actions={{ add: addServicesApi, update: updateServicesApi }}
            refreshObjects={[setInfos]}
        >
            <InputForm
                id="code"
                value={infos.code}
                onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                label="Mã dịch vụ"
            />
            <InputForm
                id="fee"
                value={infos.fee}
                onChange={(e) => setInfos({ ...infos, fee: e.target.value })}
                label="Phí dịch vụ"
            />
            <label className="mt-3 mb-3 font-medium w-5">Mô tả</label>
            <EditorV2
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Mô tả"
            />
            <div className="mb-5"></div>
            <InputSwitchForm
                id="status"
                checked={infos.status}
                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                label="Status"
            />
        </FormUpdate>
    )
}

export default UpdateService
