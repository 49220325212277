import { Columnz, DataTablezV2, HeaderListForm, NumberFormat } from '@/components'
import { typeBuidingServices } from '@/constants'
import { checkJson, formatNumber } from '@/utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useDetailBill } from '../utils'

const DetailBill = () => {
    const [infos, setInfos] = useState({})
    const [data, setData] = useState([])
    const { id } = useParams()
    const detailBill = useDetailBill(id)
    const aparments = useSelector(state => state.apartments)
    const services = useSelector(state => state.services)

    useEffect(() => {
        if (detailBill.id) {
            const apartment = aparments ? aparments.find(a => a.id === detailBill.bdc_apartment_id) || {} : {}
            setInfos({ ...detailBill, debits: undefined, apartment: apartment.name })
        }
    }, [detailBill, aparments])

    useEffect(() => {
        if (detailBill.id) {
            if (detailBill.debits && detailBill.debits[0]) {
                let total = 0
                const newData = []
                detailBill.debits.forEach(d => {

                    const service = services.find(s => s.id === d.service_id) || {}
                    const serviceType = typeBuidingServices.find(t => t.id === service.type)
                    d.note = moment(d.from_date).format('DD/MM/YYYY') + " - " + moment(d.to_date).format('DD/MM/YYYY')
                    const cost = parseFloat(d.cost) || 0
                    const gia_tien_vat = d.vat ? (cost * d.vat) / 100 : 0
                    const tien_sau_vat = cost + gia_tien_vat
                    const giam_tru = (d.discount_type === 0) ? d.discount : (tien_sau_vat * d.discount / 100)
                    const khuyen_mai = (d.promotion_type === 0) ? d.promotion_amount : (tien_sau_vat * d.promotion_amount / 100)
                    let sumary = tien_sau_vat - giam_tru - khuyen_mai || d.sumery
                    if (!(d.detail === "[]" || d.detail === JSON.stringify([]))) {
                        const detail = checkJson(d.detail) || d.detail
                        let total = 0
                        let don_gia = 0
                        if (detail.info_price && detail.info_price[0]) {
                            detail.info_price.forEach(di => {
                                if (di.total_price) {
                                    if (!(di.from || di.from === 0)) di.name = detail.info_progressives?.name
                                    di.from = Number(di.from) || 1
                                    di.to = Number(di.total_price) / Number(di.price) + Number(di.from) - 1
                                    di.vat = Number(d.vat) || 0
                                    di.tong = di.vat ? (di.vat + 100) * Number(di.total_price) / 100 : di.total_price
                                    total += Number(di.tong)
                                    don_gia += Number(di.price)
                                }
                            })
                        }
                        if (detail.surcharge && detail.surcharge.surcharge && Number(detail.surcharge.surcharge) !== 0) {
                            detail.surcharge.vat = Number(detail.surcharge.vat_for_vat_surcharge) || 0
                            detail.surcharge.price = don_gia * Number(detail.surcharge.vat_for_surcharge) / 100
                            detail.surcharge.total_price = Number(detail.surcharge.surcharge) || 0
                            detail.surcharge.tong = detail.surcharge.vat ? (detail.surcharge.vat + 100) * Number(detail.surcharge.surcharge) / 100 : detail.surcharge.surcharge
                            total += Number(detail.surcharge.tong)
                            sumary += Number(detail.surcharge.tong)
                            if (detail.info_price && detail.info_price[0]) detail.info_price.push(detail.surcharge)
                            else detail.info_price = [detail.surcharge]
                        }

                        const object = {
                            name: detail.bdc_price_type_id === 5 ? detail.name : serviceType.name,
                            service_type: serviceType.id,
                            total,
                            note: d.note,
                            info_electric: detail.info_electric,
                            info_price: detail.info_price,
                            giam_tru,
                            khuyen_mai,
                            sumary,
                            check: detail.bdc_price_type_id,
                            vat: d.vat
                        };
                        newData.push(object)
                    } else {
                        const index = newData.findIndex(n => n.service_type === serviceType.id)
                        if (index >= 0) {
                            newData[index].data.push({ ...d, service_name: d.number ? `${service.name} - ${d.number}` : service.name, giam_tru, khuyen_mai, sumary })
                            newData[index].total += sumary
                        } else {
                            newData.push({ service_type: serviceType.id, name: serviceType.name, total: sumary, data: [{ ...d, service_name: d.number ? `${service.name} - ${d.number}` : service.name, giam_tru, khuyen_mai, sumary }] })
                        }
                    }
                    total += sumary || d.sumery
                });
                setInfos(pre => ({ ...pre, total }))
                setData([...newData]);
            }
        }
    }, [detailBill, JSON.stringify(services)])
    return (
        <div className='card'>
            <HeaderListForm title={"Chi tiết bảng kê"} />
            <div className='card'>
                <div className='grid grid-form'>
                    <div className='col-12 lg:col-4 text-center font-semibold'>
                        <b className="p-0">Mã hóa đơn: {infos.bill_code}</b>
                    </div>
                    <div className='col-12 lg:col-4 text-center font-semibold'>
                        <b className="p-0">Kỳ: {infos.cycle_name}</b>
                    </div>
                    <div className='col-12 lg:col-4 text-center font-semibold'>
                        <b className="p-0">Hạn thanh toán: {moment(infos.deadline).format('DD/MM/YYYY')}</b>
                    </div>
                    <div className='col-12 lg:col-4 text-center font-semibold'>
                        <b className="p-0">Căn hộ: {infos.apartment}</b>
                    </div>
                    <div className='col-12 lg:col-4 text-center font-semibold'>
                        <b className="p-0">Chủ hộ: {infos.customer_name}</b>
                    </div>
                </div>
            </div>
            <div className={"flex justify-content-center mb-4 mt-0"}>
                <b style={{ color: "red", fontSize: '24px' }}>Tổng bảng kê: {formatNumber(infos.total) || 0} VNĐ</b>
            </div>

            <div className='card'>
                {data && data[0] && data.map((d, index) => {

                    let info_electric = d.info_electric
                    let info_price = d.info_price
                    if (Array.isArray(info_price) && info_price && info_price[0]) {
                        info_price = info_price.filter(p => p.tong > 0);

                    }

                    if (info_electric && Array.isArray(info_electric)) {
                        info_electric = info_electric.filter(ie => ie.type !== 0);
                    }
                    if (d.check === 5) {
                        info_price = [{ name: "Bình thường" }, { name: "Cao điểm" }, { name: "Thấp điểm" }]
                        const info_electric = d.info_electric?.[0]
                        info_price[0].tieu_thu = info_electric.number_3
                        info_price[1].tieu_thu = info_electric.number_2
                        info_price[2].tieu_thu = info_electric.number_1
                        info_price = info_price.map((item, index) => {
                            return { ...item, tieu_thu: info_electric[`number_${index + 1}`], don_gia: d.info_price[index] };
                        });
                        info_price = info_price.map((item) => {
                            return { ...item, thanh_tien: item.tieu_thu * item.don_gia, vat: d.vat };
                        });
                        info_price = info_price.map((item) => {
                            const thanh_tien = Math.round(item.tieu_thu * item.don_gia);
                            const vat = Math.round(thanh_tien * d.vat / 100);
                            const tong = thanh_tien + vat;
                            return { ...item, thanh_tien, vat, tong };
                        });
                    }
                    if (d.data && [3, 5, 6].includes(d.service_type) && d.data[0].detail === "[]") {
                        d.service_type = 2
                        d.checkv2 = 1
                    }
                    return <div className='card' key={index}>
                        {[3, 5, 6].includes(d.service_type) ?
                            <>
                                {d.check !== 5 ? <>
                                    <HeaderListForm title={d.name} SubTitle={() => <b className="text-xl p-0">Tổng: {formatNumber(d.total - d.giam_tru - d.khuyen_mai || 0) || 0} VNĐ</b>} />
                                    <div className='mb-3'><p className="p-0">{d.note}</p></div>
                                    {info_electric && info_electric[0] && info_electric.map((i, index) => {
                                        return (
                                            <div key={index}>
                                                <div className='grid grid-form mb-2'>
                                                    <div className='col-12 lg:col-3 '>
                                                        <p className="p-0">Đồng hồ: {index + 1}</p>
                                                    </div>
                                                    <div className='col-12 lg:col-3 '>
                                                        <p className="p-0">Chỉ số đầu: {i.before_number}</p>
                                                    </div>
                                                    <div className='col-12 lg:col-3 '>
                                                        <p className="p-0">Chỉ số cuối: {i.after_number}</p>
                                                    </div>
                                                    <div className='col-12 lg:col-3 '>
                                                        <p className="p-0">Tiêu thụ: {i.after_number - i.before_number}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        );
                                    })}

                                    <DataTablezV2 sttWithBill noStt data={info_price} title={d.name} paginator={false} >
                                        {<Columnz className="text-lg" body={e => ((e.from || e.from === 0) ? (e.name || `Từ ${e.from} - ${e.to}`) : "Phí môi trường")} header="Định mức" />}
                                        <Columnz className="text-lg" body={e => (e.from || e.from === 0) ? e.to - e.from + 1 : 1} header="Tiêu thụ" />
                                        <Columnz
                                            className='text-right text-lg'
                                            body={e => formatNumber(e.price, true)} header="Đơn giá" />
                                        <Columnz
                                            className='text-right text-lg'
                                            body={e => formatNumber(e.total_price, true)} header="Thành tiền" />
                                        <Columnz className="text-lg" body={e => e.vat ? e.vat + '%' : ""} header="VAT" />
                                        <Columnz
                                            className='text-right text-lg'
                                            body={e => NumberFormat({ value: e.tong })} header="Tổng" />
                                    </DataTablezV2>
                                    <div className='flex flex-column gap-2 mt-3 align-items-end'>
                                        <div><p>Giảm trừ: {formatNumber(d.giam_tru || 0) || 0} VNĐ</p> </div>
                                        <div><p>Ưu đãi: {formatNumber(d.khuyen_mai || 0) || 0} VNĐ</p></div>
                                    </div>
                                </> : <>
                                    <HeaderListForm title={d.name} SubTitle={() => <b className="text-xl p-0">Tổng: {formatNumber(d.sumary - d.giam_tru - d.khuyen_mai || 0) || 0} VNĐ</b>} />
                                    <div className='mb-3'><p className="p-0">{d.note}</p></div>
                                    <DataTablezV2 sttWithBill noStt data={info_price} paginator={false}  >
                                        <Columnz field="name" header="Định mức" className="text-lg" />
                                        <Columnz
                                            className='text-right text-lg'
                                            field="tieu_thu" header="Tiêu thụ" />
                                        <Columnz
                                            className='text-right text-lg'
                                            body={e => NumberFormat({ value: e.don_gia })} header="Đơn giá" />
                                        <Columnz
                                            className='text-right text-lg'
                                            body={e => NumberFormat({ value: e.thanh_tien })} header="Thành tiền" />
                                        <Columnz body={e => e.vat} className='text-right text-lg' header="VAT" />
                                        <Columnz
                                            className='text-right text-lg'
                                            body={e => NumberFormat({ value: e.tong })} header="Tổng" />
                                    </DataTablezV2>
                                    <div className='flex flex-column gap-2 mt-3 align-items-end'>
                                        <div><p>Giảm trừ: {formatNumber(d.giam_tru || 0) || 0} VNĐ</p> </div>
                                        <div><p>Ưu đãi: {formatNumber(d.khuyen_mai || 0) || 0} VNĐ</p></div>
                                    </div>
                                </>}
                            </> :
                            <>
                                <HeaderListForm title={d.name} SubTitle={() => <b className="text-xl p-0">Tổng: {formatNumber(d.total) || 0} VNĐ</b>} />
                                <DataTablezV2 sttWithBill noStt data={d.data} title={d.name} paginatorTemplate="" currentPageReportTemplate="" paginator={false}>
                                    <Columnz className="text-lg" field="service_name" header="Dịch vụ" />
                                    <Columnz className="text-lg" body={e => d.service_type === 4 ? 1 : e.quantity} header={d.checkv2 === 1 ? "Số lượng" : (d.service_type === 2 ? "Diện tích" : "Số lượng")} />

                                    < Columnz
                                        className='text-right text-lg'
                                        body={e => NumberFormat({ value: d.service_type === 4 ? ((e.sumary + e.giam_tru + e.khuyen_mai) * 100 / (e.vat ? (100 + e.vat) : 100)) : e.price })} header="Đơn giá" />
                                    <Columnz body={e => e.vat ? e.vat + '%' : ""} header="VAT" className="text-lg text-center" />
                                    <Columnz
                                        className='text-right text-lg'
                                        body={e => NumberFormat({ value: e.sumary + e.giam_tru + e.khuyen_mai })} header="Thành tiền" />
                                    <Columnz
                                        className='text-right text-lg'
                                        body={e => NumberFormat({ value: e.giam_tru })} header="Giảm trừ" />
                                    <Columnz
                                        className='text-right text-lg'
                                        body={e => NumberFormat({ value: e.khuyen_mai })} header="Ưu đãi" />
                                    <Columnz
                                        className='text-right text-lg'
                                        body={e => NumberFormat({ value: e.sumary })} header="Tổng" />
                                    <Columnz field="note" header="Ghi chú" className="text-lg text-center" />
                                </DataTablezV2>
                            </>}
                    </div>
                })}
            </div>
        </div>
    )
}

export default DetailBill