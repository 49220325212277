// socketConnection.js
import { listToast } from '@/constants';
import { setToast } from '@/redux/features';
import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
const SocketContext = createContext();

export const SocketProvider = ({ children, token, building_id, company_id, clientId, keySk, setParams, dispatch }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            query: { token, building_id, company_id, clientId }
        });

        setSocket(newSocket);
        return () => {
            newSocket.disconnect();
        };
    }, [token, building_id, company_id, clientId]);
    useEffect(() => {
        const handleConnect = () => {
            console.log('Connected to server!');
        };

        const handleDisconnect = (reason) => {
            console.log('Disconnected from server:', reason);
        };

        const handleNoti = (data) => {
            if (data.status) {
                setParams((prev) => ({ ...prev, render: !prev.render }));
                dispatch(setToast({ ...listToast[3], detail: 'Bạn có 1 thông báo mới' }));
            }
        };

        if (socket) {
            socket.on('connect', handleConnect);
            socket.on('disconnect', handleDisconnect);
            socket.on(keySk, handleNoti);

            return () => {
                socket.off('connect', handleConnect);
                socket.off('disconnect', handleDisconnect);
                socket.off(keySk, handleNoti);
            };
        }
    }, [socket, dispatch, keySk, setParams]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};
