import { Button, Column, DataTable } from '@/uiCore'
import { Fragment, memo, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { classNames } from 'primereact/utils'
import Calendar, { Image } from './ParkingImage'
import { ActionBody, Columnz, Dialogz, RenderHeader } from '@/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { removeUndefinedProps } from '@/utils'
import { databaseDate } from '@/lib'
import { refreshObject } from '@/utils'
export const DataTableParking = (props) => {
    return (
        <div className="flex flex-column pb-6">
            <div
                className="p-2 w-full justify-content-center"
                style={{
                    margin: '0 auto',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '2vh',
                    color: 'white',
                    backgroundColor: '#081f3e',
                    borderTopRightRadius: '1vh',
                    borderTopLeftRadius: '1vh',
                }}
            >
                {props.item.vehicle}
            </div>
            <DataTable value={props.item.data}>
                <Column field="main" headerStyle={{ display: 'none' }} bodyStyle={{ fontWeight: 'bold' }}></Column>
                <Column
                    field="field1"
                    headerStyle={{ display: 'none' }}
                    bodyStyle={{ color: 'orange', fontWeight: 'bold' }}
                ></Column>
                <Column field="n1" headerStyle={{ display: 'none' }} bodyStyle={{ fontWeight: 'bold' }}></Column>
                <Column
                    field="field2"
                    headerStyle={{ display: 'none' }}
                    bodyStyle={{ color: 'green', fontWeight: 'bold' }}
                ></Column>
                <Column field="n2" headerStyle={{ display: 'none' }} bodyStyle={{ fontWeight: 'bold' }}></Column>
            </DataTable>
        </div>
    )
}
export const Calendarz = (props) => {
    const { label, className, ...inputprop } = props
    return (
        <div className={classNames('col-12 mb-3 md:col-6 lg:col-3', className)}>
            <Calendar
                showIcon
                readOnlyInput
                showButtonBar
                dateFormat="dd/mm/yy"
                placeholder={label}
                className="w-full"
                style={{ minHeight: '50px' }}
                {...inputprop}
            />
        </div>
    )
}
export const TimeBody = (value) => {
    if (value) return <Fragment>{moment.unix(value).format('DD/MM/YYYY')}</Fragment>
}
export const UploadImg = (props) => {
    const { image, setImage = () => { }, title, view } = props

    useEffect(() => {
        return () => {
            image && URL.revokeObjectURL(image.preview)
        }
    }, [image])

    const handleAvatar = (e) => {
        const file = e.target.files[0]
        if (!file) return
        file.preview = URL.createObjectURL(file)
        setImage(file)
    }

    const clearImg = () => {
        setImage('')
    }

    return (
        <div className="w-full flex flex-column justify-content-center text-center">
            <h5 className="mb-2 ml-2 text-center">{title}</h5>
            {image ? (
                <Image
                    src={
                        typeof image === 'string'
                            ? image || '/assets/img/imgIcon.png'
                            : image.preview || '/assets/img/imgIcon.png'
                    }
                    alt="Image"
                    width="100"
                    height="65"
                    preview
                />
            ) : (
                ''
            )}
            {!image && <Image src="/assets/img/imgIcon.png" alt="Image" width="100" height="65" />}
        </div>
    )
}
export const FormUpdate = (props) => {
    const {
        checkId,
        title,
        handleData,
        actions,
        className,
        route,
        refreshObjects,
        setVisible,
        setResident,
        setParams,
        handleAfterCallApi,
        disabled,
        handleSubmit,
        ...prop
    } = props

    return (
        <div className={classNames({ card: !setVisible }, className)} {...prop}>
            <form>
                {title && (
                    <div
                        className="font-left flex align-items-center mb-4"
                        style={{ height: '50px', borderBottom: '2px solid #dee2e6' }}
                    ></div>
                )}
                {props.children}
            </form>
        </div>
    )
}
export const FormUpdateDialog = (props) => {
    const { title, visible, setVisible, onHide, ...prop } = props
    return (
        Boolean(visible) && (
            <Dialogz title={title} visible={Boolean(visible)} onHide={onHide} setVisible={setVisible} width="1200px">
                <FormUpdate
                    setVisible={setVisible}
                    checkId={typeof visible === 'number' || typeof visible === 'string'}
                    {...prop}
                >
                    <div className="card mt-4">{props.children}</div>
                </FormUpdate>
            </Dialogz>
        )
    )
}
export const DataTablez = memo((props) => {
    const {
        params,
        setParams,
        totalRecords,
        title,
        dataKey,
        selectedProducts,
        setSelectedProducts,
        route,
        setVisibledDialog,
        headerInfo,
        actionsInfo,
        hideParams,
        handleAfterCallApi,
        noStt,
        basePermissions = [],
        loading = true,
        ...prop
    } = props
    const { items, moreOptions, exportApi, setVisibleImport } = headerInfo || {}
    const { deleteAction, noDialog, handleDelete, moreActions } = actionsInfo || {}
    const [loadingz, setLoadingz] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    const onPage = (event) => {
        setParams({
            ...params,
            first: event.first,
            limit: event.rows,
            page: event.page !== 0 ? event.page + 1 : 1,
        })
    }

    const timeoutRef = useRef()
    useEffect(() => {
        loadLazyData()
    }, [params])

    useEffect(() => {
        setLoadingz(loading)
    }, [loading])

    const loadLazyData = () => {
        setLoadingz(true)
        timeoutRef.current = setTimeout(() => {
            setLoadingz(false)
        }, 1500)

        return () => {
            clearTimeout(timeoutRef.current)
        }
    }

    useEffect(() => {
        if (hideParams) return
        const query = {}
        for (let key in params)
        {
            if (params.hasOwnProperty(key))
            {
                const value = params[key]
                if (Array.isArray(value) && !value[0])
                {
                } else if (!['rows', 'render', 'first'].includes(key) && !['', undefined, null].includes(value))
                    query[key] = value
                if (['page', 'limit'].includes(key))
                {
                    query[key] = value
                }
            }
        }
        navigate(location.pathname + '?' + new URLSearchParams(query).toString())
    }, [params])

    return (
        <DataTable
            lazy
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            paginator
            rowHover
            header={
                headerInfo &&
                RenderHeader({
                    route,
                    setVisibledDialog,
                    selectedProducts,
                    items,
                    moreOptions,
                    params,
                    setParams,
                    handleAfterCallApi,
                    exportApi,
                    title,
                    setVisibleImport,
                    basePermissions,
                })
            }
            first={params.first}
            rows={params.limit}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
            onPage={onPage}
            dataKey={dataKey ? dataKey : 'id'}
            loading={loadingz}
            showGridlines
            stripedRows
            emptyMessage={'Không tìm thấy ' + title}
            currentPageReportTemplate="Tổng số: {totalRecords} bản ghi"
            selection={selectedProducts}
            selectionMode="checkbox"
            onSelectionChange={(e) => {
                if (setSelectedProducts)
                {
                    if (e.value && e.value.length > 0)
                    {
                        setSelectedProducts(e.value)
                    } else
                    {
                        setSelectedProducts([])
                    }
                }
            }}
            {...prop}
        >
            {selectedProducts && setSelectedProducts && (
                <Columnz
                    selectionMode="multiple"
                    headerStyle={{ width: '40px', minWidth: '40px' }}
                    bodyStyle={{ width: '40px', minWidth: '40px' }}
                />
            )}
            {!noStt && (
                <Columnz
                    header="STT"
                    body={(data, options) => options.rowIndex + 1}
                    headerStyle={{ width: '40px', minWidth: '40px' }}
                    bodyStyle={{ width: '40px', minWidth: '40px' }}
                />
            )}
            {props.children}
            {actionsInfo && (
                <Columnz
                    header="Thao tác"
                    body={(e) =>
                        ActionBody({
                            route,
                            params,
                            setParams,
                            id: dataKey ? e[dataKey] : e.id,
                            deleteAction,
                            setVisibledDialog: noDialog ? undefined : setVisibledDialog,
                            handleDelete,
                            moreActions,
                            handleAfterCallApi,
                            basePermissions,
                        })
                    }
                />
            )}
        </DataTable>
    )
})
const today = new Date()
export const defaultvalue = {
    from: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    to: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
    dates: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)]
}

export const GridForm = (props) => {
    const { className, setParams = () => { }, filter, setFilter, handleFilter, onClear, array = [] } = props
    const location = useLocation()
    const handleClear = () => {
        setParams((pre) => {
            return {
                page: pre.page || 1,
                limit: pre.limit || 20,
                first: pre.first || 0,
                render: pre.render,
                from: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
                to: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59), true),
            }
        })
        setFilter({ ...defaultvalue, plate_number: '' })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let filters = { ...filter }
        if (filters.dates && filters.dates[0])
        {
            filters.from = databaseDate(filter.dates[0])
            filters.to = filter.dates[1] ? databaseDate(filter.dates[1], true) : databaseDate(filter.dates[0], true)
            filters.dates = undefined
        }
        if (handleFilter) filters = handleFilter(filter)
        setParams((pre) => {
            return {
                page: pre.page || 1,
                limit: pre.limit || 20,
                first: pre.first || 0,
                render: pre.render,
                ...removeUndefinedProps(filters),
            }
        })
    }

    useEffect(() => {
        const query = {}
        const queryParams = new URLSearchParams(location.search)
        const dates = []
        const datez = []
        for (let [key, value] of queryParams.entries())
        {
            if (key === 'from' || key === 'to')
            {
                if (key === 'from')
                {
                    query['from'] = new Date(value)
                    dates[0] = new Date(value)
                }
                if (key === 'to')
                {
                    query['to'] = new Date(value)
                    dates[1] = new Date(value)
                }
            } else if (key === 'from_create' || key === 'to_create')
            {
                if (key === 'from_create')
                {
                    datez[0] = new Date(value)
                }
                if (key === 'to_create')
                {
                    datez[1] = new Date(value)
                }
            } else
            {
                if (array.includes(key))
                {
                    const data = value.split(',') || []
                    query[key] = data.map((d) => Number(d) || d)
                } else query[key] = value === '0' || Number(value) ? Number(value) : value
            }
        }
        if (dates && dates[0]) query['dates'] = dates
        if (datez && datez[0]) query['datez'] = datez
        setFilter({ ...filter, ...query })
    }, [])

    return (
        <form onSubmit={handleSubmit} className="grid formgrid">
            {props.children}
            <div className={classNames('mb-4 col-12 md:col-12 lg:col-3 flex justify-content-end gap-3', className)}>
                <Button
                    type="button"
                    raised
                    size="small"
                    severity="secondary"
                    label="Làm mới bộ lọc"
                    onClick={() => onClear ? onClear() : handleClear()}
                />
                <Button type="submit" raised size="small" label="Lọc" severity="info" icon="pi pi-filter" />
            </div>
        </form>
    )
}
