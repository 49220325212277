import { Body, Columnz, DataTablez, GridForm, HeaderListForm, Inputz, useGetParamsV2 } from '@/components'
import { useState } from 'react'
import { useCountBoardContactInfo, useCountContactInfo, useListBoardContactInfo, useListContactInfo } from '../utils'
import { member_admin } from "@/constants";
import UpdateManagetContact from './UpdateManagerContact';
import { deleteManagerContactApi } from '../api';

export default function ContactInfo() {
    const initParam = useGetParamsV2()
    const [params, setParams] = useState(initParam)
    const [params2, setParams2] = useState(initParam)
    const data = useListContactInfo({ status: undefined, ...params, first: undefined })
    const data2 = useListBoardContactInfo({ status: undefined, ...params2, first: undefined })
    const totalRecords = useCountContactInfo({ status: undefined, ...params, first: undefined }) || 0
    const totalRecords2 = useCountBoardContactInfo({ status: undefined, ...params2, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const type_user_company = [
        { id: 'nhan_vien', name: "Nhân viên" }
    ]

    return (
        <>
            <HeaderListForm title="Danh sách ban quản lý" />
            {visible && <UpdateManagetContact setParams={setParams} visible={visible} setVisible={setVisible} />}
            <DataTablez
                value={data}
                title="ban quản lý"
                totalRecords={totalRecords}
                params={params}
                route='/building_overview'
                setVisibledDialog={setVisible}
                setParams={setParams}
                rowsPerPageOptions={[5, 10, 50]}
                actionsInfo={{ deleteAction: deleteManagerContactApi }}
                headerInfo
                basePermissions={["insert", "delete"]}
            >
                <Columnz field="full_name" header="Tên nhân viên" />
                <Columnz field="code" header="Mã nhân viên" />
                <Columnz field="name" header="Phòng ban " />
                <Columnz body={(e) => Body({ data: type_user_company, value: e.position_code })} header="Chức danh" />
                <Columnz field="email_contact" header="Email" />
                <Columnz field="phone_contact" header="Số điện thoại" />
            </DataTablez>
            <hr />
            <HeaderListForm title="Danh sách ban quản trị" />
            <DataTablez
                value={data2}
                title="nhân viên"
                totalRecords={totalRecords2}
                params={params2}
                setParams={setParams2}
                rowsPerPageOptions={[5, 10, 50]}
            >
                <Columnz field="full_name" header="Tên nhân viên" />
                <Columnz field="phone_contact" header="Số điện thoại" />
                <Columnz field="email_contact" header="Email" />
                <Columnz field="group_concat(c.name)" header="Căn hộ" />
                <Columnz body={e => Body({ data: member_admin, value: e.type_board })} header="Chức danh   " />
            </DataTablez>
        </>
    )
}
