import {
    Body,
    Columnz,
    DataTablezV2,
    DropdownForm,
    FormUpdateDialog,
    InputForm,
    InputSwitchForm,
    InputTextareaForm,
} from '@/components'
import { processingType, statusLogImport } from '@/constants'
import { removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useDetailStaff } from '../utils'

const UpdateStaff = (props) => {
    const { setVisible, setParams, visible } = props
    const listStaff = useDetailStaff(visible)
    const [infos, setInfos] = useState({})

    useEffect(() => {
        if (listStaff.id) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(listStaff),
            })
        }
    }, [listStaff])

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'nhân sự'}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            actions={{}}
        >
            <div className="card bg-color">
                <InputForm
                    id="full_name"
                    value={infos.full_name}
                    onChange={(e) => setInfos({ ...infos, full_name: e.target.value })}
                    label="Tên nhân viên"
                    required
                    disabled
                />
                <InputForm
                    id="user_id"
                    value={infos.user_id}
                    onChange={(e) => setInfos({ ...infos, user_id: e.target.value })}
                    label="Mã nhân viên"
                    required
                    disabled
                />
                <InputForm
                    id="phone_number"
                    value={infos.phone_number}
                    onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                    label="Email"
                    required
                    disabled
                />
                <InputForm
                    id="phone_contact"
                    value={infos.phone_contact}
                    onChange={(e) => setInfos({ ...infos, phone_contact: e.target.value })}
                    label="Số điện thoại"
                    required
                    disabled
                />
                <InputForm
                    id="cmt_number"
                    value={infos.cmt_number}
                    onChange={(e) => setInfos({ ...infos, cmt_number: e.target.value })}
                    label="Số CMND/CCCD"
                    required
                    disabled
                />
                <InputTextareaForm
                    id="description"
                    value={infos.description}
                    onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                    label="Mô tả"
                    disabled
                />
                <InputSwitchForm
                    id="main_user_id"
                    checked={infos.main_user_id === infos.user_id}
                    onChange={(e) => setInfos({ ...infos, main_user_id: e.target.value })}
                    label="Trưởng bộ phận"
                    disabled
                />
                {/* <DropdownForm
                    id="type_service"
                    value={infos.building_id}
                    onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                    // options={projects}
                    label="Tòa nhà quản lý"
                    disabled
                /> */}
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateStaff
