import { listExchangeV2Api, listUserApi, positionApi } from '@/api'
import { DropdownForm, DropdownFormV2, FormUpdateDialog, InputForm, InputSwitchForm, InputTextareaForm, MultiSelectForm, useGetParams } from '@/components'
import { useGetApi } from '@/hooks'
import { Button, Checkbox } from '@/uiCore'
import { removePropObject, removeUndefinedProps } from '@/utils'
import { confirmDialog } from 'primereact/confirmdialog'
import { Tag } from 'primereact/tag'
import { useEffect, useState } from 'react'
import { addConfirmConfigApi, updateConfirmConfigApi } from '../api'
import { useDetailConfirmConfig } from '../utils'

const AddConfirmConfig = (props) => {
    const initParam = useGetParams()
    const [param, setParam] = useState(initParam)
    const { setVisible, setParams, visible } = props
    const detail = useDetailConfirmConfig(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState({ status: true })

    const person = useGetApi(listUserApi, { ...param, first: undefined, type: 'hrm' }, [])
    const exchanges = useGetApi(listExchangeV2Api)
    const userWithExChanges = person.filter(d => d.department_id === infos.department_id)
    let filteredExchanges = [];
    if (infos && infos.department_ids && Array.isArray(exchanges)) {
        filteredExchanges = exchanges.filter(exchange => infos.department_ids.includes(exchange.id));
    }
    const filterUsers = (departmentId, position) => {
        return person.filter(user => user.department_id === departmentId && user.work_position === position)
    };
    const listPosition = useGetApi(positionApi) || []
    const position = (departmentId) => {
        return listPosition.filter(position => position.department_id = departmentId) || []
    }
    const type = [
        { id: 2, name: "Duyệt trực tiếp" },
        { id: 1, name: "Duyệt trình tự" },
    ]
    const action = [
        { id: 2, name: "Duyệt" },
        { id: 1, name: "Xem" },
    ]
    useEffect(() => {
        if (detail.id) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(detail),
                status: detail.status === 0 ? false : true
            })

        }
    }, [detail])


    const [stepConfig, setStepConfig] = useState([])
    const handleInputLCChange = (id, field, value) => {
        const newData = stepConfig.map((row) => (row.id === id ? { ...row, [field]: value } : row))
        setStepConfig(newData)
    }
    const handleDeleteLCRow = (id, index) => {
        confirmDialog({
            message: `Xác nhận xóa người duyệt bước ${index + 1}`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => {
                setStepConfig(stepConfig.filter((row) => row.id !== id))
            },
        })
    }
    const stepConfigDetail = detail.step_config || []
    const handleAddLCRow = (stepNo) => {
        confirmDialog({
            message: `Xác nhận thêm mới người duyệt bước ${stepNo + 1}`,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => {
                const newRow = {
                    id: stepConfig.length + 1,
                    user_confirm: '',
                    step_no: stepConfig.length + 1,
                    department_id: '',
                    position: ''
                }
                setStepConfig([...stepConfig, newRow])
            },
        })
    }
    function formatCode(name) {
        const initials = getInitials(name);
        const currentDate = new Date();
        const formattedDateTime = `_${currentDate.getDate().toString().padStart(2, '0')}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getFullYear()}`;
        return `${initials}${formattedDateTime}`;
    }

    function getInitials(str) {
        const words = str.split(' ');
        let initials = '';
        if (!words || words.length === 0) {
            initials = 'DT'
        } else if (words.length === 1) {
            initials = words[0].charAt(0).toUpperCase() + 'D'
        } else {

            for (let i = 0; i < words.length; i++) {
                initials += words[i].charAt(0).toUpperCase();
                if (initials.length >= 2) {
                    break;
                }
            }
        }
        return initials;
    }
    const formatCreatedAt = (created_at) => {

        if (!created_at) return '';
        const dateObj = new Date(created_at);
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${hours}:${minutes} ${day}/${month}/${year}`;
    }
    useEffect(() => {
        if (!detail.id) {
            if (infos.name) {
                const formattedCode = formatCode(infos.name);
                setInfos({
                    ...infos,
                    code: formattedCode
                })
            }
        } else {
            setInfos({
                ...infos,
                ...detail,
                department_ids: detail.department_ids ? JSON.parse(detail.department_ids) : [],
                status: detail.status === 0 ? false : true,
            })
            const initialStepConfig = detail.step_config || []
            setStepConfig(initialStepConfig)
        }
    }, [detail]);
    const handleData = () => {
        const config = []
        let info = {
            ...infos,
            status: infos.status ? 1 : 0,
        }

        if (typeof visible !== 'number') {

            if (!infos.name) return "Vui lòng nhập tên sản phẩm"
            if (infos.note?.length >= 100) return "Mô tả không nhập quá 100 ký tự"
        }
        if (detail && detail.id) {
            info = { ...removePropObject(info, detail), id: detail.id }
            const newStepConfig = stepConfig.map((lc) => ({
                id: stepConfigDetail.find((row) => row.id === lc.id) ? lc.id : undefined,
                user_confirm: lc.user_confirm,
                step_no: lc.step_no,
                department_id: lc.department_id,
                position: lc.position
            }))
            if (infos.type === 1) {

                if (detail.step_config) {
                    const deleteLCElements = detail.step_config.filter(
                        (d) => !newStepConfig.some((n) => n.id === d.id),
                    )
                    if (deleteLCElements && deleteLCElements[0]) {
                        deleteLCElements.forEach((f) => {
                            config.push({ id: f.id, deleted_at: true })
                        })
                    }
                    newStepConfig.forEach((n, index) => {
                        const elements = detail.step_config.find((p) => (p.id = n.id))
                        if (
                            elements &&
                            JSON.stringify({
                                user_confirm: n.user_confirm,
                                step_no: n.step_no,
                                department_id: n.department_id,
                                position: n.position
                            }) !==
                            JSON.stringify({
                                user_confirm: elements.user_confirm,
                                step_no: elements.step_no,
                                department_id: elements.department_id,
                                position: elements.position
                            })
                        ) {
                            config.push({
                                id: elements.id,
                                user_confirm: n.user_confirm,
                                step_no: n.step_no,
                                department_id: n.department_id,
                                position: n.position
                            })
                        }
                        else if (!elements) {
                            config.push({
                                user_confirm: n.user_confirm,
                                step_no: n.step_no,
                                department_id: n.department_id,
                                position: n.position
                            })
                        }
                    })
                    info.step_config = config
                } else
                    info.step_config = newStepConfig
            } else {
                info.step_config = [{ id: undefined, user_confirm: 'truong phong', step_no: 1, deleted_at: null, department_id: null, position: null }]
                info.department_id = 0
            }
        }
        return info
    }
    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + ' cấu hình duyệt'}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            handleData={handleData}
            actions={{ add: addConfirmConfigApi, update: updateConfirmConfigApi }}

        >
            <div className="card bg-color">
                {typeof visible === 'number' ? (
                    <>
                        <div className="card bg-white">
                            <div className='text-2xl font-bold mb-3'>
                                Quyền phê duyệt đơn {infos.name}
                            </div>
                            <div className='grid grid-form'>
                                <div className="col-12 lg:col-3">
                                    <div className="w-full flex justify-content-between mb-2">
                                        <label className="font-medium w-full">
                                            Tên nhóm quyền
                                        </label>
                                    </div>
                                    <div className='text-500'>
                                        {infos.name}
                                    </div>
                                </div>
                                <div className="col-12 lg:col-3">
                                    <div className="w-full flex justify-content-between mb-2">
                                        <label className="font-medium w-full">
                                            Mô tả
                                        </label>
                                    </div>
                                    <div className='text-500'>
                                        {infos.note}
                                    </div>
                                </div>
                                <div className="col-12 lg:col-3">
                                    <div className="w-full flex justify-content-between mb-2">
                                        <label className="font-medium w-full">
                                            Ngày tạo
                                        </label>
                                    </div>
                                    <div className='text-500'>
                                        {formatCreatedAt(infos.created_at)}
                                    </div>
                                </div>
                                <div className="col-12 lg:col-3">
                                    <div className="w-full flex justify-content-between mb-2">
                                        <label className="font-medium w-full">
                                            Hiển thị người duyệt
                                        </label>
                                    </div>
                                    <Checkbox
                                        value={infos.display_status}
                                        onChange={(e) => setInfos({ ...infos, display_status: e.checked ? 1 : 0 })}
                                        checked={infos.display_status ? true : false}
                                    />
                                    <label htmlFor="ingredient1" className="ml-2">App</label>
                                </div>
                            </div>
                        </div>
                        <div className="card bg-white">
                            <div className='text-2xl font-bold mb-3'>
                                Phân quyền duyệt
                            </div>
                            <DropdownForm
                                value={infos.type}
                                onChange={(e) => setInfos({ ...infos, type: e.target.value })}
                                options={type}
                                label="Phân loại duyệt"

                            />
                            {infos.type === 1 ? (
                                <>
                                    <MultiSelectForm
                                        id="apartment_ids"
                                        value={infos.department_ids}
                                        options={exchanges}
                                        onChange={(e) => setInfos({ ...infos, department_ids: e.target.value })}
                                        label="Phòng ban áp dụng"
                                    />
                                    {Array.isArray(stepConfig) && stepConfig.map((row, index) => (
                                        <div key={row.id} >
                                            <div className='m-3 '>
                                                <Tag
                                                    value={`Bước ${index + 1}`}
                                                    rounded
                                                    className='text-xl w-2 bg-primary-reverse border border-1 surface-border text-color-secondary flex align-items-center justify-content-center'
                                                >
                                                </Tag>
                                            </div>
                                            <div className="grid grid-form">
                                                <div className="col-11 lg:col-11">
                                                    <div className="grid grid-form">
                                                        <div className="col-6 lg:col-6">
                                                            <DropdownFormV2
                                                                options={filteredExchanges}
                                                                label='Phòng ban'
                                                                optionLabel="name"
                                                                value={Number(row.department_id)}
                                                                onChange={(e) =>
                                                                    handleInputLCChange(row.id, 'department_id', e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-6 lg:col-6">
                                                            <DropdownFormV2
                                                                options={action}
                                                                label='Hành động'
                                                                optionLabel="name"
                                                                value={2}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-form">
                                                        <div className="col-6 lg:col-6">
                                                            <DropdownFormV2
                                                                options={position(row.department_id)}
                                                                label='Chức vụ'
                                                                optionLabel="name"
                                                                value={Number(row.position)}
                                                                onChange={(e) =>
                                                                    handleInputLCChange(row.id, 'position', e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-6 lg:col-6">
                                                            <DropdownFormV2
                                                                options={filterUsers(Number(row.department_id), Number(row.position))}
                                                                label='Đối tượng'
                                                                optionLabel="full_name"
                                                                value={Number(row.user_confirm)}
                                                                onChange={(e) =>
                                                                    handleInputLCChange(row.id, 'user_confirm', e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-1 lg:col-1 px-auto flex'>
                                                    <Button
                                                        className='m-auto flex align-items-center justify-content-center'
                                                        type="button"
                                                        rounded
                                                        outlined
                                                        icon="pi pi-trash"
                                                        onClick={() => handleDeleteLCRow(row.id, index)}
                                                        severity="danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="my-3">
                                        <Button style={{ marginLeft: '9px', marginTop: '15px' }} type="button" onClick={(e) => handleAddLCRow(stepConfig.length)}>
                                            Thêm mới
                                        </Button>
                                    </div>
                                </>
                            ) : null
                            }
                            <InputForm
                                label="Ghi chú"
                                value={infos.note}
                                onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                            />
                        </div>
                    </>
                ) : (
                    <div className="grid grid-form">
                        <div className="col-12 lg:col-7">
                            <InputForm
                                label="Tên cấu hình duyệt (*)"
                                value={infos.name}
                                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                required
                            />
                            <InputTextareaForm
                                label="Mô tả"
                                value={infos.note}
                                onChange={(e) => setInfos({ ...infos, note: e.target.value })}
                            />
                        </div>
                        <div className="col-12 lg:col-5">
                            <InputForm
                                label="Mã cấu hình duyệt"
                                value={infos.code}
                                onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                required
                                disabled
                            />
                            <InputSwitchForm
                                label="Trạng thái"
                                id="status"
                                value={infos.status}
                                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                checked={infos.status}
                            />
                            <InputSwitchForm
                                label="Trạng thái hiển thị người duyệt"
                                id="status"
                                value={infos.display_status}
                                onChange={(e) => setInfos({ ...infos, display_status: e.target.value })}
                                checked={infos.display_status}
                            />
                        </div>
                    </div>
                )
                }
            </div >
        </FormUpdateDialog >
    )
}

export default AddConfirmConfig
