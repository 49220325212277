import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, LoadDialog, useGetParams } from '@/components'
import { listToast, processingType, statusLogImport } from '@/constants'
import CaculatorDebtMonth from '@/modules/accountant/calculate_debt/screens/CaculatorDebtMonth'
import FirstTermFee from '@/modules/accountant/calculate_debt/screens/ImportFirstTermFee'
import { useSocket } from '@/modules/socket/SocketConnection'
import { setToast } from '@/redux/features'
import { setDebtInfo } from '@/redux/features/debtInfo'
import { Button, ProgressSpinner } from '@/uiCore'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useApartmentFilterApi, useCountLogDebt, useListLogDebt } from '../utils'
import CalElectricWaterDebt from './CalElectricWaterDebt'
import UpdateDebtImportHistory from './UpdateDebtImportHistory'
import { HistoryDetail, HeaderDetail } from './HistoryDetail'
import HistoryDetailSpecific from './HistoryDetailSpecific'

const CaculatorDebt = () => {
    const [buttonStates, setButtonStates] = useState({
        caculatorDebtMonth: false,
        importFirstTermFee: false,
        calElectricWaterDebt: false,
        debtArises: false,
    })

    const b = localStorage.getItem('token')
    const clientId = localStorage.getItem('clientId')
    const item = useSelector((state) => state.item)
    const building_id = item.building_id
    const company_id = item.company_id
    let token = ''
    if (!b) {
        token = localStorage.getItem('token')
    } else token = b
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListLogDebt({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountLogDebt({ status: undefined, ...params, first: undefined }) || 0
    const historyDetailData = useApartmentFilterApi({ status: undefined, ...params, first: undefined })
    const debtInfo = useSelector((state) => state.debtInfo)
    const toggleButton = (buttonName) => {
        if (debtInfo !== null && debtInfo.status === 0) {
            dispatch(setToast({ ...listToast[3], detail: 'Công nợ đang được xử lý. Vui lòng thử lại sau!' }))
        } else {
            setButtonStates({ ...buttonStates, [buttonName]: !buttonStates[buttonName] })
        }
    }
    const socket = useSocket()
    useEffect(() => {
        if (socket) {
            const handleServerData = (data) => {
                console.log('Received data from server:', data)
            }
            socket.on('abc', handleServerData)
            const key = 'change_status_cal_' + building_id
            const handleChangeStatusCal = (data) => {
                const debtInfo = data.data
                dispatch(setDebtInfo(debtInfo))
            }
            socket.on(key, handleChangeStatusCal)
            return () => {
                socket.off('abc', handleServerData)
                socket.off(key, handleChangeStatusCal)
            }
        }
    }, [socket])

    const prevStatusRef = useRef(null)

    useEffect(() => {
        if (debtInfo !== null && debtInfo.status !== prevStatusRef.current) {
            setParams((pre) => ({
                ...pre,
                render: !pre.render,
            }))
            prevStatusRef.current = debtInfo.status
        }
    }, [debtInfo, data])

    let { id: apartment_id } = useParams()
    useEffect(() => {
        if (apartment_id) {
            setButtonStates((prevState) => ({
                ...prevState,
                caculatorDebtMonth: true,
            }))
        }
    }, [])

    // ---------------------------- Toggle HistoryDetail -----------------------------
    const [historyDetailVisible, setHistoryDetailVisible] = useState(false) // State to manage visibility of HistoryDetail
    const [specificDetail, setSpecificDetail] = useState(false)
    const [filter, setFilter] = useState({
        key_search: '',
    })

    const handleBackButton = () => {
        setHistoryDetailVisible(false)
        setSpecificDetail(false)
        setFilter({
            key_search: '',
        })

        setParams(() => ({ page: 1, limit: 10, first: 0 }))
    }

    const toggleHistoryDetail = () => {
        setParams(() => ({ page: 1, limit: 10, first: 0 }))
        setHistoryDetailVisible(true)
        setSpecificDetail(false)
        setSelectedRowData(null)
    }

    // ----------------------- Get History Detail for each row ------------------------
    const [selectedRowData, setSelectedRowData] = useState(null)

    const handleMoreActions = (rowData) => {
        // Perform actions based on the _id of the clicked row
        setSelectedRowData(rowData)
        setSpecificDetail(true)
        setHistoryDetailVisible(false)
        setParams(() => ({ page: 1, limit: 10, first: 0 }))
    }

    const moreActions = [{ icon: 'pi pi-eye', command: handleMoreActions }]

    // ----------------------------------------------------------------------------------

    return (
        <div className={'p-card p-3'}>
            <HeaderListForm title={'Công nợ tổng hợp'} />
            {buttonStates.caculatorDebtMonth && (
                <>
                    <LoadDialog visible={buttonStates.caculatorDebtMonth} />
                    <CaculatorDebtMonth
                        visible={buttonStates.caculatorDebtMonth}
                        setVisible={() => toggleButton('caculatorDebtMonth')}
                        apartment_id_props={Number(apartment_id)}
                    />
                </>
            )}
            {buttonStates.importFirstTermFee && (
                <>
                    <LoadDialog visible={buttonStates.importFirstTermFee} />

                    <FirstTermFee
                        visible={buttonStates.importFirstTermFee}
                        setVisible={() => toggleButton('importFirstTermFee')}
                    />
                </>
            )}
            {buttonStates.calElectricWaterDebt && (
                <CalElectricWaterDebt
                    visible={buttonStates.calElectricWaterDebt}
                    setVisible={() => toggleButton('calElectricWaterDebt')}
                />
            )}
            <div className={''}>
                <div className="flex flex-wrap justify-content-between gap-2 align-items-center">
                    <div className="flex justify-content-between w-full">
                        <div className="flex gap-2">
                            <Button
                                onClick={() => toggleButton('caculatorDebtMonth')}
                                label={'Tính công nợ tháng'}
                                size="small"
                                severity={'info'}
                                raised
                            />
                            <Button
                                onClick={() => toggleButton('importFirstTermFee')}
                                label={'Import phí đầu kỳ'}
                                size="small"
                                severity={'info'}
                                raised
                            />
                            <Button
                                onClick={() => toggleButton('calElectricWaterDebt')}
                                label={'Tính công nợ điện nước'}
                                size="small"
                                severity={'info'}
                                raised
                            />
                            <Button
                                onClick={toggleHistoryDetail}
                                label={'Lịch sử chi tiết'}
                                size="small"
                                severity={'info'}
                                raised
                            />
                        </div>
                        {(historyDetailVisible || specificDetail) && (
                            <Button
                                onClick={() => {
                                    handleBackButton()
                                }}
                                label={'Trở về'}
                                size="small"
                                raised
                            />
                        )}
                    </div>
                </div>
                <br />
                {/* {(historyDetailVisible || specificDetail) && (
                    <HeaderDetail
                        setParams={setParams}
                        params={params}
                        data={historyDetailData}
                        rowData={selectedRowData}
                        filter={filter}
                        setFilter={setFilter}
                    />
                )} */}
                {visible && (
                    <UpdateDebtImportHistory
                        setParams={setParams}
                        visible={visible}
                        setVisible={setVisible}
                        data={data}
                    />
                )}
                {historyDetailVisible ? (
                    <HistoryDetail
                        data={historyDetailData}
                        setParams={setParams}
                        params={params}
                        rowData={selectedRowData}
                        filter={filter}
                    />
                ) : specificDetail ? (
                    <HistoryDetailSpecific
                        data={historyDetailData}
                        rowData={selectedRowData}
                        setParams={setParams}
                        params={params}
                    />
                ) : (
                    <div>
                        {/* default data table */}
                        <DataTablez
                            value={data}
                            title="lịch sử import"
                            totalRecords={totalRecords}
                            params={params}
                            setParams={setParams}
                            route="/service_apartment"
                            dataKey="_id"
                            setVisibledDialog={setVisible}
                            actionsInfo={{
                                moreActions,
                            }}
                        >
                            <Columnz field="name" header="Tên" />
                            <Columnz body={(e) => Body({ data: processingType, value: e.type })} header="Kiểu xử lý" />
                            <Columnz
                                header="Trạng Thái"
                                headerStyle={{ padding: 'auto', textAlign: 'center' }}
                                className="text-center"
                                body={(e) =>
                                    e.status === 1 ? (
                                        <span
                                            className="text-center font-bold text-white text-xs"
                                            style={{
                                                backgroundColor: '#54a0ff',
                                                borderRadius: '4px',
                                                padding: '4px',
                                                minWidth: '100px',
                                                display: 'inline-block',
                                            }}
                                        >
                                            Đã xử lý
                                        </span>
                                    ) : e.status === 2 ? (
                                        <span
                                            className="text-center font-bold text-white text-xs"
                                            style={{
                                                backgroundColor: statusLogImport[0].color,
                                                borderRadius: '4px',
                                                padding: '4px',
                                                minWidth: '100px',
                                                display: 'inline-block',
                                            }}
                                        >
                                            Thất bại
                                        </span>
                                    ) : e.status === 0 ? (
                                        <ProgressSpinner
                                            style={{ width: '15px', height: '15px' }}
                                            strokeWidth="7"
                                            animationDuration="1s"
                                        />
                                    ) : null
                                }
                            />
                            <Columnz
                                header="Thành công"
                                headerStyle={{ padding: 'auto', textAlign: 'center' }}
                                className="text-center"
                                body={(e) =>
                                    e.success || e.success === 0 ? (
                                        <span
                                            className="text-center font-bold text-white text-xs"
                                            style={{
                                                backgroundColor: statusLogImport[1].color,
                                                borderRadius: '4px',
                                                padding: '4px',
                                                minWidth: '100px',
                                                display: 'inline-block',
                                            }}
                                        >
                                            {e.success}
                                        </span>
                                    ) : null
                                }
                            />
                            <Columnz
                                header="Thất bại"
                                headerStyle={{ padding: 'auto', textAlign: 'center' }}
                                className="text-center"
                                body={(e) =>
                                    e.error || e.error === 0 ? (
                                        <span
                                            className="text-center font-bold text-white text-xs"
                                            style={{
                                                backgroundColor: statusLogImport[0].color,
                                                borderRadius: '4px',
                                                padding: '4px',
                                                minWidth: '100px',
                                                display: 'inline-block',
                                            }}
                                        >
                                            {e.error}
                                        </span>
                                    ) : null
                                }
                            />
                        </DataTablez>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CaculatorDebt
