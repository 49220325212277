import { getData, postData } from '@/lib/request'

export const listResidentApi = (params) => getData('web2/resident_management/getListResident', params)
export const addChildResidentApi = (params) => postData('web2/resident_management/addResidentNoUser', params)
export const listResidentV2Api = (params) => getData('web2/info/getListResident', params)
export const countResidentApi = (params) => getData('web2/resident_management/countResident', params)
export const checkResidentApi = (params) => getData('web2/resident_management/residentCheck', params)
export const detailResidentApi = (params) => getData('web2/resident_management/detailResident', params)
export const deleteResidentApi = (params) => postData('web2/resident_management/deleteResidents', params)
export const updateResidentApi = (params) => postData('web2/resident_management/editDetailResident', params, true)
export const addResidentApi = (params) => postData('web2/resident_management/addResident', params, true)
export const editRoleApi = (params) => postData('web2/resident_management/editRoleROfA', params)
export const importResidentApi = (params) => postData('web2/resident_management/importResident', params, false, true)

export const deleteRoleApi = (params) => postData('web2/resident_management/deleteRoleROfA', params)
export const addRoleApi = (params) => postData('web2/resident_management/insertRoleRofA', params)
export const exportResidentApi = (params) => getData('web2/resident_management/exportResident', params, true)
export const resetPassResidentApi = (params) => postData('web2/resident_management/restartPassword', params)
export const sendEmailApi = (params) => postData('web2/resident_management/sendMailCreateUser', params)
export const sendSmsApi = (params) => postData('web2/resident_management/sendSmsCreateUser', params)
export const listResidentByAIDApi = (params) => getData('web2/resident_management/getListUserApartment', params)
