import { useEffect, useState } from 'react'
import {
    countCategoryApi,
    countCheckListApi,
    countDoupicateApi,
    countRequestApi,
    countWorkApi,
    detailCategoryApi,
    detailCheckListApi,
    detailDoupicateApi,
    detailRequestApi,
    detailWorkApi,
    listCategoryApi,
    listCheckListApi,
    listDoupicateApi,
    listRequestApi,
    listWorkApi,
} from '../api'

// ==================List Work=========================
export const useListWork = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listWorkApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountWork = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countWorkApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailWork = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailWorkApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

// ==================Manage Douplicate=========================
export const useListDoupicate = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDoupicateApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountDoupicate = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countDoupicateApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailDoupicate = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailDoupicateApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

// ==================Manage Request=========================
export const useListRequest = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listRequestApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountRequest = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countRequestApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailRequest = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailCategoryApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

// ==================Category=========================
export const useListCategory = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCategoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountCategory = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countCategoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailCategory = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailCategoryApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

// ================CheckList=========================
export const useListCheckList = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCheckListApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountCheckList = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countCheckListApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailCheckList = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailCheckListApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
