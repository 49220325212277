import { CalendarForm, DropdownForm, FormUpdateDialog } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { addPromotionApartApi, listPromotionV2Api, updatePromotionApartApi } from '../api'
import { useDetailPromotionApart, useListSevicesId } from '../utils'
import { useGetApi } from '@/hooks'

const UpdateApartmentPromotion = (props) => {
    const { setVisible, setParams, visible } = props
    const promotionDetail = useDetailPromotionApart(typeof visible === 'number' ? visible : undefined)
    const [infos, setInfos] = useState({})
    const dataSevices = useListSevicesId({ apartment_id: infos.apartment_id })
    const apartments = useSelector((state) => state.apartments)
    const promotions = useGetApi(listPromotionV2Api, {}, [])

    function convertNumberToDateString(number) {
        const year = Math.floor(number / 100)
        const month = number % 100
        const dateObj = new Date(year, month - 1, 1)
        return dateObj
    }

    const formatDate = (date) => {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1
        const formattedDate = `${year}${month.toString().padStart(2, '0')}`
        return parseInt(formattedDate, 10)
    }

    useEffect(() => {
        if (promotionDetail.id) {
            setInfos({
                ...promotionDetail,
                cycle_name: new Date(convertNumberToDateString(promotionDetail.cycle_name)),
            })
        }
    }, [promotionDetail])

    useEffect(() => {
        if (infos.promotion_id) {
            const promotion = promotions.find(p => p.id === infos.promotion_id)
            if (promotion) setInfos({ ...infos, bdc_service_id: promotion.service_id })
        }
    }, [infos.promotion_id])

    useEffect(() => {
        if (infos.bdc_service_id && dataSevices && dataSevices[0]) {
            const service = dataSevices.find(d => d.bdc_service_id === infos.bdc_service_id)
            if (service) setInfos({ ...infos, service_price_id: service.id })
            else setInfos({ ...infos, service_price_id: undefined })
        }
    }, [infos.bdc_service_id, JSON.stringify(dataSevices)])

    const handleData = () => {
        let info = { ...infos, cycle_name: formatDate(infos.cycle_name) }
        if (!info.apartment_id) return "Vui lòng chọn căn hộ"
        if (!info.promotion_id) return "Vui lòng chọn khuyến mãi"
        if (!info.cycle_name) return "Vui lòng chọn kỳ bắt đầu ưu đãi"
        if (!info.service_price_id) return "Căn hộ không có dịch vụ được khuyến mãi"
        if (typeof visible === 'number') info = { ...removePropObject(info, promotionDetail), id: visible }
        return info
    }

    const handleApartmentChange = (e) => {
        const selectedApartmentId = e.target.value
        setInfos({ ...infos, apartment_id: selectedApartmentId, promotion_id: undefined, bdc_service_id: undefined })
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'ưu đãi căn hộ'}
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            route="/promotion_management"
            setParams={setParams}
            refreshObjects={[setInfos]}
            actions={{ add: addPromotionApartApi, update: updatePromotionApartApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="service"
                            value={infos.apartment_id}
                            optionLabel="name"
                            options={apartments}
                            onChange={handleApartmentChange}
                            label="Căn hộ  (*)"
                        />
                        <DropdownForm
                            id="service_price_id"
                            value={infos.service_price_id}
                            optionLabel="name"
                            options={dataSevices}
                            label="Dịch vụ căn hộ (*)"
                            disabled
                            showClear={false}
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <DropdownForm
                            id="service"
                            value={infos.promotion_id}
                            optionLabel="name"
                            options={promotions}
                            onChange={(e) => setInfos({ ...infos, promotion_id: e.target.value })}
                            label="Ưu đãi (*)"
                        />
                        <CalendarForm
                            id="semester"
                            value={infos.cycle_name instanceof Date ? infos.cycle_name : null}
                            onChange={(e) => setInfos({ ...infos, cycle_name: e.target.value })}
                            showIcon
                            label="Kỳ bắt đầu ưu đãi (*)"
                            view="month"
                            dateFormat="mm/yy"
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateApartmentPromotion
