import { Columnz, DataTablez, TimeBody, useGetParams } from '@/components'
import { useGetApi } from '@/hooks'
import React, { useState } from 'react'
import { countPosition, deletePosition, listPosition } from '../api'
import UpdatePosition from './UpdatePosition'

function PositionManagement() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useGetApi(listPosition, { ...params }, [])
      const totalRecords = useGetApi(countPosition, { ...params, first: undefined }, 0)
      const [visible, setVisible] = useState(false)

      return (
            <div className='card'>
                  {visible && <UpdatePosition setParams={setParams} visible={visible} setVisible={setVisible} />}
                  <DataTablez
                        value={data}
                        params={params}
                        totalRecords={totalRecords}
                        setParams={setParams}
                        basePermissions={["insert", "delete", "detail"]}
                        headerInfo
                        actionsInfo={{ deleteAction: deletePosition }}

                        route="/user_position"
                        title="chức vụ"
                        setVisibledDialog={setVisible}
                  >
                        <Columnz field="name" style={{ width: "30%" }} header="Chức vụ" />
                        <Columnz field="description" header="Mô tả" />
                        <Columnz body={(e) => TimeBody(e.created_at)} header="Ngày tạo" />
                        <Columnz body={(e) => TimeBody(e.updated_at)} header="Ngày cập nhật" />
                  </DataTablez>
            </div>
      )
}

export default PositionManagement
