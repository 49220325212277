import { CalendarForm, FormUpdate, HeaderListForm, InputForm, InputNumberForm, UploadFiles, useGetParams } from '@/components'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addUserApi, updateUserApi } from '../api'

import { databaseDate } from '@/lib'
import { Button } from 'primereact/button'
import { useListContract } from '../utils'
const initInfos = {
}
function Other() {
      const initParam = useGetParams()
      const { id } = useParams()
      const detail = useListContract(id)
      const [params, setParams] = useState(initParam)
      const [infos, setInfos] = useState(initInfos)
      const [file, setFile] = useState([])
      const [filev2, setFilev2] = useState([])
      useEffect(() => {
            if (detail.id) {
                  setInfos({
                        ...infos,
                        ...removeUndefinedProps(detail),
                  })
                  if (detail.education_files) {
                        const newImages = checkJson(detail.education_files) || detail.education_files
                        if (typeof newImages === "object" && newImages) {
                              setFile(newImages)
                        }
                  }
                  if (detail.health_files) {
                        const newImages = checkJson(detail.health_files) || detail.health_files
                        if (typeof newImages === "object") {
                              setFilev2(newImages)
                        }
                  }
                  const data = checkJson(detail.data_tax) || detail.data_tax
                  if (data && data[0]) {
                        data.forEach(d => {
                              if (d.dobnpt) d.dobnpt = new Date(d.dobnpt)
                              if (d.from_monthnpt) d.from_monthnpt = new Date(d.from_monthnpt)
                              if (d.to_monthnpt) d.to_monthnpt = new Date(d.to_monthnpt)
                              if (d.to_monthnpt) d.to_monthnpt = new Date(d.to_monthnpt)
                              if (d.dateRangenpt) d.dateRangenpt = new Date(d.dateRangenpt)
                        })
                  }

                  if (Array.isArray(data)) setDataTax([...data])
            }
      }, [detail])
      const [dataTax, setDataTax] = useState([]);

      // const [data, setData] = useState([])


      const handleChange = (value, field, index) => {
            setDataTax(prevData => {
                  const newData = [...prevData];
                  newData[index] = { ...newData[index], [field]: value };
                  return newData;
            });
      };
      const handleDelete = (indexV2) => {
            if (indexV2 || indexV2 === 0) setDataTax((pre) => pre.filter((d, index) => index !== indexV2))
      }
      const handleAdd = () => {
            const newItem = {};
            setDataTax((prevData) => [...prevData, newItem]);
            const newRowId = new Date().getTime();
            setDataTax([...dataTax, { idRow: newRowId, nanamenptme: '', dobnpt: '', tax_codenpt: '', idNumbernpt: '', relationnpt: '', addressnpt: '' }]);
      }

      // const addRow = () => {
      //       const newRowId = new Date().getTime();
      //       setDataTax([...dataTax, { idRow: newRowId, nanamenptme: '', dobnpt: '', tax_codenpt: '', idNumbernpt: '', relationnpt: '', addressnpt: '' }]);

      // };
      // const onEditorValueChange = (product, field, value) => {
      //       const updatedProduct = { ...product, [field]: value };
      //       setDataTax(dataTax.map(d => d.idRow === product.idRow ? updatedProduct : d));
      // };
      // const inputTextEditorNPT = (product, field) => {
      //       return <InputText type="text" value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      // };
      // const inputNumberEditorNPT = (product, field) => {
      //       return <InputNumberFormV2 type="text" value={product[field]} handleOnChange={(e) => onEditorValueChange(product, field, e)} />;
      // };
      // const inputCalenderNPT = (product, field) => {
      //       return <Calendar dateFormat="dd/mm/yy" value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      // };
      // const inputCalenderDateNPT = (product, field) => {
      //       return <Calendar dateFormat="dd/mm/yy" value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      // };
      // const inputCalenderFromMonthNPT = (product, field) => {
      //       return <Calendar dateFormat="mm/yy" view="month" value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      // };
      // const inputCalenderToMonthNPT = (product, field) => {
      //       return <Calendar dateFormat="mm/yy" view="month" value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      // };

      // const columnBodyTemplate = (product, column) => {
      //       return column.editor ? column.editor(product) : product[column.field];
      // };
      // const deleteRow = (product) => {

      //       if (product && (product.idRow || product.idRow === 0 || product.idRow === null)) {
      //             setDataTax(dataTax.filter(d => d.idRow !== product.idRow));
      //       }
      // }
      // const actionBodyTemplate = (product) => {
      //       return <Button icon="pi pi-trash" type='button' onClick={() => deleteRow(product)} severity="danger" />;
      // };

      // const columns = [
      //       { field: 'namenpt', header: 'Họ tên NPT', editor: (product) => inputTextEditorNPT(product, 'namenpt') },
      //       { field: 'dobnpt', header: 'Ngày sinh', editor: (product) => inputCalenderNPT(product, 'dobnpt') },
      //       { field: 'tax_codenpt', header: 'MST NPT', editor: (product) => inputNumberEditorNPT(product, 'tax_codenpt') },
      //       { field: 'idNumbernpt', header: 'Số CMND/CCCD', editor: (product) => inputNumberEditorNPT(product, 'idNumbernpt') },
      //       { field: 'dateRangenpt', header: 'Ngày cấp', editor: (product) => inputCalenderDateNPT(product, 'dateRangenpt') },
      //       { field: 'addRangenpt', header: 'Nơi cấp', editor: (product) => inputTextEditorNPT(product, 'addRangeNPT') },
      //       { field: 'relationnpt', header: 'Quan hệ', editor: (product) => inputTextEditorNPT(product, 'relationnpt') },
      //       { field: 'number_npt', header: 'Số', editor: (product) => inputTextEditorNPT(product, 'number_npt') },
      //       { field: 'from_monthnpt', header: 'Từ tháng', editor: (product) => inputCalenderFromMonthNPT(product, 'from_monthnpt') },
      //       { field: 'to_monthnpt', header: 'Đến tháng', editor: (product) => inputCalenderToMonthNPT(product, 'to_monthnpt') },
      //       { field: 'nationalitynpt', header: 'Quốc tịch', editor: (product) => inputTextEditorNPT(product, 'nationalitynpt') },
      //       { field: 'addressnpt', header: 'Địa chỉ', editor: (product) => inputTextEditorNPT(product, 'addressnpt') },
      //       // { body: (e) => actionBodyTemplate(e), header: 'Hành động' },
      // ];
      const handleData = () => {
            let info = { ...infos, status: infos.status ? 1 : 0 }
            info.birthday = info.birthday ? databaseDate(info.birthday, false, "date") : undefined
            info.cmt_date = info.cmt_date ? databaseDate(info.cmt_date, false, "date") : undefined
            info.decision_date = info.decision_date ? databaseDate(info.decision_date, false, "date") : undefined
            info.date_in = info.date_in ? databaseDate(info.date_in, false, "date") : undefined
            if (Number(id)) info = { ...removePropObject(info, detail), id: id }
            if (file) info.education_files = file
            else info.education_files = []
            if (filev2) info.health_files = filev2
            else info.health_files = []
            const params = {
                  user_id: id,
                  ...info,
                  type_user: "hrm",
                  data_tax: dataTax,
            }
            return params
      }
      return (
            <>
                  <FormUpdate
                        // title="bảo hiểm"
                        route="/list_personnel"
                        actions={{ add: addUserApi, update: updateUserApi }}
                        handleData={handleData}
                        checkId={Number(id)}

                  >

                        <div className="card">
                              <HeaderListForm title="Trình độ" />
                              <div className="grid grid-form">
                                    <div className="col-12 lg:col-6">
                                          <InputForm label="Trình độ"
                                                value={infos.academic_level}
                                                onChange={(e) => setInfos({ ...infos, academic_level: e.target.value })}
                                          />
                                          <InputForm label="Email cá nhân"
                                                value={infos.email}
                                                onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                          />
                                          <InputForm label="Ngoại ngữ"
                                                value={infos.foreign_language}
                                                onChange={(e) => setInfos({ ...infos, foreign_language: e.target.value })} />
                                          <UploadFiles files={file} setFiles={setFile} />

                                    </div>
                                    <div className="col-12 lg:col-6">
                                          <InputForm label="Chuyên ngành"
                                                value={infos.specialized}
                                                onChange={(e) => setInfos({ ...infos, specialized: e.target.value })} />
                                          <InputForm label="Email nội bộ"
                                                value={infos.internal_mail}
                                                onChange={(e) => setInfos({ ...infos, internal_mail: e.target.value })}

                                          />
                                          <InputForm label="Tin học"
                                                value={infos.technology}
                                                onChange={(e) => setInfos({ ...infos, technology: e.target.value })}
                                          />

                                    </div>


                              </div>

                        </div>
                        <div className="card">
                              <HeaderListForm title="Tình trạng sức khỏe" />
                              <div className="grid grid-form">
                                    <div className="col-12 lg:col-6">
                                          <InputForm label="Tình trạng sức khỏe"
                                                value={infos.health_status}
                                                onChange={(e) => setInfos({ ...infos, health_status: e.target.value })}
                                          />
                                          <InputForm label="Chiều cao"
                                                value={infos.height}
                                                onChange={(e) => setInfos({ ...infos, height: e.target.value })}

                                          />
                                          <UploadFiles files={filev2} setFiles={setFilev2} />
                                    </div>
                                    <div className="col-12 lg:col-6">
                                          <InputForm label="Bệnh lý"
                                                value={infos.pathological}
                                                onChange={(e) => setInfos({ ...infos, pathological: e.target.value })}
                                          />
                                          <InputForm label="Cân nặng"

                                                value={infos.weight}
                                                onChange={(e) => setInfos({ ...infos, weight: e.target.value })}
                                          />
                                    </div>
                              </div>
                        </div>
                        <div className='card'>
                              <HeaderListForm title="Thông tin người phụ thuộc" />
                              {/* <Button label="Thêm dòng" onClick={addRow} type='button' className='mb-3' /> */}
                              {/* <DataTable value={dataTax} emptyMessage="Không có dữ liệu ">
                                    {columns.map((col, i) => (
                                          <Column key={col.field || i}
                                                field={col.field}
                                                header={col.header}

                                                body={(product) => col.body ? col.body(product, col) : columnBodyTemplate(product, col)} />
                                    ))}
                              </DataTable> */}
                              {dataTax.map((d, index) => {
                                    return (
                                          <div key={index} className="grid grid-form w-full  align-items-center mx-1 card">
                                                <div className="col-3">
                                                      <InputForm
                                                            label="Họ tên NPT (*)"
                                                            value={d.namenpt}
                                                            onChange={(e) => handleChange(e.target.value, 'namenpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <CalendarForm
                                                            label="Ngày sinh "
                                                            value={d.dobnpt}
                                                            onChange={(e) => handleChange(e.target.value, 'dobnpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm
                                                            label="MST NPT"
                                                            value={d.tax_codenpt}
                                                            onChange={(e) => handleChange(e.target.value, 'tax_codenpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <InputNumberForm
                                                            label="Số CCCD NPT"
                                                            value={d.idNumbernpt}
                                                            onChange={(e) => handleChange(e.target.value, 'idNumbernpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <CalendarForm
                                                            label="Ngày Cấp "
                                                            value={d.dateRangenpt}
                                                            onChange={(e) => handleChange(e.target.value, 'dateRangenpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm
                                                            label="Nơi cấp"
                                                            value={d.addRangeNPT}
                                                            onChange={(e) => handleChange(e.target.value, 'addRangeNPT', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm
                                                            label="Quan hệ"
                                                            value={d.relationnpt}
                                                            onChange={(e) => handleChange(e.target.value, 'relationnpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm
                                                            label="Số "
                                                            value={d.number_npt}
                                                            onChange={(e) => handleChange(e.target.value, 'number_npt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <CalendarForm
                                                            label="Từ tháng "
                                                            value={d.from_monthnpt}
                                                            onChange={(e) => handleChange(e.target.value, 'from_monthnpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <CalendarForm
                                                            label="Đến tháng"
                                                            value={d.to_monthnpt}
                                                            onChange={(e) => handleChange(e.target.value, 'to_monthnpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm
                                                            label="Quốc tịch"
                                                            value={d.nationalitynpt}
                                                            onChange={(e) => handleChange(e.target.value, 'nationalitynpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm
                                                            label="Địa chỉ"
                                                            value={d.addressnpt}
                                                            onChange={(e) => handleChange(e.target.value, 'addressnpt', index)}

                                                      />
                                                </div>
                                                <div className="col-3 pl-3">
                                                      <Button
                                                            type="button"
                                                            raised
                                                            icon="pi pi-times"
                                                            severity="danger"
                                                            // disabled={data.length <= 1}
                                                            onClick={() => handleDelete(index)
                                                            }
                                                      />
                                                </div>
                                          </div>
                                    )
                              })}
                              <Button
                                    type="button"
                                    raised
                                    icon="pi pi-plus"
                                    severity="info"
                                    onClick={() => handleAdd()}
                                    className=" mt-3"
                                    label='Thêm NPT'
                              // disabled={infos.status_input ? false : true}
                              />
                        </div>
                  </FormUpdate>
            </>
      )
}

export default Other
