import { postData, getData } from '@/lib/request'

export const listHandbookApi = (params) => getData('web2/building_guide/getListHandbook', params)
export const listCate = (params) => getData('web2/building_guide/getListNameCate', params)
export const countPostApi = (params) => getData('web2/building_guide/countHandbook', params)
export const listUserCreate = (params) => getData('web2/building_guide/getListPubUser', params)
export const deletePostApi = (params) => postData('web2/building_guide/deleteHandbook', params)
export const detailPostApi = (params) => getData('web2/building_guide/getDetailHandbook', params)
export const addPostApi = (params) => postData('web2/building_guide/addHandBook', params, true)
export const updatePostApi = (params) => postData('web2/building_guide/updateHandbook', params, true)
export const listDirectoryApi = (params) => getData('web2/building_guide/getListHandbookCate', params)
export const countDirectoryApi = (params) => getData('web2/building_guide/countHandbookCate', params)
export const deleteDirectoryApi = (params) => postData('web2/company/deleteCompany', params)
export const detailDirectoryApi = (params) => getData('web2/building_guide/getDetailHandbookCate', params)
export const addDirectoryApi = (params) => postData('web2/building_guide/addHandBookCate', params)
export const updateDirectoryApi = (params) => postData('web2/building_guide/updateHandbookCate', params)
