import React from 'react'
import RateOverview from './RateOverview'
import RateDetail from './RateDetail'
import { TabPanel, TabView } from 'primereact/tabview'

export default function RatedService() {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Tổng hợp đánh giá">
                    <RateOverview />
                </TabPanel>
                <TabPanel header="Chi tiết đánh giá">
                    <RateDetail />
                </TabPanel>
            </TabView>
        </div>
    )
}
