import {
    CalendarForm,
    Columnz,
    DataTablezV2,
    FormUpdate, HeaderListForm,
    InputForm,
    InputNumberForm, InputNumberFormV2,
    MultiSelectForm,
    useGetParams
} from '@/components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addShift, updateShift } from '../api'
import { date_work } from '@/constants'
import { databaseDate } from '@/lib'
import { useCheckShift, useDetailShift } from '../utils'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { listExchangeV2Api, listGroupV2Api, listUserApi, listUserV2Api } from '@/api'
import { useGetApi } from '@/hooks'
import { ObjectShiftManagement } from './ObjectShiftManagement'
import { useSelector } from 'react-redux'
import { Button } from '@/uiCore'

const initInfos = {
    name: '',
    start: null,
    end: null,
    break_time_start: null,
    break_time_end: null,
    number_work: 0


}

function UpdateShiftManagement() {
    const initParam = useGetParams()
    const { id } = useParams()
    const [infos, setInfos] = useState(initInfos)
    const detail = useDetailShift(id)
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const [params, setParams] = useState(initParam)
    const users = useSelector(state => state.users);
    console.log()
    const check = useCheckShift({
        start: databaseDate(infos.start, false, 'hour'),
        end: databaseDate(infos.end, false, 'hour'),
        dates: infos.dates,
        shift_id: id
    })
    const exchanges = useGetApi(listExchangeV2Api, {}, [])

    const hourWork = () => {
        const start = new Date(infos.start);
        const end = new Date(infos.end);
        let totalMinutes = (end.getHours() * 60 + end.getMinutes()) - (start.getHours() * 60 + start.getMinutes());
        if (infos.break_time_start && infos.break_time_end)
        {
            const breakStart = new Date(infos.break_time_start);
            const breakEnd = new Date(infos.break_time_end);
            const breakMinutes = (breakEnd.getHours() * 60 + breakEnd.getMinutes()) - (breakStart.getHours() * 60 + breakStart.getMinutes());
            totalMinutes -= breakMinutes;
        }
        const totalHours = totalMinutes / 60;
        setInfos(prevInfos => ({
            ...prevInfos,
            number_work: Number(totalHours.toFixed(2))
        }));
    }
    useEffect(() => {
        if (infos.start && infos.end)
        {
            hourWork();
        }
    }, [infos.start, infos.end, infos.break_time_end, infos.break_time_start]);

    useEffect(() => {
        if (detail.id)
        {
            const a = checkJson(detail.dates)
            const b = checkJson(detail.department_ids)
            const c = checkJson(detail.users)
            setInfos({
                ...infos,
                ...removeUndefinedProps(detail),
                dates: a,
                start: detail.start ? new Date(`${dateString} ${detail.start}`) : '',
                end: detail.end ? new Date(`${dateString} ${detail.end}`) : '',
                break_time_end: detail.break_time_end ? new Date(`${dateString} ${detail.break_time_end}`) : '',
                break_time_start: detail.break_time_start ? new Date(`${dateString} ${detail.break_time_start}`) : '',
                department_ids: b,
                users: c
            })
        }
    }, [detail])
    const handleData = () => {
        if (!infos.start) return "Vui lòng chọn giờ bắt đầu vào ca"
        if (!infos.end) return "Vui lòng chọn giờ kết thúc ca"
        if (!infos.break_time_start) return "Vui lòng chọn giờ nghỉ "
        if (!infos.break_time_end) return "Vui lòng chọn giờ kết thúc nghỉ "
        if (!infos.dates) return "Vui lòng chọn số ngày làm việc trong tuần "
        if (!infos.total_work) return "Vui lòng nhập tổng công tính "
        let info = { ...infos, status: infos.status ? 1 : 0 }
        info.start = info.start ? databaseDate((new Date(info.start)).setSeconds(0), false, 'hour') : undefined
        info.end = info.end ? databaseDate((new Date(info.end).setSeconds(0)), false, 'hour') : undefined
        info.break_time_end = info.break_time_end ? databaseDate(info.break_time_end, false, 'hour') : undefined
        info.break_time_start = info.break_time_start ? databaseDate(info.break_time_start, false, 'hour') : undefined
        if (Number(id)) info = { ...removePropObject(info, detail), id: id }
        return info
    }
    useEffect(() => {
        const breakTime = infos.break_time_start;
        if (breakTime < infos.start || breakTime > infos.end)
        {
            setInfos({ ...infos, break_time_start: infos.start });
        }
    }, [infos.start, infos.end, infos.break_time_start]);
    useEffect(() => {
        const endTime = infos.break_time_end;
        if (endTime < infos.break_time_start || endTime > infos.end)
        {
            setInfos({ ...infos, break_time_end: infos.break_time_start });
        }
    }, [infos.end]);

    const user = users.filter(user => {
        for (let i = 0; i < infos.department_ids?.length; i++)
        {
            if (user.department_id === infos.department_ids[i])
            {
                return true;
            }
        }
        return false;
    });
    const [data, setData] = useState([])
    useEffect(() => {
        const userTable = user.filter((item) => {
            return infos.users.includes(item.user_id);
        });
        setData(userTable)
    }, [JSON.stringify(infos.users)])
    const actionBody = (e) => {
        const handleDelete = () => {
            setData([...data.filter((d) => d !== e)])
            const userChangeData = infos.users.filter((d) => d !== e.user_id);
            setInfos({ ...infos, users: userChangeData });
        }
        return <Button type="button" icon="pi pi-trash" onClick={handleDelete} rounded outlined severity="danger" />
    }

    return (
        <div>
            <FormUpdate
                checkId={Number(id)}
                title="ca làm việc "
                route="/shift_management"
                actions={{ add: addShift, update: updateShift }}
                handleData={handleData}
            >
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên ca làm việc (*)"
                            required
                        />
                        <InputForm
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã ca làm việc (*)"
                            required
                        />
                        <div className="grid">
                            <div className="col-6">
                                <InputNumberFormV2 label="Tổng công tính (*)"
                                    value={infos.total_work}
                                    handleOnChange={(e) => setInfos({ ...infos, total_work: e })}
                                />
                            </div>
                            <div className="col-6">
                                <InputForm label="Tổng giờ làm việc" value={infos.number_work} disabled />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-6">
                                <MultiSelectForm
                                    options={date_work}
                                    value={infos.dates}
                                    onChange={(e) => setInfos({ ...infos, dates: e.target.value })}
                                    label="Số ngày làm việc trong tuần (*)" />
                            </div>
                            <div className="col-6">
                                <InputForm label="Tổng số ngày làm việc trong tuần (*)"
                                    value={infos.dates ? infos.dates.length : '0'}
                                    disabled />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6">
                        <CalendarForm
                            value={infos.start}
                            onChange={(e) => setInfos({
                                ...infos, start: e.target.value
                            })}
                            timeOnly
                            label="Giờ bắt đầu vào ca (*)" />
                        <CalendarForm
                            value={infos.end}
                            onChange={(e) => setInfos({ ...infos, end: e.target.value })}
                            timeOnly
                            minDate={infos.start}
                            label="Giờ kết thúc ca (*)" />
                    </div>
                </div>
                <HeaderListForm title="Nhân sự làm việc" />
                <div className={"grid grid-form"}>
                    <div className="col-12 lg:col-6">
                        <MultiSelectForm options={exchanges} label="Chọn phòng ban"
                            onChange={(e) => setInfos({ ...infos, department_ids: e.target.value })}
                            value={infos.department_ids} />
                    </div>
                    <div className="col-12 lg:col-6">
                        <MultiSelectForm
                            optionLabel="full_name"
                            optionValue="user_id"
                            label="Chọn nhân viên"
                            options={id ? user : user.filter(d => !check.includes(d.user_id))} value={infos?.users}
                            onChange={(e) => setInfos({
                                ...infos, users: e.target.value
                            })} />
                    </div>
                </div>
                <div className="card">
                    <DataTablezV2 value={data}>
                        <Columnz field="user_id" header="ID" />
                        <Columnz field="full_name" header="Họ và Tên" />
                        <Columnz field="code" header="Mã nhân viên" />
                        <Columnz header="Action" body={actionBody} />
                    </DataTablezV2>
                </div>
            </FormUpdate>
        </div>
    )
}

export default UpdateShiftManagement
