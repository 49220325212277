import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    TimeBody,
    useGetParams,
} from '@/components'
import { genders, relationshipOwner } from '@/constants'
import { useState } from 'react'
import { getArrId } from '@/utils'
import { useDispatch, useSelector } from 'react-redux'
import { confirmDialog } from 'primereact/confirmdialog'
import { removeResident, setToast } from '@/redux/features'
import { listToast } from '@/constants'
import { Dialog } from 'primereact/dialog'
import ImportResident from './ImportResident'
import {
    countResidentApi,
    deleteResidentApi,
    exportResidentApi,
    listResidentApi,
    resetPassResidentApi,
    sendEmailApi,
    sendSmsApi,
} from '@/api'
import { useGetApi } from '@/hooks'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '', email: '', phone: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Inputz
                value={filter.email}
                placeholder="Nhập email tìm kiếm"
                onChange={(e) => setFilter({ ...filter, email: e.target.value })}
            />
            <Inputz
                value={filter.phone}
                placeholder="Nhập số điện thoại tìm kiếm"
                onChange={(e) => setFilter({ ...filter, phone: e.target.value })}
                type="number"
            />
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.gender}
                options={genders}
                onChange={(e) => setFilter({ ...filter, gender: e.target.value })}
                placeholder="Chọn giới tính"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder="Khoảng thời gian tạo"
            />
            <Dropdownz
                value={filter.relationship}
                options={relationshipOwner}
                onChange={(e) => setFilter({ ...filter, relationship: e.target.value })}
                placeholder="Quan hệ với chủ hộ"
            />
        </GridForm>
    )
}

const Residents = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [selectedProducts, setSelectedProducts] = useState([])
    const data = useGetApi(listResidentApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countResidentApi, { ...params, first: undefined }, 0)
    const [visible, setVisible] = useState(false)
    const [visibleImport, setVisibleImport] = useState(false)
    const dispatch = useDispatch()
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'cư dân',
            actionType: 'xóa',
            action: async (list_id) => await deleteResidentApi({ list_id: getArrId(list_id) }),
        },
        {
            label: 'Gửi email tạo mới cư dân',
            icon: 'pi pi-send',
            title: 'cư dân',
            actionType: 'gửi email ',
            action: async (list_id) => await sendEmailApi({ ids: getArrId(list_id) }),
        },

        {
            label: 'Gửi SMS tạo mới cư dân',
            icon: 'pi pi-send',
            title: 'cư dân',
            actionType: 'gửi sms',
            action: async (list_id) => await sendSmsApi({ ids: getArrId(list_id) }),
        },
    ]
    async function accept(id) {
        const response = await resetPassResidentApi({ id })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Đổi mật khẩu thành công!' }))
            setVisible(response.data.mess)
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }
    const confirm = (id) => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục thay đổi mật khẩu của người dùng này?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: () => accept(id),
        })
    }
    const moreActions = [{ icon: 'pi pi-sync', command: (id) => confirm(id) }]

    const handleAfterCallApi = (data) => {
        if (data) {
            if (typeof data === 'object') {
                const arrId = getArrId(data)
                arrId.forEach((a) => {
                    dispatch(removeResident(a))
                })
            } else dispatch(removeResident(data))
        }
    }
    return (
        <div className="card">
            {visibleImport && <ImportResident visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            <Dialog
                header="Mật khẩu mới"
                visible={Boolean(visible)}
                style={{ width: '500px' }}
                onHide={() => setVisible(false)}
            >
                <p className="mt-6 text-center text-xl font-bold mb-4">{visible}</p>
            </Dialog>
            <HeaderListForm title="Quản lý cư dân" />
            <Header setParams={setParams} />
            <DataTablez
                title="cư dân"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                headerInfo={{ items, exportApi: exportResidentApi, setVisibleImport: setVisibleImport }}
                route="/resident_management"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                handleAfterCallApi={handleAfterCallApi}
                actionsInfo={{
                    noDialog: true,
                    deleteAction: deleteResidentApi,
                    moreActions,
                    handleDelete: (id) => {
                        return { list_id: [id] }
                    },
                }}
                basePermissions={["insert", "detail", "delete", "import", "export"]}
            >
                <Columnz field="id" header="Id cư dân" />
                <Columnz
                    field="full_name"
                    style={{ textAlign: 'left' }}
                    header="Họ và tên"
                    body={(rowData) => {
                        const content =
                            rowData.type_board !== 0 ? (
                                <i style={{ color: '#3C8DBC' }} className="pi pi-flag-fill pl-2" />
                            ) : null
                        return (
                            <>
                                {rowData.full_name} {content}
                            </>
                        )
                    }}
                />
                <Columnz field="email" header="Email đăng nhập" />
                <Columnz field="phone" header="SĐT" />
                <Columnz body={(e) => TimeBody(e.birthday, 'date')} header="Ngày sinh" />
                <Columnz field="apartments" header="Căn hộ" />
            </DataTablez>
        </div>
    )
}

export default Residents
