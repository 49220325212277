import { useEffect, useState } from 'react'
import {
    countApartmentServiceApi,
    detailApartmentServiceApi,
    detailProgressiveApi,
    listApartmentServiceApi,
    listServiceApi,
} from '../api'

export const useListApartmentService = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listApartmentServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListService = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listServiceApi({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountApartmentService = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countApartmentServiceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useDetailProgressive = (service_id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailProgressiveApi({ service_id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (service_id) fetchData()
    }, [service_id])
    return data
}
export const useDetailApartmentService = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailApartmentServiceApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
