import { Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { RoundChartFlexCol, RoundChartFlexRow } from '@/components/Chart'
import { status } from '@/constants'
import { Card } from '@/uiCore'
import { useState } from 'react'
import { useGetNotifyEvent, useGetOpinions, useGetStatVehicle } from '../../../dashboard/utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn loại dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Dịch vụ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Căn hộ"
            />
        </GridForm>
    )
}
function InteractiveReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const opinions = useGetOpinions(params)
    const notifyEvent = useGetNotifyEvent()
    return (
        <>
            <Card className="">
                <HeaderListForm title="Tổng hợp thông tin - sự kiện" />
                <div class="grid">
                    <div class="col-12 md:col-6 lg:col-4 ">
                        <div class="text-center p-3 surface-border surface-overlay border-3 border-round font-bold" >
                            <div className="flex  align-items-center ">
                                <div className="flex-auto ">
                                    <h5 className="">{notifyEvent.count}</h5>
                                    <h5 className="m-0">Thông báo</h5>
                                </div>
                                <div className="flex-auto ">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/Notification.png`}
                                        alt="Thông báo"
                                        className="pl-8 align-items-center flex"
                                        style={{
                                            color: '#3399FF',
                                            fontSize: '4rem',
                                        }}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center p-3 surface-border surface-overlay border-3 border-round font-bold" >
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="">{notifyEvent.category_id}</h5>
                                    <h5 className="m-0">Sự kiện</h5>
                                </div>
                                <div className="flex-auto ">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/EventCalender.png`}
                                        alt="Sự kiện"
                                        className="pl-8 align-items-center flex"
                                        style={{
                                            color: '#FF0000',
                                            fontSize: '4rem',
                                        }}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center p-3 surface-border surface-overlay border-3 border-round font-bold" >
                            <div className="flex  align-items-center">
                                <div className="flex-auto ">
                                    <h5 className="">
                                        {notifyEvent.interaction_user} / {notifyEvent.interaction_comment}
                                    </h5>
                                    <h5 className="m-0">Người / Bình luận</h5>
                                </div>
                                <div className="flex-auto ">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/Comment.png`}
                                        alt="Bình luận"
                                        className=" pl-8 align-items-center flex"
                                        style={{
                                            color: '#FF0000',
                                            fontSize: '4rem',
                                        }}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-top-1 border-400 pt-2">
                    <div className=" align-items-center	justify-content-center">
                        <RoundChartFlexCol
                            title="Ý kiến - Kiến nghị"
                            color={['#4272bc', '#56a2f8', '#77c0f3']}
                            type="pie"
                            data={opinions}
                            keyChart="count"
                            aspectRatio={'.8'}
                            // pCard="p-card"
                            labels={['Đã tiếp nhận', 'Hoàn thành', 'Chờ phản hồi']}
                        />
                    </div>

                </div>
            </Card>
        </>
    )
}

export default InteractiveReport
