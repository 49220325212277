import {DropdownForm, FormUpdate, InputForm, InputNumberForm, InputNumberFormV2} from '@/components'
import {checkJson, removePropObject} from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addPriceApi, updatePriceApi } from '../api'
import { useDetailPrice } from '../utils'
import { prices, typeBdcPrices } from '@/constants'
import { Button } from '@/uiCore'
import {useGetApi} from "@/hooks";
import {listTypePriceApi} from "@/api/projects/info";

const initInfos = {
    bdc_price_type_id: 1,
    description: typeBdcPrices[0].name,
    type: 1,
    code: ''
}
const initData = [{ from: null, to: null, price: null }]

const Price = ({ value, handleDelete, handleOnChange, index, disabled }) => {
    return (
        <div className="flex gap-4 justify-content-center align-items-center">
            <div className="grid grid-form w-11">
                <div className="col-12 lg:col-4">
                    <InputNumberForm
                        value={value.from}
                        handleOnChange={(e) => handleOnChange(index, { from: e })}
                        label="Từ (*)"
                        required
                    />
                </div>
                <div className="col-12 lg:col-4">
                    <InputNumberForm
                        value={value.to}
                        handleOnChange={(e) => handleOnChange(index, { to: e })}
                        label="Đến (*)"
                        required
                    />
                </div>
                <div className="col-12 lg:col-4">
                    <InputNumberFormV2
                        value={value.price}
                        handleOnChange={(e) => handleOnChange(index, { price: e })}
                        label="Giá tiền (*)"
                        required
                    />
                </div>
            </div>
            <div className="w-1 text-center">
                <Button type="button" raised icon="pi pi-times" disabled={disabled} severity="danger" onClick={() => handleDelete(index)} />
            </div>
        </div>
    )
}

const PriceV2 = ({value, handleOnChange, index}) => {
    let label = index === 0 ? "Bậc 1 (Bình thường)" : index === 1 ? "Bậc 2 (Cao điểm)" : "Bậc 3 (Thấp điểm)"

    return (
        <>
            <div className="flex gap-4">
                <div className="grid grid-form w-11">
                    <div className="col-12 lg:col-4">
                        <InputForm
                            value={label}
                            label="Phân loại"
                        />
                    </div>
                    <div className="col-12 lg:col-4">
                        <InputNumberFormV2
                            value={value.price}
                            handleOnChange={(e) => handleOnChange(index, {price: e}, true)}
                            label="Giá tiền"
                            required
                        />
                    </div>
                    <div className="col-12 lg:col-4">
                        <InputForm
                            value={value.description}
                            onChange={(e) => handleOnChange(index, {description: e.target.value}, true)}
                            label="Ghi chú"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const UpdatePrice = () => {
    const { id } = useParams()
    const priceDetail = useDetailPrice(id)
    const [infos, setInfos] = useState(initInfos)
    const [data, setData] = useState(initData)
    const typePrices = useGetApi(listTypePriceApi, false, [])
    const [dataz, setDataz] = useState([{ price: null, description: null }, { price: null, description: null }, { price: null, description: null }])

    useEffect(() => {
        if (priceDetail.id) {
            setInfos({ ...infos, ...priceDetail })
            setData(priceDetail.list_price)
        }
    }, [priceDetail])

    useEffect(() => {
        if (priceDetail.id && priceDetail.bdc_price_type_id === infos.bdc_price_type_id) {
            if (priceDetail.bdc_price_type_id === 5) setDataz([...priceDetail.list_price])
            else setData([...priceDetail.list_price])
        } else {
            setData(initData)
        }
    }, [infos.bdc_price_type_id, priceDetail])

    const handleData = () => {
        let info = { ...infos }
        let oldListPrice = priceDetail.list_price
        let newListPrice = data

        let dataChange = []
        let isValid = true; // Biến để kiểm tra xem dữ liệu có hợp lệ không

        newListPrice.forEach((item) => {
            if (Number(item.from) > Number(item.to)) {
                isValid = false;
            }
        });
        if (!isValid) {
            return "Từ không được lớn hơn đến"
        }
        if (infos.bdc_price_type_id === 5) newListPrice = dataz
        if (Number(id)) {
            const deleteElements = oldListPrice.filter((oldP) => !newListPrice.some((newP) => oldP.id === newP.id))
            if (deleteElements && deleteElements[0]) {
                deleteElements.forEach((del) => {
                    dataChange.push({ id: del.id, deleted_at: 1 })
                })
            }
            oldListPrice.forEach((oldP, index) => {
                const elements = newListPrice.find((newP) => newP.id === oldP.id)
                if (elements && JSON.stringify(elements) !== JSON.stringify(oldP)) {
                    dataChange.push({
                        price: elements.price,
                        from: elements.from,
                        to: elements.to,
                        id: elements.id,
                        description: elements.description,
                    })
                }
            })
            const addElements = newListPrice.filter((p) => !p.id)
            if (addElements && addElements[0]) {
                addElements.forEach((add) => {
                    dataChange.push(add)
                })
            }
            info = { ...removePropObject(info, priceDetail), id: Number(id) }
        } else dataChange = newListPrice
        info.progressive_price = dataChange
        return info
    }

    const handleOnChange = (key, obj, z) => {
        if ((key || key === 0) && obj) {
            if (z) {
                const newData = dataz.map((d, index) => {
                    if (index === key) return {...d, ...obj}
                    else return {...d}
                })
                setDataz([...newData])
            } else {
                const newData = data.map((d, index) => {
                    if (index === key) return {...d, ...obj}
                    else return {...d}
                })
                setData([...newData])
            }
        }
    }

    const handleDelete = (key) => {
        if (key || key === 0) setData((pre) => pre.filter((d, index) => index !== key))
    }

    const handleAdd = () => {
        setData((preData) => [...preData, { from: '', to: '', price: '' }])
    }

    const a = () => {
        if (infos.price_type_id) {
            const price = typePrices.find(t => infos.price_type_id === t.id)
            if (price) {
                const data = checkJson(price.price_type) || price.price_type
                if (data && Array.isArray(data)) return prices.filter(p => data.includes(p.id))
                else return []
            } else return []
        } else return []
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="bảng giá"
            handleData={handleData}
            route="/list_price"
            actions={{ add: addPriceApi, update: updatePriceApi }}
        >
            <div className="card bg-color">
                <div className="flex align-items-center gap-4">
                    <div className="grid grid-form w-full">
                        <div className="col-12 lg:col-4">
                            <InputForm
                                id="name"
                                value={infos.name}
                                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                label="Tên bảng giá (*)"
                                required
                            />
                        </div>
                        <div className="col-12 lg:col-4">
                            <DropdownForm
                                id="type"
                                value={infos.price_type_id}
                                options={typePrices}
                                onChange={(e) => setInfos({ ...infos, price_type_id: e.target.value, bdc_price_type_id: undefined })}
                                label="Loại dịch vụ (*)"
                            />
                        </div>
                        <div className="col-12 lg:col-4">
                            <DropdownForm
                                id="description"
                                value={infos.description}
                                options={typeBdcPrices}
                                optionValue="name"
                                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                                label="Mô tả (*)"
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-form align-items-center ">
                    <div className="col-12 lg:col-4">
                        <DropdownForm
                            id="bdc_price_type_id"
                            value={infos.bdc_price_type_id}
                            onChange={(e) => setInfos({ ...infos, bdc_price_type_id: e.target.value })}
                            options={a()}
                            showClear={false}
                            label="Công thức tính (*)"
                        />
                    </div>
                    <div className="col-12 lg:col-4">
                        <InputForm
                            id="name"
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã bảng giá (*)"
                            required
                        />
                    </div>
                    <div className="mt-2  text-center col-4">
                        <Button
                            type="button"
                            style={{ padding: "12px" }}
                            icon="pi pi-plus"
                            severity="info"
                            raised
                            label="Thêm mới lũy tiến"
                            disabled={infos.bdc_price_type_id === 1}
                            onClick={() => handleAdd()}
                        />
                    </div>
                </div>
                {infos.bdc_price_type_id === 1 && (
                    <div className="grid grid-form w-11">
                        <div className="col-12 lg:col-4">
                            <InputNumberFormV2
                                value={data[0] && data[0].price}
                                handleOnChange={(e) => setData([{ from: 0, to: 0, price: e }])}
                                label="Giá tiền (*)"
                                required
                            />
                        </div>
                    </div>
                )}
                {infos.bdc_price_type_id === 5 && (
                    dataz.map((d, index) => {
                        return (
                            <PriceV2
                                key={index}
                                index={index}
                                handleDelete={handleDelete}
                                handleOnChange={handleOnChange}
                                value={d}
                            />
                        )
                    })
                )}
                <hr/>
                {[2, 3, 4].includes(infos.bdc_price_type_id) &&
                    data &&
                    data[0] &&
                    data.map((d, index) => {
                        return (
                            <Price
                                key={index}
                                index={index}
                                handleDelete={handleDelete}
                                handleOnChange={handleOnChange}
                                value={d}
                            />
                        )
                    })}
            </div>
        </FormUpdate>
    )
}

export default UpdatePrice
