import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import UpdateProjectGeneral from './UpdateProjectGeneral'
import BillingInfo from './BillingInfo'

function UpdateProject(props) {
    const { setVisible, setParams, visible } = props

    return (
        <TabView>
            <TabPanel header="Dự án">
                <UpdateProjectGeneral visible={visible} setVisible={setVisible} setParams={setParams} />
            </TabPanel>
            <TabPanel header="Thông tin thanh toán">
                <BillingInfo setVisible={setVisible} visible={visible} />
            </TabPanel>
        </TabView>
    )
}

export default UpdateProject