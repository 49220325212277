import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import BuildingInfo from './BuildingInfo'
import ContactInfo from './ContactInfo'
import PaymentInfo from './PaymentInfo'
import PaymentMethod from './PaymentMethod'

export default function BuildingOverview() {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Thông tin tòa nhà">
                    <BuildingInfo />
                </TabPanel>
                <TabPanel header="Thông tin liên hệ">
                    <ContactInfo />
                </TabPanel>
                <TabPanel header="Thông tin thanh toán">
                    <PaymentInfo />
                </TabPanel>
                <TabPanel header="Phương thức thanh toán">
                    <PaymentMethod />
                </TabPanel>
            </TabView>
        </div>
    )
}
