import { getData, postData } from '@/lib/request'

export const listApartmentApi = (params) => getData('web2/apartment_management/getListApartment', params)
export const listApartmentV2Api = (params) => getData('web2/info/miniListApartments', params)

export const listOwnerApi = (params) => getData('web2/info/getListOwners', params)
export const countApartmentApi = (params) => getData('web2/apartment_management/countListApartment', params)
export const detailApartmentApi = (params) => getData('web2/apartment_management/detailApartment', params)
export const deleteApartmentApi = (params) => postData('web2/apartment_management/deleteApartments', params)
export const addApartmentApi = (params) => postData('web2/apartment_management/createNewApartment', params, true)
export const updateApartmentApi = (params) => postData('web2/apartment_management/updateBdcApartment', params, true)
export const importApartmentApi = (params) => postData('web2/apartment_management/importApartment', params, false, true)
export const exportApartmentApi = (params) => getData('web2/apartment_management/expApartment', params, true)

export const listGroupApartmentApi = (params) => getData('web2/apartment_management/listGroupApartment', params)
export const addGroupApartmentApi = (params) => postData('web2/apartment_management/createListGroupApartment', params)
export const updateGroupApartmentApi = (params) => postData('web2/apartment_management/updateGroupApartment', params)
export const countGroupApartmentApi = (params) => getData('web2/apartment_management/countListGroupApartment', params)
export const detailGroupApartmentApi = (params) => getData('web2/apartment_management/detailGroupApartment', params)
export const deleteGroupApartmentApi = (params) => postData('web2/apartment_management/deleteGroupApartmentV2', params)
export const exportGroupApartmentApi = (params) => getData('web2/apartment_management/expGroupApartment', params, true)

export const deleteResidentOfApartmentApi = (params) =>
    postData('web2/apartment_management/deleteResidentOfApartment', params)
export const addApartmentToGroupApi = (params) => postData('web2/apartment_management/apartmentToGroup', params)
