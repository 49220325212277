
import { Import } from '@/components'
import { importPrice } from '../api'

const ImportPrice = ({ visible, setVisible, setParams }) => {

      return (
            <Import
                  title="bảng giá"
                  visible={visible}
                  setVisible={setVisible}
                  action={importPrice}
                  template="https://devapibdc.dxmb.vn/import/import_price.xlsx"
                  handleSuccess={() => {
                        setParams(pre => ({ ...pre, render: !pre.render }))
                        setVisible(false)
                  }}
            />
      )
}

export default ImportPrice
