
import { Import } from '@/components'
import { importPromotionApaApi } from '../api'

const ImportPromotionApa = ({ visible, setVisible, setParams }) => {

      return (
            <Import
                  title="ưu đãi căn hộ"
                  visible={visible}
                  setVisible={setVisible}
                  action={importPromotionApaApi}
                  template="https://devapibdc.dxmb.vn/import/import_promotion.xlsx"
                  handleSuccess={() => {
                        setParams(pre => ({ ...pre, render: !pre.render }))
                        setVisible(false)
                  }}
            />
      )
}

export default ImportPromotionApa
