import { getData, postData } from '@/lib/request'

export const listReceiptExpenditureApi = (params) => getData('web2/receipt/getListReceipt', params)

export const countReceiptExpenditureApi = (params) => getData('web2/receipt/countReceipt', params)
export const deleteReceiptExpenditureApi = (params) => postData('web2/receipt/deleteReceipt', params)
export const detailReceiptExpenditureApi = (params) => getData('web2/receipt/getDetailReceipt', params)
export const addReceiptExpenditureApi = (params) => postData('web2/receipt/addReceiptExpenditure', params)
export const updateReceiptExpenditureApi = (params) => postData('web2/receipt/updateReceipt', params)
export const updateListReceiptApi = (params) => postData('web2/receipt/updateReceipt', params)
export const renderReceipt = (params) => getData('web2/receipt/renderReceipt', params)

export const listCoinApi = (params) => getData('web2/info/getListCoin', params)
export const addReceiptPayment = (params) => postData('web2/receipt/addReceiptPayment', params)

//CreateReceipt
export const listDebitReceipt = (params) => getData('web2/receipt/getListDebitReceipt', params)
export const listApartmentName = (params) => getData('web2/receipt/getListApartmentNameReceipt', params)
export const addReceipt = (params) => postData('web2/receipt/addReceipt', params)
export const createCharacters = (params) => postData('web2/receipt/addReceiptCoin', params)
export const listServiceApartments = (params) => getData('web2/info/getListServiceApartment', params)
export const listDebitHasPaid = (params) => getData('web2/receipt/getListDebtHasPaid', params)
export const createAdjustment = (params) => postData('web2/receipt/addReceiptAdjustmentSlip', params)
export const genQrCode = (params) => getData('web2/receipt/genQrPayment', params)

export const exportReceiptApi = (params) => getData('web2/receipt/expListReceipt', params, true)
export const importReceiptApi = (params) => postData('web2/receipt/impReceiptCoin', params, false, true)
export const listAccountApi = (params) => getData('web2/info/getListPaymentInfo', params)

export const addDebtArise = (params) => postData('web2/receipt/addDebtArise', params)
export const getApartmentServiceBySv = (params) => getData('web2/receipt/getApartmentServiceBySv', params)
