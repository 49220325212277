import { useEffect, useState } from 'react'
import {
    listHistoryApi
} from '../api'

export const useListHistory = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listHistoryApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}
