import { getListLogInvoiceApi } from "@/api";
import {
    Body,
    Calendarz,
    Columnz,
    DataTablezV3,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz, TimeBody,
    useGetParams
} from '@/components';
import { statusLogInvoice } from "@/constants";
import { useGetApi } from "@/lib/react-query";
import { useState } from 'react';

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({})
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Inputz
                value={filter.bill_code}
                onChange={(e) => setFilter({ ...filter, bill_code: e.target.value })}
                placeholder="Tìm kiếm theo mã bảng kê"
            />
            <Dropdownz
                options={statusLogInvoice}
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder="Chọn khoảng thời gian"
                className="lg:col-3"
            />
        </GridForm>
    )
}
export default function LogInvoice() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const { data, isLoading } = useGetApi(getListLogInvoiceApi, { ...params, first: undefined }, "logInvoices")

    return (
        <div className="card">
            <HeaderListForm title="Lịch sử phát hành hóa đơn" />
            <Header setParams={setParams} />
            <DataTablezV3
                loading={isLoading}
                value={data?.data?.data?.data}
                title="lịch sử phát hành hóa đơn"
                totalRecords={data?.data?.data?.count}
                params={params}
                setParams={setParams}
                route="/log_invoice"
                basePermissions={["detail"]}
            >
                <Columnz field="other_id" header="Mã hóa đơn" />
                <Columnz field="type" header="Hành động" />
                <Columnz field="message" header="Thông báo" />
                <Columnz header="Thời gian" body={e => TimeBody(e.created_at)} />
                <Columnz body={(e) => Body({
                    data: statusLogInvoice,
                    value: e.status
                })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablezV3>
        </div>
    )
}
