import { postData, getData } from '@/lib/request'

//ListWork
export const listWorkApi = (params) => getData('web2/company/getListCompany', params)
export const countWorkApi = (params) => getData('web2/company/countCompany', params)
export const deletWorkApi = (params) => postData('web2/company/deleteCompany', params)
export const detailWorkApi = (params) => getData('web2/company/getDetailCompany', params)
export const addWorkApi = (params) => postData('/web2/company/addCompany', params)
export const updateWorkApi = (params) => postData('/web2/company/updateCompany', params)

//Manage Doupicate
export const listDoupicateApi = (params) => getData('web2/company/getListCompany', params)
export const countDoupicateApi = (params) => getData('web2/company/countCompany', params)
export const deleteDoupicateApi = (params) => postData('web2/company/deleteCompany', params)
export const detailDoupicateApi = (params) => getData('web2/company/getDetailCompany', params)
export const addDoupicateApi = (params) => postData('/web2/company/addCompany', params)
export const updateDoupicateApi = (params) => postData('/web2/company/updateCompany', params)

//Manage Request
export const listRequestApi = (params) => getData('web2/company/getListCompany', params)
export const countRequestApi = (params) => getData('web2/company/countCompany', params)
export const deleteRequestApi = (params) => postData('web2/company/deleteCompany', params)
export const detailRequestApi = (params) => getData('web2/company/getDetailCompany', params)
export const addRequestApi = (params) => postData('/web2/company/addCompany', params)
export const updateRequestApi = (params) => postData('/web2/company/updateCompany', params)

//Category's work
export const listCategoryApi = (params) => getData('web2/company/getListCompany', params)
export const countCategoryApi = (params) => getData('web2/company/countCompany', params)
export const deleteCategoryApi = (params) => postData('web2/company/deleteCompany', params)
export const detailCategoryApi = (params) => getData('web2/company/getDetailCompany', params)
export const addCategoryApi = (params) => postData('/web2/company/addCompany', params)
export const updateCategoryApi = (params) => postData('/web2/company/updateCompany', params)

//CheckList
export const listCheckListApi = (params) => getData('web2/company/getListCompany', params)
export const countCheckListApi = (params) => getData('web2/company/countCompany', params)
export const deleteCheckListApi = (params) => postData('web2/company/deleteCompany', params)
export const detailCheckListApi = (params) => getData('web2/company/getDetailCompany', params)
export const addCheckListApi = (params) => postData('/web2/company/addCompany', params)
export const updateCheckListApi = (params) => postData('/web2/company/updateCompany', params)
