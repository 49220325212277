import { TabPanel, TabView } from 'primereact/tabview'
import BuildingServices from './BuildingService'
import Listarise from './Listarise'
import VehicleCategory from './VehicleCategory'

function BuildingServicesManagement() {
      return (

            <div className="card">
                  <TabView>
                        <TabPanel header="Danh sách dịch vụ theo tháng">
                              <BuildingServices />
                        </TabPanel>
                        {/*<TabPanel header="Dịch vụ phát sinh">*/}
                        {/*      <Listarise />*/}
                        {/*</TabPanel>*/}
                        <TabPanel header="Loại phương tiện">
                              <VehicleCategory />
                        </TabPanel>
                  </TabView>
            </div>

      )
}

export default BuildingServicesManagement
