import {

      Body, Columnz,
      DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, NumberFormat, StatusBody, useGetParams
} from '@/components'
import { useState } from 'react'

import { useListUnit } from '../../asset_managerment/utils'
import { updateWarehouse } from '../api'
import { useCountWareHouse, useListWarehouse } from '../utils'

import { product_category } from '@/constants'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { useListAsset, useListAssetMix } from '../../asset_mix_management/utils'



const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({ key_search: '' })


      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
                  <Inputz
                        value={filter.key_search}
                        placeholder="Nhập tên sản phẩm"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
                  />
                  <Dropdownz
                        value={filter.type}
                        options={product_category}
                        onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                        placeholder="Chọn phân loại"
                  />
            </GridForm>
      )
}

export default function InventoryManagement() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useListWarehouse({ status: undefined, ...params, first: undefined })
      const unit = useListUnit()
      const navigate = useNavigate()
      const totalRecords = useCountWareHouse({ status: undefined, ...params, first: undefined }) || 0
      const product = useListAsset({ status: undefined, ...params, first: undefined })
      const groups = useListAssetMix({ status: undefined })
      const Total = (rowData) => {
            return (
                  NumberFormat({ value: (Number(rowData.i_amount) + Number(rowData.o_amount)) })
            )
      }


      return (
            <div className='card'>
                  <HeaderListForm title="Quản lý kho" />
                  <Header setParams={setParams} />
                  <div className="flex gap-3 pb-3">
                        <Button label="Tạo Phiếu Nhập" onClick={() => navigate('/inventory_management/create_input_form')} />
                        <Button label="Tạo phiếu xuất" onClick={() => navigate('/inventory_management/create_export_ticket')} />
                  </div>
                  <DataTablez
                        value={data}
                        title="danh sách kho"
                        params={params}
                        setParams={setParams}
                        route="/warehouse_management"
                        headerInfo
                        actionsInfo
                        basePermissions={["detail"]}
                        totalRecords={totalRecords}
                        dataKey="code"
                  >
                        <Columnz field="code" header="Mã sản phẩm trong kho" />
                        <Columnz header="Tên sản phẩm" body={(e) => Body({ data: product, value: e.product_id })} />

                        <Columnz header="Phân loại" body={(e) => {
                              const b = groups.find(d => d.id === e.product_group_id);
                              if (b) {
                                    const category = product_category.find(item => item.id === b.type);
                                    return category ? category.name : "";
                              }
                              return "";
                        }} />
                        <Columnz body={(e) => Body({ data: groups, value: e.product_group_id })} header="Nhóm " />
                        <Columnz field="unit_id" header="Đơn vị"
                              body={(e) => Body({ data: unit, value: e.unit_id })}
                        />

                        <Columnz
                              bodyStyle={{ textAlign: "right" }}
                              field="total"
                              header="Tổng số lượng"
                              body={(rowData) => Total(rowData)}
                        />
                        <Columnz
                              bodyStyle={{ textAlign: "right" }}
                              field="i_amount"
                              header="Số lượng trong kho"
                              body={(e) => NumberFormat({ value: e.i_amount })}
                        />
                        <Columnz
                              bodyStyle={{ textAlign: "right" }}
                              field="o_amount"
                              header="Số lượng đã cấp phát"
                              body={(e) => NumberFormat({ value: e.o_amount })}
                        />
                        <Columnz
                              header="Trạng Thái"
                              headerStyle={{ padding: 'auto', textAlign: 'center' }}
                              className='text-center'
                              body={(e) => StatusBody({ e, route: '/warehouse_management', updateAction: updateWarehouse })}
                        />

                  </DataTablez>
            </div>
      )
}
