import { addGroupApartmentApi, detailGroupApartmentApi, updateGroupApartmentApi } from '@/api'
import { FormUpdateDialog, InputForm, InputTextareaForm, MultiSelectForm } from '@/components'
import { useGetApi } from '@/hooks'
import { getArrId, removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const UpdateGroupApartment = (props) => {
    const { setVisible, setParams, visible } = props
    const groupApartmentDetail = useGetApi(
        detailGroupApartmentApi,
        { id: typeof visible === 'number' ? visible : undefined },
        {},
    )
    const [infos, setInfos] = useState({ name: '' })
    const apartments = useSelector((state) => state.apartments)

    useEffect(() => {
        if (groupApartmentDetail.info && groupApartmentDetail.listApartments) {
            setInfos({
                ...groupApartmentDetail.info,
                name: groupApartmentDetail.info.group_name || '',
                id_apartments: getArrId(groupApartmentDetail.listApartments),
            })
        }
    }, [groupApartmentDetail])
    const handleData = () => {
        let info = { ...infos }
        if (!(info.id_apartments && info.id_apartments[1])) return 'Vui lòng chọn từ 2 căn hộ trở lên!'
        if (info.description && info.description.length > 150) {
            return 'Mô tả không được nhiều hơn 150 kí tự.';
        }
        if (typeof visible === 'number')
            info = { ...removePropObject(info, groupApartmentDetail), groupApartment_id: visible }
        return info
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'nhóm căn hộ'}
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            route={'/apartment_management'}
            actions={{ add: addGroupApartmentApi, update: updateGroupApartmentApi }}
        >
            <InputForm
                id="name"
                value={infos.name}
                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                label="Tên nhóm căn hộ (*)"
                required
            />
            <InputTextareaForm
                id="description"
                value={infos.description}
                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                label="Mô tả"
            />
            <MultiSelectForm
                id="id_apartments"
                value={infos.id_apartments}
                options={apartments}
                onChange={(e) => setInfos({ ...infos, id_apartments: e.target.value })}
                label="Căn hộ (*)"
            />
        </FormUpdateDialog>
    )
}

export default UpdateGroupApartment
