import {
    Columnz, DropdownForm, FormUpdateDialog,
    InputForm,
    InputNumberForm, NumberFormat
} from '@/components'
import { typesV2 } from '@/constants'
import { formatNumber, removeUndefinedProps } from '@/utils'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { updateReceiptImportApi } from '../api'
import { useDetailReceiptImport, useListServices } from '../utils'


const UpdateReceiptImport = (props) => {
    const [infos, setInfos] = useState({
        bdc_apartment_id: '',
        customer_name: '',
        type_payment: '',
        cost: '',
        type: '',
    })
    const [evalue, setEvalue] = useState([])
    const [type, setType] = useState(2)
    const [listDebit, setListDebit] = useState([])
    const [apartmentId, setApartmentId] = useState(null)
    const [error, setError] = useState(null)

    const { setVisible, params, setParams, visible, convertTypePayment } = props
    const detailReceiptImport = useDetailReceiptImport(typeof visible === 'number' ? visible : undefined, apartmentId)
    const apartments = useSelector((state) => state.apartments)
    const data = detailReceiptImport.listDebit
    const services = useListServices({ bdc_apartment_id: apartmentId })

    // useEffect(() => {
    //     if (data) {
    //         setEvalue(data)
    //     }
    // }, [data])

    useEffect(() => {
        if (detailReceiptImport.id) {
            setInfos({
                ...detailReceiptImport,
            })
        }
    }, [detailReceiptImport])
    const handleAddAdditionalAmount = () => {
        const newCondition = { bdc_apartment_service_price_id: '', coin: null, type: 1 }
        setListDebit([...listDebit, newCondition])
    }
    const handleRemoveCondition = (index) => {
        setListDebit(listDebit.filter((_, idx) => idx !== index))
    }
    const handleServiceChange = (value, index) => {
        const updatedListDebit = [...listDebit]
        updatedListDebit[index].bdc_apartment_service_price_id = value
        setListDebit(updatedListDebit)
    }
    const handleAmountChange = (value, index) => {
        const updatedListDebit = [...listDebit]
        updatedListDebit[index].coin = value
        setListDebit(updatedListDebit)
    }
    useEffect(() => {
        if (evalue) {
            setInfos({
                ...infos,
                ...removeUndefinedProps(evalue),
                id: visible,
            })
        }
    }, [evalue])

    useEffect(() => {
        let total = 0
        evalue.forEach((item) => {
            const newPaid = item.new_paid ? item.new_paid : 0
            const coin = item.coin ? item.coin : 0
            total += newPaid + coin
        })
        listDebit.forEach((item) => {
            total += Number(item.coin)
        })
        const remainingAmount = Number(infos.cost) - total
        setInfos((pre) => ({ ...pre, tt: remainingAmount }))
        if (remainingAmount < 0) {
            setError('Tổng số tiền hạch toán không được lớn hơn số tiền của bạn')
        } else {
            setError(null)
        }
    }, [evalue, infos.cost, listDebit])

    useEffect(() => {
        if (data && data[0]) {
            setEvalue(data.map((item) => ({ ...item, type: item.type || 2 })))
            setType(data.some((item) => item.type !== undefined) ? type : 2)
        }
    }, [data])
    const handleData = () => {
        let surplusObject = {}
        if (infos.tt > 0) {
            surplusObject = {
                bdc_apartment_service_price_id: 0,
                type: 1,
                coin: infos.tt,
            }
        }
        if (infos.tt < 0) {
            return 'Tổng số tiền hạch toán không được lớn hơn số tiền của bạn'
        }
        const valiListDebit = listDebit.filter(item => item !== 0 && item !== '')
        const valiListEvalue = evalue.filter(item => item !== 0 && item !== '')
        const params = {
            apartment_id: infos.bdc_apartment_id === null ? apartmentId : infos.bdc_apartment_id,
            detail: surplusObject.type === 0 ? [surplusObject, ...valiListEvalue, ...valiListDebit] : [...valiListEvalue, ...listDebit],
            id: visible,
            account_balance: infos.tt,
        }
        if (params.apartment_id === null) {
            return 'Bạn chưa chọn căn hộ'
        }
        if (setParams) {
            setParams((pre) => {
                return { ...pre, render: !pre.render }
            })
        }
        return params
    }
    const handleInputChange = (e, rowData, evalue, setEvalue, tt, setError) => {
        const inputValue = Number(e)
        const index = evalue.findIndex((debit) => debit.id === rowData.id)
        if (index !== -1) {
            const newFirst = [...evalue]
            newFirst[index] = {
                ...newFirst[index],
                new_paid: inputValue,
            }
            setEvalue(newFirst)
        }
    }
    const handleChange = (index, field, value) => {
        setEvalue((prevEvalue) => {
            const updatedEvalue = [...prevEvalue]
            updatedEvalue[index] = {
                ...updatedEvalue[index],
                [field]: Number(value),
            }
            return updatedEvalue
        })
    }
    useEffect(() => {
        const selectedApartment = apartments.find(apartment => apartment.id === apartmentId);
        if (selectedApartment && selectedApartment.name_customer) {
            setInfos(prevInfos => ({
                ...prevInfos,
                customer_name: selectedApartment.name_customer
            }));
        }
    }, [apartments, apartmentId]);

    return (
        <FormUpdateDialog
            title={typeof visible === 'number' && 'Xác nhận giao dịch của căn hộ'}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            route="/receipt_import"
            refreshObjects={[setInfos]}
            handleData={handleData}
            actions={{ update: updateReceiptImportApi }}
            disabled={infos.status === "da_hach_toan" ? true : false}
        >
            <div className="card bg-color">
                <div className="grid grid-form ">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.customer_name}
                            onChange={(e) => setInfos({ ...infos, customer_name: e.target.value })}
                            label="Chủ hộ"
                            disabled
                        />
                        <DropdownForm
                            id="bdc_apartment_id"
                            value={infos.bdc_apartment_id === null ? apartmentId : infos.bdc_apartment_id}
                            options={apartments}
                            label="Căn hộ"
                            disabled={infos.status === "da_hach_toan" ? true : false || infos.bdc_apartment_id === null ? false : true}
                            onChange={(e) =>
                                infos.bdc_apartment_id === null
                                    ? setApartmentId(e.target.value)
                                    : setInfos({ ...infos, name: e.target.value })
                            }
                        />
                        <DropdownForm
                            id="building_place_id"
                            value={infos.type}
                            options={typesV2}
                            disabled
                            label="Kiểu phiếu"
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputForm id="cost" value={formatNumber(infos.cost)} label="Số tiền" disabled />
                        <InputForm
                            id="name"
                            value={convertTypePayment(infos.type_payment)}
                            label="Hình thức"
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <DataTable
                        value={evalue.filter((item) => item.type !== 1)}
                        title="hạch toán"
                        route="/receipt_import"
                    >
                        <Columnz field="name" header="Dịch vụ" />
                        <Columnz header="Sản phẩm" />
                        <Columnz field="created_at" header="Thời gian" />
                        <Columnz field="sumery" header="Phát sinh" />
                        <Columnz
                            className='text-right'
                            body={(e) => NumberFormat({ value: e.sumery - e.paid })} header="Phải trả" />
                        <Columnz
                            body={(rowData) => (
                                <>
                                    <InputNumberForm
                                        className="w-full"
                                        value={rowData?.new_paid}
                                        disabled={infos.status === "da_hach_toan" ? true : false}
                                        handleOnChange={(e) =>
                                            handleInputChange(e, rowData, evalue, setEvalue, infos.tt, setError)
                                        }
                                    />
                                    {error && (
                                        <p className="text-sm pl-1" style={{ color: 'red' }}>
                                            {error}
                                        </p>
                                    )}
                                </>
                            )}
                            header="Số tiền hạch toán"
                        />
                    </DataTable>
                    {evalue.map((item, index) => (
                        <div key={index}>
                            {item.type === 1 && (
                                <div className="grid grid-form align-items-center">
                                    <div className="col-12 lg:col-5 ">
                                        <DropdownForm
                                            value={item.bdc_apartment_service_price_id}
                                            options={services}
                                            placeholder="Chọn dịch vụ"
                                            onChange={(e) =>
                                                handleChange(index, 'bdc_apartment_service_price_id', e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="col-12 lg:col-5 ">
                                        <InputNumberForm
                                            lable="Nhập số tiền"
                                            value={item.coin}
                                            handleOnChange={(e) => handleChange(index, 'coin', e)}
                                            placeholder="Nhập số tiền"
                                            className="w-full"
                                        />
                                        {error && (
                                            <p className="text-sm pl-1" style={{ color: 'red' }}>
                                                {error}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-12 lg:col-2 ">
                                        <Button type="button" icon="pi pi-times" severity="info" />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                    {listDebit.map((d, index) => (
                        <div className="grid grid-form align-items-center" key={index}>
                            <div className="col-12 lg:col-5 ">
                                <DropdownForm
                                    value={d.bdc_apartment_service_price_id}
                                    options={services}
                                    placeholder="Chọn dịch vụ"
                                    onChange={(e) => handleServiceChange(e.target.value, index)}
                                />
                            </div>
                            <div className="col-12 lg:col-5 ">
                                <InputNumberForm
                                    lable="Nhập số tiền"
                                    value={d.coin}
                                    handleOnChange={(e) => handleAmountChange(e, index)}
                                    placeholder="Nhập số tiền"
                                    className="w-full"
                                />
                                {error && (
                                    <p className="text-sm pl-1" style={{ color: 'red' }}>
                                        {error}
                                    </p>
                                )}
                            </div>
                            <div className="col-12 lg:col-2 ">
                                <Button
                                    type="button"
                                    icon="pi pi-times"
                                    severity="info"
                                    onClick={() => handleRemoveCondition(index)}
                                />
                            </div>
                        </div>
                    ))}
                    <InputNumberForm label="Tổng tiền thừa" value={infos.tt} disabled />
                    <Button
                        type="button"
                        className="mt-3"
                        label="Thêm chỉ định tiền thừa"
                        onClick={handleAddAdditionalAmount}
                        disabled={infos.status === "da_hach_toan" ? true : false}
                    />
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateReceiptImport
