import { useEffect, useState } from 'react'
import { countPostsCustomerApi, detailPostsCustomerApi, listPostsCustomerApi } from '../api'

export const useListPostsCustomer = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPostsCustomerApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountPostsCustomer = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPostsCustomerApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPostsCustomer = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPostsCustomerApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
