import { getData, postData } from '@/lib/request'

export const listReceiptApi = (params) => getData('web2/deposit_list/getListReceipt', params)
export const listDepositApi = (params) => getData('web2/deposit_list/getListDeposit', params)
export const countReceiptApi = (params) => getData('web2/deposit_list/countReceipt', params)
export const deleteReceiptApi = (params) => postData('web2/deposit_list/deleteReceipt', params)
export const detailReceiptApi = (params) => getData('web2/deposit_list/getDetailReceipt', params)
export const addReceiptApi = (params) => postData('/web2/deposit_list/addReceipt', params)
export const addReceiptPaymentDeposit = (params) => postData('/web2/deposit_list/addReceiptPaymentDeposit', params)
export const updateReceiptApi = (params) => postData('/web2/deposit_list/updateReceipt', params)
