import { useEffect, useState } from 'react'
import { countCardApi, detailCardApi, listCardApi, listUserCreate, lisTypeVehicleApi } from '../api'

export const useListCard = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCardApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListVehicleType = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await lisTypeVehicleApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListUserCreate = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listUserCreate({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountCard = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countCardApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailCard = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailCardApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
