import { countGroupApartmentApi, deleteGroupApartmentApi, exportGroupApartmentApi, listGroupApartmentApi } from '@/api'
import { Columnz, DataTablez, Dropdownz, GridForm, Inputz, useGetParams } from '@/components'
import { useGetApi } from '@/hooks'
import { useState } from 'react'
import UpdateGroupApartment from './UpdateGroupApartment'
import { useSelector } from 'react-redux'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên nhóm căn hộ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                options={apartments}
                value={filter.apartment_id}
                placeholder="Tìm kiếm theo căn hộ"
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
            />
        </GridForm>
    )
}

const GroupApartment = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listGroupApartmentApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countGroupApartmentApi, { ...params, first: undefined }, 0)
    const [visible, setVisible] = useState(false)

    return (
        <>
            {visible && <UpdateGroupApartment setParams={setParams} visible={visible} setVisible={setVisible} />}
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="nhóm căn hộ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                setVisibledDialog={setVisible}
                route="/apartment_management"
                basePermissions={['insert', 'detail', 'delete', 'export']}
                headerInfo={{ exportApi: exportGroupApartmentApi }}
                actionsInfo={{ deleteAction: deleteGroupApartmentApi }}
                hideParams
            >
                <Columnz field="group_name" header="Tên nhóm" />
                <Columnz field="apartment_names" header="Căn hộ" />
                <Columnz field="description" header="Ghi chú" />
            </DataTablez>
        </>
    )
}

export default GroupApartment
