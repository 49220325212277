import { listExchangeV2Api } from '@/api';
import { Body, Calendarz, Columnz, Dropdownz, GridForm, HeaderListForm, Inputz, TimeBody, useGetParams } from '@/components';
import { useGetApi } from '@/hooks';
import { DataTablez } from '@/modules/parking_supervision/parking_check/screens/ParkingForm';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useListLog } from './../utils/index';

const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({})
      const users = useSelector(state => state.users);
      const exchanges = useGetApi(listExchangeV2Api, {}, [])

      const userExChanges = users.filter(u => u.department_id === filter.department_id)

      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
                  <Dropdownz
                        value={filter.department_id}
                        onChange={(e) => setFilter({ ...filter, department_id: e.target.value })}
                        placeholder="Phòng ban"
                        options={exchanges}
                  />
                  <Dropdownz
                        value={filter.user_id}
                        onChange={(e) => setFilter({ ...filter, user_id: e.target.value })}
                        placeholder="Nhân viên"
                        options={filter.department_id ? userExChanges : users}
                        optionLabel="full_name"
                        optionValue="user_id"
                  />
                  <Dropdownz
                        value={filter.code}
                        options={filter.department_id ? userExChanges : users}
                        onChange={(e) => setFilter({ ...filter, code: e.target.value })}
                        placeholder="Trạng thái"
                        optionLabel="code"
                  />
                  <Calendarz
                        value={filter.dateq}
                        onChange={(e) => setFilter({ ...filter, dateq: e.value })}
                        placeholder={"Chọn khoảng thời gian "}
                        selectionMode="range"
                  />
            </GridForm>
      )
}
function LogKeeping() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useListLog({ status: undefined, ...params, first: undefined })
      const exchanges = useGetApi(listExchangeV2Api, {}, [])
      const users = useSelector(state => state.users);
      return (
            <div className='card'>
                  <HeaderListForm title="Lịch sử chấm công" />
                  <Header setParams={setParams} />
                  <DataTablez
                        title="công lương"
                        params={params}
                        setParams={setParams}
                        route="/log_timekeeping"
                        value={data.data}
                        totalRecords={data.count}
                  >
                        <Columnz header="Mã ca" field="shift_code" />
                        <Columnz header="Mã NV" body={(e) => Body({ data: users, value: e.by, label: 'code', key: 'user_id' })} />
                        <Columnz header="Họ và Tên" body={(e) => Body({ data: users, value: e.by, label: 'full_name', key: 'user_id' })} />
                        <Columnz header="Phòng ban"
                              body={(e) => Body({ data: exchanges, value: e.department_id, })}
                        />
                        <Columnz header="Ngày " body={(e) => TimeBody(e.date, 'date')} />
                        <Columnz header="Giờ " field="time" />
                        <Columnz header="Kiểu" field="type" />
                  </DataTablez>
            </div>
      )
}

export default LogKeeping
