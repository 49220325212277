import { countCompanyApi, listCompanyApi, updateCompanyApi } from '@/api'
import {
    Columnz,
    DataTablez, Dropdownz,
    GridForm, HeaderListForm, Inputz, StatusBody, TimeBody,
    useGetParams
} from '@/components'
import { status } from '@/constants'
import { useGetApi } from '@/hooks'
import { useState } from 'react'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

const Companies = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listCompanyApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countCompanyApi, { ...params, first: undefined }, 0)

    return (
        <div className="card">
            <HeaderListForm title="Danh sách công ty" />
            <Header setParams={setParams} />
            <DataTablez
                title="công ty"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/company"
                headerInfo
                actionsInfo
                basePermissions={["insert", "detail"]}
            >
                <Columnz field="name" header="Tên công ty" />
                <Columnz field="address" header="Địa chỉ" />
                <Columnz field="phone_number" header="Hotline" />
                <Columnz field="email" header="Email" />
                <Columnz header="Thời gian tạo" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/company', updateAction: updateCompanyApi })}
                />
            </DataTablez>
        </div>
    )
}

export default Companies
