import {
    ActionBody,
    Body,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    TimeBody,
    useGetParams
} from '@/components'
import { listVehicleService } from '@/constants'
import { formatNumber } from '@/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useListVehicleCategories } from '../../building_services/utils'
import { deleteVehicleApi, exportVehicleApi, swithcStatusVehicleApi } from '../api'
import { useCountVehicle, useDetailVehicle, useListVehicle } from '../utils'
import ExportVehicle from './ExportVehicle'
import ImportVehicle from './ImportVehicle'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const buildings = useSelector((state) => state.buildings)
    const apartments = useSelector((state) => state.apartments)
    const listVehicleCategories = useListVehicleCategories()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên phương tiện, biển số"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.building_place_id}
                placeholder="Chọn tòa nhà"
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
            />
            <Dropdownz
                value={filter.bdc_apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, bdc_apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                placeholder="Loại phương tiện"
                value={filter.vehicle_category_id}
                options={listVehicleCategories}
                onChange={(e) => setFilter({ ...filter, vehicle_category_id: e.target.value })}
            />
        </GridForm>
    )
}

const ListVehicleManage = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListVehicle({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountVehicle({ status: undefined, ...params, first: undefined }) || 0
    const [visibleImport, setVisibleImport] = useState(false)
    const apartments = useSelector((state) => state.apartments)
    const { id } = useParams()
    const resident = useSelector((state) => state.residents)
    const users = useSelector((state) => state.users)
    const vehicleDetail = useDetailVehicle(id)
    const listVehicleCategories = useListVehicleCategories()
    const [visibleExportV2, setVisibleExportV2] = useState(false)
    const [infos, setInfos] = useState({
        apartment_name: '',
        building_place_id: '',
        floor: '',
        area: '',
        description: '',
        code_electric: '',
        code_water: '',
        status: '',
        code: '',
        code_customer: '',
        name_customer: '',
    })
    const moreOption3Pha = [
        {
            label: 'Biến động phương tiện theo loại',
            icon: 'pi pi-download',
            command: () => setVisibleExportV2(true)
        }
    ]
    const MapUsers = (rowData) => {
        if (typeof rowData.users === 'string') {
            const usersArray = JSON.parse(rowData.users)
            const userNames = usersArray.map((userId) => {
                const user = resident.find((item) => item.id === userId)
                return user ? user.full_name : 'Không tìm thấy'
            })
            if (Array.isArray(usersArray)) {
                const userNamesString = userNames.join(', ')
                return userNamesString
            }
        }
    }
    useEffect(() => {
        if (vehicleDetail.id)
            setInfos({ ...infos, ...vehicleDetail, status: vehicleDetail.status === 0 ? false : true })
    }, [vehicleDetail])
    const BodyUpdate = (rowData) => {
        const updatedBy = users.find(e => e.user_id === rowData.updated_by);
        const name = updatedBy?.full_name || 'Admin'
        return (
            <div className='text-center'>
                <div>
                    {name}
                </div>
                <div>
                    {TimeBody(rowData.updated_at)}
                </div>
            </div>
        )
    }
    return (
        <div className={"card"}>
            {visibleImport && <ImportVehicle visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            {visibleExportV2 && <ExportVehicle visible={visibleExportV2} setVisible={setVisibleExportV2} setParams={setParams} />}
            <HeaderListForm title="Danh sách phương tiện" />
            <Header setParams={setParams} />

            <DataTablez
                title="phương tiện"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/vehicle_manage"
                headerInfo={{ setVisibleImport: setVisibleImport, exportApi: exportVehicleApi, moreOptions: moreOption3Pha }}
                // actionsInfo={{ deleteAction: deleteVehicleApi }}
                basePermissions={["insert", "detail", "delete", "import", "export", "export", "export1",]}
                rowClassName={(rowData) => rowData.deleted_at ? 'deleted-row' : ''}

            >
                <Columnz field="name" header="Tên phương tiện" />
                <Columnz
                    header="Loại"
                    body={(e) => Body({ data: listVehicleService, value: e.type_vehicle })}
                />
                <Columnz field="number" header="Biển số" />
                <Columnz field="code" header="Mã thẻ" />
                <Columnz header="Căn hộ" body={(e) => Body({ data: apartments, value: e.bdc_apartment_id })} />
                <Columnz
                    header="Chủ phương tiện"
                    body={(e) => Body({ data: resident, value: e.user_id, label: 'full_name', key: 'user_id' })}
                />
                {/* <Columnz
                    header="Người sử dụng"
                    body={(rowData) => MapUsers(rowData)}
                    bodyStyle={{ minWidth: '12rem' }}
                /> */}
                <Columnz field="order" header="Mức ưu tiên" />
                <Columnz body={e => formatNumber(e.price)} header="Phí áp dụng" />
                <Columnz body={(e) => TimeBody(e.first_time_active, 'date')} header="Ngày bắt đầu" />
                <Columnz body={(e) => TimeBody(e.finish, 'date')} header="Ngày kết thúc" />
                <Columnz field="description" header="Ghi chú" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => e.deleted_at ? StatusBody({ e }) :

                        StatusBody({ e, route: '/vehicle_manage', updateAction: swithcStatusVehicleApi })
                    }
                />
                <Columnz body={(e) => TimeBody(e.created_at, 'date')} header="Ngày vào" />
                <Columnz body={e => BodyUpdate(e)} header="Người cập nhật" />
                <Columnz
                    header="Thao tác"
                    body={(e) => {
                        if (e.deleted_at) {
                            return null;
                        }
                        return (
                            <ActionBody
                                id={e.id}
                                route="/vehicle_manage"
                                deleteAction={deleteVehicleApi}
                                handleDelete={(id) => {
                                    return { id: id }
                                }}
                                params={params}
                                setParams={setParams}
                                basePermissions={["delete", "detail"]}
                            />
                        );
                    }}
                />

            </DataTablez>
        </div>
    )
}

export default ListVehicleManage
