import { useEffect, useState } from 'react'
import {
    countBookingApi,
    countPartnerUtilitiesApi,
    countUtilitiesApartmentApi, detailBookingApi,
    detailBookingTimePlaceApi,
    detailPartnerUtilitiesApi,
    detailUtilitiesAparmentApi,
    listBookingRegisteredApi,
    listBookingTimePlaceApi,
    listCommnentBookingApi,
    listPartnerUtilitiesApi,
    listUtilitiesApartmentApi
} from '../api'

export const useListUtilities = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listUtilitiesApartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

export const useBookingRegistered = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBookingRegisteredApi({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

export const useListBookingComment = (id, render) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await listCommnentBookingApi({ post_id: id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, render])
    return data
}

export const useCountUtilitiesApartment = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countUtilitiesApartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

export const useDetailUtilities = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailUtilitiesAparmentApi({ id: id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return data
}

export const useListPartnerUtilities = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPartnerUtilitiesApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

export const useCountPartnerUtilities = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPartnerUtilitiesApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPartnerUtilities = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPartnerUtilitiesApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return data
}
export const useListBookingTimePlace = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBookingTimePlaceApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

export const useListUtilitiesApartmentApi = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listUtilitiesApartmentApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

export const useDetailBooking = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailBookingApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return data
}

export const useDetailBookingTimePlace = (id, render) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailBookingTimePlaceApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, render])
    return data
}

export const useCountBooking = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBookingApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(params)])
    return data
}

// export const useListOwner = (params) => {
//     const [data, setData] = useState([])
//     async function fetchData() {
//         const response = await listOwnerApi({ status: 1, ...params })
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         fetchData()
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [JSON.stringify(params)])
//     return data
// }

// export const useListGroupApartment = (params) => {
//     const [data, setData] = useState([])
//     async function fetchData() {
//         const response = await listGroupApartmentApi({ status: 1, ...params })
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         fetchData()
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [JSON.stringify(params)])
//     return data
// }

// export const useCountGroupApartment = (params) => {
//     const [data, setData] = useState(0)
//     async function fetchData() {
//         const response = await countGroupApartmentApi({ status: 1, ...params })
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         fetchData()
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [JSON.stringify(params)])
//     return data
// }

// export const useDetailGroupApartment = (id) => {
//     const [data, setData] = useState({})
//     async function fetchData() {
//         const response = await detailGroupApartmentApi({ id })
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         if (id) fetchData()
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [id])
//     return data
// }
