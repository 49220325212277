import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import ListWork from './ListWork'
import ManageDouplicate from './ManageDouplicate'
import ManageRequest from './ManageRequest'
import Category from './Category'
import CheckList from './CheckList'

export default function WorkManagement() {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Danh sách công việc">
                    <ListWork />
                </TabPanel>
                <TabPanel header="Quản lý công việc lặp lại">
                    <ManageDouplicate />
                </TabPanel>
                <TabPanel header="Quản lý yêu cầu">
                    <ManageRequest />
                </TabPanel>
                <TabPanel header="Danh mục công việc">
                    <Category />
                </TabPanel>
                <TabPanel header="Check list">
                    <CheckList />
                </TabPanel>
            </TabView>
        </div>
    )
}
