import { getListLogInvoiceApi } from "@/api";
import {
    Body, Calendarz,
    Columnz,
    DataTablezV3,
    Dialogz, Dropdownz, GridForm
} from '@/components';
import { statusLogInvoice } from "@/constants";
import { databaseDate } from "@/lib";
import { useGetApi } from "@/lib/react-query";
import { useSocket } from "@/modules/socket/SocketConnection";
import { ProgressSpinner } from "@/uiCore";
import { checkJson } from "@/utils";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = ({ setParams, from }) => {
    const [filter, setFilter] = useState(() => {
        if (from) return { dates: [from] }
        else return {}
    })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className={"lg:col-6"}>
            <Dropdownz
                options={statusLogInvoice}
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder="Chọn khoảng thời gian"
                className="lg:col-3"
            />
        </GridForm>
    )
}

export default function LogInvoice(prop) {
    const { visible, setVisible, from, setFrom } = prop
    const item = useSelector(state => state.item);
    const building_id = item.building_id
    const [params, setParams] = useState(() => {
        let params = { page: 1, limit: 10, render: false, first: 0 }
        if (from) params = { ...params, from: databaseDate(from), to: databaseDate(from, true) }
        return params
    })
    const { data, isLoading } = useGetApi(getListLogInvoiceApi, { ...params, first: undefined }, "logInvoices")
    const services = useSelector(state => state.services);
    const apartments = useSelector(state => state.apartments);

    const socket = useSocket();
    useEffect(() => {
        if (socket) {
            const handleServerData = (data) => {
                console.log('Received data from server:', data);
            }
            socket.on('abc', handleServerData);
            const key = 'change_status_invoice_' + building_id;
            const handleChangeStatusCal = (data) => {
                setParams(pre => ({ ...pre, render: !pre.render }))
            };
            socket.on(key, handleChangeStatusCal);
            return () => {
                socket.off('abc', handleServerData);
                socket.off(key, handleChangeStatusCal);
            };
        }
    }, [socket]);

    return (
        <Dialogz title={"Lịch sử phát hành hóa đơn"} visible={Boolean(visible)} onHide={() => {
            setFrom(null);
            setVisible(false)
        }} width="1400px">
            <div className="card mt-4" style={{ minHeight: '600px' }}>
                <Header from={from} setParams={setParams} />
                <DataTablezV3
                    loading={isLoading}
                    value={data?.data?.data?.data}
                    title="lịch sử phát hành hóa đơn"
                    totalRecords={data?.data?.data?.count || 0}
                    params={params}
                    setParams={setParams}
                    route="/log_invoice"
                    basePermissions={["detail"]}
                    hideParams
                >
                    <Columnz header="Mã hóa đơn" body={e => {
                        const params = checkJson(e.params) || e.params
                        if (e.status === "success") return <Link
                            to={`/invoice?bdc_apartment_id=${params?.bdc_apartment_id}&cycle_name=${params?.cycle_name}`}>{e.other_id}</Link>
                        else return e.other_id
                    }} />
                    <Columnz field="bill_code" header="Mã bảng kê" />
                    <Columnz field="cycle_name" header="Kỳ" />
                    <Columnz header="Căn hộ" body={e => Body({ data: apartments, value: e.bdc_apartment_id })} />
                    <Columnz header="Dịch vụ" body={e => {
                        let data = checkJson(e.services) || e.services
                        if (!Array.isArray(data)) data = [data]
                        if (Array.isArray(data)) {
                            return <div className={'flex flex-column gap-2 text-left'}>
                                {data.map((s, index) => {
                                    const service = services.find(sv => sv.id === s)
                                    return service?.name ? <span key={index}>{index + 1}. {service?.name}</span> : <></>
                                })}
                            </div>
                        }
                    }} />
                    <Columnz field="message" header="Thông báo" />
                    <Columnz
                        header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                        className='text-center'
                        body={(e) => {
                            if (e.status) return Body({
                                data: statusLogInvoice,
                                value: e.status
                            })
                            else return <ProgressSpinner
                                style={{ width: '15px', height: '15px' }}
                                strokeWidth="7"
                                animationDuration="1s"
                            />
                        }}
                    />
                </DataTablezV3>
            </div>
        </Dialogz>
    )
}
