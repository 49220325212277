import { useEffect, useState } from 'react'
import { Calendarz, Dropdownz, GridForm, useGetParams, HeaderListForm } from '@/components'
import { status } from '@/constants'
import { Chart } from 'primereact/chart'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Công ty"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Tòa nhà"
            />
            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="Tháng" />
        </GridForm>
    )
}
function WorkReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartDataStacked, setChartDataStacked] = useState({})
    const [chartOptionsStacked, setChartOptionsStacked] = useState({})
    const [chartDataPie, setChartDataPie] = useState({})
    const [chartOptionsPie, setChartOptionsPie] = useState({})
    const [chartDataPie2, setChartDataPie2] = useState({})
    const [chartOptionsPie2, setChartOptionsPie2] = useState({})
    const [chartDataPie3, setChartDataPie3] = useState({})
    const [chartOptionsPie3, setChartOptionsPie3] = useState({})

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: [
                'Toà 17T1',
                'Toà 17T2',
                'Toà 17T3',
                'Toà 15T',
                'Toà 34T',
                'Toà 24T1',
                'Toà 17T4',
                'Toà 17T5',
                'Toà 17T6',
                'Toà 18T1',
                'Toà Bohemia',
                'Toà Vinata Tower',
                'Toà VinaConex 1',
                'New House Xala',
                'Nhà Ga T2',
                'N05 Hoàng.Đ.T',
            ],
            datasets: [
                {
                    type: 'bar',
                    label: 'Hoàn thành',
                    backgroundColor: ['rgba(22, 91, 170, 1)'],
                    barThickness: 40,
                    data: [540, 325, 702, 620, 456, 789, 620, 567, 124, 231, 321, 600, 876, 976, 124, 253],
                },
                {
                    type: 'bar',
                    label: 'Đang thực hiện',
                    backgroundColor: ['rgba(85, 185, 95, 1)'],
                    barThickness: 40,
                    data: [100, 200, 186, 122, 145, 251, 243, 452, 124, 231, 321, 125, 134, 234, 542, 104],
                },
                {
                    type: 'bar',
                    label: 'Quá hạn',
                    backgroundColor: ['rgba(255, 6, 6, 1)'],
                    barThickness: 40,
                    data: [322, 124, 342, 452, 124, 420, 124, 314, 214, 231, 321, 147, 253, 142, 124, 253],
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,

            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                    },
                },

                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        }

        setChartDataStacked(data)
        setChartOptionsStacked(options)
    }, [])

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: ['Vệ sinh', 'Kĩ thuật', 'Bảo vệ'],
            datasets: [
                {
                    data: [35, 60, 5],
                    backgroundColor: ['rgba(243, 163, 44, 1)', 'rgba(22, 91, 169, 1)', 'rgba(42, 42, 42, 1)'],
                    hoverBackgroundColor: ['rgba(243, 163, 44, .8)', 'rgba(22, 91, 169, .8)', 'rgba(42, 42, 42, .8)'],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie(data)
        setChartOptionsPie(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: ['Đang thực hiện', 'Hoàn thành', 'Quá hạn'],
            datasets: [
                {
                    data: [20, 65, 15],
                    backgroundColor: ['rgba(85, 185, 95, 1)', 'rgba(22, 91, 169, 1)', 'rgba(250, 120, 0, 1)'],
                    hoverBackgroundColor: ['rgba(85, 185, 95, .8)', 'rgba(22, 91, 169, .8)', 'rgba(250, 120, 0, .8)'],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        type: 'pointLabel',
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie2(data)
        setChartOptionsPie2(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: ['Vệ sinh', 'Bảo hành', 'Sự kiện', 'Bảo trì', 'Kiểm tra', 'Khác'],
            datasets: [
                {
                    data: [5, 10, 15, 45, 10, 15],
                    backgroundColor: [
                        'rgba(243, 163, 44, 1)',
                        'rgba(22, 91, 169, 1)',
                        'rgba(85, 185, 95, 1)',
                        'rgba(250, 120, 0, 1)',
                        'rgba(255, 6, 6, 1)',
                        'rgba(42, 42, 42, 1)',
                    ],
                    hoverBackgroundColor: [
                        'rgba(243, 163, 44, .8)',
                        'rgba(22, 91, 169, .8)',
                        'rgba(85, 185, 95, .8)',
                        'rgba(250, 120, 0, .8)',
                        'rgba(255, 6, 6, .8)',
                        'rgba(42, 42, 42, .8)',
                    ],
                },
            ],
        }
        const options = {
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                    },
                },
            },
        }

        setChartDataPie3(data)
        setChartOptionsPie3(options)
    }, [])

    return (
        <>
            <div className="card">
                <HeaderListForm title="Tổng hợp công việc" />
                <Header setParams={setParams} />
                <Chart type="bar" data={chartDataStacked} options={chartOptionsStacked} />
            </div>
            <div className="card">
                <div class="grid ">
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center  border-round-sm shadow-4 h-full font-bold">
                            <div className="shadow-1">
                                <div className="p-3 m-3">
                                    <h5>Công việc theo phòng ban</h5>
                                    <div className="flex align-items-center justify-content-between">
                                        <p className="m-0 opacity-80">All</p>
                                        <span className="text-xs border-1 p-1 border-round-sm border-gray-500 px-2">
                                            06 - 2023
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Chart
                                style={{ margin: '0 auto' }}
                                type="pie"
                                data={chartDataPie}
                                options={chartOptionsPie}
                                className="w-full md:w-22rem"
                            />
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center  border-round-sm shadow-4  h-full font-bold">
                            <div className="shadow-1">
                                <div className="p-3 m-3">
                                    <h5>Công việc theo tiến độ</h5>
                                    <div className="flex align-items-center justify-content-between">
                                        <p className="m-0 opacity-80">All</p>
                                        <span className="text-xs border-1 p-1 border-round-sm border-gray-500 px-2">
                                            06 - 2023
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Chart
                                style={{ margin: '0 auto' }}
                                type="pie"
                                data={chartDataPie2}
                                options={chartOptionsPie2}
                                className="w-full md:w-22rem "
                            />
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="text-center  border-round-sm shadow-4 h-full font-bold">
                            <div className="shadow-1">
                                <div className="p-3 m-3">
                                    <h5>Công việc theo phân loại</h5>
                                    <div className="flex align-items-center justify-content-between">
                                        <p className="m-0 opacity-80">All</p>
                                        <span className="text-xs border-1 p-1 border-round-sm border-gray-500 px-2">
                                            06 - 2023
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Chart
                                style={{ margin: '0 auto' }}
                                type="pie"
                                data={chartDataPie3}
                                options={chartOptionsPie3}
                                className="w-full md:w-23rem "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkReport
