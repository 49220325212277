import { DropdownForm, FormUpdateDialog, InputForm, InputNumberFormV2, InputTextareaForm, UploadImg, UploadMultiImage } from '@/components'
import { TemplateEmail, status, statusV2 } from '@/constants'
import { checkJson, removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useGetApi } from '@/hooks'
import { addProjectApi, detailProjectApi, listExchangeV2Api, updateProjectApi } from '@/api'

const initInfos = {
    name: '',
    building_code: '',
    bdc_department_id: '',
    address: '',
    description: ' ',
    email: '',
    phone: '',
    manager_id: '',
    day_lock_cycle_name: 5,
    template_mail: '',
    debit_active: '',
    config_menu: '',
    area: ''
}
const UpdateProjectGeneral = (props) => {
    const { setVisible, setParams, visible, users } = props
    const detail = useGetApi(detailProjectApi, { id: typeof visible === 'number' ? visible : undefined }, {})
    const listExchange = useGetApi(listExchangeV2Api, {}, [])
    const [logo, setLogo] = useState(null)
    const [infos, setInfos] = useState(initInfos)
    const [images, setImages] = useState([])
    const [dayOfMonth] = useState(() => {
        let arr = []
        for (let i = 0; i <= 28; i++) {
            arr.push({ name: i, id: i })
        }
        return arr
    })

    useEffect(() => {
        if (detail.id) {
            setInfos({ ...detail })
        }
        if (detail.logo) setLogo(detail.logo)
        if (detail.images) {
            const images = checkJson(detail.images) || detail.images
            if (typeof images === "object") setImages(images)
        }
    }, [detail])

    const handleData = () => {
        let info = { ...infos }
        if (!info.bdc_department_id) return 'Vui lòng chọn phòng ban!'
        if (!info.address) return 'Vui lòng nhập địa chỉ!'
        if (logo) info.logo = logo
        else info.logo = ' '
        if (images && images[0]) info.images = images
        else info.images = []
        info = { ...removePropObject(info, detail), id: detail.id }
        return info
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'dự án'}
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            route="/project_general"
            setParams={setParams}
            actions={{ add: addProjectApi, update: updateProjectApi }}
        >
            <div className="card">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-3">
                        <div className="card bg-color">
                            <UploadImg image={logo} setImage={setLogo} title="Logo" />
                        </div>
                    </div>
                    <div className="col-12 lg:col-9">
                        <div className="card bg-color">
                            <div className="grid grid-form">
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="name"
                                        value={infos.name}
                                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                        label="Tên dự án (*)"
                                        required
                                    />
                                    <InputForm
                                        id="building_code"
                                        value={infos.building_code}
                                        onChange={(e) => setInfos({ ...infos, building_code: e.target.value })}
                                        label="Mã dự án (*)"
                                        required
                                    />
                                    <DropdownForm
                                        id="bdc_department_id"
                                        value={infos.bdc_department_id}
                                        optionLabel="name"
                                        options={listExchange.filter(l => l.type === 2)}
                                        onChange={(e) => setInfos({ ...infos, bdc_department_id: e.target.value })}
                                        label="Phòng ban (*)"
                                    />
                                    <InputNumberFormV2
                                        id="area"
                                        value={infos.area}
                                        handleOnChange={(e) => setInfos({ ...infos, area: e })}
                                        label="Diện tích m2 (*)"
                                        required
                                    />
                                    {typeof visible === 'number' && (
                                        <DropdownForm
                                            id="config_menu"
                                            value={infos.config_menu}
                                            options={statusV2}
                                            onChange={(e) => setInfos({ ...infos, config_menu: e.target.value })}
                                            label="Hiển thị menu kế toán"
                                        />
                                    )}
                                </div>
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="email"
                                        value={infos.email}
                                        onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                        label="Email dự án (*)"
                                        type="email"
                                        required
                                    />
                                    <InputForm
                                        id="phone"
                                        value={infos.phone}
                                        onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
                                        label="Số điện thoại (*)"
                                        type="phone"
                                        required
                                    />
                                                                        <DropdownForm
                                        id="day_lock_cycle_name"
                                        value={infos.day_lock_cycle_name}
                                        onChange={(e) => setInfos({ ...infos, day_lock_cycle_name: e.target.value })}
                                        options={dayOfMonth}
                                        label="Ngày khóa sổ kế toán(*)"
                                        type="number"
                                    />
                                    {typeof visible === 'number' && (
                                        <DropdownForm
                                            id="debit_active"
                                            value={infos.debit_active}
                                            options={status}
                                            onChange={(e) => setInfos({ ...infos, debit_active: e.target.value })}
                                            label="Sửa hạn hạch toán"
                                        />
                                    )}
                                    <InputForm
                                        id="address"
                                        value={infos.address}
                                        onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                        label="Địa chỉ (*)"
                                    />
                                </div>
                            </div>
                            <InputTextareaForm
                                id="description"
                                value={infos.description}
                                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                                label="Mô tả"
                            />
                            <UploadMultiImage images={images} setImages={setImages} label="Ảnh dự án" />
                        </div>
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateProjectGeneral
