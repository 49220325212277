import {
    ApartmentService, BuildingServicesManagement, CaculatorDebt, CreateReceipt, DataReview,
    DebtImportHistory,
    ElectricWater,
    ListPay,
    ListPrice,
    ListReceipt,
    NotiList,
    PeriodManages,
    Promotion,
    ReceiptExpenditure,
    ReceiptImport,
    UpdateApartmentPromotion,
    UpdateApartmentService, UpdateBuildingServicesv2, UpdateElectricWater,
    UpdateListPrice,
    UpdatePromotion,
    UpdateReceiptExpenditure,
    UpdateVehicle,
    VehicleList
} from '@/modules/accountant'
import CreatePaymentVoucher from '@/modules/accountant/receipts_expenditures/screens/CreatePaymentVoucher'
import { PrintSlip } from '@/modules/accountant/receipts_expenditures/screens/PrintSlip'
import { ApartmentHandover, PostsCustomer, UpdateApartmentHandover } from '@/modules/apartment_handover'
import { AccessDeniedPage, ChangePassword, ErrorPage, ForgotPassword, Login, SetPassword } from '@/modules/auth'
import {
    BuildingGuide,
    BuildingOverview,
    DepartmentsList,
    DocsManagement,
    MaintainanceSchedule,
    WorkManagement
} from '@/modules/buildings'
import UpdateDepartment from '@/modules/buildings/departments_list/screens/UpdateDepartment'
import {
    Companies,
    Exchanges,
    Groups,
    Partners,
    UpdateCompany,
    UpdateExchange,
    UpdateGroup,
    UpdatePartner,
    UpdateUser,
    Users
} from '@/modules/companies'
import { DashBoard } from '@/modules/dashboard'
import {
    AggregateDebt,
    AggregateDebtDetails,
    BankTreasuryBook,
    CashBookMoney,
    CustomerList, DetailBill, ExcessMoney,
    ExcessMoneyDetails,
    PrintBill, PrintBills, PrintReduce, ServicesList
} from '@/modules/debts'
import { CreatePayment, Deposit, MarginReport } from '@/modules/deposit'
import { PrintDeposit } from '@/modules/deposit/deposit_list/screens/PrintDeposit'
import { BlockList, DetailLog, HistoryList, Notification, UpdateNotification } from '@/modules/notifications'
import { ParkingDetails, ParkingGeneral, Report } from '@/modules/parkings'
import { Permissions, ToolAffects, Tools, ToolUses, UpdatePermission, UpdateTool } from '@/modules/permissions'
import { Events, HistoryNotification, ListComments, ListVoted } from '@/modules/posts'
import Notifications from '@/modules/posts/notification/screens/Notification'
import UpdateNotifications from '@/modules/posts/notification/screens/UpdateNotification'
import { BuildingManagement, ProjectGeneral, SubdivisionList } from '@/modules/projects'

import {
    AggreGateRevenueReport,
    DebtReport,
    OptionReport,
    RevenueReport,
    VehicleReport,
    WorkReport
} from '@/modules/report_management'
import { DeatailUtilitiesApartment, ListReservatons, ReservationDetail, UtilitiesManage } from '@/modules/reservations'
import {
    Apartment,
    Card, DetailApartment,
    Import,
    Resident,
    UpdateApartment,
    UpdateCard,
    UpdateImport,
    UpdateResident
} from '@/modules/resident_apartments'
import {
    ApartmentDeposit,
    FormRequest,
    FormTemplate,
    RatedService,
    RegisterRepair,
    ResidentOpinion,
    ServiceRequest,
    UpdateApartmentDeposit,
    UpdateFormRequest,
    UpdateFormTemplate,
    UpdateRegisterRepair,
    UpdateResidentOpinion,
    UpdateServiceRequest
} from '@/modules/resident_opinions'
import { LogTemplateToolLog, LogTool, UpdateLogTool, UpdateTemplateToolLog } from '@/modules/setting'
import {
    AggregateBuildingData,
    DebtSummaryReport, InteractiveReport, RevenueAggregate
} from '@/modules/statistic_report'
// import { AggregateRevenue, BuildingData, DebtSumReport, InteractiveReport } from '@/modules/statistic_report/index'
import CreateAdjustmentVoucher from '@/modules/accountant/receipts_expenditures/screens/CreateAdjustmentVoucher'
import CreateCharacters from '@/modules/accountant/receipts_expenditures/screens/CreateCharacters'
import CreateFeesOther from '@/modules/accountant/receipts_expenditures/screens/CreateFeesOther'
import { ListPersonnel, ShiftManagement, TimeManagement, UpdatePersonnel, UpdateShiftManagement } from '@/modules/hrm'
import { Invoice, LogInvoice } from "@/modules/invoice"
import NotificationComment from '@/modules/posts/notification/screens/CommentNotification'
import {
    AccountingAccount,
    ConfigList,
    ReceptForm,
    ServicesType,
    UpdateConfig,
    UpdateReceptForm,
    UpdateService
} from '@/modules/system'
import { InputAsset, Maintenance, OutputAsset, UnitManagement } from '@/modules/warehouse_and_asset'
import AssetManagement from '@/modules/warehouse_and_asset/asset_managerment/screens/AssetManagement'
import UpdateAsset from '@/modules/warehouse_and_asset/asset_managerment/screens/UpdateAsset'
import AssetMixManagement from '@/modules/warehouse_and_asset/asset_mix_management/screens/AssetMixManagement'
import UpdateInputAseet from '@/modules/warehouse_and_asset/input_asset/screens/UpdateInputAsset'
import CreateExportTicket from '@/modules/warehouse_and_asset/inventory_management/screens/CreateExportTicket'
import CreateInputForm from '@/modules/warehouse_and_asset/inventory_management/screens/CreateInputForm'
import PrintBillWareHouse from '@/modules/warehouse_and_asset/inventory_management/screens/PrintBillWareHouse'
import UpdateInventoryManagement from '@/modules/warehouse_and_asset/inventory_management/screens/UpdateInventoryManagement'
import WareHouseManager from '@/modules/warehouse_and_asset/inventory_management/screens/WareHouseManager'
import UpdateOutputAsset from '@/modules/warehouse_and_asset/output_asset/screens/UpdateOutputAsset'



import { ApprovalList } from '@/modules/confirm_manage'
import PositionManagement from '@/modules/hrm/position_management/screens/PositionManagement'
import { ManageRevenue, ParkingCheck, ParkingReport, ReportVehicle } from '@/modules/parking_supervision'
import AddVote from "@/modules/posts/list_voted/screens/AddVote"
import ConfigBill from '@/modules/setting/config_bill/screen/ConfigBill'
import ConfigMenu from '@/modules/setting/config_receipt/screen/ConfigMenu'
import PrintQrCode from '@/modules/warehouse_and_asset/asset_managerment/screens/PrintQrCode'
import CreateRolateSlip from "@/modules/warehouse_and_asset/inventory_management/screens/CreateRolateSlip"

import LogKeeping from '@/modules/hrm/log_timekeeping/screens/LogKeeping'
export const routes = [
    // ==================== Authentication ==============================
    { path: '/', component: DashBoard, layout: true },
    { path: '/auth/login', component: Login, public: true },
    { path: '/auth/forgot_password', component: ForgotPassword, public: true },
    { path: '/auth/set_password', component: SetPassword, public: true },
    { path: '/auth/change_password', component: ChangePassword },

    // ==================== Quản Lý Quyền ==============================
    { path: '/permission', component: Permissions, layout: true },
    { path: '/permission/insert', component: UpdatePermission, layout: true },
    { path: '/permission/detail/:id', component: UpdatePermission, layout: true },
    { path: '/tool_use', component: ToolUses, layout: true },
    { path: '/tool_affect', component: ToolAffects, layout: true },

    { path: '/tool', component: Tools, layout: true },
    { path: '/tool/insert', component: UpdateTool, layout: true },
    { path: '/tool/detail/:id', component: UpdateTool, layout: true },

    // ==================== Quản Lý Công Ty ==============================
    { path: '/company', component: Companies, layout: true },
    { path: '/company/insert', component: UpdateCompany, layout: true },
    { path: '/company/detail/:id', component: UpdateCompany, layout: true },

    { path: '/department', component: Exchanges, layout: true },
    { path: '/department/insert', component: UpdateExchange, layout: true },
    { path: '/department/detail/:id', component: UpdateExchange, layout: true },

    { path: '/group', component: Groups, layout: true },
    { path: '/group/insert', component: UpdateGroup, layout: true },
    { path: '/group/detail/:id', component: UpdateGroup, layout: true },

    { path: '/user', component: Users, layout: true },
    { path: '/user/insert', component: UpdateUser, layout: true },
    { path: '/user/detail/:id', component: UpdateUser, layout: true },

    { path: '/partner', component: Partners, layout: true },
    { path: '/partner/insert', component: UpdatePartner, layout: true },
    { path: '/partner/detail/:id', component: UpdatePartner, layout: true },

    // ==================== Quản Lý Công Nợ ==============================
    { path: '/aggregate_debt', component: AggregateDebt, layout: true },
    { path: '/aggregate_debt_details', component: AggregateDebtDetails, layout: true },
    { path: '/cash_book_money', component: CashBookMoney, layout: true },
    { path: '/bill', component: CustomerList, layout: true },
    { path: '/bill/detail/:id', component: DetailBill, layout: true },

    { path: '/print/detail/:id', component: PrintBill, layout: false },
    { path: '/printReduce/detail/:id', component: PrintReduce, layout: false },
    { path: '/print/bills', component: PrintBills, layout: false },


    { path: '/excess_money', component: ExcessMoney, layout: true },
    { path: '/excess_money_details', component: ExcessMoneyDetails, layout: true },
    { path: '/services_list', component: ServicesList, layout: true },
    { path: '/bank_treasury_book', component: BankTreasuryBook, layout: true },

    // ==================== Quản Lý Tòa Nhà ==============================
    { path: '/building_overview', component: BuildingOverview, layout: true },
    { path: '/building_guide', component: BuildingGuide, layout: true },

    { path: '/departments_list', component: DepartmentsList, layout: true },
    { path: '/departments_list/insert', component: UpdateDepartment, layout: true },
    { path: '/departments_list/detail/:id', component: UpdateDepartment, layout: true },

    { path: '/docs_management', component: DocsManagement, layout: true },
    { path: '/maintainance_schedule', component: MaintainanceSchedule, layout: true },
    { path: '/work_management', component: WorkManagement, layout: true },
    // ==================== Quản Lý Thông Báo ==============================
    { path: '/notification', component: Notification, layout: true },
    { path: '/notification/detail/:id', component: UpdateNotification, layout: true },
    { path: '/log', component: HistoryList, layout: true },
    { path: '/log/detail/:id', component: DetailLog, layout: true },
    { path: '/block_list', component: BlockList, layout: true },

    // ==================== Hệ Thống Bãi Xe ==============================
    { path: '/parking_general', component: ParkingGeneral, layout: true },
    { path: '/parking_details', component: ParkingDetails, layout: true },
    { path: '/report', component: Report, layout: true },

    // ==================== Căn hộ dân cư ==============================
    { path: '/apartment_management', component: Apartment, layout: true },
    { path: '/apartment_management/insert', component: DetailApartment, layout: true },
    { path: '/apartment_management/detail/:id', component: DetailApartment, layout: true },

    { path: '/building_management', component: BuildingManagement, layout: true },


    { path: '/resident_card_management', component: Card, layout: true },
    { path: '/resident_card_management/insert', component: UpdateCard, layout: true },
    { path: '/resident_card_management/detail/:id', component: UpdateCard, layout: true },


    { path: '/import_history', component: Import, layout: true },
    { path: '/import_history/insert', component: UpdateImport, layout: true },
    { path: '/import_history/detail/:id', component: UpdateImport, layout: true },

    { path: '/resident_management', component: Resident, layout: true },
    { path: '/resident_management/insert', component: UpdateResident, layout: true },
    { path: '/resident_management/detail/:id', component: UpdateResident, layout: true },

    // ==================== Ý kiến dân cư ==============================
    { path: '/apartment_deposit', component: ApartmentDeposit, layout: true },
    { path: '/apartment_deposit/insert', component: UpdateApartmentDeposit, layout: true },
    { path: '/apartment_deposit/detail/:id', component: UpdateApartmentDeposit, layout: true },

    { path: '/form_request', component: FormRequest, layout: true },
    { path: '/form_request/detail/:id', component: UpdateFormRequest, layout: true },

    { path: '/form_setup', component: FormTemplate, layout: true },
    { path: '/form_setup/insert', component: UpdateFormTemplate, layout: true },
    { path: '/form_setup/detail/:id', component: UpdateFormTemplate, layout: true },

    { path: '/rated_service', component: RatedService, layout: true },

    { path: '/register_repair', component: RegisterRepair, layout: true },
    { path: '/register_repair/detail/:id', component: UpdateRegisterRepair, layout: true },

    { path: '/resident_opinion', component: ResidentOpinion, layout: true },
    { path: '/resident_opinion/detail/:id', component: UpdateResidentOpinion, layout: true },

    { path: '/service_request', component: ServiceRequest, layout: true },
    { path: '/service_request/detail/:id', component: UpdateServiceRequest, layout: true },

    // ==================== Bàn giao căn hộ ==============================
    { path: '/apartment_handover', component: ApartmentHandover, layout: true },
    { path: '/apartment_handover/insert', component: UpdateApartmentHandover, layout: true },
    { path: '/apartment_handover/detail/:id', component: UpdateApartmentHandover, layout: true },

    { path: '/posts_customer', component: PostsCustomer, layout: true },

    // ==================== Kế toán ==============================
    { path: '/services', component: BuildingServicesManagement, layout: true },
    { path: '/services/insert', component: UpdateBuildingServicesv2, layout: true },
    { path: '/services/detail/:id', component: UpdateBuildingServicesv2, layout: true },
    // { path: '/services/insert', component: UpdateBuildingService, layout: true },
    // { path: '/services/detail/:id', component: UpdateBuildingService, layout: true },

    { path: '/service_apartment', component: ApartmentService, layout: true },
    { path: '/service_apartment/insert', component: UpdateApartmentService, layout: true },
    { path: '/service_apartment/detail/:id', component: UpdateApartmentService, layout: true },

    { path: '/service_apartment', component: ApartmentService, layout: true },
    { path: '/service_apartment/insert', component: UpdateApartmentService, layout: true },
    { path: '/service_apartment/detail/:id', component: UpdateApartmentService, layout: true },

    { path: '/debt', component: CaculatorDebt, layout: true },
    { path: '/debt/detail/:id', component: CaculatorDebt, layout: true },
    { path: '/debt/update', component: DebtImportHistory, layout: true },

    { path: '/receipt_import', component: ReceiptImport, layout: true },
    { path: '/bill_approval', component: DataReview, layout: true },
    { path: '/bill_approval/detail/:id', component: DetailBill, layout: true },

    { path: '/electric_meter', component: ElectricWater, layout: true },
    { path: '/electric_meter/insert', component: UpdateElectricWater, layout: true },
    { path: '/electric_meter/detail/:id', component: UpdateElectricWater, layout: true },

    { path: '/list_table', component: ListPay, layout: true },
    { path: '/list_table/detail/:id', component: DetailBill, layout: true },

    { path: '/list_receipt', component: ListReceipt, layout: true },

    { path: '/noti_list', component: NotiList, layout: true },
    { path: '/noti_list/detail/:id', component: DetailBill, layout: true },

    { path: '/period_management', component: PeriodManages, layout: true },

    { path: '/list_price', component: ListPrice, layout: true },
    { path: '/list_price/insert', component: UpdateListPrice, layout: true },
    { path: '/list_price/detail/:id', component: UpdateListPrice, layout: true },

    { path: '/promotion_management', component: Promotion, layout: true },
    { path: '/promotion_management/insert', component: UpdatePromotion, layout: true },
    { path: '/promotion_management/detail/:id', component: UpdatePromotion, layout: true },
    { path: '/promotion_management/insert_apartment_promotion', component: UpdateApartmentPromotion, layout: true },
    { path: '/promotion_management/detail_apartment_promotion/:id', component: UpdateApartmentPromotion, layout: true },

    { path: '/receipt', component: ReceiptExpenditure, layout: true },
    { path: '/receipt/lap_phieu_chi', component: CreatePaymentVoucher, layout: true },
    { path: '/receipt/insert', component: UpdateReceiptExpenditure, layout: true },
    { path: '/receipt/detail/:id', component: UpdateReceiptExpenditure, layout: true },
    { path: '/receipt/print_slip/:id', component: PrintSlip },
    { path: '/receipt/lap_phieu_thu', component: CreateReceipt, layout: true },
    { path: '/receipt/lap_phieu_chi', component: CreatePaymentVoucher, layout: true },
    { path: '/receipt/lap_phieu_dieu_chinh', component: CreateAdjustmentVoucher, layout: true },
    { path: '/deposit_list/lap_phieu_chi', component: CreatePayment, layout: true },
    { path: '/deposit_list/lap_phieu_thu_kyquy', component: CreateCharacters, layout: true },
    { path: '/receipt/lap_phieu_thu_khac', component: CreateFeesOther, layout: true },
    { path: '/receipt/insert', component: UpdateReceiptExpenditure, layout: true },
    { path: '/receipt/detail/:id', component: UpdateReceiptExpenditure, layout: true },


    { path: '/vehicle_manage', component: VehicleList, layout: true },
    { path: '/vehicle_manage/insert', component: UpdateVehicle, layout: true },
    { path: '/vehicle_manage/detail/:id', component: UpdateVehicle, layout: true },

    // ==================== Hệ thống ==============================
    { path: '/service_type', component: ServicesType, layout: true },
    { path: '/service_type/insert', component: UpdateService, layout: true },
    { path: '/service_type/detail/:id', component: UpdateService, layout: true },

    { path: '/recept_form', component: ReceptForm, layout: true },
    { path: '/recept_form/insert', component: UpdateReceptForm, layout: true },
    { path: '/recept_form/detail/:id', component: UpdateReceptForm, layout: true },

    { path: '/accounting_account', component: AccountingAccount, layout: true },

    { path: '/config_list', component: ConfigList, layout: true },
    { path: '/config_list/insert', component: UpdateConfig, layout: true },
    { path: '/config_list/detail/:id', component: UpdateConfig, layout: true },

    // Ký Quỹ
    { path: '/deposit_list', component: Deposit, layout: true },
    { path: '/deposit_list/print_deposit/:id', component: PrintDeposit },
    { path: '/deposit_list/lap_phieu_chi', component: CreatePayment, layout: true },
    { path: '/deposit_list/lap_phieu_thu_kyquy', component: CreateCharacters, layout: true },
    { path: '/margin_report', component: MarginReport, layout: true },

    // Báo Cáo Thống Kê
    { path: '/revenue_aggregate', component: RevenueAggregate, layout: true },
    { path: '/aggregate_building_data', component: AggregateBuildingData, layout: true },
    { path: '/debt_summary_report', component: DebtSummaryReport, layout: true },
    { path: '/interactive_reports', component: InteractiveReport, layout: true },

    // Báo cáo quản trị
    { path: '/debt_report', component: DebtReport, layout: true },
    { path: '/option_report', component: OptionReport, layout: true },
    { path: '/revenue_report', component: RevenueReport, layout: true },
    { path: '/vehicle_report', component: VehicleReport, layout: true },
    { path: '/work_report', component: WorkReport, layout: true },
    { path: '/aggregate_revenue_report', component: AggreGateRevenueReport, layout: true },

    // log tool
    { path: '/log_tool', component: LogTool, layout: true },
    { path: '/log_tool/detail/:id', component: UpdateLogTool, layout: true },
    { path: '/config_bill', component: ConfigBill, layout: true },
    { path: '/config_receipt', component: ConfigMenu, layout: true },

    // template tool log
    { path: '/template_tool_log', component: LogTemplateToolLog, layout: true },
    { path: '/template_tool_log/insert', component: UpdateTemplateToolLog, layout: true },
    { path: '/template_tool_log/detail/:id', component: UpdateTemplateToolLog, layout: true },

    // ==================== Dự án ==============================
    { path: '/project_general', component: ProjectGeneral, layout: true },
    { path: '/subdivision', component: SubdivisionList, layout: true },
    // ==================== Đặt chỗ ==============================
    { path: '/booking_time_place', component: ListReservatons, layout: true },
    { path: '/booking_time_place/insert', component: ReservationDetail, layout: true },
    { path: '/booking_time_place/detail/:id', component: ReservationDetail, layout: true },
    { path: '/booking', component: UtilitiesManage, layout: true },
    { path: '/booking/detail/:id', component: DeatailUtilitiesApartment, layout: true },
    // ==================== Thông báo , tin tức ==============================
    { path: '/history_notification', component: HistoryNotification, layout: true },
    { path: '/list_voted', component: ListVoted, layout: true },
    { path: '/posts', component: Notifications, layout: true },
    { path: '/posts/comments/:id', component: NotificationComment, layout: true },
    { path: '/posts/insert', component: UpdateNotifications, layout: true },
    { path: '/posts/detail/:id', component: UpdateNotifications, layout: true },
    { path: '/voting_list', component: ListVoted, layout: true },
    { path: '/voting_list/insert', component: UpdateNotifications, layout: true },
    { path: '/voting_list/detail/:id', component: AddVote, layout: true },
    { path: '/events', component: Events, layout: true },
    { path: '/list_comments', component: ListComments, layout: true },
    //--------------------------Quản lý nhan sự-----------------------
    { path: '/list_personnel', component: ListPersonnel, layout: true },
    { path: '/list_personnel/insert', component: UpdatePersonnel, layout: true },
    { path: '/list_personnel/detail/:id', component: UpdatePersonnel, layout: true },
    { path: '/user_position', component: PositionManagement, layout: true },
    { path: '/timekeeping_management', component: TimeManagement, layout: true },
    { path: '/log_timekeeping', component: LogKeeping, layout: true },
    //--------------------------Quản lý ca làm việc------------------------
    { path: '/shift_management', component: ShiftManagement, layout: true },
    { path: '/shift_management/insert', component: UpdateShiftManagement, layout: true },
    { path: '/shift_management/detail/:id', component: UpdateShiftManagement, layout: true },
    //---------------------Quản lý tài sản ------------------------
    { path: '/asset_mix_management', component: AssetMixManagement, layout: true },
    { path: '/asset', component: AssetManagement, layout: true },
    { path: '/asset/insert', component: UpdateAsset, layout: true },
    { path: '/asset/detail/:id', component: UpdateAsset, layout: true },
    { path: '/warehouse_management', component: WareHouseManager, layout: true },
    { path: '/print/ware_house/:id', component: PrintBillWareHouse, layout: false },
    { path: '/warehouse_management/insert', component: UpdateInventoryManagement, layout: true },
    { path: '/warehouse_management/detail/:id', component: UpdateInventoryManagement, layout: true },
    { path: '/inventory_management/create_input_form', component: CreateInputForm, layout: true },
    { path: '/inventory_management/create_export_ticket', component: CreateExportTicket, layout: true },
    { path: '/input_asset', component: InputAsset, layout: true },
    { path: '/input_asset/insert', component: UpdateInputAseet, layout: true },
    { path: '/output_asset', component: OutputAsset, layout: true },
    { path: '/output_asset/insert', component: UpdateOutputAsset, layout: true },
    { path: '/maintain', component: Maintenance, layout: true },
    { path: '/output_asset/insert', component: Maintenance, layout: true },
    { path: '/unit_management', component: UnitManagement, layout: true },
    { path: '/log_invoice', component: LogInvoice, layout: true },
    { path: '/invoice', component: Invoice, layout: true },
    { path: '/inventory_management/rolate_slip', component: CreateRolateSlip, layout: true },
    //--------------------- Giám sát bãi xe ------------------------
    { path: '/parking_supervision', component: ParkingCheck, layout: true },
    { path: '/parking_report', component: ParkingReport, layout: true },
    { path: '/report_vehicle', component: ReportVehicle, layout: true },
    { path: '/management_revenue', component: ManageRevenue, layout: true },
    //--------------------- Quản lý phê duyệt ----------------------
    { path: '/confirm_manage', component: ApprovalList, layout: true },
    { path: '/print/qrcode_asset/:ids', component: PrintQrCode },
]

export const errorPage = { path: '*', component: ErrorPage }
export const accessDeniedPage = { path: '*', component: AccessDeniedPage }
