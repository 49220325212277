import { listExchangeV2Api, listUserApi } from '@/api';
import { Body, Calendarz, Columnz, Dropdownz, GridForm, HeaderListForm, Inputz, MultiSelectz, useGetParams } from '@/components';
import { useGetApi } from '@/hooks';
import { DataTablez } from '@/modules/parking_supervision/parking_check/screens/ParkingForm';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Row } from 'primereact/row'
import { ColumnGroup } from 'primereact/columngroup'
import { useListTimeKeeping } from '../utils';
import { listPosition } from '../../position_management/api';
import moment from 'moment';
const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({})
      const users = useSelector(state => state.users);
      const exchanges = useGetApi(listExchangeV2Api, {}, [])
      const userExChanges = users.filter(u => filter.department_ids?.includes(u.department_id));
      const userExChangesV2 = userExChanges.map((d) => {
            return { ...d, full_name: d.full_name + "-" + d.code }
      })
      const userV2 = users.map((d) => {
            return { ...d, full_name: d.full_name + "-" + d.code }
      })
      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3" array={["department_ids", "user_ids"]}>
                  <MultiSelectz
                        value={filter.department_ids}
                        onChange={(e) => setFilter({ ...filter, department_ids: e.target.value })}
                        placeholder="Phòng ban"
                        options={exchanges}
                  />
                  <MultiSelectz
                        value={filter.user_ids}
                        onChange={(e) => setFilter({ ...filter, user_ids: e.target.value })}
                        placeholder="Nhân viên"
                        options={filter.department_ids ? userExChangesV2 : userV2}
                        optionLabel="full_name"
                        optionValue="user_id"
                  />
                  <Calendarz
                        value={filter.dateq}
                        onChange={(e) => setFilter({ ...filter, dateq: e.value })}
                        placeholder={"Chọn khoảng thời gian "}
                        selectionMode="range"
                  />
            </GridForm>
      )
}
function WorksheetByShift() {
      const initParam = useGetParams()
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth(), 1);
      const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      const [params, setParams] = useState({ ...initParam, limit: 1000, start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD') })
      const data = useListTimeKeeping({ ...params, })
      const users = useSelector(state => state.users);
      const exchanges = useGetApi(listExchangeV2Api, {}, [])
      const position = useGetApi(listPosition, {}, [])
      let newArray = [];
      const getMinMaxDate = (arr) => {
            const dates = arr.flatMap(item => item.cong.map(congItem => new Date(congItem.date)));
            const minDate = new Date(Math.min(...dates));
            const maxDate = new Date(Math.max(...dates));
            return { minDate, maxDate };
      };
      data?.data?.forEach(item => {
            let existingItem = newArray.find(
                  x => x.by === item.by && x.shift_id === item.shift_id
            );
            if (existingItem)
            {
                  existingItem.cong.push({
                        ...item
                  });
            } else
            {
                  const newCong = [{ ...item }];
                  const tong = data?.data
                        .filter(i => i.by === item.by && i.shift_id === item.shift_id)
                        .reduce((sum, i) => sum + i.total_work, 0);
                  const thucte = data?.data
                        .filter(i => i.by === item.by && i.shift_id === item.shift_id)
                        .reduce((sum, i) => sum + i.number_work, 0);
                  newArray.push({
                        code: item.shift_code,
                        by: item.by,
                        shift_id: item.shift_id,
                        cong: newCong,
                        tong,
                        thucte
                  });
            }
      });
      const { minDate, maxDate } = getMinMaxDate(newArray);
      const minDatev2 = params?.start
      const maxDatev2 = params?.end
      newArray.forEach(item => {
            item.minDate = minDatev2 ? minDatev2 : minDate
            item.maxDate = maxDatev2 ? maxDatev2 : maxDate
      });
      let startDate = new Date(newArray[0]?.minDate ? newArray[0]?.minDate : minDatev2);
      let endDate = new Date(newArray[0]?.maxDate ? newArray[0]?.maxDate : maxDatev2);
      let dateArray = [];
      console.log(newArray);
      while (startDate <= endDate)
      {
            dateArray.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
      }
      const dateColumns = dateArray.map((date, index) => {
            const dayOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'][date.getDay()];
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}`;
            return <Columnz key={index} header={`${dayOfWeek} `} body={(rowData) => {
                  const item = rowData.cong.find(item => new Date(item.date).getTime() === date.getTime());
                  return item?.number_work ? item.shift_code : 'x';
            }} />;
      });
      const dateColumnsv2 = dateArray.map((date, index) => {
            const dayOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'][date.getDay()];
            const formattedDate = `${date.getDate()}-${date.getMonth() + 1}`;
            return <Columnz key={index} header={` ${formattedDate}`}
            />;
      });
      const headerGroup = (
            <ColumnGroup>
                  <Row>
                        <Columnz header="#" field="" rowSpan={2} />
                        <Columnz header="Mã NV" field="" rowSpan={2} />
                        <Columnz header="Họ và tên" field="" rowSpan={2} />
                        <Columnz header="Phòng ban" rowSpan={2} />
                        <Columnz header="Vị trí" rowSpan={2} />
                        {dateColumns}
                  </Row>
                  <Row>
                        {dateColumnsv2}
                  </Row>
            </ColumnGroup>
      )
      return (
            <div className="card" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  <HeaderListForm title="Bảng công làm việc theo ca" />
                  <Header setParams={setParams} />
                  <DataTablez
                        title="công lương"
                        params={params}
                        setParams={setParams}
                        route="/timekeeping_management"
                        value={newArray}
                        headerColumnGroup={headerGroup}
                        rowsPerPageOptions={[1000]}
                  >
                        <Columnz header="Mã NV" body={(e) => Body({ data: users, value: e.by, label: 'code', key: 'user_id' })} />
                        <Columnz header="Họ và Tên" body={(e) => Body({ data: users, value: e.by, label: 'full_name', key: 'user_id' })} />
                        <Columnz header="Phòng ban" body={(e) => {
                              const a = users.find(d => d.user_id === e.by)
                              const b = exchanges.find(d => d.id === a?.department_id)
                              return b.name
                        }} />
                        <Columnz header="Vị trí" body={(e) => {
                              const a = users?.find(d => d.user_id === e.by)
                              const b = position.find(d => d.id === a?.work_position)
                              return b?.name
                        }} />
                        {dateColumns}
                  </DataTablez>
            </div>
      )
}
export default WorksheetByShift
