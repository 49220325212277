import { useEffect, useState } from 'react'
import { countBuildingCompanyApi, countPostApi, detailPostApi, detailStaffApi, listNameBuildingCompanyApi, listPostApi, listStaffApi, listBuildingApi, listResidentApi, listApartmentApi, listCommnentNotifyApi, listPostCate } from '../api'

export const useListPost = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPostApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListPostCate = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPostCate({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}


export const useListBuilding = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listBuildingApi({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListResident = (place_id) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listResidentApi({ place_id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (place_id) fetchData()
    }, [place_id])
    return data
}
export const useListApartment = (place_id) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listApartmentApi({ place_id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (place_id) fetchData()
    }, [place_id])
    return data
}


export const useListNameBuildingCompanyApi = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listNameBuildingCompanyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}


export const useListStaff = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listStaffApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}



export const useCountPost = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPostApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPost = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPostApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

export const useDetailStaff = (user_id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailStaffApi({ user_id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (user_id) fetchData()
    }, [user_id])
    return data
}


export const useCountBuildingCompany = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countBuildingCompanyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListNotifiComment = (id, render) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await listCommnentNotifyApi({ post_id: id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, render])
    return data
}