import { Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, useGetParams } from "@/components"
import { useState } from 'react'
import { updateStatusVehiclCategoryApi } from "../api"
import { useCountVehiclCategory, useListVehicleCategories } from "../utils"
import UpdateVehicleCategory from "./UpdateVehicleCategory"

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} >
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên dịch vụ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                // options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng Thái"
            />
            <Dropdownz
                value={filter.type}
                // options={typeVehiclesService}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại dịch vụ"
            />
        </GridForm>
    )
}
const VehicleCategory = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const listVehicleCategories = useListVehicleCategories({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountVehiclCategory({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    return (
        <div className="card">
            <HeaderListForm title="Danh sách loại phương tiện" />
            {visible && <UpdateVehicleCategory visible={visible} setVisible={setVisible} setParams={setParams} />}
            <DataTablez
                value={listVehicleCategories}
                title="loại phương tiện"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/services"
                setVisibledDialog={setVisible}
                actionsInfo
                headerInfo
                basePermissions={["insert", "detail"]}
            >
                <Columnz field="name" header="Tên" />
                <Columnz field="description" header="Mô tả" />
                <Columnz
                    field="status"
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}
                    className='text-center' body={(e) => StatusBody({ e, route: '/services', updateAction: updateStatusVehiclCategoryApi })} />
            </DataTablez>
        </div>
    )
}

export default VehicleCategory