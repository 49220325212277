import { useEffect, useState } from 'react'
import { listExcessMoneyApi, countExcessMoneyApi, detailExcessMoneyApi } from '../api'

export const useListExcessMoney = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listExcessMoneyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountExcessMoney = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countExcessMoneyApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailExcessMoney = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailExcessMoneyApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
