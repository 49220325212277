import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    RenderHeader,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { useCountApartmentHandover, useListApartmentHandover } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn khách hàng"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái xác nhận"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái bàn giao"
            />
            <Calendarz
                value={filter.status}
                className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                showIcon
            />
        </GridForm>
    )
}

const ApartmentHandovers = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListApartmentHandover({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountApartmentHandover({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách căn hộ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="căn hộ bàn giao"
                setParams={setParams}
                route="/apartment_handover"
                totalRecords={totalRecords}
                params={params}
            >
                <Columnz field="name" header="Khách hàng" />
                <Columnz field="address" header="Căn hộ" />
                <Columnz field="email" header="SĐT" />
                <Columnz field="phone_number" header="Email" />
                <Columnz field="phone_number" header="Địa chỉ" />
                <Columnz field="phone_number" header="Ngày dự kiến bàn giao" />
                <Columnz field="phone_number" header="Trạng thái xác nhận" />
                <Columnz field="phone_number" header="Ghi chú" />
                <Columnz field="phone_number" header="Kết quả bàn giao" />
            </DataTablez>
        </div>
    )
}

export default ApartmentHandovers
