import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'


import { Comments, SendMessage } from './Comment'

import { useListNotifiComment } from '../utils'
import { addNotifyComment, deleteNotifyComment, switchStatusNotifiCommentApi } from '../api'


const NotificationComment = () => {
      const { id } = useParams()
      const [render, setRender] = useState(false)
      const listComments = useListNotifiComment(id, render)
      const [comments, setComments] = useState([])

      useEffect(() => {
            if (listComments && Array.isArray(listComments)) {
                  setComments([...listComments])
            }
      }, [listComments])

      const handleAddComment = (params) => {
            return { ...params, post_id: Number(id) }
      }

      return (
            <div className="card">
                  <div className="grid grid-form">

                        <div className="col-12 lg:col-12">

                              <Comments
                                    switchStatusApi={switchStatusNotifiCommentApi}
                                    addApi={addNotifyComment}
                                    deleteApi={deleteNotifyComment}
                                    setRender={setRender}
                                    handleAddComment={handleAddComment}
                                    comments={comments}
                              />
                              {/* <SendMessage
                                    id={id}
                                    addApi={addNotifyComment}
                                    handleAddComment={handleAddComment}
                                    setRender={setRender}
                              /> */}
                        </div>
                  </div>
            </div>
      )
}

export default NotificationComment
