import {vehicle_type} from "@/constants";

const TicketStatistic = (props) => {
    const { data, sum, type } = props
    return (
        <div className="col-12">
            <div className=" col-12 flex justify-content-between p-4" style={{ border: '0.1px solid lightgrey' }}>
                <div>
                    <span className="pi pi-user" style={{ color: '#5178fb', fontSize: '2.5vh' }}></span>
                    <span className="px-3" style={{ fontWeight: '600', fontSize: '20px' }}>
                        Số lượng sử dụng vé {type}
                    </span>
                </div>
                <span style={{ fontWeight: 'bold', fontSize: '2.5vh' }}>{sum}</span>
            </div>
            <div className="flex flex-row">
                {data.map((item, index) => (
                    <>
                        <div
                            className="col-3 flex justify-content-center align-item-center py-3"
                            style={{ border: '1px solid lightgrey' }}
                        >
                            <div className="flex flex-column">
                                <span
                                    style={{
                                        fontWeight: '700',
                                        fontSize: '2vh',
                                        margin: '0 auto',
                                    }}
                                >
                                    {item.count}
                                </span>
                                <span
                                    className="p-2"
                                    style={{
                                        fontWeight: '700',
                                        fontSize: '1.7vh',
                                        margin: '0 auto',
                                    }}
                                >
                                    {vehicle_type.find(v => v.id === item.vehicle_type)?.name}
                                </span>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
}

export default TicketStatistic
