import { ActionBody, HeaderListForm } from '@/components'
import { useState } from 'react'
import { deletePaymentInfoApi } from '../api'
import { useListPaymentInfo } from '../utils'
import UpdatePaymentInfo from './UpdatePaymentInfor'
import { useDispatch } from 'react-redux'
import { setToast } from '@/redux/features'
import { listToast } from '@/constants'
import { Button } from '@/uiCore'

export default function PaymentInfo() {
    const [params, setParams] = useState({ render: false })
    const [visible, setVisible] = useState(false)
    const data = useListPaymentInfo({ ...params })
    const dispatch = useDispatch()

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(setToast({ ...listToast[0], detail: "Copy số tài khoản thành công" }))
            })
            .catch((err) => {

            });
    };

    return (
        <>
            {visible && <UpdatePaymentInfo setParams={setParams} visible={visible} setVisible={setVisible} />}
            <HeaderListForm title="Danh sách thông tin thanh toán" SubTitle={() => (
                <Button
                    onClick={() => setVisible(true)}
                    icon="pi pi-plus"
                    label="Thêm mới"
                    size="small"
                    severity="info"
                    raised
                    type="button"
                />
            )} />
            <div className='grid grid-form'>
                {data.map((value, index) => (
                    <div key={index} className='col-12 lg:col-6'>
                        <div className='card'>
                            <div className="" style={{ lineHeight: '2' }}>
                                <div className='flex flex-column justify-content-center align-items-center gap-2' >
                                    <h5>{value.bank_name}</h5>
                                    {value.qr_code === null ? <img src="http://thbaibong.phoyen.edu.vn/App/images/no-image-news.png" alt="QR Code" style={{ width: '250px', height: '250px' }} /> : <img src={value.qr_code} alt="QR Code" style={{ width: '250px', height: '250px' }} />}
                                    <p className="m-0">Chi nhánh : {value.branch}</p>
                                    <div className='flex gap-2'>
                                        <h5 className='m-0'>STK: {value.bank_account}</h5>
                                        <i
                                            className="pi pi-copy"
                                            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                                            onClick={() => copyToClipboard(value.bank_account)}
                                        ></i>
                                    </div>
                                    <h5 className="m-0">Chủ tài khoản: {value.holder_name}</h5>
                                </div>
                                <br />
                                <ActionBody
                                    id={value.id}
                                    route="/building_overview"
                                    deleteAction={deletePaymentInfoApi}
                                    setParams={setParams}
                                    setVisibledDialog={setVisible}
                                    basePermissions={["detail", "delete"]}
                                />
                                <br />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
