import { CalendarForm, Columnz, DataTablez, DataTablezV2, DropdownForm, FormUpdate, FormUpdateDialog, HeaderListForm, InputForm, InputTextareaForm, UploadFile } from '@/components'
import { databaseDate } from '@/lib'
import { removePropObject, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'
import { Carousel } from 'primereact/carousel'

const UpdateOutputAsset = (props) => {
      const { id } = useParams()

      const { setVisible, setParams, visible } = props

      const [infos, setInfos] = useState({ status: 1 })
      const apartments = useSelector((state) => state.apartments)
      const services = useSelector((state) => state.services)
      const [file, setFile] = useState(null)


      return (
            <FormUpdate
                  checkId={Number(id)}
                  title="phiếu xuất"
                  // handleData={handleData}
                  route="/list_price"
            // actions={{ add: addPriceApi, update: updatePriceApi }}
            // actions={{ add: addApartmentServiceApi, update: updateApartmentServiceApi }}
            >
                  <div className=" bg-color">
                        <div className="grid grid-form">

                              <div className="col-12 lg:col-6">
                                    <CalendarForm label="Thời gian nhập" />
                                    <InputForm label="Người tạo phiếu" />
                                    <InputForm label="Nơi tiếp nhận" />
                              </div>
                              <div className="col-12 lg:col-6">
                                    <InputForm label="Mã phiếu nhập" />
                                    <DropdownForm label="Người tiếp nhận" />
                              </div>
                        </div>
                        <HeaderListForm title="Thông tin sản phẩm nhập kho" />
                        <DataTablezV2
                              // value={data}
                              title="sản phẩm"
                        // totalRecords={totalRecords}
                        // params={params}
                        // setParams={setParams}
                        // route="/asset"
                        // setVisibledDialog={setVisible}
                        // actionsInfo={{ deleteAction: deleteAssetApi }}

                        >
                              <Columnz field="name" header="Tên sản phẩm" />
                              <Columnz field="categogy" header="Mã sản phẩm" />
                              <Columnz field="typeMaintain" header="Phân loại" />
                              <Columnz field="timeMaintain" header="Đơn vị tính" />
                              <Columnz field="note" header="Số lượng" />
                              <Columnz field="note" header="Phòng ban quản lý" />
                              <Columnz field="note" header="Lý do" />
                        </DataTablezV2>
                  </div>
            </FormUpdate >
      )
}

export default UpdateOutputAsset
