import {importResidentApi, listResidentV2Api} from '@/api'
import { Import } from '@/components'
import {useDispatch} from "react-redux";
import {setResidents} from "@/redux/features";

const ImportResident = ({ visible, setVisible, setParams }) => {
    const dispatch = useDispatch()
    const handleSuccess = async () => {
        setParams(pre => ({ ...pre, render: !pre.render }))
        setVisible(false)
        const getResident = await listResidentV2Api()
        if (getResident.data && getResident.data.status) {
            const residents = getResident.data.data
            dispatch(setResidents(residents))
        }
    }

    return (
        <Import
            title="cư dân"
            visible={visible}
            setVisible={setVisible}
            action={importResidentApi}
            template="https://devapibdc.dxmb.vn/import/import_residents.xlsx"
            handleSuccess={handleSuccess}
        />
    )
}

export default ImportResident
