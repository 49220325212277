import { useEffect, useState } from 'react'
import {
    cashFlow,
    getNotifyEvent,
    getOpinions,
    getStatBuilding,
    getStatVehicle,
    opinionResident,
    getTotalRevenue,
    residentPost,
    getCountVehicle,
    getBalanceChange,
} from '../api'

export const useGetCashFlow = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await cashFlow({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useGetOpinions = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await getOpinions({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useGetStatVehicle = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await getStatVehicle({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useGetStatBuilding = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await getStatBuilding({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useGetNotifyEvent = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await getNotifyEvent({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useResidentPost = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await residentPost({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useOpinionResident = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await opinionResident({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
// Tổng hợp doanh thu
export const useGetTotalRevenue = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await getTotalRevenue({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

// Các loại xe
export const useGetCountVehicle = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await getCountVehicle({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListBalanceChange = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await getBalanceChange({ ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}