import { useEffect, useState } from 'react'
import { listParkingsApi, countParkingApi, detailParkingApi } from '../api'

export const useListParking = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listParkingsApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountParking = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countParkingApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailParking = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailParkingApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
