import { useParams } from 'react-router-dom'
import {useGetApi} from "@/hooks";
import {printApi} from "@/modules/debts/services_list/api";
import React from "react";

const Print = () => {
    const { id } = useParams()
    const data = useGetApi(printApi, { id }, '')

    return (
        <div className='flex justify-content-center align-items-center' >
            {
                data &&
                typeof data === 'string' && (
                    <div style={{ width: "1140px", fontFamily: 'DejaVu Sans' }} dangerouslySetInnerHTML={{ __html: data }} />
                )
            }
        </div>
    )
}

export default Print