import { postData, getData } from '@/lib/request'

export const listManagementDocsApi = (params) => getData('web2/docs_management/getListDocument', params)
export const countManagementDocsApi = (params) => getData('web2/docs_management/countDocument', params)
export const deleteManagementDocsApi = (params) => postData('web2/docs_management/deleteDocument', params)
export const detailManagementDocsApi = (params) => getData('web2/docs_management/detailDocument', params)
export const addManagementDocsApi = (params) => postData('web2/docs_management/addDocument', params, true)
export const updateManagementDocsApi = (params) => postData('web2/docs_management/updateDocument', params, true)

export const listApartmentDocsApi = (params) => getData('web2/company/getListCompany', params)
export const countApartmentDocsApi = (params) => getData('web2/company/countCompany', params)
export const deleteApartmentDocsApi = (params) => postData('web2/company/deleteCompany', params)
export const detailApartmentDocsApi = (params) => getData('web2/company/getDetailCompany', params)
export const addApartmentDocsApi = (params) => postData('/web2/company/addCompany', params)
export const updateApartmentDocsApi = (params) => postData('/web2/company/updateCompany', params)

export const listDocsGroupApi = params => getData('web2/category_document/getListCategoryDocument', params)
export const countDocsGroupApi = params => getData('web2/category_document/countCategoryDocument', params)
export const detailtDocsGroupApi = params => getData('web2/category_document/detailCategoryDocument', params)
export const addDocsGroupApi = params => postData('web2/category_document/addCategoryDocument', params)
export const updateDocsGroupApi = params => postData('web2/category_document/updateCategoryDocument', params)
export const deleteDocsGroupApi = params => postData('web2/category_document/deleteCategoryDocument', params)