import { useEffect, useState } from 'react'
import { countPeriodManageApi, detailPeriodManageApi, listPeriodManageApi } from '../api'

export const useListPeriodManage = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listPeriodManageApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountPeriodManage = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countPeriodManageApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailPeriodManage = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailPeriodManageApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
