import { useEffect, useState } from 'react'
import {
    countApartmentDocsApi,
    countManagementDocsApi,
    detailApartmentDocsApi,
    detailManagementDocsApi,
    listApartmentDocsApi,
    listManagementDocsApi,
    listDocsGroupApi,
    detailtDocsGroupApi,
    countDocsGroupApi

} from '../api'

export const useListManagementDocs = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listManagementDocsApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountManagementDocs = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countManagementDocsApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailManagementDocs = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailManagementDocsApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
// ========= nhóm tài liệu===========================================

export const useListDocsGroup = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDocsGroupApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountDocsGroup = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countDocsGroupApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailDocsGroup = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailtDocsGroupApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}


// ============================================================================
export const useListApartmentDocs = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listApartmentDocsApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountApartmentDocs = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countApartmentDocsApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailApartmentDocs = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailApartmentDocsApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
