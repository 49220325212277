import { TabPanel, TabView } from 'primereact/tabview'


import AssetList from './AssetList'
import AssetGroup from './AssetMixGroup'

export default function AssetMixManagement() {
    return (
        <div className="card">
            <TabView>
                {/* <TabPanel header="Danh mục sản phẩm">
                    <AssetMix />
                </TabPanel> */}
                <TabPanel header="Nhóm sản phẩm">
                    <AssetGroup />
                </TabPanel>
                <TabPanel header="Danh mục sản phẩm">
                    <AssetList />
                </TabPanel>
            </TabView>
        </div>
    )
}
