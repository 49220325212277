import { Body, Calendarz, Columnz, DataTablez, Dropdownz, GridForm, Inputz, useGetParams } from '@/components'
import { useState } from 'react'
import { useCountDetailRated, useListDepartment, useListDetailRated } from '../utils'
import { objectRate, ratedService } from '@/constants'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const departments = useListDepartment()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Chọn khách hàng, nhân viên, căn hộ"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                className="lg:col-6"
            />
            <Dropdownz
                value={filter.department_id}
                options={departments}
                onChange={(e) => setFilter({ ...filter, department_id: e.target.value })}
                placeholder="Bộ phận"
            />
            <Dropdownz
                value={filter.object}
                options={objectRate}
                onChange={(e) => setFilter({ ...filter, object: e.target.value })}
                placeholder="Đối tượng"
            />
        </GridForm>
    )
}

const RateDetail = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListDetailRated({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDetailRated({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Đánh giá"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/rated_service"
            >
                <Columnz field="customer_name" header="Khách hàng" />
                <Columnz field="apartment_name" header="Căn hộ" />
                {/* <Columnz field="department" header="Nguồn đánh giá" /> */}
                {/* <Columnz field="total_vote" header="Nhân viên/Nhà thầu" />
                <Columnz field="very_unsatisfied_count" header="Mã nhân viên/ Nhà thầu " /> */}
                <Columnz field="department" header="Bộ phận" />
                <Columnz field="phone" header="SĐT" />
                <Columnz field="email" header="Email" />
                <Columnz body={(e) => Body({ data: ratedService, value: e.point })} header="Đánh giá" />
                <Columnz field="point" header="Đánh giá" />
                <Columnz field="description" header="Ghi chú" />
            </DataTablez>
        </div>
    )
}

export default RateDetail
