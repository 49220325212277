import { addBuildingApi, detailBuildingApi, listBuildingV2Api, listSubdivisionApi, updateBuildingApi } from '@/api'
import { DropdownForm, FormUpdateDialog, InputForm, InputSwitchForm } from '@/components'
import { useGetApi } from '@/hooks'
import { setBuildings } from '@/redux/features'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const UpdateBuilding = (props) => {
    const dispatch = useDispatch()
    const { visible, setVisible, setParams } = props
    const detail = useGetApi(detailBuildingApi, { id: typeof visible === 'number' ? visible : undefined }, {})
    const [infos, setInfos] = useState({
        name: '',
        code: '',
        status: 1,
    })
    const listSubdivision = useGetApi(listSubdivisionApi, {}, [])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (!info.bdc_subdivision_id) return 'Vui lòng chọn phân khu'
        if (typeof visible === 'number') info = { ...removePropObject(info, detail), id: visible }
        return info
    }

    useEffect(() => {
        if (detail) setInfos({ ...infos, ...detail, status: Number(detail.status) === 1 })
    }, [JSON.stringify(detail)])

    const handleAfterCallApi = async () => {
        const getBuilding = await listBuildingV2Api()
        if (getBuilding.data && getBuilding.data.status) {
            const buildings = getBuilding.data.data
            dispatch(setBuildings(buildings))
        }
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            refreshObjects={[setInfos]}
            route="/building_management"
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'tòa nhà'}
            actions={{ add: addBuildingApi, update: updateBuildingApi }}
            handleAfterCallApi={handleAfterCallApi}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên tòa nhà (*)"
                            required
                        />
                        <InputForm
                            id="code"
                            value={infos.code}
                            onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                            label="Mã tòa nhà (*)"
                            required
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputSwitchForm
                            id="status"
                            checked={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                            label="Trạng thái"
                        />
                        <DropdownForm
                            id="bdc_subdivision_id"
                            value={infos.bdc_subdivision_id}
                            onChange={(e) => setInfos({ ...infos, bdc_subdivision_id: e.target.value })}
                            options={listSubdivision}
                            label="Phân khu (*)"
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default UpdateBuilding
