import React, { useState } from 'react'
import { HandRecognition } from './HandRecognition'
import { ParkingComparision } from './ParkingComparision'
import ParkingManagement from './ParkingManagement'
import ParkingSupervision from './ParkingSupervision'
import {Dropdownz, InputForm, Inputz, useGetParams} from '@/components'
import { useListCarParkingAI, useListCarParkingAll, useListCarParkingPM } from '../../utils'
import { Calendarz, GridForm } from '../ParkingForm'
import { lane_action, vehicle_type } from '@/constants'
import { databaseDate } from '@/lib'
const defaultValue = { plate_number: '', lane_action: 'IN', type_vehicle: 'Car' }
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState(() => {
        const from = new Date()
        return {dates: [from], ...defaultValue}
    })
    const handleFilter = (filters) => {
        if (filters.dates && filters.dates[0]) {
            filters.from = databaseDate(filters.dates[0], false, 'date')
            if (filters.dates[1]) filters.to = databaseDate(filters.dates[1], false, 'date')
            else filters.to = databaseDate(filters.dates[0], false, 'date')
        }
        return filters
    }

    const onClear = () => {
        const date = new Date()
        setParams((pre) => {
            return {
                page: pre.page || 1,
                limit: pre.limit || 20,
                first: pre.first || 0,
                render: pre.render,
                from: databaseDate(new Date(), false, 'date'),
                to: databaseDate(new Date(), false, 'date'),
            }
        })
        setFilter(pre => ({ dates: [date], ...defaultValue }))
    }

    return (
        <div className={"mx-3 mt-3"}>
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} handleFilter={handleFilter} onClear={onClear} className={"lg:col-12"}>
                <Calendarz
                    className={"lg:col-3"}
                    value={filter.dates}
                    onChange={(e) => setFilter({...filter, dates: e.value})}
                    placeholder={"Chọn khoảng thời gian"}
                    selectionMode="range"
                />
                <Inputz
                    className="lg:col-3"
                    value={filter.plate_number}
                    onChange={(e) => setFilter({ ...filter, plate_number: e.target.value })}
                    placeholder="Biển số xe"
                />
                <Dropdownz
                    className="lg:col-3"
                    value={filter.lane_action || 'IN'}
                    options={lane_action}
                    onChange={(e) => setFilter({ ...filter, lane_action: e.target.value })}
                    placeholder="Trạng thái"
                    showClear={false}
                />
                <Dropdownz
                    className="lg:col-3"
                    value={filter.vehicle_type || 'Car'}
                    options={vehicle_type}
                    onChange={(e) => setFilter({ ...filter, vehicle_type: e.target.value })}
                    placeholder="Loại phương tiện"
                    showClear={false}
                />
            </GridForm>
            <hr />
        </div>
    )
}
const ParkingFilterList = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState({ ...initParam, from: databaseDate(new Date(), false, 'date'), to: databaseDate(new Date(), false, 'date') })
    const [selectedProductsai, setSelectedProductsAI] = useState([])
    const [selectedProductspm, setSelectedProductsPM] = useState([])
    const [render, setRender] = useState(false)
    return (
        <>
            <Header setParams={setParams} />
            <div className="grid">
                <div className="col-12 xl:col-6 p-4">
                    <ParkingSupervision
                        selectedProductsai={selectedProductsai}
                        setSelectedProductsAI={setSelectedProductsAI}
                        params={params}
                        setParams={setParams}
                        render={render}
                        setRender={setRender}
                    />
                </div>
                <div className="col-12 xl:col-6 p-4">
                    <ParkingManagement
                        selectedProductspm={selectedProductspm}
                        setSelectedProductsPM={setSelectedProductsPM}
                        params={params}
                        setParams={setParams}
                        render={render}
                        setRender={setRender}
                    />
                </div>
            </div>
            <div className="col-12">
                <HandRecognition
                    data1={selectedProductsai}
                    data2={selectedProductspm}
                    setSelectedProductsAI={setSelectedProductsAI}
                    setSelectedProductsPM={setSelectedProductsPM}
                    setRender={setRender}
                />
            </div>
            <div className="col-12">
                <ParkingComparision render={render} params={params} />
            </div>
        </>
    )
}

export default ParkingFilterList
