import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DropdownForm, FormUpdateDialog, InputForm } from '@/components'
import { useGetApartmentService, useListProgressives } from '../utils'
import moment from 'moment'
import { addDebtArise } from '../api'

const getApartmentName = (apartments, bdc_apartment_id) => {
    const apartment = apartments.find((apt) => apt.id === bdc_apartment_id)
    return apartment ? apartment.name : ''
}

const getServices = (services, serviceIdList) => {
    const uniqueServices = []
    const serviceNames = new Set()

    for (const service of services) {
        if (serviceIdList.includes(service.id) && !serviceNames.has(service.name)) {
            uniqueServices.push(service)
            serviceNames.add(service.name)
        }
    }

    return uniqueServices
}

const calculateTotal = (price, discount, vat) => {
    const totalPrice = price - discount
    const realVat = vat / 100
    const totalWithVAT = totalPrice * realVat
    const afterVat = totalPrice + totalWithVAT
    return isNaN(afterVat) ? null : afterVat
}

const AddDebtForm = (props) => {
    const { visible, setVisible, infos, setParams, params } = props

    const getListPrice = useListProgressives()
    const apartments = useSelector((state) => state.apartments)
    const services = useSelector((state) => state.services)

    const [showDiscountWarning, setShowDiscountWarning] = useState(false)

    // -------------------------- Set specificInfos --------------------------
    const [specificInfos, setSpecificInfos] = useState({
        ...infos,
        apartment_name: getApartmentName(apartments, infos.bdc_apartment_id),
        service_id: '',
        from_date: '',
        bill_date: '',
        next_billing_date: '',
        discount: 0,
        total: 0,
        priceIn: 0,
        service_id_main: '',
    })

    useEffect(() => {
        setSpecificInfos({
            ...specificInfos,
            ...infos,
            apartment_name: getApartmentName(apartments, infos.bdc_apartment_id),
        })
    }, [infos, apartments, services])

    useEffect(() => {
        if (specificInfos.service_id && services.length > 0) {
            const selectedService = services.find((service) => service.progressive_id === specificInfos.service_id)

            if (selectedService) {
                const specificProgressivePrice =
                    getListPrice.find((item) => item.id === specificInfos.service_id)?.progressivePrice ?? []

                // Ensure specificProgressivePrice is not empty before accessing its properties
                const price =
                    specificProgressivePrice && specificProgressivePrice.length > 0
                        ? specificProgressivePrice[0].price
                        : 0

                setSpecificInfos((prevState) => ({
                    ...prevState,
                    priceIn: price,
                }))
            }
        }
    }, [specificInfos.service_id, services])

    // -------------------------- Set Date for kỳ tháng, từ ngày, đến ngày --------------------------
    useEffect(() => {
        const currentDate = new Date()
        const ky_thang = currentDate.toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' })

        if (specificInfos.service_id) {
            const formattedKyThang = ky_thang.replace('/', '-')

            const fromDate = specificInfos.bill_date
                ? `${specificInfos.bill_date}/${currentDate.toLocaleDateString('en-GB', {
                      month: '2-digit',
                      year: 'numeric',
                  })}`
                : currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })

            const [day, month, year] = fromDate.split('/')

            const fromDateMoment = moment(fromDate, 'DD/MM/YYYY')
            const formattedFromDate = fromDateMoment.format('DD-MM-YYYY')

            const toDateMoment = fromDateMoment.clone().add(1, 'year')
            const formattedToDate = toDateMoment.format('DD-MM-YYYY')

            setSpecificInfos((prevSpecificInfos) => ({
                ...prevSpecificInfos,
                from_date: formattedFromDate,
                cycle_name: formattedKyThang,
                bill_date: specificInfos.bill_date || day,
                to_date: formattedToDate,
            }))
        } else {
            setSpecificInfos((prevSpecificInfos) => ({
                ...prevSpecificInfos,
                from_date: null,
                cycle_name: null,
                bill_date: null,
                to_date: null,
            }))
        }
    }, [specificInfos.bill_date, specificInfos.service_id])

    // -------------------- Ngày tính phí tiếp theo / Aparment service --------------------
    const apartmentServiceBySv = useGetApartmentService({
        status: undefined,
        apartment_id: specificInfos.bdc_apartment_id,
        ...params,
        first: undefined,
    })
    const vat = apartmentServiceBySv?.find((item) => item.hasOwnProperty('vat'))?.vat ?? 0
    const lastTimePay = apartmentServiceBySv?.[0]?.last_time_pay ?? ''
    const formattedLastTimePay = lastTimePay ? moment(lastTimePay).format('DD-MM-YYYY') : ''
    const serviceIdList = apartmentServiceBySv?.map((service) => service.bdc_service_id) ?? []

    // -------------------------- Handle Data when submit --------------------------
    const resetSpecificInfoFields = () => {
        setSpecificInfos({
            ...infos,
            apartment_name: getApartmentName(apartments, infos.bdc_apartment_id),
            service_id: '',
            from_date: '',
            bill_date: '',
            next_billing_date: '',
            discount: 0,
            total: 0,
            priceIn: 0,
            service_id_main: '',
        })
    }

    useEffect(() => {
        if (!visible) {
            resetSpecificInfoFields()
        }
    }, [visible])

    const handleData = () => {
        // Cycle name
        const [cycleMonth, cycleYear] = specificInfos.cycle_name.split('-')
        const formattedCycleName = cycleYear + cycleMonth

        // From date
        const fromDateMoment = moment(specificInfos.from_date, 'DD/MM/YYYY')
        const formattedFromDate = fromDateMoment.format('YYYY-MM-DD')

        // To date
        const toDateMoment = moment(specificInfos.from_date, 'DD/MM/YYYY')
        const formattedToDate = toDateMoment.format('YYYY-MM-DD')

        const specificInfo = {
            from_date: formattedFromDate,
            to_date: formattedToDate,
            apartment_id: infos.bdc_apartment_id,
            service_id: specificInfos.service_id_main,
            cycle_name: formattedCycleName,
            discount: specificInfos.discount,
            priceIn: specificInfos.priceIn,
        }
        resetSpecificInfoFields()
        return specificInfo
    }
    console.log(specificInfos)
    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            params={params}
            handleData={handleData}
            title="Thêm công nợ"
            actions={{ add: addDebtArise }}
        >
            <div className="flex flex-column font-semibold">
                <div className="flex">
                    <div className="flex flex-column col-6">
                        <label className="pl-2">Căn hộ</label>
                        <InputForm
                            disabled
                            value={specificInfos.apartment_name}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, apartment_name: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-column col-6">
                        <label className="pl-2">Chủ hộ</label>
                        <InputForm
                            disabled
                            value={specificInfos.customer_name}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, customer_name: e.target.value })}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '-10px' }}>
                    <div className="flex flex-column col-12">
                        <label className="pl-2">Dịch vụ</label>
                        <DropdownForm
                            showClear={false}
                            id="id"
                            value={specificInfos.service_id}
                            options={getServices(services, serviceIdList)}
                            optionLabel="name"
                            optionValue="progressive_id"
                            onChange={(e) => {
                                const selectedServiceId = e.target.value
                                const selectedService = services.find(
                                    (service) => service.progressive_id === selectedServiceId,
                                )
                                if (selectedService) {
                                    const specificProgressivePrice =
                                        getListPrice.find((item) => item.id === selectedServiceId)?.progressivePrice ??
                                        []
                                    const price =
                                        specificProgressivePrice.length > 0 ? specificProgressivePrice[0].price : 0
                                    const discount = specificInfos.discount // Get the current discount
                                    const total = calculateTotal(price, discount, vat) // Calculate the total based on the new price
                                    setSpecificInfos({
                                        ...specificInfos,
                                        service_id: selectedServiceId,
                                        bill_date: selectedService.bill_date,
                                        priceIn: price,
                                        total: total, // Update the total based on the new price
                                        service_id_main: selectedService.id,
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex" style={{ marginTop: '-10px' }}>
                    <div className="flex col-4 flex-column">
                        <label className="pl-2">Giá dịch vụ</label>
                        <InputForm
                            disabled
                            value={specificInfos.priceIn}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, service_price: e.target.value })}
                        />
                    </div>
                    <div className="flex col-4 flex-column">
                        <label className="pl-2">Ngày tính phí tiếp theo</label>
                        <InputForm
                            value={formattedLastTimePay}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, next_billing_date: e.target.value })}
                            disabled
                        />
                    </div>
                    <div className="flex col-4" style={{ marginTop: '-9px' }}>
                        <div className="col-8">
                            <label className="pl-2">Ngày chốt</label>
                            <InputForm
                                disabled
                                value={specificInfos.bill_date}
                                onChange={(e) => setSpecificInfos({ ...specificInfos, bill_date: e.target.value })}
                            />
                        </div>
                        <div className="col-4">
                            <label className="pl-2">VAT</label>
                            <InputForm
                                disabled
                                value={`${vat}%`}
                                onChange={(e) => setSpecificInfos({ ...specificInfos, vat: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex" style={{ marginTop: '-8px' }}>
                    <div className="flex col-2 flex-column">
                        <label className="pl-2">Kỳ tháng</label>
                        <InputForm
                            disabled
                            placeholder="MM-YYYY"
                            value={specificInfos.cycle_name}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, cycle_name: e.target.value })}
                        />
                    </div>
                    <div className="flex col-2 flex-column">
                        <label className="pl-2">Từ ngày</label>
                        <InputForm
                            disabled
                            placeholder="DD-MM-YYYY"
                            value={specificInfos.from_date}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, from_date: e.target.value })}
                        />
                    </div>
                    <div className="flex col-2 flex-column">
                        <label className="pl-2">Đến ngày</label>
                        <InputForm
                            disabled
                            placeholder="DD-MM-YYYY"
                            value={specificInfos.to_date}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, to_date: e.target.value })}
                        />
                    </div>
                    <div className="flex col-2 flex-column">
                        <label className="pl-2">Phí phát sinh</label>
                        <InputForm
                            disabled
                            type="number"
                            value={specificInfos.priceIn}
                            onChange={(e) => setSpecificInfos({ ...specificInfos, priceIn: e.target.value })}
                        />
                    </div>
                    <div className="flex col-2 flex-column">
                        <label className="pl-2">Giảm trừ</label>
                        <InputForm
                            required
                            type="number"
                            value={specificInfos.discount}
                            onChange={(e) => {
                                let discount
                                if (e.target.value === '' || e.target.value === null) {
                                    discount = ''
                                } else {
                                    discount = parseFloat(e.target.value)
                                }
                                if (discount > specificInfos.priceIn) {
                                    setShowDiscountWarning(true)
                                    discount = 0
                                } else {
                                    setShowDiscountWarning(false)
                                }
                                const total = calculateTotal(specificInfos.priceIn, discount || 0, vat)
                                setSpecificInfos({ ...specificInfos, discount, total })
                            }}
                        />
                        {showDiscountWarning && (
                            <span className="text-red-500">Giảm trừ không được lớn hơn phí phát sinh</span>
                        )}
                    </div>
                    <div className="flex col-2 flex-column">
                        <label className="pl-2">Thành tiền</label>
                        <InputForm value={specificInfos.total} onChange={() => {}} disabled />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default AddDebtForm
