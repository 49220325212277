import { Body, Calendarz, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, NumberFormat, TimeBody, useGetParams } from '@/components'
import { status } from '@/constants'
import { formatNumber } from '@/utils'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useListCashBookMoney } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({})
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                placeholder="Mã chứng từ"
                value={filter.key_search}
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />

            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                className="lg:col-3"
                placeholder="Khoảng thời gian ngày hạch toán"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Người thu"
            />
        </GridForm>
    )
}

export default function CashBookMoney() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCashBookMoney({ status: undefined, ...params, first: undefined })
    const apartments = useSelector((state) => state.apartments)

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="#" field="" rowSpan={2} />
                <Column header="Căn hộ" field="" rowSpan={2} />
                <Column header="Ngày tháng chứng từ" field="" rowSpan={2} />
                <Column header="Số hiệu chứng từ" colSpan={2} />
                <Column header="Diễn giải" field="" rowSpan={2} />
                <Column header="Số tiền" colSpan={3} />
                <Column header="Người thu" field="" rowSpan={2} />
            </Row>
            <Row>
                <Column header="Thu" />
                <Column header="Chi" />
                <Column header="Thu" />
                <Column header="Chi" />
                <Column header="Tồn" />
            </Row>
        </ColumnGroup>
    )
    const NumberFormat1 = (value) => {
        if (value.type === "phieu_chi" || value.type === "phieu_chi_khac") {
            return formatNumber(value.cost) || 0
        } else {
            return '0'
        }
    }
    const NumberFormat2 = (value) => {
        if (value.type === "phieu_chi" || value.type === "phieu_chi_khac") {
            return '0'
        } else {
            return formatNumber(value.cost) || 0
        }
    }
    const Code1 = (value) => {
        if (value.type === "phieu_chi" || value.type === "phieu_chi_khac") {
            return value.receipt_code
        } else {
            return ''
        }
    }
    const Code2 = (value) => {
        if (value.type === "phieu_chi" || value.type === "phieu_chi_khac") {
            return ''
        } else {
            return value.receipt_code
        }
    }
    return (
        <div className="card">
            <HeaderListForm title="Sổ quỹ tiền mặt" />
            <Header setParams={setParams} />
            <div>
                <div className='font-bold m-2'>
                    <div className='inline'>
                        Đầu kỳ : {NumberFormat({ value: data?.detail?.dau_ky }) || 0}
                    </div>
                </div>
                <div className='font-bold m-2'>
                    <div className='inline'>
                        Thu trong kỳ : {NumberFormat({ value: data?.detail?.tong_thu }) || 0}
                    </div>
                </div>
                <div className='font-bold m-2'>
                    <div className='inline'>
                        Chi trong kỳ : {NumberFormat({ value: data?.detail?.tong_chi }) || 0}
                    </div>
                </div>
                <div className='font-bold m-2'>
                    <div className='inline'>
                        Cuối kỳ : {NumberFormat({ value: data?.detail?.cuoi_ky }) || 0}
                    </div>
                </div>
            </div>
            <DataTablez
                value={data.list}
                title="sổ quỹ tiền mặt"
                headerColumnGroup={headerGroup}
                totalRecords={data.length}
                params={params}
                setParams={setParams}
                route="/cash_book_money"
            // headerInfo={{}}
            >
                <Columnz
                    field="bdc_apartment_id"
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id })} />
                <Columnz field="create_date"
                    body={e => TimeBody(e.create_date)} />
                <Columnz field="receipt_code" body={(e) => Code2(e)} />
                <Columnz field="receipt_code" body={(e) => Code1(e)} />
                <Columnz field="description" />
                <Columnz field="cost"
                    className='text-right'
                    body={(e) => NumberFormat2(e)} />
                <Columnz field="cost"
                    className='text-right'
                    body={(e) => NumberFormat1(e)} />
                <Columnz field="inventory"
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.inventory })}
                />
                <Columnz field="user_id" />
            </DataTablez>
        </div>
    )
}
