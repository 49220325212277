import { listTypePriceApi } from '@/api/projects/info';
import { CalendarForm, DropdownForm, FormUpdate, HeaderListForm, InputForm, InputNumberForm, InputSwitchForm } from '@/components';
import { listVehicleService, typeServicesGroup } from '@/constants';
import { useGetApi } from '@/hooks';
import { databaseDate } from '@/lib';
import { setServices } from '@/redux/features';
import { Button } from '@/uiCore';
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addBuildingServiceApi, listServiceV2Api, updateBuildingServiceApi } from '../api';
import { useDetailBuildingService, useListProgressives } from '../utils';

function UpdateBuildingServicesv2() {
      const { id } = useParams()
      const dispatch = useDispatch()
      let buildingServiceDetail = useDetailBuildingService(id)
      const typePrices = useGetApi(listTypePriceApi, false, [])
      const [infos, setInfos] = useState({
            status: true,
            bdc_period_id: 1,
      })
      const checkTypeVehicle = infos.type === 4
      const listProgressives = useListProgressives()
      useEffect(() => {
            if (id)
            {
                  if (buildingServiceDetail && buildingServiceDetail)
                  {
                        const typePrice = (typePrices && typePrices[0]) && typePrices.find(t => t.id === buildingServiceDetail.bdc_price_type_id)
                        setInfos({
                              ...infos,
                              ...removeUndefinedProps(buildingServiceDetail),
                              status: buildingServiceDetail.status === 1,
                              price_type_id: typePrice && typePrice.id,
                              applicable_date: buildingServiceDetail && new Date(buildingServiceDetail.first_time_active),
                        })
                  }
                  const data = checkJson(buildingServiceDetail.banggia) || buildingServiceDetail.banggia
                  const newdata = data?.map(item => {
                        return { ...item, date_price: item && new Date(item.date_price), };
                  });
                  if (Array.isArray(newdata)) setBanggia([...newdata])
            }
      }, [buildingServiceDetail, typePrices, id])
      const handleData = () => {
            const newBanggia = banggia?.map(item => {
                  return { ...item, date_price: item.date_price && databaseDate(item.date_price,), };
            });
            let info = {
                  ...infos,
                  status: infos.status ? 1 : 0,
                  ngay_chuyen_doi: infos.ngay_chuyen_doi === 0 ? undefined : infos.ngay_chuyen_doi,
                  vat: infos.vat === '' ? 0 : infos.vat,
                  surcharge: infos.surcharge === '' ? 0 : infos.surcharge,
                  vat_surcharge: infos.vat_surcharge === '' ? 0 : infos.vat_surcharge,
                  type_service: 1,
                  banggia: newBanggia,
                  applicable_date: infos.applicable_date && databaseDate(infos.applicable_date),
            }
            if (infos.type !== 0 && !infos.type) return 'Vui lòng chọn loại dịch vụ'
            if (!infos.bill_date) return 'Vui lòng chọn ngày chốt kỳ'
            if (!infos.service_group) return 'Vui lòng chọn đối tượng thu'
            if (parseFloat(info.vat) >= 100) {
                  return 'Phí VAT không được lớn hơn hoặc bằng 100%'
            }
            if (parseFloat(info.surcharge) >= 100) {
                  return 'Phí môi trường không được lớn hơn hoặc bằng 100%'
            }
            if (parseFloat(info.vat_surcharge) >= 100) {
                  return 'Phí VAT của phí môi trường không được lớn hơn hoặc bằng 100%'
            }
            if (id) {
                  info = { ...removePropObject(info, buildingServiceDetail), id: id, }
            }
            return info
      }
      const items1 = Array.from({ length: 29 }).map((_, i) => {
            if (i === 0) return { name: 'Ngày chốt kỳ', id: 0 }
            return { name: `${i}`, id: i }
      })
      const items = Array.from({ length: 29 }).map((_, i) => {
            if (i === 0) return { name: 'Ngày chuyển đổi', id: 0 }
            return { name: `${i}`, id: i }
      })
      const [banggia, setBanggia] = useState([])
      const handleAdd = () => {
            const newItem = {};
            setBanggia((prevData) => [...prevData, newItem]);
      }
      const handleChange = (value, field, index) => {

            setBanggia(prevData => {
                  const newData = [...prevData];
                  newData[index] = { ...newData[index], [field]: value };
                  return newData;
            });
      };
      const handleDelete = (indexV2) => {
            if (indexV2 || indexV2 === 0) setBanggia((pre) => pre.filter((d, index) => index !== indexV2))
      }
      const renderProgressivePricev2 = (index) => {
            const progressive = listProgressives.find((progressive) => progressive.id === banggia[index].progressive_id)
            if (progressive) {
                  const progressivePrice = progressive.progressivePrice || []
                  if (progressive.bdc_price_type_id === 5) {
                        return progressivePrice.map((price, index) => {
                              return (
                                    <div key={index} className="grid">
                                          <div className="col-3">
                                                <InputForm value={`Bậc ${index + 1}`} disabled label="Loại" />
                                          </div>
                                          <div className="col-3">
                                                <InputNumberForm value={price.price} disabled label="Giá" />
                                          </div>
                                          <div className="col-3">
                                                <InputForm value={'VNĐ'} label="Đơn vị tính" disabled />
                                          </div>
                                          <div className="col-3">
                                                <InputForm value={price.description || " "} label="Description" disabled />
                                          </div>
                                    </div>
                              )
                        })
                  } else {
                        if (progressivePrice.length === 1 && progressivePrice[0].from === 0) {
                              return (
                                    <div className="grid">
                                          <div className="col-4">
                                                <InputNumberForm label="Giá" value={progressivePrice[0].price} disabled />
                                          </div>
                                          <div className="col-4">
                                                <InputForm value={'VNĐ'} label="Đơn vị tính" disabled />
                                          </div>
                                    </div>
                              )
                        } else {
                              return progressivePrice.map((prices, index) => {
                                    return (
                                          <div key={index} className="grid">
                                                <div className="col-3">
                                                      <InputForm value={prices.from} disabled label="Từ" />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm value={prices.to} disabled label="Tới" />
                                                </div>
                                                <div className="col-3">
                                                      <InputNumberForm value={prices.price} disabled label="Giá" />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm value={'VNĐ'} label="Đơn vị tính" disabled />
                                                </div>
                                          </div>
                                    )
                              })
                        }
                  }
            }
      }
      const renderProgressivePrice = () => {
            const progressive = listProgressives.find((progressive) => progressive.id === infos.progressive_id)
            if (progressive) {
                  const progressivePrice = progressive.progressivePrice || []
                  if (progressive.bdc_price_type_id === 5) {
                        return progressivePrice.map((price, index) => {
                              return (
                                    <div key={index} className="grid">
                                          <div className="col-3">
                                                <InputForm value={`Bậc ${index + 1}`} disabled label="Loại" />
                                          </div>
                                          <div className="col-3">
                                                <InputNumberForm value={price.price} disabled label="Giá" />
                                          </div>
                                          <div className="col-3">
                                                <InputForm value={'VNĐ'} label="Đơn vị tính" disabled />
                                          </div>
                                          <div className="col-3">
                                                <InputForm value={price.description || " "} label="Description" disabled />
                                          </div>
                                    </div>
                              )
                        })
                  } else {
                        if (progressivePrice.length === 1 && progressivePrice[0].from === 0) {
                              return (
                                    <div className="grid">
                                          <div className="col-4">
                                                <InputNumberForm label="Giá" value={progressivePrice[0].price} disabled />
                                          </div>
                                          <div className="col-4">
                                                <InputForm value={'VNĐ'} label="Đơn vị tính" disabled />
                                          </div>
                                    </div>
                              )
                        } else {
                              return progressivePrice.map((prices, index) => {
                                    return (
                                          <div key={index} className="grid">
                                                <div className="col-3">
                                                      <InputForm value={prices.from} disabled label="Từ" />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm value={prices.to} disabled label="Tới" />
                                                </div>
                                                <div className="col-3">
                                                      <InputNumberForm value={prices.price} disabled label="Giá" />
                                                </div>
                                                <div className="col-3">
                                                      <InputForm value={'VNĐ'} label="Đơn vị tính" disabled />
                                                </div>
                                          </div>
                                    )
                              })
                        }
                  }
            }
      }
      const handleAfterCallApi = async () => {
            const getService = await listServiceV2Api()
            if (getService.data && getService.data.status) {
                  const services = getService.data.data
                  dispatch(setServices(services))
            }
      }
      return (
            <FormUpdate
                  checkId={(id)}
                  actions={{ add: addBuildingServiceApi, update: updateBuildingServiceApi }}
                  title='dịch vụ tòa nhà'
                  route="/services"
                  handleData={handleData}
                  handleAfterCallApi={handleAfterCallApi}
                  refreshObjects={[setInfos]}

            >
                  <div className="grid grid-form">
                        <div className="col-12 lg:col-8">
                              <div className="card">
                                    <HeaderListForm title="Thông tin dịch vụ" />
                                    <DropdownForm
                                          value={infos.price_type_id}
                                          options={typePrices}
                                          onChange={(e) => {
                                                const typePrice = typePrices.find(t => t.id === e.target.value)
                                                setInfos({ ...infos, price_type_id: e.target.value, type: typePrice.type_sv, progressive_id: undefined })
                                          }}
                                          label="Loại dịch vụ (*)"
                                    />

                                    {checkTypeVehicle && (
                                          <DropdownForm
                                                filter={false}
                                                value={infos.type_vehicle}
                                                options={listVehicleService}
                                                onChange={(e) => setInfos({ ...infos, type_vehicle: e.target.value })}
                                                label="Loại phương tiện (*)"
                                          />
                                    )}
                                    <div className="grid">
                                          <div className="col-12">
                                                <InputForm
                                                      id="name"
                                                      value={infos.name}
                                                      onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                                      label="Tên dịch vụ (*)"
                                                      required
                                                />
                                          </div>
                                          <div className="col-6">
                                                <DropdownForm
                                                      filter={false}
                                                      value={infos.service_group}
                                                      options={typeServicesGroup}
                                                      onChange={(e) => setInfos({ ...infos, service_group: e.target.value })}
                                                      label="Đối tượng thu (*)"
                                                />
                                          </div>
                                          <div className="col-6">
                                                <InputForm
                                                      id={'code_receipt'}
                                                      value={infos.code_receipt}
                                                      onChange={(e) => setInfos({ ...infos, code_receipt: e.target.value })}
                                                      label="Mã Phí (*)"
                                                      required
                                                />
                                          </div>
                                          <div className="col-6">
                                                <InputForm
                                                      value={infos.budget_code}
                                                      onChange={(e) => setInfos({ ...infos, budget_code: e.target.value })}
                                                      label="Mã ngân sách "
                                                />
                                          </div>
                                          <div className="col-6">
                                                <InputNumberForm
                                                      value={infos.vat}
                                                      handleOnChange={(e) => setInfos({ ...infos, vat: e })}
                                                      label="Phí VAT (%)"
                                                />
                                          </div>
                                          {infos.type === 3 ? (
                                                < >
                                                      <div className="col-6">
                                                            <InputNumberForm
                                                                  value={infos.surcharge}
                                                                  handleOnChange={(e) => setInfos({ ...infos, surcharge: e })}
                                                                  label="Phí môi trường (%)"
                                                            />
                                                      </div>
                                                      <div className="col-6">
                                                            <InputNumberForm
                                                                  value={infos.vat_surcharge}
                                                                  handleOnChange={(e) => setInfos({ ...infos, vat_surcharge: e })}
                                                                  label="Phí VAT của phí môi trường (%)"
                                                            />
                                                      </div>
                                                </>
                                          ) : null}
                                    </div>
                              </div>
                              <div className="card">
                                    <HeaderListForm title="Thông tin bảng giá" />
                                    <div>
                                          {banggia.map((d, index) => {
                                                const option = infos.price_type_id ? listProgressives.filter(l => l.price_type_id === infos.price_type_id) : []
                                                return (
                                                      <div className="grid grid-form" key={index}>
                                                            <div className="col-11 lg:col-11">
                                                                  <div className="grid grid-form">
                                                                        <div className="col-6 lg:col-6">
                                                                              <DropdownForm
                                                                                    filter={false}
                                                                                    value={d.progressive_id}
                                                                                    options={option}
                                                                                    onChange={(e) => handleChange(e.target.value, 'progressive_id', index)}
                                                                                    label={`Bảng giá`}
                                                                              />
                                                                        </div>
                                                                        <div className="col-6 lg:col-6">
                                                                              <CalendarForm
                                                                                    label="Ngày áp dụng bảng giá"
                                                                                    value={d.date_price}
                                                                                    onChange={(e) => handleChange(e.target.value, 'date_price', index)}
                                                                              />
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className='col-1 lg:col-1 px-auto flex'>
                                                                  <Button
                                                                        className='m-auto flex align-items-center justify-content-center'
                                                                        type="button"
                                                                        rounded
                                                                        outlined
                                                                        icon="pi pi-trash"
                                                                        onClick={() => handleDelete(index)}
                                                                        severity="danger"
                                                                  />
                                                            </div>
                                                            {renderProgressivePricev2(index)}
                                                      </div>
                                                );
                                          })}
                                          {infos.type === 5 || infos.type === 3 || banggia.length === 0 ? (
                                                <Button label="Thêm bảng giá" className='m-2' onClick={handleAdd} type="button" />
                                          )
                                                : ''
                                          }
                                    </div>
                              </div>
                        </div>
                        <div className="col-12 lg:col-4">
                              <div className="card">
                                    <HeaderListForm title="Thông tin hạch toán" />
                                    <InputForm
                                          id="bdc_period_id"
                                          value="1 tháng"
                                          onChange={(e) => setInfos({ ...infos, bdc_period_id: 1 })}
                                          label="Chu kỳ thanh toán (Tháng) "
                                          disabled
                                    />
                                    <DropdownForm
                                          filter={false}
                                          value={infos.bill_date}
                                          options={items1}
                                          onChange={(e) => setInfos({ ...infos, bill_date: e.target.value })}
                                          label="Ngày đầu kì số liệu (*)"
                                    />
                                    <DropdownForm
                                          filter={false}
                                          value={infos.ngay_chuyen_doi}
                                          options={items}
                                          onChange={(e) => setInfos({ ...infos, ngay_chuyen_doi: e.target.value })}
                                          label="Ngày chuyển đổi"
                                    />
                                    <CalendarForm
                                          value={infos.applicable_date}
                                          onChange={(e) => setInfos({ ...infos, applicable_date: e.target.value })}
                                          label="Ngày áp dụng (*)"
                                    />

                                    <InputSwitchForm
                                          label="Trạng thái"
                                          id="status"
                                          value={infos.status}
                                          onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                          checked={infos.status}
                                    />
                              </div>
                        </div>
                  </div>
            </FormUpdate>
      )
}

export default UpdateBuildingServicesv2
