const { postData, getData } = require('@/lib/request')

// Tỉ lệ thanh toán
export const getPaymentRate = (params) => getData('web2/statistic_report/reportPayoutRatio', params)
// Dư nợ cuối kỳ
export const getEndingDept = (params) => getData('web2/statistic_report/balanceChange', params)
// Tỷ lệ dư nợ hiện tại theo loại DV:
export const getPresentDeptRate = (params) => getData('web2/statistic_report/debitOwe', params)
// Tỷ lệ dư nợ hiện tại theo nhóm nợ
export const getGroupDeptRate = (params) => getData('web2/statistic_report/debtWarning', params)
