import { postData, getData } from '@/lib/request'

export const listUserApi = (params) => getData('web2/user/getListUser', params)
export const listUserV2Api = (params) => getData('web2/info/getListEmployee', params)
export const countUserApi = (params) => getData('web2/user/countUser', params)
export const detailUserApi = (params) => getData('web2/user/getDetailUserInfo', params)
export const deleteUserApi = (params) => postData('web2/user/deleteUser', params)
export const addUserApi = (params) => postData('web2/user/addUser', params, true)
export const updateUserApi = (params) => postData('web2/user/updateUser', params, true)
export const resetPassUserApi = (params) => postData('web2/user/restartPassword', params)
