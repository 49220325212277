import { FormUpdateDialog, InputForm } from "@/components"
import { useState } from 'react'
import { addVehicleCategoryApiApi } from "../api"

const UpdateVehicleCategory = (props) => {
    const { visible, setVisible, setParams } = props
    const [infos, setInfos] = useState({
        name: '',
        description: '',
    })
    const handleData = () => {
        let info = {
            ...infos,
            name: infos.name,
            description: infos.description
        }
        if (!infos.name) return 'Vui lòng nhập tên loại phương tiện!'
        return info
    }
    return (
        <>
            <FormUpdateDialog
                visible={visible}
                setVisible={setVisible}
                setParams={setParams}
                route="/services"
                handleData={handleData}
                refreshObjects={[setInfos]}
                // // handleAfterCallApi={handleAfterCallApi}
                actions={{ add: addVehicleCategoryApiApi }}
                title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'loại phương tiện'}
            >
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6  ">
                        <InputForm
                            id="name"
                            value={infos.name}
                            onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                            label="Tên loại phương tiện (*)"
                            required
                        />
                    </div>
                    <div className="col-12 lg:col-6  ">
                        <InputForm
                            id="description"
                            value={infos.description}
                            onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                            label="Mô tả"
                        />
                    </div>
                </div>
            </FormUpdateDialog>
        </>
    )
}

export default UpdateVehicleCategory