import { getData, postData } from '@/lib/request'

export const listReceiptImportApi = (params) => getData('web2/receipt_import/getListHistoryTransaction', params)
export const listServicesApi = (params) => getData('web2/info/getListServiceApartment', params)
export const countReceiptImportApi = (params) => getData('web2/receipt_import/countHistoryTransaction', params)
export const detailReceiptImportApi = (params) => getData('web2/receipt_import/detailHistoryTransaction', params)
export const updateReceiptImportApi = (params) => postData('web2/receipt_import/addReceiptTransaction', params)
export const cancelAccountingApi = (params) => postData('web2/receipt_import/cancelAccounting', params)

export const importReceipt = (params) =>
    postData('/web2/receipt_import/impExlTransactionPayment', params, false, true)
