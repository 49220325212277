import { Body, Columnz, DataTablez, Dropdownz, HeaderListForm, TimeBody, useGetParams } from '@/components'
import { ticket_type, vehicle_type } from '@/constants'
import { databaseDate } from '@/lib'
import React, { useState } from 'react'
import { Calendarz, GridForm } from '../../parking_check/screens/ParkingForm'
import {
    useListSyntheticLogCarParking,
} from '../utils'
import { Image } from '@/uiCore'
import { expSyntheticLogCarParkingApi } from '@/modules/parking_supervision/management_revenue/api'
import {formatNumber} from "@/utils";

const Header = ({ setParams }) => {
    const today = new Date()
    const [filter, setFilter] = useState(() => {
        return {dates: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)]}
    })
    return (
        <div className={"mx-2 mt-3"}>
            <HeaderListForm title="Danh sách doanh thu" />
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
                <Calendarz
                    className={"lg:col-3"}
                    value={filter.dates}
                    onChange={(e) => setFilter({...filter, dates: e.value})}
                    placeholder={"Chọn khoảng thời gian"}
                    selectionMode={"range"}
                />
                <Dropdownz
                    value={filter.ticket_type}
                    options={ticket_type}
                    onChange={(e) => setFilter({ ...filter, ticket_type: e.target.value })}
                    placeholder="Loại thẻ"
                />
                <Dropdownz
                    value={filter.vehicle_type}
                    options={vehicle_type}
                    onChange={(e) => setFilter({ ...filter, vehicle_type: e.target.value })}
                    placeholder="Loại phương tiện"
                />
            </GridForm>
            <hr/>
        </div>
    )
}
const ManageRevenue = () => {
    const initParam = useGetParams()
    const today = new Date()
    const [params, setParams] = useState({
        from: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
        to: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)),
        ...initParam,
    })
    const { data, count, synthetic } = useListSyntheticLogCarParking({ status: undefined, ...params, dates: undefined, first: undefined })

    return (
        <div className="card">
            <Header setParams={setParams} />
            <div style={{ lineHeight: "2", marginBottom: '8px' }} className={"mx-2"}>
                <strong>Xe máy: {formatNumber(synthetic?.Motor || '0')} VNĐ</strong> <br />
                <strong>Ô tô: {formatNumber(synthetic?.Car || '0')} VNĐ</strong><br />
                <strong>Xe máy điện: {formatNumber(synthetic?.ElectricMotor || '0')} VNĐ</strong><br />
                <strong>Xe đạp: {formatNumber(synthetic?.Bicycle || '0')} VNĐ</strong><br />
            </div>

            <DataTablez
                value={data}
                title="doanh thu"
                totalRecords={count || 0}
                params={{ ...params, dates: undefined }}
                setParams={setParams}
                route="/management_revenue"
                headerInfo={{ exportApi: () => expSyntheticLogCarParkingApi(params) }}
                basePermissions={['export']}
            >
                <Columnz header="Thời gian vào"  body={e => TimeBody(e.time_in)}/>
                <Columnz header="Thời gian ra"   body={e => TimeBody(e.time_out)}/>
                <Columnz header="Giá tiền" body={e => <span className={"font-bold text-lg"}>{formatNumber(e.amount_money)}</span> } />
                <Columnz header="Thời gian trong bãi" body={e => {
                    return <div className={"flex flex-column gap-2"}>
                        <span>Thời gian: {e.parking_time}</span>
                        <span>Ghi chú: {e.parking_time_string}</span>
                    </div>
                }}  />
                <Columnz
                    header="Hình ảnh vào"
                    body={e => <Image
                        width="100"
                        height="70"
                        src={e.image_in}
                        preview
                        style={{ width: '100px', height: '65px', cursor: 'pointer' }}
                    />}
                />
                <Columnz header="Biển kiểm soát vào" field="place_number_in" />
                <Columnz
                    header="Hình ảnh ra"
                    body={e => <Image
                        width="100"
                        height="70"
                        preview
                        src={e.image_out}
                        style={{ width: '100px', height: '65px', cursor: 'pointer' }}

                    />}
                />
                <Columnz header="Biển kiểm soát ra" field="place_number_out" />
                <Columnz header="Loại thẻ" body={e => e.ticket_type === 'Monthly' ? 'Thẻ tháng' :
                    'Thẻ lượt'} />
                <Columnz header="Số thẻ" field="card_number"  />
                <Columnz header="Loại phương tiện"  body={e => Body({data: vehicle_type, value: e.vehicle_type})} />
            </DataTablez>
        </div>
    )
}
export default ManageRevenue
