import { useEffect, useState } from 'react'
import {
      Columnz,
      DataTablez,
      useGetParams,
      InputForm,
      InputTextareaForm,
      DropdownForm,
      HeaderListForm,
      FormUpdateDialog,
      LoadDialog,
      Dialogz,
      InputNumberForm,
      CalendarForm,
      UploadFiles,
      Calendarz,
} from '@/components'
import { Dropdownz, GridForm, Inputz } from '@/components'
import { useCountAsset } from '../../asset_mix_management/utils'
import { Button } from 'primereact/button'








const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({ key_search: '' })
      const listCategoryData = [
            { id: 1, name: 'Thiết bị điện' },
            { id: 2, name: 'Đồ gia dụng' },
            { id: 3, name: 'Phòng cháy chữa cháy' },
            { id: 4, name: 'Đồ điện tử' },
            { id: 5, name: 'Đồ Thông Cống' },
            { id: 6, name: 'Thiết bị thông minh' },
      ]
      const [date, setDate] = useState(new Date());

      // Hàm để cập nhật trạng thái khi người dùng chọn một ngày
      const onDateChange = (e) => {
            setDate(e.value);
      };
      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
                  <Inputz
                        value={filter.key_search}
                        placeholder="Nhập từ khóa"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
                  />

                  <Calendarz value={date} onChange={onDateChange} dateFormat="dd/mm/yy" />
            </GridForm>
      )
}

export default function Maintenance() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      // const data = useListAsset({ status: undefined, ...params, first: undefined })
      const totalRecords = useCountAsset({ status: undefined, ...params, first: undefined }) || 0
      const [visible, setVisible] = useState(false)
      const [files, setFiles] = useState([])

      return (
            <>
                  <HeaderListForm title="Quản lý bảo trì, bảo dưỡng" />
                  <Header setParams={setParams} />
                  <DataTablez
                        value={[]}
                        totalRecords={0}
                        title=""
                        params={params}
                        setParams={setParams}
                        route="/output_asset"
                        headerInfo
                        actionsInfo
                        basePermissions={["insert", "detail",]}
                  >
                        <Columnz field="name" header="Tên sản phẩm" />
                        <Columnz field="name" header="Nhóm" />
                        <Columnz field="categogy" header="Người sử dụng" />
                        <Columnz field="typeMaintain" header="Vị trí " />

                  </DataTablez>
            </>
      )
}
