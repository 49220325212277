import {
    Body, Columnz,
    DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, LoadDialog, StatusBody, useGetParams
} from '@/components'
import { status, time_unit } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteAssetMix, updateAssetMix } from '../api'
import { useCountProduct, useListAsset, useListProductGroup } from '../utils'
import UpdateAsset from './UpdateAsset'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    // const listCategoryData = [
    //     { id: 1, name: 'Thiết bị điện' },
    //     { id: 2, name: 'Đồ gia dụng' },
    //     { id: 3, name: 'Phòng cháy chữa cháy' },
    //     { id: 4, name: 'Đồ điện tử' },
    //     { id: 5, name: 'Đồ Thông Cống' },
    //     { id: 6, name: 'Thiết bị thông minh' },
    // ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập tên danh mục sản phẩm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
        </GridForm>
    )
}

export default function AssetList() {

    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListAsset({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountProduct({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)
    const dataV2 = useListProductGroup({ status: undefined, ...params, first: undefined })
    const users = useSelector((state) => state.users)
    const TimeUnitBody = (rowData) => {
        const name = time_unit.find(t => t.id === rowData.time_unit)
        return (
            <>
                {rowData.time} lần/{name.name}
            </>
        )
    }
    return (
        <>
            {visible && <UpdateAsset setParams={setParams} visible={visible} setVisible={setVisible} />}
            <LoadDialog visible={visible} />

            <HeaderListForm title="Danh mục sản phẩm" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="nhóm sản phẩm"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/asset"
                setVisibledDialog={setVisible}
                headerInfo
                actionsInfo={{ deleteAction: deleteAssetMix }}
                basePermissions={["insert", "detail",]}

            >
                <Columnz field="code" header="Mã sản phẩm" />
                <Columnz field="name" header="Tên danh mục sản phẩm" />
                <Columnz body={(e) => Body({ data: dataV2, value: e.product_group_id })} header="Tên nhóm sản phẩm" />
                <Columnz
                    body={(e) => {


                        const user = users.find(u => u.user_id === e.create_by) || {}

                        return <div>
                            {user.full_name || "Admin"}
                        </div>

                    }}
                    header="Người tạo"
                />
                <Columnz field="note" header="Mô tả" />
                <Columnz body={rowData => TimeUnitBody(rowData)} header="Chu kỳ bảo trì, bảo dưỡng" />
                <Columnz
                    header="Trạng thái danh mục"
                    body={(e) => StatusBody({ e, route: '/asset_mix_management', updateAction: updateAssetMix })}
                />
            </DataTablez>
        </>
    )
}
