import { useEffect, useState } from 'react'
import { countTicket, revenue7LastDay, revenueSyntheticLogCarParking, vehicleTurn } from '../api'
export const useRevenueSyntheticLogCarParking = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await revenueSyntheticLogCarParking({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useRevenue7LastDay = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await revenue7LastDay({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useVehicleTurn = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await vehicleTurn({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useCountTicket = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await countTicket({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
