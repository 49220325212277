import { useEffect, useState } from 'react'
import { countReceptFormApi, detailReceptFormApi, listReceptFormApi } from '../api'

export const useListReceptForm = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listReceptFormApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountReceptForm = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countReceptFormApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailReceptForm = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailReceptFormApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
