import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addCommentApi, deleteCommentApi, switchStatusRequestApi } from '../api'
import { useDetailBookingTimePlace, useListBookingComment } from '../utils'
import { Comments, SendMessage } from './Comment'

const BookingComment = () => {
    const { id } = useParams()
    const [render, setRender] = useState(false)
    const listComments = useListBookingComment(id, render)
    const [comments, setComments] = useState([])

    useEffect(() => {
        if (listComments && Array.isArray(listComments)) {
            setComments([...listComments])
        }
    }, [listComments])

    const handleAddComment = (params) => {
        return { ...params, post_id: Number(id) }
    }

    return (
        <div className="">
            <div className="grid grid-form">
                <div className="col-12 lg:col-4">
                    <SendMessage
                        id="0"
                        addApi={addCommentApi}
                        handleAddComment={handleAddComment}
                        setRender={setRender}
                    />
                </div>
                <div className="col-12 lg:col-8">
                    <Comments
                        switchStatusApi={switchStatusRequestApi}
                        addApi={addCommentApi}
                        deleteApi={deleteCommentApi}
                        setRender={setRender}
                        handleAddComment={handleAddComment}
                        comments={comments}
                    />
                </div>
            </div>
        </div>
    )
}

export default BookingComment
