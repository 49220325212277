import { useEffect, useState } from 'react'
import {
    listSyntheticLogCarParkingApi,
} from '../api'

export const useListSyntheticLogCarParking = (params) => {
    const [data, setData] = useState([])

    async function fetchData() {
        const response = await listSyntheticLogCarParkingApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }

    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
