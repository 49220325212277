import {
    Columnz,
    DataTablez, Dropdownz, FormUpdateDialog,
    GridForm,
    HeaderListForm,
    InputForm,
    InputTextareaForm,
    Inputz,
    useGetParams
} from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addDoupicateApi, deleteDoupicateApi, updateDoupicateApi } from '../api'
import { useCountDoupicate, useDetailDoupicate, useListDoupicate } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    const listCategoryData = [
        { id: 1, name: 'Thiết bị điện' },
        { id: 2, name: 'Đồ gia dụng' },
        { id: 3, name: 'Phòng cháy chữa cháy' },
        { id: 4, name: 'Đồ điện tử' },
        { id: 5, name: 'Đồ Thông Cống' },
        { id: 6, name: 'Thiết bị thông minh' },
    ]

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.category}
                options={listCategoryData}
                onChange={(e) => setFilter({ ...filter, category: e.target.value })}
                placeholder="Chọn danh mục"
            />
        </GridForm>
    )
}

export default function ManageDouplicate() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListDoupicate({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDoupicate({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    const { id } = useParams()
    const douplicateDetail = useDetailDoupicate(id)
    const [infos, setInfos] = useState({
        name: '',
        category: '',
        type_maintain: '',
        time_maintain: '',
        description: '',
    })
    useEffect(() => {
        if (douplicateDetail.id)
            setInfos({ ...infos, ...douplicateDetail, status: douplicateDetail.status === 0 ? false : true })
    }, [douplicateDetail])

    const handleData = () => {
        let info = { ...infos }
        if (Number(id)) info = { ...removePropObject(info, douplicateDetail), id: id }
        return info
    }

    const RenderDialog = () => {
        return (
            <FormUpdateDialog
                title={(typeof visible === 'number' ? 'Cập nhật' : 'Thêm mới') + ' Công việc trùng lặp'}
                handleData={handleData}
                visible={visible}
                route="/work_management"
                setVisible={setVisible}
                refreshObjects={[setInfos]}
                actions={{ add: addDoupicateApi, update: updateDoupicateApi }}
            >
                <div className="card bg-color">
                    <div className="grid grid-form">
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="name"
                                value={infos.name}
                                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                label="Tên tài sản (*)"
                                required
                            />
                            <InputForm
                                id="category"
                                value={infos.category}
                                onChange={(e) => setInfos({ ...infos, category: e.target.value })}
                                label="Danh mục"
                            />
                        </div>
                        <div className="col-12 lg:col-6">
                            <InputForm
                                id="type_maintain"
                                value={infos.type_maintain}
                                onChange={(e) => setInfos({ ...infos, type_maintain: e.target.value })}
                                label="Kiểu bảo trì"
                            />
                            <InputForm
                                id="time_maintain"
                                value={infos.time_maintain}
                                onChange={(e) => setInfos({ ...infos, time_maintain: e.target.value })}
                                label="Thời gian bảo trì"
                            />
                        </div>
                        <div className="col-12">
                            <InputTextareaForm
                                id="description"
                                value={infos.description}
                                onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                                label="Ghi chú"
                            />
                        </div>
                    </div>
                </div>
            </FormUpdateDialog>
        )
    }
    return (
        <>
            <RenderDialog />
            <HeaderListForm title="Thông tin tòa nhà" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Công việc"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/work_management"
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteDoupicateApi }}
            >
                <Columnz field="name" header="Tên công việc" />
                <Columnz field="status" header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
                <Columnz field="date_create" header="Ngày tạo" />
                <Columnz field="creator" header="Người tạo" />
            </DataTablez>
        </>
    )
}
