import {
    CalendarForm,
    DropdownForm,
    Editorz,
    FormUpdate,
    HeaderListForm,
    InputForm,
    InputSwitchForm,
    Inputz,
    MultiCheckBox,
    MultiSelectForm,
    UploadFiles,
    UploadImg,
    UploadMultiImage
} from '@/components'
import { notify_category } from '@/constants'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDetailPost, useListBuilding, useListResident, useListApartment, useListPostCate } from '../utils'
import AddQuestion from './AddQuestion'
import { addPostApi, updatePostApi } from '../api'
import { Checkbox } from 'primereact/checkbox'
import NotificationComment from './CommentNotification'
import { databaseDate } from '@/lib'

const UpdateNotifications = () => {
    const [infos, setInfos] = useState({
        status: true,
        notify: {
            check_comments: 1, send_to: 1, all_selected: 0,
            place_selected: 0, group_selected: 0, customer_selected: 0,
            is_sent: 0, is_sent_sms: 0, send_mail: 0, send_sms: 0, send_app: 0,
            place_ids: [], group_ids: [], customer_ids: [], allNotify: []
        }
    })
    const [image, setImage] = useState([])
    const { id } = useParams()
    const postDetail = useDetailPost(id)
    const listPostCate = useListPostCate()

    const [visible, setVisible] = useState(false)
    const [files, setFiles] = useState([])
    const [ingredients, setIngredients] = useState([]);
    const buildings = useListBuilding()
    const residents = useListResident(infos.place_ids && infos?.place_ids[0])
    const apartments = useListApartment(infos.place_ids && infos?.place_ids[0])
    const [pollOptions, setPollOptions] = useState([])
    const [indexQuestion, setIndexQuestion] = useState()
    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }
    useEffect(() => {
        if (postDetail.id) {
            let notify = checkJson(postDetail.notify) || postDetail.notify
            let newIngredients = [];
            let allNotify = [];

            const keys = ['all_selected', 'place_selected', 'group_selected', 'customer_selected'];
            const values = ['all', 'building', 'apartment', 'resident'];

            keys.forEach((key, index) => {
                if (notify[key] === "1" || notify[key] === 1) {
                    newIngredients.push(values[index]);
                }
            });

            ['send_app', 'send_mail', 'send_sms'].forEach(key => {
                switch (key) {
                    case 'send_app':
                        if (notify[key] === "1" || notify[key] === 1) {
                            allNotify.push('appNotify');
                        }
                        break;
                    case 'send_mail':
                        if (notify[key] === "1" || notify[key] === 1) {
                            allNotify.push('send_mail');
                        }
                        break;
                    case 'send_sms':
                        if (notify[key] === "1" || notify[key] === 1) {
                            allNotify.push('sms');
                        }
                        break;
                    default:
                        break;
                }
            });
            ['place_ids', 'group_ids', 'customer_ids'].forEach(key => {
                if (notify[key] && notify[key].length > 0) {
                    infos[key] = notify[key];
                }
            });

            setIngredients(newIngredients);
            setInfos({
                ...infos,
                ...removeUndefinedProps(postDetail),
                status: Number(postDetail.status) === 1,
                notify: notify,
                allNotify: allNotify,
                start_at: postDetail.start_at ? new Date(postDetail.start_at) : undefined,
                end_at: postDetail.end_at ? new Date(postDetail.end_at) : undefined,

            });
            if (postDetail.listPollOptions && postDetail.listPollOptions[0]) {
                setPollOptions(postDetail.listPollOptions.map(p => ({
                    ...p,
                    options: checkJson(p.options) || p.options
                })))
            }
            if (postDetail.file) {
                const file = checkJson(postDetail.file) || postDetail.file
                if (Array.isArray(file)) {
                    setFiles([...file])
                }
            }
            if (postDetail.image) {
                setImage(postDetail.image)
            }
        }
    }, [postDetail]);


    const handleData = () => {
        let info = {
            ...infos,
            list_poll_options: pollOptions,
            notify: {
                ...infos.notify, check_comments: 1, send_to: 1,
                place_ids: infos.place_ids ? infos.place_ids : [],
                group_ids: infos.group_ids ? infos.group_ids : [],
                customer_ids: infos.customer_ids ? infos.customer_ids : [],
                all_selected: ingredients.includes('all') ? 1 : 0,
                place_selected: ingredients.includes('building') ? 1 : 0,
                group_selected: ingredients.includes('apartment') ? 1 : 0,
                customer_selected: ingredients.includes('resident') ? 1 : 0,
            },
            status: infos.status ? 1 : 0
        }
        info.notify.send_mail = 0
        info.notify.is_sent = 0
        info.notify.send_sms = 0
        info.notify.is_sent_sms = 0
        info.notify.send_app = 0

        infos.allNotify && infos.allNotify.forEach(e => {
            if (e === 'send_mail') {
                info.notify.send_mail = 1
                info.notify.is_sent = 1
            }
            if (e === 'sms') {
                info.notify.send_sms = 1
                info.notify.is_sent_sms = 1
            }
            if (e === 'appNotify') {
                info.notify.send_app = 1
                info.notify.is_sent_sms = 1

            }
        });

        if (!info.category_id) return 'Vui lòng chọn danh mục!'

        if (!info.allNotify || info.allNotify.length === 0) {
            return "Vui lòng chọn nơi gửi đến!";
        }
        if (ingredients.length === 0) {
            return "Vui lòng chọn khách hàng nhận tin!";
        }
        delete info.allNotify
        delete info.place_ids
        delete info.group_ids
        delete info.customer_ids

        if (Number(id)) {
            let newPollOptions = []
            let oldListPrice = postDetail.listPollOptions
            let newListPrice = pollOptions
            info = { ...removePropObject(info, postDetail), id: Number(id) }
            const deleteElements = oldListPrice.filter((oldP) => !newListPrice.some((newP) => oldP.id === newP.id))
            if (deleteElements && deleteElements[0]) {
                deleteElements.forEach((del) => {
                    newPollOptions.push({ id: del.id, deleted_at: 1 })
                })
            }
            oldListPrice.forEach((oldP, index) => {
                const elements = newListPrice.find((newP) => newP.id === oldP.id)
                if (elements && JSON.stringify({
                    title: elements.title,
                    maximum: elements.maxium,
                    options: elements.options
                }) !== JSON.stringify({
                    title: oldP.title,
                    maximum: oldP.maxium,
                    options: checkJson(oldP.options) || oldP.options
                })) {
                    newPollOptions.push({
                        title: elements.title,
                        maximum: elements.maximum,
                        options: elements.options,
                    })
                }
            })
            const addElements = newListPrice.filter((p) => !p.id)
            if (addElements && addElements[0]) {
                addElements.forEach((add) => {
                    newPollOptions.push(add)
                })
            }
            info.list_poll_options = newPollOptions
        }

        if (image) info.image = image
        else info.image = []
        if (files && files[0]) {
            info.file = files
        } else info.file = []
        info.start_at = info.start_at ? databaseDate(info.start_at, false,) : undefined
        info.end_at = info.end_at ? databaseDate(info.end_at, false,) : undefined
        return info
    }

    useEffect(() => {
        let newIngredients = [...ingredients];

        if (!newIngredients.includes('building')) {
            setInfos({ ...infos, customer_ids: [], group_ids: [], place_ids: [] });
            newIngredients = newIngredients.filter(d => d !== 'apartment' && d !== 'resident')
        }
        if (newIngredients.includes('all')) {
            newIngredients = ['all'];
        }

        if (newIngredients.includes('building') && infos.place_ids.length > 1) {
            newIngredients = newIngredients.filter(d => d !== 'apartment' && d !== 'resident');
        }
        if (newIngredients.includes('building') && infos.place_ids.length < 1) {
            newIngredients = newIngredients.filter(d => d !== 'apartment' && d !== 'resident')
            setInfos({ ...infos, customer_ids: [], group_ids: [], place_ids: [] });
        }
        setIngredients(newIngredients);
    }, [JSON.stringify(ingredients), JSON.stringify(infos.place_ids)]);


    return (
        <div>
            <FormUpdate
                checkId={Number(id)}
                title="bài viết"
                route="/posts"
                actions={{ add: addPostApi, update: updatePostApi }}
                handleData={handleData}
            >
                <div className="grid grid-form">
                    <div className="col-12 lg:col-8">
                        <TabView className="card">
                            <TabPanel header="Tổng quan">
                                <InputForm
                                    value={infos.title}
                                    onChange={(e) => setInfos({ ...infos, title: e.target.value })}
                                    label="Tiêu đề (*)"
                                />
                                {infos.category_id === 3 ?
                                    <>
                                        <div className="grid">
                                            <div className="col-12 lg:col-6">
                                                <CalendarForm value={infos.start_at}
                                                    onChange={(e) => setInfos({ ...infos, start_at: e.target.value })} label="Ngày bắt đầu" />

                                            </div>
                                            <div className="col-12 lg:col-6">
                                                <CalendarForm value={infos.end_at}
                                                    onChange={(e) => setInfos({ ...infos, end_at: e.target.value })}
                                                    label="Ngày kết thúc " />
                                            </div>
                                            <div className="col-12">
                                                <InputForm value={infos.address}
                                                    onChange={(e) => setInfos({ ...infos, address: e.target.value })} label="Địa điểm " />
                                            </div>
                                        </div>
                                    </>
                                    : ''

                                }
                                <Editorz
                                    id="content"
                                    data={infos.content}
                                    label="Nội dung"
                                    setData={(e) => setInfos({ ...infos, content: e })}
                                />

                            </TabPanel>
                            <TabPanel header="Thăm dò ý kiến">
                                <Button
                                    label={'Thêm câu hỏi'}
                                    size="small"
                                    severity={'info'}
                                    raised
                                    icon="pi pi-plus"
                                    type="button"
                                    onClick={e => setVisible(true)}
                                />
                                <hr />
                                <HeaderListForm title="Danh sách câu hỏi bình chọn" />
                                {pollOptions && pollOptions.map((d, index) => {
                                    let newOption = checkJson(d.options) || d.options
                                    return (
                                        <div className="card">
                                            <div className="">
                                                <div className='flex w-full align-items-center justify-content-center'>
                                                    <div className='w-full'>
                                                        <InputForm label="Câu hỏi" value={d.title} disabled />
                                                    </div>
                                                    <div className='flex mt-3 gap-2'>
                                                        <Button type='button' onClick={() => {
                                                            setVisible(true)
                                                            setIndexQuestion(index)
                                                        }} icon="pi pi-pencil" severity="" className='' />
                                                        <Button type='button' onClick={() => {
                                                            setPollOptions(prev => prev.filter((item, i) => i !== index))
                                                        }} icon="pi pi-trash" severity="danger" className='' />

                                                    </div>

                                                </div>
                                                <label className="font-medium px-2 ">Câu trả lời</label>
                                                {
                                                    <div className='grid grid-form'>
                                                        {newOption.map((o, i) => (
                                                            <div className="col-6">
                                                                <Inputz className='w-full' value={o[`poll_${i}`]} disabled />
                                                            </div>

                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </TabPanel>
                            <TabPanel header="File đính kèm">
                                <UploadFiles files={files} setFiles={setFiles} />
                            </TabPanel>
                        </TabView>
                    </div>
                    <div className="col-12 lg:col-4 mb-5">
                        <div className='card'>
                            <HeaderListForm title="Thông báo" />
                            <DropdownForm
                                id="category_id"
                                value={infos.category_id}
                                label="Danh mục (*)"
                                options={listPostCate}
                                onChange={(e) => setInfos({ ...infos, category_id: e.target.value })}
                            />
                            <UploadImg image={image} setImage={setImage} title="Ảnh" />
                            <InputForm
                                value={infos.hashtag}
                                onChange={(e) => setInfos({ ...infos, hashtag: e.target.value })}
                                label="Hashtag"
                            />
                            <InputForm
                                value={infos.url_video}
                                onChange={(e) => setInfos({ ...infos, url_video: e.target.value })}
                                label="Link video"
                            />
                            <MultiCheckBox
                                className="w-full"
                                label="Gửi đến"
                                options={[{
                                    name: "App Notify",
                                    id: 'appNotify'
                                },
                                {
                                    name: "Send Email",
                                    id: 'send_mail'
                                }]}
                                value={infos.allNotify}
                                onChange={(e) => setInfos({ ...infos, allNotify: e })}

                            />
                            {/* <MultiCheckBox
                                className="w-full flex-column gap-3"
                                label="Khách hàng nhận tin"
                                options={[{ name: "Tất cả", id: 1 }, { name: "Tòa nhà", id: 2 }, { name: "Căn hộ", id: 3 }, { name: "Cư dân", id: 4 }]}
                                value={infos.type}
                                onChange={(e) => setInfos({ ...infos, type: e })}
                            /> */}
                            <div className="flex flex-column justify-content-center gap-4 m-2 mb-4">
                                <label className="font-medium">Khách hàng nhận tin</label>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="all" value="all" onChange={onIngredientsChange}
                                        checked={ingredients.includes('all')} />
                                    <label htmlFor="all" className="ml-2">Tất cả</label>
                                </div>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="building" value="building" onChange={onIngredientsChange}
                                        checked={ingredients.includes('building')}
                                        disabled={ingredients.includes('all')} />
                                    <label htmlFor="building" className="ml-2">Tòa nhà</label>
                                </div>
                                {ingredients.includes('building') &&
                                    <MultiSelectForm value={infos.place_ids} options={buildings}
                                        onChange={e => setInfos({ ...infos, place_ids: e.target.value })}
                                        label="Chọn tòa nhà" className="w-full" />}

                                <div className="flex align-items-center">
                                    <Checkbox inputId="apartment" value="apartment" onChange={onIngredientsChange}
                                        checked={ingredients.includes('apartment')}
                                        disabled={ingredients.includes('all') || infos.place_ids?.length > 1 || !ingredients.includes('building') || infos.place_ids?.length < 1} />
                                    <label htmlFor="apartment" className="ml-2">Căn hộ</label>
                                </div>
                                {ingredients.includes('apartment') &&
                                    <MultiSelectForm options={apartments} value={infos.group_ids}
                                        onChange={e => setInfos({ ...infos, group_ids: e.target.value })}
                                        label="Chọn căn hộ" className="w-full" />}

                                <div className="flex align-items-center">
                                    <Checkbox inputId="resident" value="resident" onChange={onIngredientsChange}
                                        checked={ingredients.includes('resident')}
                                        disabled={ingredients.includes('all') || infos.place_ids?.length > 1 || !ingredients.includes('building') || infos.place_ids?.length < 1} />
                                    <label htmlFor="resident" className="ml-2">Cư dân</label>
                                </div>
                                {ingredients.includes('resident') &&
                                    <MultiSelectForm options={residents} value={infos.customer_ids}
                                        onChange={e => setInfos({ ...infos, customer_ids: e.target.value })}
                                        optionLabel='full_name' label="Chọn cư dân" className="w-full" />}
                            </div>
                            <InputSwitchForm
                                label="Trạng thái"
                                id="status"
                                value={Boolean(infos.status)}
                                onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                checked={infos.status}
                            />
                        </div>
                    </div>
                </div>

            </FormUpdate>
            <NotificationComment />
            <AddQuestion visible={visible} setVisible={setVisible} pollOptions={pollOptions}
                setPollOptions={setPollOptions} indexQuestion={indexQuestion}
                setIndexQuestion={setIndexQuestion} />
        </div>
    )
}
export default UpdateNotifications



