import { FormUpdateDialog, InputForm } from '@/components'
import EditorV2 from '@/components/Editorz'
import React from 'react'

const Detail = (props) => {
    const { visible, setVisible, data } = props
    const item = data.find(d => d.id === visible)

    const handleData = () => {
        return false
    }

    return (
        <FormUpdateDialog handleData={handleData} visible={visible} setVisible={setVisible} title={'Chi tiết'}>
            <div className="card bg-color">
                <InputForm id="subject" value={item?.subject} label="Tiêu đề" />
                <InputForm id="from" value={item?.from} label="Địa chỉ gửi" />
                <InputForm id="to" value={item?.to} label="Địa chỉ nhận" />
                <EditorV2 data={item?.content || item?.subject} />
            </div>
        </FormUpdateDialog>
    )
}

export default Detail
