import { DropdownForm, FormUpdateDialog } from '@/components'
import { month, year } from '@/constants'
import { useEffect, useState } from 'react' // Import useEffect
import { addPeriodManageApi } from '../api'

const AddPeriod = (props) => {
    const { visible, setVisible, setParams } = props
    const [infos, setInfos] = useState({ status: 1 })
    const [cycleMonth, setCycleMonth] = useState('')
    const [cycleYear, setCycleYear] = useState('')

    useEffect(() => {
        const currentDate = new Date()
        setCycleMonth(currentDate.getMonth() + 1)
        setCycleYear(currentDate.getFullYear())
    }, [])
    const getFormattedDate = () => {
        const yearMonth = `${cycleYear}${String(cycleMonth).padStart(2, '0')}`
        return yearMonth
    }

    const cycleName = getFormattedDate()

    const handleData = () => {
        let info = {
            ...infos,
            cycle_name: cycleName,
        }
        return info
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            title="Thêm khóa kỳ"
            route="/period_management"
            actions={{ add: addPeriodManageApi }}
        >
            <div className="flex flex-row justify-content-center flex-wrap w-full">
                <div className="flex align-items-center justify-content-center h-4rem font-medium">Kỳ tháng</div>
                <div className="flex align-items-center justify-content-center w-13rem">
                    <div className="w-full">
                        <DropdownForm
                            id="apartment_id"
                            value={cycleMonth}
                            options={month}
                            onChange={(e) => setCycleMonth(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex align-items-center justify-content-center  w-13rem">
                    <div className="w-full">
                        <DropdownForm
                            id="apartment_id"
                            value={cycleYear}
                            options={year}
                            onChange={(e) => setCycleYear(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </FormUpdateDialog>
    )
}

export default AddPeriod
