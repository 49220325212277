import { listExchangeV2Api } from '@/api';
import { Body, Calendarz, Columnz, Dropdownz, GridForm, HeaderListForm, Inputz, MultiSelectz, useGetParams } from '@/components';
import { useGetApi } from '@/hooks';
import { DataTablez } from '@/modules/parking_supervision/parking_check/screens/ParkingForm';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useListTimeKeeping } from '../utils';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { listPosition } from '../../position_management/api';
const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({})
      const users = useSelector(state => state.users);
      const exchanges = useGetApi(listExchangeV2Api, {}, [])

      const userExChanges = users.filter(u => filter.department_ids?.includes(u.department_id));
      const userExChangesV2 = userExChanges.map((d) => {
            return { ...d, full_name: d.full_name + "-" + d.code }
      })
      const userV2 = users.map((d) => {
            return { ...d, full_name: d.full_name + "-" + d.code }
      })

      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3" array={["department_ids", "user_ids"]}>
                  <MultiSelectz
                        value={filter.department_ids}
                        onChange={(e) => setFilter({ ...filter, department_ids: e.target.value })}
                        placeholder="Phòng ban"
                        options={exchanges}
                  />
                  <MultiSelectz
                        value={filter.user_ids}
                        onChange={(e) => setFilter({ ...filter, user_ids: e.target.value })}
                        placeholder="Nhân viên"
                        options={filter.department_ids ? userExChangesV2 : userV2}
                        optionLabel="full_name"
                        optionValue="user_id"
                  />

                  <Calendarz
                        value={filter.dateq}
                        onChange={(e) => setFilter({ ...filter, dateq: e.value })}
                        placeholder={"Chọn khoảng thời gian "}
                        selectionMode="range"
                  />
            </GridForm>
      )
}
function SummaryTable() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useListTimeKeeping({ status: undefined, ...params, first: undefined, limit: 31 })
      const users = useSelector(state => state.users);
      const exchanges = useGetApi(listExchangeV2Api, {}, [])
      const position = useGetApi(listPosition, {}, [])

      const startDate = new Date(data?.data?.reduce((min, p) => p.date < min ? p.date : min, data.data[0]?.date));
      const endDate = new Date(data?.data?.reduce((max, p) => p.date > max ? p.date : max, data.data[0]?.date));

      let currentDate = startDate;
      const allDates = [];
      while (currentDate <= endDate)
      {
            allDates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
      }
      const renderDayColumns = () => {
            return allDates.map((date, index) => {
                  const item = data.data.find(item => new Date(item.date).getTime() === date.getTime());
                  const dayOfWeek = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'][date.getDay()];
                  if (item)
                  {
                        return <Columnz field="shift_code" key={index} header={dayOfWeek} />;
                  } else
                  {
                        return <Columnz body={(e) => {
                              return "-"
                        }} key={index} header={dayOfWeek} />;
                  }
            });
      };
      const renderDate = () => {
            return allDates.map((date, index) => {
                  const item = data.data.find(item => new Date(item.date).getTime() === date.getTime());
                  const formattedDate = `${date.getDate()}`;
                  if (item)
                  {
                        return <Columnz className="text-center" field="" key={index} header={formattedDate} />;
                  } else
                  {
                        return <Columnz className="text-center" field="" key={index} header={formattedDate} />;
                  }
            });
      }
      const headerGroup = (
            <ColumnGroup>
                  <Row>
                        <Columnz header="#" field="" rowSpan={2} />
                        <Columnz header="Mã NV" field="" rowSpan={2} />
                        <Columnz header="Họ và tên" field="" rowSpan={2} />
                        <Columnz header="Phòng ban" rowSpan={2} />
                        <Columnz header="Vị trí" rowSpan={2} />
                        <Columnz header="Tổng" rowSpan={2} />
                        <Columnz header="Thực tế" rowSpan={2} />
                        {renderDayColumns()}
                  </Row>
                  <Row>
                        {renderDate()}
                  </Row>
            </ColumnGroup>
      )

      return (
            <div className="card" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  <HeaderListForm title="Bảng công tổng hợp" />

                  <Header setParams={setParams} />
                  <DataTablez
                        title="công lương"
                        params={params}
                        setParams={setParams}
                        route="/timekeeping_management"
                        value={data.data}
                        totalRecords={data.count}
                        headerColumnGroup={headerGroup}
                  >
                        <Columnz header="Mã NV" body={(e) => Body({ data: users, value: e.by, label: 'code', key: 'user_id' })} />
                        <Columnz header="Họ và Tên" body={(e) => Body({ data: users, value: e.by, label: 'full_name', key: 'user_id' })} />
                        <Columnz header="Phòng ban" body={(e) => Body({ data: exchanges, value: e.department_id, })} />
                        <Columnz header="Vị trí" body={(e) => {
                              const a = users?.find(d => d.user_id === e.by)
                              const b = position.find(d => d.id === a?.work_position)
                              return b?.name
                        }} />
                        <Columnz header="Tổng" field="" body={() => data?.data?.reduce((sum, item) => sum + item.total_work, 0)} />
                        <Columnz header="Thực tế" field="" body={() => data?.data?.reduce((sum, item) => sum + item.number_work, 0)} />
                        {renderDayColumns()}

                  </DataTablez>
            </div>
      )
}

export default SummaryTable
