import { useEffect, useState } from 'react'
import { Calendarz, GridForm, useGetParams, HeaderListForm } from '@/components'
import { Chart } from 'primereact/chart'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Calendarz value={filter.status} className="lg:col-3" onChange showIcon placeholder="Thời gian" />
        </GridForm>
    )
}
function RevenueReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [chartDataPie, setChartDataPie] = useState({})
    const [chartOptionsPie, setChartOptionsPie] = useState({})
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: [
                'Toà 17T1',
                'Toà 34T',
                'Toà 24T1',
                'Toà 18T1',
                'Bohemia',
                'Vinata Tower',
                'New House Xala',
                'Nhà Ga T2',
                'N05 Hoàng.Đ.T',
            ],
            datasets: [
                {
                    type: 'line',
                    label: 'Thực tế chi tiêu',
                    borderColor: '#F0D800',
                    backgroundColor: '#F0D800',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: [1500, 1300, 1500, 1300, 1250, 1400, 1650, 1550, 1600, 1700],
                },
                {
                    type: 'line',
                    label: 'Ngân sách chi tiêu',
                    borderColor: '#14810B',
                    backgroundColor: '#14810B',
                    borderWidth: 2,
                    // fill: false,
                    tension: 0.8,
                    data: [1100, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000],
                },
                {
                    type: 'bar',
                    label: 'Công nợ đã thu',
                    backgroundColor: '#F3722C',
                    data: [1800, 1500, 1900, 1600, 1880, 800, 1690, 1350, 1500, 1330, 7000],
                    borderColor: 'white',
                    borderWidth: 0.5,
                    barPercentage: 0.6,
                    // borderWidth: 10,
                    barThickness: 22,
                    maxBarThickness: 60,
                },
                {
                    type: 'bar',
                    label: 'Công nợ phải thu',
                    backgroundColor: '#165BAA',
                    data: [1560, 1100, 1800, 1500, 1700, 900, 1900, 1700, 1500, 1590, 1400, 1300, 1200],
                    borderWidth: 0.5,
                    barPercentage: 0.6,
                    // barThickness: 22,
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    position: 'bottom',
                },
            },
            scales: {
                xAxes: [
                    {
                        categoryPercentage: 1.0,
                        barPercentage: 1.0,
                    },
                ],
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    max: 2000,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                        text: 'Trăm triệu đồng',
                    },
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: surfaceBorder,
                    },
                },
            },
        }
        setChartData(data)
        setChartOptions(options)
    }, [])
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const data = {
            labels: [
                'Phí dịch vụ cư dân',
                'Phí Vận Hành TMDV',
                'Phí dịch vụ TM',
                'Chi Phí Điện',
                'Phí dịch vụ hầm',
                'Chi Phí nước',
                'Chi Phí xe tháng',
                'Chi phí quảng cáo',
                'Chi phí Kích sóng',
                'Phí khác',
            ],
            datasets: [
                {
                    data: [20, 15, 5, 7, 5, 6, 10, 7, 6, 8],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--orange-500'),
                        documentStyle.getPropertyValue('--purple-500'),
                        documentStyle.getPropertyValue('--violet-500'),
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400'),
                    ],
                },
            ],
        }
        const options = {
            cutout: '60%',
        }

        setChartDataPie(data)
        setChartOptionsPie(options)
    }, [])
    return (
        <>
            <div className="card">
                <HeaderListForm title="Thu chi và đối soát" />
                <div class="grid nested-grid">
                    <div class="col-12 md:col-6 lg:col-4 ">
                        <div class="grid">
                            <div class="col-12">
                                <div class="text-center p-3 border-round-sm shadow-4 font-bold">
                                    <h5 className="m-0 ">Ngân sách tháng 8</h5>
                                    <h1 className="m-0" style={{ color: '#F3722C' }}>
                                        1,000,000,000
                                    </h1>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="text-center p-3 border-round-sm shadow-4 font-bold">
                                    <Chart
                                        type="doughnut"
                                        data={chartDataPie}
                                        options={chartOptionsPie}
                                        className="w-full "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-12 md:col-6 lg:col-8">
                        <div class="text-center p-3 border-round-sm h-full shadow-4 font-bold">
                            <h3 className="m-0">Báo cáo đối soát</h3>
                            <Header setParams={setParams} />
                            <Chart type="line" data={chartData} options={chartOptions} style={{ height: '600px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RevenueReport
