import { useEffect, useState } from 'react'
import { countCustomerApi, detailNoti, detailReceiptApi, listCustomerApi, listCycleByBillApi } from '../api'

export const useListCustomer = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCustomerApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListCycleByBill = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCycleByBillApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}


export const useCountCustomer = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countCustomerApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailReceipt = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailReceiptApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
export const useDetailPage = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailNoti({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
