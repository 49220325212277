import {
    ActionBody,
    Body,
    Calendarz,
    Columnz, DataTablezV3,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    NumberFormat,
    TimeBody,
    useGetParams
} from '@/components'
import { listToast, statusCustomerList, statusListpay } from '@/constants'
import { useGetApi } from "@/lib/react-query"
import { setToast } from '@/redux/features'
import { Button } from '@/uiCore'
import { getArrId } from '@/utils'
import { confirmDialog } from 'primereact/confirmdialog'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    countCustomerApi,
    deleteBillApi,
    detailNoti, exportlistCustomerApi, listCustomerApi,
    printBills,
    updateStatusBillApi
} from '../api'
import { useListCycleByBill } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    const cycleNames = useListCycleByBill()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.bill_code}
                onChange={(e) => setFilter({ ...filter, bill_code: e.target.value })}
                placeholder="Tìm kiếm theo mã bảng kê"
            />
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder={"Chọn khoảng thời gian tạo bảng kê"}
                selectionMode="range"
            />
            <Dropdownz
                options={statusListpay}
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
            <Dropdownz
                options={cycleNames}
                optionLabel="cycle_name"
                optionValue="cycle_name"
                value={filter.cycle_name}
                onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                placeholder="Kỳ bảng kê"
            />
        </GridForm>
    )
}
export default function CustomerList() {
    const navigate = useNavigate()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const { data, isLoading } = useGetApi(listCustomerApi, { status: undefined, ...params, first: undefined }, "bills")
    const totalRecords = useGetApi(countCustomerApi, { status: undefined, ...params, first: undefined }, "countBills")

    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()
    const apartments = useSelector((state) => state.apartments)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading, setLoading] = useState(false)

    const items = [
        {
            label: 'Chuyển trạng thái chờ xác nhận',
            command: () => confirm(-3),
        },
        {
            label: 'Chuyển trạng thái chờ gửi',
            command: () => confirm(-2),
        },
        {
            label: 'Chuyển trạng thái chờ thanh toán',
            command: () => confirm(1),
        },
    ]


    async function accept(status) {
        const response = await updateStatusBillApi({ bill_ids: selectedProducts.map(s => s.id), status })
        if (response.data.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Chuyển trạng thái thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    async function acceptPrintBills(billIds) {
        setLoading1(true)
        const response = await printBills({ bill_ids: getArrId(billIds) })
        setLoading1(false)
        if (response.data.status) {
            localStorage.setItem("bill_ids", JSON.stringify(getArrId(billIds)))
            window.open(`/print/bills`, "_blank")
        } else {
            dispatch(setToast({ ...listToast[1], detail: 'Hợp đồng này chưa được lên công nợ' }))
        }
    }

    const confirm = (status) => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn bảng kê trước khi tiếp tục!' }))
            return
        }
        if (status)
            confirmDialog({
                message: 'Bạn có muốn tiếp tục thay đổi trạng thái?',
                header: process.env.REACT_APP_BRANCH_NAME,
                icon: 'pi pi-info-circle',
                accept: () => accept(status),
            })

    }
    const confirmV2 = ( type = "bill") => {
        if (!(selectedProducts && selectedProducts[0])) {
            dispatch(setToast({ ...listToast[1], detail: 'Vui lòng chọn bảng kê trước khi tiếp tục!' }))
            return
        }

        if (type === "bill") {
            confirmDialog({
                message: 'Bạn có muốn xuất nhiều thông báo phí?',
                header: process.env.REACT_APP_BRANCH_NAME,
                icon: 'pi pi-info-circle',
                accept: () => acceptPrintBills(selectedProducts),
            })
        }
    }

    const moreOptions = [
        {
            label: 'Lập phiếu thu',
            icon: 'pi pi-plus',
            command: () => navigate('/receipt/lap_phieu_thu')
        },
        {
            label: 'Xuất nhiều thông báo phí',
            icon: 'pi pi-print',
            command: () => confirmV2(),
            loading: loading1
        },
    ]

    const handlePrint = async (id) => {
        setLoading(true)
        const response = await detailNoti({ id })
        setLoading(false)
        if (response.data.status) {
            window.open(`/print/detail/${id}`, "_blank")
        } else {
            dispatch(setToast({ ...listToast[1], detail: response.data?.mess }))
        }
    }

    const BodyExportReceipt = (e) => {
        return (
            <div className="flex gap-2 align-items-center justify-content-center">
                <Button type="button" icon="pi pi-print" onClick={event => handlePrint(e.id)} />
            </div>
        );
    }

    return (
        <div className="card">
            <HeaderListForm title="Danh sách bảng kê" />
            <Header setParams={setParams} />
            <DataTablezV3
                value={Array.isArray(data?.data?.data) ? data?.data?.data : []}
                title="bảng kê"
                totalRecords={typeof totalRecords?.data?.data?.data === 'number' ? totalRecords?.data?.data?.data : 0}
                params={params}
                setParams={setParams}
                headerInfo={{ items, moreOptions, exportApi: exportlistCustomerApi }}
                route="/list_table"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                loading={isLoading || loading}
                basePermissions={["export"]}
            >
                <Columnz field="bill_code" header="Mã BK"></Columnz>
                <Columnz field="customer_name" header="Tên khách hàng" />
                <Columnz field="cycle_name" header="Kì BK" />
                <Columnz
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'name' })}
                    header="Căn hộ"
                />
                <Columnz body={e => TimeBody(e.deadline, 'date')} header="Hạn TT" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary + e.discount + e.promotion })} header="Nợ Phát Sinh" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.discount })} header="Giảm trừ" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.promotion })} header="Ưu đãi" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary })} header="Thành tiền" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.paid })} header="Đã thanh toán" />
                <Columnz
                    className='text-right'
                    body={e => NumberFormat({ value: e.summary - e.paid })} header="Còn nợ" />
                <Columnz body={e => TimeBody(e.created_at)} header="Ngày lập" />
                <Columnz header="Ngày duyệt" body={(e) => TimeBody(e.confirm_date, 'date')} />
                <Columnz header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' body={(e) => Body({ data: statusCustomerList, value: e.status })} />
                <Columnz body={e => BodyExportReceipt(e)} header="In phiếu" />
                <Columnz
                    header="Thao tác"
                    body={(e) => {
                        return (
                            <ActionBody
                                id={e.id}
                                route="/list_table"
                                deleteAction={deleteBillApi}
                                handleDelete={(id) => {
                                    return { bill_ids: [id] }
                                }}
                                params={params}
                                setParams={setParams}
                                basePermissions={e.paid ? ["detail"] : ["delete", "detail"]}
                                st
                            />
                        )
                    }}
                />
            </DataTablezV3>
        </div>
    )
}

