import React, { useEffect, useState } from 'react'
import { Columnz, DataTablez, TimeBody, useGetParams, HeaderListForm, Body } from '@/components'
import { useCountTemplateToolLog, useListTemplateToolLog } from '../utils'
import { Dropdownz, GridForm } from '@/components'
import { deleteTemplateToolLogApi, listTemplateToolLogApi } from '@/modules/setting/template_tool_log/api'
import { handleModule } from '../../log_tool/screens/LogTool'
import { useGetApi } from '@/hooks'
import { listToolApi } from '@/api'

export const descriptionlogTool = [
    // Authentication
    { name: 'Đăng nhập', route: '/auth/login' },

    // Nhóm quyền
    { name: 'Thêm nhóm quyền', route: '/permission/addPermission' },
    { name: 'Cập nhật nhóm quyền', route: '/permission/updatePermission' },
    { name: 'Xóa nhóm quyền', route: '/permission/deletePermission' },

    // Công ty
    { name: 'Thêm công ty', route: '/company/addCompany' },
    { name: 'Cập nhật công ty', route: '/company/updateCompany' },
    { name: 'Xóa công ty', route: '/company/deleteCompany' },

    // Phòng ban
    { name: 'Thêm phòng ban', route: '/department/addDepartment' },
    { name: 'Cập nhật phòng ban', route: '/department/updateDepartment' },
    { name: 'Xóa phòng ban', route: '/department/deleteDepartment' },

    // Nhóm
    { name: 'Thêm nhóm', route: '/group/addGroup' },
    { name: 'Cập nhật nhóm', route: '/group/updateGroup' },
    { name: 'Xóa nhóm', route: '/group/deleteGroup' },

    // Nhân viên
    { name: 'Thêm nhân viên', route: '/user/addUser' },
    { name: 'Cập nhật nhân viên', route: '/user/updateUser' },
    { name: 'Xóa nhân viên', route: '/user/deleteUser' },

    // Danh sách chặn
    { name: 'Thêm địa chỉ chặn', route: '/log/addBlackList' },
    { name: 'Cập nhật địa chỉ chặn', route: '/log/updateBlackList' },
    { name: 'Xóa địa chỉ chặn', route: '/log/deleteBlackList' },

    // Căn hộ
    { name: 'Thêm căn hộ', route: '/apartment_management/createNewApartment' },
    { name: 'Cập nhật căn hộ', route: '/apartment_management/updateBdcApartment' },
    { name: 'Xóa căn hộ', route: '/apartment_management/deleteApartments' },
    { name: 'Import căn hộ', route: '/apartment_management/importApartment' },
    { name: 'Export căn hộ', route: '/apartment_management/expApartment' },
    { name: 'Xóa cư dân khỏi căn hộ', route: '/apartment_management/deleteResidentOfApartment' },
    { name: 'Thêm căn hộ vào nhóm căn hộ', route: '/apartment_management/apartmentToGroup' },

    // Cư dân
    { name: 'Thêm cư dân', route: '/resident_management/addResident' },
    { name: 'Xóa cư dân', route: '/resident_management/deleteResidentApi' },
    { name: 'Cập nhật quyên cư dân', route: '/resident_management/editRoleROfA' },
    // { name: 'Cập nhật thông tin cư dân', route: '/resident_management/updateResidentApi' },
    { name: 'Cập nhật thông tin cư dân', route: '/resident_management/editDetailResident' },
    { name: 'Thay đổi quyền căn hộ của cư dân', route: '/resident_management/editRoleApi' },
    { name: 'Xóa thông tin căn hộ của cư dân', route: '/resident_management/deleteRoleApi' },
    { name: 'Thêm thông tin căn hộ của cư dân', route: '/resident_management/addRoleApi' },
    { name: 'Thay đổi mật khẩu cư dân', route: '/resident_management/restartPassword' },
    { name: 'Gửi mail tạo mới cư dân', route: '/resident_management/sendMailCreateUser' },
    { name: 'Gửi sms tạo mới cư dân', route: '/resident_management/sendSmsCreateUser' },

    // Nhóm căn hộ
    { name: 'Thêm nhóm căn hộ', route: '/apartment_management/createListGroupApartment' },
    { name: 'Cập nhật nhóm căn hộ', route: '/apartment_management/updateGroupApartments' },
    { name: 'Xóa nhóm căn hộ', route: '/apartment_management/deleteGroupApartmentV2' },
    { name: 'Export nhóm căn hộ', route: '/apartment_management/expGroupApartment' },

    // Tòa nhà
    { name: 'Thêm nhóm tòa nhà', route: '/building_management/newBuildingPlace' },
    { name: 'Cập nhật nhóm tòa nhà', route: '/building_management/editBuildingPlace' },
    { name: 'Xóa nhóm tòa nhà', route: '/building_management/deleteBuildingPlace' },

    { name: 'Thêm tầng trong tòa nhà', route: '/building_management/createFloor' },
    { name: 'Cập nhật tầng trong tòa nhà', route: '/building_management/editFloorV2' },
    { name: 'Xóa tầng trong tòa nhà', route: '/building_management/deleteFloor' },

    // Ý kiến cư dân
    { name: 'Thêm bình luận yêu cầu dịch vụ', route: '/service_request/insertComment' },
    { name: 'Xóa bình luận yêu cầu dịch vụ', route: '/service_request/deleteComment' },
    { name: 'Đổi trạng thái yêu cầu dịch vụ', route: '/service_request/switchStatus' },
    { name: 'Xóa ý kiến cư dân', route: '/resident_opinion/deleteFeedBack' },

    { name: 'Thêm bình luận ý kiến cư dân', route: '/feedback_comment/createComment' },
    { name: 'Xóa bình luận ý kiến cư dân', route: '/feedback_comment/deleteComments' },
    { name: 'Đổi trạng thái ý kiến cư dân', route: '/feedback_comment/deleteComments' },
    { name: 'Đổi trạng thái bình luận của cư dân', route: '/feedback_comment/deleteComments' },

    // Kế toán -> Bảng giá
    { name: 'Thêm địa bảng giá', route: '/list_price/addProgressives' },
    { name: 'Cập nhật địa bảng giá', route: '/list_price/updateProgressives' },
    { name: 'Xóa địa bảng giá', route: '/list_price/delProgressives' },

    // Kế toán -> Điện nước
    { name: 'Cập nhật chỉ số cuối', route: '/electric_meter/UpdateAfterNumberElectricMeter' },
    { name: 'Xóa ảnh', route: '/electric_meter/deleteImageElectricMeter' },
    { name: 'Xóa điện nước', route: '/electric_meter/deleteImageElectricMeter' },
    { name: 'Import chỉ số cuối', route: '/electric_meter/importElectricMeter' },
    { name: 'Export danh sách điện nước theo dự án', route: '/electric_meter/expElectricMeter' },

    // Kế toán -> QL Phương tiện
    { name: 'Thêm phương tiện', route: '/vehicle/addVehicle' },
    { name: 'Cập nhật phương tiện', route: '/vehicle/updateVehicle' },

    // Kế toán-> tính toán công nợ
    { name: 'Tính công nợ tháng', route: '/debt/debtCalculation' },
    { name: 'Import phí đầu kỳ', route: '/debt/importServiceCharge' },
    { name: 'Tính công nợ điện nước', route: '/debt/debtCalculationWater' },
    //Kế toán-> Danh sách duyệt số liệu
    { name: 'Duyệt số liệu', route: '/bill_approval/approveBill' },
    { name: 'Xóa số liệu', route: '/bill_approval/detailDataReview' },
    //Kế toán-> Danh sách gửi thông báo
    { name: 'Xóa thông báo', route: '/template_tool_log/deleteTemplateToolLog' },
    //Kế toán-> Danh sách bảng kê
    { name: 'Thêm bảng kê', route: '/list_table/addPayment' },
    { name: 'Cập nhật bảng kê', route: '/list_table/updateBdcPayment' },
    { name: 'Duyệt bảng kê', route: '/bill_approval/approveBill' },
    //Kế toán-> Quản lý phiếu thu chi
    { name: 'Xóa phiếu thu chi', route: '/receipt/deleteReceipt' },
    { name: 'Thêm nhật thẻ', route: '/receipt/addReceiptExpenditure' },
    { name: 'Thêm thẻ', route: '/receipt/updateReceiptExpenditure' },
    { name: 'Lập phiếu thu', route: '/receipt/addReceipt' },
    { name: 'Lập phiếu thu ký quỹ', route: '/receipt/addReceiptCoin' },
    //Kế toán-> Quản lý giao dịch
    { name: 'Import giao dịch', route: '/receipts_expenditure/addReceiptExpenditure' },
    { name: 'Import giao dịch đầu kỳ', route: '/history_transactioimpExlTransactionPayment' },
    { name: 'Cập nhật giao dịch', route: 'history_transaction/addReceiptTransaction' },
    //Kế toán-> Dịch vụ căn hộ
    { name: 'Xóa dịch vụ căn hộ', route: '/service_apartment/delServiceApartment' },
    { name: 'Thêm dịch vụ căn hộ', route: '/service_apartment/addServiceApartment' },
    { name: 'Cập nhật dịch vụ căn hộ', route: '/service_apartment/updateServiceApartment' },
    { name: 'Cập nhật trạng thái dịch vụ căn hộ', route: '/service_apartment/updateStatusServiceApartment' },
    //Công nợ-> Chi tiết bảng kê, khách hàng
    { name: 'Xóa bảng kê, khách hàng', route: '/bill/deleteBill' },
    //Công nợ-> Quản lý tiền thừa
    { name: 'Cập nhật tiền thừa', route: '/company/updateExcessMoney' },
    //Công nợ-> Danh sách chặn
    { name: 'Thêm mới danh sách chặn', route: '/log/addBlackList' },
    { name: 'Cập nhật danh sách chặn', route: '/log/updateBlackList' },
    { name: 'Xóa danh sách chặn', route: '/log/deleteBlackList' },
    //Hệ thống -> Quản lý hình thức phiếu thu
    { name: 'Xóa hình thức phiếu thu', route: '/recept_form/deleteReceptForm' },
    { name: 'Thêm phiếu thu', route: '/recept_form/addReceptForm' },
    { name: 'Cập nhật phiếu thu', route: '/recept_form/updateReceptForm' },
    { name: 'Cập nhật active phiếu thu', route: '/recept_form/activeStatus' },
    { name: 'Cập nhật InActive phiếu thu', route: '/recept_form/inActiveStatus' },
    //Cài đặt -> Quản lý hình thức phiếu thu
    { name: 'Xóa template tool', route: '/template_tool_log/deleteTemplateToolLog' },
    { name: 'Thêm template tool', route: '/template_tool_log/addTemplateToolLog' },
    //Quản lý đặt chỗ -> Quản lý đặt chỗ
    { name: 'Xóa đặt chỗ', route: '/booking_time_place/deleteBookingTimePlace' },
    { name: 'Thêm đặt chỗ', route: '/booking_time_place/addBookingTimePlace' },
    { name: 'Cập nhật đặt chỗ', route: '/booking_time_place/updateBookingTimePlace' },
    { name: 'Thêm commment', route: '/booking_time_place/insertComment' },
    { name: 'Xóa comment', route: '/booking_time_place/deleteComment' },
//Quản lý đặt chỗ -> Quản lý tiện ích
    { name: 'Cập nhật tiện ích', route: '/booking/updateBooking' },
    //Thông báo
    { name: 'Cập nhật thông báo', route: '/posts/updatePost' },
    { name: 'Gửi thông báo', route: '/posts/notifyPostToApp' },

    // Bảng kê khách hàng
    { name: 'Cập nhật trạng thái bảng kê', route: '/bill/updateStatusBill' },

    // Tài sản
    { name: 'Thêm tài sản', route: '/asset/addCirDist' },
    { name: 'Cập nhật tài sản tài sản', route: '/asset/updateAsset' },

    // Thông báo
    { name: 'Đọc thông báo', route: '/notify/readNotifyV2' },
]

const Header = ({ setParams, tools }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                value={filter.tool}
                options={tools}
                optionValue={'route'}
                onChange={(e) => setFilter({ ...filter, tool: e.target.value })}
                placeholder="Module"
            />
        </GridForm>
    )
}

const TemplateToolLogs = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [tools, setTools] = useState([])
    const data = useGetApi(listTemplateToolLogApi, { ...params, first: undefined }, [])
    const totalRecords = useCountTemplateToolLog({ status: undefined, ...params, first: undefined }) || 0
    const toolsData = useGetApi(listToolApi, {}, [])

    useEffect(() => {
        if (toolsData && toolsData[0]) {
            let newData = []
            toolsData.forEach((t) => {
                if (t.list_tool_child && t.list_tool_child[0]) {
                    newData = [...newData, ...t.list_tool_child]
                }
            })
            setTools([...newData, { name: 'Authentication', route: '/auth' }, {  }])
        }
    }, [toolsData])

    return (
        <div className="card">
            <HeaderListForm title="Danh sách template tool" />
            <Header setParams={setParams} tools={tools} />
            <DataTablez
                value={data}
                title="template tool"
                totalRecords={totalRecords}
                params={params}
                dataKey="_id"
                setParams={setParams}
                route="/template_tool_log"
                actionsInfo={{ deleteAction: deleteTemplateToolLogApi }}
                headerInfo
                basePermissions={["detail", "detail", "insert"]}
            >
                <Columnz
                    body={(e) => Body({ data: tools, value: handleModule(e.tool), key: 'route' })}
                    header="module"
                />
                <Columnz body={(e) => Body({ data: descriptionlogTool, value: e.tool, key: 'route' })} header="Mô tả" />
                <Columnz header="Thời gian thêm" body={(e) => TimeBody(e.created_at)} />
                <Columnz header="Thời gian cập nhật" body={(e) => TimeBody(e.updated_at)} />
            </DataTablez>
        </div>
    )
}

export default TemplateToolLogs
