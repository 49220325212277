import {
    Columnz,
    DataTablez, GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    useGetParams
} from '@/components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteDepartmentApi } from '../api'
import { useCountDepartment, useListDepartment } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const Events = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const users = useSelector((state) => state.users)
    const data = useListDepartment({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDepartment({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const moreActions = [{ icon: 'pi pi-comment' }, { icon: 'pi pi-calendar' }]
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            actionType: 'xóa',
        },
        {
            label: 'Active',
            icon: 'pi pi-check',

            actionType: 'active',
        },
        {
            label: 'Inactive',
            icon: 'pi pi-times',

            actionType: 'inactive',
        },
    ]
    return (
        <div className="card ">
            <HeaderListForm title="Danh sách bộ phận" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bộ phận"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/events"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                actionsInfo={{ deleteAction: deleteDepartmentApi, moreActions }}
                headerInfo={{ items }}
            >
                <Columnz field="" header="ID" />
                <Columnz field="" header="Tiêu đề" />
                <Columnz field="" header="Hashtag" />
                <Columnz field="" header="Danh mục" />
                <Columnz field="" header="Người viết" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/work_management',
                            // updateAction: updateDepartmentApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}

export default Events
