import { ActionBody, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, NumberFormat, useGetParams } from '@/components'
import { useListMonth } from '@/modules/accountant/electric_water_meter/utils'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteExcessMoney, expListLogCoin } from '../api'
import { useCountExcessMoneyDetail, useListExcessMoneyDetail } from '../utils'
import UpdateExcessMoneyDetails from './UpdateExcessMoneyDetails'

const Header = ({ setParams, services }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    const cycle = useListMonth()

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.cycle_name}
                options={cycle}
                onChange={(e) => setFilter({ ...filter, cycle_name: e.target.value })}
                placeholder="Kì bảng kê"
                optionLabel="cycle_name"
                optionValue="cycle_name"
            />
            <Dropdownz
                value={filter.service_id}
                options={services}
                onChange={(e) => setFilter({ ...filter, service_id: e.target.value })}
                placeholder="Dịch vụ"
            />
        </GridForm>
    )
}
export default function ExcessMoneyDetails() {
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const services = useSelector((state) => state.services)
    const [params, setParams] = useState(initParam)
    const data = useListExcessMoneyDetail({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountExcessMoneyDetail({ status: undefined, ...params, first: undefined }) || 0

    const ServiceBody = (e) => {
        const service = services.find(s => s.id === e.bdc_service_id)
        if (service) {
            if (e.number) return <span>{service.name + "_" + e.number}</span>
            else return <span>{service.name}</span>
        } else return <span>Tiền thừa không chỉ định</span>
    }

    return (
        <div className="card">
            {visible && <UpdateExcessMoneyDetails setParams={setParams} visible={visible} setVisible={setVisible} />}
            <HeaderListForm title="Chi tiết tiền thừa" />
            <Header setParams={setParams} services={services} />
            <DataTablez
                value={data}
                title="chi tiết tiền thừa"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/excess_money_details"
                headerInfo={{ exportApi: expListLogCoin }}
                setVisibledDialog={setVisible}
                basePermissions={["export"]}
                rowClassName={(rowData) => rowData.deleted_at ? 'deleted-row' : ''}
            >
                <Columnz field="created_at" header="Ngày tạo" />
                <Columnz field="receipt_code" header="Mã chứng từ" style={{ whiteSpace: "nowrap" }} />
                <Columnz field="name_customer" header="Khách Hàng" />
                <Columnz field="apartment_name" header="Căn hộ" />
                <Columnz field="cycle_name" header="Kỳ" />
                <Columnz body={ServiceBody} header="Dịch vụ" />
                <Columnz field="desc" header="Diễn giải" />
                <Columnz
                    className='text-right'
                    body={(e) => (e.type === 1 ? NumberFormat({ value: e.coin }) : '')} header="Tăng trong kì" />
                <Columnz
                    className='text-right'
                    body={(e) => (e.type === 0 ? NumberFormat({ value: e.coin }) : '')} header="Giảm trong kỳ" />
                <Columnz header="Thao tác" body={(e) =>
                    (e.from_type === 2 || e.from_type === 12)
                        ? (!e.deleted_at ? ActionBody({
                            id: e.id,
                            params,
                            setParams,
                            basePermissions: ["delete", "detail"],
                            setVisibledDialog: setVisible,
                            route: '/excess_money_details',
                            handleDelete: (id) => {
                                return { id, apartment_id: e.bdc_apartment_id, apartment_service_price_id: e.bdc_apartment_service_price_id }
                            },
                            deleteAction: deleteExcessMoney,
                        }) : ActionBody({
                            id: e.id,
                            params,
                            setParams,
                            setVisibledDialog: setVisible,
                            route: '/excess_money_details',
                            handleDelete: (id) => {
                                return { id, apartment_id: e.bdc_apartment_id, apartment_service_price_id: e.bdc_apartment_service_price_id }
                            },
                            deleteAction: deleteExcessMoney,
                        }))
                        : ""
                } />

            </DataTablez>
        </div>
    )
}
