import { Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, useGetParams } from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { deleteApartmentDepositApi } from '../api'
import { useCountApartmentDeposit, useListApartmentDeposit } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn loại"
            />
            <Inputz
                value={filter.key_search}
                placeholder="Hướng"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Inputz
                value={filter.key_search}
                placeholder="Nhập tên sản phẩm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const ApartmentDeposits = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListApartmentDeposit({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountApartmentDeposit({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách ký gửi BĐS" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Kí gửi"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/apartment_deposit"
                actionsInfo={{ deleteAction: deleteApartmentDepositApi }}
            >
                <Columnz field="name" header="Tên" />
                <Columnz field="address" header="Địa chỉ" />
                <Columnz field="address" header="Mô tả" />
                <Columnz field="address" header="Hướng" />
                <Columnz field="address" header="Loại" />
                <Columnz field="address" header="Nhu cầu" />
                <Columnz field="address" header="Diện tích" />
                <Columnz field="address" header="Giá" />
                <Columnz field="address" header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
                <Columnz field="phone" header="Ảnh" />
            </DataTablez>
        </div>
    )
}

export default ApartmentDeposits
