import { countBuildingApi, deleteBuildingApi, listBuildingApi, listSubdivisionApi, updateBuildingApi } from '@/api'
import { Body, Columnz, DataTablez, Dropdownz, GridForm, Inputz, StatusBody, useGetParams } from '@/components'
import { status } from '@/constants'
import { useGetApi } from '@/hooks'
import { removeBuilding } from '@/redux/features'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import UpdateBuilding from './UpdateBuilding'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ name: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.name}
                placeholder="Tìm kiếm theo tên tòa nhà"
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const Buildings = () => {
    const dispatch = useDispatch()
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listBuildingApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countBuildingApi, { ...params, first: undefined }, 0)
    const subdivision = useGetApi(listSubdivisionApi, {}, [])
    const [visible, setVisible] = useState(false)

    const handleAfterCallApi = (data) => {
        if (data) dispatch(removeBuilding(data))
    }

    return (
        <div className="card">
            {visible && <UpdateBuilding visible={visible} setVisible={setVisible} setParams={setParams} />}
            <Header setParams={setParams} />
            <DataTablez
                title="Tòa nhà"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_management"
                headerInfo
                actionsInfo={{
                    deleteAction: deleteBuildingApi,
                }}
                basePermissions={["insert", "detail", "delete"]}
                setVisibledDialog={setVisible}
                handleAfterCallApi={handleAfterCallApi}
            >
                <Columnz field="name" header="Tên tòa nhà" />
                <Columnz field="code" header="Mã" />
                <Columnz body={(e) => Body({ data: subdivision, value: e.bdc_subdivision_id })} header="Phân khu" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/building_management',
                            updateAction: updateBuildingApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}
export default Buildings
