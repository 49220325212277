import { detailUserApi } from "@/api"
import { useEffect, useState } from "react"

export const useListContract = (id, render) => {
      const [data, setData] = useState([])
      async function fetchData() {
            const response = await detailUserApi({ id, })
            if (response.data.status) setData(response.data.data)
      }
      useEffect(() => {
            if (id) fetchData()

      }, [JSON.stringify(id), render])
      return data
}