import {
    Body, Columnz,
    DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, LoadDialog, StatusBody,
    useGetParams
} from '@/components'
import { handbook_Type } from '@/constants'
import { useState } from 'react'
import { deleteDirectoryApi, updateDirectoryApi } from '../api'
import { useCountDirectory, useListDirectory } from '../utils'
import UpdateDirectory from './UpdateDirectory'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập nội dung tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />

            <Dropdownz
                value={filter.bdc_handbook_type_id}
                options={handbook_Type}
                onChange={(e) => setFilter({ ...filter, bdc_handbook_type_id: e.target.value })}
                placeholder="Phân loại"
            />
        </GridForm>
    )
}

export default function Directory() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListDirectory({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDirectory({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)
    const mapIdToName = (id) => {
        const category = data.find((item) => item.id === id)
        return category ? category.name : 'Không'
    }
    return (
        <>
            {visible && <UpdateDirectory setParams={setParams} visible={visible} setVisible={setVisible} />}
            <LoadDialog visible={visible} />

            <HeaderListForm title="Thông tin danh mục" />
            <Header setParams={setParams} />
            <DataTablez
                title="Danh mục"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/building_guide"
                headerInfo
                actionsInfo={{ deleteAction: deleteDirectoryApi }}
                basePermissions={["insert", "detail"]}
                setVisibledDialog={setVisible}
            >
                <Columnz field="name" header="Tiêu đề " />
                <Columnz
                    body={(e) => Body({ data: handbook_Type, value: e.bdc_handbook_type_id })}
                    header="Phân loại"
                />
                <Columnz field="phone" header="Điện thoại" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/building_guide',
                            updateAction: updateDirectoryApi,
                        })
                    }
                />
            </DataTablez>
        </>
    )
}
