import { Card } from '@/uiCore'
import React, { useState } from 'react'
import { Calendarz, GridForm } from '../../parking_check/screens/ParkingForm'
import DataTableParking from '../../parking_report/screens/TableRow'
import TicketStatistic from './TicketStatistic'
import { vehicle_type } from "@/constants";

const Header = ({ setParams }) => {
    const today = new Date()
    const [filter, setFilter] = useState(() => {
        return {dates: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)]}
    })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className={"lg:col-5"}>
            <Calendarz
                className={"lg:col-5"}
                value={filter.dates}
                onChange={(e) => setFilter({...filter, dates: e.value})}
                placeholder={"Chọn khoảng thời gian"}
                selectionMode={"range"}
            />
        </GridForm>
    )
}
export const ReportStatistic = ({ params, setParams, totalDaily, revenueTurn, countTicket }) => {
    const colors = ['#e4e4fe', '#fef3d5', '#d8f7e8', '#fdddd0']
    const countTicketDefault = [
        {
            "count": 0,
            "vehicle_type": "Bicycle",
            "ticket_type": "Daily"
        },
        {
            "count": 0,
            "vehicle_type": "Bicycle",
            "ticket_type": "Monthly"
        },
        {
            "count": 0,
            "vehicle_type": "Car",
            "ticket_type": "Daily"
        },
        {
            "count": 0,
            "vehicle_type": "Car",
            "ticket_type": "Monthly"
        },
        {
            "count": 0,
            "vehicle_type": "ElectricMotor",
            "ticket_type": "Daily"
        },
        {
            "count": 0,
            "vehicle_type": "ElectricMotor",
            "ticket_type": "Monthly"
        },
        {
            "count": 0,
            "vehicle_type": "Motor",
            "ticket_type": "Daily"
        },
        {
            "count": 0,
            "vehicle_type": "Motor",
            "ticket_type": "Monthly"
        }
    ]
    const countTicketData = countTicket || countTicketDefault
    const ticketmonth = countTicketData.filter(m => m.ticket_type === 'Monthly')
    const ticketdaily = countTicketData.filter(m => m.ticket_type === 'Daily')
    const sum1 = ticketmonth && ticketmonth?.map((d) => d.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const sum2 = ticketdaily && ticketdaily?.map((d) => d.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const defaultTotalDaily = [
        {
            "pm_in": "0",
            "pm_out": "0",
            "ai_in": "0",
            "ai_out": "0",
            "type_vehicle": "Bicycle"
        },
        {
            "pm_in": "0",
            "pm_out": "0",
            "ai_in": "0",
            "ai_out": "0",
            "type_vehicle": "Car"
        },
        {
            "pm_in": "0",
            "pm_out": "0",
            "ai_in": "0",
            "ai_out": "0",
            "type_vehicle": "ElectricMotor"
        },
        {
            "pm_in": "0",
            "pm_out": "0",
            "ai_in": "0",
            "ai_out": "0",
            "type_vehicle": "Motor"
        }
    ]
    const totalDailyData = totalDaily || defaultTotalDaily
    const defaultRevenueTurn = [
        {
            'count': 0,
            'vehicle_type': 'Car',
            'compare': '0',
            'trend': 'giảm',
        },
        {
            'count': 0,
            'vehicle_type': 'ElectricMotor',
            'compare': '0',
            'trend': 'giảm',
        },
        {
            'count': 0,
            'vehicle_type': 'Motor',
            'compare': '0',
            'trend': 'giảm',
        },
        {
            'count': 0,
            'vehicle_type': 'Bicycle',
            'compare': '0',
            'trend': 'giảm',
        },
    ]
    const revenueTurnData = revenueTurn || defaultRevenueTurn
    const data2 = totalDailyData.map((item) => ({
        vehicle: vehicle_type.find(v => v.id === item.type_vehicle)?.name,
        data: [
            {
                main: 'Giám sát tự động',
                field1: 'Lượt vào',
                n1: parseInt(item.ai_in),
                field2: 'Lượt ra',
                n2: parseInt(item.ai_out),
            },
            {
                main: 'PM quản lý bãi xe ',
                field1: 'Lượt vào',
                n1: parseInt(item.pm_in),
                field2: 'Lượt ra',
                n2: parseInt(item.pm_out),
            },
        ],
    }))


    return (
        <>
            <Header setParams={setParams} />
            <hr />
            <div className="grid py-3">
                <div className="grid col-12">
                    {revenueTurnData.map((item, index) => (
                        <>
                            <div className="col-12 md:col-6 xl:col-3 px-2" key={index}>
                                <Card className="col-12">
                                    <div className="grid">
                                        <div className="flex flex-column col-9">
                                            <div
                                                style={{
                                                    color: 'grey',
                                                    fontSize: '1.8vh',
                                                    fontWeight: 'bold',
                                                    marginBottom: '1vh',
                                                }}
                                            >
                                                Lượt {vehicle_type.find(v => v.id === item.vehicle_type)?.name?.toLowerCase()}
                                            </div>
                                            <div style={{ fontSize: '3.5vh', fontWeight: 'bold' }}>{item.count}</div>
                                        </div>
                                        <div className="col-3">
                                            <div
                                                style={{
                                                    backgroundColor: colors[index % colors.length],
                                                    borderRadius: '2vh',
                                                    width: '100%',
                                                    aspectRatio: '1',
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex flex-row pt-3" style={{ fontSize: '25px' }}>
                                            {item.trend == 'bằng' && (
                                                <span
                                                    className="flex flex-row pi pi-angle-double-up"
                                                    style={{
                                                        fontSize: '20px',
                                                        marginRight: '5px',
                                                        fontWeight: 'bold',
                                                        color: '#5ccfbe',
                                                    }}
                                                >
                                                    {' '}
                                                    0 %
                                                </span>
                                            )}
                                            {item.trend == 'giảm' && (
                                                <span
                                                    className="flex flex-row pi pi-angle-double-down"
                                                    style={{
                                                        fontSize: '20px',
                                                        marginRight: '5px',
                                                        fontWeight: 'bold',
                                                        color: 'red',
                                                    }}
                                                >
                                                    {' '}
                                                    {item.compare}%
                                                </span>
                                            )}
                                            {item.trend == 'tăng' && (
                                                <span
                                                    className="flex flex-row pi pi-angle-double-up"
                                                    style={{
                                                        fontSize: '20px',
                                                        marginRight: '5px',
                                                        fontWeight: 'bold',
                                                        color: '#5ccfbe',
                                                    }}
                                                >
                                                    {' '}
                                                    {item.compare}%
                                                </span>
                                            )}
                                            <span
                                                className=""
                                                style={{
                                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                                    color: 'grey',
                                                    fontWeight: 'bold',
                                                    marginLeft: '0.5vh',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                {' '}
                                                So với tháng trước
                                            </span>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </>
                    ))}
                    <div className="flex flex-row col-12">
                        {data2.map((item, index) => (
                            <>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <DataTableParking key={index} item={item} />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <div className="grid col-12">
                    <div className="col-12 lg:col-6">
                        <TicketStatistic data={ticketmonth} type={'tháng'} sum={sum1} />
                    </div>
                    <div className="col-12 lg:col-6">
                        <TicketStatistic data={ticketdaily} type={'lượt'} sum={sum2} />
                    </div>
                </div>
            </div>
        </>
    )
}
