import { Checkbox } from 'primereact/checkbox'
import { useEffect, useState } from 'react'

const MulticheckBox = ({ title, categories, value, onChange, tool_id }) => {
    categories = categories || []
    const [selectAll, setSelectAll] = useState(false)

    useEffect(() => {
        if (value.length === 0) setSelectAll(false)
        let newArr = []
        categories.forEach((c) => {
            newArr.push(tool_id + '-' + c)
        })
        if (newArr.every((item) => value.includes(item))) setSelectAll(true)
        else setSelectAll(false)
    }, [value])

    const onCategoryChange = (e) => {
        let _selectedCategories = [...value]
        let newArray = []
        if (e.checked) _selectedCategories.push(tool_id + '-' + e.value)
        else _selectedCategories = _selectedCategories.filter((s) => s !== tool_id + '-' + e.value)
        categories.forEach((c) => newArray.push(tool_id + '-' + c))
        onChange(_selectedCategories)
    }

    const handleSelectAll = () => {
        let _selectedCategories = [...value]
        if (selectAll)
            categories.forEach(
                (c) => (_selectedCategories = _selectedCategories.filter((s) => s !== tool_id + '-' + c)),
            )
        else
            categories.forEach((c) => {
                if (!_selectedCategories.includes(title + ' - ' + c)) _selectedCategories.push(tool_id + '-' + c)
            })
        onChange(_selectedCategories)
        setSelectAll(!selectAll)
    }

    return (
        <div className="w-full card gap-2 flex justify-content-between mb-0">
            <div className="w-4 flex align-items-center">
                <Checkbox inputId={tool_id} onChange={handleSelectAll} checked={selectAll} />
                <label
                    htmlFor={tool_id}
                    className="ml-2"
                    style={{ marginBottom: 0, fontWeight: 400, cursor: 'pointer' }}
                >
                    {title}
                </label>
            </div>
            <div className="flex gap-3 flex-wrap">
                {categories.map((c) => {
                    return (
                        <div key={tool_id + '-' + c} className="flex align-items-center ">
                            <Checkbox
                                inputId={tool_id + '-' + c}
                                value={c}
                                onChange={onCategoryChange}
                                checked={value.some((item) => item === tool_id + '-' + c)}
                            />
                            <label
                                htmlFor={tool_id + '-' + c}
                                className="ml-2"
                                style={{ marginBottom: 0, fontWeight: 400, cursor: 'pointer' }}
                            >
                                {c}
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export const MultiSelectz = ({ title, tree, permissionToolbar, setPermissionToolbar }) => {
    tree = tree || []
    return (
        <>
            <div className=" flex w-full flex-wrap text-left">
                <b>{title}</b>
                {tree.map((t) => {
                    return (
                        <div key={t.name} className="w-full mb-4">
                            <span className="font-semibold text-sm">{t.name}</span>
                            <div className="card flex flex-column gap-3 mt-2">
                                {t.list_tool_child.map((c) => {
                                    return (
                                        <MulticheckBox
                                            value={permissionToolbar}
                                            onChange={setPermissionToolbar}
                                            key={c.name}
                                            title={c.name}
                                            categories={c.children}
                                            tool_id={c.id}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
