import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { removePropObject } from '@/utils'
import { InputForm, InputSwitchForm, FormUpdate } from '@/components'
import { MultiSelectz } from './MultiSelect'
import { ListUser } from './ListUser'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { TabPanel, TabView } from 'primereact/tabview'
import { addPermissionApi, detailPermissionApi, listToolApi, listUserV2Api, updatePermissionApi } from '@/api'
import { useGetApi } from '@/hooks'

const handlePermissions = (listTool) => {
    const app_permission = []
    const web_permission = []
    const noti_permission = []
    listTool = listTool || []
    listTool.forEach((l) => {
        if (l.list_tool_child && l.list_tool_child[0]) {
            l.list_tool_child.forEach((lc) => {
                const actionsJSON = lc.actions
                const actions = actionsJSON ? JSON.parse(actionsJSON) : []
                lc.children = actions
            })
        }
        if (l.device_type === 'web') web_permission.push(l)
        if (l.device_type === 'app') app_permission.push(l)
        if (l.device_type === 'notification') noti_permission.push(l)
    })
    return { web_permission, app_permission, noti_permission }
}

const UpdatePermission = () => {
    const { id } = useParams()
    const [infos, setInfos] = useState({ name: '', status: true })
    const detail = useGetApi(detailPermissionApi, { id }, {})
    const listTool = useGetApi(listToolApi, { id }, [])
    const [data, setData] = useState([])
    const [permissionToolbar, setPermissionToolbar] = useState([])
    const [permission, setPermission] = useState([])
    const users = useGetApi(listUserV2Api, {}, [])

    useEffect(() => {
        if (detail.permission)
            setInfos({
                ...infos,
                ...detail.permission,
                status: detail.permission.status === 0 ? false : true,
            })
        if (detail.permission_tool && detail.permission_tool[0]) {
            let newPermissionToolbar = []
            detail.permission_tool.forEach((p) => {
                if (p.bdc_tool_child_id) {
                    const actionsJson = p.actions
                    const actions = actionsJson ? JSON.parse(actionsJson) : []
                    if (actions[0]) {
                        const newActions = actions.map((a) => p.bdc_tool_child_id + '-' + a) || []
                        newPermissionToolbar = [...newPermissionToolbar, ...newActions]
                    }
                }
            })
            setPermissionToolbar([...newPermissionToolbar])
        }
    }, [detail])

    useEffect(() => {
        if (users && users[0] && detail && detail.permission_member && detail.permission_member[0]) {
            setData([...users.filter((u) => detail.permission_member.some((p) => u.user_id === p.user_id))])
        }
    }, [detail, users])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        const newMembers =
            data && data[0]
                ? data.map((d) => {
                      return { user_id: d.user_id }
                  })
                : []
        const newToolbal = []
        if (permissionToolbar && permissionToolbar[0]) {
            permissionToolbar.map((p) => {
                const values = p.split('-')
                const findIndex = newToolbal.findIndex((n) => n.tool_child_id === Number(values[0]))
                if (findIndex < 0) {
                    newToolbal.push({ tool_child_id: Number(values[0]), actions: [values[1]] })
                } else {
                    if (newToolbal[findIndex]) {
                        newToolbal[findIndex].actions.push(values[1])
                    }
                }
            })
        }

        const tools = []
        const member = []
        if (Number(id)) {
            info = { ...removePropObject(info, detail), id: id }
            if (detail.permission_tool) {
                const deleteElements = detail.permission_tool.filter(
                    (d) => !newToolbal.some((n) => n.tool_child_id === d.bdc_tool_child_id),
                )
                if (deleteElements && deleteElements[0]) {
                    deleteElements.forEach((f) => {
                        tools.push({ id: f.id, deleted_at: 1 })
                    })
                }
                newToolbal.forEach((n, index) => {
                    const elements = detail.permission_tool.find((p) => p.bdc_tool_child_id === n.tool_child_id)
                    if (elements) {
                        if (JSON.stringify(n.actions) !== elements.actions) {
                            tools.push({ id: elements.id, actions: n.actions })
                        }
                    } else tools.push({ ...n })
                })
            }
            if (detail.permission_member) {
                const deleteElements = detail.permission_member.filter(
                    (d) => !newMembers.some((n) => n.user_id === d.user_id),
                )
                if (deleteElements && deleteElements[0]) {
                    deleteElements.forEach((f) => {
                        member.push({ id: f.id, deleted_at: 1 })
                    })
                }
                newMembers.forEach((n, index) => {
                    const elements = detail.permission_member.find((p) => p.user_id === n.user_id)
                    if (!elements) member.push({ ...n })
                })
            }
        }
        info = { ...info, tools: id ? tools : newToolbal, members: id ? member : newMembers }
        return info
    }

    useEffect(() => {
        if (listTool && listTool[0]) setPermission({ ...handlePermissions(listTool) })
    }, [listTool])

    return (
        <FormUpdate
            checkId={Number(id)}
            title="nhóm quyền"
            handleData={handleData}
            actions={{ add: addPermissionApi, update: updatePermissionApi }}
            route="/permission"
        >
            <div className="card">
                <TabView>
                    <TabPanel header="Thông tin chi tiết">
                        <div className="card bg-color">
                            <div className="grid formgrid">
                                <div className="col-12 lg:col-6">
                                    <InputForm
                                        id="name"
                                        value={infos.name}
                                        onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                        label="Tên nhóm quyền (*)"
                                        required
                                    />
                                </div>
                                <div className="col-12 lg:col-6">
                                    <InputSwitchForm
                                        checked={infos.status}
                                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 text-left">
                            <Accordion multiple activeIndex={[]} className="mb-4">
                                <AccordionTab header="Quyền trên app">
                                    {permission.app_permission && permission.app_permission[0] ? (
                                        <MultiSelectz
                                            tree={permission.app_permission}
                                            permissionToolbar={permissionToolbar}
                                            setPermissionToolbar={setPermissionToolbar}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </AccordionTab>
                                <AccordionTab header="Quyền trên web">
                                    {permission.web_permission && permission.web_permission[0] ? (
                                        <Accordion multiple activeIndex={[0, 1]} className="mb-4">
                                            <AccordionTab header="Công ty">
                                                <MultiSelectz
                                                    tree={permission.web_permission.filter((p) => p.type === 'company')}
                                                    permissionToolbar={permissionToolbar}
                                                    setPermissionToolbar={setPermissionToolbar}
                                                />
                                            </AccordionTab>
                                            <AccordionTab header="Dự án">
                                                <MultiSelectz
                                                    tree={permission.web_permission.filter(
                                                        (p) => p.type === 'building',
                                                    )}
                                                    permissionToolbar={permissionToolbar}
                                                    setPermissionToolbar={setPermissionToolbar}
                                                />
                                            </AccordionTab>
                                        </Accordion>
                                    ) : (
                                        ''
                                    )}
                                </AccordionTab>
                                <AccordionTab header="Quyền thông báo">
                                    {permission.noti_permission && permission.noti_permission[0] ? (
                                        <MultiSelectz
                                            tree={permission.noti_permission}
                                            permissionToolbar={permissionToolbar}
                                            setPermissionToolbar={setPermissionToolbar}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </TabPanel>
                    <TabPanel header="Danh sách người dùng">
                        <ListUser users={users} data={data} setData={setData} />
                    </TabPanel>
                </TabView>
            </div>
        </FormUpdate>
    )
}

export default UpdatePermission
