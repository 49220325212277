import {
      Columnz,
      DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, StatusBody, useGetParams
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { updateUnit } from '../api'
import { useCountUnit, useListUnit } from '../utils'
import UpdateUnitManagement from './UpdateUnitManagement'

const Header = ({ setParams }) => {
      const [filter, setFilter] = useState({ key_search: '' })


      return (
            <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
                  <Inputz
                        value={filter.key_search}
                        placeholder="Nhập tên đơn vị"
                        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
                  />
                  <Dropdownz
                        value={filter.status}
                        options={status}
                        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                        placeholder="Trạng thái"
                  />
            </GridForm>
      )
}

export default function UnitManagement() {
      const initParam = useGetParams()
      const [params, setParams] = useState(initParam)
      const data = useListUnit({ status: undefined, ...params, first: undefined, type: 'category' })
      const totalRecords = useCountUnit({ status: undefined, ...params, first: undefined, type: 'category' }) || 0
      const [visible, setVisible] = useState(false)

      return (
            <div className='card'>

                  {visible && <UpdateUnitManagement setParams={setParams} visible={visible} setVisible={setVisible} />}
                  <HeaderListForm title="Danh sách đơn vị" />
                  <Header setParams={setParams} />
                  <DataTablez
                        value={data}
                        title="đơn vị"
                        totalRecords={totalRecords}
                        params={params}
                        setParams={setParams}
                        route="/asset"
                        setVisibledDialog={setVisible}
                        headerInfo
                        actionsInfo
                        basePermissions={["insert", "detail",]}
                  >
                        <Columnz field="name" header="Tên đơn vị" />

                        <Columnz
                              header="Trạng Thái"
                              headerStyle={{ padding: 'auto', textAlign: 'center' }}
                              className='text-center'
                              body={(e) => StatusBody({ e, route: '/asset_mix_management', updateAction: updateUnit })}
                        />

                  </DataTablez>
            </div>
      )
}
