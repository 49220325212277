import { detailLogImportApartmentApi } from '@/api'
import { Body, FormUpdate, HeaderListForm, InputForm, InputTextareaForm } from '@/components'
import { statusLogImport, typeLogImport } from '@/constants'
import { useGetApi } from '@/hooks'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const initInfos = {
    import_by: '',
    import_by_name: '',
    mess: '',
    address: '',
    _id: '',
    created_at: '',
    isHandle: true,
}
const UpdateImport = () => {
    const { id } = useParams()
    const importDetail = useGetApi(detailLogImportApartmentApi, { id }, {})
    const [infos, setInfos] = useState(initInfos)

    useEffect(() => {
        if (importDetail._id) {
            const type = typeLogImport.find((t) => t.id === importDetail.type) || {}
            setInfos({ ...infos, ...importDetail, type: type.name })
        }
    }, [importDetail])

    return (
        <FormUpdate checkId={Number(id)} route="/import_history">
            <HeaderListForm title="Chi tiết lịch sử import căn hộ, cư dân" />
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm value={infos.type} label="Loại import" />
                        <InputForm value={infos.mess} label="Message" />
                        <div className="mb-3 px-2 change-disabled">
                            <div className="w-full flex justify-content-between">
                                <label className="font-medium w-full">Trạng thái</label>
                            </div>
                            <div className="w-full mt-2 flex pt-2 pb-2" style={{ minHeight: '42px' }}>
                                <Body data={statusLogImport} value={infos.status} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputForm value={infos.created_at} label="Thời gian tạo" />
                        <InputForm value={infos.isHandle} label="Xử lý" />
                        <InputTextareaForm value={JSON.stringify(infos.data, null, 2)} label="Data" />
                    </div>
                </div>
            </div>
        </FormUpdate>
    )
}

export default UpdateImport
