import { useState } from 'react';
import { useListDepartmentName, useListUserDepartment } from '../utils';
import { DropdownForm, FormUpdateDialog } from '@/components';
import { addManagerContactApi } from '../api';

const UpdateManagetContact = (props) => {
    const { setVisible, setParams, visible } = props
    const [infos, setInfos] = useState({
        department_id: null,
        building_company_id: null
    })
    const departments = useListDepartmentName()
    const userDepartments = useListUserDepartment({ department_id: infos.department_id })

    const handleData = () => {
        let info = { ...infos }
        if (!info.building_company_id) return 'Chưa chọn được nhân viên'
        // if (Number(id)) info = { ...removePropObject(info, postlDetail), id: visible }
        return info
    }

    return (
        <FormUpdateDialog
            title={(typeof visible === 'number' ? 'Cập nhật' : 'Thêm mới') + ' thành viên quản lý'}
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            refreshObjects={[setInfos]}
            setParams={setParams}
            actions={{ add: addManagerContactApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12">
                        <DropdownForm
                            id="department_id"
                            value={infos.department_id}
                            options={departments}
                            onChange={(e) => setInfos({ ...infos, department_id: e.target.value })}
                            label="Phòng ban (*)"
                        />
                    </div>
                    {infos.department_id &&
                        <div className="col-12">
                            <DropdownForm
                                id="building_company_id"
                                value={infos.building_company_id}
                                optionLabel='name_code'
                                optionValue='building_company_id'
                                options={userDepartments}
                                onChange={(e) => setInfos({ ...infos, building_company_id: e.target.value })}
                                label="Mã nhân viên (*)"
                            />
                        </div>}
                </div>
            </div>
        </FormUpdateDialog>
    )
}
export default UpdateManagetContact
