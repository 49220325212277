import { Import } from '@/components'
import { importElectricMeterApi } from '../api'
import { Calendar } from '@/uiCore'
import { useState } from 'react'

const MoreOption = ({ date, setDate }) => {
    return (
        <div className={'mt-4'}>
            <div className="flex justify-content-center">
                <Calendar value={date} onChange={(e) => setDate(e.value)} view="month" dateFormat="mm/yy" showIcon />
            </div>
        </div>
    )
}

const ImportElectricWater = ({ visible, setVisible, setParams }) => {
    const [date, setDate] = useState(new Date())
    return (
        <Import
            title="điện nước"
            visible={visible}
            setVisible={setVisible}
            action={importElectricMeterApi}
            MoreOption={() => MoreOption({ date, setDate })}
            template="https://devapibdc.dxmb.vn/import/import_electric_meter.xlsx"
            handleData={() => {
                if (!date) return ''
                else {
                    const year = date.getFullYear()
                    const month = (date.getMonth() + 1).toString().padStart(2, '0') //month start from 0
                    const result = `${year}${month}`
                    return { month_create: result }
                }
            }}
            handleSuccess={() => {
                setParams(pre => ({ ...pre, render: !pre.render }))
                setVisible(false)
            }}
        />
    )
}

export default ImportElectricWater
