import { Calendarz, Columnz, DataTablez, Dropdownz, GridForm, RenderHeader, TimeBody, useGetParams } from '@/components'
import { status } from '@/constants'
import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart'
import { InputText } from 'primereact/inputtext'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Công ty"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Tòa nhà"
            />
            <Calendarz value={filter.status} onChange showIcon placeholder="Tháng" />
        </GridForm>
    )
}

function NumberOfVehicles() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement)
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')
        const data = {
            labels: [
                'Greenbay1',
                'Phương Tây',
                'Fortune 28',
                'GoldenPearl',
                'Efficent',
                'River Lake',
                'BearSky',
                'Fuwa',
                'Golden Plaza 2',
                'Paragic',
                'Green Peel',
                'Imperiol',
                'Sliver Park',
                'New Duxware',
                'Opic2',
                'Astoria 89',
            ],
            datasets: [
                {
                    type: 'bar',
                    label: 'Xe máy',
                    backgroundColor: '#165BAA',
                    data: [1000, 900, 950, 810, 790, 750, 1200, 1000, 1100, 820, 700, 1150, 1100, 820, 650, 1200],
                    borderColor: 'white',
                    borderWidth: 1,
                    barPercentage: 0.5,
                },
                {
                    type: 'bar',
                    label: 'Ô tô',
                    backgroundColor: '#55B95F',
                    data: [200, 150, 150, 180, 350, 100, 150, 100, 150, 200, 250, 170, 100, 200, 220, 150],
                    borderWidth: 1,
                    barPercentage: 0.5,
                },
                {
                    type: 'bar',
                    label: 'Xe khác',
                    borderColor: '#FEBA66',
                    backgroundColor: '#FEBA66',
                    borderWidth: 1,
                    data: [50, 150, 70, 140, 70, 160, 180, 150, 130, 140, 152, 131, 180, 156, 141, 80],
                    barPercentage: 0.5,
                },
            ],
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    position: 'bottom',
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true,
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        }
        setChartData(data)
        setChartOptions(options)
    }, [])

    return (
        <div className="card">
            <Header setParams={setParams} />
            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Chart
                    type="line"
                    data={chartData}
                    options={chartOptions}
                    style={{ height: '600px', marginTop: '40px' }}
                />
            </div>
        </div>
    )
}

export default NumberOfVehicles
