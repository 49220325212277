import { Calendarz, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, Inputz, useGetParams } from '@/components'
import { payments, types } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteReceiptApi } from '../api'
import { useCountReceipt, useListReceipt } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const buildings = useSelector((state) => state.buildings)
    const apartments = useSelector((state) => state.apartments)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Mã BK ..."
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.payments}
                options={payments}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Hình thức"
            />
            <Dropdownz
                value={filter.types}
                options={types}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Kiểu phiếu"
            />
            <Dropdownz
                value={filter.buildings}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Calendarz
                value={filter.dates}
                className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                placeholder="Ngày hoạch toán (Chọn khoảng thời gian)"
            />
            <Calendarz
                value={filter.dates}
                className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, dates: e.target.value })}
                placeholder="Ngày lập phiếu (Chọn khoảng thời gian)"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn căn hộ"
            />
        </GridForm>
    )
}

const ListReceipts = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListReceipt({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountReceipt({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Danh sách phiếu thu" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Phiếu thu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/list_receipt"
                actionsInfo={{ deleteAction: deleteReceiptApi }}
            >
                <Columnz field="name" header="Mã chứng từ" />
                <Columnz field="address" header="Hình thức" />
                <Columnz field="phone" header="Loại phiếu" />
                <Columnz field="phone" header="Ngày lập phiếu" />
                <Columnz field="phone" header="Ngày hạch toán" />
                <Columnz field="phone" header="Căn hộ" />
                <Columnz field="phone" header="Tòa" />
                <Columnz field="phone" header="Khách hàng" />
                <Columnz field="phone" header="Số tiền" />
                <Columnz field="phone" header="Nội dung" />
                <Columnz field="phone" header="Người tạo" />
                <Columnz field="phone" header="In phiếu" />
            </DataTablez>
        </div>
    )
}

export default ListReceipts
