import { listTypePriceApi } from '@/api/projects/info'
import { Body, CalendarForm, Columnz, DropdownForm, FormUpdateDialog, InputNumberForm, MultiSelectForm } from '@/components'
import { apartment_charges } from '@/constants'
import { useGetApi } from '@/hooks'
import { DataTable } from '@/uiCore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { debtCalculationWaterApi } from '../api'
import { type_prices_table } from './../../../../constants/main'

const CalElectricWaterDebt = (props) => {
    const { setVisible, setParams, visible } = props
    const currentDate = new Date();
    const nextThreeDays = new Date(currentDate);
    nextThreeDays.setDate(currentDate.getDate() + 3);
    const [infos, setInfos] = useState({ status: 1, deadline: nextThreeDays })
    const apartments = useSelector((state) => state.apartments)
    const [cycleMonth, setCycleMonth] = useState('')
    const [cycleYear, setCycleYear] = useState('')
    const getFormattedDate = () => {
        const yearMonth = `${cycleYear}${String(cycleMonth).padStart(2, '0')}`
        return yearMonth
    }
    const defaultCurrentMonth = moment().format('YYYYMM')
    const [selectedProducts, setSelectedProducts] = useState(null)
    const [rowEditor, setRowEditor] = useState(null)
    const typeMonneys = [
        { name: 'VND', id: 0 },
        { name: '%', id: 1 },
    ]
    useEffect(() => {
        const currentMonth = moment().format('MM')
        const currentYear = moment().format('YYYY')

        setCycleMonth(currentMonth)
        setCycleYear(currentYear)
    }, [])
    const priceEditor = (options) => {
        return <InputNumberForm value={options.value} handleOnChange={(e) => options.editorCallback(e)} />
    }
    const onRowEditComplete = (e) => {
        let _products = [...dataTable]
        let { newData, index } = e
        _products[index] = newData
        setDataTable(_products)
        setRowEditor(true)
    }
    const onRowEditCancel = (e) => {
        setRowEditor(true)
    }
    const onRowEditInit = (e) => {
        setRowEditor(false)
    }
    const cycleName = getFormattedDate()




    const isoDateString = infos.deadline;
    const date = new Date(isoDateString);


    const yearv2 = date.getFullYear();
    const monthv2 = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');


    const formattedDate = `${yearv2}-${monthv2}-${day}`;
    const handleData = () => {
        if (selectedProducts === null) return 'Vui lòng tích chọn danh mục cần tính công nợ!'
        let newProducts = dataTable.filter((p) => selectedProducts.some((s) => s.price_type_id === p.price_type_id))
        newProducts.forEach(obj => {
            delete obj.updated_at;
            delete obj.deleted_at;
            delete obj.cut_photo;
            delete obj.price_type;
            delete obj.type_sv;
            delete obj.type;
            delete obj.created_at;
            delete obj.building_id;
            delete obj.id;
        });
        let info = {
            ...infos,
            check_all_apartment: infos.apartment_charges,
            cycle_name: cycleName,
            apartment_ids: infos.apartment_charges === 0 ? infos.apartment_ids : [],
            deadline: formattedDate
        }
        if (newProducts && newProducts[0]) {
            info.service_config = newProducts.map((e) => ({
                ...e,
            }))
        }
        if (info.apartment_charges === 0 && info.apartment_ids === undefined)
            return 'Vui lòng chọn căn hộ cần tính công nợ!'
        if (infos.apartment_charges === undefined)
            return 'Vui lòng chọn căn hộ tính phí!'
        if (rowEditor === false)
            return 'Vui lòng xác nhận chỉnh sửa danh mục cần tính công nợ!'
        if (newProducts && ((newProducts[0] && !newProducts[0].cycle_name_use) || newProducts[1] && !newProducts[1].cycle_name_use)) return 'Vui lòng chọn khóa kỳ cho danh mục cần tính công nợ!'
        return info
    }
    const generateCycleData = () => {
        const currentDate = moment()
        const monthsData = []
        for (let i = -12; i <= 12; i++) {
            const newMonth = currentDate.clone().add(i, 'months')
            const id = i + 6

            monthsData.push({
                id,
                month_create: newMonth.format('YYYYMM'),
            })
        }

        return monthsData
    }
    const generateMonthData = () => {
        const currentDate = moment()
        const monthsData = []

        for (let i = 0; i < 12; i++) {
            const newMonth = currentDate.clone().add(i, 'months')
            const id = i + 1

            monthsData.push({
                id,
                month: newMonth.format('MM'),
            })
        }
        return monthsData
    }
    const generateYearData = () => {
        const currentDate = moment()
        const years = []

        for (let i = -2; i < 3; i++) {
            const newMonth = currentDate.clone().add(i, 'years')
            const id = i + 1

            years.push({
                id,
                year: newMonth.format('YYYY'),
            })
        }

        return years
    }
    const year = generateYearData()
    const month = generateMonthData()
    const jsonData = generateCycleData()
    const typeEditor = (options) => {
        return (
            <DropdownForm
                value={options.value}
                onChange={(e) => options.editorCallback(e.value)}
                options={jsonData}
                optionLabel="month_create"
                optionValue="month_create"
            />
        )
    }
    const typeEditorV2 = (options) => {
        return (
            <DropdownForm
                value={options.value}
                onChange={(e) => options.editorCallback(e.value)}
                options={typeMonneys}
            />
        )
    }
    const typePrice = useSelector((state) => state.typePrice)
    const type_pricesv2 = typePrice.filter(d => d.type_sv === 3 || d.type_sv == 5)
    console.log(type_pricesv2);
    const [dataTable, setDataTable] = useState()
    useEffect(() => {
        setDataTable(type_pricesv2.map(d => {
            return {
                ...d,
                cycle_name_use: defaultCurrentMonth,
                discount: 0,
                price_type_id: d.id
            }
        }))
    }, [JSON.stringify(type_pricesv2)])

    return (
        <FormUpdateDialog
            title="Thiết lập công nợ"
            handleData={handleData}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            refreshObjects={[setInfos]}
            actions={{ add: debtCalculationWaterApi }}
        >
            <div className="card bg-color">
                <div className="flex flex-row justify-content-center flex-wrap">
                    <div className="flex align-items-center justify-content-center w-4rem h-4rem font-medium">
                        Kỳ tính
                    </div>
                    <div className="flex align-items-center justify-content-center">
                        <div className="w-full">
                            <DropdownForm
                                value={cycleMonth}
                                options={month}
                                optionLabel="month"
                                optionValue="month"
                                onChange={(e) => setCycleMonth(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex align-items-center justify-content-center ">
                        <div className="w-full">
                            <DropdownForm
                                value={cycleYear}
                                options={year}
                                optionLabel="year"
                                optionValue="year"
                                onChange={(e) => setCycleYear(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-column justify-content-center flex-wrap w-full align-items-center">
                    <div className="w-5">
                        <CalendarForm label="Hạn thanh toán" value={infos.deadline} onChange={(e) => setInfos({ ...infos, deadline: e.target.value })} />

                    </div>
                </div>
                <div className="flex flex-column justify-content-center flex-wrap w-full align-items-center">
                    <div className="w-5">
                        <DropdownForm
                            id="apartment_charges"
                            value={infos.apartment_charges}
                            label="Căn hộ tính phí"
                            options={apartment_charges}
                            onChange={(e) => setInfos({ ...infos, apartment_charges: e.target.value })}
                        />
                    </div>
                    {infos.apartment_charges === 0 ? (
                        <div className="w-5">
                            <MultiSelectForm
                                id="apartment_ids"
                                value={infos.apartment_ids}
                                options={apartments}
                                onChange={(e) => setInfos({ ...infos, apartment_ids: e.target.value })}
                                label="Căn hộ (*)"
                            />
                        </div>
                    ) : null}
                </div>
                <DataTable
                    value={dataTable}
                    editMode="row"
                    stripedRows
                    showGridlines
                    // dataKey="id"
                    selectionMode="checkbox"
                    onRowEditComplete={onRowEditComplete}
                    onRowEditInit={onRowEditInit}
                    onRowEditCancel={onRowEditCancel}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                >
                    <Columnz selectionMode="multiple" headerStyle={{ width: '3rem' }}></Columnz>

                    <Columnz field="name" body={(e) => Body({ data: type_prices_table, value: e.type })} header="Tất cả" style={{ width: '10%' }}></Columnz>
                    <Columnz
                        field="cycle_name_use"
                        header="Tháng kỹ thuật viên chốt số"
                        editor={(options) => typeEditor(options)}
                        style={{ width: '40%' }}
                    ></Columnz>
                    <Columnz
                        field="discount"
                        header="Giảm trừ"
                        editor={(options) => priceEditor(options)}
                        style={{ width: '40%' }}
                    ></Columnz>
                    <Columnz
                        field="discount_type"
                        body={(e) => (e.discount_type === 0 ? <span>VND</span> : <span>%</span>)}
                        header="Đơn vị"
                        editor={(options) => typeEditorV2(options)}
                        style={{ width: '20%' }}
                    ></Columnz>
                    <Columnz
                        rowEditor
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                        bodyStyle={{ textAlign: 'center' }}
                    ></Columnz>
                </DataTable>
            </div>
        </FormUpdateDialog>
    )
}

export default CalElectricWaterDebt
