import {
    Body,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    useGetParams,
} from '@/components'
import { useState } from 'react'
import { deleteDepartmentApi } from '../api'
import { useListDepartment, useCountDepartment } from '../utils'
import { useSelector } from 'react-redux'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { DataTable } from 'primereact/datatable'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const HistoryNotification = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const users = useSelector((state) => state.users)
    const data = useListDepartment({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDepartment({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    const moreActions = [{ icon: 'pi pi-comment' }, { icon: 'pi pi-calendar' }]
    const items = [
        {
            label: 'Gửi lại email',
            icon: 'pi pi-send',
            actionType: 'xóa',
        },
    ]
    const BodyInfo = (info) => {
        return (
            <>
                <DataTable>
                    <Column header="Trạng thái	" />
                    <Column header="Phương thức gửi" />
                    <Column header="Tổng" />
                    <Column header="Đã gửi" />
                    <Column
                        header="Thất bại
"
                    />
                </DataTable>
            </>
        )
    }

    return (
        <div className="card ">
            <HeaderListForm title="Danh sách bộ phận" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bộ phận"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/history_notification"
                headerInfo={{ items }}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="" header="ID" />
                <Columnz field="" header="Tiêu đề" />
                <Columnz field="" header="Loại thông báo" />
                <Columnz field="" header="Ngày gửi" />
                <Columnz body={(e) => BodyInfo(e)} header="Thống kê" />
            </DataTablez>
        </div>
    )
}

export default HistoryNotification
