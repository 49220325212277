import React from 'react'
import {Columnz, DataTablez, DataTablezV3, Dropdownz, HeaderListForm} from '@/components'
import {useGetParams} from '@/components'
import {useState} from 'react'
import {useListTotalDaily, useListTotalDailySynthetic} from '../utils'
import {Calendarz, TimeBody} from './ParkingForm'
import {vehicle_type} from '@/constants'
import {databaseDate} from '@/lib'
import DataTableParking from './TableRow'
import {exportDailyReport, listTotalDailyApi} from '../api'
import {GridForm} from '../../parking_check/screens/ParkingForm'
import {useGetApi} from "@/lib/react-query";

const Header = ({setParams}) => {
    const today = new Date()
    const [filter, setFilter] = useState(() => {
        return {
            dates: [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)],
            vehicle_type: 'Car'
        }
    })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Calendarz
                className={"lg:col-5"}
                value={filter.dates}
                onChange={(e) => setFilter({...filter, dates: e.value})}
                placeholder={"Chọn khoảng thời gian"}
                selectionMode="range"
            />
            <Dropdownz
                value={filter.type_vehicle || 'Car'}
                options={vehicle_type}
                onChange={(e) => setFilter({...filter, type_vehicle: e.target.value})}
                placeholder="Loại phương tiện"
                showClear={false}
            />
        </GridForm>
    )
}
const ParkingReport = () => {
    const today = new Date()
    const initParam = useGetParams()
    const [params, setParams] = useState({
        from: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
        to: databaseDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)),
        ...initParam,
        page: 1,
        limit: 200
    })
    const {data, isLoading} = useGetApi(listTotalDailyApi, {
        status: undefined, ...params,
        first: undefined,
        page: 1,
        limit: 200
    }, "daily")
    const data1 = useListTotalDailySynthetic({status: undefined, ...params, first: undefined, page: 1, limit: 200})
    data1.sort((a, b) => {
        const order = {
            "Car": 0,
            "Motor": 1,
            "ElectricMotor": 2,
            "Bicycle": 3
        };
        return order[a.type_vehicle] - order[b.type_vehicle];
    });
    const data2 = data1.map((item) => ({
        vehicle: vehicle_type.find(v => v.id === item.type_vehicle)?.name,
        data: [
            {
                main: 'Hệ thống giám sát bãi xe AI',
                field1: 'Lượt vào',
                n1: parseInt(item.ai_in),
                field2: 'Lượt ra',
                n2: parseInt(item.ai_out),
            },
            {
                main: 'Phần mềm quản lý bãi xe ',
                field1: 'Lượt vào',
                n1: parseInt(item.pm_in),
                field2: 'Lượt ra',
                n2: parseInt(item.pm_out),
            },
            {
                main: 'So sánh',
                field1: 'Lượt vào',
                n1: Math.abs(parseInt(item.ai_in) - parseInt(item.pm_in)),
                field2: 'Lượt ra',
                n2: Math.abs(parseInt(item.ai_out) - parseInt(item.pm_out)),
            },
        ],
    }))
    return (
        <div className="card">
            <HeaderListForm title="Tổng hợp hàng ngày"/>
            <Header setParams={setParams}/>
            <div className="grid">
                <div className="col-12 lg:col-5 xl:col-4 px-2">
                    <div className="card mt-3">
                        {data2.map((item, index) => (
                            <DataTableParking index={index} item={item}/>
                        ))}
                    </div>
                </div>
                <div className="col-12 lg:col-7 xl:col-8 p-4">
                    <DataTablezV3
                        loading={isLoading}
                        value={data?.data?.data?.listDaily}
                        title="Báo cáo hàng ngày"
                        totalRecords={data?.data?.data?.count || 0}
                        params={{...params, dates: undefined}}
                        setParams={setParams}
                        route="/parking_report"
                        headerInfo={{exportApi: exportDailyReport}}
                        basePermissions={['export']}
                        rowsPerPageOptions={[200]}
                    >
                        <Columnz header="Ngày ghi nhận" body={(e) => TimeBody(e.date_by_int)}/>
                        <Columnz header="Giám sát vào" field="ai_in"/>
                        <Columnz header="Quản lý vào" field="pm_in"/>
                        <Columnz header="Lệch vào" body={(e) => Math.abs(e.ai_in - e.pm_in)}/>
                        <Columnz header="Giám sát ra" field="ai_out"/>
                        <Columnz header="Quản lý ra" field="pm_out"/>
                        <Columnz header="Lệch ra" body={(e) => Math.abs(e.ai_out - e.pm_out)}/>
                    </DataTablezV3>
                </div>
            </div>
        </div>
    )
}
export default ParkingReport
