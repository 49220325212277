import {
    Body,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    StatusBody,
    useGetParams,
} from '@/components'
import { useState } from 'react'
import { deleteDepartmentApi } from '../api'
import { useListDepartment, useCountDepartment } from '../utils'
import { useSelector } from 'react-redux'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

const ListComments = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const users = useSelector((state) => state.users)
    const data = useListDepartment({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountDepartment({ status: undefined, ...params, first: undefined }) || 0
    const [selectedProducts, setSelectedProducts] = useState([])
    return (
        <div className="card ">
            <HeaderListForm title="Danh sách bình luận" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="bộ phận"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/list_comments"
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            >
                <Columnz field="" header="Bài viết" />
                <Columnz field="" header="Nội dung bình luận" />
                <Columnz field="" header="Người bình luận" />
                <Columnz field="" header="Thời gian bình luận" />
            </DataTablez>
        </div>
    )
}

export default ListComments
