import { useEffect, useState } from 'react'
import {
    countReceiptExpenditureApi,
    listCoinApi,
    detailReceiptExpenditureApi,
    listApartmentName,
    listDebitReceipt,
    listReceiptExpenditureApi,
    renderReceipt,
    listServiceApartments,
    listDebitHasPaid,
    getApartmentServiceBySv,
} from '../api'
import { listProgressives } from '../../building_services/api'

export const useListReceiptExpenditure = (params, render) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listReceiptExpenditureApi({ status: 1, ...params, })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params), render])
    return data
}
export const useListServiceApartments = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listServiceApartments({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
export const useListDebitHasPaid = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDebitHasPaid({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
// export const useListServiceApartments = (params) => {
//     const [data, setData] = useState([])
//     async function fetchData() {
//         const response = await listServiceApartments()
//         if (response.data.status) setData(response.data.data)
//     }
//     useEffect(() => {
//         fetchData()
//     }, [JSON.stringify(params)])
//     return data
// }

export const useListCoin = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listCoinApi({ ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListApartmentName = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listApartmentName({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountReceiptExpenditure = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countReceiptExpenditureApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailReceiptExpenditure = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailReceiptExpenditureApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

export const useRenderReceipt = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await renderReceipt({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}

//---------------------------------------Create---------------------------------
export const useListDebitReceipt = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listDebitReceipt({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListProgressives = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listProgressives({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useGetApartmentService = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await getApartmentServiceBySv({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
