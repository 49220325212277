import { postData, getData } from '@/lib/request'

export const listCustomerApi = (params) => getData('web2/list_table/getListBillV2', params)
export const countCustomerApi = (params) => getData('web2/list_table/countBillV2', params)
export const deleteBillApi = (params) => postData('web2/list_table/deleteBill', params)
export const updateStatusBillApi = (params) => postData('web2/list_table/updateStatusBill', params)
export const detailReceiptApi = (params) => getData('web2/list_table/getDetailReceipt', params)
export const detailNoti = (params) => getData('web2/list_table/renderBill', params)
export const listCycleByBillApi = (params) => getData('web2/info/getListCycleByBill', params)
export const printBills = (params) => getData('web2/bill/renderListBill', params)
export const exportInvoiceByBillsApi = (params) => postData('web2/bill/exportInvoiceByBills', params)
export const exportInvoiceByBillApi = (params) => postData('web2/bill/exportInvoiceByBill', params)
export const viewInvoiceByBillApi = (params) => getData('web2/bill/viewInvoiceByBill', params)
export const viewInvoiceByBillslApi = (params) => getData('web2/bill/viewInvoiceByBills', params)
export const exportlistCustomerApi = (params) => getData('web2/list_table/expListBillV2', params, true)

