import {
      Dialogz,
      FormUpdateDialog,
      InputForm,
      InputNumberForm,
} from '@/components';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { addPostApi } from '../api';
import { useDispatch } from 'react-redux';
import { setToast } from '@/redux/features';
import { listToast } from '@/constants';

const InputQuestion = ({ handleOnChange, id, value, handleDelete }) => {
      return <div className="flex align-items-center justify-content-between">
            <div className="w-full">
                  <InputForm placeholder="Nhập câu trả lời" value={value[`poll_${id}`]} onChange={e => handleOnChange({ value: e.target.value, id })} />
            </div>
            <div className='mb-2'>
                  <Button type='button' icon="pi pi-trash" onClick={e => handleDelete(id)} severity="danger" className='' />
            </div>
      </div>
}

const AddQuestion = (props) => {
      const { visible, setVisible, setPollOptions, pollOptions, indexQuestion, setIndexQuestion } = props
      const [infos, setInfos] = useState({ title: '', options: '', maximum: '' });
      const [options, setOptions] = useState([{ poll_0: null }])

      const handleAdd = () => {
            setOptions(prev => [...prev, { poll: null }])
      }



      const handleOnChange = ({ value, id }) => {
            const newoptions = [...options];

            const index = newoptions.findIndex((d, i) => i === id);

            if (index === id) {
                  newoptions[index] = { [`poll_${id}`]: value };
            }
            setOptions(newoptions)
      }

      const handleDelete = (id) => {
            if (id !== 0) {
                  const newoptions = [...options];
                  const newList = newoptions.filter((d, i) => i !== id)
                  setOptions(newList);
            }
      }
      const dispatch = useDispatch()


      useEffect(() => {
            let newPollOptions = pollOptions[indexQuestion]
            if (newPollOptions) {
                  setInfos({
                        ...newPollOptions,
                  })
                  if (Array.isArray(newPollOptions.options)) {
                        setOptions(newPollOptions.options)
                  } else {
                        setOptions(JSON.parse(newPollOptions.options))
                  }
            }
      }, [pollOptions, indexQuestion])

      const handleAddAsk = () => {
            if (indexQuestion || indexQuestion === 0) {
                  let updatedOptions = [...pollOptions]
                  updatedOptions[indexQuestion].options = options
                  if (infos.maximum > options.length) {
                        dispatch(setToast({ ...listToast[1], detail: "Số câu trả lời tối đa phải nhỏ hơn số đáp án" }))
                        return
                  }
                  setPollOptions(updatedOptions)
                  setVisible(false)
            } else {
                  let info = { ...infos, options }
                  if (infos.maximum > options.length) {
                        dispatch(setToast({ ...listToast[1], detail: "Số câu trả lời tối đa phải nhỏ hơn số đáp án" }))
                        return
                  }
                  const listPollOptions = info
                  setPollOptions(prev => [...prev, listPollOptions])
                  setVisible(false)
            }
      }

      useEffect(() => {
            if (visible === false) {
                  setIndexQuestion(false)
                  setInfos({ title: '', options: '', maximum: '' })
                  setOptions([{ poll_0: null }])
            }
      }, [visible])


      const footerContent = (
            <div>
                  <Button label="Hủy" icon="pi pi-times" onClick={() => setVisible(false)} severity="danger" />
                  <Button label="Thêm" icon="pi pi-check" onClick={handleAddAsk} />
            </div>
      );
      return (
            <Dialogz
                  visible={visible}
                  setVisible={setVisible}
                  route='/posts'
                  title={indexQuestion || indexQuestion === 0 ? "Cập nhật câu hỏi" : "Thêm câu hỏi"}
                  footer={footerContent}
                  refreshObjects={[setInfos]}
                  width="1200px"
            >
                  <InputForm label="Câu hỏi thăm dò (*)" value={infos.title} onChange={e => setInfos({ ...infos, title: e.target.value })} />

                  <div className="">
                        {options && Array.isArray(options) && options.map((d, index) =>
                              <InputQuestion id={index} value={d} handleOnChange={handleOnChange} handleDelete={handleDelete} key={index} />)}
                        <div className="flex flex-column pl-2 w-4 py-3">
                              <Button type="button" label="Thêm câu trả lời" severity="success" raised onClick={handleAdd} />
                              <br />
                              <InputNumberForm value={infos.maximum} handleOnChange={e => setInfos({ ...infos, maximum: e })} label="Số câu trả lời tối đa" />
                        </div>
                  </div>

            </Dialogz>
      )
}

export default AddQuestion
