
import { Import } from '@/components'
import { importServiceApartment } from '../api'

const ImportServiceApartment = ({ visible, setVisible, setParams }) => {

      return (
            <Import
                  title="dịch vụ căn hộ"
                  visible={visible}
                  setVisible={setVisible}
                  action={importServiceApartment}
                  template="https://devapibdc.dxmb.vn/import/import_service_apartment.xlsx"
                  handleSuccess={() => {
                        setParams(pre => ({ ...pre, render: !pre.render }))
                        setVisible(false)
                  }}
            />
      )
}

export default ImportServiceApartment
