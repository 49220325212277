import { useParams } from 'react-router-dom';
import { useRenderQrCode } from '../utils';

function PrintQrCode() {
    const { ids } = useParams()
    const data = useRenderQrCode(ids)
    console.log(data);
    return (
        <div className='flex justify-content-center align-items-center' >
            {
                data &&
                typeof data === 'string' && (
                    <div style={{ width: "1140px", fontFamily: 'DejaVu Sans' }} dangerouslySetInnerHTML={{ __html: data }} />
                )
            }
        </div>
    )
}

export default PrintQrCode

