import { getData, postData } from '@/lib/request'

export const listApartmentHandoverApi = (params) => getData('web2/apartment_handover/getListApartmentHandover', params)
export const countApartmentHandoverApi = (params) => getData('web2/apartment_handover/countApartmentHandover', params)
export const deleteApartmentHandoverApi = (params) =>
    postData('web2/apartment_handover/deleteApartmentHandover', params)
export const detailApartmentHandoverApi = (params) =>
    getData('web2/apartment_handover/getDetailApartmentHandover', params)
export const addApartmentHandoverApi = (params) => postData('/web2/apartment_handover/addApartmentHandover', params)
export const updateApartmentHandoverApi = (params) =>
    postData('/web2/apartment_handover/updateApartmentHandover', params)
