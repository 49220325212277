import { listGroupApartmentApi } from '@/api'
import {
    CalendarForm,
    Columnz,
    DropdownForm,
    FormUpdateDialog,
    InputForm,
    InputNumberForm,
    MultiSelectForm
} from '@/components'
import { useGetApi } from '@/hooks'
import { listServiceV2Api } from "@/modules/accountant/aparment_services/api"
import { DataTable } from '@/uiCore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { debtCalculationApi } from '../api'

const CaculatorDebtMonth = (props) => {
    let { visible, setVisible, apartment_id_props } = props
    const currentDate = new Date();
    const nextThreeDays = new Date(currentDate);
    nextThreeDays.setDate(currentDate.getDate() + 3);
    const [infos, setInfos] = useState({
        cycle_name: new Date(),
        deadline: nextThreeDays
    })
    const [selectedProducts, setSelectedProducts] = useState(null)
    const apartments = useSelector((state) => state.apartments)
    const groups = useGetApi(listGroupApartmentApi, {}, [])
    const services = useGetApi(listServiceV2Api, {}, [])
    const [products, setProducts] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        if (services && services[0])
        {
            const date = new Date(infos.cycle_name)
            const servicez = services.filter((s) => s.status && ![3, 5, 6].includes(s.type))
            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
            const servicezv2 = servicez?.filter(d => d.show === true)
            const newData = servicezv2?.map((s) => {
                return {
                    bdc_service_id: s.id,
                    name: s.name,
                    start: new Date(date.getFullYear(), date.getMonth(), s.bill_date || 1),
                    end: new Date(date.getFullYear(), date.getMonth(), Math.min(lastDayOfMonth)),
                    discount: 0,
                    discount_type: 0,
                    discount_note: '',
                    apartment_ids: s.bdc_apartment_id?.split(','),
                }
            })
            setProducts(newData)
            setData(newData)
        }
    }, [services, infos.cycle_name])

    useEffect(() => {
        if (apartment_id_props)
        {
            setInfos({ ...infos, type: 2, apartment_ids: [apartment_id_props] })
        }
    }, [apartment_id_props])

    const types = [
        { name: 'Tất cả căn hộ', id: 1 },
        { name: 'Nhóm căn hộ', id: 3 },
        { name: 'Căn hộ', id: 2 },
    ]

    const typeMonneys = [
        { name: 'VNĐ', id: 0 },
        { name: '%', id: 1 },
    ]

    const onRowEditComplete = (e) => {
        let _products = [...products]
        let { newData, index } = e
        _products[index] = newData
        setProducts(_products)
    }

    const textEditor = (options) => {
        return <InputForm type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }
    const textEditorV2 = (options) => {
        return <InputForm type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    const priceEditor = (options) => {
        return <InputNumberForm value={options.value} handleOnChange={(e) => options.editorCallback(e)} />
    }

    const typeEditor = (options) => {
        return (
            <DropdownForm
                value={options.value}
                onChange={(e) => options.editorCallback(e.value)}
                options={typeMonneys}
            />
        )
    }

    const calendarEditor = (options) => {
        return <CalendarForm value={options.value} onChange={(e) => options.editorCallback(e.value)} />
    }
    const calendarTemplate = (e) => {
        return <span>{moment(e).format('DD-MM-YYYY')}</span>
    }

    const typeTemplate = (e) => {
        const type = typeMonneys.find((t) => t.id === e)
        return <span>{type.name}</span>
    }

    useEffect(() => {
        if (infos.type && infos.type !== 1)
            setData(products.filter((p) => p.apartment_ids?.some((d) => infos.apartment_ids?.includes(Number(d)))))
        setSelectedProducts([])
    }, [infos.apartment_ids, infos.type])
    const isoDateString = infos.deadline;
    const date = new Date(isoDateString);


    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');


    const formattedDate = `${year}-${month}-${day}`;
    const handleData = () => {

        let info = { ...infos, deadline: formattedDate }
        let newProducts = products.filter(
            (p) =>
                Array.isArray(selectedProducts) && selectedProducts.some((s) => s.bdc_service_id === p.bdc_service_id),
        )
        if (!info.type) return 'Vui lòng chọn căn hộ tính phí'
        if (info.type === 1)
        {
            info.check_all_apartment = 1
        } else info.check_all_apartment = undefined
        if (!info.check_all_apartment && !(info.apartment_ids && info.apartment_ids[0]))
            return 'Vui lòng chọn căn hộ tính phí'
        const year = info.cycle_name.getFullYear()
        const month = (info.cycle_name.getMonth() + 1).toString().padStart(2, '0') // Đảm bảo tháng luôn có hai chữ số
        info.cycle_name = year + month
        if (newProducts && newProducts[0])
        {
            info.service_config = newProducts.map((e) => ({
                ...e,
                start: moment(e.start).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(e.end).format('YYYY-MM-DD HH:mm:ss'),
            }))
        } else return 'Vui lòng chọn dịch vụ'
        return info
    }
    useEffect(() => {
        setInfos({ ...infos, type: null, apartment_ids: null, group_ids: null })
    }, [infos.cycle_name])
    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            handleData={handleData}
            route="/debt"
            title="Tính toán công nợ tháng"
            actions={{ add: debtCalculationApi }}
        >
            <div className="flex justify-content-center align-items-center gap-3">
                <div className={'w-6'}>
                    <CalendarForm
                        label={'Kỳ tháng'}
                        value={infos.cycle_name}
                        onChange={(e) => setInfos({ ...infos, cycle_name: e.target.value })}
                        view="month"
                        dateFormat="mm/yy"
                    />
                    <CalendarForm label="Hạn thanh toán" value={infos.deadline} onChange={(e) => setInfos({ ...infos, deadline: e.target.value })} />
                    <DropdownForm
                        filter={false}
                        label={'Căn hộ tính phí'}
                        value={infos.type}
                        onChange={(e) =>
                            setInfos({ ...infos, type: e.target.value, apartment_ids: undefined, group_ids: undefined })
                        }
                        options={types}
                        disabled={apartment_id_props}
                    />
                    {infos.type === 2 && (
                        <MultiSelectForm
                            label={'Căn hộ tính phí'}
                            value={infos.apartment_ids}
                            onChange={(e) => setInfos({ ...infos, apartment_ids: e.target.value })}
                            options={apartments}
                            disabled={apartment_id_props}
                        />
                    )}
                    {infos.type === 3 && (
                        <MultiSelectForm
                            label={'Nhóm căn hộ tính phí'}
                            value={infos.group_ids}
                            onChange={(e) => {
                                const apartment_ids = apartments.map((a) => {
                                    if (e.target.value.includes(a.bdc_apartment_group_id)) return a.id
                                })
                                setInfos({ ...infos, group_ids: e.target.value, apartment_ids })
                            }}
                            options={groups}
                            optionLabel={'group_name'}
                            disabled={apartment_id_props}
                        />
                    )}
                </div>
            </div>
            <div className="mt-4 overflow-y-auto" style={{ maxHeight: '270px' }}>
                <DataTable
                    value={data}
                    editMode="row"
                    stripedRows
                    showGridlines
                    dataKey="bdc_service_id"
                    selectionMode="checkbox"
                    onRowEditComplete={onRowEditComplete}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    className="overflow-x-hidden"
                >
                    <Columnz selectionMode="multiple" headerStyle={{ width: '3rem' }}></Columnz>
                    <Columnz
                        field="name"
                        header="Tất cả"
                        editor={(options) => textEditor(options)}
                        style={{ width: '15%' }}
                    ></Columnz>
                    <Columnz
                        field="start"
                        header="Ngày bắt đầu"
                        body={(e) => calendarTemplate(e.start)}
                        editor={(options) => calendarEditor(options)}
                        style={{ width: '15%' }}
                    ></Columnz>
                    <Columnz
                        field="end"
                        header="Ngày kêt thúc"
                        body={(e) => calendarTemplate(e.end)}
                        editor={(options) => calendarEditor(options)}
                        style={{ width: '15%' }}
                    ></Columnz>
                    <Columnz
                        field="discount"
                        header="Giảm trừ"
                        editor={(options) => priceEditor(options)}
                        style={{ width: '15%' }}
                    ></Columnz>
                    <Columnz
                        field="discount_type"
                        body={(e) => typeTemplate(e.discount_type)}
                        header="Đơn vị"
                        editor={(options) => typeEditor(options)}
                        style={{ width: '5%' }}
                    ></Columnz>
                    <Columnz
                        field="discount_note"
                        header="Lý do"
                        editor={(options) => textEditorV2(options)}
                        style={{ width: '30%' }}
                    ></Columnz>
                    <Columnz
                        rowEditor
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                        bodyStyle={{ textAlign: 'center' }}
                    ></Columnz>
                </DataTable>
            </div>
        </FormUpdateDialog>
    )
}

export default CaculatorDebtMonth
