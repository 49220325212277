import {importApartmentApi, listApartmentV2Api} from '@/api'
import { Import } from '@/components'
import {setApartments} from "@/redux/features/apartments";
import {useDispatch} from "react-redux";

const ImportApartment = ({ visible, setVisible, setParams }) => {
    const dispatch = useDispatch()
    const handleSuccess = async () => {
        setParams(pre => ({ ...pre, render: !pre.render }))
        setVisible(false)
        const getApartments = await listApartmentV2Api()
        if (getApartments.data && getApartments.data.status) {
            const apartments = getApartments.data.data
            dispatch(setApartments(apartments))
        }
    }

    return (
        <Import
            title="căn hộ"
            visible={visible}
            setVisible={setVisible}
            action={importApartmentApi}
            template="https://devapibdc.dxmb.vn/import/import_apartments.xlsx"
            handleSuccess={handleSuccess}
        />
    )
}

export default ImportApartment
