import { getData, postData } from '@/lib/request'

export const listCardApi = (params) => getData('web2/resident_card_management/getListResidentCard', params)


export const countCardApi = (params) => getData('web2/resident_card_management/countResidentCard', params)
export const detailCardApi = (params) => getData('web2/resident_card_management/detailResidentCard', params)
export const deleteCardApi = (params) => postData('web2/resident_card_management/deleteResidentCard', params)
export const addCardApi = (params) => postData('web2/resident_card_management/addResidentCard', params)
export const updateCardApi = (params) => postData('web2/resident_card_management/updateResidentCard', params)
export const checkCardApartmentApi = (params) => getData('web2/resident_card_management/checkCardApartment', params)


export const lisTypeVehicleApi = (params) => getData('web2/card_management/listTypeVehicle', params)
export const listUserCreate = (params) => getData('web2/building_handbook/getListNameCate', params)

