import {
    Body, Columnz, HeaderListForm, InputForm, StatusBody,
    TimeBody,
} from '@/components'
import ShowFile from '@/components/ShowFile'
import { listToast } from '@/constants'
import { getData } from '@/lib/request'
import { setToast } from '@/redux/features'
import { Button, Tree } from '@/uiCore'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTablezV2 } from '../../../../components/data_table/DataTablez'
import { addManagementDocsApi, deleteManagementDocsApi, updateManagementDocsApi } from '../api'
import { useListManagementDocs } from '../utils'

export default function ManagementDocs() {
    const initParams = {
        render: false,
    }
    const [params, setParams] = useState(initParams)
    const data = useListManagementDocs({ status: undefined, ...params, first: undefined, from_type: 0 })
    const [file, setFile] = useState(null)
    const [infos, setInfos] = useState({ status: null })
    const [position, setPosition] = useState({ left: 0, top: 0 })
    const [isOpen, setIsOpen] = useState([])
    const [isMenu, setIsMenu] = useState([])
    const menuRef = useRef(null)
    const openRef = useRef(null)
    const iRef = useRef(null)
    const [idMove, setIdMove] = useState(null)
    const [moveNode, setMoveNode] = useState({})
    const dispatch = useDispatch()
    const [renderNode, setRenderNode] = useState([])
    const [selectedKeyFrom, setSelectedKeyFrom] = useState('')
    const [selectedKeyTo, setSelectedKeyTo] = useState('')
    const [loadingDownload, setLoadingDownload] = useState({})
    const [isLoadingDownload, setIsLoadingDownload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [parentId, setParentId] = useState(null)
    const [initPositonY, setInitPositonY] = useState(null)
    const [expandedKeys, setExpandedKeys] = useState({})
    const users = useSelector(state => state.users)
    const fileInputRef = useRef(null)
    const [inputKey, setInputKey] = useState(0)

    const handleClick = (node, e) => {
        const rect = e.currentTarget.getBoundingClientRect()
        setIsOpen([node.id])
        setPosition({ x: rect.left, y: rect.top + initPositonY || 0 })
    }

    useEffect(() => {
        const handleScroll = () => {
            if (isOpen && isOpen.length > 0) setIsOpen([])
            const scrollTop = window.scrollY || document.documentElement.scrollTop
            if (scrollTop) setInitPositonY(scrollTop)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isOpen])

    const handleClickOutside = (e) => {
        if (openRef.current && !openRef.current.contains(e.target)) {
            setIsOpen([])
        }
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setIsMenu([])
            setInfos({})
            setFile(null)
            setMoveNode({})
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const formatTree = (data, parentId = 0, parentKey = '', index = 0) => {
        let tree = []
        for (let i = 0; i < data.length; i++) {
            if (data[i].parent_id === parentId && data[i].from_type === 0) {
                let key = parentKey ? `${parentKey}-${index}` : `${data[i].id}`
                let children = formatTree(data, data[i].id, key)
                let node = {
                    id: data[i].id,
                    parent_id: data[i].parent_id,
                    key: key,
                    type: data[i].type,
                    label: data[i].name,
                    icon: data[i].type === 0 ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
                    level: data[i].level,
                    file: data[i].file && data[i].file,
                    from_type: data[i].from_type,
                    created_at: data[i].created_at,
                    user_id: data[i].user_id,
                    status: data[i].status,


                }
                if (children.length) {
                    node.children = children
                }
                tree.push(node)
                index++
            }
        }
        return tree
    }

    const handleAdd = async (node) => {
        const id = node && node.id
        const type = isMenu[0].split('___')[0]
        const level = id && data.find(d => d.id === Number(id))

        const req = {
            name: infos.name,
            type: type === 'folder' ? 0 : 1,
            parent_id: Number(id),
            level: level && level.level + 1,
            from_type: 0,
            from_id: 0,
            document_type: 0,
        }
        if (infos.name.length > 50) return dispatch(setToast({
            ...listToast[1],
            detail: ` ${isMenu[0] === 'root' || type === 'folder' ? 'thư mục' : 'file'} không được quá 50 ký tự`,
        }))
        if (!infos.name) return dispatch(setToast({
            ...listToast[1],
            detail: `Vui lòng nhập tên ${isMenu[0] === 'root' || type === 'folder' ? 'thư mục' : 'file'}!`,
        }))
        if (type === 'file') {
            if (!file) return dispatch(setToast({ ...listToast[1], detail: `Vui lòng chọn file!` }))
            req.file = file
        }
        if (isMenu[0] === 'root') {
            req.type = 0
            req.parent_id = 0
            req.level = 0
        }
        setLoading(true)
        const res = await addManagementDocsApi(req)
        setLoading(false)
        if (res.data.status) {
            dispatch(setToast({
                ...listToast[0],
                detail: `Thêm ${type === 'folder' ? 'thư mục thành công' : 'thư mục thành công'} `,
            }))
        } else {
            dispatch(setToast({ ...listToast[1], detail: res.data.mess }))
        }
        setParams(prev => ({ ...prev, render: !prev.render }))
        setIsMenu([])
        setInfos({})
        setFile(null)
    }

    const handleDelete = (node) => {
        const id = node.id
        setIsOpen([])
        confirmDialog({
            message: `Xác nhận xóa ${node.type === 0 ? `thư mục này ${Array.isArray(node.children) ? 'sẽ mất hết các thư mục bên trong' : ''}` : 'file này'}  `,
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept: async () => {
                const res = await deleteManagementDocsApi({ id: id })
                if (res.data.status) {
                    dispatch(setToast({
                        ...listToast[0],
                        detail: `Xóa ${node.type === 0 ? 'thư mục' : 'file'} thành công`,
                    }))
                }
                setParams(prev => ({ ...prev, render: !prev.render }))
                setIsOpen([])
                setRenderNode(prev => flattenData(prev).filter(d => d.id !== id))
            },
        })
    }

    const handleUpdate = async (node) => {
        const id = node.id
        const req = {
            id: id,
        }
        if (infos.name !== node.label) {
            req.name = infos.name
        }

        if (Object.keys(moveNode) === 0) return dispatch(setToast({
            ...listToast[1],
            detail: `Vui lòng chọn nơi chuyển đến`,
        }))
        if (!infos.name) return dispatch(setToast({
            ...listToast[1],
            detail: `Không được để trống tên ${node.type === 0 ? 'thư mục' : 'file'}`,
        }))
        if (isMenu[0] === 'move') {
            req.id = idMove
            req.parent_id = moveNode.id
            req.level = moveNode.level + 1
            delete req.name
        }
        setLoading(true)
        const res = await updateManagementDocsApi(req)
        setLoading(false)
        if (res.data.status) {
            dispatch(setToast({ ...listToast[0], detail: `Cập nhật thành công` }))
        } else {
            dispatch(setToast({ ...listToast[1], detail: res.data.mess }))
        }
        setParams(prev => ({ ...prev, render: !prev.render }))
        setMoveNode({})
        setIsMenu([])
        setInfos({ ...infos, name: '' })
    }
    const updateStatus = async (node) => {
        setIsOpen([])
        const id = node.id
        const params = {
            id: id,
            status: node.status === 1 ? 0 : 1,

        }
        const res = await updateManagementDocsApi(params)
        if (res.data.status) {
            dispatch(setToast({ ...listToast[0], detail: `Cập nhật thành công` }))
        } else {
            dispatch(setToast({ ...listToast[1], detail: res.data.mess }))
        }
        setParams(prev => ({ ...prev, render: !prev.render }))
    }
    useEffect(() => {
        if (file && isMenu[0].split('___')[0] === 'file') setInfos({
            ...infos,
            name: file.name.substring(0, file.name.lastIndexOf('.')),
        })
        else if (!infos.name) setInfos({})
    }, [file])

    const nodeTemplate = (node) => {
        return (
            <div key={node.id} className="w-10">
                <div onClick={() => setRenderNode([node])}
                     className={`${!node.status ? ' opacity-60' : ''} flex justify-content-between align-items-center min-h-12rem py-2 text-lg font-semibold `}>
                    <div className=""
                         style={{ width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {node.label}
                    </div>
                    <i ref={iRef} className=" pi pi-ellipsis-h pr-2" onClick={(e) => {
                        handleClick(node, e)
                    }}></i>
                </div>
                {
                    (isOpen && isOpen.includes(node.id)) && (
                        <div ref={openRef} className="card bg-white ml-5 shadow-8 w-13rem" style={{
                            position: 'absolute',
                            left: `${position.x}px`,
                            top: `${position.y - 10}px`,
                            zIndex: 9999,
                        }}>
                            <ul className="list-none p-1">
                                {node.type === 0 && (
                                    <>
                                        <li className="cursor-pointer py-2 hover:surface-200" onClick={e => {
                                            setIsOpen([])
                                            setIsMenu(['folder___' + node.id])
                                        }}
                                        >Thêm thư mục
                                        </li>
                                        <li className="cursor-pointer py-2 hover:surface-200 w-full" onClick={e => {
                                            setIsMenu([])
                                            setIsOpen([])
                                            setInfos({})
                                            setParentId(node.id)
                                            fileInputRef.current.click()
                                        }}>
                                            Thêm file
                                        </li>
                                    </>
                                )}
                                <li className="cursor-pointer py-2 hover:surface-200" onClick={() => {
                                    setIsMenu(['update___' + node.id])
                                    setIsOpen([])
                                    setInfos({ name: node.label })
                                    setFile(replaceFile(node.file))
                                }}>Sửa
                                </li>
                                <li className="cursor-pointer py-2 hover:surface-200" onClick={() => handleDelete(node)}
                                >Xóa
                                </li>
                                <li className="cursor-pointer py-2 hover:surface-200" onClick={() => {
                                    setIsMenu(['move'])
                                    setIsOpen([])
                                    setIdMove(node.id)
                                }}
                                >Di chuyển đến
                                </li>
                                <li className="cursor-pointer py-2 hover:surface-200" onClick={() => updateStatus(node)}>
                                    {node.status ? 'Ẩn' : 'Hiển thị'}
                                </li>
                            </ul>
                        </div>
                    )
                }
                {
                    (
                        isMenu && (isMenu.includes('folder___' + node.id) || isMenu.includes('file___' + node.id))) && (
                        <div ref={menuRef} className="bg-white ml-5 shadow-3 " style={{
                            position: 'absolute',
                            left: `${position.x}px`,
                            top: `${position.y}px`,
                            zIndex: 9999,
                        }}>
                            <div className="p-2 mt-2">
                                <InputForm
                                    label={isMenu[0].split('___')[0] === 'folder' ? 'Tên thư mục' : 'Tên file'}
                                    value={infos.name}
                                    onChange={e => setInfos({ ...infos, name: e.target.value })}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            handleAdd(node)
                                        }
                                    }}
                                />

                                <div className="flex justify-content-between px-2">
                                    <Button label="Hủy" severity="danger" onClick={() => {
                                        setIsMenu([])
                                        setInfos({})
                                        setFile(null)
                                    }} />
                                    <Button label="Thêm" loading={loading} onClick={e => handleAdd(node)} />
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    (isMenu && (isMenu.includes('update___' + node.id))) && (
                        <div ref={menuRef} className="bg-white ml-5 shadow-3" style={{
                            position: 'absolute',
                            left: `${position.x}px`,
                            top: `${position.y}px`,
                            zIndex: 9999,
                        }}>
                            <div className="p-2 mt-2">
                                <InputForm label={node.type ? 'Cập nhật file' : 'Cập nhật thư mục'} value={infos.name}
                                           onChange={e => setInfos({ ...infos, name: e.target.value })} />
                                <div className="flex justify-content-between px-2">
                                    <Button label="Hủy" severity="danger" onClick={() => {
                                        setIsMenu([])
                                        setInfos({})
                                        setFile(null)
                                    }} />
                                    <Button label="Xác nhận" onClick={e => handleUpdate(node)} />
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        )
    }
    const nodeTemplateMove = (node) => {
        return (
            <div onClick={() => setMoveNode(node)}
                 className="flex justify-content-between align-items-center min-h-12rem py-2 text-lg font-semibold w-full">
                <span className="">{node.label}</span>
            </div>
        )
    }

    const handleAddRoot = (e) => {
        const rect = e.currentTarget.getBoundingClientRect()
        setPosition({ x: rect.left + 150, y: rect.top })
        setIsMenu(['root'])
    }

    const footer = (node) => {
        return (
            <div className="flex justify-content-between mt-3">
                <Button severity="danger" label="Hủy" onClick={() => {
                    setIsMenu([])
                    setMoveNode({})
                }} />
                <Button label="Chuyển đến" onClick={() => handleUpdate(node)} />
            </div>
        )
    }
    const flattenData = (data) => {
        let result = []
        data.forEach(item => {
            const { children, ...rest } = item
            if (item.type === 1) {
                result.push(rest)
            }
            if (Array.isArray(children)) {
                result = [...result, ...flattenData(children)]
            }
        })
        return result
    }

    const replaceFile = (data) => {
        if (data) return data.replace(/"/g, '')
    }

    const token = localStorage.getItem('token')

    const handleDownloadImg = async (link, label) => {
        if (link) {
            setIsLoadingDownload(true)
            setLoadingDownload(prevState => ({...prevState, [label]: true}))
            fetch(
                `${link}?token=${token}`,
            )
                .then((response) => response.blob())
                .then((blob) => {
                    const blobUrl = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    document.body.appendChild(a)
                    a.href = blobUrl
                    a.download = label
                    a.click()
                    window.URL.revokeObjectURL(blobUrl)
                    setLoadingDownload(prevState => ({...prevState, [label]: false}))
                    setIsLoadingDownload(false)
                })

        }
        return { data: { status: true } }
    }

    console.log(loadingDownload)
    const handleFile = async (e) => {
        let file = e.target.files
        const imagesArray = []
        for (let i = 0; i < file.length; i++) {
            file[i].preview = URL.createObjectURL(file[i])
            imagesArray.push(file[i])
        }

        if (imagesArray && imagesArray[0]) {
            const level = parentId && data.find(d => d.id === Number(parentId))
            let params = {
                type: 1,
                parent_id: Number(parentId),
                level: level && level.level + 1,
                from_type: 0,
                from_id: 0,
                document_type: 0,
            }
            params.files = imagesArray
            setLoading(true)
            const res = await addManagementDocsApi(params)
            setLoading(false)

            if (res.data?.status) {
                dispatch(setToast({ ...listToast[0], detail: `Thêm File thành công` }))
            } else {
                dispatch(setToast({ ...listToast[1], detail: 'File đã tồn tại trong thư mục!' }))
            }
            setParams(prev => ({ ...prev, render: !prev.render }))
        }
        setParentId(null)
        setInputKey(prevKey => prevKey + 1)
    }
    return (
        <div className="card">
            <input key={inputKey} ref={fileInputRef} type="file" onChange={handleFile} multiple className="hidden" />
            <HeaderListForm title="Thông tin tài liệu dự án" />
            <div className="flex justify-content-end pb-3 ">
                <Button
                    type="button"
                    raised
                    size="small"
                    severity="secondary"
                    label="Làm mới"
                    onClick={() => {
                        setRenderNode([])
                        setSelectedKeyFrom('')
                        setSelectedKeyTo('')
                        setExpandedKeys({})
                    }}
                />
            </div>
            <div className="card flex w-full gap-3 ">

                <div className={`${loading} ? "relative" : "" `} style={{ minWidth: '416px' }}>
                    {loading &&
                        <div
                            className="absolute h-full w-full bg-gray-900 z-1 opacity-10 flex justify-content-center align-items-center">
                            <i className="pi pi-spin pi-spinner absolute z-5"
                               style={{ fontSize: '3rem', color: 'white', fontWeight: 'bold' }}></i>
                        </div>
                    }
                    <div className="mb-3"><Button icon="pi pi-plus" label="Thêm thư mục"
                                                  onClick={e => handleAddRoot(e)} /></div>
                    <Tree
                        filter
                        filterPlaceholder="Nhập tên thư mục, file..."
                        selectionMode="single"
                        selectionKeys={selectedKeyFrom}
                        onSelectionChange={(e) => setSelectedKeyFrom(e.value)}
                        value={formatTree(data)}
                        nodeTemplate={e => nodeTemplate(e)}
                        className="p-3"
                        expandedKeys={expandedKeys}
                        onToggle={e => setExpandedKeys(e.value)}
                    />
                    {(isMenu && (isMenu.includes('move'))) && (
                        <div ref={menuRef} className="card bg-white ml-5 shadow-3" style={{
                            position: 'absolute',
                            left: `${position.x}px`,
                            top: `${position.y}px`,
                            zIndex: 9999,
                        }}>
                            <Tree selectionMode="single" selectionKeys={selectedKeyTo}
                                  onSelectionChange={(e) => setSelectedKeyTo(e.value)}
                                  value={formatTree(data.filter(d => d.id !== idMove && d.type === 0))}
                                  nodeTemplate={e => nodeTemplateMove(e)} footer={footer} className="w-full p-3" />
                        </div>
                    )}
                    {(isMenu && !loading && (isMenu.includes('root'))) && (
                        <div ref={menuRef} className="bg-white ml-5 shadow-3 " style={{
                            position: 'absolute',
                            left: `${position.x}px`,
                            top: `${position.y}px`,
                            zIndex: 9999,
                        }}>
                            <div className="p-2 mt-2">
                                <InputForm label="Thêm thư mục" value={infos.name}
                                           onChange={e => setInfos({ ...infos, name: e.target.value })} autoFocus
                                           onKeyPress={e => {
                                               if (e.key === 'Enter') {
                                                   handleAdd()
                                               }
                                           }} />
                                <div className="flex justify-content-between px-2">
                                    <Button label="Hủy" severity="danger" onClick={() => {
                                        setIsMenu([])
                                        setInfos({})
                                        setFile(null)
                                    }} />
                                    <Button label="Thêm" onClick={e => handleAdd()} />
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <div className="w-full" style={{ minWidth: '600px' }}>
                    <DataTablezV2
                        value={flattenData(renderNode)}
                        title="tài liệu"
                        totalRecords={flattenData(renderNode).length}
                        params={params}
                        route="/docs_management"
                    >
                        <Columnz body={e => <div className="cursor-pointer">
                            <ShowFile target="_blank" link={e.file} label={e.label} />
                        </div>}
                                 header="File tài liệu" />
                        <Columnz body={e => e.from_type === 0 ? 'Dự án' : e.from_type === 1 ? 'Căn hộ' : ''}
                                 header="Phân loại" />
                        <Columnz
                            body={e => Body({ data: users, value: e.user_id, label: 'full_name', key: 'user_id' }) ?
                                Body({ data: users, value: e.user_id, label: 'full_name', key: 'user_id' })
                                : 'Admin'} header="Người tạo" />
                        <Columnz body={(e) => TimeBody(e.created_at)} header="Ngày tạo"></Columnz>
                        <Columnz
                            header="Trạng Thái"
                            headerStyle={{ padding: 'auto', textAlign: 'center' }}
                            className="text-center"
                            body={(e) => StatusBody({
                                e,
                                route: '/docs_management',
                                updateAction: updateManagementDocsApi,
                            })}
                        />
                        <Columnz body={e => <Button disabled={isLoadingDownload} loading={loadingDownload[e.label]} onClick={() => handleDownloadImg(e.file, e.label)} type="download"
                                                    rounded outlined icon="pi pi-download" />} header="Download File" />
                    </DataTablezV2>
                </div>
            </div>
        </div>
    )
}
