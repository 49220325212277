import {
    Body,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    NumberFormat,
    TimeBody,
    useGetParams
} from '@/components'
import { reservationStatus } from '@/constants'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteBookingTimePlaceApi } from '../api'
import { useCountBooking, useDetailBooking, useListBookingTimePlace, useListUtilitiesApartmentApi } from '../utils'

const Header = ({ setParams, apartments, reservationStatus, listBooking }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm ..."
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.apartment_id}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                options={apartments}
                placeholder="Căn hộ"
            />
            <Dropdownz
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                options={reservationStatus}
                placeholder="Trạng thái"
            />
            <Dropdownz
                value={filter.booking_id}
                options={listBooking}
                onChange={(e) => setFilter({ ...filter, booking_id: e.target.value })}
                placeholder="Dịch vụ"
            />
        </GridForm>
    )
}

const ListReservations = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const totalRecords = useCountBooking({ status: undefined, ...params, first: undefined }) || 0
    const apartments = useSelector((state) => state.apartments)
    const residents = useSelector((state) => state.residents)
    const data = useListBookingTimePlace({ status: undefined, ...params, first: undefined })
    const listBooking = useListUtilitiesApartmentApi()

    const typePayment = (type) => {
        if (type === 1) return 'Tiền mặt'
        if (type === 2) return 'Chuyển khoản'
    }

    const InfoReservation = (info) => {
        const booking = useDetailBooking(info.booking_id)
        return (
            <>
                <div className="flex justify-content-center" style={{ backgroundColor: 'rgb(60, 141, 188)', height: '48px', lineHeight: '48px' }}>
                    <b style={{ color: 'white', fontSize: '18px' }}>{Body({ data: listBooking, value: info.booking_id })}</b>
                </div>
                <div className='grid grid-form px-2'>
                    <div className="col-12 lg:col-6 flex flex-column text-left gap-1" style={{ minHeight: '48px', borderBottom: '1px solid #d3d9de' }}>
                        <span>Mã đơn</span>
                        <strong>{info.receipt_code}</strong>
                    </div>
                    <div className="col-12 lg:col-6 flex flex-column text-left gap-1" style={{ minHeight: '48px', borderBottom: '1px solid #d3d9de' }}>
                        <span>Người đặt</span>
                        <strong>
                            {Body({ data: residents, value: info.user_id, label: 'full_name', key: 'user_id' })}
                        </strong>
                    </div>
                    <div className="col-12 lg:col-6 flex flex-column text-left gap-1" style={{ minHeight: '48px', borderBottom: '1px solid #d3d9de' }}>
                        <span>Phí đặt chỗ</span>
                        <strong><NumberFormat value={info.deposit_fee} /></strong>
                    </div>
                    <div className="col-12 lg:col-6 flex flex-column text-left gap-1" style={{ minHeight: '48px', borderBottom: '1px solid #d3d9de' }}>
                        <span>Phương thức thanh toán</span>
                        <strong>{typePayment(info.type_payment)}</strong>
                    </div>
                    <div className="col-12 lg:col-6 flex flex-column text-left gap-1" style={{ minHeight: '48px', borderBottom: '1px solid #d3d9de' }}>
                        <span>Vị trí đặt chỗ</span>
                        <strong>
                            {Body({
                                data: booking.location_config && booking.location_config,
                                value: info.booking_place_id,
                            })}
                        </strong>
                    </div>
                    <div className="col-12 lg:col-6 flex flex-column text-left gap-1" style={{ minHeight: '48px', borderBottom: '1px solid #d3d9de' }}>
                        <div>Ngày sử dụng</div>
                        <strong>{TimeBody(info.date_use, 'date')}</strong>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="card">
            <HeaderListForm title="Danh sách đặt chỗ" />
            <Header
                setParams={setParams}
                apartments={apartments}
                reservationStatus={reservationStatus}
                listBooking={listBooking}
            />
            <DataTablez
                title="chỗ đã đặt"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/booking_time_place"
                headerInfo
                actionsInfo={{ deleteAction: deleteBookingTimePlaceApi }}
                basePermissions={['insert', 'detail', 'delete']}
            >
                <Columnz field="booking_name" header="Dịch vụ đăng ký" />
                <Columnz
                    header="Thông tin đặt chỗ"
                    body={InfoReservation}
                    bodyStyle={{ minWidth: '25rem', padding: '8px' }}
                />
                <Columnz body={(e) => Body({ data: apartments, value: e.bdc_apartment_id })} header="Căn hộ" />
                <Columnz field="created_at" header="Ngày tạo" body={e => TimeBody(e.created_at, "date")} />
                <Columnz
                    field="deposit_request"
                    header="Số tiền yêu cầu"
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.deposit_request })}
                />
                <Columnz
                    field="deposit_fee_paid"
                    header="Thực thu"
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.deposit_fee_paid })}
                />
                <Columnz field="deposit_fee" header="Thực chi"
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.deposit_fee })} />
                <Columnz body={(e) => Body({ data: reservationStatus, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}

export default ListReservations
