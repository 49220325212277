import {
    Body,
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    TimeBody,
    useGetParams
} from '@/components'
import { statusLog, typeServiceNotification } from '@/constants'
import { useState } from 'react'
import { useCountHistory, useHistoryList } from '../utils'
import Detail from './Detail'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '', subject: '' })
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.subject}
                onChange={(e) => setFilter({ ...filter, subject: e.target.value })}
                placeholder="Tìm kiếm theo tiêu đề"
            />
            <Inputz
                placeholder="Tìm kiếm theo địa chỉ nhận gửi"
                value={filter.key_search}
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.type_service}
                options={typeServiceNotification}
                optionValue="id"
                onChange={(e) => setFilter({ ...filter, type_service: e.target.value })}
                placeholder="Kiểu dịch vụ"
            />
            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                placeholder="Chọn khoảng thời gian"
                className="lg:col-3"
            />
            <Dropdownz
                options={statusLog}
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}
export default function HistoryList() {
    const [visible, setVisible] = useState(false)
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useHistoryList({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountHistory({ status: undefined, ...params, first: undefined }) || 0

    const renderTypeServiceColumn = (rowData) => {
        const type = typeServiceNotification.find((item) => item.id === rowData.type_service)
        if (type) {
            return (
                <i
                    className={`pi ${type.id === 'email' ? 'pi-envelope' : ''} ${type.id === 'sms' ? 'pi-tablet' : ''}`}
                />
            )
        }
        return null
    }

    return (
        <div className="card">
            {visible && <Detail visible={visible} setVisible={setVisible} setParams={setParams} data={data} />}
            <HeaderListForm title="Lịch sử thông báo" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="lịch sử thông báo"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/log"
                actionsInfo
                setVisibledDialog={setVisible}
                basePermissions={["detail"]}
            >
                <Columnz field="subject" header="Tiêu đề" />
                <Columnz field="from" header="Địa chỉ gửi" />
                <Columnz field="to" header="Địa chỉ nhận" />
                <Columnz field="created_at" header="Thời gian gửi" body={e => TimeBody(e.created_at)} />
                <Columnz field="type_service" header="Kiểu dịch vụ" body={renderTypeServiceColumn} bodyStyle={{ textAlign: "center" }} headerStyle={{ textAlign: "center" }} />
                <Columnz body={(e) => Body({ data: statusLog, value: e.status })} header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center' />
            </DataTablez>
        </div>
    )
}
