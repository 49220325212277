import { useEffect, useState } from 'react'
import { countResidentOpinionsApi, ListCreatorApi, listResidentOpinionsApi } from '../api'

export const useListResidentOpinions = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listResidentOpinionsApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useListCreator = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await ListCreatorApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountResidentOpinions = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countResidentOpinionsApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}
