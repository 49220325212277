import {
    Calendarz,
    Columnz,
    DataTablez,
    Dropdownz,
    GridForm,
    HeaderListForm,
    TimeBody,
    useGetParams,
} from '@/components'
import { status } from '@/constants'
import { useState } from 'react'
import { useCountCompany, useListCompany } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Calendarz
                value={filter.status}
                className="lg:col-6"
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                showIcon
                placeholder="Thời gian"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Trạng thái"
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Phân loại"
            />
        </GridForm>
    )
}

export default function ParkingDetails() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCompany({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCompany({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Chi tiết xe ra vào" />
            <Header setParams={setParams} />
            <DataTablez value={data} title="bãi xe" totalRecords={totalRecords} params={params} setParams={setParams}>
                <Columnz field="name" header="Phân loại" />
                <Columnz header="Thời gian" body={(e) => TimeBody(e.created_at)} />
                <Columnz field="image" header="Hình ảnh" />
                <Columnz field="color" header="màu sắc" />
                <Columnz field="type" header="Loại xe" />
                <Columnz field="numberPlate" header="Biển kiểm soát" />
                <Columnz field="cardNumber" header="Mã thẻ" />
                <Columnz field="toMoney" header="Thành tiền" />
                <Columnz field="cardType" header="Loại thẻ" />
            </DataTablez>
        </div>
    )
}
