import { TabPanel, TabView } from 'primereact/tabview'
import UpdateApartLog from '@/modules/resident_apartments/apartment_management/UpdateApartLog'
import UpdateApartServiceUsage from '@/modules/resident_apartments/apartment_management/UpdateApartServiceUsage'
import UpdateApartDocs from '@/modules/resident_apartments/apartment_management/UpdateApartDocs'
import { useParams } from 'react-router-dom'
import UpdateApartment from '@/modules/resident_apartments/apartment_management/UpdateApartment'
import { useState } from 'react'

export const DetailApartment = () => {
    const { id } = useParams()
    return (
        <TabView>
            <TabPanel header="Thông tin chung">
                <UpdateApartment id={Number(id)}  />
            </TabPanel>
            <TabPanel header="Nhật kí căn hộ">
                <UpdateApartLog id={Number(id)}  />
            </TabPanel>
            <TabPanel header="Dịch vụ sử dụng">
                <UpdateApartServiceUsage apartment_id={Number(id)} />
            </TabPanel>
            <TabPanel header="Tài liệu căn hộ">
                <UpdateApartDocs apartment_id={Number(id)} />
            </TabPanel>
        </TabView>
    )
}

export default DetailApartment