import React, { useState } from 'react'
import {
    Columnz,
    DataTablez,
    useGetParams,
    HeaderListForm,
    Body,
} from '@/components'
import {
    useCountManagementDocs,
    useListDocsGroup,
    useListManagementDocs,
} from '@/modules/buildings/docs_management/utils'
import { deleteManagementDocsApi } from '@/modules/buildings/docs_management/api'
import { useSelector } from 'react-redux'
import ShowFile from '@/components/ShowFile'
import UpdateApartmentDocs from '@/modules/buildings/docs_management/screens/UpdateApartmentDocs'

export default function UpdateApartDocs(props) {
    const { apartment_id } = props
    const documentClassification = useListDocsGroup({ status: undefined, first: undefined })
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListManagementDocs({ status: undefined, ...params, first: undefined, from_type: 1, apartment_id })

    const totalRecords = useCountManagementDocs({
        status: undefined, ...params,
        from_type: 1,
        first: undefined,
        apartment_id,
    }) || 0
    const [visible, setVisible] = useState(false)
    const buildings = useSelector(state => state.buildings)
    const apartments = useSelector(state => state.apartments)
    const users = useSelector(state => state.users)

    return (
        <div className="card">
            <HeaderListForm title="Tài liệu căn hộ" />
            {
                visible && <UpdateApartmentDocs visible={visible} setVisible={setVisible} setParams={setParams}
                                                buildings={buildings} apartments={apartments}
                                                apartment_id={apartment_id}
                />
            }
            <DataTablez
                value={data}
                title="Tài liệu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/apartment_management"
                headerInfo={{}}
                setVisibledDialog={setVisible}
                actionsInfo={{ deleteAction: deleteManagementDocsApi }}
                basePermissions={['insert', 'detail', 'delete']}
                hideParams
            >
                <Columnz body={e => Body({ data: apartments, value: e.from_id })} header="Căn hộ" />
                <Columnz body={e => {
                    const building_place_id = apartments && apartments.find(a => a.id === e.from_id)
                    return Body({ data: buildings, value: building_place_id?.building_place_id })
                }} header="Tòa nhà" />
                <Columnz body={e => <div className="cursor-pointer">
                    <ShowFile target="_blank" link={e.file} label={e.name} />
                </div>}
                         header="File tài liệu" />
                <Columnz body={e => Body({ data: documentClassification, value: Number(e.document_type) })}
                         header="Phân loại" />
                <Columnz body={(e) => {
                    const user = users.find(u => u.user_id === e.user_id)
                    return user?.full_name ? user?.full_name : 'Admin'
                }}
                         header="Người tạo" />
                <Columnz field="department_store" header="Bộ phận lưu trữ tài liệu gốc" />
                <Columnz field="department_backup" header="Bộ phận lưu trữ tài sao lưu" />
            </DataTablez>
        </div>
    )
}
