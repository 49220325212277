import { Calendarz, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, useGetParams } from '@/components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useCountCompany, useListCompany } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-12">
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />

            <Calendarz
                value={filter.dates}
                onChange={(e) => setFilter({ ...filter, dates: e.value })}
                className="lg:col-6"
                placeholder="Khoảng thời gian ngày hạch toán"
            />
        </GridForm>
    )
}
function MarginReport() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListCompany({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountCompany({ status: undefined, ...params, first: undefined }) || 0
    return (
        <div className="card">
            <HeaderListForm title="Sổ ký quỹ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                route="/deposit_list"
                title="Sổ ký quỹ"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
            >
                <Columnz field="" header="Căn hộ" />
                <Columnz field="" header="Đã thu" />
                <Columnz field="" header="Đã chi" />
                <Columnz field="" header="Còn nợ" />
                <Columnz field="" header="Mã SP" />
            </DataTablez>
        </div>
    )
}

export default MarginReport
