import { getData, postData } from '@/lib/request'

//api building
export const listBuildingServiceApi = (params) => getData('web2/services/getListService', params)
export const listServiceV2Api = (params) => getData('web2/info/getListServiceV2', params)
export const countBuildingServiceApi = (params) => getData('web2/services/countService', params)
export const detailBuildingServiceApi = (params) => getData('web2/services/getDetailService', params)
export const addBuildingServiceApi = (params) => postData('web2/services/addService', params)
export const updateBuildingServiceApi = (params) => postData('web2/services/updateService', params)
export const updateStatusBuildingServiceApi = (params) => postData('web2/services/updateStatusService', params)
export const updateSortServiceSortToPaymentApi = (params) =>
    postData('web2/services/updateSortServiceSortToPayment', params)
export const listProgressives = (params) => getData('web2/info/getListProgressives', params)

//danh sách loại phương tiện
export const listVehicleCategories = (params) => getData('web2/services/getListBdcVehiclesCategory', params)
export const countVehiclCategoryApi = (params) => getData('web2/services/countVehicleCategory', params)
export const addVehicleCategoryApiApi = (params) => postData('web2/vehicle_category/addVehicleCategoryV2', params)
export const updateStatusVehiclCategoryApi = (params) => postData('web2/services/updateStatusVehicleCategory', params)

//api price
export const listPriceApi = (params) => getData('web2/list_price/getListProgressives', params)
export const countPriceApi = (params) => getData('web2/list_price/countProgressives', params)
export const deletePriceApi = (params) => postData('web2/list_price/delProgressives', params)
export const detailPriceApi = (params) => getData('web2/list_price/getDetailProgressives', params)
export const addPriceApi = (params) => postData('web2/list_price/addProgressives', params)
export const updatePriceApi = (params) => postData('web2/list_price/updateProgressives', params)
export const importService = (params) => postData('web2/services/importService', params, false, true)
export const exportServiceApi = (params) => getData('web2/services/expListService', params, true)
