import { Body, Columnz, DataTablez, HeaderListForm, NumberFormat, useGetParams } from '@/components'
import { type_payment } from '@/constants'
import { useState } from 'react'
import { deletePaymentMethodApi } from '../api'
import { useCountPaymentMethod, useListPaymentMethod } from '../utils'
import UpdatePaymentMethod from './UpdatePaymentMethod'

export default function PaymentMethod() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListPaymentMethod({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountPaymentMethod({ status: undefined, ...params, first: undefined }) || 0
    const [visible, setVisible] = useState(false)

    return (
        <>
            {visible && <UpdatePaymentMethod setParams={setParams} visible={visible} setVisible={setVisible} data={data} />}
            <HeaderListForm title="Phương thức thanh toán" />
            <DataTablez
                title="Phương thức"
                value={data}
                totalRecords={totalRecords}
                route="/building_overview"
                params={params}
                setParams={setParams}
                headerInfo
                actionsInfo={{ deleteAction: deletePaymentMethodApi }}
                basePermissions={["insert", "detail"]}
                setVisibledDialog={setVisible}
            >
                <Columnz header="Phương thức" body={(e) => Body({ data: type_payment, value: e.type_payment })} />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.fixed_charge })} header="Phí cố định/giao dịch(VND)" />
                <Columnz
                    className='text-right'
                    body={(e) => NumberFormat({ value: e.fee_percentage })} header="Phí giao dịch(%) " />
            </DataTablez>
        </>
    )
}
