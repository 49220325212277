import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import NumberOfVehicles from './NumberOfVehicles'
import VehicleRevenueReport from './VehicleRevenueReport'

function VehicleReport() {
    return (
        <div className="card">
            <TabView>
                <TabPanel header="Báo cáo số lượng phương tiện">
                    <NumberOfVehicles />
                </TabPanel>
                <TabPanel header="Báo cáo doanh thu phương tiên">
                    <VehicleRevenueReport />
                </TabPanel>
            </TabView>
        </div>
    )
}

export default VehicleReport
