import { useEffect, useState } from 'react'
import {
    listCompanyApi,
    countCompanyApi,
    detailCompanyApi,
    listReportApi,
    countReportApi,
    detailsReportApi,
} from '../api'

export const useListReport = (params) => {
    const [data, setData] = useState([])
    async function fetchData() {
        const response = await listReportApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useCountReport = (params) => {
    const [data, setData] = useState(0)
    async function fetchData() {
        const response = await countReportApi({ status: 1, ...params })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        fetchData()
    }, [JSON.stringify(params)])
    return data
}

export const useDetailReport = (id) => {
    const [data, setData] = useState({})
    async function fetchData() {
        const response = await detailsReportApi({ id })
        if (response.data.status) setData(response.data.data)
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    return data
}
