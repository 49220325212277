import { Body, HeaderListForm, UploadMultiImage } from '@/components'
import { listToast, statusOpinion } from '@/constants'
import { setToast } from '@/redux/features'
import { Button } from '@/uiCore'
import { checkJson } from '@/utils'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Comments } from '../../service_request/screen/Comment'
import { addCommentApi, deleteCommentApi, switchStatusFeedbackApi, switchStatusRequestApi } from '../api'
import { useDetailRegisterRepair } from '../utils'

const ResponseInfo = ({ infos, id, setRender, title }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const attached = infos.attached ? checkJson(infos.attached) || infos.attached : {}
    const files = attached.files
    const images = attached.images


    useEffect(() => {
        if (infos && infos.data) {
            const data = infos.data && JSON.parse(infos.data)
            setData({ ...data })
        }
    }, [infos])

    const currentDate = new Date()
    const createdAt = new Date(infos.created_at)
    let timeDiff = Math.floor((currentDate - createdAt) / 60000)
    if (timeDiff > 60 * 24 * 30 * 12) timeDiff = Math.floor(timeDiff / (60 * 24 * 30 * 12)) + ' năm trước'
    else if (timeDiff > 60 * 24 * 30) timeDiff = Math.floor(timeDiff / (60 * 24 * 30)) + ' tháng trước'
    else if (timeDiff > 60 * 24) timeDiff = Math.floor(timeDiff / (60 * 24)) + ' ngày trước'
    else if (timeDiff > 60) timeDiff = Math.floor(timeDiff / 60) + ' giờ trước'
    else timeDiff = (timeDiff < 0 ? 0 : timeDiff) + ' phút trước'

    const fetchData = async (status, mess) => {
        const response = await switchStatusFeedbackApi({ id, status: status })
        if (response.data && response.data.status) {
            setRender((e) => !e)
            dispatch(setToast({ ...listToast[0], detail: mess }))
        } else dispatch(setToast({ ...listToast[1], detail: response.data.mess }))
    }

    return (
        <div className="card">
            <HeaderListForm title={title} />
            <div className="card">
                <div className="grid grid-form">
                    <div className="col-4 lg:col-3">
                        <p className="font-semibold">Trạng thái:</p>
                    </div>
                    <div className="col-8 lg:col-9">
                        <Body data={statusOpinion} value={infos.status} />
                    </div>
                </div>
                <div className="grid grid-form">
                    <div className="col-4 lg:col-3">
                        <p className="font-semibold">Tiêu đề:</p>
                    </div>
                    <div className="col-8 lg:col-9">
                        <p className="font-semibold">{infos.title}</p>
                    </div>
                </div>
                <div className="grid grid-form">
                    <div className="col-4 lg:col-3">
                        <p className="font-semibold">Nội dung:</p>
                    </div>
                    <div className="col-8 lg:col-9">
                        <p className="font-semibold">{infos.content}</p>
                    </div>
                </div>
            </div>
            {files && files[0] && (
                <div className="mb-4">
                    <HeaderListForm title="File đính kèm" />
                    {files.map((f) => {
                        return f && (
                            f.endsWith('.mp4') || f.endsWith('MOV')
                                ?
                                <div style={{ height: '300px', overflow: 'hidden' }}>
                                    <ReactPlayer url={f} controls width="100%" height="100%" />
                                </div>
                                :
                                <a target="_blank" href={f}>
                                    {f}
                                </a>
                        )
                    })}
                </div>
            )}
            {images && images[0] && (
                <div className="mb-4">
                    <HeaderListForm title="Ảnh đính kèm" />
                    <UploadMultiImage view images={images} />
                </div>
            )}
            <div className="flex align-items-center justify-content-between">
                <span>
                    <span className="font-semibold">{infos.full_name || 'Không rõ '}</span> || {timeDiff}
                </span>
                {(infos.status !== 0 && infos.status !== 1) && (
                    <Button raised size="small" severity="info" label="Hoàn thành" onClick={() => fetchData(1, 'Đổi trạng thái thành công')} />
                )}

                {(infos.status == 0) && (
                    <Button raised size="small" severity="info" label="Tiếp nhận" onClick={() => fetchData(3, 'Tiếp nhận thành công')} />
                )}

            </div>
        </div>
    )
}

const Response = ({ title }) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [render, setRender] = useState(false)
    const registerRepairDetail = useDetailRegisterRepair(id, render)
    const [infos, setInfos] = useState({})
    const [comments, setComments] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (registerRepairDetail.detail) setInfos({ ...infos, ...registerRepairDetail.detail })
        if (registerRepairDetail.comment && registerRepairDetail.comment[0])
            setComments([...registerRepairDetail.comment])
    }, [registerRepairDetail])

    const SubTitle = () => {
        return (
            <Button type="button" raised size="small" severity="danger" label="Trở về" onClick={() => navigate(-1)} />
        )
    }

    const handleAddComment = (params) => {
        return { ...params, post_id: Number(id) }
    }

    return (
        <div className="card">
            <HeaderListForm title="Ý kiến phản hồi" SubTitle={SubTitle} />
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    {infos && <ResponseInfo title={title} setRender={setRender} id={Number(id)} infos={infos} />}
                </div>
                {infos.status !== 0 && <div className="col-12 lg:col-6">
                    <Comments
                        switchStatusApi={switchStatusRequestApi}
                        addApi={addCommentApi}
                        deleteApi={deleteCommentApi}
                        setRender={setRender}
                        handleAddComment={handleAddComment}
                        comments={comments}
                    />
                </div>}

            </div>
        </div>
    )
}

export default Response
