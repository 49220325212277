import { DropdownForm, FormUpdateDialog, InputForm } from '@/components'
import React, { useEffect, useState } from 'react'
import { removePropObject } from '@/utils'
import { useGetApi } from '@/hooks'
import { addFloorApi, detailFloorV2Api, updateFloorApi } from '@/api'

const UpdateFloor = (props) => {
    const { visible, setVisible, setParams, buildings } = props
    const detail = useGetApi(detailFloorV2Api, { id: typeof visible === 'number' ? visible : undefined }, {})
    const [infos, setInfos] = useState({
        name: '',
        place_id: '',
    })

    useEffect(() => {
        if (detail) setInfos({ ...infos, ...detail })
    }, [JSON.stringify(detail)])

    const handleData = () => {
        let info = { ...infos }
        if (typeof visible === 'number') info = { ...removePropObject(info, detail), id: visible }
        return info
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'tầng'}
            handleData={handleData}
            route="/building_management"
            actions={{ add: addFloorApi, update: updateFloorApi }}
        >
            <InputForm
                id="name"
                value={infos.name}
                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                label="Tên tầng"
                required
            />
            <DropdownForm
                id="place_id"
                value={infos.place_id}
                onChange={(e) => setInfos({ ...infos, place_id: e.target.value })}
                options={buildings}
                label="Chọn tòa nhà"
            />
        </FormUpdateDialog>
    )
}

export default UpdateFloor
