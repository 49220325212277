import {
    Body, Columnz,
    DataTablez,
    Dropdownz,
    GridForm, useGetParams
} from '@/components'
import { formatNumber } from '@/utils'
import { DataTable } from 'primereact/datatable'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import DetaiServicesMoney from '../../../debts/excess_money/screens/DetaiServicesMoney'
import { useCountExcessMoney } from '../../../debts/excess_money/utils'
import { useListDeposit } from '../utils'
export const listReceptType = [
    { id: "phieu_thu", name: 'Phiếu thu' },
    { id: "phieu_bao_co", name: 'Phiếu báo có' },
    { id: "phieu_thu_khac", name: 'Phiếu thu khác' },
    { id: "phieu_chi", name: 'Phiếu chi' },
    { id: "phieu_chi_khac", name: 'Phiếu chi khác' },
    { id: "phieu_ke_toan", name: 'Phiếu kế toán' },
    { id: "phieu_dieu_chinh", name: 'Phiếu điều chỉnh' },
]
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    const buildings = useSelector((state) => state.buildings)
    const services = useSelector((state) => state.services)

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-3">
            <Dropdownz
                value={filter.building_place_id}
                options={buildings}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.service_id}
                options={services}
                onChange={(e) => setFilter({ ...filter, service_id: e.target.value })}
                placeholder="Chọn dịch vụ"
            />
        </GridForm>
    )
}
function DepositManage() {
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState(initParam)
    const data = useListDeposit({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountExcessMoney({ status: undefined, ...params, first: undefined }) || 0
    const buildings = useSelector((state) => state.buildings)

    const services = useSelector((state) => state.services)
    const apartments = useSelector((state) => state.apartments)
    const groupedData = data.reduce((accumulator, currentValue) => {
        const key = currentValue.bdc_apartment_id;
        if (!accumulator[key]) {
            accumulator[key] = {
                bdc_apartment_id: currentValue.bdc_apartment_id,
                bdc_apartment_service_price_id: currentValue.bdc_apartment_service_price_id,
                bdc_building_id: currentValue.bdc_building_id,
                bdc_service_id: currentValue.bdc_service_id,
                data: [{ service_id: currentValue.bdc_service_id, coin: currentValue.coin }],
                totalCoin: currentValue.coin,
            };
        } else {
            accumulator[key].data.push({ service_id: currentValue.bdc_service_id, coin: currentValue.coin });
            accumulator[key].totalCoin += currentValue.coin;
        }
        return accumulator;
    }, {});
    const groupedDataArray = Object.values(groupedData);
    const treeTableTemplate = (rowData) => {
        const filteredData = rowData.data.filter(e => e.coin !== 0);
        const ServiceBody = (e) => {
            const service = services.find(s => s.id === e.service_id)
            if (service) {
                if (e.number) return <span>{service.name + "_" + e.number}</span>
                else return <span>{service.name}</span>
            } else return <span>{e.name}</span>
        }

        return (
            <DataTable value={filteredData}>
                <Columnz
                    body={ServiceBody}
                    header="Dịch vụ"
                ></Columnz>
                <Columnz body={(e) => formatNumber(e.coin)} header="Tiền thừa hiện tại" />
            </DataTable>
        );
    };
    return (
        <div className="card">
            {visible && (
                <DetaiServicesMoney data={data} setParams={setParams} visible={visible} setVisible={setVisible} />
            )}
            <Header setParams={setParams} />
            <DataTablez
                value={groupedDataArray}
                route="/deposit_list"
                title="ký quỹ"
                totalRecords={groupedDataArray.length}
                params={params}
                setParams={setParams}
            >
                <Columnz header="Căn hộ" body={(e) => Body({ data: apartments, value: e.bdc_apartment_id })} />
                <Columnz header="Mã căn hộ" body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'code' })} />
                <Columnz header="Khách hàng" body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'name_customer' })} />
                <Columnz body={e => formatNumber(e.totalCoin)} header="Tổng" />

                <Columnz body={treeTableTemplate}></Columnz>
            </DataTablez>
        </div>
    )
}


export default DepositManage
