import { DropdownForm, FormUpdateDialog, InputForm, InputSwitchForm } from '@/components'
import { removePropObject } from '@/utils'
import { useEffect, useState } from 'react'
import { addPaymentInfoApi, updatePaymentInfoApi } from '../api'
import { useDetailPaymentInfo, useListBankInfo } from '../utils'

const UpdatePaymentInfo = (props) => {
    const { visible, setVisible, setParams } = props
    const paymentInfoDetail = useDetailPaymentInfo(typeof visible === 'number' ? visible : undefined)
    const bank = useListBankInfo()

    const [infos, setInfos] = useState({
        bank_account: '',
        status_payment_info: true,
        active_payment: true
    })
    const newBank = bank.map(item => ({
        ...item,
        name: `${item.name} (${item.short_url})`,
    }));


    useEffect(() => {
        if (paymentInfoDetail && paymentInfoDetail.id) {
            const bankDetail = newBank.find((b) => b.name === paymentInfoDetail.bank_name) || {}
            setInfos({
                ...infos,
                ...paymentInfoDetail,
                active_payment: paymentInfoDetail.active_payment === 0 ? false : true,
                status_payment_info: paymentInfoDetail.status_payment_info === 0 ? false : true,
                status: paymentInfoDetail.status === 0 ? false : true,
                bank_id: bankDetail.id,
            })
        }
    }, [JSON.stringify(paymentInfoDetail), JSON.stringify(newBank)])

    const handleData = () => {
        let info = { ...infos }
        if (typeof visible === "number") info = { ...removePropObject(info, paymentInfoDetail), id: Number(visible) }
        const bankSelected = newBank.find((b) => b.id === infos.bank_id)
        info.bank_name = bankSelected.name
        info.short_url = bankSelected.short_url
        info.active_payment = infos.active_payment === true ? 1 : 0
        info.status_payment_info = infos.status_payment_info === true ? 1 : 0
        return info
    }

    return (
        <FormUpdateDialog
            checkId={typeof visible === "number"}
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + 'thông tin thanh toán'}
            actions={{ add: addPaymentInfoApi, update: updatePaymentInfoApi }}
        >
            <InputForm
                id="bank_account"
                value={infos.bank_account}
                onChange={(e) => setInfos({ ...infos, bank_account: e.target.value })}
                label="Số tài khoản (*)"
                required
            />
            <DropdownForm
                id="bank_id"
                value={infos.bank_id}
                onChange={(e) => setInfos({ ...infos, bank_id: e.target.value })}
                options={newBank}
                label="Ngân hàng (*)"
            />
            <InputForm
                id="branch"
                value={infos.branch}
                onChange={(e) => setInfos({ ...infos, branch: e.target.value })}
                label="Chi nhánh (*)"
                required
            />
            <InputForm
                id="holder_name"
                value={infos.holder_name}
                onChange={(e) => setInfos({ ...infos, holder_name: e.target.value })}
                label="Tên tài khoản (*)"
                required
            />
            <InputSwitchForm
                label="Nhận giao dịch tự động"
                id="active_payment"
                value={infos.active_payment}
                onChange={(e) => setInfos({ ...infos, active_payment: e.target.value })}
                checked={infos.active_payment}
            />
            <InputSwitchForm
                label="Tự động hạch toán"
                id="status_payment_info"
                value={infos.status_payment_info}
                onChange={(e) => setInfos({ ...infos, status_payment_info: e.target.value })}
                checked={infos.status_payment_info}
            />
        </FormUpdateDialog>
    )
}

export default UpdatePaymentInfo
