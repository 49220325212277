import { postData, getData } from '@/lib/request'
export const listUserApi = (params) => getData('web2/list_personnel/getListUser', params)
export const countUserApi = (params) => getData('web2/list_personnel/countUser', params)
export const detailUserApi = (params) => getData('web2/list_personnel/getDetailUserInfo', params)
export const deleteUserApi = (params) => postData('web2/list_personnel/deleteUser', params)
export const addUserApi = (params) => postData('web2/list_personnel/addUser', params, true)
export const addContractApi = (params) => postData('web2/list_personnel/addBillUser', params)

export const deleteContractApi = (params) => postData('web2/list_personnel/deleteBillUser', params)
export const updateUserApi = (params) => postData('web2/list_personnel/updateUser', params, true)
export const updateContractApi = (params) => postData('web2/list_personnel/updateBillUser', params)
export const listExchangeV2Api = (params) => getData('web2/info/getListDepartmentInfo', params)
export const listGroupV2Api = (params) => getData('web2/info/getListGroupInfo', params)

