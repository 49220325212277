import {
    Body,
    Columnz,
    DataTablez, Dialogz,
    Dropdownz,
    GridForm,
    HeaderListForm,
    Inputz,
    LoadDialog,
    StatusBody,
    useGetParams
} from '@/components'
import { } from '@/utils'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { countProjectApi, listProjectApi, updateProjectApi } from '@/api'
import { useGetApi } from '@/hooks'
import { status } from '@/constants'
import UpdateProject from './UpdateProject'
import UpdateProjectGeneral from './UpdateProjectGeneral'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-6">
            <Inputz
                value={filter.key_search}
                placeholder="Tìm kiếm theo tên, SDT, email dự án"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
            <Dropdownz
                value={filter.status}
                options={status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}

const ProjectGeneral = () => {
    const initParam = useGetParams()
    const [visible, setVisible] = useState(false)
    const [params, setParams] = useState(initParam)
    const data = useGetApi(listProjectApi, { ...params, first: undefined }, [])
    const totalRecords = useGetApi(countProjectApi, { ...params, first: undefined }, 0)
    const users = useSelector((state) => state.users)

    return (
        <div className="card">
            {visible && (
                <Dialogz title={visible ? 'Cập nhật' : 'Thêm mới'} visible={visible} setVisible={setVisible} width="1200px">
                    <UpdateProject visible={visible} setVisible={setVisible} setParams={setParams} />
                </Dialogz>
            )}
            <LoadDialog visible={visible} />
            <HeaderListForm title="Danh sách dự án" />
            <Header setParams={setParams} />
            <DataTablez
                title="dự án"
                value={data}
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/project_general"
                headerInfo
                actionsInfo
                basePermissions={["insert", "detail"]}
                setVisibledDialog={setVisible}
            >
                <Columnz field="name" header="Tên dự án" />
                <Columnz field="address" header="Địa chỉ" />
                <Columnz field="phone" header="Số điện thoại" />
                <Columnz field="description" header="Mô tả" />
                <Columnz
                    body={(e) => Body({ data: users, value: e.manager_id, key: 'user_id', label: 'full_name' })}
                    header="Trưởng ban quản lý"
                />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) => StatusBody({ e, route: '/project_general', updateAction: updateProjectApi })}
                />
            </DataTablez>
        </div>
    )
}

export default ProjectGeneral
