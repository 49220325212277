import {
    Columnz,
    DataTablez, GridForm, HeaderListForm, Inputz, StatusBody,
    useGetParams
} from '@/components'
import { useState } from 'react'
import { deleteAccountingAccountApi, updateAccountingAccountApi } from '../api'
import { useCountAccountingAccount, useListAccountingAccount } from '../utils'

const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Inputz
                value={filter.key_search}
                placeholder="Nhập từ khóa"
                onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
            />
        </GridForm>
    )
}

export default function AccountingAccount() {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListAccountingAccount({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountAccountingAccount({ status: undefined, ...params, first: undefined }) || 0

    return (
        <div className="card">
            <HeaderListForm title="Tài khoản hạch toán" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="hạch toán"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/system"
                actionsInfo={{ deleteAction: deleteAccountingAccountApi }}
            >
                <Columnz field="code" header="Mã TK" />
                <Columnz field="name" header="Tên TK" />
                <Columnz field="user_update" header="Người cập nhật" />
                <Columnz field="account" header="TK Nợ PT" />
                <Columnz field="account" header="TK Có PT" />
                <Columnz field="account" header="TK Nợ Báo có" />
                <Columnz field="account" header="TK Có Báo có" />
                <Columnz field="account" header="TK Nợ Thuế" />
                <Columnz field="account" header="TK Có Thuế" />
                <Columnz field="account" header="TK Nợ Trước VAT" />
                <Columnz field="account" header="TK Có Trước VAT" />
                <Columnz
                    header="Trạng Thái"
                    headerStyle={{ padding: 'auto', textAlign: 'center' }}

                    className='text-center'
                    body={(e) =>
                        StatusBody({
                            e,
                            route: '/accounting_account',
                            updateAction: updateAccountingAccountApi,
                        })
                    }
                />
            </DataTablez>
        </div>
    )
}
