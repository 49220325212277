import {
    ActionBody,
    Body,
    Columnz, DataTablezV2, DataTablezV3,
    Dialogz,
    Dropdownz,
    GridForm,
    HeaderListForm,
    LoadDialog,
    NumberFormat,
    useGetParams
} from '@/components'
import { typeElectricMeter } from '@/constants'
import { useGetApi } from '@/hooks'
import { Galleria, Image } from '@/uiCore'
import { checkJson } from '@/utils'
import { saveAs } from 'file-saver'
import JSZip from "jszip"
import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
    deleteElectricWaterApi,
    exportElectricMeter3PriceApi,
    exportElectricMeterApi,
    listFloorApi
} from '../api'
import { useListElectricWater, useListMonth } from '../utils'
import DetailElectricWater from './DetailElectricWater'
import ImportElectricThreePhasePower from './ImportElectricThreePhasePower'
import ImportElectricWater from './ImportElectricWater'
import ImportElectricWaterV2 from './ImportElectricWaterV2'

const Header = ({ setParams, apartments, type_pricesv2 }) => {
    const [filter, setFilter] = useState({ key_search: '', type: 5 })
    const building_place_id = useSelector((state) => state.buildings)
    const month_create = useListMonth()
    const floors = useGetApi(listFloorApi, {}, [])

    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter} className="lg:col-9">
            <Dropdownz
                value={filter.building_place_id}
                options={building_place_id}
                onChange={(e) => setFilter({ ...filter, building_place_id: e.target.value, apartment_id: undefined })}
                placeholder="Chọn tòa nhà"
            />
            <Dropdownz
                value={filter.apartment_id}
                options={filter.building_place_id ? apartments.filter(a => a.building_place_id === filter.building_place_id) : apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.month_create}
                options={month_create}
                optionLabel="cycle_name"
                optionValue="cycle_name"
                onChange={(e) => setFilter({ ...filter, month_create: e.target.value })}
                placeholder="Tháng chốt số"
            />
            <Dropdownz
                value={filter.type}
                options={type_pricesv2}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại"
                optionValue="type"
            />
            <Dropdownz
                value={filter.floor}
                options={floors}
                optionValue="name"
                onChange={(e) => setFilter({ ...filter, floor: e.target.value })}
                placeholder="Tầng"
            />
        </GridForm>
    )
}

const ListElectricWater = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState({ ...initParam })
    const { list, count } = useListElectricWater({ status: undefined, ...params, first: undefined, type: 5 })
    const apartments = useSelector((state) => state.apartments)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [visible, setVisible] = useState(false)
    const [visibleImport, setVisibleImport] = useState(false)
    const [loading, setLoading] = useState(false)
    const [visibleImportV2, setVisibleImportV2] = useState(false)
    const [visibleThreePhasePower, setVisibleThrePhasePower] = useState(false)
    const users = useSelector((state) => state.users)
    const typePrice = useSelector((state) => state.typePrice)
    const type_pricesv2 = typePrice.filter(d => d.type_sv === 3 || d.type_sv == 5)
    const items = [
        {
            label: 'Xóa',
            icon: 'pi pi-trash',
            title: 'chỉ số điện nước',
            actionType: 'xóa',
            action: async (ids) => await deleteElectricWaterApi({ items: ids.map(id => ({ type: params.type, id: id.id })) }),
        },
        {
            label: 'Tải ảnh xuống',
            icon: 'pi pi-cloud-download ',
            title: 'chỉ số điện nước',
            actionType: 'tải hình ảnh',
            action: async (selectedProducts) => await handleDownloadImg(selectedProducts),
        },
    ]

    const moreOptions = [
        {
            label: 'Import V2',
            icon: 'pi pi-upload',
            command: () => setVisibleImportV2(true),
        },
        {
            label: 'Import Điện 3 giá',
            icon: 'pi pi-upload',
            command: () => setVisibleThrePhasePower(true),
        },
    ]
    const token = localStorage.getItem('token')
    const handleDownloadImg = async (selectedProducts) => {
        const zip = new JSZip();
        for (let i = 0; i < selectedProducts.length; i++) {
            if (selectedProducts[i].images) {
                const apartment = apartments.find((a) => a.id === selectedProducts[i].bdc_apartment_id) || {};
                const type = type_pricesv2.find((a) => a.type === selectedProducts[i].type);
                const imageUrl = selectedProducts[i].images;
                const filename = `chi_so_${type.name}_can_ho_${apartment.name}_thang_${selectedProducts[i].month_create}.jpg`;
                const imageUrlConver = checkJson(imageUrl);
                const downloadAndZipImages = async (imageUrls, filename) => {
                    const imagePromises = imageUrls.map((url, index) => {
                        return fetch(url)
                            .then((response) => response.blob())
                            .then((blob) => {
                                zip.file(`${filename}_${index}.jpg`, blob);
                            });
                    });

                    await Promise.all(imagePromises);
                };

                if (selectedProducts[i].type === 7) {
                    const imageUrls = Object.keys(imageUrlConver).map((key) => `${imageUrlConver[key]}?token=${token}`);
                    await downloadAndZipImages(imageUrls, filename);
                } else {
                    const imageUrlV2 = [`${imageUrl}?token=${token}`];
                    await downloadAndZipImages(imageUrlV2, filename);
                }
            }
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'images.zip');
        });

        return { data: { status: true } };
    };
    const [visibleImage, setVisibleImage] = useState(false)
    const [images, setImages] = useState(null)

    const Images = (prop) => {
        const { visible, setVisible, images } = prop

        const newImage = Object.values(JSON.parse(images)).map(i => ({
            itemImageSrc: i,
            thumbnailImageSrc: i,
        }))

        const responsiveOptions = [
            {
                breakpoint: '991px',
                numVisible: 6,
            },
            {
                breakpoint: '767px',
                numVisible: 3,
            },
            {
                breakpoint: '575px',
                numVisible: 1,
            },
        ]

        const itemTemplate = (item) => {
            return <Image
                className="flex w-screen"
                src={item.itemImageSrc}
                preview
                imageStyle={{
                    width: 'auto',
                    height: '500px',
                    margin: '0 auto',
                }}
            />
        }
        const thumbnailTemplate = (item) => {
            return <Image
                className="flex "
                src={item.thumbnailImageSrc}
                imageStyle={{
                    height: '50%',
                    width: '50%',
                    margin: '0 auto',
                }}
            />
        }

        return (
            <div className="">
                <Dialogz title="Hình ảnh điện 3 giá " visible={visible} setVisible={setVisible}>
                    <div className="card">
                        <Galleria value={newImage}
                            item={itemTemplate} responsiveOptions={responsiveOptions}
                            thumbnail={thumbnailTemplate} />
                    </div>
                </Dialogz>
            </div>
        )

    }

    const headerStyle = { backgroundColor: '#A3AAB1' }
    const styleColumn = { fontSize: '15px' }

    return (
        <div className="card">
            {visible && (
                <DetailElectricWater
                    apartments={apartments}
                    type={typeElectricMeter}
                    data={list}
                    setParams={setParams}
                    visible={visible}
                    setVisible={setVisible}
                    params={params}
                />
            )}
            {visibleImport &&
                <ImportElectricWater visible={visibleImport} setVisible={setVisibleImport} setParams={setParams} />}
            {visibleImportV2 && <ImportElectricWaterV2 visible={visibleImportV2} setVisible={setVisibleImportV2}
                setParams={setParams} />}
            {visibleThreePhasePower &&
                <ImportElectricThreePhasePower visible={visibleThreePhasePower} setVisible={setVisibleThrePhasePower}
                    setParams={setParams} />}
            <HeaderListForm title="Danh sách chỉ số điện nước" />
            <Header apartments={apartments} type_pricesv2={type_pricesv2} setParams={setParams} />

            {visibleImage && <Images visible={visibleImage} setVisible={setVisibleImage} images={images} />}
            <LoadDialog visible={visibleImage} />

            <DataTablezV3
                loading={loading}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                value={list && list.map((item) => ({
                    ...item,
                    consumption: item.after_number - item.before_number,
                }))}
                title={params.type != 7 ? 'chỉ số điện nước ' : 'chỉ số điện 3 giá'}
                totalRecords={count || 0}
                params={params}
                headerInfo={{
                    items, exportApi: params.type !== 7 ? (() => exportElectricMeterApi(
                        {
                            ...params,
                            page: false,
                            limit: false,
                            type: params.type || params.type === 0 ? params.type : 1,
                        },
                    )
                    ) : () => exportElectricMeter3PriceApi({
                        ...params,
                        page: false,
                        limit: false,
                    }),
                    setVisibleImport: setVisibleImport, moreOptions,
                }}
                setParams={setParams}
                setVisibledDialog={setVisible}
                route="/electric_meter"
                basePermissions={['delete', 'import', 'export', 'detail']}
                headerStyle={headerStyle}

                styleColumn={styleColumn}
            >
                <Columnz
                    style={styleColumn}
                    headerStyle={headerStyle}
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'name' })}
                    header="Căn hộ"
                />
                <Columnz
                    style={styleColumn}
                    headerStyle={headerStyle}
                    body={(e) => Body({ data: apartments, value: e.bdc_apartment_id, label: 'code' })}
                    header="Mã căn hộ"
                />
                <Columnz style={styleColumn} headerStyle={headerStyle} field="service_name" header="Dịch vụ" />
                {params.type !== 7 ? <Columnz style={styleColumn} headerStyle={headerStyle}
                    body={e => <NumberFormat value={e.before_number} />}
                    bodyStyle={{ textAlign: 'right' }} header={'Chỉ số đầu'} /> :
                    <Columnz style={{ display: 'none' }} />}
                {params.type !== 7 ? <Columnz style={styleColumn} headerStyle={headerStyle}
                    body={e => <NumberFormat value={e.after_number} />}
                    bodyStyle={{ textAlign: 'right' }} header={'Chỉ số cuối'} /> :
                    <Columnz style={{ display: 'none' }} />}
                {params.type !== 7 ? <Columnz style={styleColumn} headerStyle={headerStyle}
                    body={e => <NumberFormat value={e.consumption} />}
                    bodyStyle={{ textAlign: 'right' }} header={'Tiêu thụ'} /> :
                    <Columnz style={{ ...styleColumn, display: 'none' }} />}

                {params.type === 7 ? <Columnz style={styleColumn} headerStyle={headerStyle} body={e => {
                    const imagesParse = checkJson(e.images) || e.images
                    const data = Object.values(imagesParse).map(i => ({
                        itemImageSrc: i,
                        thumbnailImageSrc: i,

                    }))

                    const elictric = [e.total_number, e.number_1, e.number_2, e.number_3]

                    const newData = elictric.map((e, i) => {
                        if (i === 0) {
                            return { id: i, ...data[i], elictricMeter: e, color: true }
                        } else {
                            return { id: i, ...data[i], elictricMeter: e }
                        }
                    })
                    return (
                        <DataTablezV2 value={newData} noStt paginator={false} title="chỉ số"
                            rowClassName={(rowData) => {
                                let classes = ''
                                if (rowData.color) {
                                    classes += 'total-consumption'
                                }
                                return classes
                            }}
                        >
                            <Columnz style={styleColumn} headerStyle={headerStyle} className="w-2" body={e => <Image
                                src={e.itemImageSrc}
                                preview
                                imageStyle={{
                                    maxWidth: '100px',
                                    height: 'auto',
                                    width: 'auto',
                                    margin: '0 auto',
                                }}
                            />
                            } header="Hình ảnh" />
                            <Columnz style={styleColumn} headerStyle={headerStyle} className="w-2" body={() => '-'}
                                field="" header="Hệ số" />
                            <Columnz style={styleColumn} headerStyle={headerStyle} className="w-2" body={() => '-'}
                                field="" header="Chỉ số đầu" />
                            <Columnz style={styleColumn} headerStyle={headerStyle} className="w-2" body={() => '-'}
                                field="" header="Chỉ số cuối" />
                            <Columnz style={styleColumn} headerStyle={headerStyle} field="elictricMeter"
                                header="Tổng tiêu thụ" />
                        </DataTablezV2>
                    )
                }} header={'Thông tin chi tiết'} /> : <Columnz style={{ display: 'none', fontSize: '15px' }} />}

                <Columnz style={styleColumn} headerStyle={headerStyle} field="month_create" header="Tháng chốt số" />
                <Columnz
                    style={styleColumn}
                    headerStyle={headerStyle}
                    body={(e) => e.date_update ? moment(e.date_update).format('DD/MM/YYYY') : ''}
                    header="Ngày chốt số"
                />
                {params.type !== 7 ? <Columnz style={styleColumn} headerStyle={headerStyle} body={e =>
                    <Image
                        src={e.images}
                        preview
                        imageStyle={{
                            maxWidth: '100px',
                            height: 'auto',
                            width: 'auto',
                            margin: '0 auto',
                        }}
                    />
                } header="Hình ảnh" /> : <Columnz style={{ display: 'none', fontSize: '15px' }} />}


                <Columnz style={styleColumn} headerStyle={headerStyle} body={e => {
                    if (e.type_action === 1) return 'Thay đồng hồ'
                }} header="Hình thức" />
                <Columnz
                    style={styleColumn}
                    headerStyle={headerStyle}
                    body={(e) => Body({ data: users, value: Number(e.updated_by) || Number(e.created_by), key: 'user_id', label: 'full_name' }) || "Admin"}
                    header="Người chốt"
                />
                <Columnz
                    style={styleColumn}
                    headerStyle={headerStyle}
                    header="Thao tác"
                    body={(e) =>
                        ActionBody({
                            id: e.id,
                            params,
                            setParams,
                            basePermissions: (e.status === 1 ? ['detail'] : ['delete', 'detail']),
                            setVisibledDialog: setVisible,
                            route: '/electric_meter',
                            handleDelete: (id) => {
                                return { items: [{ type: params.type, id }] }
                            },
                            deleteAction: deleteElectricWaterApi,
                        })
                    }
                />
            </DataTablezV3>
        </div>
    )
}

export default ListElectricWater
