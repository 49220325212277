import {Body, FormUpdate, HeaderListForm, InputForm, InputTextareaForm} from '@/components'
import {removeUndefinedProps} from '@/utils'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {descriptionlogTool} from '../../template_tool_log/screens/TemplateToolLog'
import {useDetailLogTool} from '../utils'
import {status} from './LogTool'
import {listToolApi} from '@/api'
import {useGetApi} from '@/hooks'

const initInfos = {
    action: '',
    by_name: '',
    client_id: '',
    description: '',
    code: '',
    error: '',
    request_id: '',
    response: '',
    time: '',
    token: '',
    tool: '',
    url: '',
    _id: '',
    module: '',
}

const UpdateLogTool = () => {
    const {id} = useParams()
    const logToolDetail = useDetailLogTool(id)
    const [infos, setInfos] = useState(initInfos)
    const [tools, setTools] = useState([])
    const toolsData = useGetApi(listToolApi, {}, [])

    useEffect(() => {
        if (logToolDetail._id) {
            const detailLog = descriptionlogTool.find((d) => d.route === logToolDetail.tool)
            const modules = tools.find((t) => t.route === logToolDetail.tool)
            const module = modules ? modules.name : ''
            const description = detailLog ? detailLog.name : ''
            setInfos({...infos, ...removeUndefinedProps(logToolDetail), description, module})
        }
    }, [logToolDetail, tools])

    useEffect(() => {
        if (toolsData && toolsData[0]) {
            let newData = []
            toolsData.forEach((t) => {
                if (t.list_tool_child && t.list_tool_child[0]) {
                    newData = [...newData, ...t.list_tool_child]
                }
            })
            setTools([...newData, {name: 'Authentication', route: '/auth'}])
        }
    }, [toolsData])

    return infos?.template ? <div className="card overflow-scroll">
        <div dangerouslySetInnerHTML={{__html: infos?.template}}/>
    </div> : <FormUpdate checkId={id} route="/log_tool">
        <HeaderListForm title="Thông tin người thao tác"/>
        <div className="card bg-color">
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <InputForm value={infos.by_name} label="Người thao tác"/>
                    <InputForm value={infos.token} label="token"/>
                </div>
                <div className="col-12 lg:col-6">
                    <InputForm value={infos.client_id} label="client_id"/>
                </div>
            </div>
        </div>

        <HeaderListForm title="Thông tin chi tiết"/>
        <div className="card bg-color">
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <InputForm value={infos.module} label="Module"/>
                    <InputForm value={infos.action === 'update' ? 'Cập nhật' : 'Thêm'} label="Hành Động"/>
                    <InputForm value={infos.description} label="Mô tả"/>
                    <InputForm value={infos.tool} label="tool"/>
                    <InputForm value={infos.url} label="url"/>
                </div>
                <div className="col-12 lg:col-6">
                    <InputForm value={infos.time} label="Thời gian"/>
                    <div className="mb-3 px-2 change-disabled">
                        <div className="w-full flex justify-content-between">
                            <label className="font-medium w-full">Trạng thái</label>
                        </div>
                        <div className="w-full mt-2 flex pt-2 pb-2" style={{minHeight: '42px'}}>
                            <Body data={status} value={infos.status}/>
                        </div>
                    </div>
                    <InputForm value={infos.request_id} label="request_id"/>
                    <InputForm value={infos._id} label="_id"/>
                </div>
            </div>
            <div className="grid grid-form">
                <div className="col-12 lg:col-6">
                    <InputTextareaForm value={JSON.stringify(infos.param, null, 2)} label="Param"/>
                </div>
                <div className="col-12 lg:col-6">
                    <InputTextareaForm value={infos.response} label="Response"/>
                </div>
            </div>
            <InputTextareaForm value={infos.template} label="Template"/>
        </div>
    </FormUpdate>
}

export default UpdateLogTool
