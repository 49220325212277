import {Body, Columnz, HeaderListForm, TimeBody} from '@/components'
import {Image} from '@/uiCore'
import {useEffect, useState} from 'react'
import {exportFilteredAiLog} from '../../api'
import {useListCarParkingAI} from '../../utils'
import {ParkingDialog} from '../ParkingDialog'
import {DataTablez} from '../ParkingForm'
import {vehicle_type} from "@/constants";

const ParkingSupervision = (props) => {
    const {selectedProductsai, setSelectedProductsAI} = props
    const [show, setShow] = useState(false)
    const [showImg, setShowImg] = useState(false)
    const [propsdata, setPropsData] = useState({})
    const handleImage = (e) => {
        setShow(true)
        setPropsData({
            id: e.id,
            plate_number: e.plate_number,
            transfer_time: e.transfer_time,
            lane_action: e.lane_action,
            vehicle_type: e.vehicle_type,
            image: e.image,
        })
    }
    const handleForm = () => {
        setShow(true)
    }
    const [params, setParams] = useState({...props.params})
    const [data, refresh] = useListCarParkingAI({
        status: undefined,
        ...params,
        render: props.render,
        first: undefined,
    })

    useEffect(() => {
        setParams(props.params)
    }, [props.params])
    return (
        <>
            <HeaderListForm title="Hệ thống giám sát bãi xe AI"/>
            <DataTablez
                value={data.ai}
                title="thông tin bộ lọc tự động AI"
                totalRecords={data.countAi}
                params={params}
                setParams={setParams}
                selectedProducts={selectedProductsai}
                setSelectedProducts={setSelectedProductsAI}
                hideParams
                route="/parking_supervision"
                headerInfo={{exportApi: () => exportFilteredAiLog({...props.params})}}
                basePermissions={['export']}
            >
                <Columnz header="Thời gian" headerStyle={{height: '75px'}} field="transfer_time"
                         body={e => TimeBody(e.transfer_time)}/>
                <Columnz header="Trạng Thái" body={(e) => (e.lane_action === 'IN' ? <>Xe vào</> : <>Xe ra</>)}/>
                <Columnz
                    header="Hình ảnh"
                    body={(e) => (
                            <Image
                                width="100"
                                height="70"
                                src={e.image}
                                style={{width: '100px', height: '65px', cursor: 'pointer'}}
                                onClick={() => handleImage(e)}
                            />
                    )}
                />
                <Columnz header="Loại xe" body={e => Body({data: vehicle_type, value: e.vehicle_type, })}/>
                <Columnz header="Biển đối chiếu" field="plate_number"/>
            </DataTablez>
            <ParkingDialog
                show={show}
                setShow={setShow}
                propsdata={propsdata}
                handleForm={handleForm}
                isAi={true}
                refresh={refresh}
                setRender={props.setRender}
            />
        </>
    )
}
export default ParkingSupervision
