import { FormUpdateDialog, InputForm, InputSwitchForm } from '@/components'

import { removePropObject, removeUndefinedProps } from '@/utils'
import { useEffect, useState } from 'react'
import { useDetailUnit } from '../utils'
import { addUnit, updateUnit } from '../api'



const UpdateUnitManagement = (props) => {
      const { setVisible, setParams, visible, } = props
      const detail = useDetailUnit(typeof visible === 'number' ? visible : undefined)
      const [infos, setInfos] = useState({ status: null })
      useEffect(() => {
            if (detail.id) {
                  setInfos({
                        ...infos,
                        ...detail,
                        status: detail.status === 1 ? true : false,
                  })
            }
      }, [detail])
      const handleData = () => {
            let info = {
                  ...infos,
                  status: infos.status ? 1 : 0,
                  name: infos.name
            }
            if (typeof visible === 'number') info = { ...removePropObject(info, detail), id: visible }
            return info
      }

      return (
            <FormUpdateDialog
                  title={(typeof visible === 'number' ? 'Chi tiết ' : 'Thêm mới ') + ' đơn vị'}

                  visible={visible}
                  setVisible={setVisible}
                  setParams={setParams}
                  refreshObjects={[setInfos]}
                  actions={{ add: addUnit, update: updateUnit }}
                  handleData={handleData}
            >
                  <InputForm
                        label="Tên đơn vị (*)"
                        value={infos.name}
                        onChange={(e) => setInfos({
                              ...infos, name: e.target.value
                        })}
                        required />

                  <InputSwitchForm
                        label="Trạng thái"
                        id="status"
                        value={infos.status}
                        onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        checked={infos.status}
                  />

            </FormUpdateDialog >
      )
}

export default UpdateUnitManagement
