import {Body, Columnz, DataTablez, HeaderListForm, TimeBody, useGetParams} from '@/components'
import {Image} from '@/uiCore'
import {useEffect, useState} from 'react'
import {exportPmLog} from '../../api'
import {useListCarParkingLogPM} from '../../utils'
import {ParkingDialog} from '../ParkingDialog'
import {vehicle_type} from "@/constants";

const ParkingLogManagement = (props) => {
    const [show, setShow] = useState(false)
    const [showImg, setShowImg] = useState(false)
    const [propsdata, setPropsData] = useState({})
    const handleImage = (e, image) => {
        setShow(true)
        setPropsData({
            id: e.id,
            plate_number: e.plate_compare_number,
            date_time: e.date_time,
            lane_action: e.lane_action,
            vehicle_type: e.vehicle_type,
            image: image,
        })
    }
    const handleForm = () => {
        setShow(true)
    }
    const initParam = useGetParams()
    const [params, setParams] = useState({...props.params})
    const [data, refresh] = useListCarParkingLogPM({
        status: undefined,
        ...params,
        render: props.render,
        first: undefined,
    })
    useEffect(() => {
        setParams(props.params)
    }, [props.params])
    return (
        <>
            <HeaderListForm title="Phần mềm quản lý bãi xe"/>
            <DataTablez
                value={data.data}
                title="thông tin log pm"
                totalRecords={data.count}
                params={params}
                setParams={setParams}
                hideParams
                route="/parking_supervision"
                headerInfo={{exportApi: () => exportPmLog({...props.params})}}
                basePermissions={['export']}
            >
                <Columnz header="Thời gian" headerStyle={{height: '75px'}} body={e => TimeBody(e.date_time)}/>
                <Columnz header="Trạng Thái" body={(e) => (e.lane_action === 'IN' ? <>Xe vào</> : <>Xe ra</>)}/>
                <Columnz
                    header="Hình ảnh"
                    body={(e) => (
                        <div onClick={() => handleImage}>
                            <Image
                                width="100"
                                height="70"
                                src={e.image_1}
                                style={{width: '100px', height: '65px', cursor: 'pointer'}}
                                onClick={() => handleImage(e, e.image_1)}
                            />
                        </div>
                    )}
                />
                <Columnz header="Loại xe" body={e => Body({data: vehicle_type, value: e.vehicle_type})}/>
                <Columnz header="Biển kiểm soát gốc" field="plate_number"/>
                <Columnz header="Biển đối chiếu" field="plate_compare_number"/>
            </DataTablez>
            <ParkingDialog
                show={show}
                setShow={setShow}
                propsdata={propsdata}
                handleForm={handleForm}
                isAi={false}
                refresh={refresh}
                setRender={props.setRender}
            />
        </>
    )
}
export default ParkingLogManagement
