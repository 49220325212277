import { Body, Columnz, DataTablez, Dropdownz, GridForm, HeaderListForm, TimeBody, useGetParams } from '@/components'
import {
    genders,
    listBooking,
    listToast,
    relationshipOwner,
    statusRequest,
    typeCard,
    typeRequest,
    typeVehicle,
    type_vehicles
} from '@/constants'
import { setToast } from '@/redux/features'
import { Button } from '@/uiCore'
import { checkJson, removeUndefinedProps } from '@/utils'
import moment from 'moment'
import { confirmDialog } from 'primereact/confirmdialog'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { switchStatusRequestApi } from '../api'
import { useCountServiceRequest, useListServiceRequest } from '../utils'

export const DataBody = ({ data, type, visible, infos }) => {
    const resident = useSelector((state) => state.residents)
    const apartments = useSelector((state) => state.apartments)
    const MapUsers = (rowData) => {
        try {

            const userNames = rowData.map((userId) => {
                const user = resident.find((item) => item && item.user_id && item.user_id === userId);
                return user && user.full_name ? user.full_name : 'Không tìm thấy';
            });
            const userNamesString = userNames.join(', ');
            return userNamesString;
        } catch (error) {
            // console.error("Error parsing JSON or mapping users:", error);
            return "Chưa có người sử dụng";
        }
    };
    if (!data || data.length === 0) {
        return <p className="font-semibold">Không tìm thấy dữ liệu</p>
    }

    if (type === 13 || type === 14) {
        data.reqs = []
        const newData = checkJson(data.req_cate) || data.req_cate
        if (Array.isArray(newData) && newData && newData[0]) {
            newData.forEach(n => {
                data.reqs.push(n.name)
            })
        }
    }
    let name
    if (type === 4) {
        if (Number(data.pass) === 1) name = "Chuyển đồ ra"
        else name = "Chuyển đồ vào"
    }

    return (
        <>
            <div>
                <div className="text-white text-center py-2" style={{ backgroundColor: 'rgb(60, 141, 188)' }}>
                    <b>{name || typeRequest.find((e) => e.id === type)?.name}</b>
                </div>
                <div>
                    {type === 9 || type === 10 ? (
                        <>
                            <div
                                className='grid grid-form px-2 mt-1'
                            >
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Họ và tên</div>
                                    <strong>{data.full_name}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Ngày sinh</div>
                                    <strong>{TimeBody(data.birthday, 'date')}</strong>
                                </div>
                                {type === 10 ? (
                                    <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                        style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                        <div>Địa chỉ</div>
                                        <strong>{infos.data.address}</strong>
                                    </div>
                                ) : null}
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Giới tính</div>
                                    <strong>{genders.find((gender) => gender.id === data.gender)?.name}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>SĐT</div>
                                    <strong>{infos.data.phone || infos.data.sdt || ""}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Email</div>
                                    <strong>{data.email}</strong>
                                </div>
                                {visible === true ? (
                                    <>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Số CCCD</div>
                                            <strong>{data.cmt_number}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Nơi cấp</div>
                                            <strong>{data.cmt_address}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Căn hộ</div>
                                            <strong>{infos.apartment_id ? apartments.find(a => a.id === infos.apartment_id)?.name : infos.name}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Quan hệ với chủ hộ</div>
                                            <strong>{infos.data.type ? relationshipOwner.find(a => a.id === infos.data.type)?.name : infos.data.type}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Ngày cấp</div>
                                            <strong>{TimeBody(data.cmt_date, 'date')}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Người đăng ký</div>
                                            <strong>{infos.user_info_id ? resident.find(e => e.user_id === infos.user_info_id)?.full_name || infos.full_name : infos.full_name}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>SĐT người đăng ký</div>
                                            <strong>{infos.user_info_id ? (resident.find(e => e.user_id === infos.user_info_id)?.phone_contact) || "Trống" : "Trống"}</strong>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : type === 1 || type === 2 ? (
                        <>
                            <div
                                className='grid grid-form px-2 mt-1'
                            >
                                {type === 1 ? (
                                    <>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Người đăng ký</div>
                                            <strong>{infos.user_id ? resident.find(e => e.user_id === infos.user_id)?.full_name : infos.full_name}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Loại xe</div>
                                            <strong>{type_vehicles.find(t => t.id === Number(infos.data.type_vehicles))?.name}</strong>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Người đăng ký</div>
                                            <strong>{infos.user_id ? resident.find(e => e.user_id === infos.user_id)?.full_name : infos.full_name}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Lý do</div>
                                            <strong>{infos.data.reason}</strong>
                                        </div>
                                    </>
                                )}
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Biển số xe</div>
                                    <strong>{infos.data.number_vehicles}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    {type === 1 ? (
                                        <>
                                            <div>Ngày bắt đầu sử dụng dự kiến:</div>
                                            <strong>{TimeBody(infos.data.date_begin, 'date')}</strong>

                                        </>
                                    ) : (
                                        <>
                                            <div>Ngày kết thúc sử dụng dự kiến:</div>
                                            <strong>{TimeBody(infos.data.date_end, 'date')}</strong>
                                        </>
                                    )}
                                    <strong>{genders.find((gender) => gender.id === data.gender)?.name}</strong>
                                </div>
                            </div>
                            {visible === true ? (
                                <>
                                    <div
                                        className='grid grid-form px-2 mt-1'
                                    >
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Số khung</div>
                                            <strong>{infos.data.frame_number}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Số máy</div>
                                            <strong>{infos.data.machine_number}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Đăng ký tại</div>
                                            <strong>{infos.data.registration_address}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Mô tả</div>
                                            <strong>{infos.data.description}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Chủ phương tiện</div>
                                            <strong>{infos.user_info_id ? resident.find(e => e.id === infos.user_info_id)?.full_name : infos.full_name}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Người sử dụng</div>
                                            <strong>{MapUsers(data.list_user_id)}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Loại</div>
                                            <strong>{infos.data.additional}</strong>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : type === 5 ? (
                        <>
                            <div
                                className='grid grid-form px-2 mt-1'
                            >
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Người đăng ký</div>
                                    <strong>{data.full_name}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Điện thoại</div>
                                    <strong>{data.phone}</strong>
                                </div>
                                {visible === true ? (
                                    <>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Email</div>
                                            <strong>{data.email}</strong>
                                        </div>
                                    </>
                                ) : null}
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Đơn vị thi công</div>
                                    <strong>{data.construction}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Ghi chú</div>
                                    <strong>{data.content}</strong>
                                </div>
                                {visible === true ? (
                                    <>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Thành tiền</div>
                                            <strong>{data.summary}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Đã thanh toán</div>
                                            <strong>{data.paid}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left " style={{
                                            borderBottom: '1px solid #d3d9de', minHeight: "60px"
                                        }}>
                                            <div>Thời gian dự kiến thi công</div>
                                            <strong>{TimeBody(data.to)}</strong>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-12 lg:col-12" style={{
                                        borderBottom: '1px solid #d3d9de', minHeight: "60px"
                                    }}>
                                        <div>Thời gian dự kiến thi công</div>
                                        <strong>{TimeBody(data.to)}</strong>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : type === 6 ? (
                        <>
                            <div
                                className='grid grid-form px-2 mt-1'
                            >
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Người đăng ký</div>
                                    <strong>{data.full_name}</strong>

                                    {/* <strong>{resident.find(e => e.user_id === infos.user_id)?.full_name || null}</strong> */}
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Điện thoại</div>
                                    <strong>{data.phone}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Ngày đăng ký</div>
                                    <strong>{TimeBody(data.date, 'date')}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Tiện ích</div>
                                    <strong>{Body({ data: listBooking, value: data.service_type })}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Khung giờ đăng ký</div>
                                    {data.time && data.time[0] && data.time.map(e => {
                                        return (
                                            <div>
                                                <strong>
                                                    {e}
                                                </strong>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    ) : type === 3 || type === 7 ? (
                        <>
                            <div
                                className='grid grid-form px-2 mt-1'
                            >
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Biển số xe</div>
                                    <strong>{data.number_vehicles}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Lý do</div>
                                    <strong>{data?.reason && data.reason.title}</strong>
                                </div>
                            </div>
                            {/* <div
                            className='grid grid-form px-2 mt-1'
                        >
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left" style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <div>Loại xe</div>
                                <strong>{data.birthday}</strong>
                            </div>
                        </div> */}
                        </>
                    ) : type === 4 ? (
                        <>
                            <div
                                className='grid grid-form px-2 mt-1'
                            >
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Người đăng ký</div>
                                    <strong>{infos.user_id ? resident.find(e => e.user_id === infos.user_id)?.full_name : infos.full_name}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Điện thoại</div>
                                    <strong>{data.phone}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Khung giờ đăng ký</div>
                                    {data.times && data.times[0] && data.times.map(e => {
                                        return (
                                            <div>
                                                <strong>
                                                    {e}
                                                </strong>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Ngày đăng ký</div>
                                    <strong>{TimeBody(data.date, 'date')}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Phương tiện</div>
                                    <strong>{data.vehicle_name}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <div>Biển kiểm soát</div>
                                    <strong>{data.vehicle_number}</strong>
                                </div>
                                {visible === true ? (
                                    <>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Căn hộ</div>
                                            <strong>{infos.apartment_id ? apartments.find(a => a.id === infos.apartment_id)?.name : infos.name}</strong>
                                        </div>
                                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                            <div>Mô tả</div>
                                            <strong>{data.description}</strong>
                                        </div>
                                    </>
                                ) : null}

                                <div className="col-12 lg:col-12">
                                    <div>Đồ vận chuyển</div>
                                    <>
                                        <table className='w-full mt-1'>
                                            <thead>
                                                <tr className='text-center w-full gap-2'>
                                                    <th className='align-items-center justify-content-center h-2rem w-3 surface-200 font-bold border-round'>
                                                        Tên
                                                    </th>
                                                    <th className='align-items-center justify-content-center h-2rem w-4 surface-200 font-bold border-round'>
                                                        Số lượng
                                                    </th>
                                                    <th className='align-items-center justify-content-center h-2rem w-5 surface-200 font-bold border-round'>
                                                        Mô tả
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.products && data.products[0] && data.products.map(e => {
                                                    return (
                                                        <tr className='justify-content-center w-full gap-2'>
                                                            <td className='align-items-center justify-content-center h-2rem text-center w-3 surface-200 border-round'>{e.title}</td>
                                                            <td className='align-items-center justify-content-center h-2rem text-center w-4 surface-200 border-round'>{e.amount}</td>
                                                            <td className='align-items-center justify-content-center h-2rem text-center w-5 surface-200 border-round'>{e.desc}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </>
                                </div>
                            </div>
                        </>
                    ) : type === 11 ? (
                        <>
                            <div className='grid grid-form px-2 mt-1'>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <span>Người đăng ký</span>
                                    <strong>{data.full_name ? data.full_name : (resident.find(e => e.user_id === data.user_info_id)?.full_name)}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <span>Số điện thoại</span>
                                    <strong>{data.phone ? data.phone : (resident.find(e => e.user_id === data.user_info_id)?.phone_contact)}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <span>Loại thẻ</span>
                                    <strong>{typeCard.find(e => e.id === data.type)?.name || ""}</strong>
                                </div>
                                <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                    style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                    <span>Số CCCD</span>
                                    <strong>{data.cmt_number ? data.cmt_number : (resident.find(e => e.user_id === data.user_info_id)?.cmt_number)}</strong>
                                </div>
                                {data.type === 3 && <>
                                    <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                        style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                        <span>Biển số xe</span>
                                        <strong>{data.number_vehicle}</strong>
                                    </div>
                                    <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                        style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                        <span>Loại phương tiện</span>
                                        <strong>{typeVehicle.find(e => e.id === data.type_vehicle)?.name || ""}</strong>
                                    </div>
                                </>}
                            </div>
                        </>
                    ) : type === 12 ? (
                        <div className='grid grid-form px-2 mt-1'>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Số thẻ</span>
                                <strong>{data.card_number}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Mã thẻ</span>
                                <strong>{data.card_code}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Lý do</span>
                                {data.reason?.includes(";") ? data.reason.split(";").map((d, index) => (
                                    <>
                                        <strong>{d}</strong>
                                        <br />
                                    </>
                                )) : <strong>{data.reason}</strong>}
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <div>Người đăng ký</div>
                                <strong>{infos.user_id ? resident.find(e => e.user_id === infos.user_id)?.full_name : infos.full_name}</strong>
                            </div>
                        </div>
                    ) : type === 13 ? (
                        <div className='grid grid-form px-2 mt-1'>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <div>Người đăng ký</div>
                                <strong>{infos.user_id ? resident.find(e => e.user_id === infos.user_id)?.full_name : infos.full_name}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <div>Căn hộ</div>
                                <strong>{infos.apartment_id ? apartments.find(a => a.id === infos.apartment_id)?.name : infos.name}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Khu vực</span>
                                <strong>{checkJson(data.area) && checkJson(data.area).name}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Hạng mục yêu cầu</span>
                                {data.reqs.map((d, index) => (
                                    <strong key={index}>{d}</strong>
                                ))}
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Mô tả</span>
                                <strong>{data.description}</strong>
                            </div>
                        </div>
                    ) : type === 14 ? (
                        <div className='grid grid-form px-2 mt-1'>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <div>Người đăng ký</div>
                                <strong>{infos.user_id ? resident.find(e => e.user_id === infos.user_id)?.full_name : infos.full_name}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <div>Căn hộ</div>
                                <strong>{infos.apartment_id ? apartments.find(a => a.id === infos.apartment_id)?.name : infos.name}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Khu vực</span>
                                <strong>{checkJson(data.area) && checkJson(data.area).name}</strong>
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Hạng mục yêu cầu</span>
                                {data.reqs.map((d, index) => (
                                    <strong key={index}>{d}</strong>
                                ))}
                            </div>
                            <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                                style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                                <span>Mô tả</span>
                                <strong>{data.description}</strong>
                            </div>
                        </div>
                    ) : type === 15 ? <div className='grid grid-form px-2 mt-1'>
                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                            <div>Người đăng ký</div>
                            <strong>{infos.user_id ? resident.find(e => e.user_id === infos.user_id)?.full_name : infos.full_name}</strong>
                        </div>
                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                            <div>Ngày bắt đầu tính phí</div>
                            <strong>{TimeBody(data.first_time_active, 'date')}</strong>
                        </div>
                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                            <div>Biển số xe</div>
                            <strong>{data.number_vehicles || data.vehicle_number}</strong>
                        </div>
                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                            <div>Ngày kết thúc tính phí</div>
                            <strong>{TimeBody(data.finish, 'date')}</strong>
                        </div>
                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                            <div>Ghi chú</div>
                            <strong>{data.note}</strong>
                        </div>
                        <div className="col-12 lg:col-6 flex flex-column gap-1 text-left"
                            style={{ borderBottom: '1px solid #d3d9de', minHeight: "60px" }}>
                            <div>Thời gian gia hạn</div>
                            <strong>{TimeBody(data.extended, 'date')}</strong>
                        </div>
                    </div>
                        : <></>}
                </div>
            </div>
        </>
    )
}
export const ActionBody = ({
    id,
    route,
    status,
    deleteAction = () => {
    },
    params,
    setParams,
    setVisibledDialog,
    handleDelete = (id) => ({ id }),
    moreActions,
    handleAfterCallApi = () => {
    },
    basePermissions = []
}) => {
    const permission = useSelector((state) => state.myTool).myTool
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [query, setQuery] = useState(null)

    async function accept() {
        const params = handleDelete(id)
        const response = await deleteAction(params)
        if (response && response.data?.status) {
            dispatch(setToast({ ...listToast[0], detail: 'Xóa dữ liệu thành công!' }))
            if (params && setParams) {
                setParams((pre) => {
                    return { ...pre, render: !pre.render }
                })
            }
            if (handleAfterCallApi) handleAfterCallApi(id)
        } else dispatch(setToast({ ...listToast[1], detail: response && response.data?.mess }))
    }

    const confirm = () => {
        confirmDialog({
            message: 'Bạn có muốn tiếp tục xóa dữ liệu này?',
            header: process.env.REACT_APP_BRANCH_NAME,
            icon: 'pi pi-info-circle',
            accept,
        })
    }
    useEffect(() => {
        if (params) {
            setQuery(
                '?' +
                new URLSearchParams(
                    removeUndefinedProps({
                        ...params,
                        page: undefined,
                        limit: undefined,
                        render: undefined,
                        first: undefined,
                    }),
                ).toString(),
            )
        }
    }, [params])
    const viewDetails = async () => {
        try {
            const response = await switchStatusRequestApi({ id: id, status: 1 });
        } catch (error) {
            console.error('Error calling API:', error);
        }
    };
    const handleViewDetails = () => {
        if (status === 0 || status === 5) {
            viewDetails();
        }
    };
    return (
        <div className="gap-2 flex justify-content-center">
            {basePermissions.includes('detail') &&
                (setVisibledDialog ?
                    <Button type="button" onClick={() => setVisibledDialog(id)} icon="pi pi-eye" rounded outlined
                        disabled={!route || !permission.includes(route + '/detail/:id')} />
                    : <Link
                        to={(route && permission.includes(route + '/detail/:id')) ? (route + '/detail/' + id) : ""}>
                        <Button
                            disabled={(!route || !permission.includes(route + '/detail/:id'))}
                            type="button"
                            icon="pi pi-eye" rounded outlined
                            onClick={() => handleViewDetails()
                            }
                        />
                    </Link>)}
            {basePermissions.includes('delete') && (
                <Button type="button" rounded outlined icon="pi pi-trash" onClick={confirm} severity="danger"
                    disabled={!route || !permission.includes(route + '/delete')} />
            )}
            {moreActions &&
                moreActions[0] &&
                moreActions.map((act, index) => {
                    return (
                        <Button
                            onClick={() => act.command(id)}
                            key={index}
                            icon={act.icon}
                            severity={act.severity || 'info'}
                            outlined
                            rounded
                            type="button"
                        />
                    )
                })}
        </div>
    )
}
const Header = ({ setParams }) => {
    const [filter, setFilter] = useState({ key_search: '' })
    const apartments = useSelector((state) => state.apartments)
    return (
        <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
            <Dropdownz
                value={filter.apartment_id}
                options={apartments}
                onChange={(e) => setFilter({ ...filter, apartment_id: e.target.value })}
                placeholder="Chọn căn hộ"
            />
            <Dropdownz
                value={filter.type}
                options={typeRequest}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                placeholder="Loại yêu cầu"
            />
            <Dropdownz
                value={filter.status}
                options={statusRequest}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                placeholder="Chọn trạng thái"
            />
        </GridForm>
    )
}
const ServiceRequests = () => {
    const initParam = useGetParams()
    const [params, setParams] = useState(initParam)
    const data = useListServiceRequest({ status: undefined, ...params, first: undefined })
    const totalRecords = useCountServiceRequest({ status: undefined, ...params, first: undefined }) || 0
    return (
        <div className="card">
            <HeaderListForm title="Danh sách yêu cầu dịch vụ" />
            <Header setParams={setParams} />
            <DataTablez
                value={data}
                title="Yêu cầu"
                totalRecords={totalRecords}
                params={params}
                setParams={setParams}
                route="/service_request"
                // actionsInfo
                basePermissions={['detail']}
            >
                {/* <Columnz field="id" header="ID" /> */}
                <Columnz body={(e) => Body({ data: typeRequest, value: e.type })} header="Tiêu đề" />
                <Columnz
                    body={(e) => DataBody({ data: e.data, type: e.type, infos: e })}
                    header="Thông tin chi tiết"
                    bodyStyle={{ minWidth: '30rem', padding: '8px' }}
                />
                <Columnz field="building_name" header="Tòa nhà" />
                <Columnz field="floor" header="Tầng" />
                <Columnz field="name" header="Căn hộ" />
                <Columnz body={e => {
                    return <span style={{ lineHeight: '20px' }}>{e.full_name}
                        <br /> {moment(e.created_at).format("DD/MM/YYYY HH:mm:ss")} </span>
                }} header="Người lập" />
                <Columnz body={(e) => Body({ data: statusRequest, value: e.status })} header="Trạng Thái"
                    className='text-center' />
                <Columnz
                    header="Thao tác"
                    body={(rowData) => {
                        return <>
                            < ActionBody
                                id={rowData.id}
                                status={rowData.status}
                                route="/service_request"
                                params={params}
                                setParams={setParams}
                                basePermissions={['detail']}
                            />
                        </>
                    }}
                />
            </DataTablez>
        </div>
    )
}
export default ServiceRequests
