import {
    CalendarForm,
    InputNumberForm,
    InputTextareaForm,
} from '@/components'
import { useEffect, useState } from 'react'
import { FormUpdateDialog, InputForm } from '@/components'
import { updateDebitApi } from '../api'

export default function DetailReceipt(prop) {
    const { visible, setVisible, setParams, rowData } = prop

    const [infos, setInfos] = useState({
        name: '',
        code: '',
        status: true,
    })

    useEffect(() => {
        if (rowData.id)
            setInfos({
                ...rowData,
                ...infos,
                deadline: new Date(rowData.deadline),
                from_date: new Date(rowData.from_date),
                to_date: new Date(rowData.to_date),
                cost: rowData.sumary + rowData.discount + rowData.khuyen_mai,
            })
    }, [rowData])

    const handleData = () => {
        let info = { id: visible, discount: infos.discount, discount_note: infos.discount_note, note: infos.note }
        return info
    }

    return (
        <FormUpdateDialog
            visible={visible}
            setVisible={setVisible}
            setParams={setParams}
            handleData={handleData}
            refreshObjects={[setInfos]}
            route="/services_list"
            title="Chi tiết bảng kê dịch vụ"
            actions={{ update: updateDebitApi }}
        >
            <div className="card bg-color">
                <div className="grid grid-form">
                    <div className="col-12 lg:col-6">
                        <InputForm id="cycle_name" value={infos.cycle_name} label="Kỳ tháng" disabled />
                        <InputForm id="service_name" value={infos.service_name} label="Dịch vụ" disabled />
                        <InputNumberForm id="price" value={infos.price} label="Đơn giá" disabled />
                        <CalendarForm
                            id="deadline"
                            value={infos.deadline}
                            label="Hạn thanh toán"
                            disabled
                            showIcon={false}
                        />
                        <CalendarForm
                            id="from_date"
                            value={infos.from_date}
                            label="Thời gian bắt đầu"
                            disabled
                            showIcon={false}
                        />
                        <CalendarForm
                            id="to_date"
                            value={infos.to_date}
                            label="Thời gian chốt"
                            disabled
                            showIcon={false}
                        />
                        <InputForm
                            id="time"
                            value={rowData.from_date.replace(/-/g, '/') + '-' + rowData.to_date.replace(/-/g, '/')}
                            label="Thời gian"
                            disabled
                        />
                    </div>
                    <div className="col-12 lg:col-6">
                        <InputNumberForm id="cost" value={infos.cost} label="Phát sinh" disabled />
                        <InputNumberForm
                            id="discount"
                            value={infos.discount}
                            handleOnChange={(e) => setInfos({ ...infos, discount: e })}
                            label="Giảm trừ"
                        />
                        <InputNumberForm id="khuyen_mai" value={infos.khuyen_mai} label="Ưu đãi" disabled />
                        <InputNumberForm
                            id="code"
                            value={infos.cost - infos.discount - infos.khuyen_mai}
                            label="Thành tiền"
                            disabled
                        />
                        <InputNumberForm id="paid" value={infos.paid} label="Thanh toán" disabled />
                        <InputNumberForm id="debt" value={infos.cost - infos.discount - infos.khuyen_mai - infos.paid} label="Còn nợ" disabled />
                        <InputForm id="discount_note" value={infos.discount_note} label="Lý do giảm trừ" onChange={(e) => setInfos({ ...infos, discount_note: e.target.value })} />
                    </div>
                </div>
                <InputTextareaForm id="note" value={infos.note} label="Ghi chú" onChange={(e) => setInfos({ ...infos, note: e.target.value })} />
            </div>
        </FormUpdateDialog>
    )
}
