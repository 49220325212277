import { CalendarForm, DataTablezV2, DropdownForm, FormUpdate, HeaderListForm, InputForm, InputNumberForm, InputNumberFormV2, InputSwitchForm, MultiRadioButton, UploadImg, useGetParams } from '@/components'

import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addUserApi, detailUserApi, listExchangeV2Api, listGroupV2Api, updateUserApi } from '../api'
import { useGetApi } from '@/hooks'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { genders, level, marital_status, tax_authorization, work_position } from '@/constants'
import { databaseDate } from '@/lib'
import moment from 'moment'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { useListBankInfo } from '@/modules/buildings/building_overview/utils'
import { Calendar } from 'primereact/calendar'
import { listPosition } from '../../position_management/api'
const initInfos = {
      status: true,
      gender: 1,
}
function GeneralInformation({ setShowMore }) {
      const initParam = useGetParams()
      const { id } = useParams()
      const detail = useGetApi(detailUserApi, { id }, {})
      const [params, setParams] = useState(initParam)
      const [avatar, setAvatar] = useState(null)
      const [infos, setInfos] = useState(initInfos)
      const exchanges = useGetApi(listExchangeV2Api, {}, [])
      const groups = useGetApi(listGroupV2Api, { department_id: infos.department_id }, [])
      useEffect(() => {
            if (id) setShowMore(true)
      }, [id])
      const bank = useListBankInfo()
      const position = useGetApi(listPosition)

      const newBank = bank.map(item => ({
            ...item,
            name: `${item.name} (${item.short_url})`,
      }));
      const [data, setData] = useState([]);
      const [idRow, setIdRow] = useState(1);

      const addRow = () => {
            const newRowId = new Date().getTime();
            const newRow = { idRow: newRowId, name: '', dob: '', phone: '', idNumber: '', relation: '', address: '' };
            setData([...data, newRow]);
      };

      const onEditorValueChange = (product, field, value) => {
            const updatedProduct = { ...product, [field]: value };
            setData(data.map(d => d.idRow === product.idRow ? updatedProduct : d));
      };
      const inputTextEditor = (product, field) => {
            return <InputText type="text" value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      };
      const inputNumberEditor = (product, field) => {
            return <InputNumberFormV2 type="text" value={product[field]} handleOnChange={(e) => onEditorValueChange(product, field, e)} />;
      };

      const columnBodyTemplate = (product, column) => {
            return column.editor ? column.editor(product) : product[column.field];
      };
      const deleteRow = (product) => {

            if (product && (product.idRow || product.idRow === 0 || product.idRow === null)) {
                  setData(data.filter(d => d.idRow !== product.idRow));
            }
      }
      const inputCalender = (product, field) => {
            return <Calendar value={product[field]} onChange={(e) => onEditorValueChange(product, field, e.target.value)} />;
      };
      const actionBodyTemplate = (product) => {
            return <Button icon="pi pi-trash" type='button' onClick={() => deleteRow(product)} severity="danger" />;
      };
      const columns = [
            { field: 'name', header: 'Họ và tên', editor: (product) => inputTextEditor(product, 'name') },
            { field: 'dob', header: 'Ngày sinh', editor: (product) => inputCalender(product, 'dob') },
            { field: 'phone', header: 'Số điện thoại', editor: (product) => inputNumberEditor(product, 'phone') },
            { field: 'idNumber', header: 'Số CMND/CCCD', editor: (product) => inputNumberEditor(product, 'idNumber') },
            { field: 'relation', header: 'Quan hệ', editor: (product) => inputTextEditor(product, 'relation') },
            { field: 'address', header: 'Địa chỉ', editor: (product) => inputTextEditor(product, 'address') },
            { body: (e) => actionBodyTemplate(e), header: 'Hành động' },
      ];

      useEffect(() => {
            if (detail.id) {

                  setInfos({
                        ...infos,
                        ...removeUndefinedProps(detail),
                        status: Number(detail.status) === 1,
                        email: detail.email || '',
                        phone: detail.phone || '',
                        birthday: detail.birthday ? new Date(detail.birthday) : '',
                        cmt_date: detail.cmt_date ? new Date(detail.cmt_date) : '',
                        decision_date: detail.decision_date ? new Date(detail.decision_date) : '',
                        date_in: detail.date_in ? new Date(detail.date_in) : '',
                        validity_period: detail.validity_period ? new Date(detail.validity_period) : '',
                  })
                  const data = checkJson(detail.data) || detail.data
                  if (data && data[0]) {
                        data.forEach(d => {
                              if (d.dob) d.dob = new Date(d.dob)
                        })
                  }
                  if (Array.isArray(data)) setData([...data])
            }
            if (detail.avatar && detail.avatar !== JSON.stringify([])) setAvatar(detail.avatar)
      }, [detail])

      const handleData = () => {
            if (!infos.date_in) return "Ngày vào không được để trống";
            // if (!infos.birthday) return "Ngày sinh không được để trống";
            let info = { ...infos, status: infos.status ? 1 : 0 }
            info.birthday = info.birthday ? databaseDate(info.birthday, false, "date") : undefined
            info.cmt_date = info.cmt_date ? databaseDate(info.cmt_date, false, "date") : undefined
            info.decision_date = info.decision_date ? databaseDate(info.decision_date, false, "date") : undefined
            info.date_in = info.date_in ? databaseDate(info.date_in, false, "date") : undefined
            info.validity_period = info.validity_period ? databaseDate(info.validity_period, false, "date") : undefined
            if (avatar) info.avatar = avatar
            else info.avatar = []
            if (!info.department_id) return 'Vui lòng chọn phòng ban'
            if (Number(id)) info = { ...removePropObject(info, detail), id: id }
            if (!infos.group_id && detail.group_id) info.group_id = 0
            const params = {
                  ...info,
                  data: data,
                  type_user: "hrm"
            }

            return params

      }
      return (
            <FormUpdate
                  checkId={Number(id)}
                  title="nhân sự"
                  route="/list_personnel"
                  actions={{ add: addUserApi, update: updateUserApi }}
                  handleData={handleData}
            >
                  <div className="card">
                        <HeaderListForm title="Thông tin cá nhân" />
                        <div className="grid grid-form card">
                              <div className="col-12 lg:col-4">
                                    <UploadImg image={avatar} setImage={setAvatar} title="Ảnh đại diện" />
                              </div>
                              <div className="col-12 lg:col-4">
                                    <InputForm
                                          id="code"
                                          value={infos.code}
                                          onChange={(e) => setInfos({ ...infos, code: e.target.value })}
                                          label="Mã nhân viên (*)"
                                          required
                                    />
                                    <InputForm
                                          id="full_name"
                                          value={infos.full_name}
                                          onChange={(e) => setInfos({ ...infos, full_name: e.target.value })}
                                          label="Tên nhân viên (*)"
                                          required
                                    />
                                    <MultiRadioButton
                                          value={infos.gender}
                                          onChange={(e) => setInfos({ ...infos, gender: e })}
                                          options={genders}
                                          className="w-full"
                                          label="Giới tính (*)"
                                    />
                                    <InputForm
                                          value={infos.nation}
                                          onChange={(e) => setInfos({ ...infos, nation: e.target.value })}
                                          label="Dân tộc "

                                    />
                                    <InputNumberFormV2
                                          id="cmt_number"
                                          value={infos.cmt_number}
                                          handleOnChange={(e) => setInfos({ ...infos, cmt_number: e })}
                                          label="Số CMND/CCCD (*)"
                                          required
                                    />
                                    <InputForm
                                          id="cmt_address"
                                          value={infos.cmt_address}
                                          onChange={(e) => setInfos({ ...infos, cmt_address: e.target.value })}
                                          label="Nơi cấp "


                                    />
                              </div>
                              <div className="col-12 lg:col-4">
                                    <InputForm
                                          label="Mã cũ"
                                          value={infos.code_staff_old}
                                          onChange={(e) => setInfos({ ...infos, code_staff_old: e.target.value })} />
                                    <CalendarForm
                                          id="birthday"
                                          label="Ngày sinh (*)"
                                          value={infos.birthday}
                                          onChange={(e) => setInfos({ ...infos, birthday: e.target.value })}
                                    />
                                    <DropdownForm
                                          value={infos.marital_status
                                          }
                                          options={marital_status}
                                          onChange={(e) => setInfos({ ...infos, marital_status: e.target.value })}
                                          label="Tình trạng hôn nhân" />
                                    <InputForm
                                          label="Tôn giáo "
                                          value={infos.religion} onChange={(e) => setInfos({
                                                ...infos, religion: e.target.value
                                          })}

                                    />
                                    <CalendarForm
                                          value={infos.cmt_date}
                                          onChange={(e) => setInfos({ ...infos, cmt_date: e.target.value })}
                                          label="Ngày cấp "
                                    />
                                    <InputSwitchForm
                                          checked={infos.status}
                                          onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                                    />
                              </div>
                        </div>
                        <HeaderListForm title="Thông tin liên lạc" />
                        <div className="grid grid-form card">
                              <div className="col-12 lg:col-6">
                                    <InputForm
                                          id="address"
                                          value={infos.address}
                                          onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                          label="Địa chỉ hiện tại"

                                    />
                                    <InputForm
                                          value={infos.domicile}
                                          onChange={(e) => setInfos({ ...infos, domicile: e.target.value })}
                                          label="Nguyên quán"
                                    />
                                    <InputNumberFormV2
                                          id="phone"
                                          value={infos.phone}
                                          handleOnChange={(e) => setInfos({ ...infos, phone: e })}
                                          label="Số điện thoại "
                                          type="phone"

                                          disabled={id}
                                    />
                              </div>
                              <div className="col-12 lg:col-6">
                                    <InputForm
                                          value={infos.permanent_address}
                                          onChange={(e) => setInfos({ ...infos, permanent_address: e.target.value })}
                                          label="Địa chỉ thường chú "

                                    />
                                    <InputForm
                                          value={infos.place_birth}
                                          onChange={(e) => setInfos({ ...infos, place_birth: e.target.value })}
                                          label="Nơi sinh "

                                    />
                                    <InputForm
                                          id="email"
                                          value={infos.email}
                                          onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                          label="Email cá nhân (*) "
                                          type="email"
                                          disabled={id}
                                    />
                              </div>
                        </div>
                        <HeaderListForm title="Thông tin tiếp nhận nhân sự" />
                        <div className="grid grid-form card">
                              <div className="col-12 lg:col-6">
                                    <InputForm label="Mã chấm công "
                                          value={infos.timekeeping_code}
                                          onChange={(e) => setInfos({
                                                ...infos, timekeeping_code: e.target.value
                                          })}


                                    />
                                    <InputForm
                                          value={infos.decision_number}
                                          onChange={(e) => setInfos({ ...infos, decision_number: e.target.value })}
                                          label="Số quyết định "


                                    />
                                    <DropdownForm
                                          id="department_id"
                                          value={infos.department_id}
                                          onChange={(e) =>
                                                setInfos({ ...infos, department_id: e.target.value, group_id: undefined })
                                          }
                                          options={exchanges}
                                          label="Phòng ban (*)"
                                    />
                                    <DropdownForm
                                          id="group_id"
                                          value={infos.group_id}
                                          onChange={(e) => setInfos({ ...infos, group_id: e.target.value })}
                                          options={groups}
                                          label="Bộ phận"
                                    />

                                    <DropdownForm
                                          value={infos.work_position
                                          }
                                          onChange={(e) => setInfos({
                                                ...infos, work_position: e.target.value
                                          })}
                                          label="Chức vụ (*)"
                                          options={position}
                                    />
                              </div>
                              <div className="col-12 lg:col-6">
                                    <CalendarForm
                                          value={infos.date_in}
                                          onChange={(e) => setInfos({ ...infos, date_in: e.target.value })}
                                          label="Ngày vào (*)" />
                                    <CalendarForm value={infos.decision_date}
                                          onChange={(e) => setInfos({ ...infos, decision_date: e.target.value })}
                                          label="Ngày quyết định " />

                                    <DropdownForm value={infos.level}
                                          onChange={(e) => setInfos({ ...infos, level: e.target.value })}
                                          options={level}
                                          label="Cấp độ" />
                                    <DropdownForm
                                          value={infos.bank}
                                          options={newBank}

                                          onChange={(e) => setInfos({ ...infos, bank: e.target.value })}
                                          label="Ngân Hàng" />
                                    <InputForm
                                          value={infos.account_number}
                                          onChange={(e) => setInfos({ ...infos, account_number: e.target.value })}
                                          label="Số tài khoản" />
                              </div>
                        </div>

                        <div className="grid grid-form card">
                              <div className="col-12 lg:col-12">
                                    <HeaderListForm title="Bảo hiểm xã hội" />
                                    <InputForm
                                          value={infos.bhxh_number}
                                          onChange={(e) => setInfos({ ...infos, bhxh_number: e.target.value })}
                                          label="Số BHXH "

                                    />

                              </div>
                              <div className="col-12 lg:col-12">
                                    <HeaderListForm title="Bảo hiểm y tế" />
                                    <div className="grid">
                                          <div className="col-12 lg:col-6">
                                                <InputForm
                                                      label="Số BHYT "
                                                      value={infos.bhyt_number}
                                                      onChange={(e) => setInfos({ ...infos, bhyt_number: e.target.value })}
                                                />
                                                <InputForm
                                                      label="Nơi đăng ký KCBBD"
                                                      value={infos.register_place}
                                                      onChange={(e) => setInfos({ ...infos, register_place: e.target.value })}
                                                />
                                          </div>
                                          <div className="col-12 lg:col-6">
                                                <CalendarForm
                                                      label="Thời hạn có giá trị "
                                                      value={infos.validity_period}
                                                      onChange={(e) => setInfos({ ...infos, validity_period: e.target.value })}
                                                />
                                          </div>
                                    </div>
                              </div>
                              <div className="col-12 lg:col-12 ">
                                    <HeaderListForm title="Thuế" />
                                    <div className="grid">
                                          <div className="col-12 lg:col-6 ">
                                                <InputForm
                                                      label="Mã số thuế "
                                                      value={infos.tax_code}
                                                      onChange={(e) => setInfos({ ...infos, tax_code: e.target.value })}
                                                />
                                          </div>
                                          <div className="col-12 lg:col-6 ">
                                                <DropdownForm
                                                      label="Ủy quyền quyết toán"
                                                      value={infos.settlement_authorization}
                                                      onChange={(e) => setInfos({ ...infos, settlement_authorization: e.target.value })}
                                                      options={tax_authorization}
                                                />
                                          </div>
                                    </div>

                              </div>

                        </div>
                        <HeaderListForm title="Liên hệ trong trường hợp khẩn cấp" />
                        <div className="card">
                              <Button label="Thêm dòng" onClick={addRow} className='mb-3' type='button' />
                              <DataTable value={data} emptyMessage="Không có dữ liệu">
                                    {columns.map((col, i) => (
                                          <Column key={col.field || i}
                                                field={col.field}
                                                header={col.header}
                                                body={(product) => col.body ? col.body(product, col) : columnBodyTemplate(product, col)} />
                                    ))}
                              </DataTable>
                        </div>
                  </div>
            </FormUpdate>
      )
}

export default GeneralInformation
