import { addExchangeApi, detailExchangeApi, listProjectApi, listUserV2Api, updateExchangeApi } from '@/api'
import {
    DropdownForm, FormUpdate, InputForm, InputSwitchForm, InputTextareaForm, MultiRadioButton, MultiSelectForm,
    UploadImg
} from '@/components'
import { units } from '@/constants'
import { useGetApi } from '@/hooks'
import { validate } from '@/lib'
import { checkJson, removePropObject, removeUndefinedProps } from '@/utils'
import { TabPanel, TabView } from 'primereact/tabview'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Groups from '../group/Groups'
import { useSelector } from 'react-redux'

const initInfos = {
    name: '',
    phone_number: '',
    email: '',
    address: '',
    description: '',
    code_department: '',
    status: true,
    type: 1,
}

const ExchangeInfo = (props) => {
    const { infos, setInfos, projects, setSignature, signature } = props
    const users = useSelector(state => state.users)

    return (
        <div className="card">
            <div className="grid grid-form">
                <div className="col-12 lg:col-3">
                    <div className="card bg-color">
                        <UploadImg image={signature} setImage={setSignature} title="Chữ kí" />
                    </div>
                </div>
                <div className="col-12 lg:col-9">
                    <div className="card bg-color">
                        <div className="grid grid-form">
                            <div className="col-12 lg:col-6">
                                <MultiRadioButton
                                    className="w-full"
                                    label="Đơn vị quản lý (*)"
                                    options={units.slice(0, -1)}
                                    value={infos.type}
                                    disabled={Number(infos.id) || false}
                                    onChange={(e) => setInfos({ ...infos, type: e, building_id: undefined })}
                                />
                                <InputForm
                                    id="name"
                                    value={infos.name}
                                    onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                                    label="Tên phòng ban, dự án (*)"
                                    required
                                />
                                <InputForm
                                    id="code_department"
                                    value={infos.code_department}
                                    onChange={(e) => setInfos({ ...infos, code_department: e.target.value })}
                                    label="Mã phòng ban"
                                />
                                <DropdownForm
                                    id="main_user_id"
                                    value={infos.main_user_id}
                                    onChange={(e) => {
                                        if (e.target.value === undefined) setInfos({ ...infos, main_user_id: null })
                                        else setInfos({ ...infos, main_user_id: e.target.value })
                                    }}
                                    options={users}
                                    optionLabel="full_name"
                                    optionValue="user_id"
                                    label="Trưởng bộ phận"
                                    showClear
                                />
                            </div>
                            <div className="col-12 lg:col-6">
                                <InputForm
                                    id="address"
                                    value={infos.address}
                                    onChange={(e) => setInfos({ ...infos, address: e.target.value })}
                                    label="Địa chỉ"
                                />
                                <InputForm
                                    id="email"
                                    value={infos.email}
                                    onChange={(e) => setInfos({ ...infos, email: e.target.value })}
                                    label="Email"
                                />
                                <InputForm
                                    id="phone_number"
                                    value={infos.phone_number}
                                    onChange={(e) => setInfos({ ...infos, phone_number: e.target.value })}
                                    label="Số điện thoại"
                                />
                                {infos.type === 1 && (
                                    <MultiSelectForm
                                        id="building_id"
                                        value={infos.building_id}
                                        onChange={(e) => setInfos({ ...infos, building_id: e.target.value })}
                                        options={projects}
                                        label="Dự án"
                                    />
                                )}
                            </div>
                        </div>
                        <InputTextareaForm
                            id="description"
                            value={infos.description}
                            onChange={(e) => setInfos({ ...infos, description: e.target.value })}
                            label="Ghi chú"
                        />
                        <InputSwitchForm
                            id="status"
                            checked={infos.status}
                            onChange={(e) => setInfos({ ...infos, status: e.target.value })}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const UpdateExchange = () => {
    const { id } = useParams()
    const detail = useGetApi(detailExchangeApi, { id }, {})
    const [infos, setInfos] = useState(initInfos)
    const projects = useGetApi(listProjectApi, {}, [])
    const [signature, setSignature] = useState(null)

    useEffect(() => {
        if (detail.id) {
            let building_id = []
            if (detail.building_id === '*') building_id = projects.map((p) => p.id)
            else {
                building_id = checkJson(detail.building_id) || detail.building_id
                if (typeof building_id !== 'object') building_id = []
            }
            setInfos({
                ...infos,
                ...removeUndefinedProps(detail),
                building_id,
                status: Number(detail.status) !== 0,
            })
            if (detail.signature) setSignature(detail.signature)
        }
    }, [detail, projects])

    const handleData = () => {
        let info = { ...infos, status: infos.status ? 1 : 0 }
        if (info.building_id && info.building_id.length === projects.length) {
            info = { ...info, building_id: '*' }
        }
        if (signature) info.signature = signature
        else info.signature = 'none'
        if (infos.email && !validate('email', infos.email)) {
            return 'Email không đúng định dạng '
        }
        if (infos.phone_number && !validate('phone', infos.phone_number)) {
            return 'Số điện thoại không đúng định dạng'
        }
        if (Number(id)) info = { ...removePropObject(info, detail), id: id }
        return info
    }

    return (
        <FormUpdate
            checkId={Number(id)}
            title="phòng ban / dự án"
            handleData={handleData}
            route="/department"
            actions={{ add: addExchangeApi, update: updateExchangeApi }}
        >
            <div className="card">
                <TabView>
                    <TabPanel header="Chi tiết">
                        <ExchangeInfo
                            signature={signature}
                            setSignature={setSignature}
                            infos={infos}
                            setInfos={setInfos}
                            projects={projects}
                        />
                    </TabPanel>
                    {Number(id) && (
                        <TabPanel header="Danh sách bộ phận">
                            <Groups bdc_department_id={Number(id)} />
                        </TabPanel>
                    )}
                </TabView>
            </div>
        </FormUpdate>
    )
}

export default UpdateExchange
